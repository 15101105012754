import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import usePage from '../hooks/usePage'
import { FlexColumn } from '../components/atoms/layout/Flex'
import ChatMessageTable from '../components/molecules/display/ChatMessageTable'
import Pagination from '../components/atoms/navigation/Pagination'
import { ChatActions } from '../store/chat'
import { ChatSelectors } from '../store/chat/selectors'
import PaginationCountLabel from '../components/molecules/display/PaginationCountLabel'
import Label from '../components/atoms/display/Label'
import useRouter from '../hooks/useRouter'

export default function UnreadMessagesPage() {
  const { changeTitle } = usePage()
  const { unreadMessages } = useRouter()
  const dispatch = useDispatch()
  const messages = useSelector(ChatSelectors.unreadMessages)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = Number(query.get('page') ?? 1)
  useEffect(() => {
    changeTitle('未読メッセージ')
  }, [changeTitle])
  useEffect(() => {
    dispatch(ChatActions.loadUnreadMessages({ page }))
  }, [dispatch, page])
  const handlePage = (pageNumber: number) => {
    unreadMessages(pageNumber)
    window.scrollTo(0, 0)
  }
  return (
    <FlexColumn>
      {messages.count === 0 ? (
        <Label text="未読メッセージはありません" />
      ) : (
        <>
          <PaginationCountLabel page={page} contents={messages} />
          <ChatMessageTable messages={messages.results} />
          <Pagination count={messages.count} page={page} size={30} onChange={handlePage} />
        </>
      )}
    </FlexColumn>
  )
}

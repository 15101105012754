import { useState } from 'react'
import DocViewer from '@cyntler/react-doc-viewer'
import MSDocRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/msdoc'
import CSVDocRenderer from '@cyntler/react-doc-viewer/dist/esm/renderers/csv'
import { Attachment } from '../../../types/Document'
import { useAttachmentsViewDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexRow } from '../../atoms/layout/Flex'
import IconButton from '../../atoms/inputs/IconButton'
import { ArrowBackIcon, ArrowForwardIcon, DownloadIcon } from '../../atoms/display/Icons'
import ImageViewer from '../../atoms/display/ImageViewer'
import RelativeBox from '../../atoms/layout/RelativeBox'
import Label from '../../atoms/display/Label'
import PdfViewer from '../../atoms/display/PdfViewer'
import usePdf from '../../../hooks/usePdf'
import Link from '../../atoms/navigation/Link'

type AttachmentsViewDialogProps = {
  activeId: string
  attachments: Attachment[]
}

function typeFromName(name: string) {
  if (name.endsWith('.pdf')) {
    return 'pdf'
  }
  if (name.endsWith('.png')) {
    return 'image'
  }
  if (name.endsWith('.jpg')) {
    return 'image'
  }
  if (name.endsWith('.xlsx')) {
    return 'excel'
  }
  if (name.endsWith('.docx')) {
    return 'word'
  }
  if (name.endsWith('.csv')) {
    return 'csv'
  }
  return 'unknown'
}

function NoRendererView() {
  return <Label text="プレビューできないファイルです" />
}

export default function AttachmentsViewDialog({ activeId, attachments }: AttachmentsViewDialogProps) {
  const { state, close } = useAttachmentsViewDialog()
  const [displayIndex, setDisplayIndex] = useState(attachments.map((item) => item.id).indexOf(activeId))
  const attachment = attachments[displayIndex]
  const pdfParams = usePdf({ filename: attachment.name })
  const type = typeFromName(attachment.name)
  MSDocRenderer.fileTypes.push('binary/octet-stream')
  CSVDocRenderer.fileTypes.push('binary/octet-stream')

  return (
    <Dialog
      open={state !== undefined}
      width="lg"
      title={attachment.name}
      onClose={close}
      actions={
        <FlexRow>
          <Link href={attachment.url} download>
            <DownloadIcon />
          </Link>
        </FlexRow>
      }
    >
      <FlexRow align="center" justify="space-between">
        <IconButton label="前へ" disabled={displayIndex === 0} onClick={() => setDisplayIndex(displayIndex - 1)}>
          <ArrowBackIcon />
        </IconButton>
        {['image'].includes(type) && (
          <RelativeBox>
            <FlexRow align="center" justify="center" height={480}>
              <ImageViewer src={attachment.url} alt="image" />
            </FlexRow>
          </RelativeBox>
        )}
        {['word', 'excel'].includes(type) && (
          <FlexRow align="center" justify="center" height={480}>
            <DocViewer
              documents={[
                {
                  uri: attachment.url,
                },
              ]}
              pluginRenderers={[MSDocRenderer]}
              prefetchMethod="GET"
              config={{
                noRenderer: {
                  overrideComponent: NoRendererView,
                },
              }}
              style={{ width: 640, height: 480 }}
            />
          </FlexRow>
        )}
        {['csv'].includes(type) && (
          <FlexRow align="center" justify="center" height={480}>
            <DocViewer
              documents={[
                {
                  uri: attachment.url,
                },
              ]}
              pluginRenderers={[CSVDocRenderer]}
              prefetchMethod="GET"
              config={{
                noRenderer: {
                  overrideComponent: NoRendererView,
                },
              }}
            />
          </FlexRow>
        )}
        {['pdf'].includes(type) && (
          <FlexRow align="center" justify="center" height={480} width={640}>
            <PdfViewer url={attachment.url} httpHeaders={{}} height={480} params={pdfParams} />
          </FlexRow>
        )}
        {['unknown'].includes(type) && (
          <FlexRow align="center" justify="center" height={480}>
            <Label text="プレビューできないファイルです" />
          </FlexRow>
        )}
        <IconButton
          label="次へ"
          disabled={displayIndex === attachments.length - 1}
          onClick={() => setDisplayIndex(displayIndex + 1)}
        >
          <ArrowForwardIcon />
        </IconButton>
      </FlexRow>
    </Dialog>
  )
}

import { useCallback } from 'react'

export default function useString() {
  const truncate = useCallback((text: string, length: number) => {
    if (text.length > length) {
      return `${text.substring(0, length)} ...`
    }
    return text
  }, [])
  return {
    truncate,
  }
}

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { ApprovalFlow, ApprovalFlowMember } from '../../../types/ApprovalFlow'
import Dialog from '../../atoms/feedback/Dialog'
import { useApprovalFlowSettingDialog } from '../../../hooks/useDialog'
import Button from '../../atoms/inputs/Button'
import useProcessing from '../../../hooks/useProcessing'
import { ApprovalFlowActions } from '../../../store/approval_flow'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import { CompanySelectors } from '../../../store/company/selectors'
import Avatar from '../../atoms/display/Avatar'
import Label from '../../atoms/display/Label'
import { AccountsIcon, UserAddIcon, UserRemoveIcon } from '../../atoms/display/Icons'
import IconButton from '../../atoms/inputs/IconButton'
import { toRelatedUser, User } from '../../../types/User'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Divider from '../../atoms/display/Divider'
import useForm from '../../../hooks/useForm'
import useValidationRule from '../../../hooks/useValidationRule'
import useYup from '../../../hooks/useYup'
import ApprovalFlowNameField from '../../molecules/inputs/ApprovalFlowNameField'
import FormItem from '../../molecules/inputs/FormItem'
import Alert from '../../atoms/feedback/Alert'
import { SessionSelectors } from '../../../store/session/selectors'
import { CompanyActions } from '../../../store/company'

type ApprovalFlowSettingDialogProps = {
  flow?: ApprovalFlow
}

type ApprovalFlowSettingParams = {
  name: string
}

export default function ApprovalFlowSettingDialog({ flow }: ApprovalFlowSettingDialogProps) {
  const { processing, startProcess } = useProcessing(ApprovalFlowActions.saveFlow.typePrefix)
  const { state, close } = useApprovalFlowSettingDialog()
  const users = useSelector(CompanySelectors.users)
  const [flowMembers, setFlowMembers] = useState<ApprovalFlowMember[]>(flow?.members ?? [])
  const companyId = useSelector(SessionSelectors.companyId)
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.approvalFlowName.required(),
  })
  const { handleSubmit, register, errors, setValue } = useForm<ApprovalFlowSettingParams>(schema)

  useEffect(() => {
    dispatch(CompanyActions.fetchUsers())
  }, [dispatch])
  useEffect(() => {
    setValue('name', flow?.name ?? '')
    setFlowMembers(flow?.members ?? [])
  }, [flow?.name, flow?.members, setValue])

  const handleAddUser = useCallback(
    (user: User | null) => {
      if (flowMembers.length >= 5) {
        return
      }
      setFlowMembers(
        flowMembers.concat({
          step: flowMembers.length + 1,
          user: user ? toRelatedUser(user) : null,
        })
      )
    },
    [flowMembers]
  )
  const handleRemoveUser = useCallback(
    (user: User | null) => {
      if (user === null) {
        setFlowMembers(flowMembers.filter((item) => item.user !== null))
      } else {
        setFlowMembers(flowMembers.filter((item) => item.user?.id !== user.id))
      }
    },
    [flowMembers]
  )
  const handleSave = useCallback(
    (values: ApprovalFlowSettingParams) => {
      if (companyId) {
        startProcess()
        dispatch(
          ApprovalFlowActions.saveFlow({
            companyId,
            id: flow?.id,
            name: values.name,
            users: flowMembers.map((member) => member.user),
          })
        )
      }
    },
    [companyId, dispatch, flow?.id, flowMembers, startProcess]
  )

  return (
    <Dialog
      open={state !== undefined}
      title="承認フローの設定"
      onClose={close}
      onSubmit={handleSubmit(handleSave)}
      actions={
        <Button type="submit" disabled={flowMembers.length === 0 || processing}>
          保存
        </Button>
      }
    >
      <FlexColumn space={2}>
        <FlexColumn>
          {flowMembers.length > 0 ? (
            <AvatarStepper members={flowMembers} />
          ) : (
            <Alert severity="warning" label="フローにメンバーを追加してください" />
          )}
        </FlexColumn>
        <Divider />
        <FormItem label="承認フロー名">
          <ApprovalFlowNameField register={register('name')} error={errors.name} />
        </FormItem>
        <FlexColumn>
          <Label text="フローには5人まで追加できます" variant="caption" />
          <FlexColumn space={2}>
            <FlexRow align="center" justify="space-between">
              <FlexRow align="center" width="240px">
                <AccountsIcon size={40} />
                <Label text="担当者" />
              </FlexRow>
              {flowMembers.some((member) => member.user === null) ? (
                <IconButton label="" onClick={() => handleRemoveUser(null)} color="caution">
                  <UserRemoveIcon />
                </IconButton>
              ) : (
                <IconButton label="" onClick={() => handleAddUser(null)} disabled={flowMembers.length >= 5}>
                  <UserAddIcon />
                </IconButton>
              )}
            </FlexRow>
            {users.map((user) => (
              <FlexRow align="center" justify="space-between">
                <FlexRow align="center" width="240px">
                  <Avatar alt={user.name} url={user.icon} />
                  <Label text={user.name} />
                </FlexRow>
                {flowMembers.map((member) => member.user?.id).includes(user.id) ? (
                  <IconButton label="" onClick={() => handleRemoveUser(user)} color="caution">
                    <UserRemoveIcon />
                  </IconButton>
                ) : (
                  <IconButton label="" onClick={() => handleAddUser(user)} disabled={flowMembers.length >= 5}>
                    <UserAddIcon />
                  </IconButton>
                )}
              </FlexRow>
            ))}
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

import { Request } from './Request'
import { Notification, User } from '../types/User'

type LoginResponse = {
  token: string
  company: {
    id: string
    workspaceDomain: string
  }
}

type UploadUserImageResponse = {
  icon: string
}

export default class SessionRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async register(
    email: string,
    password: string,
    companyDomain: string,
    companyName: string,
    representativeName?: string,
    token?: string,
    registrationStatus?: { isContractee: boolean; isInviteContractor: boolean; isLinkedLineAccount: boolean }
  ) {
    await this.request.post('/register', {
      email,
      password,
      companyUid: companyDomain,
      companyName,
      companyRepresentativeName: representativeName ?? null,
      token,
      registrationStatus,
    })
  }

  async resetPassword(companyDomain: string, email: string) {
    await this.request.post('/password-reset', { companyUid: companyDomain, email })
  }

  async login(companyDomain: string, email: string, password: string) {
    const response = await this.request.post<LoginResponse>('/auth', {
      companyUid: companyDomain,
      email,
      password,
    })
    return response.data
  }

  logout() {
    // TODO implement
  }

  async loadMe() {
    const response = await this.request.get<User>(`/users/me`)
    return response.data
  }

  async update(user: User) {
    await this.request.put('/users/me', {
      name: user.name,
    })
  }

  async updateRegistrationStatus(registrationStatus: {
    isContractee: boolean
    isInviteContractor: boolean
    isLinkedLineAccount: boolean
  }) {
    await this.request.put('/users/me', {
      registrationStatus,
    })
  }

  async changePassword(id: string, currentPassword: string, password: string) {
    await this.request.put(`/users/${id}/change-password`, {
      newPassword: password,
      oldPassword: currentPassword,
    })
  }

  async changeEmail(id: string, email: string) {
    await this.request.put(`/users/${id}/change-email`, {
      email,
    })
  }

  async loadNotificationSettings(userId: string) {
    const response = await this.request.get<{ notifications: Notification[] }>(`/users/${userId}/notifications`)
    return response.data.notifications
  }

  async getLineConnectUrl(userId: string) {
    const response = await this.request.get<string | undefined>(`/users/${userId}/line-url`, undefined, {
      notFound: () => undefined,
    })
    return response.data
  }

  async updateNotificationSetting(userId: string, notificationId: number, emailEnable: boolean, lineEnable: boolean) {
    await this.request.put(`/users/${userId}/notifications/${notificationId}`, { emailEnable, lineEnable })
  }

  async uploadUserImage(file: File) {
    const formData = new FormData()
    formData.append('icon', file)
    const response = await this.request.put<UploadUserImageResponse>('/users/me/upload-icon', formData)
    return { url: response.data.icon }
  }
}

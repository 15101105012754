import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import usePage from '../hooks/usePage'
import { DashboardActions } from '../store/dashboard'
import { DashboardSelectors } from '../store/dashboard/selectors'
import { FlexColumn } from '../components/atoms/layout/Flex'
import TransactionPhaseSearchResultTable from '../components/molecules/display/TransactionPhaseSearchResultTable'
import Alert from '../components/atoms/feedback/Alert'
import { DocumentSearchResultItem } from '../types/Document'

function filterDocuments(documents: DocumentSearchResultItem[], moneyspaceId?: string | null) {
  if (moneyspaceId) {
    return documents.filter((doc) => doc.contract?.moneyspace?.id === moneyspaceId)
  }
  return documents
}

export default function FactoringPage() {
  const { changeTitle } = usePage()
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const documents = useSelector(DashboardSelectors.factoringDocuments) ?? []
  const filteredDocuments = filterDocuments(documents, query.get('ms'))
  const dispatch = useDispatch()
  useEffect(() => {
    changeTitle('報酬の早払いが利用できる書類一覧')
  }, [changeTitle])
  useEffect(() => {
    dispatch(DashboardActions.fetchFactoringDocuments({ silent: false }))
  }, [dispatch])

  return (
    <FlexColumn>
      {filteredDocuments?.length > 0 ? (
        <TransactionPhaseSearchResultTable items={[]} />
      ) : (
        <Alert severity="success" label="報酬の早払いが利用できる書類はありません" />
      )}
    </FlexColumn>
  )
}

import { useSelector } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import {
  useTransactionApproveDialog,
  useTransactionPhaseDiffDialog,
  useTransactionRejectDialog,
} from '../../../hooks/useDialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { approvalButtonLabel } from './TransactionButtons'
import { Moneyspace } from '../../../types/Moneyspace'
import { ApprovalFlowState } from '../../../types/ApprovalFlow'
import Button from '../../atoms/inputs/Button'
import usePermission from '../../../hooks/usePermission'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { SessionSelectors } from '../../../store/session/selectors'
import { PhaseChangeLog, Transaction } from '../../../types/transaction'
import TransactionDiffView from '../../molecules/display/TransactionDiffView'
import useConsts from '../../../hooks/useConsts'

type TransactionPhaseDiffDialogProps = {
  moneyspace: Moneyspace
  transaction: Transaction
  previousPhase: Transaction
  changeLogs: PhaseChangeLog[]
  approvalFlowState?: ApprovalFlowState
  processable: boolean
}

export default function TransactionPhaseDiffDialog({
  moneyspace,
  transaction,
  previousPhase,
  changeLogs,
  approvalFlowState,
  processable,
}: TransactionPhaseDiffDialogProps) {
  const { state, close } = useTransactionPhaseDiffDialog()
  const { transactionPermissions } = usePermission()
  const { isContractee } = useMoneyspace()
  const user = useSelector(SessionSelectors.user)
  const transactionApproveDialog = useTransactionApproveDialog()
  const transactionRejectDialog = useTransactionRejectDialog()
  const { phaseName } = useConsts()
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="フェーズの明細差異を確認する"
      width="xl"
      actions={
        <FlexRow>
          {processable && (
            <>
              {transactionPermissions.approve(
                isContractee(moneyspace, user),
                transaction,
                moneyspace,
                user,
                approvalFlowState
              ) && (
                <Button onClick={() => transactionApproveDialog.open(moneyspace.id, transaction)}>
                  {approvalButtonLabel(transaction, phaseName)}
                </Button>
              )}
              {transactionPermissions.reject(
                isContractee(moneyspace, user),
                transaction,
                moneyspace,
                user,
                approvalFlowState
              ) && (
                <Button color="caution" onClick={() => transactionRejectDialog.open(transaction)}>
                  却下
                </Button>
              )}
            </>
          )}
        </FlexRow>
      }
    >
      <FlexColumn>
        <FlexRow>
          <Label text="二つのフェーズの明細差異を確認ください。" />
        </FlexRow>
        <FlexRow maxHeight={640}>
          <TransactionDiffView previousPhase={previousPhase} currentPhase={transaction} changeLogs={changeLogs} />
        </FlexRow>
      </FlexColumn>
    </Dialog>
  )
}

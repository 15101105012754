import ServiceLogoSvg from '../../../assets/service_logo.svg'
import Svg from '../../atoms/display/Svg'

type ServiceLogo = {
  width: number
  height: number
}

export default function ServiceLogo({ width, height }: ServiceLogo) {
  return <Svg src={ServiceLogoSvg} color="white" width={width} height={height} />
}

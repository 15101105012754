import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { Attachment } from '../../../types/Document'
import { TransactionActions } from '../../../store/transactions'
import Label from '../../atoms/display/Label'
import { useAttachmentDeleteDialog } from '../../../hooks/useDialog'
import useProcessing from '../../../hooks/useProcessing'

type AttachmentDeleteDialogProps = {
  contractId: string
  attachment: Attachment
}

export default function AttachmentDeleteDialog({ contractId, attachment }: AttachmentDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.deleteAttachment.typePrefix)
  const { state, close } = useAttachmentDeleteDialog()
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    startProcess()
    dispatch(
      TransactionActions.deleteAttachment({
        contractId,
        attachmentId: attachment.id,
      })
    )
  }, [attachment.id, contractId, dispatch, startProcess])
  return (
    <Dialog
      open={state !== undefined}
      title="添付ファイルを削除する"
      actions={
        <Button color="caution" onClick={handleDelete} disabled={processing}>
          削除
        </Button>
      }
      onClose={close}
    >
      <FlexColumn>
        <FlexRow>
          <Label text="添付ファイル名" />
          <Label text={attachment.name} />
        </FlexRow>
      </FlexColumn>
    </Dialog>
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type DocumentItemPropertyNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function DocumentItemPropertyNameField({
  register,
  error,
  disabled,
}: DocumentItemPropertyNameFieldProps) {
  return <TextField register={register} placeholder="○○様邸" size="md" error={error?.message} readonly={disabled} />
}

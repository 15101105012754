import MuiBox from '@mui/material/Box'
import { forwardRef, ReactNode } from 'react'
import { ErrorBoundary } from '@sentry/react'
import { useDeviceType } from '../../../hooks/useDeviceType'

export type FlexAlign = 'flex-start' | 'center' | 'flex-end' | 'baseline'
export type FlexJustify = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around'
type SpaceSize = 0 | 1 | 2 | 4 | 8 | 16 | 32 | 40
type MarginSize = 0 | 1 | 2 | 4 | 8 | 16 | 32 | 40
type PaddingSize = 0 | 1 | 2 | 4 | 8 | 16 | 32 | 40

type FlexProps = {
  id?: string
  align?: FlexAlign
  justify?: FlexJustify
  width?: number | string
  mbWidth?: number | string
  height?: number | string
  maxHeight?: number | string
  space?: SpaceSize
  wrap?: boolean
  grow?: boolean
  scroll?: boolean
  ma?: MarginSize
  mx?: MarginSize
  ml?: MarginSize
  mr?: MarginSize
  my?: MarginSize
  mt?: MarginSize
  mb?: MarginSize
  pa?: PaddingSize
  px?: PaddingSize
  py?: PaddingSize
  pl?: PaddingSize
  pr?: PaddingSize
  pt?: PaddingSize
  pb?: PaddingSize
  display?: boolean
  displayPrint?: boolean
  children?: ReactNode
  onLoad?: () => void
}

export const FlexColumn = forwardRef((props: FlexProps, ref) => {
  const { children } = props
  return (
    <Flex direction="column" {...props} ref={ref}>
      {children}
    </Flex>
  )
})

export const FlexRow = forwardRef((props: FlexProps, ref) => {
  const { children } = props
  return (
    <Flex direction="row" {...props} ref={ref}>
      {children}
    </Flex>
  )
})

type BaseFlexProps = {
  direction: 'row' | 'column'
} & FlexProps

const Flex = forwardRef(
  (
    {
      id,
      direction,
      align,
      justify,
      width,
      mbWidth,
      height,
      maxHeight,
      space = 1,
      wrap = false,
      grow = false,
      scroll = true,
      ma,
      mx = ma,
      my = ma,
      mt = my,
      mb = my,
      ml = mx,
      mr = mx,
      pa,
      px = pa,
      py = pa,
      pt = py,
      pb = py,
      pl = px,
      pr = px,
      display = true,
      displayPrint = true,
      children,
      onLoad,
    }: BaseFlexProps,
    ref
  ) => {
    const { pc } = useDeviceType()
    return (
      <ErrorBoundary
        onError={(error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        }}
      >
        <MuiBox
          id={id}
          sx={{
            display: display ? 'flex' : 'none',
            flexDirection: direction,
            alignItems: align,
            justifyContent: justify,
            width: pc ? width : mbWidth ?? width,
            minWidth: pc ? width : mbWidth ?? width,
            height,
            maxHeight,
            flexGrow: grow ? 1 : 0,
            overflowY: scroll ? 'auto' : undefined,
            flexWrap: wrap ? 'wrap' : 'nowrap',
            gap: space,
            mt,
            mb,
            ml,
            mr,
            pt,
            pb,
            pl,
            pr,
          }}
          ref={ref}
          onLoad={onLoad}
          displayPrint={displayPrint ? 'block' : 'none'}
        >
          {children}
        </MuiBox>
      </ErrorBoundary>
    )
  }
)

import MuiPagination from '@mui/material/Pagination'
import { ChangeEvent, useCallback } from 'react'

type PaginationProps = {
  count: number
  size: number
  page: number
  onChange?: (page: number) => void
}

export default function Pagination({ count, size, page, onChange }: PaginationProps) {
  const handleChangePage = useCallback(
    (e: ChangeEvent<unknown>, p: number) => {
      if (onChange) {
        onChange(p)
      }
    },
    [onChange]
  )
  const numberOfPage = count > 0 ? Math.floor((count - 1) / size) + 1 : 0
  return <MuiPagination count={numberOfPage} page={page} onChange={handleChangePage} />
}

import { useCallback } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import useDocument from '../../../hooks/useDocument'
import { Document } from '../../../types/Document'
import { useDocumentDeleteDialog } from '../../../hooks/useDialog'
import useProcessing from '../../../hooks/useProcessing'
import { DocumentsActions } from '../../../store/documents'

type DocumentDeleteDialogProps = {
  document?: Document
}

export default function DocumentDeleteDialog({ document }: DocumentDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(DocumentsActions.deleteDocument.typePrefix)
  const { close } = useDocumentDeleteDialog()
  const { deleteDocument } = useDocument()
  const handleDelete = useCallback(() => {
    if (document) {
      startProcess()
      deleteDocument(document.id)
    }
  }, [document, startProcess, deleteDocument])
  if (document) {
    return (
      <Dialog
        open
        onClose={close}
        title="書類の削除"
        actions={
          <Button color="caution" onClick={handleDelete} disabled={processing}>
            削除
          </Button>
        }
      >
        <FlexColumn space={4}>
          <FlexColumn>
            <FlexRow>
              <Label text="削除する書類" />
              <Label text={document?.name} />
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <Label text="この書類を削除しますか？" />
          </FlexColumn>
        </FlexColumn>
      </Dialog>
    )
  }
  return <></>
}

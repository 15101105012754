import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type ContractNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
}

export default function ContractNameField({ register, error }: ContractNameFieldProps) {
  return <TextField register={register} error={error?.message} />
}

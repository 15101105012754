import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FieldError } from 'react-hook-form'
import { useEmailChangeDialog } from '../../../hooks/useDialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { SessionActions } from '../../../store/session'
import Label from '../../atoms/display/Label'
import EmailField from '../../molecules/inputs/EmailField'
import useProcessing from '../../../hooks/useProcessing'

type ChangeEmailParams = {
  email: string
}

type EmailChangeDialogProps = {
  email: string
}

export default function EmailChangeDialog({ email }: EmailChangeDialogProps) {
  const { processing, startProcess } = useProcessing(SessionActions.changeEmail.typePrefix)
  const { state, close } = useEmailChangeDialog()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    email: rules.email.required(),
  })
  const { handleSubmit, register, errors } = useForm<ChangeEmailParams>(schema, { email })
  const dispatch = useDispatch()
  const handleChangeEmail = useCallback(
    (values: ChangeEmailParams) => {
      startProcess()
      dispatch(SessionActions.changeEmail({ email: values.email }))
    },
    [dispatch, startProcess]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="メールアドレス変更"
      actions={
        <Button type="submit" disabled={processing}>
          変更
        </Button>
      }
      onSubmit={handleSubmit(handleChangeEmail)}
    >
      <FlexColumn>
        <FormItem label="メールアドレス" required>
          <EmailField register={register('email')} error={errors.email as FieldError} />
        </FormItem>
        <Label text="変更後のメールアドレスに確認用のURLをお送りします" />
      </FlexColumn>
    </Dialog>
  )
}

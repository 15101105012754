import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { SystemSelectors } from '../store/system/selectors'
import systemSlice from '../store/system'

export default function useProcessing(actionName: string) {
  const dispatch = useDispatch()
  const processing = useSelector(SystemSelectors.processing)
  const startProcess = useCallback(() => {
    dispatch(systemSlice.actions.setProcessingTarget({ actionName }))
  }, [actionName, dispatch])
  return { processing, startProcess }
}

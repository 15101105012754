import * as yup from 'yup'
import useDay from './useDay'

export default function useYup() {
  const { formatDate } = useDay()
  yup.setLocale({
    mixed: {
      default: () => `不正な入力です`,
      required: ({ path }) => `${path}は必須項目です`,
      oneOf: ({ path, values }: { path: string; values: string[] }) =>
        `${path}は、次のいずれかを入力してください[${values.join(', ')}]`,
      notOneOf: ({ path, values }: { path: string; values: string[] }) =>
        `${path}は、次のリスト以外を入力してください[${values.join(', ')}]`,
    },
    string: {
      email: () => 'このメールアドレスは有効ではありません',
      max: ({ max }) => `${max}文字以下で入力してください`,
      min: ({ min }) => `${min}文字以上入力してください`,
      length: ({ length }) => `${length}文字入力してください`,
      matches: () => `入力の形式が正しくありません`,
      url: () => `不正なURLです`,
      trim: () => `行頭、行末に空白文字が含まれています`,
      lowercase: () => `小文字英字で入力してください`,
      uppercase: () => `大文字英字で入力してください`,
    },
    number: {
      min: ({ min }) => `${min}以上の数値を入力してください`,
      max: ({ max }) => `${max}以下の数値を入力してください`,
      lessThan: ({ less }) => `${less}より小さい数字を入力してください`,
      moreThan: ({ more }) => `${more}より大きい数字を入力してください`,
      positive: () => `正数を入力してください`,
      negative: () => `負数を入力してください`,
      integer: () => `整数を入力してください`,
    },
    date: {
      min: ({ min }) => `${formatDate(min)}より後の日付を入力してください`,
      max: ({ max }) => `${formatDate(max)}より前の日付を入力してください`,
    },
    object: {
      noUnknown: () => `不正なキーが含まれています`,
    },
    array: {
      min: ({ min }) => `${min}件以上入力してください`,
      max: ({ max }) => `${max}件以下入力してください`,
    },
  })

  return yup
}

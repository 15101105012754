import MuiLink from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import { Component } from '../../../types/react'

type LinkProps = {
  href?: string
  download?: boolean
  children: Component
  onClick?: () => void
}

export default function Link({ href, download, children, onClick }: LinkProps) {
  const external =
    href === undefined || href?.startsWith('http') || href?.startsWith('mailto:') || href?.startsWith('tel:')
  const component = external ? 'a' : RouterLink
  const target = external && !download ? '_blank' : '_self'
  return (
    <MuiLink
      component={component}
      to={href}
      href={href}
      target={target}
      sx={{ color: 'black.main', textDecorationColor: 'inherit', cursor: 'pointer' }}
      onClick={onClick}
    >
      {children}
    </MuiLink>
  )
}

import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const toolsSelector = (state: StoreState) => state.tools
export const ToolsSelectors = {
  documentImporterMoneyspaceContracts: createSelector(
    [toolsSelector],
    (state) => state.documentImporter.moneyspaceContracts
  ),
  transactionImporterMoneyspaceTransactions: createSelector(
    [toolsSelector],
    (state) => state.transactionImporter.moneyspaceTransactions
  ),
  ocrResult: createSelector([toolsSelector], (state) => state.ocrResult),
}

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useMessageDeleteDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import useScheduleExpression from '../../../hooks/useScheduleExpression'
import { ChatActions } from '../../../store/chat'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import { reservedMessageSettingToText } from './ReservedMessageDialog'

export default function MessageDeleteDialog() {
  const { state, close } = useMessageDeleteDialog()
  const { parse } = useScheduleExpression()
  const dispatch = useDispatch()

  const handleDelete = useCallback(() => {
    if (state) {
      dispatch(
        ChatActions.deleteReservedMessage({
          reservedMessageId: state.message.id,
        })
      )
    }
  }, [state, dispatch])

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="予約メッセージ削除"
      actions={
        <Button color="caution" onClick={handleDelete}>
          削除
        </Button>
      }
    >
      <FlexColumn>
        <Label text="予約送信を削除しますか？" />
        {state && <Label text={reservedMessageSettingToText(parse(state.message.scheduleExpression))} />}
      </FlexColumn>
    </Dialog>
  )
}

import { useSelector } from 'react-redux'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { SessionSelectors } from '../../store/session/selectors'
import usePath from '../../hooks/usePath'

type LoggedInPageProps = {
  children: ReactNode
}

export default function LoggedInPage({ children }: LoggedInPageProps) {
  const loggedIn = useSelector(SessionSelectors.loggedIn)
  const user = useSelector(SessionSelectors.user)
  const location = useLocation()
  const {
    login,
    registerContracteeInvitation,
    registerContracteeInvitationCompleted,
    registerContracteeAddress,
    registerContracteePhone,
    registerContracteeInvoiceLicense,
    registerContracteeBank,
    registerContracteeLine,
    registerContracteeWellcome,
  } = usePath()
  if (loggedIn) {
    if (user?.registrationStatus?.isContractee) {
      if ([registerContracteeInvitation(), registerContracteeInvitationCompleted()].includes(location.pathname)) {
        return <>{children}</>
      }
      if (!user.registrationStatus.isInviteContractor) {
        return <Navigate to={registerContracteeInvitation()} />
      }
      if (
        [
          registerContracteeAddress(),
          registerContracteePhone(),
          registerContracteeInvoiceLicense(),
          registerContracteeBank(),
          registerContracteeLine(),
          registerContracteeWellcome(),
        ].includes(location.pathname)
      ) {
        return <>{children}</>
      }
      if (!user.registrationStatus.isLinkedLineAccount) {
        return <Navigate to={registerContracteeAddress()} />
      }
    }
    return <>{children}</>
  }
  return <Navigate to={login()} />
}

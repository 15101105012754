import { useCallback } from 'react'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import useConsts from '../../../hooks/useConsts'
import useDay from '../../../hooks/useDay'
import Checkbox from '../../atoms/inputs/Checkbox'
import SortableTableCell from './SortableTableCell'
import { SortDirection } from '../../../types/System'
import useRouter from '../../../hooks/useRouter'
import { TransactionSearchResultItem } from '../../../types/transaction'
import TransactionStatusLabel from './TransactionStatusLabel'

type onCheckFunc = (transaction: TransactionSearchResultItem, checked: boolean) => void

type TransactionSearchResultSortableTableProps = {
  transactions: TransactionSearchResultItem[]
  checkedTransactions?: TransactionSearchResultItem[]
  sortColumn?: string
  sortDirection?: SortDirection
  onSort: (name: string, sortDirection: SortDirection) => void
  onChangeChecked?: (documents: TransactionSearchResultItem[]) => void
}

function TransactionRow({
  transaction,
  onCheck,
  checked,
}: {
  transaction: TransactionSearchResultItem
  onCheck?: onCheckFunc
  checked: boolean
}) {
  const { phaseName } = useConsts()
  const { transaction: transactionPage } = useRouter()
  const { formatDate } = useDay()
  const { moneyspace } = transaction
  const handleClick = useCallback(() => {
    transactionPage(transaction.id)
  }, [transaction.id, transactionPage])
  return (
    <TableRow onClick={handleClick}>
      {onCheck && (
        <TableCell size="xxs" align="center">
          <Checkbox onChange={(checkedState) => onCheck(transaction, checkedState)} checked={checked} />
        </TableCell>
      )}
      <TableCell size="sm">{moneyspace.name}</TableCell>
      <TableCell size="sm">{transaction.name}</TableCell>
      <TableCell size="xs" align="center">
        {phaseName(transaction.currentPhase)}
      </TableCell>
      <TableCell size="xs" align="center">
        <TransactionStatusLabel transaction={transaction} />
      </TableCell>
      <TableCell size="xs" align="right">
        <Label
          text={transaction.details.map((detail) => detail.amount).reduce((value1, value2) => value1 + value2, 0)}
          format="amount"
        />
      </TableCell>
      <TableCell size="xs" align="center">
        <Label text={transaction.paymentDate ? formatDate(transaction.paymentDate) : ''} />
      </TableCell>
      <TableCell size="xs" align="center">
        <Label text={transaction.closingDate ? formatDate(transaction.closingDate) : ''} />
      </TableCell>
      <TableCell size="xs">
        <Label text={transaction.moneyspace.contractee?.name ?? ''} />
      </TableCell>
      <TableCell size="xs">
        <Label text={transaction.moneyspace.contractor?.name ?? ''} />
      </TableCell>
    </TableRow>
  )
}

export default function TransactionSearchResultSortableTable({
  transactions,
  checkedTransactions = [],
  sortColumn,
  sortDirection,
  onSort,
  onChangeChecked,
}: TransactionSearchResultSortableTableProps) {
  const handleClickSort = (columnName: string) => {
    if (sortColumn === columnName) {
      onSort(sortColumn, sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      onSort(columnName, 'desc')
    }
  }
  const handleCheckAll = useCallback(
    (checked: boolean) => {
      if (onChangeChecked) {
        if (checked) {
          onChangeChecked(transactions)
        } else {
          onChangeChecked([])
        }
      }
    },
    [transactions, onChangeChecked]
  )
  const handleCheck = useCallback(
    (item: TransactionSearchResultItem, checked: boolean) => {
      if (onChangeChecked) {
        if (checked) {
          onChangeChecked(checkedTransactions.concat(item))
        } else {
          onChangeChecked(checkedTransactions.filter((checkedItem) => checkedItem !== item))
        }
      }
    },
    [checkedTransactions, onChangeChecked]
  )
  return (
    <Table
      header={
        <TableRow>
          {onChangeChecked && (
            <TableCell header size="xxs">
              <Checkbox
                onChange={handleCheckAll}
                indeterminate={checkedTransactions?.length > 0 && checkedTransactions?.length !== transactions.length}
                checked={checkedTransactions.length === transactions.length}
              />
            </TableCell>
          )}
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="ms"
            label="マネースペース"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="sm"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="name"
            label="取引名"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="sm"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="phase"
            label="フェーズ"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="status"
            label="ステータス"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="totalAmount"
            label="合計金額"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="paymentDate"
            label="支払期限"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="closingDate"
            label="締め日"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="contractee"
            label="発注者"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="contractor"
            label="受注者"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
        </TableRow>
      }
    >
      {transactions.map((transaction) => (
        <TransactionRow
          key={transaction.id}
          transaction={transaction}
          onCheck={onChangeChecked ? handleCheck : undefined}
          checked={checkedTransactions.includes(transaction)}
        />
      ))}
    </Table>
  )
}

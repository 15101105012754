import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { useTransactionDeleteDialog } from '../../../hooks/useDialog'
import useProcessing from '../../../hooks/useProcessing'
import { Transaction } from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import usePath from '../../../hooks/usePath'

type TransactionDeleteDialogProps = {
  transaction?: Transaction
}

export default function TransactionDeleteDialog({ transaction }: TransactionDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.deleteTransaction.typePrefix)
  const { close } = useTransactionDeleteDialog()
  const dispatch = useDispatch()
  const { moneyspace: moneyspacePage } = usePath()
  const handleDelete = useCallback(() => {
    if (transaction) {
      startProcess()
      dispatch(
        TransactionActions.deleteTransaction({
          transactionId: transaction.id,
          getRedirectPath: () => moneyspacePage(transaction.moneyspace.id),
        })
      )
    }
  }, [dispatch, moneyspacePage, startProcess, transaction])
  if (transaction) {
    return (
      <Dialog
        open
        onClose={close}
        title="取引の削除"
        actions={
          <Button color="caution" disabled={processing} onClick={handleDelete}>
            削除
          </Button>
        }
      >
        <FlexColumn space={4}>
          <FlexColumn>
            <FlexRow>
              <Label text="削除する取引" />
              <Label text={transaction?.name} />
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <Label text="この取引を削除しますか？" />
          </FlexColumn>
        </FlexColumn>
      </Dialog>
    )
  }
  return <></>
}

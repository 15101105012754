import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import MuiSelect from '@mui/material/Select'
import MuiMenuItem from '@mui/material/MenuItem'
import MuiFormControl from '@mui/material/FormControl/FormControl'
import { FormHelperText } from '@mui/material'

export type SelectItem = {
  value?: number | string
  label: string
}

type SelectSize = 'xs' | 'sm' | 'md' | 'lg'

const width = (size?: SelectSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'md':
      return 360
    case 'lg':
      return 480
    default:
      return '100%'
  }
}

type SelectControlProps<T extends FieldValues> = {
  label?: string
  name: FieldPath<T>
  items: SelectItem[]
  blank?: boolean
  size?: SelectSize
  control: Control<T>
  disabled?: boolean
  error?: string
}

export default function SelectControl<T extends FieldValues>({
  label,
  name,
  items,
  blank,
  size,
  control,
  disabled,
  error,
}: SelectControlProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MuiFormControl error={error !== undefined}>
          <MuiSelect
            label={label}
            variant="outlined"
            size="small"
            sx={{ width: width(size) }}
            disabled={disabled}
            {...field}
            onChange={(e) => {
              field.onChange(e.target.value)
            }}
          >
            {blank && <MuiMenuItem key="blank" value="" style={{ height: '36px' }} />}
            {items.map((item) => (
              <MuiMenuItem key={item.value} value={item.value}>
                {item.label}
              </MuiMenuItem>
            ))}
          </MuiSelect>
          {error && <FormHelperText>{error}</FormHelperText>}
        </MuiFormControl>
      )}
    />
  )
}

import Decimal from 'decimal.js-light'
import { Transaction, TransactionDetail } from '../types/transaction'

function sum(prev: number, current: number) {
  return prev + current
}

function calcDetailAmount(detail: TransactionDetail) {
  if (detail.unitPrice && detail.quantity) {
    return Math.floor(new Decimal(detail.unitPrice).times(detail.quantity).toNumber())
  }
  return 0
}

export default function useTransactionAmounts() {
  return {
    calcSimpleAmount: (details: TransactionDetail[]) =>
      details.map((detail) => calcDetailAmount(detail)).reduce(sum, 0),
    calcInstallmentAccumulated: (transaction: Transaction) => {
      if (transaction.installment && transaction.times && transaction.times > 1) {
        let accumulated = 0
        for (let index = 0; index < transaction.times - 1; index += 1) {
          const details = transaction.installment.details.times[index + 1]
          accumulated += details.reduce((total, detail) => total + detail.paid, 0)
        }
        return accumulated
      }
      return 0
    },
  }
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Moneyspace } from '../../../types/Moneyspace'
import useProcessing from '../../../hooks/useProcessing'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import { useMoneyspaceClientEditDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import CompanyForm from '../company/CompanyForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import Button from '../../atoms/inputs/Button'

type MoneyspaceClientEditDialogProps = {
  moneyspace: Moneyspace
}

type ClientSaveParams = {
  name: string
  representativeName?: string
  representativeRole?: string
  bankName?: string
  bankCode?: string
  branchName?: string
  branchCode?: string
  accountType: number
  accountNumber?: string
  accountOwner?: string
  payAfterHolidays: number
}

export default function MoneyspaceClientEditDialog({ moneyspace }: MoneyspaceClientEditDialogProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.saveMoneyspace.typePrefix)
  const { state, close } = useMoneyspaceClientEditDialog()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.companyName.required(),
    representativeName: rules.representativeName,
    representativeRole: rules.representativeRole,
    zipCode: rules.zipCode,
    address: rules.address,
    bankName: rules.bankName,
    bankCode: rules.bankCode,
    branchName: rules.branchName,
    branchCode: rules.branchCode,
    accountNumber: rules.accountNumber,
    accountOwner: rules.accountOwner,
  })
  const { handleSubmit, register, control, errors, getValues, setValue } = useForm<ClientSaveParams>(
    schema,
    moneyspace.client ? { ...moneyspace.client, payAfterHolidays: moneyspace.client.payAfterHolidays ? 1 : 0 } : {}
  )
  const handleSave = useCallback(
    (params: ClientSaveParams) => {
      const client = {
        ...params,
        payAfterHolidays: params.payAfterHolidays === 1,
      }
      startProcess()
      dispatch(
        MoneyspacesActions.saveMoneyspace({
          moneyspace: {
            ...moneyspace,
            client,
          },
        })
      )
    },
    [dispatch, moneyspace, startProcess]
  )

  return (
    <Dialog
      open={state !== undefined}
      title="取引先情報の設定"
      onClose={close}
      actions={
        <Button type="submit" disabled={processing}>
          設定
        </Button>
      }
      onSubmit={handleSubmit(handleSave)}
    >
      <CompanyForm register={register} control={control} errors={errors} getValues={getValues} setValue={setValue} />
    </Dialog>
  )
}

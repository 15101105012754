import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { FieldError } from 'react-hook-form'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import { useUserEditDialog } from '../../../hooks/useDialog'
import FullNameField from '../../molecules/inputs/FullNameField'
import FormItem from '../../molecules/inputs/FormItem'
import EmailField from '../../molecules/inputs/EmailField'
import RoleSelect from '../../molecules/inputs/RoleSelect'
import Button from '../../atoms/inputs/Button'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { CompanyActions } from '../../../store/company'
import { User } from '../../../types/User'
import { SessionSelectors } from '../../../store/session/selectors'
import useProcessing from '../../../hooks/useProcessing'

type UserEditDialog = {
  user: User
}

export default function UserEditDialog({ user }: UserEditDialog) {
  const { processing, startProcess } = useProcessing(CompanyActions.updateUser.typePrefix)
  const sessionUser = useSelector(SessionSelectors.user)
  const { state, close } = useUserEditDialog()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.userName.required(),
    email: rules.email.required(),
  })
  const { handleSubmit, register, control, errors } = useForm<User>(schema, user)
  const dispatch = useDispatch()
  const handleUpdateUser = useCallback(
    (values: User) => {
      startProcess()
      dispatch(
        CompanyActions.updateUser({
          id: user.id,
          name: values.name === user.name ? undefined : values.name,
          email: values.email === user.email ? undefined : values.email,
          role: values.role === user.role ? undefined : values.role,
        })
      )
    },
    [dispatch, startProcess, user.email, user.id, user.name, user.role]
  )
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="ユーザーの編集"
      onSubmit={handleSubmit(handleUpdateUser)}
      actions={
        <Button type="submit" disabled={processing}>
          保存
        </Button>
      }
    >
      <FlexColumn>
        <FormItem label="ユーザ名" required>
          <FullNameField register={register('name')} error={errors.name as FieldError} />
        </FormItem>
        <FormItem label="メールアドレス" required>
          <EmailField register={register('email')} error={errors.email as FieldError} />
        </FormItem>
        <FormItem label="権限">
          <RoleSelect name="role" control={control} userRole={sessionUser?.role} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import { SessionSelectors } from '../../store/session/selectors'
import usePage from '../../hooks/usePage'
import Button from '../../components/atoms/inputs/Button'
import ProfileForm from '../../components/organisms/user/ProfileForm'
import { useUserImageSettingDialog } from '../../hooks/useDialog'
import UserImage from '../../components/molecules/display/UserImage'
import NotificationSettingsForm from '../../components/organisms/user/NotificationSettingsForm'
import { SessionActions } from '../../store/session'
import Label from '../../components/atoms/display/Label'
import Image from '../../components/atoms/display/Image'

export default function ProfilePage() {
  const { changeTitle } = usePage()
  const user = useSelector(SessionSelectors.user)
  const lineConnectUrl = useSelector(SessionSelectors.lineConnectUrl)
  const userImageSettingDialog = useUserImageSettingDialog()
  const dispatch = useDispatch()
  useEffect(() => {
    changeTitle('個人設定')
  }, [changeTitle])
  useEffect(() => {
    if (user && !user.lineId) {
      dispatch(SessionActions.getLineConnectUrl())
    }
  }, [dispatch, user])
  const handleConnectLine = useCallback(() => {
    window.open(lineConnectUrl)
  }, [lineConnectUrl])
  if (user) {
    return (
      <FlexColumn>
        <FlexRow space={8} wrap>
          <FlexColumn align="center">
            <UserImage url={user.icon} />
            <Button size="lg" onClick={() => userImageSettingDialog.open(user)}>
              プロフィール画像変更
            </Button>
          </FlexColumn>
          <FlexColumn space={4}>
            <ProfileForm user={user} />
            <FlexColumn space={0}>
              <Label text="LINE連携すると、各通知をLINEで受け取れます。" />
              {user.lineId ? (
                <Label text="LINE連携済み" />
              ) : (
                <Button onClick={handleConnectLine}>
                  <FlexRow align="center">
                    <Image url="/LINE.png" alt="LINE" width={24} height={24} />
                    <Label text="LINE連携" />
                  </FlexRow>
                </Button>
              )}
            </FlexColumn>
            <NotificationSettingsForm disabledLine={!user.lineId} />
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    )
  }
  return <></>
}

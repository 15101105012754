import Stepper from '../../atoms/navigation/Stepper'
import {
  Transaction,
  TransactionPhase,
  TransactionSearchResultItem,
  TransactionStatusClosed,
} from '../../../types/transaction'
import useConsts from '../../../hooks/useConsts'

type TransactionStepperProps = {
  transaction: Transaction | TransactionSearchResultItem
  selectedPhase?: TransactionPhase | number
  onSelectPhase: (phase: TransactionPhase) => void
}

export default function TransactionStepper({ transaction, selectedPhase, onSelectPhase }: TransactionStepperProps) {
  const { phaseName } = useConsts()

  return (
    <Stepper
      active={transaction.phases.findIndex((phase) => phase === transaction.currentPhase)}
      steps={transaction.phases.map((phase) => ({
        id: phase,
        label: phaseName(phase),
        completed:
          phase < transaction.currentPhase ||
          (phase === transaction.currentPhase && transaction.status === TransactionStatusClosed),
        badge: phase === selectedPhase,
        onClick: () => onSelectPhase(phase),
      }))}
    />
  )
}

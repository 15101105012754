import { useSelector } from 'react-redux'
import Menu from '../../atoms/navigation/Menu'
import MenuItem from '../../atoms/navigation/MenuItem'
import Divider from '../../atoms/display/Divider'
import {
  AccountsIcon,
  ApprovalFlowIcon,
  ClientIcon,
  CompanyIcon,
  LogoutIcon,
  SettingsIcon,
} from '../../atoms/display/Icons'
import { CompanySelectors } from '../../../store/company/selectors'
import Chip from '../../atoms/display/Chip'

type AccountMenuProps = {
  target?: Element
  onClose: () => void
  onLogout: () => void
  onSettingsCompany: () => void
  onSettingsUsers: () => void
  onSettingsClients: () => void
  onSettingsProfile: () => void
  onSettingsApprovalFlows: () => void
}

export default function AccountMenu({
  target,
  onClose,
  onLogout,
  onSettingsCompany,
  onSettingsUsers,
  onSettingsClients,
  onSettingsProfile,
  onSettingsApprovalFlows,
}: AccountMenuProps) {
  const countInvitations = useSelector(CompanySelectors.countInvitations)
  const handleSelectItem = (callback: () => void) => () => {
    callback()
    onClose()
  }
  return (
    <Menu target={target} onClose={onClose}>
      <MenuItem icon={<CompanyIcon />} label="企業情報管理" onClick={handleSelectItem(onSettingsCompany)} />
      <MenuItem icon={<AccountsIcon />} label="ユーザー管理" onClick={handleSelectItem(onSettingsUsers)} />
      <MenuItem
        icon={<ClientIcon />}
        label="取引先管理"
        right={countInvitations > 0 ? <Chip label={countInvitations} color="error" size="small" /> : undefined}
        onClick={handleSelectItem(onSettingsClients)}
      />
      <MenuItem
        icon={<ApprovalFlowIcon />}
        label="承認フロー設定"
        onClick={handleSelectItem(onSettingsApprovalFlows)}
      />
      <Divider />
      <MenuItem icon={<SettingsIcon />} label="個人設定" onClick={handleSelectItem(onSettingsProfile)} />
      <Divider />
      <MenuItem icon={<LogoutIcon />} label="ログアウト" onClick={onLogout} />
    </Menu>
  )
}

import { ReactNode, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '../atoms/surfaces/AppBar'
import { FlexColumn, FlexRow } from '../atoms/layout/Flex'
import IconButton from '../atoms/inputs/IconButton'
import { AccountIcon, MenuIcon } from '../atoms/display/Icons'
import Label from '../atoms/display/Label'
import AccountMenu from '../molecules/navigation/AccountMenu'
import { SessionActions } from '../../store/session'
import usePage from '../../hooks/usePage'
import useRouter from '../../hooks/useRouter'
import { SessionSelectors } from '../../store/session/selectors'
import Avatar from '../atoms/display/Avatar'
import useSidebar from '../../hooks/useSidebar'
import { useDeviceType } from '../../hooks/useDeviceType'
import useString from '../../hooks/useString'
import Badge from '../atoms/display/Badge'
import { CompanySelectors } from '../../store/company/selectors'

type SiteNavHeaderProps = {}

function BadgeWrapper(props: { badge: boolean; children: ReactNode }) {
  const { badge, children } = props
  if (badge) {
    return (
      <Badge color="error" variant="dot">
        {children}
      </Badge>
    )
  }
  return <>{children}</>
}

export default function SiteNavHeader({}: SiteNavHeaderProps) {
  const { open } = useSidebar()
  const { mobile } = useDeviceType()
  const countInvitations = useSelector(CompanySelectors.countInvitations)
  const [accountMenuTarget, setAccountMenuTarget] = useState<Element | undefined>(undefined)
  const user = useSelector(SessionSelectors.user)
  const { title, subTitle } = usePage()
  const { truncate } = useString()
  const { settingsCompany, settingsUsers, settingsClients, settingsProfile, settingsApprovalFlows } = useRouter()
  const dispatch = useDispatch()
  const handleAccountMenu = useCallback(
    (target: Element) => {
      setAccountMenuTarget(target)
    },
    [setAccountMenuTarget]
  )
  const handleLogout = useCallback(() => {
    dispatch(SessionActions.logout())
  }, [dispatch])

  return (
    <AppBar color="inherit" position="static">
      <FlexRow height={64} justify="space-between" align="center" grow>
        <FlexRow align="center">
          {mobile && (
            <IconButton label="menu" onClick={open}>
              <MenuIcon />
            </IconButton>
          )}
          <FlexRow align="baseline" wrap space={0}>
            {title && (
              <FlexRow pr={1}>
                <Label text={mobile ? truncate(title, 7) : title} variant="title" />
              </FlexRow>
            )}
            {subTitle && <Label text={mobile ? truncate(subTitle, 12) : subTitle} variant="subtitle" />}
          </FlexRow>
        </FlexRow>
        <FlexColumn align="center" width={56}>
          <IconButton label="account" onClick={handleAccountMenu}>
            <BadgeWrapper badge={countInvitations > 0}>
              {user?.icon ? <Avatar url={user.icon} alt="User Icon" /> : <AccountIcon />}
            </BadgeWrapper>
          </IconButton>
          <AccountMenu
            target={accountMenuTarget}
            onSettingsCompany={settingsCompany}
            onSettingsUsers={settingsUsers}
            onSettingsClients={settingsClients}
            onSettingsProfile={settingsProfile}
            onSettingsApprovalFlows={settingsApprovalFlows}
            onClose={() => setAccountMenuTarget(undefined)}
            onLogout={handleLogout}
          />
        </FlexColumn>
      </FlexRow>
    </AppBar>
  )
}

import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import FormItem from '../../molecules/inputs/FormItem'
import TextField from '../../atoms/inputs/TextField'
import Button from '../../atoms/inputs/Button'
import { CsvImportSetting } from '../../../types/Company'
import { CompanySelectors } from '../../../store/company/selectors'
import { CompanyActions } from '../../../store/company'
import SelectControl from '../../atoms/inputs/SelectControl'

type TransactionImportSettingDialogProps = {
  open: boolean
  onClose: () => void
}

export default function TransactionImportSettingDialog(props: TransactionImportSettingDialogProps) {
  const { open, onClose } = props
  const csvImportSetting = useSelector(CompanySelectors.csvImportSetting)
  const dispatch = useDispatch()
  const form = useForm<CsvImportSetting>({
    defaultValues: csvImportSetting,
  })
  const { fields, append, remove } = useFieldArray({ control: form.control, name: 'skippingTargetValues' })

  useEffect(() => {
    if (fields.length === 0) {
      append({ value: '' })
    }
  }, [fields, append])

  const handleSave = useCallback(
    (params: CsvImportSetting) => {
      dispatch(CompanyActions.saveCsvImportSetting(params))
      onClose()
    },
    [dispatch, onClose]
  )

  return (
    <Dialog
      open={open}
      title="取引のインポート設定"
      onClose={onClose}
      onSubmit={form.handleSubmit(handleSave)}
      actions={
        <FlexRow>
          <Button onClick={onClose} variant="outlined">
            キャンセル
          </Button>
          <Button type="submit">保存</Button>
        </FlexRow>
      }
    >
      <FlexColumn>
        <Label text="CSVの列名と取引項目の対応を設定してください" />
        <FormItem label="取引のキーとなる項目">
          <SelectControl
            name="bundlingKeyField"
            items={[
              { value: 'name', label: '取引名' },
              { value: 'custom_prop_1', label: 'カスタム項目1' },
              { value: 'custom_prop_2', label: 'カスタム項目2' },
              { value: 'custom_prop_3', label: 'カスタム項目3' },
              { value: 'custom_prop_4', label: 'カスタム項目4' },
            ]}
            control={form.control}
          />
        </FormItem>
        <Label text="取引項目" variant="subtitle" />
        <FormItem label="仕入先コードの列名">
          <TextField register={form.register('externalId')} />
        </FormItem>
        <FormItem label="取引名の列名">
          <TextField register={form.register('name')} />
        </FormItem>
        <FormItem label="消費税の表示の列名">
          <TextField register={form.register('isTaxIn')} />
        </FormItem>
        <FormItem label="発行日の列名">
          <TextField register={form.register('publishedAt')} />
        </FormItem>
        <FormItem label="備考の列名">
          <TextField register={form.register('note')} />
        </FormItem>
        <FormItem label="支払日の列名">
          <TextField register={form.register('paymentDate')} />
        </FormItem>
        <FormItem label="締め日の列名">
          <TextField register={form.register('closingDate')} />
        </FormItem>
        <FormItem label="納入期間開始日の列名">
          <TextField register={form.register('deliveryDateFrom')} />
        </FormItem>
        <FormItem label="納入期間終了日の列名">
          <TextField register={form.register('deliveryDateTo')} />
        </FormItem>
        <FormItem label="担当者1IDの列名">
          <TextField register={form.register('picId')} />
        </FormItem>
        <FormItem label="担当者2IDの列名">
          <TextField register={form.register('picSubId')} />
        </FormItem>
        <FormItem label="現場住所の列名">
          <TextField register={form.register('siteAddress')} />
        </FormItem>
        <FormItem label="カスタム項目1の列名">
          <TextField register={form.register('customProp1')} />
        </FormItem>
        <FormItem label="カスタム項目2の列名">
          <TextField register={form.register('customProp2')} />
        </FormItem>
        <FormItem label="カスタム項目3の列名">
          <TextField register={form.register('customProp3')} />
        </FormItem>
        <FormItem label="カスタム項目4の列名">
          <TextField register={form.register('customProp4')} />
        </FormItem>
        <Label text="明細項目" variant="subtitle" />
        <FormItem label="デフォルト項目の列名">
          <TextField register={form.register('detailDefaultProp')} />
        </FormItem>
        <FormItem label="カスタム項目1の列名">
          <TextField register={form.register('detailCustomProp1')} />
        </FormItem>
        <FormItem label="カスタム項目2の列名">
          <TextField register={form.register('detailCustomProp2')} />
        </FormItem>
        <FormItem label="単価の列名">
          <TextField register={form.register('detailUnitPrice')} />
        </FormItem>
        <FormItem label="数量の列名">
          <TextField register={form.register('detailQuantity')} />
        </FormItem>
        <FormItem label="単位の列名">
          <TextField register={form.register('detailUnit')} />
        </FormItem>
        <FormItem label="税区分の列名">
          <TextField register={form.register('detailTaxBucketId')} />
        </FormItem>
        <FormItem label="除外対象の列名">
          <SelectControl
            name="skippingKeyField"
            items={[
              { label: `${form.getValues().externalId}`, value: 'external_id' },
              { label: `${form.getValues().name}`, value: 'name' },
              { label: `${form.getValues().isTaxIn}`, value: 'is_tax_in' },
              { label: `${form.getValues().publishedAt}`, value: 'published_at' },
              { label: `${form.getValues().note}`, value: 'note' },
              { label: `${form.getValues().paymentDate}`, value: 'payment_date' },
              { label: `${form.getValues().closingDate}`, value: 'closing_date' },
              { label: `${form.getValues().deliveryDateFrom}`, value: 'delivery_date_from' },
              { label: `${form.getValues().deliveryDateTo}`, value: 'delivery_date_to' },
              { label: `${form.getValues().picId}`, value: 'pic_id' },
              { label: `${form.getValues().picSubId}`, value: 'pic_sub_id' },
              { label: `${form.getValues().siteAddress}`, value: 'site_address' },
              { label: `${form.getValues().customProp1}`, value: 'custom_prop_1' },
              { label: `${form.getValues().customProp2}`, value: 'custom_prop_2' },
              { label: `${form.getValues().customProp3}`, value: 'custom_prop_3' },
              { label: `${form.getValues().customProp4}`, value: 'custom_prop_4' },
              { label: `${form.getValues().detailDefaultProp}`, value: 'detail_default_prop' },
              { label: `${form.getValues().detailCustomProp1}`, value: 'detail_custom_prop1' },
              { label: `${form.getValues().detailCustomProp2}`, value: 'detail_custom_prop2' },
              { label: `${form.getValues().detailUnitPrice}`, value: 'detail_unit_price' },
              { label: `${form.getValues().detailQuantity}`, value: 'detail_quantity' },
              { label: `${form.getValues().detailUnit}`, value: 'detail_unit' },
              { label: `${form.getValues().detailTaxBucketId}`, value: 'detail_tax_bucket_id' },
            ]}
            control={form.control}
          />
        </FormItem>
        <FormItem label="除外対象の値">
          <FlexColumn>
            {fields.map((detail, index) => (
              <FlexRow key={detail.id}>
                <TextField register={form.register(`skippingTargetValues.${index}.value`)} />
                {index === 0 ? (
                  <Button size="sm" onClick={() => append({ value: '' })}>
                    追加
                  </Button>
                ) : (
                  <Button size="sm" onClick={() => remove(index)}>
                    削除
                  </Button>
                )}
              </FlexRow>
            ))}
          </FlexColumn>
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

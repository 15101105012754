import Label from '../../atoms/display/Label'
import { Role } from '../../../types/User'

type UserRoleLabelProps = {
  role: Role
}

function name(role: Role) {
  switch (role) {
    case 1:
      return 'オーナー'
    case 2:
      return 'リーダー'
    case 3:
      return 'メンバー'
    default:
      return 'その他'
  }
}

export default function UserRoleLabel({ role }: UserRoleLabelProps) {
  return <Label text={name(role)} />
}

import { createSlice } from '@reduxjs/toolkit'
import { createAsyncAction, GetRedirectPath } from '../actions'
import { Notification, User } from '../../types/User'
import { TransactionActions } from '../transactions'
import { RegisterActions } from '../register'

export type SessionState = {
  token?: string
  companyId?: string
  companyDomain?: string
  user?: User
  contractIncludeClosed: boolean
  notifications: Notification[]
  lineConnectUrl?: string
}

export const initialSessionState: SessionState = {
  token: undefined,
  companyId: undefined,
  companyDomain: undefined,
  user: undefined,
  contractIncludeClosed: true,
  notifications: [],
}

export const SessionActions = {
  register: createAsyncAction<
    {
      email: string
      password: string
      companyDomain: string
      companyName: string
      token?: string
      getRedirectPath?: GetRedirectPath<void>
    },
    {
      redirectTo?: string
    }
  >('register', async (params, { sessionRepository }) => {
    await sessionRepository.register(
      params.email,
      params.password,
      params.companyDomain,
      params.companyName,
      undefined,
      params.token
    )
    const redirectTo = params.getRedirectPath ? params.getRedirectPath() : undefined
    return { redirectTo }
  }),
  resetPassword: createAsyncAction<
    {
      companyDomain: string
      email: string
      getRedirectPath: GetRedirectPath<void>
    },
    {
      redirectTo: string
    }
  >('resetPassword', async (params, { sessionRepository }) => {
    await sessionRepository.resetPassword(params.companyDomain, params.email)
    const redirectTo = params.getRedirectPath()
    return { redirectTo }
  }),
  login: createAsyncAction<
    {
      companyDomain: string
      email: string
      password: string
    },
    {
      token: string
      companyId: string
      companyDomain: string
    }
  >('login', async (params, { sessionRepository }) => {
    const response = await sessionRepository.login(params.companyDomain, params.email, params.password)
    return { token: response.token, companyId: response.company.id, companyDomain: response.company.workspaceDomain }
  }),
  logout: createAsyncAction<void, void>('logout', async () => Promise.resolve()),
  fetchMe: createAsyncAction<
    void,
    {
      user: User
      notifications: Notification[]
    }
  >('fetchMe', async (params, { sessionRepository }) => {
    const user = await sessionRepository.loadMe()
    const notifications = await sessionRepository.loadNotificationSettings(user.id)
    return { user, notifications }
  }),
  getLineConnectUrl: createAsyncAction<
    void,
    {
      lineConnectUrl?: string
    }
  >('getLineConnectUrl', async (params, { sessionRepository }, state) => {
    const { user } = state.session
    if (user) {
      const lineConnectUrl = await sessionRepository.getLineConnectUrl(user.id)
      return { lineConnectUrl }
    }
    return { lineConnectUrl: undefined }
  }),
  updateMe: createAsyncAction<
    {
      user: User
    },
    {
      user: User
    }
  >('updateMe', async (params, { sessionRepository }) => {
    await sessionRepository.update(params.user)
    return { user: params.user }
  }),
  changePassword: createAsyncAction<
    {
      currentPassword: string
      password: string
    },
    void
  >('changePassword', async (params, { sessionRepository }, state) => {
    if (state.session.user) {
      await sessionRepository.changePassword(state.session.user.id, params.currentPassword, params.password)
    }
  }),
  changeEmail: createAsyncAction<
    {
      email: string
    },
    {
      email: string
    }
  >('changeEmail', async (params, { sessionRepository }, state) => {
    if (state.session.user) {
      await sessionRepository.changeEmail(state.session.user.id, params.email)
    }
    return { email: params.email }
  }),
  updateNotificationSetting: createAsyncAction<
    {
      notificationId: number
      emailEnable: boolean
      lineEnable: boolean
    },
    {
      notificationId: number
      emailEnable: boolean
      lineEnable: boolean
    }
  >('updateNotificationSetting', async (params, { sessionRepository }, state) => {
    if (state.session.user) {
      await sessionRepository.updateNotificationSetting(
        state.session.user.id,
        params.notificationId,
        params.emailEnable,
        params.lineEnable
      )
    }
    return {
      notificationId: params.notificationId,
      emailEnable: params.emailEnable,
      lineEnable: params.lineEnable,
    }
  }),
  uploadUserImage: createAsyncAction<
    {
      file: File
    },
    {
      url: string
    }
  >('uploadUserImage', async (params, { sessionRepository }) => {
    const response = await sessionRepository.uploadUserImage(params.file)
    return { url: response.url }
  }),
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSessionState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SessionActions.login.fulfilled, (state, action) => {
        state.token = action.payload.token
        state.companyId = action.payload.companyId
        state.companyDomain = action.payload.companyDomain
      })
      .addCase(SessionActions.login.rejected, (state) => {
        state.token = undefined
        state.companyId = undefined
        state.companyDomain = undefined
        state.user = undefined
      })
      .addCase(SessionActions.logout.fulfilled, (state) => {
        state.token = undefined
        state.companyId = undefined
        state.companyDomain = undefined
        state.user = undefined
      })
      .addCase(SessionActions.fetchMe.fulfilled, (state, action) => {
        state.user = { ...action.payload.user, companyId: state.companyId }
        state.notifications = action.payload.notifications
      })
      .addCase(SessionActions.getLineConnectUrl.fulfilled, (state, action) => {
        state.lineConnectUrl = action.payload.lineConnectUrl
      })
      .addCase(SessionActions.updateMe.fulfilled, (state, action) => {
        state.user = action.payload.user
      })
      .addCase(SessionActions.uploadUserImage.fulfilled, (state, action) => {
        if (state.user) {
          state.user.icon = action.payload.url
        }
      })
      .addCase(SessionActions.changePassword.fulfilled, (state) => {
        if (state.user) {
          state.user.isRawPassword = false
        }
      })
      .addCase(SessionActions.updateNotificationSetting.fulfilled, (state, action) => {
        const notification = state.notifications.find((item) => item.id === action.payload.notificationId)
        if (notification) {
          notification.emailEnable = action.payload.emailEnable
          notification.lineEnable = action.payload.lineEnable
        }
      })
      .addCase(RegisterActions.invite.fulfilled, (state) => {
        if (state.user?.registrationStatus) {
          state.user.registrationStatus.isInviteContractor = true
        }
      })
      .addCase(RegisterActions.connectLine.fulfilled, (state) => {
        if (state.user?.registrationStatus) {
          state.user.registrationStatus.isLinkedLineAccount = true
        }
      })
      .addCase(TransactionActions.fetchContracts.pending, (state, action) => {
        state.contractIncludeClosed = !action.meta.arg.excludeClosed
      })
      .addMatcher(
        (action: { type: string }) => action.type.endsWith('/rejected'),
        (state, action: { payload: { status?: number } }) => {
          if (action.payload.status === 401) {
            state.user = undefined
            state.companyId = undefined
            state.token = undefined
            state.companyDomain = undefined
          }
        }
      )
  },
})

export default sessionSlice

import MuiAlert, { AlertColor } from '@mui/material/Alert'
import MuiAlertTitle from '@mui/material/AlertTitle'
import { ReactNode } from 'react'

type Severity = AlertColor
type Variant = 'filled' | 'outlined' | 'standard'

type AlertProps = {
  icon?: boolean
  severity: Severity
  title?: string
  label?: string
  variant?: Variant
  children?: ReactNode
}

export default function Alert({ icon, severity, title, label, variant, children }: AlertProps) {
  return (
    <MuiAlert severity={severity} variant={variant} icon={icon}>
      {title && <MuiAlertTitle>{title}</MuiAlertTitle>}
      {label || children}
    </MuiAlert>
  )
}

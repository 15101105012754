import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import Alert from '../../../components/atoms/feedback/Alert'
import usePath from '../../../hooks/usePath'
import Link from '../../../components/atoms/navigation/Link'
import useRouter from '../../../hooks/useRouter'
import { RegisterSelectors } from '../../../store/register/selectors'

export default function RegisterContracteeCompletedPage() {
  const { registerContracteeEmailSupport } = usePath()
  const { registerContracteeEmail } = useRouter()
  const contractee = useSelector(RegisterSelectors.contractee)

  if (contractee.email === undefined) {
    registerContracteeEmail()
    return <></>
  }

  return (
    <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
      <FlexColumn align="center">
        <Label text="アカウント登録" variant="title" />
        <FlexColumn width={320} space={4}>
          <FlexRow wrap>
            <Label text={contractee.email} />
            <Label text="にメールを送信しました" />
          </FlexRow>
          <Label text="「【monect】本登録のご案内」という件名のメールに記載の 「本登録」をクリックしてログインしてください" />
          <FlexColumn>
            <Alert severity="warning">新規登録はまだ完了していません</Alert>
            <Alert severity="warning">
              メールが届かない場合は<Link href={registerContracteeEmailSupport()}>こちら</Link>
            </Alert>
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
      <FlexColumn>
        <FlexRow justify="center">
          <DotPagination count={11} current={4} />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}

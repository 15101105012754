import MuiButton from '@mui/material/Button'
import { styled } from '@mui/material'
import { ChangeEvent, useCallback } from 'react'
import { Component } from '../../../types/react'

type FileSelectButtonProps = {
  accepts: string[]
  multiple?: boolean
  disabled?: boolean
  maxSize?: number
  children: Component
  onSelect: (files: File[]) => void
  onError?: () => void
}

const StyledInput = styled('input')({})

export default function FileSelectButton({
  accepts,
  multiple = false,
  disabled = false,
  maxSize,
  children,
  onSelect,
  onError,
}: FileSelectButtonProps) {
  const handleSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const array: File[] = []
        const list: FileList = event.target.files
        for (let index = 0; index < list.length; index += 1) {
          const file = list.item(index)
          if (file) {
            array.push(file)
          }
        }
        onSelect(array)
        event.target.value = ''
      }
    },
    [onSelect]
  )
  return (
    <label htmlFor="file-select-button">
      <StyledInput
        sx={{ display: 'none' }}
        accept={accepts.join(',')}
        type="file"
        id="file-select-button"
        name="file-select-button"
        multiple={multiple}
        size={maxSize}
        disabled={disabled}
        onChange={handleSelect}
        onError={onError}
      />
      <MuiButton variant="contained" component="span" disabled={disabled}>
        {children}
      </MuiButton>
    </label>
  )
}

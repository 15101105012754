import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMessageEditDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexRow } from '../../atoms/layout/Flex'
import useDay from '../../../hooks/useDay'
import useScheduleExpression from '../../../hooks/useScheduleExpression'
import { ReservedMessageRepeatType } from '../../../types/Chat'
import { ChatActions } from '../../../store/chat'
import MessageForm, { FormParams } from './MessageForm'
import MoneyspaceSelect from './MoneyspaceSelect'

function toDate(time: string): Date {
  if (time === '') {
    return new Date()
  }
  const [hour, minute] = time.split(':')
  const date = new Date()
  date.setHours(Number(hour))
  date.setMinutes(Number(minute))
  return date
}

export default function MessageEditDialog() {
  const { state, close } = useMessageEditDialog()
  const [checkedMoneyspaceIds, setCheckedMoneyspaceIds] = useState<string[]>([])
  const { parse } = useScheduleExpression()
  const { formatDate } = useDay()
  const form = useForm<FormParams>({
    defaultValues: {
      type: 1,
      date: formatDate(new Date()),
      time: new Date(),
      repeatType: '1',
      repeatDayOfWeek: 1,
      repeatDayOfMonth: 1,
      repeatTime: new Date(),
      repeatBaseDate: formatDate(new Date()),
      message: '',
    },
  })
  const { generate } = useScheduleExpression()
  const { formatTime } = useDay()
  const dispatch = useDispatch()

  useEffect(() => {
    if (state) {
      const schedule = parse(state.message.scheduleExpression)
      form.setValue('type', schedule.type === 'schedule' ? 2 : 3)
      form.setValue('date', schedule.date)
      form.setValue('time', toDate(schedule.time))
      form.setValue('repeatType', schedule.repeatType.toString())
      form.setValue('repeatTime', toDate(schedule.repeatTime))
      form.setValue('repeatDayOfWeek', schedule.repeatDayOfWeek ?? 1)
      form.setValue('repeatDayOfMonth', schedule.repeatDayOfMonth ?? 1)
      form.setValue('repeatBaseDate', schedule.repeatBaseDate === '' ? formatDate(new Date()) : schedule.repeatBaseDate)
      form.setValue('message', state.message.description)
    }
  }, [form, parse, state, formatDate])

  useEffect(() => {
    if (state) {
      setCheckedMoneyspaceIds(state.message.msIds)
    }
  }, [state])

  const handleSend = useCallback(
    (params: FormParams) => {
      if (state) {
        const schedule = generate({
          type: Number(params.type) === 2 ? 'schedule' : 'repeat',
          date: params.date ?? '',
          time: params.time ? formatTime(params.time) : '',
          repeatType: (Number(params.repeatType) ?? 1) as ReservedMessageRepeatType,
          repeatTime: params.repeatTime ? formatTime(params.repeatTime) : '',
          repeatDayOfWeek: Number(params.repeatDayOfWeek ?? 1),
          repeatDayOfMonth: Number(params.repeatDayOfMonth ?? 1),
          repeatBaseDate: params.repeatBaseDate ?? '',
        })
        dispatch(
          ChatActions.updateReservedMessage({
            reservedMessageId: state.message.id,
            moneyspaceIds: checkedMoneyspaceIds,
            description: params.message,
            scheduleExpression: schedule,
          })
        )
      }
    },
    [state, generate, formatTime, dispatch, checkedMoneyspaceIds]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="予約メッセージ編集"
      width="lg"
      onSubmit={form.handleSubmit(handleSend)}
    >
      <FormProvider {...form}>
        <FlexRow wrap>
          <MoneyspaceSelect moneyspaceIds={checkedMoneyspaceIds} onChangeMoneyspaceIds={setCheckedMoneyspaceIds} />
          <MessageForm moneyspaceIds={checkedMoneyspaceIds} edit />
        </FlexRow>
      </FormProvider>
    </Dialog>
  )
}

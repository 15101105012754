import { createSlice } from '@reduxjs/toolkit'
import { createAsyncAction, GetRedirectPath } from '../actions'
import {
  Document,
  DocumentActivity,
  DocumentAttributes,
  DocumentDiffs,
  DocumentItemSearchResultItem,
  DocumentSearchCondition,
  DocumentSearchParams,
  DocumentSearchResultItem,
  FactoringDocument,
  Tag,
} from '../../types/Document'
import { ApprovalFlowState } from '../../types/ApprovalFlow'
import { Page, SortDirection } from '../../types/System'

export type DocumentsState = {
  document?: Document
  activities: DocumentActivity[]
  approvalFlowState?: ApprovalFlowState
  searchParams: DocumentSearchParams
  searchResult?: {
    documents?: Page<DocumentSearchResultItem>
    items?: Page<DocumentItemSearchResultItem>
  }
  searchConditions: DocumentSearchCondition[]
  factoringDocuments: FactoringDocument[]
  unapprovedDocuments: Page<DocumentSearchResultItem>
  submittedDocuments: Page<DocumentSearchResultItem>
  paymentDocuments: Page<DocumentSearchResultItem>
  documentDiffs?: DocumentDiffs
  tags: Tag[]
}

export const initialDocumentsState: DocumentsState = {
  document: undefined,
  activities: [],
  searchParams: {
    moneyspacePositions: 0,
    keyword: '',
    documentTypes: {
      type1: false,
      type2: false,
      type3: false,
      type4: false,
      type5: false,
      type6: false,
      type7: false,
      type8: false,
    },
    documentStatus: 0,
    paymentDateFrom: undefined,
    searchType: 1,
  },
  searchResult: undefined,
  searchConditions: [],
  factoringDocuments: [],
  unapprovedDocuments: {
    count: 0,
    results: [],
    next: null,
    previous: null,
  },
  submittedDocuments: {
    count: 0,
    results: [],
    next: null,
    previous: null,
  },
  paymentDocuments: {
    count: 0,
    results: [],
    next: null,
    previous: null,
  },
  tags: [],
}

export const DocumentsActions = {
  fetchDocument: createAsyncAction<
    {
      id: string
      handleCompleted?: () => void
    },
    {
      document?: Document
      activities: DocumentActivity[]
    }
  >('fetchDocument', async (params, { documentRepository }) => {
    try {
      const document = await documentRepository.loadDocument(params.id)
      const activities = await documentRepository.loadDocumentActivities(params.id)
      return { document, activities }
    } finally {
      if (params.handleCompleted) {
        params.handleCompleted()
      }
    }
  }),
  fetchDocumentDiffs: createAsyncAction<
    {
      id: string
    },
    {
      documentDiffs?: DocumentDiffs
    }
  >('fetchDocumentDiffs', async (params, { documentRepository }) => {
    const response = await documentRepository.loadDocumentDiffs(params.id)
    if (response) {
      const previousDocument = await documentRepository.loadDocument(response.previousDocument.id)
      const currentDocument = await documentRepository.loadDocument(response.currentDocument.id)
      return {
        documentDiffs: {
          previousDocument: {
            document: previousDocument,
            detailIds: response.previousDocument.detailIds,
          },
          currentDocument: {
            document: currentDocument,
            detailIds: response.currentDocument.detailIds,
          },
        },
      }
    }
    return { documentDiffs: undefined }
  }),
  fetchApprovalFlowState: createAsyncAction<
    {
      id: string
    },
    {
      approvalFlowState?: ApprovalFlowState
    }
  >('fetchApprovalFlowState', async (params, { documentRepository }) => {
    const approvalFlowState = await documentRepository.loadApprovalFlowState(params.id)
    return { approvalFlowState }
  }),
  searchDocuments: createAsyncAction<
    {
      page: number
    } & DocumentSearchParams,
    {
      documents?: Page<DocumentSearchResultItem>
      items?: Page<DocumentItemSearchResultItem>
    }
  >('searchDocuments', async (params, { documentRepository }) => {
    if (params.searchType === 1) {
      const result = await documentRepository.searchPagingDocuments(params)
      return {
        documents: result,
      }
    }
    const result = await documentRepository.searchPagingDocumentItems(params)
    return {
      items: result,
    }
  }),
  updateSearchParams: createAsyncAction<
    DocumentSearchParams,
    {
      searchParams: DocumentSearchParams
    }
  >('updateSearchParams', async (params) => Promise.resolve({ searchParams: params })),
  fetchSearchCondition: createAsyncAction<
    void,
    {
      searchConditions: DocumentSearchCondition[]
    }
  >('fetchSearchCondition', async (params, { documentRepository }, state) => {
    const userId = state.session.user?.id ?? ''
    const response = await documentRepository.loadSearchConditions(userId)
    const searchConditions: DocumentSearchCondition[] = response.searchQueries.map((item) => ({
      id: item.id,
      name: item.name,
      params: JSON.parse(item.query) as DocumentSearchParams,
    }))
    return {
      searchConditions,
    }
  }),
  saveSearchCondition: createAsyncAction<
    {
      id?: string
      name: string
      params: DocumentSearchParams
    },
    {
      id: string
      name: string
      params: DocumentSearchParams
    }
  >('saveSearchCondition', async (params, { documentRepository }, state) => {
    const userId = state.session.user?.id ?? ''
    const json = JSON.stringify(params.params)
    if (params.id) {
      await documentRepository.updateSearchCondition(userId, params.id, params.name)
      return {
        id: params.id,
        name: params.name,
        params: params.params,
      }
    }
    const response = await documentRepository.createSearchCondition(userId, params.name, json)
    return {
      id: response.id,
      name: params.name,
      params: params.params,
    }
  }),
  deleteSearchCondition: createAsyncAction<
    {
      id: string
    },
    {
      id: string
    }
  >('deleteSearchCondition', async (params, { documentRepository }, state) => {
    const userId = state.session.user?.id ?? ''
    await documentRepository.deleteSearchCondition(userId, params.id)
    return {
      id: params.id,
    }
  }),
  loadFactoringDocuments: createAsyncAction<
    void,
    {
      factoringDocuments: FactoringDocument[]
    }
  >('loadFactoringDocuments', async (params, { documentRepository }) => {
    const factoringDocuments = await documentRepository.loadFactoringDocuments()
    return {
      factoringDocuments,
    }
  }),
  loadUnapprovedDocuments: createAsyncAction<
    {
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      unapprovedDocuments: Page<DocumentSearchResultItem>
    }
  >('loadUnapprovedDocuments', async (params, { documentRepository }) => {
    const unapprovedDocuments = await documentRepository.searchUnApprovedDocuments(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { unapprovedDocuments }
  }),
  loadSubmittedDocuments: createAsyncAction<
    {
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      submittedDocuments: Page<DocumentSearchResultItem>
    }
  >('loadSubmittedDocuments', async (params, { documentRepository }) => {
    const submittedDocuments = await documentRepository.searchSubmittedDocuments(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { submittedDocuments }
  }),
  loadPaymentDocuments: createAsyncAction<
    {
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      paymentDocuments: Page<DocumentSearchResultItem>
    }
  >('loadPaymentDocuments', async (params, { documentRepository }) => {
    const paymentDocuments = await documentRepository.searchPaymentDocuments(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { paymentDocuments }
  }),
  downloadDocuments: createAsyncAction<
    {
      filename: string
    } & DocumentSearchParams,
    void
  >('downloadDocuments', async (params, { documentRepository }) => {
    if (params.searchType === 1) {
      await documentRepository.downloadDocuments(params)
      return
    }
    await documentRepository.downloadDocumentItems(params)
  }),
  createDocument: createAsyncAction<
    {
      document: DocumentAttributes
      file?: File
      getRedirectPath?: GetRedirectPath<Document>
    },
    {
      document: Document
      activities: DocumentActivity[]
      redirectTo?: string
    }
  >('createDocument', async (params, { documentRepository }) => {
    const document = await documentRepository.createDocument(params.document)
    const activities = await documentRepository.loadDocumentActivities(document.id)
    const redirectTo = params.getRedirectPath ? params.getRedirectPath(document) : undefined
    if (params.file) {
      const url = await documentRepository.uploadAttachment(document.id, params.file)
      return { document: { ...document, attachment: url }, activities, redirectTo }
    }
    return { document, activities, redirectTo }
  }),
  saveDocument: createAsyncAction<
    {
      id: string
      document: DocumentAttributes
      getRedirectPath?: GetRedirectPath<Document>
    },
    {
      document: Document
      activities: DocumentActivity[]
      redirectTo?: string
    }
  >('saveDocument', async (params, { documentRepository }) => {
    const document = await documentRepository.saveDocument(params.id, params.document)
    const activities = await documentRepository.loadDocumentActivities(document.id)
    const redirectTo = params.getRedirectPath ? params.getRedirectPath(document) : undefined
    return { document, activities, redirectTo }
  }),
  deleteDocument: createAsyncAction<
    {
      id: string
    },
    {
      id: string
    }
  >('deleteDocument', async (params, { documentRepository }) => {
    await documentRepository.deleteDocument(params.id)
    return { id: params.id }
  }),
  applyDocument: createAsyncAction<
    {
      id: string
      flowId: string
    },
    {
      document: Document
      activities: DocumentActivity[]
    }
  >('applyDocument', async (params, { documentRepository }) => {
    await documentRepository.updateApprovalFlow(params.id, params.flowId)
    const document = await documentRepository.applyDocument(params.id)
    const activities = await documentRepository.loadDocumentActivities(document.id)
    return { document, activities }
  }),
  updateApprovalFlow: createAsyncAction<
    {
      id: string
      flowId: string
    },
    {
      activities: DocumentActivity[]
      approvalFlowState?: ApprovalFlowState
    }
  >('updateApprovalFlow', async (params, { documentRepository }) => {
    await documentRepository.updateApprovalFlow(params.id, params.flowId)
    const activities = await documentRepository.loadDocumentActivities(params.id)
    const approvalFlowState = await documentRepository.loadApprovalFlowState(params.id)
    return { activities, approvalFlowState }
  }),
  approveDocument: createAsyncAction<
    {
      id: string
      getRedirectPath?: GetRedirectPath<void>
    },
    {
      document: Document
      activities: DocumentActivity[]
      approvalFlowState?: ApprovalFlowState
      redirectTo?: string
    }
  >('approveDocument', async (params, { documentRepository }) => {
    const document = await documentRepository.approveDocument(params.id)
    const activities = await documentRepository.loadDocumentActivities(document.id)
    const approvalFlowState = await documentRepository.loadApprovalFlowState(params.id)
    const redirectTo = params.getRedirectPath ? params.getRedirectPath() : undefined
    return { document, activities, approvalFlowState, redirectTo }
  }),
  bulkApproveDocument: createAsyncAction<
    {
      documentIds: string[]
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      unapprovedDocuments: Page<DocumentSearchResultItem>
    }
  >('bulkApproveDocument', async (params, { documentRepository }) => {
    await documentRepository.bulkApproveDocument(params.documentIds)
    const unapprovedDocuments = await documentRepository.searchUnApprovedDocuments(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { unapprovedDocuments }
  }),
  rejectDocument: createAsyncAction<
    {
      id: string
      note?: string
    },
    {
      document: Document
      activities: DocumentActivity[]
      approvalFlowState?: ApprovalFlowState
    }
  >('rejectDocument', async (params, { documentRepository }) => {
    const document = await documentRepository.rejectDocument(params.id, params.note)
    const activities = await documentRepository.loadDocumentActivities(document.id)
    const approvalFlowState = await documentRepository.loadApprovalFlowState(params.id)
    return { document, activities, approvalFlowState }
  }),
  fetchTags: createAsyncAction<
    void,
    {
      tags: Tag[]
    }
  >('fetchTags', async (params, { documentRepository }) => {
    const tags = await documentRepository.loadTags()
    return { tags }
  }),
  createTag: createAsyncAction<
    {
      name: string
    },
    {
      tag: Tag
    }
  >('createTag', async (params, { documentRepository }) => {
    const tag = await documentRepository.createTag(params.name)
    return { tag }
  }),
  updateTag: createAsyncAction<
    {
      tag: Tag
    },
    {
      tag: Tag
    }
  >('updateTag', async (params, { documentRepository }) => {
    const tag = await documentRepository.saveTag(params.tag)
    return { tag }
  }),
  deleteTag: createAsyncAction<
    {
      tag: Tag
    },
    {
      tag: Tag
    }
  >('deleteTag', async (params, { documentRepository }) => {
    await documentRepository.deleteTag(params.tag)
    return { tag: params.tag }
  }),
}

const documentsSlice = createSlice({
  name: 'documents',
  initialState: initialDocumentsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(DocumentsActions.fetchDocument.pending, (state) => {
        state.document = undefined
      })
      .addCase(DocumentsActions.fetchDocument.rejected, (state) => {
        state.document = undefined
      })
      .addCase(DocumentsActions.fetchDocument.fulfilled, (state, action) => {
        state.document = action.payload.document
        state.activities = action.payload.activities
      })
      .addCase(DocumentsActions.fetchDocumentDiffs.fulfilled, (state, action) => {
        state.documentDiffs = action.payload.documentDiffs
      })
      .addCase(DocumentsActions.fetchApprovalFlowState.fulfilled, (state, action) => {
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(DocumentsActions.searchDocuments.fulfilled, (state, action) => {
        state.searchResult = {
          documents: action.payload.documents,
          items: action.payload.items,
        }
      })
      .addCase(DocumentsActions.updateSearchParams.fulfilled, (state, action) => {
        state.searchParams = action.payload.searchParams
      })
      .addCase(DocumentsActions.fetchSearchCondition.fulfilled, (state, action) => {
        state.searchConditions = action.payload.searchConditions
      })
      .addCase(DocumentsActions.saveSearchCondition.fulfilled, (state, action) => {
        const condition = state.searchConditions.find((item) => item.id === action.payload.id)
        if (condition) {
          condition.name = action.payload.name
        } else {
          state.searchConditions.push(action.payload)
        }
      })
      .addCase(DocumentsActions.deleteSearchCondition.fulfilled, (state, action) => {
        state.searchConditions = state.searchConditions.filter((item) => item.id !== action.payload.id)
      })
      .addCase(DocumentsActions.loadFactoringDocuments.fulfilled, (state, action) => {
        state.factoringDocuments = action.payload.factoringDocuments
      })
      .addCase(DocumentsActions.loadUnapprovedDocuments.fulfilled, (state, action) => {
        state.unapprovedDocuments = action.payload.unapprovedDocuments
      })
      .addCase(DocumentsActions.loadSubmittedDocuments.fulfilled, (state, action) => {
        state.submittedDocuments = action.payload.submittedDocuments
      })
      .addCase(DocumentsActions.loadPaymentDocuments.fulfilled, (state, action) => {
        state.paymentDocuments = action.payload.paymentDocuments
      })
      .addCase(DocumentsActions.saveDocument.fulfilled, (state, action) => {
        state.document = action.payload.document
        state.activities = action.payload.activities
      })
      .addCase(DocumentsActions.applyDocument.fulfilled, (state, action) => {
        state.document = action.payload.document
        state.activities = action.payload.activities
      })
      .addCase(DocumentsActions.updateApprovalFlow.fulfilled, (state, action) => {
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(DocumentsActions.approveDocument.fulfilled, (state, action) => {
        state.document = action.payload.document
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(DocumentsActions.bulkApproveDocument.fulfilled, (state, action) => {
        state.unapprovedDocuments = action.payload.unapprovedDocuments
      })
      .addCase(DocumentsActions.rejectDocument.fulfilled, (state, action) => {
        state.document = action.payload.document
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(DocumentsActions.fetchTags.fulfilled, (state, action) => {
        state.tags = action.payload.tags
      })
      .addCase(DocumentsActions.createTag.fulfilled, (state, action) => {
        state.tags.push(action.payload.tag)
      })
      .addCase(DocumentsActions.updateTag.fulfilled, (state, action) => {
        state.tags = state.tags.map((tag) => {
          if (tag.id === action.payload.tag.id) {
            return action.payload.tag
          }
          return tag
        })
      })
      .addCase(DocumentsActions.deleteTag.fulfilled, (state, action) => {
        state.tags = state.tags.filter((tag) => tag.id !== action.payload.tag.id)
      })
  },
})

export default documentsSlice

import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import { useTransactionSearchConditionDeleteDialog } from '../../../hooks/useDialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { TransactionActions } from '../../../store/transactions'

type TransactionSearchConditionDeleteDialogProps = {
  id: string
}

export default function TransactionSearchConditionDeleteDialog({ id }: TransactionSearchConditionDeleteDialogProps) {
  const { state, close } = useTransactionSearchConditionDeleteDialog()
  const transactionSearchConditions = useSelector(TransactionSelectors.searchConditions)
  const searchCondition = transactionSearchConditions.find((item) => item.id === id)
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    dispatch(TransactionActions.deleteSearchCondition({ id }))
  }, [dispatch, id])
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="お気に入り検索条件の削除"
      actions={
        <Button color="caution" onClick={handleDelete}>
          削除
        </Button>
      }
    >
      <FlexColumn>
        <FlexRow>
          <Label text={searchCondition?.name ?? ''} />
          <Label text="を削除してよろしいですか？" />
        </FlexRow>
      </FlexColumn>
    </Dialog>
  )
}

import { Control } from 'react-hook-form'
import Select from '../../atoms/inputs/Select'
import useRange from '../../../hooks/useRange'

type ClosingDayGracePeriodFieldProps = {
  name: string
  control: Control
  disabled?: boolean
}

export default function ClosingDayGracePeriodField({ name, control, disabled }: ClosingDayGracePeriodFieldProps) {
  const days = useRange(20)
  const items = [
    { value: 0, label: '0日間（設定なし）' },
    ...days.map((day) => ({ value: day + 1, label: `${day + 1}日間` })),
  ]
  return <Select items={items} size="sm2" name={name} control={control} disabled={disabled} />
}

import MuiTextField from '@mui/material/TextField'
import { UseFormRegisterReturn } from 'react-hook-form'
import { ReactNode } from 'react'
import { InputAdornment } from '@mui/material'
import { useDeviceType } from '../../../hooks/useDeviceType'

export type TextSize = 'xs' | 'sm' | 'md' | 'lg'
type TextType = 'text' | 'email' | 'password'

type TextFieldProps = {
  label?: string
  value?: string
  type?: TextType
  size?: TextSize
  focused?: boolean
  placeholder?: string
  prefix?: ReactNode
  suffix?: ReactNode
  readonly?: boolean
  multiline?: boolean
  rows?: number
  minRows?: number
  maxRows?: number
  register?: UseFormRegisterReturn
  error?: string
  helperText?: string
  convertToAscii?: boolean
  onChange?: (value?: string) => void
  onLoad?: (_target: EventTarget) => void
  onFocus?: (_target: EventTarget) => void
  onBlur?: (value?: string) => void
  onSelect?: (selectionStart: number, selectionEnd: number) => void
}

const width = (pc: boolean, size?: TextSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'md':
      return pc ? 360 : 240
    case 'lg':
      return pc ? 480 : 240
    default:
      return '100%'
  }
}

export default function TextField({
  label,
  value,
  type = 'text',
  size,
  focused,
  placeholder,
  prefix,
  suffix,
  readonly,
  multiline,
  rows,
  minRows,
  maxRows,
  register,
  error,
  helperText,
  convertToAscii,
  onChange,
  onLoad,
  onFocus,
  onBlur,
  onSelect,
}: TextFieldProps) {
  const { pc } = useDeviceType()
  return (
    <MuiTextField
      label={label}
      value={value}
      type={type}
      variant="outlined"
      autoFocus={focused}
      placeholder={placeholder}
      focused={focused}
      disabled={readonly}
      multiline={multiline}
      rows={multiline ? rows : 1}
      minRows={minRows}
      maxRows={maxRows}
      sx={{ width: width(pc, size) }}
      InputProps={{
        startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
        endAdornment: suffix && <InputAdornment position="end">{suffix}</InputAdornment>,
      }}
      {...register}
      error={error !== undefined}
      helperText={helperText ?? error ?? ''}
      size="small"
      onChange={(event) => {
        if (onChange) {
          onChange(event.target.value)
        }
        if (register) {
          void register.onChange(event)
        }
      }}
      onBlur={(event) => {
        if (convertToAscii && event.target.value) {
          event.target.value = event.target.value.replace(/[０-９]/g, (char: string) =>
            String.fromCharCode(char.charCodeAt(0) - 0xfee0)
          )
        }
        if (onChange) {
          onChange(event.target.value)
        }
        if (register) {
          void register.onChange(event)
        }
        if (onBlur) {
          onBlur(event.target.value)
        }
      }}
      onLoad={(e) => onLoad && onLoad(e.target)}
      onFocus={(e) => onFocus && onFocus(e.target)}
      onSelect={(e) => {
        if (onSelect) {
          const target = e.target as HTMLInputElement
          if (target.selectionStart !== null && target.selectionEnd !== null) {
            onSelect(target.selectionStart, target.selectionEnd)
          }
        }
      }}
    />
  )
}

import Stepper from '../../atoms/navigation/Stepper'
import { Contract } from '../../../types/Document'
import useConsts from '../../../hooks/useConsts'
import useRouter from '../../../hooks/useRouter'

type ContractStepsProps = {
  moneyspaceId: string
  activeDocumentId: string
  contract: Contract
}

export default function ContractSteps({ moneyspaceId, activeDocumentId, contract }: ContractStepsProps) {
  const { documentTypeName } = useConsts()
  const { document: documentPage } = useRouter()
  const active = contract.documents.map((document) => document.id).indexOf(activeDocumentId)
  const currentDocument = contract.documents.find((doc) => doc.status !== 4)
  const steps = contract.documents.map((document) => ({
    id: document.id,
    label: documentTypeName(document.type),
    completed: document.status === 4,
    badge: document.id === currentDocument?.id,
    onClick: () => document.id && documentPage(moneyspaceId, contract.id, document.id),
  }))
  return <Stepper active={active} steps={steps} />
}

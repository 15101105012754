import { Page } from '../../../types/System'
import { FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'

type PaginationCountLabelProps = {
  page: number
  pageSize?: number
  contents: Page<object>
}

export default function PaginationCountLabel({ page, pageSize = 30, contents }: PaginationCountLabelProps) {
  return (
    <FlexRow>
      <Label text="件数" />
      {contents.count === 0 ? (
        <Label text={contents.count} />
      ) : (
        <>
          <Label text={`${(page - 1) * pageSize + 1} - ${Math.min(page * pageSize, contents.count)}`} />
          <Label text="/" />
          <Label text={contents.count} />
        </>
      )}
    </FlexRow>
  )
}

import { useSelector } from 'react-redux'
import { useCallback, useState } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import useRouter from '../../../hooks/useRouter'
import {
  useTransactionApplyDialog,
  useTransactionApproveDialog,
  useTransactionRejectDialog,
  useTransactionSubmitDialog,
} from '../../../hooks/useDialog'
import usePermission from '../../../hooks/usePermission'
import { SessionSelectors } from '../../../store/session/selectors'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { ApprovalFlowState } from '../../../types/ApprovalFlow'
import {
  Transaction,
  TransactionPhase,
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionStatusReview,
} from '../../../types/transaction'
import useTransaction from '../../../hooks/useTransaction'
import Label from '../../atoms/display/Label'
import useConsts from '../../../hooks/useConsts'
import TransactionInstallmentConfirmDialog from './TransactionInstallmentConfirmDialog'

type TransactionButtonsProps = {
  transaction: Transaction
  approvalFlowState?: ApprovalFlowState
}

export function approvalButtonLabel(transaction: Transaction, phaseName: (phase: TransactionPhase) => string) {
  if (transaction.currentPhase === TransactionPhaseBilling || transaction.status === TransactionStatusReview) {
    return `${phaseName(transaction.currentPhase)}を承認`
  }
  if (transaction.phases[transaction.phases.length - 1] === transaction.currentPhase) {
    return '取引を終了する'
  }
  const currentIndex = transaction.phases.indexOf(transaction.currentPhase)
  return `${phaseName(transaction.phases[currentIndex + 1])}へ進む`
}

export default function TransactionButtons({ transaction, approvalFlowState }: TransactionButtonsProps) {
  const { find } = useMoneyspace()
  const moneyspace = find(transaction.moneyspace.id)
  const { transactionEdit, transactionInstallment } = useRouter()
  const { transactionPermissions } = usePermission()
  const user = useSelector(SessionSelectors.user)
  const transactionSubmitDialog = useTransactionSubmitDialog()
  const transactionApplyDialog = useTransactionApplyDialog()
  const transactionApproveDialog = useTransactionApproveDialog()
  const transactionRejectDialog = useTransactionRejectDialog()
  const { isContractee } = useMoneyspace()
  const { validateTransaction, validateClosingDate } = useTransaction()
  const { phaseName } = useConsts()
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false)

  const handleInstallment = useCallback(() => {
    if (transaction.installment) {
      transactionInstallment(transaction.id)
    } else {
      setConfirmDialog(true)
    }
  }, [transaction.id, transaction.installment, transactionInstallment])

  const handleConfirmNext = useCallback(() => {
    transactionInstallment(transaction.id)
    setConfirmDialog(false)
  }, [transactionInstallment, transaction])

  const handleConfirmCancel = useCallback(() => {
    setConfirmDialog(false)
  }, [])

  if (moneyspace === undefined) {
    return <></>
  }
  return (
    <FlexColumn wrap>
      <FlexRow>
        {transactionPermissions.edit(isContractee(moneyspace, user), transaction, moneyspace, user) && (
          <Button onClick={() => transactionEdit(transaction.id)}>編集</Button>
        )}
        {transactionPermissions.apply(isContractee(moneyspace, user), transaction, moneyspace, user) && (
          <>
            {moneyspace.client ? (
              <Button
                onClick={() => transactionApplyDialog.open(transaction)}
                disabled={!validateTransaction(moneyspace, transaction)}
              >
                <Label text="社内確認依頼" />
              </Button>
            ) : (
              <Button
                onClick={() => transactionSubmitDialog.open(transaction)}
                disabled={!validateTransaction(moneyspace, transaction)}
              >
                <Label text="取引先へ提出" />
              </Button>
            )}
          </>
        )}
        {transactionPermissions.approve(
          isContractee(moneyspace, user),
          transaction,
          moneyspace,
          user,
          approvalFlowState
        ) && (
          <Button
            onClick={() => transactionApproveDialog.open(moneyspace.id, transaction)}
            disabled={moneyspace.aggregateTransactions && !validateClosingDate(moneyspace, transaction)}
          >
            {approvalButtonLabel(transaction, phaseName)}
          </Button>
        )}
        {transactionPermissions.reject(
          isContractee(moneyspace, user),
          transaction,
          moneyspace,
          user,
          approvalFlowState
        ) && (
          <Button color="caution" onClick={() => transactionRejectDialog.open(transaction)}>
            {phaseName(transaction.currentPhase)}を却下
          </Button>
        )}
      </FlexRow>
      {transactionPermissions.approve(
        isContractee(moneyspace, user),
        transaction,
        moneyspace,
        user,
        approvalFlowState
      ) &&
        moneyspace.aggregateTransactions &&
        !validateClosingDate(moneyspace, transaction) && (
          <FlexColumn space={0}>
            <Label text="承認期限が経過したため承認できません" variant="caption" color="caution.main" />
            <Label text="却下してください" variant="caption" color="caution.main" />
          </FlexColumn>
        )}
      {transaction.phases[transaction.phases.length - 1] === transaction.currentPhase &&
        [TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase) &&
        transactionPermissions.installment(isContractee(moneyspace, user), transaction, moneyspace, user) && (
          <Button variant="outlined" onClick={handleInstallment}>
            {transaction.installment ? <Label text="今月分を請求" /> : <Label text="分割請求" />}
          </Button>
        )}
      {confirmDialog && (
        <TransactionInstallmentConfirmDialog onNext={handleConfirmNext} onCancel={handleConfirmCancel} />
      )}
    </FlexColumn>
  )
}

import dayjs from 'dayjs'
import { createSlice } from '@reduxjs/toolkit'
import { ContractSummary, DocumentSearchResultItem } from '../../types/Document'
import { createAsyncAction } from '../actions'
import { DashboardData } from '../../types/System'

export type DashboardState = {
  unapprovedDocuments?: DocumentSearchResultItem[]
  latestDocuments?: DocumentSearchResultItem[]
  thisMonthPayDocuments?: DocumentSearchResultItem[]
  contractSummaries?: ContractSummary[]
  factoringDocuments?: DocumentSearchResultItem[]
  data: DashboardData
}

export const initialDashboardState: DashboardState = {
  unapprovedDocuments: undefined,
  latestDocuments: undefined,
  thisMonthPayDocuments: undefined,
  contractSummaries: undefined,
  factoringDocuments: undefined,
  data: {
    documents: {
      count: {
        submitted: 0,
        unapproved: 0,
      },
    },
    messages: {
      hasToMentioned: false,
      count: {
        unread: 0,
      },
    },
  },
}

export const DashboardActions = {
  fetchDashboard: createAsyncAction<
    void,
    {
      data: DashboardData
    }
  >('fetchDashboard', async (params, { systemRepository }) => {
    const data = await systemRepository.loadDashboard()
    return { data }
  }),
  fetchData: createAsyncAction<
    void,
    {
      unapprovedDocuments: DocumentSearchResultItem[]
      latestDocuments: DocumentSearchResultItem[]
      thisMonthPayDocuments: DocumentSearchResultItem[]
      contractSummaries: ContractSummary[]
      factoringDocuments: DocumentSearchResultItem[]
    }
  >('fetchData', async (params, { documentRepository, contractRepository }) => {
    const unapprovedDocuments = await documentRepository.loadUnApprovedDocuments()
    const latestDocuments = await documentRepository.loadLatestDocuments()
    const contractSummaries = await contractRepository.loadActiveContracts()

    const today = dayjs()
    const thisMonthPayDocuments = await documentRepository.searchDocuments({
      documentTypes: {
        type1: false,
        type2: false,
        type3: false,
        type4: false,
        type5: false,
        type6: false,
        type7: false,
        type8: false,
      },
      paymentDateFrom: today.startOf('month').format('YYYY/MM/DD'),
      paymentDateTo: today.endOf('month').format('YYYY/MM/DD'),
      searchType: 1,
    })
    const factoringDocuments = await documentRepository.loadFactoringDocuments(true)
    return {
      unapprovedDocuments,
      latestDocuments,
      thisMonthPayDocuments: thisMonthPayDocuments.documents,
      contractSummaries: contractSummaries.slice(0, 3),
      factoringDocuments,
    }
  }),
  fetchUnapprovedDocuments: createAsyncAction<
    void,
    {
      unapprovedDocuments: DocumentSearchResultItem[]
    }
  >('fetchUnapprovedDocuments', async (params, { documentRepository }) => {
    const unapprovedDocuments = await documentRepository.loadUnApprovedDocuments()

    return {
      unapprovedDocuments,
    }
  }),
  fetchLatestDocuments: createAsyncAction<
    void,
    {
      latestDocuments: DocumentSearchResultItem[]
    }
  >('fetchLatestDocuments', async (params, { documentRepository }) => {
    const latestDocuments = await documentRepository.loadLatestDocuments()
    return {
      latestDocuments,
    }
  }),
  fetchThisMonthPayDocuments: createAsyncAction<
    void,
    {
      thisMonthPayDocuments: DocumentSearchResultItem[]
    }
  >('fetchThisMonthPayDocuments', async (params, { documentRepository }) => {
    const today = dayjs()
    const thisMonthPayDocuments = await documentRepository.searchDocuments({
      documentTypes: {
        type1: false,
        type2: false,
        type3: false,
        type4: false,
        type5: false,
        type6: false,
        type7: false,
        type8: false,
      },
      paymentDateFrom: today.startOf('month').format('YYYY/MM/DD'),
      paymentDateTo: today.endOf('month').format('YYYY/MM/DD'),
      searchType: 1,
      silent: true,
    })
    return {
      thisMonthPayDocuments: thisMonthPayDocuments.documents,
    }
  }),
  fetchContractSummaries: createAsyncAction<
    {
      silent?: boolean
    },
    {
      contractSummaries: ContractSummary[]
    }
  >('fetchContractSummaries', async (params, { contractRepository }) => {
    const contractSummaries = await contractRepository.loadActiveContracts(params.silent)
    return {
      contractSummaries: contractSummaries.slice(0, 3),
    }
  }),
  fetchFactoringDocuments: createAsyncAction<
    {
      silent?: boolean
    },
    {
      factoringDocuments: DocumentSearchResultItem[]
    }
  >('fetchFactoringDocuments', async (params, { documentRepository }) => {
    const factoringDocuments = await documentRepository.loadFactoringDocuments(params.silent)
    return {
      factoringDocuments,
    }
  }),
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialDashboardState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(DashboardActions.fetchDashboard.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
    builder.addCase(DashboardActions.fetchData.fulfilled, (state, action) => {
      state.unapprovedDocuments = action.payload.unapprovedDocuments
      state.latestDocuments = action.payload.latestDocuments
      state.thisMonthPayDocuments = action.payload.thisMonthPayDocuments
      state.contractSummaries = action.payload.contractSummaries
      state.factoringDocuments = action.payload.factoringDocuments
    })
    builder.addCase(DashboardActions.fetchUnapprovedDocuments.fulfilled, (state, action) => {
      state.unapprovedDocuments = action.payload.unapprovedDocuments
    })
    builder.addCase(DashboardActions.fetchLatestDocuments.fulfilled, (state, action) => {
      state.latestDocuments = action.payload.latestDocuments
    })
    builder.addCase(DashboardActions.fetchThisMonthPayDocuments.fulfilled, (state, action) => {
      state.thisMonthPayDocuments = action.payload.thisMonthPayDocuments
    })
    builder.addCase(DashboardActions.fetchContractSummaries.fulfilled, (state, action) => {
      state.contractSummaries = action.payload.contractSummaries
    })
    builder.addCase(DashboardActions.fetchFactoringDocuments.fulfilled, (state, action) => {
      state.factoringDocuments = action.payload.factoringDocuments
    })
  },
})

export default dashboardSlice

import { useFormContext } from 'react-hook-form'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SectionPanel from '../../molecules/SectionPanel'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import SearchField from '../../molecules/inputs/SearchField'
import FormItem from '../../molecules/inputs/FormItem'
import CheckboxControl from '../../atoms/inputs/CheckboxControl'
import PhaseStatusSingleSelect from '../../molecules/inputs/PhaseStatusSingleSelect'
import Label from '../../atoms/display/Label'
import DateControl from '../../atoms/inputs/DateControl'
import RadioControl from '../../atoms/inputs/RadioControl'
import useConsts from '../../../hooks/useConsts'
import { TransactionSearchParams } from '../../../types/search'
import { TransactionActions } from '../../../store/transactions'
import NumberControl from '../../atoms/inputs/NumberControl'
import Checkbox from '../../atoms/inputs/Checkbox'
import NumberField from '../../atoms/inputs/NumberField'
import { useTransactionSearchConditionListDialog } from '../../../hooks/useDialog'
import { TransactionSelectors } from '../../../store/transactions/selectors'

export default function TransactionSearchForm() {
  const transactionSearchConditionListDialog = useTransactionSearchConditionListDialog()
  const { control, register, setValue } = useFormContext<TransactionSearchParams>()
  const searchingTransactions = useSelector(TransactionSelectors.searchingTransactions)
  const { phaseName } = useConsts()
  const searchParams = useSelector(TransactionSelectors.searchParams)
  const dispatch = useDispatch()

  useEffect(() => {
    setValue('keyword', searchParams.keyword)
    setValue('phases.phase1', searchParams.phases.phase1)
    setValue('phases.phase2', searchParams.phases.phase2)
    setValue('phases.phase3', searchParams.phases.phase3)
    setValue('phases.phase4', searchParams.phases.phase4)
    setValue('phases.phase5', searchParams.phases.phase5)
    setValue('phases.phase6', searchParams.phases.phase6)
    setValue('phases.phase7', searchParams.phases.phase7)
    setValue('status', searchParams.status)
    setValue('amountMin', searchParams.amountMin)
    setValue('amountMax', searchParams.amountMax)
    setValue('paymentDateFrom', searchParams.paymentDateFrom)
    setValue('paymentDateTo', searchParams.paymentDateTo)
    setValue('closingDateFrom', searchParams.closingDateFrom)
    setValue('closingDateTo', searchParams.closingDateTo)
    setValue('searchType', searchParams.searchType)
  }, [searchParams, setValue])

  const handleReset = useCallback(() => {
    dispatch(
      TransactionActions.saveSearchParams({
        searchParams: {
          keyword: '',
          phases: {
            phase1: false,
            phase2: false,
            phase3: false,
            phase4: false,
            phase5: false,
            phase6: false,
            phase7: false,
            phase8: false,
          },
          status: 0,
          paymentDateFrom: undefined,
          paymentDateTo: undefined,
          closingDateFrom: undefined,
          closingDateTo: undefined,
          amountMin: null,
          amountMax: null,
          searchType: 1,
          isFavorite: false,
        },
      })
    )
    setValue('keyword', '')
    setValue('phases.phase1', false)
    setValue('phases.phase2', false)
    setValue('phases.phase3', false)
    setValue('phases.phase4', false)
    setValue('phases.phase5', false)
    setValue('phases.phase6', false)
    setValue('phases.phase7', false)
    setValue('status', 0)
    setValue('amountMin', null)
    setValue('amountMax', null)
    setValue('paymentDateFrom', null)
    setValue('paymentDateTo', null)
    setValue('closingDateFrom', null)
    setValue('closingDateTo', null)
    setValue('searchType', 1)
    setValue('isFavorite', false)
  }, [dispatch, setValue])

  return (
    <SectionPanel title="検索条件" size="full">
      <FlexColumn>
        <Button size="lg" onClick={() => transactionSearchConditionListDialog.open()}>
          お気に入り検索条件
        </Button>
        <SearchField register={register('keyword')} />
        <FormItem label="フェーズ">
          {searchingTransactions ? (
            <FlexRow wrap>
              <Checkbox label={phaseName(1)} />
              <Checkbox label={phaseName(2)} />
              <Checkbox label={phaseName(3)} />
              <Checkbox label={phaseName(4)} />
              <Checkbox label={phaseName(5)} />
              <Checkbox label={phaseName(6)} />
              <Checkbox label={phaseName(7)} />
            </FlexRow>
          ) : (
            <FlexRow wrap>
              <CheckboxControl name="phases.phase1" label={phaseName(1)} control={control} />
              <CheckboxControl name="phases.phase2" label={phaseName(2)} control={control} />
              <CheckboxControl name="phases.phase3" label={phaseName(3)} control={control} />
              <CheckboxControl name="phases.phase4" label={phaseName(4)} control={control} />
              <CheckboxControl name="phases.phase5" label={phaseName(5)} control={control} />
              <CheckboxControl name="phases.phase6" label={phaseName(6)} control={control} />
              <CheckboxControl name="phases.phase7" label={phaseName(7)} control={control} />
            </FlexRow>
          )}
        </FormItem>
        <FormItem label="ステータス">
          <PhaseStatusSingleSelect name="status" control={control} />
        </FormItem>
        {searchingTransactions ? (
          <Checkbox label="お気に入りのみ" />
        ) : (
          <CheckboxControl name="isFavorite" label="お気に入りのみ" control={control} />
        )}
        <FlexRow wrap>
          <FormItem label="金額">
            {searchingTransactions ? (
              <FlexRow align="center">
                <NumberField size="xs" />
                <Label text="〜" />
                <NumberField size="xs" />
              </FlexRow>
            ) : (
              <FlexRow align="center">
                <NumberControl name="amountMin" control={control} size="xs" />
                <Label text="〜" />
                <NumberControl name="amountMax" control={control} size="xs" />
              </FlexRow>
            )}
          </FormItem>
          <FormItem label="支払期限">
            <FlexRow align="center">
              <DateControl name="paymentDateFrom" control={control} />
              <Label text="〜" />
              <DateControl name="paymentDateTo" control={control} />
            </FlexRow>
          </FormItem>
          <FormItem label="締め日">
            <FlexRow align="center">
              <DateControl name="closingDateFrom" control={control} />
              <Label text="〜" />
              <DateControl name="closingDateTo" control={control} />
            </FlexRow>
          </FormItem>
        </FlexRow>
        <RadioControl
          items={[
            { value: 1, label: 'フェーズごとに表示' },
            { value: 2, label: '項目ごとに表示' },
          ]}
          name="searchType"
          control={control}
          row
        />
        <FlexRow>
          <Button type="submit">検索</Button>
          <Button variant="outlined" onClick={handleReset}>
            リセット
          </Button>
        </FlexRow>
      </FlexColumn>
    </SectionPanel>
  )
}

import { Component } from '../../types/react'
import { FlexColumn } from '../atoms/layout/Flex'
import Label from '../atoms/display/Label'
import Card, { CardSize } from '../atoms/surfaces/Card'

type SectionPanel = {
  title: string
  size?: CardSize
  children: Component
}

export default function SectionPanel({ title, size = 'lg', children }: SectionPanel) {
  return (
    <Card size={size}>
      <FlexColumn space={0}>
        <Label text={title} variant="subtitle" />
        {children}
      </FlexColumn>
    </Card>
  )
}

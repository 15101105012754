import MuiStepper from '@mui/material/Stepper'
import MuiStep from '@mui/material/Step'
import MuiStepLabel from '@mui/material/StepLabel'
import MuiStepIcon from '@mui/material/StepIcon'
import { ReactNode } from 'react'
import MuiBox from '@mui/material/Box'
import { styled } from '@mui/material'
import MuiBadge from '@mui/material/Badge'

type Step = {
  id: string
  label: string
  icon: ReactNode
  badge?: boolean
}

type StepViewProps = {
  steps: Step[]
  activeStep?: number
}

const StepBadge = styled(MuiBadge)(() => ({
  '.MuiBadge-dot': {
    top: '6%',
  },
}))

function BadgeWrapper(props: { badge?: boolean; children: ReactNode }) {
  const { badge, children } = props
  if (badge) {
    return (
      <StepBadge color="warning" variant="dot" overlap="circular">
        {children}
      </StepBadge>
    )
  }
  return <>{children}</>
}

export default function StepView({ steps, activeStep }: StepViewProps) {
  return (
    <MuiStepper sx={{ width: '100%', paddingTop: '2px' }} activeStep={activeStep}>
      {steps.map((step) => (
        <MuiStep key={step.id}>
          <BadgeWrapper badge={step.badge}>
            <MuiBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <MuiStepIcon icon={step.icon} />
              <MuiStepLabel>{step.label}</MuiStepLabel>
            </MuiBox>
          </BadgeWrapper>
        </MuiStep>
      ))}
    </MuiStepper>
  )
}

import dayjs from 'dayjs'
import { ReservedMessageRepeatType, ReservedMessageSetting } from '../types/Chat'

function generateRepeatType1ScheduleExpression(setting: ReservedMessageSetting) {
  const timeValues = setting.repeatTime.split(':')
  const minute = timeValues[1]
  const hour = timeValues[0]
  return `cron(${minute} ${hour} * * ? *)`
}

function generateRepeatType2ScheduleExpression(setting: ReservedMessageSetting) {
  const timeValues = setting.repeatTime.split(':')
  const minute = timeValues[1]
  const hour = timeValues[0]
  return `cron(${minute} ${hour} * * ${setting.repeatDayOfWeek} *)`
}

function generateRepeatType3ScheduleExpression(setting: ReservedMessageSetting) {
  const timeValues = setting.repeatTime.split(':')
  const minute = timeValues[1]
  const hour = timeValues[0]
  return `cron(${minute} ${hour} 8-14,22-28 * ${setting.repeatDayOfWeek} *)`
}

function generateRepeatType4ScheduleExpression(setting: ReservedMessageSetting) {
  const timeValues = setting.repeatTime.split(':')
  const minute = timeValues[1]
  const hour = timeValues[0]
  return `cron(${minute} ${hour} ${setting.repeatDayOfMonth} * ? *)`
}

function generateRepeatType5ScheduleExpression(setting: ReservedMessageSetting) {
  const timeValues = setting.repeatTime.split(':')
  const minute = timeValues[1]
  const hour = timeValues[0]
  const baseDate = dayjs(setting.repeatBaseDate, 'YYYY/MM/DD')
  const month1 = baseDate.month() + 1
  const month2 = baseDate.add(3, 'month').month() + 1
  const month3 = baseDate.add(6, 'month').month() + 1
  const month4 = baseDate.add(9, 'month').month() + 1
  const months = [month1, month2, month3, month4].sort((a, b) => a - b)
  return `cron(${minute} ${hour} ${baseDate.date()} ${months.join(',')} ? *)`
}

function generateRepeatType6ScheduleExpression(setting: ReservedMessageSetting) {
  const timeValues = setting.repeatTime.split(':')
  const minute = timeValues[1]
  const hour = timeValues[0]
  const baseDate = dayjs(setting.repeatBaseDate, 'YYYY/MM/DD')
  const month1 = baseDate.month() + 1
  const month2 = baseDate.add(6, 'month').month() + 1
  const months = [month1, month2].sort((a, b) => a - b)
  return `cron(${minute} ${hour} ${baseDate.day()} ${months.join(',')} ? *)`
}

function generateRepeatType7ScheduleExpression(setting: ReservedMessageSetting) {
  const timeValues = setting.repeatTime.split(':')
  const minute = timeValues[1]
  const hour = timeValues[0]
  const baseDate = dayjs(setting.repeatBaseDate, 'YYYY/MM/DD')
  const month = baseDate.month() + 1
  return `cron(${minute} ${hour} ${baseDate.day()} ${month} ? *)`
}

function parseCronDay(day: string) {
  if (day === '*') {
    return '01'
  }
  if (day === '8-14,22-28') {
    return '01'
  }
  return day.padStart(2, '0')
}

function parseCronMonth(month: string) {
  if (month === '*') {
    return 1
  }
  const months = month.split(',')
  const currentMonth = dayjs().month() + 1
  if (months.length > 1) {
    for (let i = 0; i < months.length; i += 1) {
      const m = Number(months[i])
      if (currentMonth <= m) {
        return `${m}`.padStart(2, '0')
      }
    }
    return months[0].padStart(2, '0')
  }
  return month.padStart(2, '0')
}

function parseCronExpression(cron: string): ReservedMessageSetting {
  const values = cron.split(' ')
  const minute = values[0]
  const hour = values[1]
  const day = values[2]
  const month = values[3]
  const dayOfWeek = values[4]
  let repeatType = 1
  if (day === '*') {
    if (dayOfWeek !== '?') {
      repeatType = 2
    }
  } else if (day === '8-14,22-28') {
    repeatType = 3
  } else if (month === '*') {
    repeatType = 4
  } else {
    const months = month.split(',')
    if (months.length === 4) {
      repeatType = 5
    } else if (months.length === 2) {
      repeatType = 6
    } else {
      repeatType = 7
    }
  }
  return {
    type: 'repeat',
    date: '',
    time: '',
    repeatType: repeatType as ReservedMessageRepeatType,
    repeatTime: `${hour}:${minute}`,
    repeatDayOfWeek: dayOfWeek !== '?' ? Number(dayOfWeek) : 1,
    repeatDayOfMonth: Number(parseCronDay(day)),
    repeatBaseDate: `${parseCronMonth(month)}/${parseCronDay(day)}`,
  }
}

function parseScheduleExpression(date: string, time: string): ReservedMessageSetting {
  return {
    type: 'schedule',
    date,
    time,
    repeatType: 1,
    repeatTime: '',
    repeatDayOfWeek: 1,
    repeatDayOfMonth: 1,
    repeatBaseDate: '',
  }
}

export default function useScheduleExpression() {
  return {
    generate: (setting: ReservedMessageSetting) => {
      if (setting.type === 'schedule') {
        return `at(${dayjs(setting.date).format('YYYY-MM-DD')}T${setting.time}:00)`
      }
      switch (setting.repeatType) {
        case 1: // 毎日
          return generateRepeatType1ScheduleExpression(setting)
        case 2: // 毎週
          return generateRepeatType2ScheduleExpression(setting)
        case 3: // 隔週
          return generateRepeatType3ScheduleExpression(setting)
        case 4: // 毎月
          return generateRepeatType4ScheduleExpression(setting)
        case 5: // 四半期ごと
          return generateRepeatType5ScheduleExpression(setting)
        case 6: // 半年ごと
          return generateRepeatType6ScheduleExpression(setting)
        case 7: // 毎年
          return generateRepeatType7ScheduleExpression(setting)
        default:
          return ''
      }
    },
    parse: (expression: string): ReservedMessageSetting => {
      const cron = expression.match(/^cron\((.*)\)$/)
      if (cron) {
        return parseCronExpression(cron[1])
      }
      const at = expression.match(/^at\((.*)T(.*)\)$/)
      if (at) {
        return parseScheduleExpression(at[1], at[2])
      }
      throw new Error('Invalid expression')
    },
  }
}

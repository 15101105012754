import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Decimal from 'decimal.js-light'
import { DocumentsActions } from '../store/documents'
import { DocumentsSelectors } from '../store/documents/selectors'
import { Contract, Contractee, Document, DocumentAttributes, DocumentItem, DocumentType } from '../types/Document'
import useDay from './useDay'
import usePath from './usePath'
import { Moneyspace } from '../types/Moneyspace'

export default function useDocument() {
  const { formatDate, closingDate, paymentDate } = useDay()
  const document = useSelector(DocumentsSelectors.document)
  const documentDiffs = useSelector(DocumentsSelectors.documentDiffs)
  const activities = useSelector(DocumentsSelectors.activities)
  const approvalFlowState = useSelector(DocumentsSelectors.approvalFlowState)
  const { document: documentPath } = usePath()
  const dispatch = useDispatch()
  const newDocument = useCallback(
    (type: DocumentType): DocumentAttributes => ({
      name: '',
      type,
      publishedAt: formatDate(new Date()),
      japaneseCalenderDisplaying: true,
      note: '',
      isTaxIn: true,
      contractee: undefined,
      contractor: undefined,
      details: [],
    }),
    [formatDate]
  )
  const newContractor = useCallback(
    () => ({
      name: '',
      representativeName: undefined,
      representativeRole: undefined,
      workspaceDomain: undefined,
      zipCode: undefined,
      address: undefined,
      bankName: undefined,
      bankCode: undefined,
      branchName: undefined,
      branchCode: undefined,
      accountType: 1,
      accountNumber: undefined,
      accountOwner: undefined,
    }),
    []
  )
  const newContractee = useCallback(
    (): Contractee => ({
      name: '',
      representativeName: undefined,
      representativeRole: undefined,
      uid: undefined,
      zipCode: undefined,
      address: undefined,
      closingDate: '',
      paymentDate: '',
      bearingFee: true,
    }),
    []
  )
  const load = useCallback(
    (id: string, handleCompleted?: () => void) => {
      dispatch(DocumentsActions.fetchDocument({ id, handleCompleted }))
    },
    [dispatch]
  )
  const loadDiffs = useCallback(
    (id: string) => {
      dispatch(DocumentsActions.fetchDocumentDiffs({ id }))
    },
    [dispatch]
  )
  const loadApprovalFlowState = useCallback(
    (id: string) => {
      dispatch(DocumentsActions.fetchApprovalFlowState({ id }))
    },
    [dispatch]
  )
  const save = useCallback(
    (moneyspaceId: string, contractId: string, id: string, doc: DocumentAttributes) => {
      dispatch(
        DocumentsActions.saveDocument({
          id,
          document: {
            name: doc.name,
            type: doc.type,
            publishedAt: doc.publishedAt,
            japaneseCalenderDisplaying: doc.japaneseCalenderDisplaying,
            note: doc.note,
            isTaxIn: doc.isTaxIn,
            contractee: doc.contractee,
            contractor: doc.contractor,
            details: doc.details,
            deliveryDateFrom: doc.deliveryDateFrom,
            deliveryDateTo: doc.deliveryDateTo,
          },
          getRedirectPath: (updatedDoc: Document) => documentPath(moneyspaceId, contractId, updatedDoc.id),
        })
      )
    },
    [dispatch, documentPath]
  )
  const deleteDocument = useCallback(
    (id: string) => {
      dispatch(DocumentsActions.deleteDocument({ id }))
    },
    [dispatch]
  )
  const applyDocument = useCallback(
    (id: string, flowId: string) => {
      dispatch(DocumentsActions.applyDocument({ id, flowId }))
    },
    [dispatch]
  )
  const updateApprovalFlow = useCallback(
    (id: string, flowId: string) => {
      dispatch(DocumentsActions.updateApprovalFlow({ id, flowId }))
    },
    [dispatch]
  )
  const approveDocument = useCallback(
    (moneyspaceId: string, contract: Contract, doc: Document) => {
      if (doc.type === 7 || doc.status === 2 || contract.documents[contract.documents.length - 1].id === doc.id) {
        dispatch(DocumentsActions.approveDocument({ id: doc.id }))
      } else {
        const nextDocIndex = contract.documents.findIndex((item) => item.id === doc.id) + 1
        const nextDoc = contract.documents[nextDocIndex]
        dispatch(
          DocumentsActions.approveDocument({
            id: doc.id,
            getRedirectPath: () => documentPath(moneyspaceId, contract.id, nextDoc.id),
          })
        )
      }
    },
    [dispatch, documentPath]
  )
  const rejectDocument = useCallback(
    (id: string, note?: string) => {
      dispatch(DocumentsActions.rejectDocument({ id, note }))
    },
    [dispatch]
  )
  const copyDocument = useCallback(
    (src: Document, dest: Document) => {
      dispatch(
        DocumentsActions.saveDocument({
          id: dest.id,
          document: {
            name: src.name,
            type: dest.type,
            publishedAt: src.publishedAt,
            japaneseCalenderDisplaying: src.japaneseCalenderDisplaying,
            note: src.note,
            isTaxIn: src.isTaxIn,
            contractee: src.contractee,
            contractor: src.contractor,
            details: src.details.map((item) => ({ ...item, id: undefined })),
          },
        })
      )
    },
    [dispatch]
  )
  const pdfUrl = useCallback((id: string) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL
    if (baseUrl) {
      return `${baseUrl}/documents/${id}/pdf`
    }
    return ''
  }, [])
  const calcDetailAmount = useCallback((item: DocumentItem) => {
    if (item.unitPrice && item.quantity) {
      return Math.floor(new Decimal(item.unitPrice).times(item.quantity).toNumber())
    }
    return 0
  }, [])
  const convertContractee = useCallback(
    (moneyspace: Moneyspace): Contractee | undefined => {
      const company = moneyspace.contractee ?? moneyspace.client
      if (company) {
        return {
          ...company,
          closingDate: closingDate(moneyspace.closingDay ?? 1),
          bearingFee: moneyspace.bearingFee ?? true,
          paymentDate: paymentDate(moneyspace.paymentMonth, moneyspace.paymentDay ?? 1),
        }
      }
      return undefined
    },
    [closingDate, paymentDate]
  )
  return {
    document,
    documentDiffs,
    activities,
    approvalFlowState,
    newDocument,
    newContractor,
    newContractee,
    load,
    loadDiffs,
    loadApprovalFlowState,
    save,
    deleteDocument,
    applyDocument,
    updateApprovalFlow,
    approveDocument,
    rejectDocument,
    copyDocument,
    pdfUrl,
    calcDetailAmount,
    convertContractee,
  }
}

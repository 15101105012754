import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Bank } from '../types/Company'

export default class BankRepository {
  instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_BANK_API_BASE_URL,
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json',
    })
  }

  async searchBanks({ code, name }: { code?: string; name?: string }) {
    if (code || name) {
      const config: AxiosRequestConfig = {
        params: { code, name, kana: name },
      }
      const response = await this.instance.get<Bank[]>(`/banks/search.json`, config)
      return response.data
    }
    return []
  }

  async searchBankBranches(bankCode: string, { code, name }: { code?: string; name?: string }) {
    if (code || name) {
      const config: AxiosRequestConfig = {
        params: { code, name, kana: name },
      }
      const response = await this.instance.get<Bank[]>(`/banks/${bankCode}/branches/search.json`, config)
      return response.data
    }
    return []
  }
}

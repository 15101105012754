// eslint-disable-next-line max-classes-per-file
import { AsyncThunk, createAsyncThunk, Dispatch } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'
import { StoreState, ThunkExtra } from './index'
import SessionRepository from '../repositories/SessionRepository'
import { ErrorHandler, Params, Request } from '../repositories/Request'
import systemSlice from './system'
import CompanyRepository from '../repositories/CompanyRepository'
import UserRepository from '../repositories/UserRepository'
import MoneyspaceRepository from '../repositories/MoneyspaceRepository'
import DocumentRepository from '../repositories/DocumentRepository'
import ContractRepository from '../repositories/ContractRepository'
import SystemRepository from '../repositories/SystemRepository'
import BankRepository from '../repositories/BankRepository'
import ChatRepository from '../repositories/ChatRepository'
import TransactionRepository from '../repositories/TransactionRepository'

export type Repositories = {
  systemRepository: SystemRepository
  sessionRepository: SessionRepository
  companyRepository: CompanyRepository
  userRepository: UserRepository
  moneyspaceRepository: MoneyspaceRepository
  documentRepository: DocumentRepository
  transactionRepository: TransactionRepository
  contractRepository: ContractRepository
  bankRepository: BankRepository
  chatRepository: ChatRepository
}

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

class RetryableRequest implements Request {
  request: Request

  constructor(request: Request) {
    this.request = request
  }

  async get<T>(path: string, params?: Params, errorHandler?: ErrorHandler<T>): Promise<AxiosResponse<T>> {
    try {
      return await this.request.get<T>(path, params, errorHandler)
    } catch (e) {
      await sleep(2000)
      return this.request.get<T>(path, params, errorHandler)
    }
  }

  async download(
    filename: string,
    path: string,
    params?: Params,
    errorHandler?: ErrorHandler<void>
  ): Promise<AxiosResponse<void>> {
    try {
      return await this.request.download(filename, path, params, errorHandler)
    } catch (e) {
      await sleep(1000)
      return this.request.download(filename, path, params, errorHandler)
    }
  }

  async post<T>(path: string, params?: Params): Promise<AxiosResponse<T>> {
    return this.request.post<T>(path, params)
  }

  async patch<T>(path: string, params?: Params): Promise<AxiosResponse<T>> {
    return this.request.patch<T>(path, params)
  }

  async put<T>(path: string, params?: Params): Promise<AxiosResponse<T>> {
    return this.request.put<T>(path, params)
  }

  async delete(path: string, params?: Params): Promise<AxiosResponse> {
    return this.request.delete(path, params)
  }

  setAuthToken(authToken?: string): void {
    this.request.setAuthToken(authToken)
  }
}

class WrappedRequest implements Request {
  request: Request

  dispatch: Dispatch

  constructor(request: Request, dispatch: Dispatch) {
    this.request = request
    this.dispatch = dispatch
  }

  async get<T>(
    path: string,
    params?: Params,
    errorHandler?: ErrorHandler<T>,
    silent?: boolean
  ): Promise<AxiosResponse<T>> {
    this.dispatch(systemSlice.actions.beginRequest({ silent }))
    try {
      return await this.request.get<T>(path, params, errorHandler)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.dispatch(systemSlice.actions.endRequest({ error: e, silent }))
      }
      return Promise.reject(e)
    } finally {
      this.dispatch(systemSlice.actions.endRequest({ silent }))
    }
  }

  async download(
    filename: string,
    path: string,
    params?: Params,
    errorHandler?: ErrorHandler<void>,
    silent?: boolean
  ): Promise<AxiosResponse<void>> {
    this.dispatch(systemSlice.actions.beginRequest({ silent }))
    try {
      return await this.request.download(filename, path, params, errorHandler)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.dispatch(systemSlice.actions.endRequest({ error: e, silent }))
      }
      return Promise.reject(e)
    } finally {
      this.dispatch(systemSlice.actions.endRequest({ silent }))
    }
  }

  async post<T>(path: string, params?: Params, silent?: boolean): Promise<AxiosResponse<T>> {
    this.dispatch(systemSlice.actions.beginRequest({ silent }))
    try {
      return await this.request.post<T>(path, params)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.dispatch(systemSlice.actions.endRequest({ error: e, silent }))
      }
      return Promise.reject(e)
    } finally {
      this.dispatch(systemSlice.actions.endRequest({ silent }))
    }
  }

  async patch<T>(path: string, params?: Params, silent?: boolean): Promise<AxiosResponse<T>> {
    this.dispatch(systemSlice.actions.beginRequest({ silent }))
    try {
      return await this.request.patch<T>(path, params)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.dispatch(systemSlice.actions.endRequest({ error: e, silent }))
      }
      return Promise.reject(e)
    } finally {
      this.dispatch(systemSlice.actions.endRequest({ silent }))
    }
  }

  async put<T>(path: string, params?: Params, silent?: boolean): Promise<AxiosResponse<T>> {
    this.dispatch(systemSlice.actions.beginRequest({ silent }))
    try {
      return await this.request.put<T>(path, params)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.dispatch(systemSlice.actions.endRequest({ error: e, silent }))
      }
      return Promise.reject(e)
    } finally {
      this.dispatch(systemSlice.actions.endRequest({ silent }))
    }
  }

  async delete(path: string, params?: Params, silent?: boolean): Promise<AxiosResponse> {
    this.dispatch(systemSlice.actions.beginRequest({ silent }))
    try {
      return await this.request.delete(path, params)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.dispatch(systemSlice.actions.endRequest({ error: e, silent }))
      }
      return Promise.reject(e)
    } finally {
      this.dispatch(systemSlice.actions.endRequest({ silent }))
    }
  }

  setAuthToken(authToken?: string): void {
    this.request.setAuthToken(authToken)
  }
}

export type GetRedirectPath<T> = (value: T) => string

export function createAsyncAction<P, R>(
  actionName: string,
  action: (params: P, repositories: Repositories, state: StoreState, dispatch: Dispatch) => Promise<R>
): AsyncThunk<R, P, { extra: ThunkExtra; state: StoreState }> {
  return createAsyncThunk<R, P, { extra: ThunkExtra; state: StoreState }>(
    actionName,
    async (params, { extra, getState, dispatch, rejectWithValue }) => {
      const state = getState()
      const { token } = state.session
      extra.request.setAuthToken(token)
      const wrappedRequest = new WrappedRequest(new RetryableRequest(extra.request), dispatch)

      try {
        return await action(
          params,
          {
            systemRepository: new SystemRepository(wrappedRequest),
            sessionRepository: new SessionRepository(wrappedRequest),
            companyRepository: new CompanyRepository(wrappedRequest),
            userRepository: new UserRepository(wrappedRequest),
            moneyspaceRepository: new MoneyspaceRepository(wrappedRequest),
            documentRepository: new DocumentRepository(wrappedRequest),
            transactionRepository: new TransactionRepository(wrappedRequest),
            contractRepository: new ContractRepository(wrappedRequest),
            bankRepository: new BankRepository(),
            chatRepository: new ChatRepository(wrappedRequest),
          },
          state,
          dispatch
        )
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const { response } = e
          return rejectWithValue({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: response?.data,
            status: response?.status,
          })
        }
        return Promise.reject(e)
      }
    }
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useRouter from '../../../hooks/useRouter'
import Form from '../../../components/atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import FormItem from '../../../components/molecules/inputs/FormItem'
import BankCodeField from '../../../components/molecules/inputs/BankCodeField'
import BankNameField from '../../../components/molecules/inputs/BankNameField'
import BankBranchOfficeCodeField from '../../../components/molecules/inputs/BankBranchOfficeCodeField'
import BankBranchOfficeNameField from '../../../components/molecules/inputs/BankBranchOfficeNameField'
import BankAccountTypeField from '../../../components/molecules/inputs/BankAccountTypeField'
import BankAccountNumberField from '../../../components/molecules/inputs/BankAccountNumberField'
import BankAccountNameField from '../../../components/molecules/inputs/BankAccountNameField'
import { CompanySelectors } from '../../../store/company/selectors'
import { CompanyActions } from '../../../store/company'

type BankParams = {
  bankName?: string
  bankCode?: string
  branchName?: string
  branchCode?: string
  accountType?: number
  accountNumber?: string
  accountOwner?: string
}

export default function RegisterContracteeBankPage() {
  const company = useSelector(CompanySelectors.company)
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    bankName: rules.bankName,
    bankCode: rules.bankCode,
    branchName: rules.branchName,
    branchCode: rules.branchCode,
    accountNumber: rules.accountNumber,
    accountOwner: rules.accountOwner,
  })
  const form = useForm<BankParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      bankName: company?.bankName,
      bankCode: company?.bankCode,
      branchName: company?.branchName,
      branchCode: company?.branchCode,
      accountType: company?.accountType,
      accountNumber: company?.accountNumber,
      accountOwner: company?.accountOwner,
    },
  })
  const router = useRouter()
  const dispatch = useDispatch()

  const handleNext = useCallback(
    (params: BankParams) => {
      if (company) {
        dispatch(
          CompanyActions.saveCompany({
            id: company.id,
            company: {
              ...company,
              bankName: params.bankName,
              bankCode: params.bankCode,
              branchName: params.branchName,
              branchCode: params.branchCode,
              accountType: params.accountType ?? 1,
              accountNumber: params.accountNumber,
              accountOwner: params.accountOwner,
            },
          })
        )
      }
      router.registerContracteeLine()
    },
    [dispatch, router, company]
  )

  return (
    <>
      <Form onSubmit={form.handleSubmit(handleNext)}>
        <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
          <FlexColumn align="center">
            <Label text="振込先口座情報" variant="title" />
            <FlexColumn width="360px" scroll={false}>
              <FlexRow wrap scroll={false}>
                <FormItem label="銀行コード">
                  <BankCodeField register={form.register('bankCode')} error={form.formState.errors.bankCode} />
                </FormItem>
                <FormItem label="銀行名">
                  <BankNameField register={form.register('bankName')} error={form.formState.errors.bankName} />
                </FormItem>
              </FlexRow>
              <FlexRow wrap scroll={false}>
                <FormItem label="支店コード">
                  <BankBranchOfficeCodeField
                    register={form.register('branchCode')}
                    error={form.formState.errors.branchCode}
                  />
                </FormItem>
                <FormItem label="支店名">
                  <BankBranchOfficeNameField
                    register={form.register('branchName')}
                    error={form.formState.errors.branchName}
                  />
                </FormItem>
              </FlexRow>
              <FormItem label="口座種別">
                <BankAccountTypeField name="accountType" control={form.control} />
              </FormItem>
              <FormItem label="口座番号">
                <BankAccountNumberField
                  register={form.register('accountNumber')}
                  error={form.formState.errors.accountNumber}
                />
              </FormItem>
              <FormItem label="口座名（半角カナ）">
                <BankAccountNameField
                  register={form.register('accountOwner')}
                  error={form.formState.errors.accountOwner}
                />
              </FormItem>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn align="center" scroll={false}>
            <Button variant="text" size="lg" onClick={router.registerContracteeLine}>
              この項目をスキップ
            </Button>
            <Button type="submit" size="lg">
              次へ
            </Button>
            <FlexRow justify="center">
              <DotPagination count={11} current={9} />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </Form>
    </>
  )
}

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import usePage from '../hooks/usePage'
import { FlexColumn } from '../components/atoms/layout/Flex'
import Label from '../components/atoms/display/Label'
import Pagination from '../components/atoms/navigation/Pagination'
import { TransactionSelectors } from '../store/transactions/selectors'
import { TransactionActions } from '../store/transactions'
import PaginationCountLabel from '../components/molecules/display/PaginationCountLabel'
import { SortDirection } from '../types/System'
import useRouter from '../hooks/useRouter'
import TransactionSummaryTable from '../components/molecules/display/TransactionSummaryTable'

export default function ActiveTransactionsPage() {
  const { changeTitle } = usePage()
  const { activeTransactions } = useRouter()
  const dispatch = useDispatch()
  const transactions = useSelector(TransactionSelectors.activeTransactions)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const sortColumn = query.get('column') ?? undefined
  const sortDirection = (query.get('direction') as SortDirection) ?? undefined
  const page = Number(query.get('page') ?? 1)
  useEffect(() => {
    changeTitle('進行中の取引')
  }, [changeTitle])
  useEffect(() => {
    dispatch(TransactionActions.searchActiveTransactions({ page, sortColumn, sortDirection }))
  }, [dispatch, page, sortColumn, sortDirection])
  const handleSort = (name: string, direction: SortDirection) => {
    activeTransactions(page, name, direction)
    window.scrollTo(0, 0)
  }
  const handlePage = (pageNumber: number) => {
    activeTransactions(pageNumber, sortColumn, sortDirection)
    window.scrollTo(0, 0)
  }
  return (
    <FlexColumn>
      {transactions.count === 0 ? (
        <Label text="進行中の取引はありません" />
      ) : (
        <>
          <PaginationCountLabel page={page} contents={transactions} />
          <TransactionSummaryTable
            transactions={transactions.results}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={handleSort}
          />
          <Pagination count={transactions.count} page={page} size={30} onChange={handlePage} />
        </>
      )}
    </FlexColumn>
  )
}

import { Control } from 'react-hook-form'
import { User } from '../../../types/User'
import Checkboxies from '../../atoms/inputs/Checkboxies'
import Avatar from '../../atoms/display/Avatar'

type UserSelectFieldProps = {
  users: User[]
  name: string
  control: Control
  defaultCheckedValues?: string[]
}

export default function UserSelectField({ users, name, control, defaultCheckedValues }: UserSelectFieldProps) {
  const items = users.map((user) => ({
    label: user.name,
    avatar: <Avatar url={user.icon} alt={user.name} />,
    value: user.id,
  }))
  return <Checkboxies items={items} column name={name} control={control} defaultCheckedValues={defaultCheckedValues} />
}

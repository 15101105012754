import { ReactNode, useCallback, MouseEvent } from 'react'
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import MuiToggleButton from '@mui/material/ToggleButton'

type ToggleButtonItem = {
  value: string
  content: ReactNode
}

type ToggleButtonProps = {
  items: ToggleButtonItem[]
  orientation: 'horizontal' | 'vertical'
  value: string
  onChange: (value: string) => void
}

export default function ToggleButton({ items, orientation, value, onChange }: ToggleButtonProps) {
  const handleChange = useCallback(
    (event: MouseEvent<HTMLElement>, newAlignment: string | null) => {
      if (newAlignment) {
        onChange(newAlignment)
      }
    },
    [onChange]
  )
  return (
    <MuiToggleButtonGroup value={value} orientation={orientation} exclusive fullWidth onChange={handleChange}>
      {items.map((item) => (
        <MuiToggleButton key={item.value} value={item.value}>
          {item.content}
        </MuiToggleButton>
      ))}
    </MuiToggleButtonGroup>
  )
}

import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const dialogsSelector = (state: StoreState) => state.dialogs

export const DialogsSelectors = {
  userCreate: createSelector([dialogsSelector], (state) => state.userCreate),
  userEdit: createSelector([dialogsSelector], (state) => state.userEdit),
  userDelete: createSelector([dialogsSelector], (state) => state.userDelete),
  clientCreate: createSelector([dialogsSelector], (state) => state.clientCreate),
  clientEdit: createSelector([dialogsSelector], (state) => state.clientEdit),
  clientDelete: createSelector([dialogsSelector], (state) => state.clientDelete),
  clientAccept: createSelector([dialogsSelector], (state) => state.clientAccept),
  tagEdit: createSelector([dialogsSelector], (state) => state.tagEdit),
  tagDelete: createSelector([dialogsSelector], (state) => state.tagDelete),
  passwordChange: createSelector([dialogsSelector], (state) => state.passwordChange),
  emailChange: createSelector([dialogsSelector], (state) => state.emailChange),
  userImageSetting: createSelector([dialogsSelector], (state) => state.userImageSetting),
  moneyspaceGroupCreate: createSelector([dialogsSelector], (state) => state.moneyspaceGroupCreate),
  moneyspaceGroupEdit: createSelector([dialogsSelector], (state) => state.moneyspaceGroupEdit),
  moneyspaceFolderMove: createSelector([dialogsSelector], (state) => state.moneyspaceFolderMove),
  moneyspaceGroupDelete: createSelector([dialogsSelector], (state) => state.moneyspaceGroupDelete),
  moneyspaceCreate: createSelector([dialogsSelector], (state) => state.moneyspaceCreate),
  moneyspaceEdit: createSelector([dialogsSelector], (state) => state.moneyspaceEdit),
  moneyspaceMove: createSelector([dialogsSelector], (state) => state.moneyspaceMove),
  moneyspaceDelete: createSelector([dialogsSelector], (state) => state.moneyspaceDelete),
  moneyspaceMembersSetting: createSelector([dialogsSelector], (state) => state.moneyspaceMembersSetting),
  moneyspaceClientEdit: createSelector([dialogsSelector], (state) => state.moneyspaceClientEdit),
  moneyspaceView: createSelector([dialogsSelector], (state) => state.moneyspaceView),
  documentDelete: createSelector([dialogsSelector], (state) => state.documentDelete),
  documentApply: createSelector([dialogsSelector], (state) => state.documentApply),
  documentApproveFlowSetting: createSelector([dialogsSelector], (state) => state.documentApprovalFlowSetting),
  documentApprove: createSelector([dialogsSelector], (state) => state.documentApprove),
  documentBulkApprove: createSelector([dialogsSelector], (state) => state.documentBulkApprove),
  documentReject: createSelector([dialogsSelector], (state) => state.documentReject),
  documentCopy: createSelector([dialogsSelector], (state) => state.documentCopy),
  documentCsvImportItemPreview: createSelector([dialogsSelector], (state) => state.documentCsvImportItemPreview),
  documentDiff: createSelector([dialogsSelector], (state) => state.documentDiff),
  documentSearchConditionList: createSelector([dialogsSelector], (state) => state.documentSearchConditionList),
  documentSearchConditionForm: createSelector([dialogsSelector], (state) => state.documentSearchConditionForm),
  documentSearchConditionDelete: createSelector([dialogsSelector], (state) => state.documentSearchConditionDelete),
  transactionCreate: createSelector([dialogsSelector], (state) => state.transactionCreate),
  transactionCancel: createSelector([dialogsSelector], (state) => state.transactionCancel),
  transactionDelete: createSelector([dialogsSelector], (state) => state.transactionDelete),
  transactionApply: createSelector([dialogsSelector], (state) => state.transactionApply),
  transactionSubmit: createSelector([dialogsSelector], (state) => state.transactionSubmit),
  transactionApprove: createSelector([dialogsSelector], (state) => state.transactionApprove),
  transactionBulkApprove: createSelector([dialogsSelector], (state) => state.transactionBulkApprove),
  transactionApprovalFlowStatus: createSelector([dialogsSelector], (state) => state.transactionApprovalFlowSetting),
  transactionsApprovalFlowStatus: createSelector([dialogsSelector], (state) => state.transactionsApprovalFlowSetting),
  transactionReject: createSelector([dialogsSelector], (state) => state.transactionReject),
  transactionPhaseDiff: createSelector([dialogsSelector], (state) => state.transactionPhaseDiff),
  transactionSearchConditionList: createSelector([dialogsSelector], (state) => state.transactionSearchConditionList),
  transactionSearchConditionForm: createSelector([dialogsSelector], (state) => state.transactionSearchConditionForm),
  transactionSearchConditionDelete: createSelector(
    [dialogsSelector],
    (state) => state.transactionSearchConditionDelete
  ),
  transactionCopy: createSelector([dialogsSelector], (state) => state.transactionCopy),
  transactionCsvImportItemPreview: createSelector([dialogsSelector], (state) => state.transactionCsvImportItemPreview),
  transactionPdfPreview: createSelector([dialogsSelector], (state) => state.transactionPdfPreview),
  billingPdfPreview: createSelector([dialogsSelector], (state) => state.billingPdfPreview),

  contractEdit: createSelector([dialogsSelector], (state) => state.contractEdit),
  contractDelete: createSelector([dialogsSelector], (state) => state.contractDelete),
  contractCopy: createSelector([dialogsSelector], (state) => state.contractCopy),
  attachmentDelete: createSelector([dialogsSelector], (state) => state.attachmentDelete),
  attachmentsView: createSelector([dialogsSelector], (state) => state.attachmentsView),
  approvalFlowSetting: createSelector([dialogsSelector], (state) => state.approvalFlowSetting),
  approvalFlowDelete: createSelector([dialogsSelector], (state) => state.approvalFlowDelete),
  transactionClientView: createSelector([dialogsSelector], (state) => state.transactionClientView),
  transactionRelease: createSelector([dialogsSelector], (state) => state.transactionRelease),
  billingPaid: createSelector([dialogsSelector], (state) => state.billingPaid),
  billingBulkPaid: createSelector([dialogsSelector], (state) => state.billingBulkPaid),
  messageCreate: createSelector([dialogsSelector], (state) => state.messageCreate),
  messageEdit: createSelector([dialogsSelector], (state) => state.messageEdit),
  messageDelete: createSelector([dialogsSelector], (state) => state.messageDelete),
  reservedMessage: createSelector([dialogsSelector], (state) => state.reservedMessage),
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Dialog from '../../atoms/feedback/Dialog'
import { useApprovalFlowSettingDialog, useTransactionApprovalFlowSettingDialog } from '../../../hooks/useDialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useProcessing from '../../../hooks/useProcessing'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { ApprovalFlowSelectors } from '../../../store/approval_flow/selectors'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import { RadioCheckedIcon, RadioUncheckedIcon } from '../../atoms/display/Icons'
import { ApprovalFlowActions } from '../../../store/approval_flow'
import { SessionSelectors } from '../../../store/session/selectors'
import { Transaction, TransactionPhaseBilling } from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import useMoneyspace from '../../../hooks/useMoneyspace'
import FormItem from '../../molecules/inputs/FormItem'
import DateControl from '../../atoms/inputs/DateControl'
import { TransactionInstallmentFormParams } from './TransactionInstallmentForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useTransaction from '../../../hooks/useTransaction'
import useDay from '../../../hooks/useDay'

type TransactionApprovalFlowSettingDialogProps = {
  transaction: Transaction
}

type ApprovalFlowSettingParams = {
  closingDate: string | null
  paymentDate: string | null
}

export default function TransactionApprovalFlowSettingDialog({
  transaction,
}: TransactionApprovalFlowSettingDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.updateApprovalFlow.typePrefix)
  const { state, close } = useTransactionApprovalFlowSettingDialog()
  const companyId = useSelector(SessionSelectors.companyId)
  const flows = useSelector(ApprovalFlowSelectors.flows)
  const [selectedFlow, setSelectedFlow] = useState<string>()
  const ApprovalFlowSettingDialog = useApprovalFlowSettingDialog()
  const hasPic = transaction.pic != null || transaction.picSub != null
  const dispatch = useDispatch()
  const { find, isContractee } = useMoneyspace()
  const moneyspace = find(transaction.moneyspace.id)
  const user = useSelector(SessionSelectors.user)
  const isContracteeUser = moneyspace ? isContractee(moneyspace, user) : true
  const displayPaymentDate = [TransactionPhaseBilling].includes(transaction.currentPhase)
  const { formatDate } = useDay()

  const { isFixed } = useTransaction()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    paymentDate: rules.paymentDate.required().test({
      name: 'overFixedDate',
      message: '承認期限が過ぎているため、次回の支払日に変更してください',
      test() {
        const { parent } = this as { parent: TransactionInstallmentFormParams }
        const { closingDate } = parent
        if (displayPaymentDate && moneyspace) {
          return !(moneyspace.aggregateTransactions && isFixed(moneyspace, closingDate))
        }
        return true
      },
    }),
    closingDate: rules.closingDate
      .required()
      .test(
        'overFixedDate',
        '承認期限が過ぎているため、次回の締め日に変更してください',
        (item: Date | null | undefined) => {
          if (displayPaymentDate && moneyspace) {
            return !(moneyspace.aggregateTransactions && isFixed(moneyspace, item))
          }
          return true
        }
      ),
  })
  const form = useForm<ApprovalFlowSettingParams>({
    defaultValues: {
      closingDate: transaction.closingDate,
      paymentDate: transaction.paymentDate,
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (companyId && state !== undefined) {
      dispatch(ApprovalFlowActions.fetchFlows({ companyId }))
    }
  }, [companyId, dispatch, state])
  const handleApply = useCallback(
    (params: ApprovalFlowSettingParams) => {
      startProcess()
      if (selectedFlow) {
        dispatch(TransactionActions.updateApprovalFlow({ transactionId: transaction.id, flowId: selectedFlow }))
      }
      if (params.closingDate && params.paymentDate) {
        dispatch(
          TransactionActions.saveTransactionClosingDate({
            transactionId: transaction.id,
            closingDate: formatDate(params.closingDate),
            paymentDate: formatDate(params.paymentDate),
          })
        )
      }
    },
    [startProcess, selectedFlow, dispatch, transaction, formatDate]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="承認フローを設定する"
      onSubmit={form.handleSubmit(handleApply)}
      actions={
        <Button type="submit" disabled={selectedFlow === undefined || processing}>
          設定
        </Button>
      }
    >
      <FlexColumn space={4}>
        {displayPaymentDate && (
          <FlexRow>
            <FormItem label="締め日" required>
              <DateControl
                name="closingDate"
                control={form.control}
                error={form.formState.errors.closingDate?.message}
              />
            </FormItem>
            <FormItem label="支払期限" required>
              <DateControl
                name="paymentDate"
                control={form.control}
                error={form.formState.errors.paymentDate?.message}
              />
            </FormItem>
          </FlexRow>
        )}
        <Label text="承認フローを選択してください" />
        <FlexColumn maxHeight="480px" scroll>
          {isContracteeUser ? (
            <>
              {!hasPic && (
                <Label
                  text="取引に担当者が設定されていないため、担当者を含む承認フローは選択できません"
                  variant="caption"
                  color="caution.main"
                />
              )}
            </>
          ) : (
            <Label text="担当者を含む承認フローは、発注者のみ選択できます" variant="caption" color="caution.main" />
          )}
          {flows.map((flow) => (
            <FlexColumn width="100%" space={0} scroll={false} key={flow.id}>
              <Label text={flow.name} />
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setSelectedFlow(flow.id)}
                disabled={flow.members.some((member) => member.user === null) && !hasPic}
              >
                <FlexRow width="100%" align="center">
                  {flow.id === selectedFlow ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                  <AvatarStepper members={flow.members} />
                </FlexRow>
              </Button>
            </FlexColumn>
          ))}
          {flows.length < 10 && (
            <FlexRow justify="flex-end">
              <Button variant="text" size="lg" onClick={() => ApprovalFlowSettingDialog.open()}>
                新しい承認フローを作成する
              </Button>
            </FlexRow>
          )}
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

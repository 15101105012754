import MuiFab from '@mui/material/Fab'
import { ReactNode } from 'react'

type FabProps = {
  children: ReactNode
  onClick: () => void
}

export default function Fab({ children, onClick }: FabProps) {
  return (
    <MuiFab color="primary" onClick={onClick}>
      {children}
    </MuiFab>
  )
}

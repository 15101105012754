import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FieldError } from 'react-hook-form'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import EmailField from '../../molecules/inputs/EmailField'
import FullNameField from '../../molecules/inputs/FullNameField'
import PasswordField from '../../molecules/inputs/PasswordField'
import Button from '../../atoms/inputs/Button'
import { useUserCreateDialog } from '../../../hooks/useDialog'
import Label from '../../atoms/display/Label'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { User } from '../../../types/User'
import { CompanyActions } from '../../../store/company'
import RoleSelect from '../../molecules/inputs/RoleSelect'
import { SessionSelectors } from '../../../store/session/selectors'
import useProcessing from '../../../hooks/useProcessing'
import { InfoIcon } from '../../atoms/display/Icons'
import Link from '../../atoms/navigation/Link'

type UserCreateParams = {
  password: string
  passwordConfirm: string
} & User

export default function UserCreateDialog() {
  const { processing, startProcess } = useProcessing(CompanyActions.createUser.typePrefix)
  const sessionUser = useSelector(SessionSelectors.user)
  const { state, close } = useUserCreateDialog()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.userName.required(),
    email: rules.email.required(),
    password: rules.password.required(),
    passwordConfirm: rules.passwordConfirm.required(),
  })
  const { handleSubmit, register, control, errors } = useForm<UserCreateParams>(schema, { role: 3 })
  const dispatch = useDispatch()
  const handleCreateUser = useCallback(
    (values: UserCreateParams) => {
      startProcess()
      dispatch(
        CompanyActions.createUser({
          name: values.name,
          email: values.email,
          password: values.password,
          role: values.role,
        })
      )
    },
    [dispatch, startProcess]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="ユーザーの作成"
      onSubmit={handleSubmit(handleCreateUser)}
      actions={
        <Button type="submit" disabled={processing}>
          作成
        </Button>
      }
    >
      <FlexColumn>
        <FormItem label="ユーザ名" required>
          <FullNameField register={register('name')} error={errors.name as FieldError} />
        </FormItem>
        <FormItem label="メールアドレス" required>
          <EmailField register={register('email')} error={errors.email as FieldError} />
        </FormItem>
        <FormItem label="パスワード" required>
          <PasswordField register={register('password')} error={errors.password as FieldError} />
        </FormItem>
        <FormItem label="パスワード（確認用）" required>
          <PasswordField register={register('passwordConfirm')} error={errors.passwordConfirm as FieldError} />
        </FormItem>
        <FormItem
          label="権限"
          icon={
            <Link href="https://www.monect-tutorial.jp/beginner-11#権限">
              <InfoIcon size={16} />
            </Link>
          }
        >
          <RoleSelect name="role" control={control} userRole={sessionUser?.role} />
        </FormItem>
        <Label text="入力したメールアドレス宛に、招待メールを送信します" />
      </FlexColumn>
    </Dialog>
  )
}

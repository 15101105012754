import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { SortDirection } from '../../../types/System'
import { FlexColumn } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import { useTransactionBulkApproveDialog } from '../../../hooks/useDialog'
import { TransactionActions } from '../../../store/transactions'

type TransactionBulkApproveDialogProps = {
  transactionIds: string[]
  page: number
  sortColumn?: string
  sortDirection?: SortDirection
}

export default function TransactionBulkApproveDialog({
  transactionIds,
  page,
  sortColumn,
  sortDirection,
}: TransactionBulkApproveDialogProps) {
  const { state, close } = useTransactionBulkApproveDialog()
  const dispatch = useDispatch()
  const handleBulkApprove = useCallback(() => {
    dispatch(TransactionActions.bulkApproveTransaction({ transactionIds, page, sortColumn, sortDirection }))
  }, [transactionIds, dispatch, page, sortColumn, sortDirection])
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="一括承認"
      actions={<Button onClick={handleBulkApprove}>承認する</Button>}
    >
      <FlexColumn align="center">
        <Label text={`${transactionIds.length}件の取引を選択中`} />
        <Label text="一括で承認しますか？" />
      </FlexColumn>
    </Dialog>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { SystemSelectors } from '../store/system/selectors'
import systemSlice from '../store/system'

export default function usePage() {
  const dispatch = useDispatch()
  const title = useSelector(SystemSelectors.pageTitle)
  const subTitle = useSelector(SystemSelectors.pageSubTitle)
  const changeTitle = useCallback(
    (newTitle: string, newSubTitle?: string) => {
      dispatch(systemSlice.actions.pageTitle({ title: newTitle, subTitle: newSubTitle }))
    },
    [dispatch]
  )
  return {
    title,
    changeTitle,
    subTitle,
  }
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type DocumentItemUnitFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function DocumentItemUnitField({ register, error, disabled }: DocumentItemUnitFieldProps) {
  return <TextField size="xs" register={register} error={error?.message} readonly={disabled} />
}

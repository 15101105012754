import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const moneyspacesSelector = (state: StoreState) => state.moneyspaces
export const MoneyspacesSelectors = {
  folders: createSelector([moneyspacesSelector], (state) => state.folders),
  moneyspaces: createSelector([moneyspacesSelector], (state) => state.moneyspaces),
  members: createSelector([moneyspacesSelector], (state) => state.members),
  initialized: createSelector([moneyspacesSelector], (state) => state.initializedMoneyspace && state.initializedGroups),
  latestMessages: createSelector([moneyspacesSelector], (state) => state.latestMessages),
}

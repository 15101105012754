import MuiCheckbox from '@mui/material/Checkbox'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'

type CheckboxProps<T extends FieldValues> = {
  label?: string
  name: FieldPath<T>
  control: Control<T>
  disabled?: boolean
}

export default function CheckboxControl<T extends FieldValues>({ label, name, control, disabled }: CheckboxProps<T>) {
  return (
    <MuiFormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            <MuiCheckbox {...field} disabled={disabled} defaultChecked={field.value} />
          )}
        />
      }
      label={label ?? ''}
    />
  )
}

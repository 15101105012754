import { useTransactionClientViewDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { CompanyAttributes } from '../../../types/Company'

type TransactionClientViewDialogProps = {
  company: CompanyAttributes
  isContractee: boolean
}

function accountTypeName(value?: number) {
  switch (value) {
    case 1:
      return '普通'
    case 2:
      return '当座'
    default:
      return ''
  }
}

export default function TransactionClientViewDialog({ company, isContractee }: TransactionClientViewDialogProps) {
  const { state, close } = useTransactionClientViewDialog()

  return (
    <Dialog open={state !== undefined} onClose={close} title="取引先の情報" width="xs">
      <FlexColumn space={2}>
        <FlexColumn space={0}>
          <Label text="郵便番号" variant="caption" />
          <Label text={company.zipCode ?? ''} />
        </FlexColumn>
        <FlexColumn space={0}>
          <Label text="住所" variant="caption" />
          <Label text={company.address ?? ''} />
        </FlexColumn>
        <FlexColumn space={0}>
          <Label text="法人名/屋号" variant="caption" />
          <Label text={company.name} />
        </FlexColumn>
        <FlexRow>
          <FlexColumn space={0}>
            <Label text="代表者役職" variant="caption" />
            <Label text={company.representativeRole ?? ''} />
          </FlexColumn>
          <FlexColumn space={0}>
            <Label text="代表者氏名" variant="caption" />
            <Label text={company.representativeName ?? ''} />
          </FlexColumn>
        </FlexRow>
        {!isContractee && (
          <FlexColumn space={0}>
            <Label text="振込先口座情報" variant="caption" />
            <FlexRow>
              <Label text={`${company.bankName ?? ''}(${company.bankCode ?? ''})`} />
              <Label text={`${company.branchName ?? ''}(${company.branchCode ?? ''})`} />
            </FlexRow>
            <FlexRow>
              <Label text={accountTypeName(company.accountType)} />
              <Label text={company.accountNumber ?? ''} />
            </FlexRow>
            <Label text={company.accountOwner ?? ''} />
          </FlexColumn>
        )}
      </FlexColumn>
    </Dialog>
  )
}

import { Control } from 'react-hook-form'
import RadioGroup from '../../atoms/inputs/RadioGroup'

type ConsumptionTaxSelectProps = {
  name: string
  control?: Control
  disabled?: boolean
}

export default function ConsumptionTaxSelect({ name, control, disabled }: ConsumptionTaxSelectProps) {
  return (
    <RadioGroup
      items={[
        { value: false, label: '税抜' },
        { value: true, label: '税込' },
      ]}
      row
      name={name}
      control={control}
      disabled={disabled}
    />
  )
}

import { Control, FieldError } from 'react-hook-form'
import NumberField from '../../atoms/inputs/NumberField'

type DocumentItemUnitPriceFieldProps = {
  name?: string
  control?: Control
  error?: FieldError
  disabled?: boolean
}

export default function DocumentItemUnitPriceField({
  name,
  control,
  error,
  disabled,
}: DocumentItemUnitPriceFieldProps) {
  return (
    <NumberField
      type="amount"
      size="sm"
      placeholder="100,000"
      name={name}
      control={control}
      error={error?.message}
      disabled={disabled}
    />
  )
}

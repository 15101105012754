import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs, { Dayjs } from 'dayjs'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import SearchField from '../../molecules/inputs/SearchField'
import MoneyspaceSelectDialog from './MoneyspaceSelectDialog'
import PeriodSelect, { Period } from './PeriodSelect'
import Button from '../../atoms/inputs/Button'
import Accordion from '../../atoms/feedback/Accordion'
import Label from '../../atoms/display/Label'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import { TransactionActions } from '../../../store/transactions'
import { BillingSearchResultItem, TransactionSearchResultItem } from '../../../types/transaction'
import useRouter from '../../../hooks/useRouter'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import useDay from '../../../hooks/useDay'

type AggregationItem = {
  title: string
  total: number
  billings: BillingSearchResultItem[]
}

function minDay(days: Dayjs[]) {
  let lastDay: Dayjs = days[0]
  days.forEach((day) => {
    if (day.isBefore(lastDay)) {
      lastDay = day
    }
  })
  return lastDay
}

function generateItems(billings: BillingSearchResultItem[], beginDate: Dayjs, endDate: Dayjs): AggregationItem[] {
  let currentMonth = beginDate.endOf('month')
  const endMonth = endDate.startOf('month')
  const items: AggregationItem[] = []
  while (currentMonth.isAfter(endMonth)) {
    const beginDateInMonth = currentMonth.startOf('month')
    const endDateInMonth = currentMonth.endOf('month')
    const filteredBillings = billings.filter((billing) => {
      const billingDate = dayjs(billing.paymentDate)
      return billingDate.isAfter(beginDateInMonth) && billingDate.isBefore(endDateInMonth)
    })
    filteredBillings.sort((a, b) => (dayjs(a.paymentDate).isBefore(dayjs(b.paymentDate)) ? 1 : -1))
    items.push({
      title: `${beginDateInMonth.format('YYYY年MM月DD日')}〜${endDateInMonth.format('YYYY年MM月DD日')}`,
      total: filteredBillings.reduce((sum, billing) => sum + billing.totalAmount, 0),
      billings: filteredBillings,
    })
    currentMonth = currentMonth.subtract(1, 'month')
  }
  return items
}

function transactionsName(transactions: TransactionSearchResultItem[]) {
  if (transactions.length === 0) {
    return ''
  }
  if (transactions.length === 1) {
    return transactions[0].name
  }
  return `${transactions[0].name}他${transactions.length - 1}件`
}

export default function BillingAggregationView() {
  const downloadRef = useRef<HTMLAnchorElement>(null)
  const [period, setPeriod] = useState<Period | null>(null)
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)
  const [selectedMoneyspaces, setSelectedMoneyspaces] = useState<string[]>([])
  const [items, setItems] = useState<AggregationItem[]>([])
  const dispatch = useDispatch()
  const { moneyspace: moneyspacePage } = useRouter()
  const billings = useSelector(TransactionSelectors.billings)
  const day = useDay()

  useEffect(() => {
    if (billings.length === 0) {
      setItems([])
      return
    }
    let filteredBillings = billings
    if (searchKeyword) {
      filteredBillings = filteredBillings.filter((billing) =>
        billing.transactions.some((transaction) => transaction.name.includes(searchKeyword))
      )
    }
    filteredBillings = filteredBillings.filter((transaction) => selectedMoneyspaces.includes(transaction.moneyspace.id))
    if (period === null) {
      if (filteredBillings.length === 0) {
        setItems([])
      } else {
        const lastPaymentDate = minDay(filteredBillings.map((billing) => dayjs(billing.paymentDate)))
        setItems(generateItems(filteredBillings, dayjs(), lastPaymentDate))
      }
    } else {
      setItems(generateItems(filteredBillings, period.toDate, period.fromDate))
    }
  }, [period, billings, searchKeyword, selectedMoneyspaces])

  const handleChangePeriods = (values: Period | null) => {
    setPeriod(values)
    if (values === null) {
      dispatch(
        TransactionActions.loadBillings({
          searchParams: {
            isPaid: 2,
          },
        })
      )
      return
    }
    dispatch(
      TransactionActions.loadBillings({
        searchParams: {
          isPaid: 2,
          paymentDateFrom: values.fromDate.format('YYYY-MM-DD'),
          paymentDateTo: values.toDate.format('YYYY-MM-DD'),
        },
      })
    )
  }

  const handleCsvDownload = (item: AggregationItem) => {
    if (downloadRef.current === null) {
      return
    }
    const header = 'マネースペース,取引名,最終残高,締め日,支払日'
    const csvData = item.billings.map(
      (billing) =>
        `${billing.moneyspace.name},${transactionsName(billing.transactions)},${billing.totalTax},${day.formatDate(
          billing.closingDate ?? ''
        )},${day.formatDate(billing.paymentDate ?? '')})}`
    )
    const data = new Blob([[header, ...csvData].join('\n')], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(data)
    downloadRef.current.setAttribute('href', url)
    downloadRef.current.setAttribute('download', `請求書_${item.title}.csv`)
    downloadRef.current.click()
  }

  return (
    <FlexColumn space={2}>
      <FlexRow justify="flex-end">
        <FlexRow wrap>
          <FlexRow width={360}>
            <SearchField placeholder="フリーワード検索" onChange={(value) => setSearchKeyword(value ?? null)} />
          </FlexRow>
          <MoneyspaceSelectDialog onChange={setSelectedMoneyspaces} />
          <PeriodSelect onChange={handleChangePeriods} />
        </FlexRow>
      </FlexRow>
      {items.map((item) => (
        <FlexColumn pr={1} pb={1} key={item.title}>
          <FlexRow justify="flex-end">
            <Button height="small" variant="outlined" onClick={() => handleCsvDownload(item)}>
              CSVダウンロード
            </Button>
            <a ref={downloadRef} style={{ display: 'none' }} aria-label="csv download" href="/">
              csv download
            </a>
          </FlexRow>
          <Accordion
            title={
              <FlexRow justify="space-between">
                <Label text={item.title} />
                <FlexRow>
                  <Label text="最終残高" />
                  <Label text={item.total} format="amountWithUnit" />
                </FlexRow>
              </FlexRow>
            }
          >
            {item.billings.length === 0 ? (
              <Label text="請求書がありません" />
            ) : (
              <Table
                header={
                  <TableRow>
                    <TableCell header>マネースペース</TableCell>
                    <TableCell header>取引名</TableCell>
                    <TableCell header size="sm">
                      金額
                    </TableCell>
                    <TableCell header size="sm">
                      締め日
                    </TableCell>
                    <TableCell header size="sm">
                      支払日
                    </TableCell>
                  </TableRow>
                }
              >
                {item.billings.map((billing) => (
                  <TableRow
                    key={billing.id}
                    onClick={() =>
                      moneyspacePage(billing.moneyspace.id, billing.isPaid ? 'completed' : 'pending', 1, billing.id)
                    }
                  >
                    <TableCell>{billing.moneyspace.name}</TableCell>
                    <TableCell>
                      {billing.transactions.length > 0 && (
                        <>
                          {billing.transactions.length === 1 ? (
                            <Label text={billing.transactions[0].name} />
                          ) : (
                            <FlexRow>
                              <Label text={billing.transactions[0].name} />
                              <Label text={`他${billing.transactions.length - 1}件`} />
                            </FlexRow>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell size="sm">
                      <FlexRow justify="flex-end">
                        <Label text={billing.totalAmount} format="amountWithUnit" />
                        <Label text="（税込）" />
                      </FlexRow>
                    </TableCell>
                    <TableCell size="xs" align="center">
                      <Label text={day.formatDate(billing.closingDate ?? '')} />
                    </TableCell>
                    <TableCell size="xs" align="center">
                      <Label text={day.formatDate(billing.paymentDate ?? '')} />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            )}
          </Accordion>
        </FlexColumn>
      ))}
    </FlexColumn>
  )
}

import { useCallback } from 'react'
import { Contract, Document } from '../../../types/Document'
import { useDocumentApproveDialog } from '../../../hooks/useDialog'
import useDocument from '../../../hooks/useDocument'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useProcessing from '../../../hooks/useProcessing'
import { DocumentsActions } from '../../../store/documents'

type DocumentApproveDialogProps = {
  moneyspaceId: string
  contract: Contract
  document: Document
}

function getLabels(contract: Contract, document: Document) {
  if (document.type === 7 || document.status === 2) {
    return {
      title: '書類を承認する',
      message: 'この書類を承認しますか？',
      button: '承認する',
    }
  }
  if (contract.documents[contract.documents.length - 1].id === document.id) {
    return {
      title: '取引を終了する',
      message: 'この書類を確認して取引を終了しますか？',
      button: '終了する',
    }
  }
  return {
    title: '次の書類に進む',
    message: '次の書類に進みますか？',
    button: '次の書類へ',
  }
}

export default function DocumentApproveDialog({ moneyspaceId, contract, document }: DocumentApproveDialogProps) {
  const { processing, startProcess } = useProcessing(DocumentsActions.approveDocument.typePrefix)
  const { state, close } = useDocumentApproveDialog()
  const { approveDocument } = useDocument()
  const handleApprove = useCallback(() => {
    startProcess()
    approveDocument(moneyspaceId, contract, document)
  }, [approveDocument, contract, document, moneyspaceId, startProcess])
  const labels = getLabels(contract, document)
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title={labels.title}
      actions={
        <Button onClick={handleApprove} disabled={processing}>
          {labels.button}
        </Button>
      }
    >
      <Label text={labels.message} />
    </Dialog>
  )
}

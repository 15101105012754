import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApprovalFlow } from '../../../types/ApprovalFlow'
import useProcessing from '../../../hooks/useProcessing'
import { ApprovalFlowActions } from '../../../store/approval_flow'
import { useApprovalFlowDeleteDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import { SessionSelectors } from '../../../store/session/selectors'

type ApprovalFlowDeleteDialogProps = {
  flow: ApprovalFlow
}

export default function ApprovalFlowDeleteDialog({ flow }: ApprovalFlowDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(ApprovalFlowActions.deleteFlow.typePrefix)
  const { state, close } = useApprovalFlowDeleteDialog()
  const companyId = useSelector(SessionSelectors.companyId)
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    if (companyId) {
      startProcess()
      dispatch(ApprovalFlowActions.deleteFlow({ companyId, id: flow.id }))
    }
  }, [companyId, dispatch, flow.id, startProcess])
  return (
    <Dialog
      open={state !== undefined}
      title="承認フローの削除"
      onClose={close}
      actions={
        <Button color="caution" disabled={processing} onClick={handleDelete}>
          削除
        </Button>
      }
    >
      <FlexColumn>
        <Label text="承認フローを削除しますか？" />
        <Label text={flow.name} />
        <AvatarStepper members={flow.members} />
      </FlexColumn>
    </Dialog>
  )
}

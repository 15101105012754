import { getFilePlugin, GetFilePlugin } from '@react-pdf-viewer/get-file'
import { printPlugin, PrintPlugin } from '@react-pdf-viewer/print'
import useDay from './useDay'

export type PdfParams = {
  downloadPlugin: GetFilePlugin
  printPlugin: PrintPlugin
}

export default function usePdf({ filename }: { filename?: string }): PdfParams {
  const { timestamp } = useDay()
  const downloadPdfPlugin = getFilePlugin({
    fileNameGenerator: filename ? () => `${filename}_${timestamp()}.pdf` : undefined,
  })
  const printPdfPlugin = printPlugin()

  return {
    downloadPlugin: downloadPdfPlugin,
    printPlugin: printPdfPlugin,
  }
}

import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import Form from '../../atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import DateControl from '../../atoms/inputs/DateControl'
import Alert from '../../atoms/feedback/Alert'
import RadioControl from '../../atoms/inputs/RadioControl'
import SelectControl from '../../atoms/inputs/SelectControl'
import TransactionDetailsForm from './TransactionDetailsForm'
import Button from '../../atoms/inputs/Button'
import { useDeviceType } from '../../../hooks/useDeviceType'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import TextField from '../../atoms/inputs/TextField'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import {
  Transaction,
  TransactionDetail,
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionPhaseDelivered,
} from '../../../types/transaction'
import useRouter from '../../../hooks/useRouter'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { RelatedUser } from '../../../types/User'
import { InvoiceLessPatternB, Moneyspace } from '../../../types/Moneyspace'
import { TransactionActions } from '../../../store/transactions'
import useDay from '../../../hooks/useDay'
import usePath from '../../../hooks/usePath'
import useTransaction from '../../../hooks/useTransaction'
import useTransactionAmounts from '../../../hooks/useTransactionAmounts'
import { TransactionInstallmentFormParams } from './TransactionInstallmentForm'

type TransactionFormProps = {
  moneyspace: Moneyspace
  transaction: Transaction
}

export type TransactionFormParams = {
  name: string
  siteAddress: string | null
  publishedAt: string | null
  isTaxIn: 1 | 2
  closingDate: string | null
  paymentDate: string | null
  deliveryDateType: 1 | 2
  deliveryDateFrom?: string | null
  deliveryDateTo?: string | null
  pic?: string | null
  picSub?: string | null
  details: TransactionDetail[]
  note: string
  customProp1?: string | null
  customProp2?: string | null
  customProp3?: string | null
  customProp4?: string | null
}

export function findContracteeMembers(members: RelatedUser[], moneyspace?: Moneyspace) {
  if (moneyspace === undefined || moneyspace.contractee == null) {
    return []
  }
  const contracteeId = moneyspace.contractee.id
  return members
    .filter((member) => member.companyId === contracteeId)
    .map((member) => ({
      value: member.id,
      label: member.name,
    }))
}

function isNotBlank(name?: string | null) {
  return name && name !== ''
}

export default function TransactionForm({ moneyspace, transaction }: TransactionFormProps) {
  const { findMembers } = useMoneyspace()
  const { calcSimpleAmount } = useTransactionAmounts()
  const [amount, setAmount] = useState<number>(calcSimpleAmount(transaction.details))
  const dispatch = useDispatch()
  const { pc } = useDeviceType()
  const { formatDate } = useDay()
  const disabled = transaction.phases[0] !== transaction.currentPhase && transaction.fixFollowingPhase
  const { transaction: transactionPage } = useRouter()
  const { transaction: transactionPath } = usePath()
  const members = findMembers(transaction.moneyspace.id)
  const contracteeMembers = findContracteeMembers(members, moneyspace)
  const displayPaymentDate = [TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase)
  const displayDeliveryDate = [
    TransactionPhaseDelivered,
    TransactionPhaseBillingDetail,
    TransactionPhaseBilling,
  ].includes(transaction.currentPhase)
  const { isFixed } = useTransaction()
  const yup = useYup()
  const rules = useValidationRule()
  const [selectedPic, setSelectedPic] = useState<string | null | undefined>(transaction.pic?.id)
  const [selectedPicSub, setSelectedPicSub] = useState<string | null | undefined>(transaction.picSub?.id)
  const [deliveryDateType, setDeliveryDateType] = useState<number>(transaction.deliveryDateTo ? 2 : 1)

  const customProp1Rule = rules.customProp.label(moneyspace.transactionPropConfig?.customProp1.name)
  const customProp2Rule = rules.customProp.label(moneyspace.transactionPropConfig?.customProp2.name)
  const customProp3Rule = rules.customProp.label(moneyspace.transactionPropConfig?.customProp3.name)
  const customProp4Rule = rules.customProp.label(moneyspace.transactionPropConfig?.customProp4.name)

  const schema = yup.object().shape({
    name: rules.transactionName.required(),
    customProp1: moneyspace.transactionPropConfig?.customProp1.required ? customProp1Rule.required() : customProp1Rule,
    customProp2: moneyspace.transactionPropConfig?.customProp2.required ? customProp2Rule.required() : customProp2Rule,
    customProp3: moneyspace.transactionPropConfig?.customProp3.required ? customProp3Rule.required() : customProp3Rule,
    customProp4: moneyspace.transactionPropConfig?.customProp4.required ? customProp4Rule.required() : customProp4Rule,
    publishedAt: rules.publishedAt.required(),
    note: rules.note,
    paymentDate: (displayPaymentDate ? rules.paymentDate.required() : rules.paymentDate).test({
      name: 'overFixedDate',
      message: '承認期限が過ぎているため、次回の支払日に変更してください',
      test() {
        const { parent } = this as { parent: TransactionInstallmentFormParams }
        const { closingDate } = parent
        if (displayPaymentDate && moneyspace) {
          return !(moneyspace.aggregateTransactions && isFixed(moneyspace, closingDate))
        }
        return true
      },
    }),
    closingDate: (displayPaymentDate ? rules.closingDate.required() : rules.closingDate).test(
      'overFixedDate',
      '承認期限が過ぎているため、次回の締め日に変更してください',
      (item: Date | null | undefined) => {
        if (displayPaymentDate && moneyspace) {
          return !(moneyspace.aggregateTransactions && isFixed(moneyspace, item))
        }
        return true
      }
    ),
    deliveryDateFrom:
      displayDeliveryDate && moneyspace.detailPropConfig.requiredDeliveryDate
        ? rules.deliveryDateFrom.required()
        : rules.deliveryDateFrom,
    deliveryDateTo:
      displayDeliveryDate && moneyspace.detailPropConfig.requiredDeliveryDate
        ? rules.deliveryDateTo.required()
        : rules.deliveryDateTo,
    pic: moneyspace.detailPropConfig.requiredPic ? rules.pic.required() : rules.pic,
    details: rules.transactionDetails(moneyspace.detailPropConfig),
  })
  const form = useForm<TransactionFormParams>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: transaction.name,
      siteAddress: transaction.siteAddress,
      publishedAt: transaction.publishedAt,
      isTaxIn: transaction.isTaxIn ? 2 : 1,
      closingDate: transaction.closingDate,
      paymentDate: transaction.paymentDate,
      deliveryDateType: transaction.deliveryDateTo ? 2 : 1,
      deliveryDateFrom: transaction.deliveryDateFrom,
      deliveryDateTo: transaction.deliveryDateTo ?? transaction.deliveryDateFrom,
      pic: transaction.pic?.id ?? '',
      picSub: transaction.picSub?.id ?? '',
      details: transaction.details,
      note: transaction.note,
      customProp1: transaction.customProp1,
      customProp2: transaction.customProp2,
      customProp3: transaction.customProp3,
      customProp4: transaction.customProp4,
    },
    mode: 'onChange',
  })
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
    setValue,
  } = form

  useEffect(() => {
    watch((value, { name }) => {
      if (name) {
        if (name.startsWith('details') && value && value.details) {
          const details = value.details as TransactionDetail[]
          setAmount(calcSimpleAmount(details))
        }
        if (name === 'isTaxIn') {
          const details = value.details as TransactionDetail[]
          setAmount(calcSimpleAmount(details))
        }
        if (name === 'pic') {
          setSelectedPic(value.pic)
        }
        if (name === 'picSub') {
          setSelectedPicSub(value.picSub)
        }
        const type = Number(value.deliveryDateType)
        if (name === 'deliveryDateType' && value.deliveryDateType) {
          setDeliveryDateType(type)
          if (type === 1) {
            setValue('deliveryDateTo', value.deliveryDateFrom)
          }
          if (type === 2) {
            setValue('deliveryDateTo', null)
          }
        }
        if (name === 'deliveryDateFrom' && value.deliveryDateFrom && type === 1) {
          setValue('deliveryDateTo', value.deliveryDateFrom)
        }
      }
    })
  }, [calcSimpleAmount, contracteeMembers, watch, setValue])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleSave = useCallback(
    (params: TransactionFormParams) => {
      const paramsDeliveryDateTo = Number(params.deliveryDateType) === 2 ? params.deliveryDateTo : null
      dispatch(
        TransactionActions.saveTransaction({
          transaction: {
            ...transaction,
            name: params.name,
            siteAddress: params.siteAddress,
            publishedAt: params.publishedAt ? formatDate(params.publishedAt) : null,
            isTaxIn: Number(params.isTaxIn) === 2,
            closingDate: params.closingDate ? formatDate(params.closingDate) : null,
            paymentDate: params.paymentDate ? formatDate(params.paymentDate) : null,
            deliveryDateFrom: params.deliveryDateFrom ? formatDate(params.deliveryDateFrom) : null,
            deliveryDateTo: paramsDeliveryDateTo ? formatDate(paramsDeliveryDateTo) : null,
            pic: members.find((item) => item.id === params.pic) ?? null,
            picSub: members.find((item) => item.id === params.picSub) ?? null,
            customProp1: params.customProp1,
            customProp2: params.customProp2,
            customProp3: params.customProp3,
            customProp4: params.customProp4,
            details: params.details.map((detail) => ({
              id: transaction.details.find((item) => item.id === detail.id) ? detail.id : '',
              defaultProp: detail.defaultProp ?? '',
              customProp1: detail.customProp1 ?? '',
              customProp2: detail.customProp2 ?? '',
              unitPrice: detail.unitPrice,
              quantity: detail.quantity,
              unit: detail.unit,
              taxBucket: detail.taxBucket,
              amount: detail.amount,
              description: '',
            })),
            note: params.note,
          },
          getRedirectPath: (updatedTransaction: Transaction) => transactionPath(updatedTransaction.id),
        })
      )
    },
    [dispatch, formatDate, members, transaction, transactionPath]
  )
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleCancel = useCallback(() => {
    transactionPage(transaction.id)
  }, [transaction.id, transactionPage])

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(handleSave)}>
        <FlexColumn space={4}>
          <FlexColumn>
            <FlexRow wrap>
              {isNotBlank(moneyspace.transactionPropConfig?.customProp1.name) && (
                <FormItem
                  label={moneyspace.transactionPropConfig.customProp1.name}
                  required={moneyspace.transactionPropConfig.customProp1.required}
                >
                  <TextField register={register('customProp1')} error={errors.customProp1?.message} />
                </FormItem>
              )}
              {isNotBlank(moneyspace.transactionPropConfig?.customProp2.name) && (
                <FormItem
                  label={moneyspace.transactionPropConfig.customProp2.name}
                  required={moneyspace.transactionPropConfig.customProp2.required}
                >
                  <TextField register={register('customProp2')} error={errors.customProp2?.message} />
                </FormItem>
              )}
              {isNotBlank(moneyspace.transactionPropConfig?.customProp3.name) && (
                <FormItem
                  label={moneyspace.transactionPropConfig.customProp3.name}
                  required={moneyspace.transactionPropConfig.customProp3.required}
                >
                  <TextField register={register('customProp3')} error={errors.customProp3?.message} />
                </FormItem>
              )}
              {isNotBlank(moneyspace.transactionPropConfig?.customProp4.name) && (
                <FormItem
                  label={moneyspace.transactionPropConfig.customProp4.name}
                  required={moneyspace.transactionPropConfig.customProp4.required}
                >
                  <TextField register={register('customProp4')} error={errors.customProp4?.message} />
                </FormItem>
              )}
            </FlexRow>
            <FlexRow wrap align="flex-start">
              <FormItem label="取引名" required>
                <TextField register={register('name')} error={errors.name?.message} />
              </FormItem>
              <FormItem label="現場住所">
                <TextField register={register('siteAddress')} error={errors.siteAddress?.message} size="lg" />
              </FormItem>
            </FlexRow>
            <FlexRow wrap align="flex-start">
              <FormItem label="発行日" required>
                <DateControl name="publishedAt" control={control} error={errors.publishedAt?.message} />
              </FormItem>
              {displayPaymentDate && (
                <FlexColumn>
                  <FormItem label="">
                    <Alert
                      severity="success"
                      icon={false}
                      label={transaction.bearingFee ? '振込手数料：受注者負担' : '振込手数料：発注者負担'}
                    />
                  </FormItem>
                </FlexColumn>
              )}
            </FlexRow>
            <FlexRow wrap>
              <FormItem label="消費税の表示">
                <RadioControl
                  items={[
                    { value: 1, label: '税抜' },
                    { value: 2, label: '税込' },
                  ]}
                  name="isTaxIn"
                  control={control}
                  row
                  disabled={disabled || transaction.invoiceLessActionType === InvoiceLessPatternB}
                />
              </FormItem>
              {displayPaymentDate && (
                <>
                  <FormItem label="締め日" required>
                    <DateControl name="closingDate" control={control} error={errors.closingDate?.message} />
                  </FormItem>
                  <FormItem label="支払期限" required>
                    <DateControl name="paymentDate" control={control} error={errors.paymentDate?.message} />
                  </FormItem>
                </>
              )}
            </FlexRow>
            <FlexRow wrap>
              {displayDeliveryDate && (
                <FormItem label="工事期間" required={moneyspace.detailPropConfig.requiredDeliveryDate}>
                  <FlexRow pl={1}>
                    <RadioControl
                      items={[
                        { label: '日付指定', value: 1 },
                        { label: '期間指定', value: 2 },
                      ]}
                      name="deliveryDateType"
                      control={control}
                      row
                      fontSize={16}
                    />
                  </FlexRow>
                  <FlexRow>
                    <DateControl name="deliveryDateFrom" control={control} error={errors.deliveryDateFrom?.message} />
                    {deliveryDateType === 2 && (
                      <DateControl name="deliveryDateTo" control={control} error={errors.deliveryDateTo?.message} />
                    )}
                  </FlexRow>
                </FormItem>
              )}
            </FlexRow>
            <FlexRow wrap>
              <FormItem label="担当者1" required={moneyspace.detailPropConfig.requiredPic}>
                <SelectControl
                  name="pic"
                  items={contracteeMembers.filter((member) => member.value !== selectedPicSub)}
                  blank
                  control={control}
                  size="sm"
                  error={errors.pic?.message}
                />
              </FormItem>
              <FormItem label="担当者2">
                <SelectControl
                  name="picSub"
                  items={contracteeMembers.filter((member) => member.value !== selectedPic)}
                  blank
                  control={control}
                  size="sm"
                />
              </FormItem>
            </FlexRow>
          </FlexColumn>
          <TransactionDetailsForm detailPropConfig={moneyspace.detailPropConfig} disabled={disabled} />
          <FlexRow mt={4} wrap align="flex-end">
            <FlexColumn width={pc ? 640 : 320}>
              <FormItem label="備考">
                <TextField register={register('note')} multiline rows={10} error={errors.note?.message} />
              </FormItem>
            </FlexColumn>
            <FlexRow width={240}>
              <Table>
                <TableRow>
                  <TableCell color="header" size="xs">
                    <FlexColumn>
                      <Label text="合計金額" />
                    </FlexColumn>
                  </TableCell>
                  <TableCell size="xs">
                    <Label text={amount} format="amountNoStyle" />
                  </TableCell>
                </TableRow>
              </Table>
            </FlexRow>
          </FlexRow>
          <FlexRow justify="center">
            <Button onClick={handleCancel} color="secondary">
              キャンセル
            </Button>
            <Button type="submit">保存</Button>
          </FlexRow>
        </FlexColumn>
      </Form>
    </FormProvider>
  )
}

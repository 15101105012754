import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs, { Dayjs } from 'dayjs'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import PeriodSelect, { Period } from './PeriodSelect'
import Accordion from '../../atoms/feedback/Accordion'
import { TransactionPhaseOrder, TransactionSearchResultItem } from '../../../types/transaction'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import { TransactionActions } from '../../../store/transactions'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import useRouter from '../../../hooks/useRouter'
import SearchField from '../../molecules/inputs/SearchField'
import MoneyspaceSelectDialog from './MoneyspaceSelectDialog'

type AggregationItem = {
  title: string
  total: number
  transactions: TransactionSearchResultItem[]
}

function minDay(days: Dayjs[]) {
  let lastDay: Dayjs = days[0]
  days.forEach((day) => {
    if (day.isBefore(lastDay)) {
      lastDay = day
    }
  })
  return lastDay
}

function generateItems(
  transactions: TransactionSearchResultItem[],
  beginDate: Dayjs,
  endDate: Dayjs
): AggregationItem[] {
  let currentMonth = beginDate.endOf('month')
  const endMonth = endDate.startOf('month')
  const items: AggregationItem[] = []
  while (currentMonth.isAfter(endMonth)) {
    const beginDateInMonth = currentMonth.startOf('month')
    const endDateInMonth = currentMonth.endOf('month')
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = dayjs(transaction.publishedAt)
      return transactionDate.isAfter(beginDateInMonth) && transactionDate.isBefore(endDateInMonth)
    })
    filteredTransactions.sort((a, b) => (dayjs(a.publishedAt).isBefore(dayjs(b.publishedAt)) ? 1 : -1))
    items.push({
      title: `${beginDateInMonth.format('YYYY年MM月DD日')}〜${endDateInMonth.format('YYYY年MM月DD日')}`,
      total: filteredTransactions.reduce((sum, transaction) => sum + transaction.subtotal, 0),
      transactions: filteredTransactions,
    })
    currentMonth = currentMonth.subtract(1, 'month')
  }
  return items
}

export default function OrderAggregationView() {
  const downloadRef = useRef<HTMLAnchorElement>(null)
  const [items, setItems] = useState<AggregationItem[]>([])
  const [period, setPeriod] = useState<Period | null>(null)
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)
  const [selectedMoneyspaces, setSelectedMoneyspaces] = useState<string[]>([])
  const dispatch = useDispatch()
  const transactions = useSelector(TransactionSelectors.aggregateTransactions)
  const { transaction: transactionPage } = useRouter()

  useEffect(() => {
    if (transactions.length === 0) {
      setItems([])
      return
    }
    let filteredTransactions = transactions
    if (searchKeyword) {
      filteredTransactions = transactions.filter((transaction) => transaction.name.includes(searchKeyword))
    }
    filteredTransactions = filteredTransactions.filter((transaction) =>
      selectedMoneyspaces.includes(transaction.moneyspace.id)
    )
    if (period === null) {
      if (filteredTransactions.length === 0) {
        setItems([])
      } else {
        const lastPublishedDate = minDay(filteredTransactions.map((transaction) => dayjs(transaction.publishedAt)))
        setItems(generateItems(filteredTransactions, dayjs(), lastPublishedDate))
      }
    } else {
      setItems(generateItems(filteredTransactions, period.toDate, period.fromDate))
    }
  }, [period, transactions, searchKeyword, selectedMoneyspaces])

  const handleChangePeriods = (values: Period | null) => {
    setPeriod(values)
    if (values === null) {
      dispatch(
        TransactionActions.aggregateTransactions({
          phase: TransactionPhaseOrder,
          publishedDateFrom: null,
          publishedDateTo: null,
        })
      )
      return
    }
    dispatch(
      TransactionActions.aggregateTransactions({
        phase: TransactionPhaseOrder,
        publishedDateFrom: values.fromDate.format('YYYY-MM-DD'),
        publishedDateTo: values.toDate.format('YYYY-MM-DD'),
      })
    )
  }

  const handleCsvDownload = (item: AggregationItem) => {
    if (downloadRef.current === null) {
      return
    }
    const header = 'マネースペース,取引名,最終残高'
    const csvData = item.transactions.map(
      (transaction) => `${transaction.moneyspace.name},${transaction.name},${transaction.subtotal}`
    )
    const data = new Blob([[header, ...csvData].join('\n')], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(data)
    downloadRef.current.setAttribute('href', url)
    downloadRef.current.setAttribute('download', `発注集計_${item.title}.csv`)
    downloadRef.current.click()
  }

  return (
    <FlexColumn space={2}>
      <FlexRow justify="flex-end">
        <FlexRow wrap>
          <FlexRow width={360}>
            <SearchField placeholder="フリーワード検索" onChange={(value) => setSearchKeyword(value ?? null)} />
          </FlexRow>
          <MoneyspaceSelectDialog onChange={setSelectedMoneyspaces} />
          <PeriodSelect onChange={handleChangePeriods} />
        </FlexRow>
      </FlexRow>
      {items.map((item) => (
        <FlexColumn pr={1} pb={1} key={item.title}>
          <FlexRow justify="flex-end">
            <Button height="small" variant="outlined" onClick={() => handleCsvDownload(item)}>
              CSVダウンロード
            </Button>
            <a ref={downloadRef} style={{ display: 'none' }} aria-label="csv download" href="/">
              csv download
            </a>
          </FlexRow>
          <Accordion
            title={
              <FlexRow justify="space-between">
                <Label text={item.title} />
                <FlexRow>
                  <Label text="最終残高" />
                  <Label text={item.total} format="amountWithUnit" />
                </FlexRow>
              </FlexRow>
            }
          >
            {item.transactions.length === 0 ? (
              <Label text="取引がありません" />
            ) : (
              <Table
                header={
                  <TableRow>
                    <TableCell header>マネースペース</TableCell>
                    <TableCell header>取引名</TableCell>
                    <TableCell header size="sm">
                      金額
                    </TableCell>
                  </TableRow>
                }
              >
                {item.transactions.map((transaction) => (
                  <TableRow key={transaction.id} onClick={() => transactionPage(transaction.id)}>
                    <TableCell>{transaction.moneyspace.name}</TableCell>
                    <TableCell>{transaction.name}</TableCell>
                    <TableCell size="sm">
                      <FlexRow justify="flex-end">
                        <Label text={transaction.subtotal} format="amountWithUnit" />
                        <Label text="（税抜）" />
                      </FlexRow>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            )}
          </Accordion>
        </FlexColumn>
      ))}
    </FlexColumn>
  )
}

import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { MoneyspacesSelectors } from '../store/moneyspaces/selectors'
import { Moneyspace, MoneyspaceFolder } from '../types/Moneyspace'

function countUnreadMessage(
  folder: MoneyspaceFolder | null,
  folders: MoneyspaceFolder[],
  moneyspaces: Moneyspace[]
): number {
  if (folder) {
    return (
      getMoneyspaces(folder, moneyspaces, folders).reduce(
        (total, moneyspace) => total + (moneyspace.unreadMessageCount ?? 0),
        0
      ) + folder.children.reduce((total, child) => total + countUnreadMessage(child, folders, moneyspaces), 0)
    )
  }
  return getMoneyspaces(folder, moneyspaces, folders).reduce(
    (total, moneyspace) => total + (moneyspace.unreadMessageCount ?? 0),
    0
  )
}

function getMoneyspaceIds(folder: MoneyspaceFolder): string[] {
  return folder.msIds.concat(folder.children.flatMap((child) => getMoneyspaceIds(child)))
}

function getMoneyspaces(
  folder: MoneyspaceFolder | null,
  moneyspaces: Moneyspace[],
  folders: MoneyspaceFolder[]
): Moneyspace[] {
  if (folder) {
    return moneyspaces.filter((moneyspace) => folder.msIds.includes(moneyspace.id))
  }
  const folderMsIds = folders.flatMap((item) => getMoneyspaceIds(item))
  return moneyspaces.filter((moneyspace) => !folderMsIds.includes(moneyspace.id))
}

function findTreeFolder(folderId: string, folders: MoneyspaceFolder[]): MoneyspaceFolder | null {
  const result1 = folders.find((item) => item.id === folderId)
  if (result1) {
    return result1
  }
  for (let i = 0; i < folders.length; i += 1) {
    const folder = folders[i]
    const result2 = findTreeFolder(folderId, folder.children)
    if (result2) {
      return result2
    }
  }
  return null
}

export default function useMoneyspaceFolder(folder: MoneyspaceFolder | null) {
  const folders = useSelector(MoneyspacesSelectors.folders)
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const unreadMessageCount = countUnreadMessage(folder, folders, moneyspaces)
  const findFolder = useCallback((folderId: string) => findTreeFolder(folderId, folders), [folders])
  return {
    folders: folder === null ? folders : folder.children,
    moneyspaces: getMoneyspaces(folder, moneyspaces, folders),
    unreadMessageCount,
    findFolder,
  }
}

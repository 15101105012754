import { Request } from './Request'
import { Attachment, Contract, ContractSummary } from '../types/Document'
import { Page, SortDirection } from '../types/System'

export default class ContractRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadContracts(moneyspaceId: string, excludeClosed?: boolean) {
    const response = await this.request.get<{ contracts: Contract[] }>(`/contracts`, {
      msId: moneyspaceId,
      exclude: excludeClosed ? 'closed' : undefined,
    })
    return response.data.contracts
  }

  async searchContracts(page: number, moneyspaceId: string, excludeClosed?: boolean) {
    const response = await this.request.get<Page<Contract>>(`/contracts`, {
      page,
      msId: moneyspaceId,
      exclude: excludeClosed ? 'closed' : undefined,
    })
    return response.data
  }

  async loadLastUpdatedContracts() {
    const response = await this.request.get<{ contracts: Contract[] }>(`/contracts`, { filterType: 'lastUpdated' })
    return response.data.contracts
  }

  async loadActiveContracts(silent?: boolean) {
    const response = await this.request.get<{ contracts: ContractSummary[] }>(
      `/contracts`,
      { filterType: 'active' },
      undefined,
      silent
    )
    const { contracts } = response.data
    return contracts.map((contract) => {
      contract.documents.sort((doc1, doc2) => doc1.contractStep - doc2.contractStep)
      return contract
    })
  }

  async searchActiveContracts(
    page: number,
    sortColumn?: string,
    sortDirection?: SortDirection
  ): Promise<Page<ContractSummary>> {
    const sort = sortColumn && sortDirection ? `${sortColumn}_${sortDirection}` : undefined
    const response = await this.request.get<Page<ContractSummary>>(`/contracts`, {
      filterType: 'active',
      page,
      sort,
    })
    return response.data
  }

  async loadContract(id: string) {
    const response = await this.request.get<Contract>(`/contracts/${id}`)
    return response.data
  }

  async createContract(moneyspaceId: string, name: string, documentIds: string[], fixFollowingDocs: boolean) {
    const response = await this.request.post<Contract>(`/contracts`, {
      moneyspaceId,
      name,
      documents: documentIds,
      fixFollowingDocs,
    })
    return response.data
  }

  async saveContract(id: string, name: string) {
    const response = await this.request.put<Contract>(`/contracts/${id}`, {
      name,
    })
    return response.data
  }

  async deleteContract(id: string) {
    await this.request.delete(`/contracts/${id}`)
  }

  async loadAttachments(id: string) {
    const response = await this.request.get<{ attachments: Attachment[] }>(`/transactions/${id}/attachments`)
    return response.data.attachments
  }

  async uploadAttachment(id: string, file: File) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', file.name)
    const response = await this.request.post<Attachment>(`/transactions/${id}/attachments`, formData)
    return response.data
  }

  async deleteAttachment(id: string, attachmentId: string) {
    await this.request.delete(`/transactions/${id}/attachments/${attachmentId}`)
  }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncAction, GetRedirectPath } from '../actions'

export type RegisterState = {
  contractee: {
    email?: string
    password?: string
    uid?: string
    name?: string
    representativeName?: string
  }
  companyUiVerified?: boolean
}

export const initialRegisterState: RegisterState = {
  contractee: {},
}

export const RegisterActions = {
  invite: createAsyncAction<
    {
      uid: string
      getRedirectPath: GetRedirectPath<void>
    },
    {
      redirectTo: string
    }
  >('register:invite', async (params, { companyRepository, sessionRepository }, state) => {
    if (state.session.companyId) {
      await companyRepository.createClient(state.session.companyId, params.uid)
      await sessionRepository.updateRegistrationStatus({
        isContractee: true,
        isInviteContractor: true,
        isLinkedLineAccount: false,
      })
    }
    return { redirectTo: params.getRedirectPath() }
  }),
  connectLine: createAsyncAction<void, void>('register:connectLine', async (params, { sessionRepository }) => {
    await sessionRepository.updateRegistrationStatus({
      isContractee: true,
      isInviteContractor: true,
      isLinkedLineAccount: true,
    })
  }),
  validateCompanyUid: createAsyncAction<{ uid: string }, { result: boolean }>(
    'register/validate-company-uid',
    async (params, { companyRepository }) => {
      const result = await companyRepository.validateCompanyUid(params.uid)
      return {
        result,
      }
    }
  ),
  register: createAsyncAction<
    {
      email: string
      password: string
      uid: string
      name: string
      representativeName?: string
      isContractee: boolean
      getRedirectPath: GetRedirectPath<void>
    },
    {
      redirectTo: string
    }
  >('register:register', async (params, { sessionRepository }) => {
    const registrationStatus = params.isContractee
      ? {
          isContractee: true,
          isInviteContractor: false,
          isLinkedLineAccount: false,
        }
      : undefined
    await sessionRepository.register(
      params.email,
      params.password,
      params.uid,
      params.name,
      params.representativeName,
      undefined,
      registrationStatus
    )
    const redirectTo = params.getRedirectPath()
    return { redirectTo }
  }),
}

const registerSlice = createSlice({
  name: 'register',
  initialState: initialRegisterState,
  reducers: {
    setContractee: (
      state,
      action: PayloadAction<{
        email?: string
        password?: string
        uid?: string
        name?: string
        representativeName?: string
      }>
    ) => {
      state.contractee = {
        ...state.contractee,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(RegisterActions.validateCompanyUid.fulfilled, (state, action) => {
      state.companyUiVerified = action.payload.result
    })
  },
})

export default registerSlice

import { Transaction, TransactionSearchResultItem } from '../../../types/transaction'
import { FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import useTransactionAmounts from '../../../hooks/useTransactionAmounts'

type TransactionAmountViewProps = {
  transaction: Transaction | TransactionSearchResultItem
}

export default function TransactionAmountView({ transaction }: TransactionAmountViewProps) {
  const { calcSimpleAmount } = useTransactionAmounts()

  return (
    <FlexRow justify="flex-end" wrap>
      <Label text={calcSimpleAmount(transaction.details)} format="amountWithUnit" />
      <Label text={transaction.isTaxIn ? '（税込）' : '（税抜）'} />
    </FlexRow>
  )
}

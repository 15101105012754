import MuiAdapterDayjs from '@mui/lab/AdapterDayjs'
import MuiLocalizationProvider from '@mui/lab/LocalizationProvider'
import MuiDatePicker from '@mui/lab/DatePicker'
import MuiTextField from '@mui/material/TextField'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'

type DatePickerProps<T extends FieldValues> = {
  label?: string
  name: FieldPath<T>
  control: Control<T>
  error?: string
  disabled?: boolean
  clearable?: boolean
  onChange?: (value: Date | null) => void
}

export default function DateControl<T extends FieldValues>({
  label,
  name,
  control,
  error,
  disabled,
  clearable = true,
}: DatePickerProps<T>) {
  return (
    <MuiLocalizationProvider dateAdapter={MuiAdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const val = field.value as string | undefined
          return (
            <MuiDatePicker
              {...field}
              value={val === undefined ? null : val}
              label={label}
              inputFormat="YYYY/MM/DD"
              mask="____/__/__"
              views={['year', 'month', 'day']}
              clearable={clearable}
              disabled={disabled}
              onChange={(newValue: Date | null) => {
                try {
                  field.onChange(newValue?.toISOString() ?? null)
                } catch {
                  field.onChange(null)
                }
              }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  sx={{ width: 160 }}
                  error={error !== undefined}
                  helperText={error ?? ''}
                />
              )}
            />
          )
        }}
      />
    </MuiLocalizationProvider>
  )
}

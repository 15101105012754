import { useCallback } from 'react'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import { BillingSearchResultItem } from '../../../types/transaction'
import useDay from '../../../hooks/useDay'
import { FlexRow } from '../../atoms/layout/Flex'
import SortableTableCell from './SortableTableCell'
import { SortDirection } from '../../../types/System'
import useRouter from '../../../hooks/useRouter'

type BillingSearchResultTableProps = {
  billings: BillingSearchResultItem[]
  sortColumn?: string
  sortDirection?: SortDirection
  onSort: (column: string, direction: SortDirection) => void
}

export default function BillingSearchResultTable({
  billings,
  sortColumn,
  sortDirection,
  onSort,
}: BillingSearchResultTableProps) {
  const { formatDate } = useDay()
  const { moneyspace: moneyspacePage } = useRouter()

  const handleSort = useCallback(
    (name: string) => {
      if (sortColumn === name) {
        onSort(name, sortDirection === 'asc' ? 'desc' : 'asc')
      } else {
        onSort(name, 'desc')
      }
    },
    [onSort, sortColumn, sortDirection]
  )

  const handleClick = useCallback(
    (billing: BillingSearchResultItem) => {
      moneyspacePage(billing.moneyspace.id, billing.isPaid ? 'completed' : 'pending', undefined, billing.id)
    },
    [moneyspacePage]
  )

  return (
    <Table
      header={
        <TableRow>
          <SortableTableCell
            header
            onClick={handleSort}
            name="ms"
            label="マネースペース"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="sm"
          />
          <SortableTableCell
            header
            onClick={handleSort}
            name="client"
            label="取引先"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="sm"
          />
          <SortableTableCell
            header
            onClick={handleSort}
            name="amount"
            label="合計金額"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleSort}
            name="paymentDate"
            label="支払期限"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleSort}
            name="closingDate"
            label="締め日"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <TableCell header size="sm">
            取引名
          </TableCell>
          <SortableTableCell
            header
            onClick={handleSort}
            name="isPaid"
            label="支払状態"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
        </TableRow>
      }
    >
      {billings.map((item) => (
        <TableRow onClick={() => handleClick(item)}>
          <TableCell size="sm">{item.moneyspace.name}</TableCell>
          <TableCell size="sm">{item.clientName}</TableCell>
          <TableCell size="xs" align="right">
            <FlexRow justify="flex-end">
              <Label text={item.totalAmount} format="amountNoStyle" />
              <Label text="円" />
            </FlexRow>
          </TableCell>
          <TableCell size="xs" align="center">
            {item.paymentDate && formatDate(item.paymentDate)}
          </TableCell>
          <TableCell size="xs" align="center">
            {item.closingDate && formatDate(item.closingDate)}
          </TableCell>
          <TableCell size="sm">
            {item.transactions.length > 0 && (
              <>
                {item.transactions.length === 1 ? (
                  <Label text={item.transactions[0].name} />
                ) : (
                  <FlexRow>
                    <Label text={item.transactions[0].name} />
                    <Label text={`他${item.transactions.length - 1}件`} />
                  </FlexRow>
                )}
              </>
            )}
          </TableCell>
          <TableCell size="xs" align="center">
            <Label text={item.isPaid ? '支払済' : '未払い'} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

import { useCallback } from 'react'
import { parse, ParseResult } from 'papaparse'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { TaxBucket } from '../types/transaction'
import { CompanySelectors } from '../store/company/selectors'
import { CsvImportSetting } from '../types/Company'

export type TransactionCsvImportRecord = {
  externalId?: string | null
  transactionName: string | null
  isTaxIn: 1 | 2
  publishedAt: string
  note: string
  paymentDate: string | null
  closingDate: string | null
  deliveryDateFrom: string | null
  deliveryDateTo: string | null
  picId: string | null
  picSubId: string | null
  siteAddress: string
  customProp1?: string | null
  customProp2?: string | null
  customProp3?: string | null
  customProp4?: string | null
  itemDefaultProp?: string | null
  itemCustomProp1?: string | null
  itemCustomProp2?: string | null
  itemUnitPrice: number
  itemQuantity: number
  itemUnit: string
  itemTaxBucket: 1 | 2 | 3
}

type CsvParams = {
  externalId: string
  transactionName: string | null
  isTaxIn: 1 | 2
  publishedAt: string
  note: string
  paymentDate: string
  closingDate: string
  deliveryDateFrom: string
  deliveryDateTo: string
  picId: string
  picSubId: string
  siteAddress: string
  customProp1: string
  customProp2: string
  customProp3: string
  customProp4: string
  itemDefaultProp: string
  itemCustomProp1: string
  itemCustomProp2: string
  itemUnitPrice: number
  itemQuantity: number
  itemUnit: string
  itemTaxBucket: string
}

function makeTransformHeader(csvImportSetting: CsvImportSetting) {
  return (header: string): string => {
    switch (header) {
      case csvImportSetting.externalId:
        return 'externalId'
      case csvImportSetting.name:
        return 'transactionName'
      case csvImportSetting.isTaxIn:
        return 'isTaxIn'
      case csvImportSetting.publishedAt:
        return 'publishedAt'
      case csvImportSetting.paymentDate:
        return 'paymentDate'
      case csvImportSetting.closingDate:
        return 'closingDate'
      case csvImportSetting.deliveryDateFrom:
        return 'deliveryDateFrom'
      case csvImportSetting.deliveryDateTo:
        return 'deliveryDateTo'
      case csvImportSetting.picId:
        return 'picId'
      case csvImportSetting.picSubId:
        return 'picSubId'
      case csvImportSetting.siteAddress:
        return 'siteAddress'
      case csvImportSetting.note:
        return 'note'
      case csvImportSetting.customProp1:
        return 'customProp1'
      case csvImportSetting.customProp2:
        return 'customProp2'
      case csvImportSetting.customProp3:
        return 'customProp3'
      case csvImportSetting.customProp4:
        return 'customProp4'
      case csvImportSetting.detailDefaultProp:
        return 'itemDefaultProp'
      case csvImportSetting.detailCustomProp1:
        return 'itemCustomProp1'
      case csvImportSetting.detailCustomProp2:
        return 'itemCustomProp2'
      case csvImportSetting.detailQuantity:
        return 'itemQuantity'
      case csvImportSetting.detailUnit:
        return 'itemUnit'
      case csvImportSetting.detailUnitPrice:
        return 'itemUnitPrice'
      case csvImportSetting.detailTaxBucketId:
        return 'itemTaxBucket'
      default:
        break
    }
    return header
  }
}
function transform(value: string, name: string) {
  if (name.endsWith('TaxBucket')) {
    switch (value) {
      case '10%':
      case '10':
        return '1'
      case '軽減8%':
      case '8%':
      case '8':
        return '2'
      case '非課税':
      case '':
        return '3'
      default:
        return '4'
    }
  }
  if (name === 'isTaxIn') {
    switch (value) {
      case '税込':
      case '税込み':
        return 1
      case '税抜':
      case '税抜き':
        return 2
      default:
        return 3
    }
  }
  return value
}

function toString(value: string | number | null): string | undefined {
  if (value) {
    return String(value)
  }
  return undefined
}

function toStringRequired(value: string | number | null): string {
  if (value) {
    return String(value)
  }
  return ''
}

function toNumber(value: string | number | null): number {
  if (value) {
    return Number(value)
  }
  return 0
}

export default function useTransactionCsv() {
  const csvImportSetting = useSelector(CompanySelectors.csvImportSetting)
  const load = useCallback(
    (file: File, onCompleted: (records: TransactionCsvImportRecord[]) => void) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      parse<CsvParams>(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: makeTransformHeader(csvImportSetting),
        transform,
        encoding: 'sjis',
        complete: (results: ParseResult<CsvParams>) => {
          const records = results.data.map((result) => ({
            externalId: toString(result.externalId),
            transactionName: result.transactionName,
            isTaxIn: result.isTaxIn,
            publishedAt: result.publishedAt ?? dayjs().format('YYYY/MM/DD'),
            note: result.note,
            paymentDate: result.paymentDate,
            closingDate: result.closingDate,
            deliveryDateFrom: result.deliveryDateFrom,
            deliveryDateTo: result.deliveryDateTo,
            picId: result.picId,
            picSubId: result.picSubId,
            siteAddress: result.siteAddress,
            customProp1: toString(result.customProp1),
            customProp2: toString(result.customProp2),
            customProp3: toString(result.customProp3),
            customProp4: toString(result.customProp4),
            itemDefaultProp: toString(result.itemDefaultProp),
            itemCustomProp1: toString(result.itemCustomProp1),
            itemCustomProp2: toString(result.itemCustomProp2),
            itemQuantity: toNumber(result.itemQuantity),
            itemUnit: toStringRequired(result.itemUnit),
            itemUnitPrice: toNumber(result.itemUnitPrice),
            itemTaxBucket: toNumber(result.itemTaxBucket) as TaxBucket,
          }))
          onCompleted(records)
        },
      })
    },
    [csvImportSetting]
  )
  return {
    load,
  }
}

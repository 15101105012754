import MuiDialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import MuiDialogContent from '@mui/material/DialogContent'
import MuiDialogActions from '@mui/material/DialogActions'
import MuiIconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'
import MuiBox from '@mui/material/Box'
import MuiDivider from '@mui/material/Divider'
import { Component } from '../../../types/react'

export type DialogWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type DialogProps = {
  open: boolean
  title?: string
  width?: DialogWidth
  children: Component
  actions?: Component
  onClose: () => void
  onSubmit?: () => void
}

export default function Dialog({ open, title, width = 'sm', children, actions, onClose, onSubmit }: DialogProps) {
  const content = (
    <>
      {children}
      {actions && (
        <>
          <MuiDivider sx={{ mx: -3, my: 2 }} />
          <MuiDialogActions>{actions}</MuiDialogActions>
        </>
      )}
    </>
  )
  return (
    <MuiDialog open={open} fullWidth maxWidth={width} onClose={onClose}>
      <MuiDialogTitle>
        <MuiBox sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <MuiBox>{title}</MuiBox>
          <MuiIconButton aria-label="閉じる" onClick={onClose}>
            <Close />
          </MuiIconButton>
        </MuiBox>
      </MuiDialogTitle>
      <MuiDialogContent dividers>
        {onSubmit ? <form onSubmit={onSubmit}>{content}</form> : <>{content}</>}
      </MuiDialogContent>
    </MuiDialog>
  )
}

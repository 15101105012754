import Stepper from '../../atoms/navigation/Stepper'

type RegisterStepViewProps = {
  step: number
}

export default function RegisterStepView({ step }: RegisterStepViewProps) {
  return (
    <Stepper
      active={step}
      steps={[
        { id: 1, label: 'アカウント作成', completed: step > 0 },
        { id: 2, label: 'メール送信完了', completed: step > 1 },
        { id: 3, label: '本登録完了', completed: step > 2 },
      ]}
    />
  )
}

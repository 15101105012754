import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type FullNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function FullNameField({ register, error, disabled }: FullNameFieldProps) {
  return <TextField register={register} placeholder="請求 太郎" size="sm" error={error?.message} readonly={disabled} />
}

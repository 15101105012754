import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Moneyspace } from '../../../types/Moneyspace'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import useProcessing from '../../../hooks/useProcessing'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import { useMoneyspaceMembersSettingDialog } from '../../../hooks/useDialog'
import { CompanySelectors } from '../../../store/company/selectors'
import UserSelectField from '../../molecules/inputs/UserSelectField'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import { FlexColumn } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { toRelatedUser } from '../../../types/User'
import { SessionSelectors } from '../../../store/session/selectors'
import useMoneyspace from '../../../hooks/useMoneyspace'

type MoneyspaceMembersSettingDialogProps = {
  moneyspace: Moneyspace
}

type MoneyspaceMembersParams = {
  members: string[]
}

export default function MoneyspaceMembersDialog({ moneyspace }: MoneyspaceMembersSettingDialogProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.saveMembers.typePrefix)
  const { state, close } = useMoneyspaceMembersSettingDialog()
  const [errorMessage, setErrorMessage] = useState<string>()
  const users = useSelector(CompanySelectors.users)
  const companyId = useSelector(SessionSelectors.companyId)
  const { findMembers } = useMoneyspace()
  const moneyspaceMemberIds = findMembers(moneyspace.id).map((member) => member.id)
  const memberIds = users.map((user) => (moneyspaceMemberIds.includes(user.id) ? user.id : undefined))
  const dispatch = useDispatch()
  const yup = useYup()
  const schema = yup.object().shape({})
  const { handleSubmit, control, watch } = useForm<MoneyspaceMembersParams>(schema, {
    members: memberIds,
  })
  watch((params) => {
    const { members } = params
    if (members) {
      const selectedOwners = users.filter((user) => members.includes(user.id) && user.role === 1)
      if (selectedOwners.length === 0) {
        setErrorMessage('オーナーは１人以上参加する必要があります')
      } else {
        setErrorMessage(undefined)
      }
    }
  })
  const handleSave = useCallback(
    (params: MoneyspaceMembersParams) => {
      startProcess()
      const selectedMembers = users.filter((user) => params.members.includes(user.id)).map(toRelatedUser)
      const selectedMemberIds = selectedMembers.map((member) => member.id)
      const deleteMembers = findMembers(moneyspace.id)
        .filter((member) => member.companyId === companyId)
        .filter((member) => !selectedMemberIds.includes(member.id))
      dispatch(MoneyspacesActions.saveMembers({ moneyspaceId: moneyspace.id, members: selectedMembers, deleteMembers }))
    },
    [startProcess, users, findMembers, moneyspace.id, dispatch, companyId]
  )
  return (
    <Dialog
      open={state !== undefined}
      title="マネースペースのメンバーを選択"
      actions={
        <Button type="submit" disabled={errorMessage !== undefined || processing}>
          保存
        </Button>
      }
      onClose={close}
      onSubmit={handleSubmit(handleSave)}
    >
      <FlexColumn>
        <UserSelectField users={users} name="members" control={control} defaultCheckedValues={moneyspaceMemberIds} />
        {errorMessage && <Label text={errorMessage} color="error" variant="caption" />}
      </FlexColumn>
    </Dialog>
  )
}

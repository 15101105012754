import { styled } from '@mui/material'

type ImageProps = {
  url: string
  alt: string
  width?: number
  height?: number
  borderRadius?: number
}

const StyledImg = styled('img')({})

export default function Image({ url, alt, width, height, borderRadius }: ImageProps) {
  return <StyledImg src={url} alt={alt} width={width} height={height} sx={{ borderRadius }} />
}

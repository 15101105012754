import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import {
  useDocumentSearchConditionDeleteDialog,
  useDocumentSearchConditionFormDialog,
  useDocumentSearchConditionListDialog,
} from '../../../hooks/useDialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { DocumentsSelectors } from '../../../store/documents/selectors'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon, EditIcon, SearchIcon } from '../../atoms/display/Icons'
import { DocumentsActions } from '../../../store/documents'
import { DocumentSearchCondition } from '../../../types/Document'
import useDay from '../../../hooks/useDay'

export default function DocumentSearchConditionListDialog() {
  const { state, close } = useDocumentSearchConditionListDialog()
  const documentSearchConditions = useSelector(DocumentsSelectors.searchConditions)
  const documentSearchConditionFormDialog = useDocumentSearchConditionFormDialog()
  const documentSearchConditionDeleteDialog = useDocumentSearchConditionDeleteDialog()
  const { formatDate } = useDay()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(DocumentsActions.fetchSearchCondition())
  }, [dispatch])
  const handleSearch = useCallback(
    (condition: DocumentSearchCondition) => {
      const { params } = condition
      dispatch(
        DocumentsActions.updateSearchParams({
          keyword: params.keyword,
          moneyspacePositions: params.moneyspacePositions,
          documentTypes: params.documentTypes,
          documentStatus: params.documentStatus,
          paymentDateFrom: params.paymentDateFrom && formatDate(params.paymentDateFrom),
          paymentDateTo: params.paymentDateTo && formatDate(params.paymentDateTo),
          closingDateFrom: params.closingDateFrom && formatDate(params.closingDateFrom),
          closingDateTo: params.closingDateTo && formatDate(params.closingDateTo),
          amountMin: params.amountMin,
          amountMax: params.amountMax,
          searchType: params.searchType,
        })
      )
    },
    [dispatch, formatDate]
  )
  return (
    <Dialog open={state !== undefined} onClose={close} title="お気に入り検索条件">
      <FlexColumn>
        {documentSearchConditions.length === 0 && <Label text="お気に入りの検索条件はありません" />}
        {documentSearchConditions.map((condition) => (
          <FlexRow justify="space-between" align="center" key={condition.id}>
            <Label text={condition.name} />
            <FlexRow>
              <IconButton label="検索" onClick={() => handleSearch(condition)}>
                <SearchIcon />
              </IconButton>
              <IconButton label="編集" onClick={() => documentSearchConditionFormDialog.open(condition.id)}>
                <EditIcon />
              </IconButton>
              <IconButton label="削除" onClick={() => documentSearchConditionDeleteDialog.open(condition.id)}>
                <DeleteIcon />
              </IconButton>
            </FlexRow>
          </FlexRow>
        ))}
      </FlexColumn>
    </Dialog>
  )
}

import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useMemo } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import DocumentItemUnitPriceField from '../../molecules/inputs/DocumentItemUnitPriceField'
import DocumentItemQuantityField from '../../molecules/inputs/DocumentItemQuantityField'
import DocumentItemUnitField from '../../molecules/inputs/DocumentItemUnitField'
import TaxCategoryField from '../../molecules/inputs/TaxCategoryField'
import NumberField from '../../atoms/inputs/NumberField'
import { DocumentAttributes, DocumentItem } from '../../../types/Document'
import DocumentItemPropertyNameField from '../../molecules/inputs/DocumentItemPropertyNameField'
import DocumentItemConstructionTypeField from '../../molecules/inputs/DocumentItemConstructionTypeField'
import DocumentItemPicNameField from '../../molecules/inputs/DocumentItemPicNameField'
import DocumentItemOthersField from '../../molecules/inputs/DocumentItemOthersField'
import Label from '../../atoms/display/Label'
import { RelatedUser } from '../../../types/User'

type DocumentItemFormProps = {
  contracteeMembers: RelatedUser[]
  register: UseFormRegister<DocumentAttributes>
  control: Control
  errors?: FieldErrors<DocumentItem>
  index: number
  amount: number
  disabled?: boolean
}

function ErrorLabel({ errors }: { errors?: FieldErrors<DocumentItem> }) {
  if (errors) {
    const { message } = errors as unknown as { message: string }
    if (message) {
      return <Label text={message} color="error" variant="caption" />
    }
  }
  return <></>
}

export default function DocumentItemForm({
  contracteeMembers,
  register,
  control,
  errors,
  index,
  amount,
  disabled,
}: DocumentItemFormProps) {
  const itemForm = useMemo(
    () => (
      <FlexColumn>
        <ErrorLabel errors={errors} />
        <FlexRow wrap>
          <FormItem label="物件名" required>
            <DocumentItemPropertyNameField
              register={register(`details.${index}.propertyName`)}
              error={errors && errors.propertyName}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="工事種別" required>
            <DocumentItemConstructionTypeField
              register={register(`details.${index}.constructionType`)}
              error={errors && errors.constructionType}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="担当者名">
            <DocumentItemPicNameField
              members={contracteeMembers}
              register={register(`details.${index}.picName`)}
              control={control}
              name={`details.${index}.picName`}
              error={errors && errors.picName}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="その他">
            <DocumentItemOthersField
              register={register(`details.${index}.others`)}
              error={errors && errors.others}
              disabled={disabled}
            />
          </FormItem>
        </FlexRow>
        <FlexRow wrap>
          <FormItem label="単価" required>
            <DocumentItemUnitPriceField
              name={`details.${index}.unitPrice`}
              control={control}
              error={errors && errors.unitPrice}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="数量" required>
            <DocumentItemQuantityField
              name={`details.${index}.quantity`}
              control={control}
              error={errors && errors.quantity}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="単位" required>
            <DocumentItemUnitField
              register={register(`details.${index}.unit`)}
              error={errors && errors.unit}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="税区分">
            <TaxCategoryField name={`details.${index}.taxBucket`} control={control} disabled={disabled} />
          </FormItem>
          <FormItem label="金額">
            <NumberField type="amount" readonly value={amount} disabled={disabled} />
          </FormItem>
        </FlexRow>
      </FlexColumn>
    ),
    [amount, contracteeMembers, control, disabled, errors, index, register]
  )
  return <FlexColumn>{itemForm}</FlexColumn>
}

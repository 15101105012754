import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Card from '../../atoms/surfaces/Card'
import { ChatActions } from '../../../store/chat'
import ChatPanel from './ChatPanel'

type ChatWindowProps = {
  moneyspaceId: string
  open: boolean
  showMoneyspaceButton?: boolean
}

export default function ChatWindow({ moneyspaceId, open, showMoneyspaceButton }: ChatWindowProps) {
  const dispatch = useDispatch()
  useEffect(() => {
    if (moneyspaceId) {
      dispatch(ChatActions.fetchMessages({ moneyspaceId }))
    }
    const interval = setInterval(() => {
      dispatch(ChatActions.fetchMessages({ moneyspaceId }))
    }, 10000)
    return () => clearInterval(interval)
  }, [dispatch, moneyspaceId])
  return (
    <>
      {open && (
        <Card elevation={1} size="lg">
          <ChatPanel moneyspaceId={moneyspaceId} showMoneyspaceButton={showMoneyspaceButton} />
        </Card>
      )}
    </>
  )
}

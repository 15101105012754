import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import usePage from '../hooks/usePage'
import { FlexColumn, FlexRow } from '../components/atoms/layout/Flex'
import Label from '../components/atoms/display/Label'
import Pagination from '../components/atoms/navigation/Pagination'
import PaginationCountLabel from '../components/molecules/display/PaginationCountLabel'
import Button from '../components/atoms/inputs/Button'
import { SortDirection } from '../types/System'
import { useTransactionBulkApproveDialog } from '../hooks/useDialog'
import useRouter from '../hooks/useRouter'
import { TransactionActions } from '../store/transactions'
import { TransactionSearchResultItem } from '../types/transaction'
import { TransactionSelectors } from '../store/transactions/selectors'
import TransactionSearchResultSortableTable from '../components/molecules/display/TransactionSearchResultSortableTable'

export default function PendingTransactionsPage() {
  const { changeTitle } = usePage()
  const { pendingTransactions } = useRouter()
  const dispatch = useDispatch()
  const unapprovedTransactions = useSelector(TransactionSelectors.unapprovedTransactions)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const column = query.get('column') ?? undefined
  const direction = (query.get('direction') as SortDirection) ?? undefined
  const page = Number(query.get('page') ?? 1)
  const [checkedTransactions, setCheckedTransactions] = useState<TransactionSearchResultItem[]>([])
  const TransactionBulkApproveDialog = useTransactionBulkApproveDialog()

  useEffect(() => {
    changeTitle('あなたの承認待ち')
  }, [changeTitle])
  useEffect(() => {
    dispatch(TransactionActions.loadUnapprovedTransactions({ page, sortColumn: column, sortDirection: direction }))
  }, [dispatch, page, column, direction])
  useEffect(() => {
    setCheckedTransactions([])
    window.scrollTo(0, 0)
  }, [unapprovedTransactions])
  const handleSort = (name: string, sortDirection: SortDirection) => {
    pendingTransactions(page, name, sortDirection)
  }
  const handlePage = (pageNumber: number) => {
    pendingTransactions(pageNumber, column, direction)
  }
  const handleChangeChecked = useCallback((item: TransactionSearchResultItem[]) => {
    setCheckedTransactions(item)
  }, [])
  return (
    <FlexColumn>
      <FlexRow justify="space-between" wrap>
        <Label text="自分に承認フローが回ってきた取引の一覧です。確認してください。" variant="subtitle" />
        <FlexColumn align="flex-end">
          <Button
            disabled={checkedTransactions.length === 0}
            onClick={() =>
              TransactionBulkApproveDialog.open(
                checkedTransactions.map((item) => item.id),
                page,
                column,
                direction
              )
            }
          >
            一括承認
          </Button>
          <Label text="却下の操作は各ページにご確認ください" variant="caption" />
        </FlexColumn>
      </FlexRow>
      {unapprovedTransactions.count === 0 ? (
        <Label text="承認待ちの取引はありません" />
      ) : (
        <>
          <PaginationCountLabel page={page} contents={unapprovedTransactions} />
          <TransactionSearchResultSortableTable
            transactions={unapprovedTransactions.results}
            sortColumn={column}
            sortDirection={direction}
            onSort={handleSort}
            checkedTransactions={checkedTransactions}
            onChangeChecked={handleChangeChecked}
          />
          <Pagination count={unapprovedTransactions.count} page={page} size={30} onChange={handlePage} />
        </>
      )}
    </FlexColumn>
  )
}

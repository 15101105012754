import MuiTimeline from '@mui/lab/Timeline'
import MuiTimelineItem from '@mui/lab/TimelineItem'
import MuiTimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import MuiTimelineSeparator from '@mui/lab/TimelineSeparator'
import MuiTimelineDot from '@mui/lab/TimelineDot'
import MuiTimelineConnector from '@mui/lab/TimelineConnector'
import MuiTimelineContent from '@mui/lab/TimelineContent'
import { Component } from '../../../types/react'

type TimelineItem = {
  key: string
  left?: Component
  right?: Component
}

type TimelineProps = {
  items: TimelineItem[]
  leftWidth?: number
  rightWidth?: number
}

export default function Timeline({ items, leftWidth, rightWidth }: TimelineProps) {
  return (
    <MuiTimeline position="right">
      {items.map((item, index) => (
        <MuiTimelineItem key={item.key}>
          {item.left && (
            <MuiTimelineOppositeContent maxWidth={leftWidth} sx={{ marginRight: 0 }}>
              {item.left}
            </MuiTimelineOppositeContent>
          )}
          <MuiTimelineSeparator>
            <MuiTimelineDot />
            {items.length - 1 !== index && <MuiTimelineConnector />}
          </MuiTimelineSeparator>
          {item.right && <MuiTimelineContent maxWidth={rightWidth}>{item.right}</MuiTimelineContent>}
        </MuiTimelineItem>
      ))}
    </MuiTimeline>
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField, { TextSize } from '../../atoms/inputs/TextField'

type AddressFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
  size?: TextSize
}

export default function AddressField({ register, error, disabled, size }: AddressFieldProps) {
  return (
    <TextField
      register={register}
      placeholder="東京都千代田区神田神保町"
      size={size ?? 'lg'}
      error={error?.message}
      readonly={disabled}
    />
  )
}

import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { useCallback } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import CompanyNameField from '../../molecules/inputs/CompanyNameField'
import OfficialPositionField from '../../molecules/inputs/OfficialPositionField'
import FullNameField from '../../molecules/inputs/FullNameField'
import PostalCodeField from '../../molecules/inputs/PostalCodeField'
import AddressField from '../../molecules/inputs/AddressField'
import { CompanyBasic } from '../../../types/Company'

type CompanyBasicFormProps = {
  register: UseFormRegister<CompanyBasic>
  errors: FieldErrors<CompanyBasic>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
}

export default function CompanyBasicForm({ register, errors, getValues, setValue }: CompanyBasicFormProps) {
  const handleSearchAddress = useCallback(
    (address: string) => {
      setValue('address', address)
    },
    [setValue]
  )
  return (
    <FlexColumn>
      <FlexRow wrap>
        <FormItem label="法人名 / 個人事業主名" required>
          <CompanyNameField register={register('name')} error={errors.name} />
        </FormItem>
        <FormItem label="代表者役職">
          <OfficialPositionField register={register('representativeRole')} error={errors.representativeRole} />
        </FormItem>
        <FormItem label="代表者氏名">
          <FullNameField register={register('representativeName')} error={errors.representativeName} />
        </FormItem>
      </FlexRow>
      <FlexRow>
        <FormItem label="郵便番号">
          <PostalCodeField
            register={register('zipCode')}
            error={errors.zipCode}
            getValues={getValues}
            onSearchAddress={handleSearchAddress}
          />
        </FormItem>
      </FlexRow>
      <FlexRow>
        <FormItem label="住所">
          <AddressField register={register('address')} error={errors.address} />
        </FormItem>
      </FlexRow>
    </FlexColumn>
  )
}

import { useParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DocumentForm from '../../components/organisms/document/DocumentForm'
import useDocument from '../../hooks/useDocument'
import usePage from '../../hooks/usePage'
import { Contractee, DocumentAttributes } from '../../types/Document'
import useDay from '../../hooks/useDay'
import { FlexColumn } from '../../components/atoms/layout/Flex'
import useRouter from '../../hooks/useRouter'
import useProcessing from '../../hooks/useProcessing'
import { DocumentsActions } from '../../store/documents'
import useConsts from '../../hooks/useConsts'
import { TransactionActions } from '../../store/transactions'
import { TransactionSelectors } from '../../store/transactions/selectors'
import { MoneyspacesSelectors } from '../../store/moneyspaces/selectors'

function buildContractee(formatDate: (value: string) => string, contractee?: Contractee) {
  if (contractee === undefined) {
    return undefined
  }
  const closingDate = formatDate(contractee.closingDate)
  const paymentDate = formatDate(contractee.paymentDate)
  return {
    ...contractee,
    closingDate,
    paymentDate,
  }
}

export default function DocumentEditPage() {
  const { processing, startProcess } = useProcessing(DocumentsActions.saveDocument.typePrefix)
  const { moneyspaceId, contractId, documentId } = useParams()
  const { formatDate } = useDay()
  const { document, load, save } = useDocument()
  const { changeTitle } = usePage()
  const { document: documentPage } = useRouter()
  const { documentTypeName } = useConsts()
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const contract = useSelector(TransactionSelectors.contract)
  const dispatch = useDispatch()
  useEffect(() => {
    if (documentId) {
      load(documentId)
    }
  }, [documentId, load])
  useEffect(() => {
    if (contractId) {
      dispatch(TransactionActions.fetchContract({ id: contractId }))
    }
  }, [contractId, dispatch])
  useEffect(() => {
    if (contract && document) {
      const moneyspace = moneyspaces.find((ms) => ms.id === moneyspaceId)
      changeTitle(`${moneyspace?.name ?? ''} ${contract.name}`, documentTypeName(document.type))
    }
  }, [changeTitle, contract, document, documentTypeName, moneyspaceId, moneyspaces])
  const handleSubmit = useCallback(
    (values: DocumentAttributes, ocrFile?: File) => {
      if (moneyspaceId && contractId && documentId && document) {
        startProcess()
        save(moneyspaceId, contractId, documentId, {
          ...values,
          publishedAt: formatDate(values.publishedAt),
          deliveryDateFrom: values.deliveryDateFrom ? formatDate(values.deliveryDateFrom) : null,
          deliveryDateTo: values.deliveryDateTo ? formatDate(values.deliveryDateTo) : null,
          contractee: buildContractee(formatDate, values.contractee),
          contractor: values.contractor,
          details: values.details.map((detail) => {
            if (document.details.find((item) => item.id === detail.id)) {
              return detail
            }
            return {
              ...detail,
              id: undefined,
            }
          }),
        })
        if (ocrFile) {
          dispatch(TransactionActions.uploadAttachments({ transactionId: contractId, files: [ocrFile] }))
        }
      }
    },
    [moneyspaceId, contractId, documentId, document, startProcess, save, formatDate, dispatch]
  )
  if (document === undefined || moneyspaceId === undefined || contractId === undefined) {
    return <></>
  }
  return (
    <FlexColumn>
      <DocumentForm
        document={document}
        documentType={document.type}
        processing={processing}
        moneyspaceId={moneyspaceId}
        disabledDetails={contract?.fixFollowingDocs && contract.documents[0].id !== document.id}
        onSubmit={handleSubmit}
        onCancel={() => documentId && documentPage(moneyspaceId, contractId, documentId)}
      />
    </FlexColumn>
  )
}

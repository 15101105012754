import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import usePage from '../hooks/usePage'
import { FlexColumn, FlexRow } from '../components/atoms/layout/Flex'
import Label from '../components/atoms/display/Label'
import Pagination from '../components/atoms/navigation/Pagination'
import PaginationCountLabel from '../components/molecules/display/PaginationCountLabel'
import { SortDirection } from '../types/System'
import useRouter from '../hooks/useRouter'
import { TransactionActions } from '../store/transactions'
import { TransactionSelectors } from '../store/transactions/selectors'
import Table from '../components/atoms/display/Table'
import TableRow from '../components/atoms/display/TableRow'
import SortableTableCell from '../components/molecules/display/SortableTableCell'
import TableCell from '../components/atoms/display/TableCell'
import useDay from '../hooks/useDay'
import Checkbox from '../components/atoms/inputs/Checkbox'
import Button from '../components/atoms/inputs/Button'
import { useBillingBulkPaidDialog } from '../hooks/useDialog'
import { SessionSelectors } from '../store/session/selectors'

export default function PaymentBillingsPage() {
  const { changeTitle } = usePage()
  const { formatDate } = useDay()
  const { paymentBillings, moneyspace: moneyspacePage } = useRouter()
  const dispatch = useDispatch()
  const searchBillingResult = useSelector(TransactionSelectors.searchBillingResult)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const sortColumn = query.get('column') ?? undefined
  const sortDirection = (query.get('direction') as SortDirection) ?? undefined
  const page = Number(query.get('page') ?? 1)
  const [selectedBillingIds, setSelectedBillingIds] = useState<string[]>([])
  const billingBulkPaidDialog = useBillingBulkPaidDialog()
  const user = useSelector(SessionSelectors.user)
  const canBulkPaid = user?.role !== 3

  useEffect(() => {
    changeTitle('支払い')
  }, [changeTitle])
  useEffect(() => {
    setSelectedBillingIds([])
  }, [searchBillingResult])
  useEffect(() => {
    dispatch(
      TransactionActions.searchBilling({
        page,
        searchParams: {
          isPaid: 0,
          filterType: 'unpaid',
          sortColumn,
          sortDirection,
        },
      })
    )
  }, [dispatch, page, sortColumn, sortDirection])
  const handleSort = (name: string) => {
    if (sortColumn === name) {
      paymentBillings(page, name, sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      paymentBillings(page, name, 'desc')
    }
    window.scrollTo(0, 0)
  }
  const handlePage = (pageNumber: number) => {
    paymentBillings(pageNumber, sortColumn, sortDirection)
    window.scrollTo(0, 0)
  }
  const handleSelectAll = (checked: boolean) => {
    if (checked && searchBillingResult) {
      setSelectedBillingIds(searchBillingResult?.results.map((item) => item.id))
    } else {
      setSelectedBillingIds([])
    }
  }
  const handleSelect = (checked: boolean, billingId: string) => {
    if (checked) {
      setSelectedBillingIds(selectedBillingIds.concat(billingId))
    } else {
      setSelectedBillingIds(selectedBillingIds.filter((item) => item !== billingId))
    }
  }

  if (searchBillingResult === undefined) {
    return <></>
  }
  return (
    <FlexColumn>
      {searchBillingResult.count === 0 ? (
        <Label text="支払いが必要な書類はありません" />
      ) : (
        <>
          <FlexRow align="center" justify="space-between">
            <PaginationCountLabel page={page} contents={searchBillingResult} />
            {canBulkPaid && (
              <Button
                disabled={selectedBillingIds.length === 0}
                onClick={() =>
                  billingBulkPaidDialog.open(selectedBillingIds, {
                    isPaid: 0,
                    filterType: 'unpaid',
                    sortColumn,
                    sortDirection,
                  })
                }
              >
                支払済にする
              </Button>
            )}
          </FlexRow>
          <Table
            header={
              <TableRow>
                {canBulkPaid && (
                  <TableCell size="xs3" align="center">
                    <Checkbox
                      onChange={handleSelectAll}
                      checked={selectedBillingIds.length === searchBillingResult.results.length}
                      indeterminate={
                        selectedBillingIds.length !== searchBillingResult.results.length &&
                        selectedBillingIds.length > 0
                      }
                    />
                  </TableCell>
                )}
                <SortableTableCell
                  header
                  onClick={handleSort}
                  name="ms"
                  label="マネースペース"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  size="sm"
                />
                <SortableTableCell
                  header
                  onClick={handleSort}
                  name="client"
                  label="受注者"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  size="sm"
                />
                <SortableTableCell
                  header
                  onClick={handleSort}
                  name="amount"
                  label="合計金額"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  size="xs"
                />
                <SortableTableCell
                  header
                  onClick={handleSort}
                  name="paymentDate"
                  label="支払期限"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  size="xs"
                />
                <SortableTableCell
                  header
                  onClick={handleSort}
                  name="closingDate"
                  label="締め日"
                  sortColumn={sortColumn}
                  sortDirection={sortDirection}
                  size="xs"
                />
                <TableCell header size="sm">
                  取引名
                </TableCell>
              </TableRow>
            }
          >
            {searchBillingResult.results.map((item) => (
              <TableRow onClick={() => moneyspacePage(item.moneyspace.id, 'pending', undefined, item.id)}>
                {canBulkPaid && (
                  <TableCell size="xs3" align="center">
                    <Checkbox
                      onChange={(checked) => handleSelect(checked, item.id)}
                      checked={selectedBillingIds.includes(item.id)}
                    />
                  </TableCell>
                )}
                <TableCell size="sm">{item.moneyspace.name}</TableCell>
                <TableCell size="sm">{item.clientName}</TableCell>
                <TableCell size="xs" align="right">
                  <Label text={item.totalAmount} format="amountNoStyle" />
                </TableCell>
                <TableCell size="xs" align="center">
                  {item.paymentDate && formatDate(item.paymentDate)}
                </TableCell>
                <TableCell size="xs" align="center">
                  {item.closingDate && formatDate(item.closingDate)}
                </TableCell>
                <TableCell size="sm">
                  {item.transactions.length > 0 && (
                    <>
                      {item.transactions.length === 1 ? (
                        <Label text={item.transactions[0].name} />
                      ) : (
                        <FlexRow>
                          <Label text={item.transactions[0].name} />
                          <Label text={`他${item.transactions.length - 1}件`} />
                        </FlexRow>
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <Pagination count={searchBillingResult.count} page={page} size={30} onChange={handlePage} />
        </>
      )}
    </FlexColumn>
  )
}

import MuiCollapse from '@mui/material/Collapse'
import { Component } from '../../../types/react'

type CollapseProps = {
  open: boolean
  children: Component
}

export default function Collapse({ open, children }: CollapseProps) {
  return (
    <MuiCollapse in={open} timeout="auto" unmountOnExit>
      {children}
    </MuiCollapse>
  )
}

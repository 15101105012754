import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import Select from '../../atoms/inputs/Select'
import IconButton from '../../atoms/inputs/IconButton'
import { CopyIcon, HelpIcon } from '../../atoms/display/Icons'
import BillingAmountView from '../../molecules/display/BillingAmountView'
import Label, { formatText } from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import { TransactionActions } from '../../../store/transactions'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import useDay from '../../../hooks/useDay'
import { BillingSearchResultItem } from '../../../types/transaction'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { useBillingPaidDialog, useTransactionCopyDialog, useTransactionReleaseDialog } from '../../../hooks/useDialog'
import useRouter from '../../../hooks/useRouter'
import Link from '../../atoms/navigation/Link'
import { SessionSelectors } from '../../../store/session/selectors'
import useTransaction from '../../../hooks/useTransaction'
import BillingPdfButtons from '../transaction/BillingPdfButtons'
import TransactionAmountView from '../../molecules/display/TransactionAmountView'
import useConsts from '../../../hooks/useConsts'

type MoneyspaceTabPendingViewProps = {
  moneyspaceId: string
}

function BillingSelect(params: {
  billings: BillingSearchResultItem[]
  selectedBillingId?: string
  onChange: (index: number) => void
}) {
  const { formatDate } = useDay()
  const { billings, selectedBillingId, onChange } = params
  const { phaseName } = useConsts()
  const billingItems = billings.map((billing, index) => ({
    value: index,
    label: `支払日 ${billing.paymentDate ? formatDate(billing.paymentDate) : '-'} (${
      billing.transactions.length
    }件 / ${formatText(billing.totalAmount, 'amountNoStyle')}円) ${phaseName(billing.type)}`,
  }))

  return (
    <Select
      items={billingItems}
      value={billings.findIndex((billing) => billing.id === selectedBillingId)}
      size="lg"
      onChange={onChange}
    />
  )
}

export default function MoneyspaceTabPendingView({ moneyspaceId }: MoneyspaceTabPendingViewProps) {
  const dispatch = useDispatch()
  const billings = useSelector(TransactionSelectors.billings)
  const [selectedBilling, setSelectedBilling] = useState<BillingSearchResultItem>()
  const { formatDate } = useDay()
  const user = useSelector(SessionSelectors.user)
  const { find, isContractee } = useMoneyspace()
  const moneyspace = find(moneyspaceId)
  const transactionCopyDialog = useTransactionCopyDialog()
  const transactionReleaseDialog = useTransactionReleaseDialog()
  const billingPaidDialog = useBillingPaidDialog()
  const { transaction: transactionPage, moneyspace: moneyspacePage } = useRouter()
  const { isFixed } = useTransaction()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const billingId = query.get('billingId')

  useEffect(() => {
    if (billings.length === 0) {
      setSelectedBilling(undefined)
    } else {
      setSelectedBilling(billings[0])
      const findBilling = billings.find((billing) => billing.id === billingId)
      if (findBilling) {
        setSelectedBilling(findBilling)
      }
    }
  }, [billingId, billings])

  useEffect(() => {
    dispatch(
      TransactionActions.loadBillings({
        searchParams: {
          moneyspaceId,
          isPaid: 0,
        },
      })
    )
  }, [dispatch, moneyspaceId])

  const handleChangeBilling = useCallback(
    (index: number) => {
      if (billings.length > index) {
        moneyspacePage(moneyspaceId, 'pending', undefined, billings[index].id)
      }
    },
    [billings, moneyspaceId, moneyspacePage]
  )

  const transactions = selectedBilling ? selectedBilling.transactions : []
  return (
    <FlexColumn space={4}>
      <FlexRow justify="space-between" wrap>
        <FormItem label="支払日（取引数/合計金額）を選択">
          <BillingSelect billings={billings} selectedBillingId={selectedBilling?.id} onChange={handleChangeBilling} />
        </FormItem>
      </FlexRow>
      {moneyspace && selectedBilling && (
        <FlexRow wrap space={4}>
          <FlexColumn>
            <FlexRow align="center">
              {selectedBilling.closingDate && <Label text={`${formatDate(selectedBilling.closingDate)} 締め分`} />}
              <FlexColumn space={0}>
                {isContractee(moneyspace, user) &&
                  (!moneyspace.aggregateTransactions || isFixed(moneyspace, selectedBilling.closingDate)) &&
                  user?.role !== 3 && (
                    <Button height="small" onClick={() => billingPaidDialog.open(moneyspaceId, selectedBilling.id)}>
                      支払済にする
                    </Button>
                  )}
                <FlexRow>
                  <Label text="支払状態" variant="caption" />
                  <Label text="未払い" color="caution.main" variant="caption" />
                </FlexRow>
              </FlexColumn>
            </FlexRow>
            <BillingAmountView
              moneyspace={moneyspace}
              billing={selectedBilling}
              isFixed={isFixed(moneyspace, selectedBilling.closingDate)}
            />
          </FlexColumn>
          <FlexColumn>
            <FlexRow justify="space-between" align="center">
              <Label text="取引一覧" variant="subtitle" />
              <BillingPdfButtons billingId={selectedBilling.id} />
            </FlexRow>
            <Table
              header={
                <TableRow>
                  <TableCell header size="lg">
                    取引名
                  </TableCell>
                  <TableCell header size="sm">
                    金額
                  </TableCell>
                  <TableCell header size="xs">
                    分割請求
                  </TableCell>
                  <TableCell header size="xs" />
                  <TableCell header size="sm2">
                    <FlexRow align="center">
                      <Label text="おまとめ解除" />
                      <Link href="https://monect-tutorial.jp/beginner-2#%E8%A7%A3%E9%99%A4%E3%83%9C%E3%82%BF%E3%83%B3%E3%81%AE%E8%AA%AC%E6%98%8E">
                        <HelpIcon />
                      </Link>
                    </FlexRow>
                  </TableCell>
                </TableRow>
              }
            >
              {transactions.map((transaction) => (
                <TableRow key={transaction.id} onClick={() => transactionPage(transaction.id)}>
                  <TableCell size="lg">{transaction.name}</TableCell>
                  <TableCell size="sm" align="center">
                    <TransactionAmountView transaction={transaction} />
                  </TableCell>
                  <TableCell size="xs" align="center">
                    <>{transaction.times && <Label text={`第${transaction.times}回`} />}</>
                  </TableCell>
                  <TableCell size="xs" align="center">
                    <IconButton
                      label="コピー"
                      disabled={transaction.times !== null || user?.role === 3}
                      onClick={() => transactionCopyDialog.open(transaction.id, transaction.name)}
                    >
                      <CopyIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell size="sm2" align="center">
                    <Button
                      size="sm"
                      height="small"
                      variant="outlined"
                      disabled={
                        transactions.length === 1 ||
                        isFixed(moneyspace, selectedBilling.closingDate) ||
                        user?.role === 3 ||
                        !isContractee(moneyspace, user)
                      }
                      onClick={() => transactionReleaseDialog.open(moneyspaceId, transaction.id)}
                    >
                      解除
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </FlexColumn>
        </FlexRow>
      )}
    </FlexColumn>
  )
}

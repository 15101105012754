import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useUserImageSettingDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn } from '../../atoms/layout/Flex'
import FileSelectField from '../../molecules/inputs/FileSelectField'
import FileSelectButton from '../../atoms/inputs/FileSelectButton'
import useFile from '../../../hooks/useFile'
import ImageEditor from '../../atoms/inputs/ImageEditor'
import { SessionActions } from '../../../store/session'
import useDay from '../../../hooks/useDay'
import useProcessing from '../../../hooks/useProcessing'

export default function UserImageSettingDialog() {
  const { processing, startProcess } = useProcessing(SessionActions.uploadUserImage.typePrefix)
  const { state, close } = useUserImageSettingDialog()
  const { base64, base64ToFile } = useFile()
  const { timestamp } = useDay()
  const accepts = ['image/jpeg', 'image/png', 'image/gif']
  const [image, setImage] = useState<string | undefined>(undefined)
  const [trimmedImage, setTrimmedImage] = useState<string | undefined>(undefined)
  const dispatch = useDispatch()
  const handleSelectFile = useCallback(
    async (files: File[]) => {
      if (files.length > 0) {
        const selectedImage = await base64(files[0])
        setImage(selectedImage)
      }
    },
    [base64, setImage]
  )
  const handleClose = useCallback(() => {
    close()
    setImage(undefined)
    setTrimmedImage(undefined)
  }, [close, setImage])
  const handleUpload = useCallback(() => {
    if (trimmedImage) {
      startProcess()
      const file = base64ToFile(trimmedImage, `image_${timestamp()}.png`, 'image/png')
      dispatch(SessionActions.uploadUserImage({ file }))
      setImage(undefined)
      setTrimmedImage(undefined)
    }
  }, [trimmedImage, startProcess, base64ToFile, timestamp, dispatch])

  return (
    <Dialog
      open={state !== undefined}
      onClose={handleClose}
      title="プロフィール画像"
      actions={
        <Button disabled={trimmedImage === undefined || processing} onClick={handleUpload}>
          アップロード
        </Button>
      }
    >
      {image ? (
        <FlexColumn align="center">
          <ImageEditor image={image} aspect={1 / 1} onChange={setTrimmedImage} />
        </FlexColumn>
      ) : (
        <FlexColumn align="center">
          <FileSelectField accepts={accepts} onSelect={handleSelectFile} maxFiles={1} />
          <FileSelectButton accepts={accepts} onSelect={handleSelectFile}>
            画像を選択する
          </FileSelectButton>
        </FlexColumn>
      )}
    </Dialog>
  )
}

import { Control } from 'react-hook-form'
import Select from '../../atoms/inputs/Select'

type PaymentMonthFieldProps = {
  name: string
  control: Control
}

export default function PaymentMonthField({ name, control }: PaymentMonthFieldProps) {
  return (
    <Select
      value={2}
      items={[
        { value: 1, label: '当月' },
        { value: 2, label: '翌月' },
        { value: 3, label: '翌々月' },
        { value: 4, label: '翌々々月' },
      ]}
      size="xs"
      name={name}
      control={control}
    />
  )
}

import { Control, FieldError } from 'react-hook-form'
import Select from '../../atoms/inputs/Select'

type DayFieldProps = {
  control: Control
  name: string
  error?: FieldError
}

export default function DayField({ control, name, error }: DayFieldProps) {
  // return <TextField register={register} placeholder="31" size="xs" error={error?.message} />
  const items: { value: number; label: string }[] = []
  for (let day = 1; day < 28; day += 1) {
    items.push({
      value: day,
      label: `${day}日`,
    })
  }
  items.push({
    value: 99,
    label: '末日',
  })
  return <Select control={control} name={name} error={error?.message} items={items} size="xs" />
}

import { ReactNode } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MuiBox from '@mui/material/Box'

type AccordionProps = {
  title: ReactNode
  children: ReactNode
}

export default function Accordion({ title, children }: AccordionProps) {
  return (
    <MuiAccordion>
      <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <MuiBox width="100%">{title}</MuiBox>
      </MuiAccordionSummary>
      <MuiAccordionDetails>{children}</MuiAccordionDetails>
    </MuiAccordion>
  )
}

import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import Terms from '../../../components/atoms/display/Terms'
import Checkbox from '../../../components/atoms/inputs/Checkbox'
import { RegisterActions } from '../../../store/register'
import { RegisterSelectors } from '../../../store/register/selectors'
import usePath from '../../../hooks/usePath'

export default function RegisterContracteeTermsPage() {
  const [agree, setAgree] = useState<boolean>(false)
  const { registerContracteeCompleted } = usePath()
  const contractee = useSelector(RegisterSelectors.contractee)
  const dispatch = useDispatch()

  const handleNext = useCallback(() => {
    if (contractee.email && contractee.password && contractee.uid && contractee.name) {
      dispatch(
        RegisterActions.register({
          email: contractee.email,
          password: contractee.password,
          uid: contractee.uid,
          name: contractee.name,
          representativeName: contractee.representativeName,
          isContractee: true,
          getRedirectPath: () => registerContracteeCompleted(),
        })
      )
    }
  }, [contractee, dispatch, registerContracteeCompleted])

  return (
    <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
      <FlexColumn align="center">
        <Label text="アカウント登録" variant="title" />
        <FlexColumn mx={2}>
          <Label text="利用規約を確認" />
          <Terms height={320} />
          <Checkbox label="同意する" checked={agree} onChange={setAgree} />
        </FlexColumn>
      </FlexColumn>
      <FlexColumn align="center">
        <Button size="lg" disabled={!agree} onClick={handleNext}>
          次へ
        </Button>
        <FlexRow justify="center">
          <DotPagination count={11} current={3} />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}

export type DeviceType = 'Mobile' | 'PC'

export function useDeviceType() {
  const mobile = navigator.userAgent.match(/iPhone|Android.+Mobile/)
  const type = mobile ? 'Mobile' : 'PC'
  return {
    type,
    pc: type === 'PC',
    mobile: type === 'Mobile',
  }
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import useProcessing from '../../../hooks/useProcessing'
import { TransactionActions } from '../../../store/transactions'
import { useBillingBulkPaidDialog } from '../../../hooks/useDialog'
import { BillingSearchParams } from '../../../types/search'

type BillingBulkPaidDialogProps = {
  billingIds: string[]
  searchParams: BillingSearchParams
}

export default function BillingBulkPaidDialog({ billingIds, searchParams }: BillingBulkPaidDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.bulkPaidBilling.typePrefix)
  const { close } = useBillingBulkPaidDialog()
  const dispatch = useDispatch()
  const handlePaid = useCallback(() => {
    startProcess()
    dispatch(
      TransactionActions.bulkPaidBilling({
        billingIds,
        searchParams,
      })
    )
  }, [startProcess, dispatch, billingIds, searchParams])
  return (
    <Dialog
      open
      onClose={close}
      title="支払い"
      actions={
        <Button color="caution" onClick={handlePaid} disabled={processing}>
          支払済にする
        </Button>
      }
    >
      <FlexColumn space={4}>
        <FlexColumn>
          <Label text={`${billingIds.length}件の請求書類を選択中`} />
          <Label text="選択中の請求書類を一括で支払済にしますか？" />
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

import { useSelector } from 'react-redux'
import { FlexRow } from '../../atoms/layout/Flex'
import IconButton from '../../atoms/inputs/IconButton'
import { DownloadIcon, PdfIcon, PrintIcon } from '../../atoms/display/Icons'
import usePdf from '../../../hooks/usePdf'
import PdfViewer from '../../atoms/display/PdfViewer'
import { SessionSelectors } from '../../../store/session/selectors'
import { useBillingPdfPreviewDialog } from '../../../hooks/useDialog'

type BillingPdfButtonsProps = {
  billingId: string
}

export default function BillingPdfButtons({ billingId }: BillingPdfButtonsProps) {
  const pdfParams = usePdf({ filename: 'pdf' })
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { Download } = pdfParams.downloadPlugin
  const { Print } = pdfParams.printPlugin
  const token = useSelector(SessionSelectors.token)
  const BillingPdfPreviewDialog = useBillingPdfPreviewDialog()
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  if (token === undefined || baseUrl === undefined) {
    return <></>
  }

  return (
    <FlexRow>
      <Download>
        {({ onClick }) => (
          <IconButton label="ダウンロード" onClick={onClick}>
            <DownloadIcon />
          </IconButton>
        )}
      </Download>
      <Print>
        {({ onClick }) => (
          <IconButton label="印刷" onClick={onClick}>
            <PrintIcon />
          </IconButton>
        )}
      </Print>
      <IconButton label="プレビュー" onClick={() => BillingPdfPreviewDialog.open(billingId)}>
        <PdfIcon />
      </IconButton>
      <FlexRow width={0}>
        <PdfViewer
          url={`${baseUrl}/billings/${billingId}/pdf`}
          height={0}
          httpHeaders={{ authorization: `Token ${token}` }}
          params={pdfParams}
        />
      </FlexRow>
    </FlexRow>
  )
}

import { useCallback } from 'react'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import useRouter from '../../../hooks/useRouter'
import { FlexRow } from '../../atoms/layout/Flex'
import SortableTableCell from './SortableTableCell'
import { SortDirection } from '../../../types/System'
import { TransactionPhase, TransactionSearchResultItem } from '../../../types/transaction'
import TransactionStepper from '../navigation/TransactionStepper'

type TransactionSummaryTableProps = {
  transactions: TransactionSearchResultItem[]
  sortColumn?: string
  sortDirection?: SortDirection
  onSort: (name: string, direction: SortDirection) => void
}

type ContractDocumentsProps = {
  transaction: TransactionSearchResultItem
}

function TransactionPhases({ transaction }: ContractDocumentsProps) {
  const { transaction: transactionPage } = useRouter()
  return (
    <TransactionStepper
      transaction={transaction}
      onSelectPhase={(phase: TransactionPhase) => transactionPage(transaction.id, phase)}
    />
  )
}

function amount(transaction: TransactionSearchResultItem) {
  return transaction.details.map((detail) => detail.amount).reduce((value1, value2) => value1 + value2, 0)
}

export default function TransactionSummaryTable({
  transactions,
  sortColumn,
  sortDirection,
  onSort,
}: TransactionSummaryTableProps) {
  const { transaction: transactionPage } = useRouter()
  const handleClickSort = (columnName: string) => {
    if (sortColumn === columnName) {
      onSort(columnName, sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      onSort(columnName, 'desc')
    }
  }
  const handleClickCell = useCallback(
    (transaction: TransactionSearchResultItem) => {
      transactionPage(transaction.id)
    },
    [transactionPage]
  )
  return (
    <Table
      header={
        <TableRow>
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="ms"
            label="マネースペース"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="name"
            label="取引名"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <SortableTableCell
            header
            onClick={handleClickSort}
            name="totalAmount"
            label="合計金額"
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            size="xs"
          />
          <TableCell size="lg" />
        </TableRow>
      }
    >
      {transactions.map((transaction) => (
        <TableRow key={transaction.id} onClick={() => handleClickCell(transaction)}>
          <TableCell size="xs">
            <Label text={transaction.moneyspace.name} />
          </TableCell>
          <TableCell size="xs">
            <Label text={transaction.name} />
          </TableCell>
          <TableCell size="xs" align="right">
            <Label text={amount(transaction)} format="amount" />
          </TableCell>
          <TableCell size="lg">
            <FlexRow align="flex-start">
              <TransactionPhases transaction={transaction} />
            </FlexRow>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { Moneyspace } from '../../../types/Moneyspace'
import { useMoneyspaceDeleteDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import usePath from '../../../hooks/usePath'
import useProcessing from '../../../hooks/useProcessing'

type MoneyspaceDeleteDialogProps = {
  moneyspace: Moneyspace
}

export default function MoneyspaceDeleteDialog({ moneyspace }: MoneyspaceDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.deleteMoneyspace.typePrefix)
  const { state, close } = useMoneyspaceDeleteDialog()
  const { root } = usePath()
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    startProcess()
    dispatch(MoneyspacesActions.deleteMoneyspace({ moneyspace, getRedirectPath: () => root() }))
  }, [dispatch, moneyspace, root, startProcess])

  return (
    <Dialog
      open={state !== undefined}
      title="マネースペースの削除"
      actions={
        <Button color="caution" onClick={handleDelete} disabled={processing}>
          削除
        </Button>
      }
      onClose={close}
    >
      <FlexColumn space={4}>
        <FlexRow>
          <Label text="マネースペース" />
          <Label text={moneyspace.name} />
        </FlexRow>
        <Label text="このマネースペースを削除しますか？" />
      </FlexColumn>
    </Dialog>
  )
}

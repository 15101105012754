import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type DocumentNoteFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  rows?: number
}

export default function DocumentNoteField({ register, error, rows = 10 }: DocumentNoteFieldProps) {
  return <TextField multiline rows={rows} register={register} error={error?.message} />
}

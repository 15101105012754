import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import ClickableLink from '../../atoms/navigation/Link'
import { useTransactionClientViewDialog } from '../../../hooks/useDialog'
import { Transaction, TransactionPhaseBilling, TransactionPhaseBillingDetail } from '../../../types/transaction'
import useDay from '../../../hooks/useDay'
import { Moneyspace } from '../../../types/Moneyspace'
import useTransaction from '../../../hooks/useTransaction'

type TransactionViewProps = {
  moneyspace: Moneyspace
  transaction: Transaction
  isContractee: boolean
}

function isNotBlank(name?: string | null) {
  return name && name.length > 0
}

export default function TransactionView({ moneyspace, transaction, isContractee }: TransactionViewProps) {
  const transactionClientViewDialog = useTransactionClientViewDialog()
  const client = isContractee ? moneyspace.contractor : moneyspace.contractee
  const { formatDate } = useDay()
  const {
    validateDeliveryDate,
    validatePublishDate,
    validatePic,
    validateClosingDate,
    validatePaymentDate,
    validateCustomProp1,
    validateCustomProp2,
    validateCustomProp3,
    validateCustomProp4,
  } = useTransaction()

  return (
    <FlexRow align="flex-end">
      <FlexColumn width={940} align="flex-end">
        <Table>
          {(isNotBlank(moneyspace.transactionPropConfig?.customProp1.name) ||
            isNotBlank(moneyspace.transactionPropConfig?.customProp2.name)) && (
            <TableRow>
              <TableCell color="header" size="sm3">
                {moneyspace.transactionPropConfig?.customProp1.name ?? ''}
              </TableCell>
              <TableCell size="sm">
                <FlexColumn>
                  <Label text={transaction.customProp1 ?? ''} />
                  {!validateCustomProp1(moneyspace, transaction) && (
                    <Label
                      text={`${moneyspace.transactionPropConfig?.customProp1.name}が未入力です`}
                      color="error"
                      variant="caption"
                    />
                  )}
                </FlexColumn>
              </TableCell>
              <TableCell color="header" size="sm3">
                {moneyspace.transactionPropConfig?.customProp2.name ?? ''}
              </TableCell>
              <TableCell size="sm">
                <FlexColumn>
                  <Label text={transaction.customProp2 ?? ''} />
                  {!validateCustomProp2(moneyspace, transaction) && (
                    <Label
                      text={`${moneyspace.transactionPropConfig?.customProp2.name}が未入力です`}
                      color="error"
                      variant="caption"
                    />
                  )}
                </FlexColumn>
              </TableCell>
            </TableRow>
          )}
          {(isNotBlank(moneyspace.transactionPropConfig?.customProp3.name) ||
            isNotBlank(moneyspace.transactionPropConfig?.customProp4.name)) && (
            <TableRow>
              <TableCell color="header" size="sm3">
                {moneyspace.transactionPropConfig?.customProp3.name ?? ''}
              </TableCell>
              <TableCell size="sm">
                <FlexColumn>
                  <Label text={transaction.customProp3 ?? ''} />
                  {!validateCustomProp3(moneyspace, transaction) && (
                    <Label
                      text={`${moneyspace.transactionPropConfig?.customProp3.name}が未入力です`}
                      color="error"
                      variant="caption"
                    />
                  )}
                </FlexColumn>
              </TableCell>
              <TableCell color="header" size="sm3">
                {moneyspace.transactionPropConfig?.customProp4.name ?? ''}
              </TableCell>
              <TableCell size="sm">
                <FlexColumn>
                  <Label text={transaction.customProp4 ?? ''} />
                  {!validateCustomProp4(moneyspace, transaction) && (
                    <Label
                      text={`${moneyspace.transactionPropConfig?.customProp4.name}が未入力です`}
                      color="error"
                      variant="caption"
                    />
                  )}
                </FlexColumn>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell color="header" size="sm3">
              取引先名
            </TableCell>
            <TableCell size="sm">
              {client && (
                <ClickableLink onClick={() => transactionClientViewDialog.open(client, !isContractee)}>
                  {client.name}
                </ClickableLink>
              )}
              {moneyspace.client && (
                <ClickableLink
                  onClick={() => {
                    if (moneyspace.client) {
                      transactionClientViewDialog.open(moneyspace.client, !isContractee)
                    }
                  }}
                >
                  {moneyspace.client.name}
                </ClickableLink>
              )}
            </TableCell>
            <TableCell color="header" size="sm3">
              現場住所
            </TableCell>
            <TableCell size="sm">
              {transaction.siteAddress && (
                <FlexRow wrap>
                  <Label text={transaction.siteAddress} />
                  <ClickableLink href={`https://local.google.co.jp/maps?q=${transaction.siteAddress}`}>
                    地図
                  </ClickableLink>
                </FlexRow>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell color="header" size="sm3">
              担当者
            </TableCell>
            <TableCell size="sm">
              <FlexRow>
                <FlexRow space={0}>
                  <Label text={transaction.pic?.name ?? ''} />
                  {transaction.pic?.isDeleted && <Label text="（削除済みのユーザー）" />}
                </FlexRow>
                <FlexRow space={0}>
                  <Label text={transaction.picSub?.name ?? ''} />
                  {transaction.picSub?.isDeleted && <Label text="（削除済みのユーザー）" />}
                </FlexRow>
              </FlexRow>
              {!validatePic(moneyspace, transaction) && (
                <Label text="担当者が未入力です" color="error" variant="caption" />
              )}
            </TableCell>
            <TableCell color="header" size="sm3">
              発行日
            </TableCell>
            <TableCell size="sm">
              {transaction.publishedAt && formatDate(transaction.publishedAt)}
              {!validatePublishDate(transaction) && <Label text="発行日が未入力です" color="error" variant="caption" />}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell color="header" size="sm3">
              工事期間
            </TableCell>
            <TableCell size="sm">
              <FlexRow>
                {transaction.deliveryDateFrom || transaction.deliveryDateTo ? (
                  <>
                    {transaction.deliveryDateFrom && formatDate(transaction.deliveryDateFrom)}
                    {transaction.deliveryDateTo && <>〜{formatDate(transaction.deliveryDateTo)}</>}
                  </>
                ) : (
                  <>
                    {validateDeliveryDate(moneyspace, transaction) ? (
                      <Label text="-" />
                    ) : (
                      <Label text="工事期間が未入力です" color="error" variant="caption" />
                    )}
                  </>
                )}
              </FlexRow>
            </TableCell>
            <TableCell color="header" size="sm3">
              消費税の表示
            </TableCell>
            <TableCell size="sm">{transaction.isTaxIn ? '税込' : '税抜'}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell color="header" size="sm3">
              締め日
            </TableCell>
            <TableCell size="sm3">
              {validateClosingDate(moneyspace, transaction) ? (
                <>
                  {[TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase) &&
                  transaction.closingDate ? (
                    <Label text={formatDate(transaction.closingDate)} />
                  ) : (
                    <Label text="-" />
                  )}
                </>
              ) : (
                <>
                  {transaction.closingDate ? (
                    <FlexColumn space={0}>
                      <Label text={formatDate(transaction.closingDate)} />
                      <Label
                        text="承認期限が過ぎているため、次の締め日に変更してください"
                        color="error"
                        variant="caption"
                      />
                    </FlexColumn>
                  ) : (
                    <Label text="締め日が未入力です" color="error" />
                  )}
                </>
              )}
            </TableCell>
            <TableCell color="header" size="sm3">
              支払期限
            </TableCell>
            <TableCell size="sm">
              {validatePaymentDate(moneyspace, transaction) ? (
                <>
                  {[TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase) &&
                  transaction.paymentDate ? (
                    <Label text={formatDate(transaction.paymentDate)} />
                  ) : (
                    <Label text="-" />
                  )}
                </>
              ) : (
                <>
                  {transaction.paymentDate ? (
                    <FlexColumn space={0}>
                      <Label text={formatDate(transaction.paymentDate)} />
                      <Label
                        text="承認期限が過ぎているため、次の支払日に変更してください"
                        color="error"
                        variant="caption"
                      />
                    </FlexColumn>
                  ) : (
                    <Label text="支払期限が未入力です" color="error" />
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        </Table>
      </FlexColumn>
    </FlexRow>
  )
}

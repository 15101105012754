import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import LoginPage from '../../pages/login'
import usePath from '../../hooks/usePath'
import LoggedInPage from './LoggedInPage'
import UnLoggedPage from './UnLoggedPage'
import NotLoggedInLayout from '../templates/NotLoggedInLayout'
import LoggedInLayout from '../templates/LoggedInLayout'
import CompanyPage from '../../pages/settings/company'
import UsersPage from '../../pages/settings/users'
import ClientsPage from '../../pages/settings/clients'
import ProfilePage from '../../pages/settings/profile'
import DocumentPage from '../../pages/documents/id'
import DocumentEditPage from '../../pages/documents/edit'
import { SystemSelectors } from '../../store/system/selectors'
import useRouter from '../../hooks/useRouter'
import systemSlice from '../../store/system'
import RegisterPage from '../../pages/register'
import RegisterSendEmailPage from '../../pages/register/send_email'
import RegisterCompletedPage from '../../pages/register/completed'
import PasswordResetPage from '../../pages/password_reset'
import PasswordResetCompletedPage from '../../pages/password_reset/completed'
import PasswordResetSettingPage from '../../pages/password_reset/setting'
import SearchPage from '../../pages/search'
import ApprovalFlowsPage from '../../pages/settings/approval_flows'
import ChatButton from '../organisms/chat/ChatButton'
import FactoringPage from '../../pages/factoring'
import DashboardPage from '../../pages/dashboard'
import ActiveTransactionsPage from '../../pages/active_transactions'
import PaymentBillingsPage from '../../pages/payment_billings'
import PendingTransactionsPage from '../../pages/pending_transactions'
import ReceivedTransactionsPage from '../../pages/received_transactions'
import UnreadMessagesPage from '../../pages/unread_messages'
import Error500Page from '../../pages/error/500'
import MoneyspacePage from '../../pages/moneyspaces/id'
import TransactionPage from '../../pages/transactions/id'
import TransactionEditPage from '../../pages/transactions/edit'
import TransactionImporterPage from '../../pages/tools/transaction_importer'
import useConfig from '../../hooks/useConfig'
import TransactionInstallmentPage from '../../pages/transactions/installment'
import RegisterContracteeEmailPage from '../../pages/register/contractee/email'
import RegisterContracteeUidPage from '../../pages/register/contractee/uid'
import RegisterContracteeNamePage from '../../pages/register/contractee/name'
import RegisterContracteeTermsPage from '../../pages/register/contractee/terms'
import RegisterContracteeEmailSupportPage from '../../pages/register/contractee/email_support'
import RegisterContracteeCompletedPage from '../../pages/register/contractee/completed'
import RegisterContracteeInvitationPage from '../../pages/register/contractee/invitation'
import RegisterContracteeLinePage from '../../pages/register/contractee/line'
import RegisterContracteeWellcomePage from '../../pages/register/contractee/wellcome'
import RegisterContracteeInvitationCompletedPage from '../../pages/register/contractee/invitation_completed'
import RegisterContracteeAddressPage from '../../pages/register/contractee/address'
import RegisterContracteePhonePage from '../../pages/register/contractee/phone'
import RegisterContracteeInvoiceLicensePage from '../../pages/register/contractee/invoce_license'
import RegisterContracteeBankPage from '../../pages/register/contractee/bank'
import BillingTransactionsPage from '../../pages/billing_transactions'
import MessagesPage from '../../pages/messages'
import AggregationPage from '../../pages/aggregation'

function LoggedInPageLayout() {
  return (
    <LoggedInPage>
      <LoggedInLayout>
        <Outlet />
      </LoggedInLayout>
      <ChatButton />
    </LoggedInPage>
  )
}

function UnLoggedPageLayout() {
  return (
    <UnLoggedPage>
      <NotLoggedInLayout>
        <Outlet />
      </NotLoggedInLayout>
    </UnLoggedPage>
  )
}

function RegisterPageLayout() {
  return (
    <UnLoggedPage>
      <NotLoggedInLayout mt={8} mb={4}>
        <Outlet />
      </NotLoggedInLayout>
    </UnLoggedPage>
  )
}

function LoggedInRegisterPageLayout() {
  return (
    <LoggedInPage>
      <NotLoggedInLayout mt={8} mb={4}>
        <Outlet />
      </NotLoggedInLayout>
    </LoggedInPage>
  )
}

function RedirectProvider() {
  const redirectTo = useSelector(SystemSelectors.redirectTo)
  const dispatch = useDispatch()
  const { go } = useRouter()
  useEffect(() => {
    if (redirectTo) {
      dispatch(systemSlice.actions.clearRedirectTo())
      go(redirectTo)
    }
  }, [go, redirectTo, dispatch])
  return <></>
}

export default function RoutesProvider() {
  const path = usePath()
  const { enableTransactionImport } = useConfig()

  return (
    <BrowserRouter>
      <RedirectProvider />
      <Routes>
        <Route element={<LoggedInPageLayout />}>
          <Route path={path.root()} element={<DashboardPage />} />
          <Route path={path.activeTransactions()} element={<ActiveTransactionsPage />} />
          <Route path={path.billingTransactions()} element={<BillingTransactionsPage />} />
          <Route path={path.paymentBillings()} element={<PaymentBillingsPage />} />
          <Route path={path.pendingTransactions()} element={<PendingTransactionsPage />} />
          <Route path={path.receivedTransactions()} element={<ReceivedTransactionsPage />} />
          <Route path={path.unreadMessages()} element={<UnreadMessagesPage />} />
          <Route path={path.messages()} element={<MessagesPage />} />
          <Route path={path.moneyspaceMessages()} element={<MessagesPage />} />
          <Route path={path.aggregation()} element={<AggregationPage />} />
          <Route path={path.moneyspace()} element={<MoneyspacePage />} />
          <Route path={path.document()} element={<DocumentPage />} />
          <Route path={path.transaction()} element={<TransactionPage />} />
          <Route path={path.transactionEdit()} element={<TransactionEditPage />} />
          <Route path={path.transactionInstallment()} element={<TransactionInstallmentPage />} />
          <Route path={path.documentEdit()} element={<DocumentEditPage />} />
          <Route path={path.settingsCompany()} element={<CompanyPage />} />
          <Route path={path.settingsUsers()} element={<UsersPage />} />
          <Route path={path.settingsClients()} element={<ClientsPage />} />
          <Route path={path.settingsProfile()} element={<ProfilePage />} />
          <Route path={path.settingsApprovalFlows()} element={<ApprovalFlowsPage />} />
          <Route path={path.search()} element={<SearchPage />} />
          <Route path={path.factoring()} element={<FactoringPage />} />
          {enableTransactionImport && (
            <Route path={path.toolsTransactionImporter()} element={<TransactionImporterPage />} />
          )}
          <Route path={path.error500()} element={<Error500Page />} />
        </Route>
        <Route element={<UnLoggedPageLayout />}>
          <Route path={path.login()} element={<LoginPage />} />
          <Route path={path.register()} element={<RegisterPage />} />
          <Route path={path.registerSendEmail()} element={<RegisterSendEmailPage />} />
          <Route path={path.registerCompleted()} element={<RegisterCompletedPage />} />
          <Route path={path.passwordReset()} element={<PasswordResetPage />} />
          <Route path={path.passwordResetSetting()} element={<PasswordResetSettingPage />} />
          <Route path={path.passwordResetCompleted()} element={<PasswordResetCompletedPage />} />
        </Route>
        <Route element={<RegisterPageLayout />}>
          <Route path={path.registerContracteeEmail()} element={<RegisterContracteeEmailPage />} />
          <Route path={path.registerContracteeUid()} element={<RegisterContracteeUidPage />} />
          <Route path={path.registerContracteeName()} element={<RegisterContracteeNamePage />} />
          <Route path={path.registerContracteeTerms()} element={<RegisterContracteeTermsPage />} />
          <Route path={path.registerContracteeEmailSupport()} element={<RegisterContracteeEmailSupportPage />} />
          <Route path={path.registerContracteeCompleted()} element={<RegisterContracteeCompletedPage />} />
        </Route>
        <Route element={<LoggedInRegisterPageLayout />}>
          <Route path={path.registerContracteeInvitation()} element={<RegisterContracteeInvitationPage />} />
          <Route
            path={path.registerContracteeInvitationCompleted()}
            element={<RegisterContracteeInvitationCompletedPage />}
          />
          <Route path={path.registerContracteeAddress()} element={<RegisterContracteeAddressPage />} />
          <Route path={path.registerContracteePhone()} element={<RegisterContracteePhonePage />} />
          <Route path={path.registerContracteeInvoiceLicense()} element={<RegisterContracteeInvoiceLicensePage />} />
          <Route path={path.registerContracteeBank()} element={<RegisterContracteeBankPage />} />
          <Route path={path.registerContracteeLine()} element={<RegisterContracteeLinePage />} />
          <Route path={path.registerContracteeWellcome()} element={<RegisterContracteeWellcomePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const dashboardSelector = (state: StoreState) => state.dashboard
export const DashboardSelectors = {
  unapprovedDocuments: createSelector([dashboardSelector], (state) => state.unapprovedDocuments),
  latestDocuments: createSelector([dashboardSelector], (state) => state.latestDocuments),
  thisMonthPayDocuments: createSelector([dashboardSelector], (state) => state.thisMonthPayDocuments),
  contractSummaries: createSelector([dashboardSelector], (state) => state.contractSummaries),
  factoringDocuments: createSelector([dashboardSelector], (state) => state.factoringDocuments),
  data: createSelector([dashboardSelector], (state) => state.data),
}

import MuiBox from '@mui/material/Box'
import { Component } from '../../../types/react'

type AbsoluteBoxProps = {
  top?: string | number
  left?: string | number
  right?: string | number
  bottom?: string | number
  hidden?: boolean
  children: Component
}

export default function AbsoluteBox({ top, left, right, bottom, hidden, children }: AbsoluteBoxProps) {
  return (
    <MuiBox
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      position="absolute"
      boxSizing="border-box"
      hidden={hidden}
    >
      {children}
    </MuiBox>
  )
}

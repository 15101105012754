import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type BankCodeFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function BankCodeField({ register, error, disabled }: BankCodeFieldProps) {
  return (
    <TextField
      register={register}
      placeholder="9999"
      size="xs"
      error={error?.message}
      convertToAscii
      readonly={disabled}
    />
  )
}

import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import MuiAdapterDayjs from '@mui/lab/AdapterDayjs'
import MuiLocalizationProvider from '@mui/lab/LocalizationProvider'
import MuiTimePicker from '@mui/lab/TimePicker'
import MuiTextField from '@mui/material/TextField'

type TimeControlProps<T extends FieldValues> = {
  name: FieldPath<T>
  control: Control<T>
  error?: string
  disabled?: boolean
}

export default function TimeControl<T extends FieldValues>({ name, control, error, disabled }: TimeControlProps<T>) {
  return (
    <MuiLocalizationProvider dateAdapter={MuiAdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const val = field.value as string | undefined
          return (
            <MuiTimePicker
              {...field}
              value={val ?? null}
              clearable
              inputFormat="HH:mm"
              mask="__:__"
              views={['hours', 'minutes']}
              ampm={false}
              disabled={disabled}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  size="small"
                  sx={{ width: 120 }}
                  error={error !== undefined}
                  helperText={error ?? ''}
                />
              )}
            />
          )
        }}
      />
    </MuiLocalizationProvider>
  )
}

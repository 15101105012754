import MuiCheckbox from '@mui/material/Checkbox'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { Control, Controller } from 'react-hook-form'
import { FormControl, FormHelperText } from '@mui/material'
import MuiBox from '@mui/material/Box'
import { FlexColumn, FlexRow } from '../layout/Flex'
import { Component } from '../../../types/react'

type CheckboxItem = {
  label: string
  avatar?: Component
  value: number | string
  disabled?: boolean
}

type CheckboxiesProps = {
  items: CheckboxItem[]
  column?: boolean
  name: string
  defaultCheckedValues?: (number | string | undefined)[]
  control: Control
  error?: string
}

export default function Checkboxies({ items, column, name, defaultCheckedValues, control, error }: CheckboxiesProps) {
  const FlexBox = column ? FlexColumn : FlexRow
  return (
    <FormControl>
      <FlexBox wrap>
        {items.map((item, index) => (
          <Controller
            render={({ field }) => (
              <MuiFormControlLabel
                control={
                  <MuiCheckbox
                    {...field}
                    value={item.value}
                    defaultChecked={defaultCheckedValues && defaultCheckedValues.includes(item.value)}
                    disabled={item.disabled}
                    onChange={(e) => {
                      field.onChange(e.target.checked ? item.value : undefined)
                    }}
                  />
                }
                label={
                  <MuiBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    {item.avatar && item.avatar}
                    {item.label}
                  </MuiBox>
                }
              />
            )}
            name={`${name}.${index}`}
            control={control}
            key={item.value}
          />
        ))}
      </FlexBox>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

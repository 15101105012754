import { FieldErrors, UseFormGetValues, UseFormRegister } from 'react-hook-form'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CompanyAttributes } from '../../../types/Company'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import TextField from '../../atoms/inputs/TextField'
import Label from '../../atoms/display/Label'
import { CompanyActions } from '../../../store/company'
import { CompanySelectors } from '../../../store/company/selectors'
import Alert from '../../atoms/feedback/Alert'

type InvoiceLicenseNumberFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  errors: FieldErrors<CompanyAttributes>
  disabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
}

export default function InvoiceLicenseNumberForm({
  register,
  errors,
  disabled,
  getValues,
}: InvoiceLicenseNumberFormProps) {
  const dispatch = useDispatch()
  const invoiceLicenseCompanyName = useSelector(CompanySelectors.invoiceLicenseCompanyName)
  useEffect(() => {
    const invoiceLicenseNumber = getValues('invoiceLicenseNumber') as string
    if (invoiceLicenseNumber) {
      dispatch(CompanyActions.searchInvoiceLicense({ invoiceLicenseNumber: `T${invoiceLicenseNumber}` }))
    } else {
      dispatch(CompanyActions.searchInvoiceLicense({ invoiceLicenseNumber: undefined }))
    }
  }, [dispatch, getValues])
  const handleChange = useCallback(
    (value?: string) => {
      if (value) {
        if (value.length === 13) {
          dispatch(CompanyActions.searchInvoiceLicense({ invoiceLicenseNumber: `T${value}` }))
        }
      } else {
        dispatch(CompanyActions.searchInvoiceLicense({ invoiceLicenseNumber: undefined }))
      }
    },
    [dispatch]
  )
  return (
    <FlexColumn>
      <FlexColumn space={0}>
        <FormItem label="事業者登録番号">
          <TextField
            prefix="T"
            register={register('invoiceLicenseNumber')}
            error={errors.invoiceLicenseNumber?.message}
            placeholder="1234567890123"
            size="sm"
            convertToAscii
            readonly={disabled}
            onChange={handleChange}
          />
        </FormItem>
        <Label text="2023年10月以降、適格請求書（インボイス）に記載が必要な番号です" variant="caption" />
      </FlexColumn>
      {invoiceLicenseCompanyName === null && <Alert label="該当する事業者が存在しません" severity="error" />}
      {invoiceLicenseCompanyName && (
        <FlexColumn>
          <FlexRow>
            <Label text="事業者名" />
            <Label text={invoiceLicenseCompanyName} />
          </FlexRow>
          {invoiceLicenseCompanyName !== getValues('name') &&
            invoiceLicenseCompanyName !== getValues('representativeName') && (
              <Alert
                title="登録の法人名/個人事業主名と事業者登録番号から取得した事業者名が一致しないため、保存できません"
                severity="error"
              >
                ※法人名を変更する場合、登記情報の商号変更手続き完了後に「法人名/個人事業主名」の更新ができます
              </Alert>
            )}
        </FlexColumn>
      )}
    </FlexColumn>
  )
}

import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import MoneyspaceNameField from '../../molecules/inputs/MoneyspaceNameField'
import MoneyspacePositionSelect from '../../molecules/inputs/MoneyspacePositionSelect'
import { MoneyspaceCreateParams } from '../../../types/Moneyspace'
import Label from '../../atoms/display/Label'
import CompanyPaymentForm from '../company/CompanyPaymentForm'
import ClientSelect from '../../molecules/inputs/ClientSelect'
import { CompanySelectors } from '../../../store/company/selectors'
import RadioGroup from '../../atoms/inputs/RadioGroup'
import MembershipFeeView from '../../molecules/display/MembershipFeeView'

type MoneyspaceFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  control: Control
  errors: FieldErrors<MoneyspaceCreateParams>
  disabledMembershipFee?: boolean
  hideMembershipFee?: boolean
  displayRole: boolean
}

export default function MoneyspaceForm({
  register,
  control,
  errors,
  disabledMembershipFee,
  hideMembershipFee,
  displayRole,
}: MoneyspaceFormProps) {
  const membershipFee = useSelector(CompanySelectors.membershipFee)
  const clients = useSelector(CompanySelectors.clients)
  return (
    <FlexColumn>
      <FormItem label="マネースペース名" required>
        <MoneyspaceNameField register={register('name')} error={errors.name} />
      </FormItem>
      <FormItem label="取引先" required>
        <FlexColumn space={0}>
          <ClientSelect clients={clients} name="invitee" control={control} error={errors.invitee} />
          <Label
            text="企業に所属しているユーザー全員が、このマネースペースを閲覧できるようになります"
            variant="caption"
          />
        </FlexColumn>
      </FormItem>
      {displayRole && (
        <FormItem label="マネースペースでの立場" required>
          <MoneyspacePositionSelect name="isContractee" control={control} />
        </FormItem>
      )}
      <CompanyPaymentForm control={control} errors={errors} />
      <FlexColumn space={0}>
        <RadioGroup
          items={[
            { value: 1, label: '協力会費なし' },
            { value: 2, label: '協力会費あり' },
          ]}
          name="membershipFee"
          control={control}
          disabled={membershipFee?.conditions.length === 0 || disabledMembershipFee}
          row
        />
        {!hideMembershipFee && !disabledMembershipFee && <MembershipFeeView membershipFee={membershipFee} />}
      </FlexColumn>
    </FlexColumn>
  )
}

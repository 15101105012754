import { useDispatch, useSelector } from 'react-redux'
import { SubmitHandler } from 'react-hook-form'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { useClientCreateDialog } from '../../../hooks/useDialog'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import { CompanyActions } from '../../../store/company'
import CompanyDomainField from '../../molecules/inputs/CompanyDomainField'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import FormItem from '../../molecules/inputs/FormItem'
import useProcessing from '../../../hooks/useProcessing'
import { CompanySelectors } from '../../../store/company/selectors'
import useConsts from '../../../hooks/useConsts'
import usePath from '../../../hooks/usePath'
import EmailField from '../../molecules/inputs/EmailField'
import Form from '../../atoms/inputs/Form'

type UserInviteParams = {
  email: string
}

type ClientInviteParams = {
  companyUid: string
}

export default function ClientCreateDialog() {
  const { state, close } = useClientCreateDialog()
  const { processing: clientInviteProcessing, startProcess: startClientInviteProcess } = useProcessing(
    CompanyActions.createClient.typePrefix
  )
  const { processing: userInviteProcessing, startProcess: startUserInviteProcess } = useProcessing(
    CompanyActions.inviteUser.typePrefix
  )
  const inviteToken = useSelector(CompanySelectors.inviteToken)
  const { siteBaseUrl } = useConsts()
  const { register: registerPath } = usePath()
  const yup = useYup()
  const rules = useValidationRule()
  const userInviteSchema = yup.object().shape({
    email: rules.email.required(),
  })
  const clientInviteSchema = yup.object().shape({
    companyUid: rules.companyDomain.required(),
  })
  const {
    handleSubmit: handleUserInviteSubmit,
    register: userInviteRegister,
    errors: userInviteErrors,
  } = useForm<UserInviteParams>(userInviteSchema, {})
  const { handleSubmit, register, errors } = useForm<ClientInviteParams>(clientInviteSchema, {})
  const dispatch = useDispatch()
  const handleCreate: SubmitHandler<ClientInviteParams> = (values: ClientInviteParams) => {
    startClientInviteProcess()
    dispatch(CompanyActions.createClient({ companyUid: values.companyUid }))
  }
  const handleUserInvite: SubmitHandler<UserInviteParams> = (values: UserInviteParams) => {
    startUserInviteProcess()
    dispatch(CompanyActions.inviteUser({ email: values.email }))
  }

  return (
    <Dialog open={state !== undefined} onClose={close} title="取引先の招待" width="xs">
      <FlexColumn space={2}>
        {siteBaseUrl && inviteToken && (
          <FlexColumn space={0}>
            <Label text="未登録ユーザーの招待URL" variant="caption" />
            <Label text={`${siteBaseUrl}${registerPath(inviteToken)}`} variant="caption" />
          </FlexColumn>
        )}
        <Form onSubmit={handleUserInviteSubmit(handleUserInvite)}>
          <FlexColumn space={0}>
            <FormItem label="招待するユーザーのメールアドレス">
              <EmailField register={userInviteRegister('email')} error={userInviteErrors.email} />
            </FormItem>
            <Label text="指定アドレスへ招待メールを送信します" variant="caption" />
            <FlexRow justify="flex-end">
              <Button type="submit" disabled={userInviteProcessing}>
                招待する
              </Button>
            </FlexRow>
          </FlexColumn>
        </Form>
        <Form onSubmit={handleSubmit(handleCreate)}>
          <FlexColumn space={0}>
            <FormItem label="招待する企業ID">
              <CompanyDomainField register={register('companyUid')} error={errors.companyUid} />
            </FormItem>
            <Label text="招待先のオーナーへ招待メールを送信します" variant="caption" />
            <FlexRow justify="flex-end">
              <Button type="submit" disabled={clientInviteProcessing}>
                招待する
              </Button>
            </FlexRow>
          </FlexColumn>
        </Form>
      </FlexColumn>
    </Dialog>
  )
}

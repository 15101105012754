import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import Button from '../../../components/atoms/inputs/Button'
import useRouter from '../../../hooks/useRouter'

export default function RegisterContracteeInvitationCompletedPage() {
  const { registerContracteeAddress } = useRouter()
  return (
    <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
      <FlexColumn align="center">
        <Label text="アカウント登録" variant="title" />
        <Label text="元請けさんに送信しました" />
      </FlexColumn>
      <FlexColumn align="center">
        <Button size="lg" onClick={registerContracteeAddress}>
          次へ
        </Button>
        <FlexRow justify="center">
          <DotPagination count={11} current={5} />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}

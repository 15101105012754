import { useCallback, useState } from 'react'
import { styled } from '@mui/material'
import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { Crop } from 'react-image-crop'

type ImageEditorProps = {
  image: string
  aspect: number
  onChange: (_image: string) => void
}

const StyledCropper = styled(ReactCrop)({})

function getCroppedImg(image: HTMLImageElement, crop: Crop) {
  const canvas = document.createElement('canvas')
  const pixelRatio = window.devicePixelRatio
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const ctx = canvas.getContext('2d')

  if (ctx === null) {
    return undefined
  }

  canvas.width = crop.width * pixelRatio * scaleX
  canvas.height = crop.height * pixelRatio * scaleY

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = 'high'

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  )

  return canvas.toDataURL('image/png')
}

export default function ImageEditor({ image, aspect, onChange }: ImageEditorProps) {
  const [crop, setCrop] = useState<Crop>({ aspect, x: 25, y: 10, width: 0, height: 80, unit: '%' })
  const [imageElement, setImage] = useState<HTMLImageElement>()
  const handleComplete = useCallback(() => {
    if (imageElement) {
      const trimmedImage = getCroppedImg(imageElement, crop)
      if (trimmedImage) {
        onChange(trimmedImage)
      }
    }
  }, [imageElement, crop, onChange])

  return (
    <StyledCropper crop={crop} onChange={setCrop} onImageLoaded={setImage} src={image} onComplete={handleComplete} />
  )
}

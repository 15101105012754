import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { SystemSelectors } from '../store/system/selectors'
import systemSlice from '../store/system'

export default function useSidebar() {
  const state = useSelector(SystemSelectors.sidebar)
  const dispatch = useDispatch()
  const open = useCallback(() => {
    dispatch(systemSlice.actions.openSidebar())
  }, [dispatch])
  const close = useCallback(() => {
    dispatch(systemSlice.actions.closeSidebar())
  }, [dispatch])
  return {
    state,
    open,
    close,
  }
}

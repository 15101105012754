import MuiCard from '@mui/material/Card'
import MuiCardHeader from '@mui/material/CardHeader'
import MuiCardContent from '@mui/material/CardContent'
import MuiCardActions from '@mui/material/CardActions'
import MuiCardActionArea from '@mui/material/CardActionArea'
import { Component } from '../../../types/react'
import { useDeviceType } from '../../../hooks/useDeviceType'

export type CardSize = 'sm' | 'sm2' | 'md' | 'lg' | 'xl' | 'full'
export type CardColor = 'primary' | 'secondary' | 'info'

const width = (size: CardSize, pc: boolean) => {
  switch (size) {
    case 'sm':
      return pc ? 240 : 240
    case 'sm2':
      return pc ? 384 : 240
    case 'md':
      return pc ? 480 : 240
    case 'lg':
      return pc ? 720 : 344
    case 'xl':
      return pc ? 960 : 344
    case 'full':
      return '100%'
    default:
      return pc ? 480 : 344
  }
}

type CardProps = {
  header?: Component
  children: Component
  actions?: Component
  size?: CardSize
  elevation?: number
  color?: CardColor
  onClick?: () => void
}

function bgColor(color?: CardColor) {
  if (color === undefined) {
    return undefined
  }
  return `${color}.main`
}

function textColor(color?: CardColor) {
  if (color === undefined) {
    return undefined
  }
  return `${color}.contrastText`
}

function CardContent(props: { children: Component; onClick?: () => void }) {
  const { children, onClick } = props
  if (onClick) {
    return (
      <MuiCardActionArea onClick={onClick}>
        <MuiCardContent>{children}</MuiCardContent>
      </MuiCardActionArea>
    )
  }
  return <MuiCardContent>{children}</MuiCardContent>
}

export default function Card({ header, children, actions, size = 'md', elevation, color, onClick }: CardProps) {
  const { pc } = useDeviceType()
  return (
    <MuiCard
      variant={elevation ? 'elevation' : 'outlined'}
      sx={{ width: width(size, pc), borderRadius: 4, backgroundColor: bgColor(color), color: textColor(color) }}
      elevation={elevation}
    >
      {header && <MuiCardHeader title={header} />}
      <CardContent onClick={onClick}>{children}</CardContent>
      {actions && <MuiCardActions>{actions}</MuiCardActions>}
    </MuiCard>
  )
}

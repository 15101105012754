import MuiBox from '@mui/material/Box'
import { ReactNode } from 'react'

type DecorationType = 'Border' | 'BoldLine' | 'DoubleLine'

type DecorationBoxProps = {
  type: DecorationType
  color?: string
  children: ReactNode
}

export default function DecorationBox({ type, color = '#000', children }: DecorationBoxProps) {
  if (type === 'Border') {
    return <MuiBox sx={{ border: `solid 1px ${color}` }}>{children}</MuiBox>
  }
  if (type === 'BoldLine') {
    return <MuiBox sx={{ borderBottom: `solid 3px ${color}` }}>{children}</MuiBox>
  }
  if (type === 'DoubleLine') {
    return <MuiBox sx={{ borderBottom: `double 4px ${color}` }}>{children}</MuiBox>
  }
  return <>{children}</>
}

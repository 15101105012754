import { useSelector } from 'react-redux'
import { FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import { Contract, Document } from '../../../types/Document'
import useRouter from '../../../hooks/useRouter'
import {
  useDocumentApplyDialog,
  useDocumentApproveDialog,
  useDocumentDeleteDialog,
  useDocumentRejectDialog,
} from '../../../hooks/useDialog'
import usePermission from '../../../hooks/usePermission'
import { SessionSelectors } from '../../../store/session/selectors'
import { Moneyspace } from '../../../types/Moneyspace'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { ApprovalFlowState } from '../../../types/ApprovalFlow'

type DocumentButtonsProps = {
  moneyspace: Moneyspace
  document: Document
  contract: Contract
  approvalFlowState?: ApprovalFlowState
}

export function approvalButtonLabel(contract: Contract, document: Document) {
  if (document.type === 7 || document.status === 2) {
    return '承認'
  }
  if (contract.documents[contract.documents.length - 1].id === document.id) {
    return '取引を終了する'
  }
  return '次の書類へ'
}

export default function DocumentButtons({ moneyspace, document, contract, approvalFlowState }: DocumentButtonsProps) {
  const { documentEdit } = useRouter()
  const { documentPermissions } = usePermission()
  const user = useSelector(SessionSelectors.user)
  const documentDeleteDialog = useDocumentDeleteDialog()
  const documentApplyDialog = useDocumentApplyDialog()
  const documentApproveDialog = useDocumentApproveDialog()
  const documentRejectDialog = useDocumentRejectDialog()
  const { isContractee } = useMoneyspace()
  return (
    <FlexRow>
      {documentPermissions.edit(isContractee(moneyspace, user), document, moneyspace) && (
        <Button onClick={() => documentEdit(moneyspace.id, contract.id, document.id)}>編集</Button>
      )}
      {documentPermissions.apply(isContractee(moneyspace, user), document, moneyspace) && (
        <Button onClick={() => documentApplyDialog.open(document)}>社内確認依頼</Button>
      )}
      {documentPermissions.approve(isContractee(moneyspace, user), document, moneyspace, user, approvalFlowState) && (
        <Button onClick={() => documentApproveDialog.open(moneyspace.id, contract, document)}>
          {approvalButtonLabel(contract, document)}
        </Button>
      )}
      {documentPermissions.reject(isContractee(moneyspace, user), document, moneyspace, user, approvalFlowState) && (
        <Button color="caution" onClick={() => documentRejectDialog.open(document)}>
          却下
        </Button>
      )}
      {documentPermissions.delete(isContractee(moneyspace, user), document, moneyspace) && (
        <Button color="caution" onClick={() => documentDeleteDialog.open(document)}>
          削除
        </Button>
      )}
    </FlexRow>
  )
}

import { useSelector } from 'react-redux'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import { useMoneyspaceMembersSettingDialog, useTransactionApprovalFlowSettingDialog } from '../../../hooks/useDialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import Alert from '../../atoms/feedback/Alert'
import { Moneyspace } from '../../../types/Moneyspace'
import { SessionSelectors } from '../../../store/session/selectors'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { Transaction } from '../../../types/transaction'
import { ApprovalFlowState } from '../../../types/ApprovalFlow'

type TransactionApprovalFlowStateViewProps = {
  transaction: Transaction
  moneyspace: Moneyspace
  approvalFlowState?: ApprovalFlowState
}

export default function TransactionApprovalFlowStateView({
  transaction,
  moneyspace,
  approvalFlowState,
}: TransactionApprovalFlowStateViewProps) {
  const { findMembers } = useMoneyspace()
  const TransactionApprovalFlowSettingDialog = useTransactionApprovalFlowSettingDialog()
  const user = useSelector(SessionSelectors.user)
  const MoneyspaceMemberSettingDialog = useMoneyspaceMembersSettingDialog()
  const approvalFlowUsers = approvalFlowState?.members?.map((member) => member.user) ?? []
  const moneyspaceMemberIds = findMembers(moneyspace.id).map((member) => member.id)
  const nonParticipationUsers = approvalFlowUsers.filter((u) => !moneyspaceMemberIds.includes(u.id))
  if (approvalFlowState === undefined) {
    if (user === undefined || user.role === 3) {
      return <></>
    }
    return (
      <Button onClick={() => TransactionApprovalFlowSettingDialog.open(transaction)} size="lg">
        <Label text="承認フローを設定する" />
      </Button>
    )
  }
  return (
    <FlexColumn>
      <AvatarStepper members={approvalFlowState.members} activeStep={approvalFlowState.currentStep - 1} />
      {nonParticipationUsers.length > 0 && (
        <FlexColumn>
          <Alert severity="error">
            {`${nonParticipationUsers.map((u) => u.name).join('、')}はこのマネースペースに参加していません`}
          </Alert>
          {user?.role === 3 ? (
            <Label
              text="オーナーまたはリーダー権限のユーザーに、メンバーの設定を依頼してください"
              variant="caption"
              color="error"
            />
          ) : (
            <Button size="lg" onClick={() => MoneyspaceMemberSettingDialog.open(moneyspace)}>
              メンバーを設定する
            </Button>
          )}
        </FlexColumn>
      )}
    </FlexColumn>
  )
}

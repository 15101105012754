import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { Attachment, Contract, Contractee, Contractor, ContractSummary, DocumentAttributes } from '../../types/Document'
import { createAsyncAction, GetRedirectPath } from '../actions'
import { Moneyspace } from '../../types/Moneyspace'
import { DocumentsActions } from '../documents'
import { Page, SortDirection } from '../../types/System'
import {
  BillingSearchResultItem,
  FactoringTransaction,
  Transaction,
  TransactionActivity,
  TransactionDetailSearchResultItem,
  TransactionInstallmentDetail,
  TransactionPhase,
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionPhaseDelivered,
  TransactionPhaseEFQ,
  TransactionPhaseOrder,
  TransactionPhaseOrderConfirm,
  TransactionPhaseQuotation,
  TransactionSearchCondition,
  TransactionSearchResultItem,
} from '../../types/transaction'
import { ApprovalFlowState } from '../../types/ApprovalFlow'
import { BillingSearchParams, TransactionSearchParams } from '../../types/search'

export type TransactionsState = {
  transactions: Page<Transaction>
  transaction?: Transaction
  installmentTransactions?: Transaction[]
  selectedTransaction?: Transaction
  activities: TransactionActivity[]
  approvalFlowState?: ApprovalFlowState
  factoringTransactions: FactoringTransaction[]
  previousPhase?: Transaction
  searchParams: TransactionSearchParams
  searchingTransactions: boolean
  searchTransactionResult?: Page<TransactionSearchResultItem>
  searchTransactionDetailResult?: Page<TransactionDetailSearchResultItem>
  searchConditions: TransactionSearchCondition[]
  searchBillingParams: BillingSearchParams
  searchingBillings: boolean
  searchBillingResult?: Page<BillingSearchResultItem>
  billings: BillingSearchResultItem[]
  unapprovedTransactions: Page<TransactionSearchResultItem>
  submittedTransactions: Page<TransactionSearchResultItem>
  aggregateTransactions: TransactionSearchResultItem[]

  contracts: Page<Contract>
  contract?: Contract
  attachments: Attachment[]
  contractSummaries: ContractSummary[]
  activeTransactions: Page<TransactionSearchResultItem>
  billingTransactions: Page<TransactionSearchResultItem>
}

export const initialTransactionsState: TransactionsState = {
  transactions: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  activities: [],
  factoringTransactions: [],
  searchParams: {
    keyword: '',
    phases: {
      phase1: false,
      phase2: false,
      phase3: false,
      phase4: false,
      phase5: false,
      phase6: false,
      phase7: false,
      phase8: false,
    },
    status: 0,
    paymentDateFrom: undefined,
    paymentDateTo: undefined,
    closingDateFrom: undefined,
    closingDateTo: undefined,
    amountMin: null,
    amountMax: null,
    searchType: 1,
  },
  searchingTransactions: false,
  searchConditions: [],
  searchBillingParams: {
    clientName: undefined,
    paymentDateFrom: undefined,
    paymentDateTo: undefined,
    closingDateFrom: undefined,
    closingDateTo: undefined,
    amountMin: null,
    amountMax: null,
    isPaid: 2,
    invoiceLessActionType: 2,
  },
  searchingBillings: false,
  billings: [],
  unapprovedTransactions: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  submittedTransactions: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  aggregateTransactions: [],

  contracts: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  contract: undefined,
  attachments: [],
  contractSummaries: [],
  activeTransactions: {
    count: 0,
    results: [],
    next: null,
    previous: null,
  },
  billingTransactions: {
    count: 0,
    results: [],
    next: null,
    previous: null,
  },
}

function sortDocuments(contract: Contract) {
  return contract.documents.sort((doc1, doc2) => doc1.type - doc2.type)
}

export const TransactionActions = {
  fetchContracts: createAsyncAction<
    { page: number; moneyspaceId: string; excludeClosed?: boolean },
    { contracts: Page<Contract> }
  >('transaction:fetchContracts', async (params, { contractRepository }) => {
    const contracts = await contractRepository.searchContracts(params.page, params.moneyspaceId, params.excludeClosed)
    return { contracts }
  }),
  fetchContract: createAsyncAction<{ id: string }, { contract: Contract }>(
    'transaction:fetchContract',
    async (params, { contractRepository }) => {
      const contract = await contractRepository.loadContract(params.id)
      contract.documents = sortDocuments(contract)
      return {
        contract,
      }
    }
  ),
  fetchActiveContracts: createAsyncAction<
    void,
    {
      contractSummaries: ContractSummary[]
    }
  >('transaction:fetchActiveContracts', async (params, { contractRepository }) => {
    const contractSummaries = await contractRepository.loadActiveContracts()
    return { contractSummaries }
  }),
  searchActiveTransactions: createAsyncAction<
    {
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      activeTransactions: Page<TransactionSearchResultItem>
    }
  >('transaction:searchActiveTransactions', async (params, { transactionRepository }) => {
    const activeTransactions = await transactionRepository.searchActiveTransactions(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { activeTransactions }
  }),
  searchBillingTransactions: createAsyncAction<
    {
      page: number
      pageSize?: number
    },
    {
      billingTransactions: Page<TransactionSearchResultItem>
    }
  >('transaction:searchBillingTransactions', async (params, { transactionRepository }) => {
    const billingTransactions = await transactionRepository.searchBillingTransactions(params.page, params.pageSize)
    return { billingTransactions }
  }),
  fetchTransactions: createAsyncAction<
    {
      moneyspaceId: string
      page: number
      onBilling?: boolean
      filterType?: 'active' | 'inactive'
    },
    {
      transactions: Page<Transaction>
    }
  >('transaction:fetchTransactions', async (params, { transactionRepository }) => {
    const transactions = await transactionRepository.loadTransactions(
      params.moneyspaceId,
      params.page,
      params.onBilling,
      params.filterType
    )
    return {
      transactions,
    }
  }),
  loadTransaction: createAsyncAction<
    {
      transactionId: string
    },
    {
      transaction: Transaction
      installmentTransactions?: Transaction[]
    }
  >('transaction:loadTransaction', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.loadTransaction(params.transactionId)
    let installmentTransactions
    if (transaction.installment) {
      installmentTransactions = await transactionRepository.loadInstallmentTransactions(transaction.installment.id)
    }
    return {
      transaction,
      installmentTransactions,
    }
  }),
  loadSelectedTransaction: createAsyncAction<
    {
      transactionId: string
      phase: TransactionPhase
    },
    {
      transaction: Transaction
    }
  >('transaction:loadSelectedTransaction', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.loadTransaction(params.transactionId, params.phase)
    return {
      transaction,
    }
  }),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resetSelectedTransaction: createAsyncAction<void, void>('transaction:resetSelectedTransaction', async () => {}),
  createTransaction: createAsyncAction<
    {
      moneyspaceId: string
      name: string
      phase1: boolean
      phase2: boolean
      phase3: boolean
      phase4: boolean
      phase5: boolean
      phase6: boolean
      phase7: boolean
      fixFollowingPhase: boolean
      getRedirectPath: GetRedirectPath<Transaction>
    },
    {
      transaction: Transaction
      redirectTo: string
    }
  >('transaction:createTransaction', async (params, { transactionRepository }) => {
    const phases: TransactionPhase[] = []
    if (params.phase1) phases.push(TransactionPhaseEFQ)
    if (params.phase2) phases.push(TransactionPhaseQuotation)
    if (params.phase3) phases.push(TransactionPhaseOrder)
    if (params.phase4) phases.push(TransactionPhaseOrderConfirm)
    if (params.phase5) phases.push(TransactionPhaseDelivered)
    if (params.phase6) phases.push(TransactionPhaseBillingDetail)
    if (params.phase7) phases.push(TransactionPhaseBilling)
    const transaction = await transactionRepository.createTransaction(
      params.moneyspaceId,
      params.name,
      phases,
      params.fixFollowingPhase
    )
    return {
      transaction,
      redirectTo: params.getRedirectPath(transaction),
    }
  }),
  saveTransaction: createAsyncAction<
    {
      transaction: Transaction
      getRedirectPath?: GetRedirectPath<Transaction>
    },
    {
      transaction: Transaction
      redirectTo?: string
    }
  >('transaction:saveTransaction', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.saveTransaction(params.transaction)
    const redirectTo = params.getRedirectPath ? params.getRedirectPath(transaction) : undefined
    return {
      transaction,
      redirectTo,
    }
  }),
  saveTransactionClosingDate: createAsyncAction<
    {
      transactionId: string
      closingDate: string
      paymentDate: string
    },
    {
      transaction: Transaction
      redirectTo?: string
    }
  >('transaction:saveTransactionClosingDate', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.saveTransactionClosingDate(
      params.transactionId,
      params.closingDate,
      params.paymentDate
    )
    return {
      transaction,
    }
  }),
  saveTransactionsClosingDate: createAsyncAction<
    {
      transactionIds: string[]
      closingDate: string
      paymentDate: string
    },
    void
  >('transaction:saveTransactionsClosingDate', async (params, { transactionRepository }) => {
    await Promise.all(
      params.transactionIds.map(async (transactionId) =>
        transactionRepository.saveTransactionClosingDate(transactionId, params.closingDate, params.paymentDate)
      )
    )
  }),
  loadTransactionActivity: createAsyncAction<
    {
      transactionId: string
    },
    {
      activities: TransactionActivity[]
    }
  >('transaction:loadTransactionActivity', async (params, { transactionRepository }) => {
    const activities = await transactionRepository.loadActivities(params.transactionId)
    return {
      activities,
    }
  }),
  cancelTransaction: createAsyncAction<
    {
      transactionId: string
      note?: string
    },
    {
      transaction: Transaction
      activities: TransactionActivity[]
    }
  >('transaction:cancelTransaction', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.cancelTransaction(params.transactionId, params.note)
    const activities = await transactionRepository.loadActivities(transaction.id)
    return { transaction, activities }
  }),
  deleteTransaction: createAsyncAction<
    {
      transactionId: string
      getRedirectPath: GetRedirectPath<void>
    },
    {
      redirectTo: string
    }
  >('transaction:deleteTransaction', async (params, { transactionRepository }) => {
    await transactionRepository.deleteTransaction(params.transactionId)
    return { redirectTo: params.getRedirectPath() }
  }),
  loadApprovalFlowState: createAsyncAction<
    {
      transactionId: string
    },
    {
      approvalFlowState?: ApprovalFlowState
    }
  >('transaction:loadApprovalFlowState', async (params, { transactionRepository }) => {
    const approvalFlowState = await transactionRepository.loadApprovalFlowState(params.transactionId)
    return {
      approvalFlowState,
    }
  }),
  loadFactoringTransaction: createAsyncAction<
    void,
    {
      factoringTransactions: FactoringTransaction[]
    }
  >('transaction:loadFactoringTransaction', async (params, { transactionRepository }) => {
    const factoringTransactions = await transactionRepository.loadFactoringTransactions()
    return {
      factoringTransactions,
    }
  }),
  fetchTransactionPreviousPhase: createAsyncAction<
    {
      transactionId: string
      phase?: TransactionPhase
    },
    {
      previousPhase: Transaction
    }
  >('transaction:fetchTransactionPreviousPhase', async (params, { transactionRepository }) => {
    const previousPhase = await transactionRepository.loadTransaction(params.transactionId, params.phase)
    return {
      previousPhase,
    }
  }),
  clearTransactionPreviousPhase: createAsyncAction<void, void>('transaction:clearTransactionPreviousPhase', async () =>
    Promise.resolve()
  ),
  applyTransaction: createAsyncAction<
    {
      transactionId: string
      flowId: string
      installment?: {
        publishedAt: string
        closingDate: string
        paymentDate: string
        deliveryDateFrom?: string | null
        deliveryDateTo?: string | null
        picId?: string | null
        picSubId?: string | null
        details: TransactionInstallmentDetail[]
      }
      getRedirectPath?: GetRedirectPath<void>
    },
    {
      transaction: Transaction
      activities: TransactionActivity[]
      approvalFlowState?: ApprovalFlowState
      redirectTo?: string
    }
  >('transaction:applyTransaction', async (params, { transactionRepository }) => {
    if (params.installment) {
      const { installment } = params
      await transactionRepository.saveTransactionForInstallment(
        params.transactionId,
        installment.publishedAt,
        installment.paymentDate,
        installment.closingDate,
        installment.deliveryDateFrom ?? null,
        installment.deliveryDateTo ?? null,
        installment.picId ?? null,
        installment.picSubId ?? null
      )
      await transactionRepository.createInstallment(params.transactionId, installment.details)
    }
    await transactionRepository.updateApprovalFlow(params.transactionId, params.flowId)
    const transaction = await transactionRepository.applyTransaction(params.transactionId)
    const activities = await transactionRepository.loadActivities(transaction.id)
    const approvalFlowState = await transactionRepository.loadApprovalFlowState(params.transactionId)
    return {
      transaction,
      activities,
      approvalFlowState,
      redirectTo: params.getRedirectPath ? params.getRedirectPath() : undefined,
    }
  }),
  submitTransaction: createAsyncAction<
    {
      transaction: Transaction
    },
    {
      transaction: Transaction
      activities: TransactionActivity[]
      approvalFlowState?: ApprovalFlowState
    }
  >('transaction:submitTransaction', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.submitTransaction(params.transaction.id)
    const activities = await transactionRepository.loadActivities(transaction.id)
    const approvalFlowState = await transactionRepository.loadApprovalFlowState(params.transaction.id)
    return { transaction, activities, approvalFlowState }
  }),
  approveTransaction: createAsyncAction<
    {
      transaction: Transaction
    },
    {
      transaction: Transaction
      activities: TransactionActivity[]
      approvalFlowState?: ApprovalFlowState
    }
  >('transaction:approveDocument', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.approveTransaction(params.transaction.id, params.transaction.status)
    const activities = await transactionRepository.loadActivities(transaction.id)
    const approvalFlowState = await transactionRepository.loadApprovalFlowState(params.transaction.id)
    return { transaction, activities, approvalFlowState }
  }),
  bulkApproveTransaction: createAsyncAction<
    {
      transactionIds: string[]
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      unapprovedTransactions: Page<TransactionSearchResultItem>
    }
  >('transaction:bulkApproveDocument', async (params, { transactionRepository }) => {
    await transactionRepository.bulkApproveTransaction(params.transactionIds)
    const unapprovedTransactions = await transactionRepository.searchUnApprovedTransactions(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { unapprovedTransactions }
  }),
  updateApprovalFlow: createAsyncAction<
    {
      transactionId: string
      flowId: string
    },
    {
      activities: TransactionActivity[]
      approvalFlowState?: ApprovalFlowState
    }
  >('transaction:updateApprovalFlow', async (params, { transactionRepository }) => {
    await transactionRepository.updateApprovalFlow(params.transactionId, params.flowId)
    const activities = await transactionRepository.loadActivities(params.transactionId)
    const approvalFlowState = await transactionRepository.loadApprovalFlowState(params.transactionId)
    return { activities, approvalFlowState }
  }),
  updateApprovalFlows: createAsyncAction<
    {
      transactionIds: string[]
      flowId: string
      closingDate: string
      paymentDate: string
      getRedirectPath?: GetRedirectPath<void>
      pageSize?: number
    },
    {
      billingTransactions: Page<TransactionSearchResultItem>
      redirectTo?: string
    }
  >('transaction:updateApprovalFlows', async (params, { transactionRepository }) => {
    await Promise.all(
      params.transactionIds.map(async (transactionId) =>
        transactionRepository.updateApprovalFlow(transactionId, params.flowId)
      )
    )
    await Promise.all(
      params.transactionIds.map(async (transactionId) =>
        transactionRepository.saveTransactionClosingDate(transactionId, params.closingDate, params.paymentDate)
      )
    )
    const redirectTo = params.getRedirectPath ? params.getRedirectPath() : undefined
    const billingTransactions = await transactionRepository.searchBillingTransactions(1, params.pageSize)
    return {
      billingTransactions,
      redirectTo,
    }
  }),
  rejectTransaction: createAsyncAction<
    {
      transactionId: string
      note?: string
    },
    {
      transaction: Transaction
      activities: TransactionActivity[]
      approvalFlowState?: ApprovalFlowState
    }
  >('transaction:rejectTransaction', async (params, { transactionRepository }) => {
    const transaction = await transactionRepository.rejectTransactionPhase(params.transactionId, params.note)
    const activities = await transactionRepository.loadActivities(params.transactionId)
    const approvalFlowState = await transactionRepository.loadApprovalFlowState(params.transactionId)
    return { transaction, activities, approvalFlowState }
  }),
  saveSearchParams: createAsyncAction<
    {
      searchParams: TransactionSearchParams
    },
    {
      searchParams: TransactionSearchParams
    }
  >('transaction:saveSearchParams', async (params) =>
    Promise.resolve({
      searchParams: {
        ...params.searchParams,
        paymentDateFrom: params.searchParams.paymentDateFrom
          ? dayjs(params.searchParams.paymentDateFrom).format('YYYY-MM-DD')
          : undefined,
        paymentDateTo: params.searchParams.paymentDateTo
          ? dayjs(params.searchParams.paymentDateTo).format('YYYY-MM-DD')
          : undefined,
        closingDateFrom: params.searchParams.closingDateFrom
          ? dayjs(params.searchParams.closingDateFrom).format('YYYY-MM-DD')
          : undefined,
        closingDateTo: params.searchParams.closingDateTo
          ? dayjs(params.searchParams.closingDateTo).format('YYYY-MM-DD')
          : undefined,
      },
    })
  ),
  searchTransaction: createAsyncAction<
    {
      page: number
      searchParams: TransactionSearchParams
    },
    {
      searchTransactionResult?: Page<TransactionSearchResultItem>
      searchTransactionDetailResult?: Page<TransactionDetailSearchResultItem>
    }
  >('transaction:searchTransaction', async (params, { transactionRepository }) => {
    if (Number(params.searchParams.searchType) === 1) {
      const result = await transactionRepository.searchTransactions(params.page, params.searchParams)
      return {
        searchTransactionResult: result,
      }
    }
    const result = await transactionRepository.searchTransactionDetails(params.page, params.searchParams)
    return {
      searchTransactionDetailResult: result,
    }
  }),
  aggregateTransactions: createAsyncAction<
    {
      phase: TransactionPhase
      publishedDateFrom: string | null
      publishedDateTo: string | null
    },
    {
      transactions: TransactionSearchResultItem[]
    }
  >('transaction:aggregateTransactions', async (params, { transactionRepository }) => {
    const result = await transactionRepository.aggregateTransactions(
      params.phase,
      params.publishedDateFrom,
      params.publishedDateTo
    )
    return {
      transactions: result,
    }
  }),
  downloadTransactions: createAsyncAction<
    {
      filename: string
      searchParams: TransactionSearchParams
    },
    void
  >('transaction:downloadTransactions', async (params, { transactionRepository }) => {
    if (Number(params.searchParams.searchType) === 1) {
      await transactionRepository.downloadTransactions(params.filename, params.searchParams)
    } else {
      await transactionRepository.downloadTransactionDetails(params.filename, params.searchParams)
    }
  }),
  fetchSearchCondition: createAsyncAction<
    void,
    {
      searchConditions: TransactionSearchCondition[]
    }
  >('transaction:fetchSearchCondition', async (params, { transactionRepository }, state) => {
    const userId = state.session.user?.id ?? ''
    const response = await transactionRepository.loadSearchConditions(userId)
    const searchConditions: TransactionSearchCondition[] = response.searchQueries.map((item) => ({
      id: item.id,
      name: item.name,
      params: JSON.parse(item.query) as TransactionSearchParams,
    }))
    return {
      searchConditions,
    }
  }),
  saveSearchBillingParams: createAsyncAction<
    {
      searchParams: BillingSearchParams
    },
    {
      searchParams: BillingSearchParams
    }
  >('transaction:saveSearchBillingParams', async (params) =>
    Promise.resolve({
      searchParams: {
        ...params.searchParams,
        paymentDateFrom: params.searchParams.paymentDateFrom
          ? dayjs(params.searchParams.paymentDateFrom).format('YYYY-MM-DD')
          : undefined,
        paymentDateTo: params.searchParams.paymentDateTo
          ? dayjs(params.searchParams.paymentDateTo).format('YYYY-MM-DD')
          : undefined,
        closingDateFrom: params.searchParams.closingDateFrom
          ? dayjs(params.searchParams.closingDateFrom).format('YYYY-MM-DD')
          : undefined,
        closingDateTo: params.searchParams.closingDateTo
          ? dayjs(params.searchParams.closingDateTo).format('YYYY-MM-DD')
          : undefined,
      },
    })
  ),
  searchBilling: createAsyncAction<
    {
      page: number
      searchParams: BillingSearchParams
    },
    {
      searchBillingResult: Page<BillingSearchResultItem>
    }
  >('transaction:searchBilling', async (params, { transactionRepository }) => {
    const result = await transactionRepository.searchBillings(params.page, params.searchParams)
    return {
      searchBillingResult: result,
    }
  }),
  loadBillings: createAsyncAction<
    {
      searchParams: BillingSearchParams
    },
    {
      billings: BillingSearchResultItem[]
    }
  >('transaction:loadBilling', async (params, { transactionRepository }) => {
    const result = await transactionRepository.loadBillings(params.searchParams)
    return {
      billings: result,
    }
  }),
  downloadBillingsPdf: createAsyncAction<
    {
      filename: string
      searchParams: BillingSearchParams
    },
    void
  >('transaction:downloadBillingsPdf', async (params, { transactionRepository }) => {
    await transactionRepository.downloadBillingsPdf(params.filename, params.searchParams)
  }),
  downloadBillings: createAsyncAction<
    {
      filename: string
      searchParams: BillingSearchParams
    },
    void
  >('transaction:downloadBillings', async (params, { transactionRepository }) => {
    await transactionRepository.downloadBillings(params.filename, params.searchParams)
  }),
  saveSearchCondition: createAsyncAction<
    {
      id?: string
      name: string
      params: TransactionSearchParams
    },
    {
      id: string
      name: string
      params: TransactionSearchParams
    }
  >('transaction:saveSearchCondition', async (params, { transactionRepository }, state) => {
    const userId = state.session.user?.id ?? ''
    const json = JSON.stringify(params.params)
    if (params.id) {
      await transactionRepository.updateSearchCondition(userId, params.id, params.name)
      return {
        id: params.id,
        name: params.name,
        params: params.params,
      }
    }
    const response = await transactionRepository.createSearchCondition(userId, params.name, json)
    return {
      id: response.id,
      name: params.name,
      params: params.params,
    }
  }),
  deleteSearchCondition: createAsyncAction<
    {
      id: string
    },
    {
      id: string
    }
  >('transaction:deleteSearchCondition', async (params, { transactionRepository }, state) => {
    const userId = state.session.user?.id ?? ''
    await transactionRepository.deleteSearchCondition(userId, params.id)
    return {
      id: params.id,
    }
  }),
  loadUnapprovedTransactions: createAsyncAction<
    {
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      unapprovedTransactions: Page<TransactionSearchResultItem>
    }
  >('transaction:loadUnapprovedDocuments', async (params, { transactionRepository }) => {
    const unapprovedTransactions = await transactionRepository.searchUnApprovedTransactions(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { unapprovedTransactions }
  }),
  loadSubmittedTransactions: createAsyncAction<
    {
      page: number
      sortColumn?: string
      sortDirection?: SortDirection
    },
    {
      submittedTransactions: Page<TransactionSearchResultItem>
    }
  >('transaction:loadSubmittedTransactions', async (params, { transactionRepository }) => {
    const submittedTransactions = await transactionRepository.searchSubmittedTransactions(
      params.page,
      params.sortColumn,
      params.sortDirection
    )
    return { submittedTransactions }
  }),
  copyTransaction: createAsyncAction<
    {
      transactionId: string
      name: string
      getRedirectPath?: GetRedirectPath<string>
    },
    {
      redirectTo?: string
    }
  >('transaction:copyTransaction', async (params, { transactionRepository }) => {
    const transactionId = await transactionRepository.copyTransaction(params.transactionId, params.name)
    const redirectTo = params.getRedirectPath ? params.getRedirectPath(transactionId) : undefined
    return {
      redirectTo,
    }
  }),
  releaseTransaction: createAsyncAction<
    {
      transactionId: string
      moneyspaceId: string
    },
    {
      billings: BillingSearchResultItem[]
    }
  >('transaction:releaseTransaction', async (params, { transactionRepository }) => {
    await transactionRepository.releaseTransaction(params.transactionId)
    const result = await transactionRepository.loadBillings({
      moneyspaceId: params.moneyspaceId,
      isPaid: 0,
    })
    return {
      billings: result,
    }
  }),
  paidBilling: createAsyncAction<
    {
      moneyspaceId: string
      billingId: string
    },
    {
      billings: BillingSearchResultItem[]
    }
  >('transaction:paidBilling', async (params, { transactionRepository }) => {
    await transactionRepository.paidBilling(params.billingId)
    const result = await transactionRepository.loadBillings({
      moneyspaceId: params.moneyspaceId,
      isPaid: 0,
    })
    return {
      billings: result,
    }
  }),
  bulkPaidBilling: createAsyncAction<
    {
      billingIds: string[]
      searchParams: BillingSearchParams
    },
    {
      searchBillingResult: Page<BillingSearchResultItem>
    }
  >('transaction:bulkPaidBilling', async (params, { transactionRepository }) => {
    await transactionRepository.bulkPaidBilling(params.billingIds)
    const result = await transactionRepository.searchBillings(1, params.searchParams)
    return {
      searchBillingResult: result,
    }
  }),

  saveContract: createAsyncAction<
    {
      id: string
      name: string
    },
    {
      contract: Contract
    }
  >('transaction:saveContract', async (params, { contractRepository }) => {
    const contract = await contractRepository.saveContract(params.id, params.name)
    return {
      contract,
    }
  }),
  deleteContract: createAsyncAction<
    {
      id: string
      getRedirectPath: GetRedirectPath<void>
    },
    {
      redirectTo: string
    }
  >('transaction:deleteContract', async (params, { contractRepository }) => {
    await contractRepository.deleteContract(params.id)
    return {
      redirectTo: params.getRedirectPath(),
    }
  }),
  copyContract: createAsyncAction<
    {
      moneyspace: Moneyspace
      contract: Contract
      contractee?: Contractee
      contractor?: Contractor
      document: DocumentAttributes
      name: string
      publishedAt: string
      page: number
      excludeClosed?: boolean
    },
    {
      contracts: Page<Contract>
    }
  >('transaction:copyContract', async (params, { contractRepository, documentRepository }) => {
    const docs = params.contract.documents
    const { document } = params
    const docIds: string[] = []
    const copyDocumnet = await documentRepository.createDocument({
      name: params.name,
      type: document.type,
      publishedAt: params.publishedAt,
      japaneseCalenderDisplaying: document.japaneseCalenderDisplaying,
      note: document.note,
      isTaxIn: document.isTaxIn,
      contractee: params.contractee,
      contractor: params.contractor,
      details: [],
    })
    docIds.push(copyDocumnet.id)
    if (docs.length > 1) {
      const ids = await Promise.all(
        docs.slice(1).map(async (doc) => {
          const newDoc = await documentRepository.createDocument({
            contractee: params.contractee,
            contractor: params.contractor,
            name: params.name,
            publishedAt: params.publishedAt,
            type: doc.type,
            japaneseCalenderDisplaying: true,
            note: '',
            isTaxIn: true,
            details: [],
          })
          return newDoc.id
        })
      )
      docIds.push(...ids)
    }
    await contractRepository.createContract(
      params.moneyspace.id,
      params.name,
      docIds,
      params.contract.fixFollowingDocs ?? false
    )
    await documentRepository.saveDocument(copyDocumnet.id, {
      name: params.name,
      type: document.type,
      publishedAt: params.publishedAt,
      japaneseCalenderDisplaying: document.japaneseCalenderDisplaying,
      note: document.note,
      isTaxIn: document.isTaxIn,
      contractee: params.contractee,
      contractor: params.contractor,
      details: document.details.map((detail) => ({
        ...detail,
        id: undefined,
      })),
    })
    const contracts = await contractRepository.searchContracts(params.page, params.moneyspace.id, params.excludeClosed)
    return {
      contracts,
    }
  }),
  fetchAttachments: createAsyncAction<
    {
      id: string
    },
    {
      attachments: Attachment[]
    }
  >('transaction:fetchAttachments', async (params, { contractRepository }) => {
    const attachments = await contractRepository.loadAttachments(params.id)
    return {
      attachments,
    }
  }),
  uploadAttachments: createAsyncAction<
    {
      transactionId: string
      files: File[]
    },
    {
      attachments: Attachment[]
    }
  >('transaction:uploadAttachments', async (params, { contractRepository }) => {
    const attachments: Attachment[] = []
    await Promise.all(
      params.files.map(async (file) => {
        attachments.push(await contractRepository.uploadAttachment(params.transactionId, file))
      })
    )
    return {
      attachments,
    }
  }),
  deleteAttachment: createAsyncAction<
    {
      contractId: string
      attachmentId: string
    },
    {
      contractId: string
      attachmentId: string
    }
  >('transaction:deleteAttachment', async (params, { contractRepository }) => {
    await contractRepository.deleteAttachment(params.contractId, params.attachmentId)
    return {
      contractId: params.contractId,
      attachmentId: params.attachmentId,
    }
  }),
  saveInstallment: createAsyncAction<
    {
      transaction: Transaction
      publishedAt: string
      closingDate: string
      paymentDate: string
      deliveryDateFrom?: string | null
      deliveryDateTo?: string | null
      picId?: string | null
      picSubId?: string | null
      submit: boolean
      details: TransactionInstallmentDetail[]
      getRedirectPath?: GetRedirectPath<void>
    },
    {
      redirectTo?: string
    }
  >('transaction:saveInstallment', async (params, { transactionRepository }) => {
    await transactionRepository.saveTransactionForInstallment(
      params.transaction.id,
      params.publishedAt,
      params.paymentDate,
      params.closingDate,
      params.deliveryDateFrom ?? null,
      params.deliveryDateTo ?? null,
      params.picId ?? null,
      params.picSubId ?? null
    )
    await transactionRepository.createInstallment(params.transaction.id, params.details)
    if (params.submit) {
      await transactionRepository.submitTransaction(params.transaction.id)
    }
    return {
      redirectTo: params.getRedirectPath ? params.getRedirectPath() : undefined,
    }
  }),
  addFavorite: createAsyncAction<{ transactionId: string }, { transactionId: string }>(
    'transaction:addFavorite',
    async (params, { transactionRepository }) => {
      await transactionRepository.addFavorite(params.transactionId)
      return { transactionId: params.transactionId }
    }
  ),
  removeFavorite: createAsyncAction<{ transactionId: string }, { transactionId: string }>(
    'transaction:removeFavorite',
    async (params, { transactionRepository }) => {
      await transactionRepository.removeFavorite(params.transactionId)
      return { transactionId: params.transactionId }
    }
  ),
}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: initialTransactionsState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(TransactionActions.saveTransaction.fulfilled, (state, action) => {
        state.transaction = action.payload.transaction
      })
      .addCase(TransactionActions.searchActiveTransactions.fulfilled, (state, action) => {
        state.activeTransactions = action.payload.activeTransactions
      })
      .addCase(TransactionActions.searchBillingTransactions.fulfilled, (state, action) => {
        state.billingTransactions = action.payload.billingTransactions
      })
      .addCase(TransactionActions.fetchTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload.transactions
      })
      .addCase(TransactionActions.loadTransaction.fulfilled, (state, action) => {
        state.transaction = action.payload.transaction
        state.installmentTransactions = action.payload.installmentTransactions
      })
      .addCase(TransactionActions.loadSelectedTransaction.fulfilled, (state, action) => {
        state.selectedTransaction = action.payload.transaction
      })
      .addCase(TransactionActions.resetSelectedTransaction.fulfilled, (state) => {
        state.selectedTransaction = undefined
      })
      .addCase(TransactionActions.loadTransactionActivity.fulfilled, (state, action) => {
        state.activities = action.payload.activities
      })
      .addCase(TransactionActions.loadApprovalFlowState.fulfilled, (state, action) => {
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(TransactionActions.loadFactoringTransaction.fulfilled, (state, action) => {
        state.factoringTransactions = action.payload.factoringTransactions
      })
      .addCase(TransactionActions.cancelTransaction.fulfilled, (state, action) => {
        if (state.transaction?.id === action.payload.transaction.id) {
          state.transaction = action.payload.transaction
          state.activities = action.payload.activities
        }
      })
      .addCase(TransactionActions.applyTransaction.fulfilled, (state, action) => {
        state.transaction = action.payload.transaction
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(TransactionActions.submitTransaction.fulfilled, (state, action) => {
        state.transaction = action.payload.transaction
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(TransactionActions.approveTransaction.fulfilled, (state, action) => {
        state.transaction = action.payload.transaction
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(TransactionActions.bulkApproveTransaction.fulfilled, (state, action) => {
        state.unapprovedTransactions = action.payload.unapprovedTransactions
      })
      .addCase(TransactionActions.updateApprovalFlow.fulfilled, (state, action) => {
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(TransactionActions.updateApprovalFlows.fulfilled, (state, action) => {
        state.billingTransactions = action.payload.billingTransactions
      })
      .addCase(TransactionActions.rejectTransaction.fulfilled, (state, action) => {
        state.transaction = action.payload.transaction
        state.activities = action.payload.activities
        state.approvalFlowState = action.payload.approvalFlowState
      })
      .addCase(TransactionActions.saveSearchParams.fulfilled, (state, action) => {
        state.searchParams = action.payload.searchParams
      })
      .addCase(TransactionActions.searchTransaction.pending, (state) => {
        state.searchingTransactions = true
      })
      .addCase(TransactionActions.searchTransaction.fulfilled, (state, action) => {
        state.searchingTransactions = false
        state.searchTransactionResult = action.payload.searchTransactionResult
        state.searchTransactionDetailResult = action.payload.searchTransactionDetailResult
      })
      .addCase(TransactionActions.searchTransaction.rejected, (state) => {
        state.searchingTransactions = false
      })
      .addCase(TransactionActions.aggregateTransactions.fulfilled, (state, action) => {
        state.aggregateTransactions = action.payload.transactions
      })
      .addCase(TransactionActions.saveSearchBillingParams.fulfilled, (state, action) => {
        state.searchBillingParams = action.payload.searchParams
      })
      .addCase(TransactionActions.searchBilling.pending, (state) => {
        state.searchingBillings = true
      })
      .addCase(TransactionActions.searchBilling.fulfilled, (state, action) => {
        state.searchingBillings = false
        state.searchBillingResult = action.payload.searchBillingResult
      })
      .addCase(TransactionActions.searchBilling.rejected, (state) => {
        state.searchingBillings = false
      })
      .addCase(TransactionActions.loadBillings.fulfilled, (state, action) => {
        state.billings = action.payload.billings
      })
      .addCase(TransactionActions.fetchSearchCondition.fulfilled, (state, action) => {
        state.searchConditions = action.payload.searchConditions
      })
      .addCase(TransactionActions.saveSearchCondition.fulfilled, (state, action) => {
        const condition = state.searchConditions.find((item) => item.id === action.payload.id)
        if (condition) {
          condition.name = action.payload.name
        } else {
          state.searchConditions.push(action.payload)
        }
      })
      .addCase(TransactionActions.deleteSearchCondition.fulfilled, (state, action) => {
        state.searchConditions = state.searchConditions.filter((item) => item.id !== action.payload.id)
      })
      .addCase(TransactionActions.loadUnapprovedTransactions.fulfilled, (state, action) => {
        state.unapprovedTransactions = action.payload.unapprovedTransactions
      })
      .addCase(TransactionActions.loadSubmittedTransactions.fulfilled, (state, action) => {
        state.submittedTransactions = action.payload.submittedTransactions
      })
      .addCase(TransactionActions.fetchTransactionPreviousPhase.fulfilled, (state, action) => {
        state.previousPhase = action.payload.previousPhase
      })
      .addCase(TransactionActions.clearTransactionPreviousPhase.fulfilled, (state) => {
        state.previousPhase = undefined
      })

      .addCase(TransactionActions.fetchContracts.fulfilled, (state, action) => {
        state.contracts = action.payload.contracts
      })
      .addCase(TransactionActions.fetchContract.fulfilled, (state, action) => {
        state.contract = action.payload.contract
      })
      .addCase(TransactionActions.fetchActiveContracts.fulfilled, (state, action) => {
        state.contractSummaries = action.payload.contractSummaries
      })
      .addCase(TransactionActions.saveContract.fulfilled, (state, action) => {
        if (state.contract?.id === action.payload.contract.id) {
          state.contract = action.payload.contract
        }
      })
      .addCase(TransactionActions.copyContract.fulfilled, (state, action) => {
        state.contracts = action.payload.contracts
      })
      .addCase(TransactionActions.deleteContract.fulfilled, (state) => {
        state.contract = undefined
      })
      .addCase(TransactionActions.fetchAttachments.fulfilled, (state, action) => {
        state.attachments = action.payload.attachments
      })
      .addCase(TransactionActions.uploadAttachments.fulfilled, (state, action) => {
        state.attachments = state.attachments.concat(action.payload.attachments)
      })
      .addCase(TransactionActions.deleteAttachment.fulfilled, (state, action) => {
        state.attachments = state.attachments.filter((attachment) => attachment.id !== action.payload.attachmentId)
      })
      .addCase(DocumentsActions.deleteDocument.fulfilled, (state, action) => {
        state.contracts.results.forEach((contract) => {
          contract.documents = contract.documents.filter((document) => document.id !== action.payload.id)
        })
        if (state.contract) {
          state.contract.documents = state.contract.documents.filter((document) => document.id !== action.payload.id)
        }
      })
      .addCase(TransactionActions.paidBilling.fulfilled, (state, action) => {
        state.billings = action.payload.billings
      })
      .addCase(TransactionActions.bulkPaidBilling.fulfilled, (state, action) => {
        state.searchBillingResult = action.payload.searchBillingResult
      })
      .addCase(TransactionActions.releaseTransaction.fulfilled, (state, action) => {
        state.billings = action.payload.billings
      })
      .addCase(TransactionActions.addFavorite.fulfilled, (state, action) => {
        state.transactions.results = state.transactions.results.map((transaction) => {
          if (transaction.id === action.payload.transactionId) {
            return { ...transaction, isFavorite: true }
          }
          return transaction
        })
        if (state.searchTransactionResult) {
          state.searchTransactionResult.results = state.searchTransactionResult.results.map((transaction) => {
            if (transaction.id === action.payload.transactionId) {
              return { ...transaction, isFavorite: true }
            }
            return transaction
          })
        }
        if (state.searchTransactionDetailResult) {
          state.searchTransactionDetailResult.results = state.searchTransactionDetailResult.results.map((detail) => {
            if (detail.transaction.id === action.payload.transactionId) {
              return {
                ...detail,
                transaction: { ...detail.transaction, isFavorite: true },
              }
            }
            return detail
          })
        }
      })
      .addCase(TransactionActions.removeFavorite.fulfilled, (state, action) => {
        state.transactions.results = state.transactions.results.map((transaction) => {
          if (transaction.id === action.payload.transactionId) {
            return { ...transaction, isFavorite: false }
          }
          return transaction
        })
        if (state.searchTransactionResult) {
          state.searchTransactionResult.results = state.searchTransactionResult.results.map((transaction) => {
            if (transaction.id === action.payload.transactionId) {
              return { ...transaction, isFavorite: false }
            }
            return transaction
          })
        }
        if (state.searchTransactionDetailResult) {
          state.searchTransactionDetailResult.results = state.searchTransactionDetailResult.results.map((detail) => {
            if (detail.transaction.id === action.payload.transactionId) {
              return {
                ...detail,
                transaction: { ...detail.transaction, isFavorite: false },
              }
            }
            return detail
          })
        }
      })
  },
})

export default transactionsSlice

import MuiTableRow from '@mui/material/TableRow'
import { Component } from '../../../types/react'

type TableRowProps = {
  noBorder?: boolean
  children: Component
  onClick?: () => void
}

export default function TableRow({ noBorder, children, onClick }: TableRowProps) {
  return (
    <MuiTableRow
      sx={{ '& > *': { borderBottom: noBorder ? 'none' : undefined }, cursor: onClick ? 'pointer' : undefined }}
      hover={onClick !== undefined}
      onClick={onClick}
    >
      {children}
    </MuiTableRow>
  )
}

import { Company, CompanyAttributes } from '../../../types/Company'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import MoneyspaceUsersView from './MoneyspaceUsersView'
import { Moneyspace } from '../../../types/Moneyspace'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import { useMoneyspaceClientEditDialog } from '../../../hooks/useDialog'

type MoneyspaceClientViewProps = {
  client: CompanyAttributes | null
  company?: Company
  moneyspace: Moneyspace
  editableMember: boolean
}

export default function MoneyspaceClientView({
  client,
  company,
  moneyspace,
  editableMember,
}: MoneyspaceClientViewProps) {
  const moneyspaceClientEditDialog = useMoneyspaceClientEditDialog()
  if (company) {
    return (
      <FlexColumn space={0}>
        <Label text={company.name} />
        <MoneyspaceUsersView moneyspace={moneyspace} targetCompanyId={company.id} editable={editableMember} />
      </FlexColumn>
    )
  }
  return (
    <FlexRow align="center">
      {client && <Label text={client.name} />}
      <Button variant="text" onClick={() => moneyspaceClientEditDialog.open(moneyspace)}>
        取引先情報を設定
      </Button>
    </FlexRow>
  )
}

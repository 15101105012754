import { DocumentItem } from '../../../types/Document'
import { useDocumentCsvImportItemPreviewDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Label from '../../atoms/display/Label'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import TaxCategoryLabel from '../../molecules/display/TaxCategoryLabel'

type DocumentCsvImportItemPreviewDialogProps = {
  items: DocumentItem[]
}

export default function DocumentCsvImportItemPreviewDialog({ items }: DocumentCsvImportItemPreviewDialogProps) {
  const { state, close } = useDocumentCsvImportItemPreviewDialog()
  return (
    <Dialog open={state !== undefined} onClose={close} title={`明細の確認 ${state ? state.title : ''}`} width="xl">
      <Table
        header={
          <TableRow>
            <TableCell header size="xs">
              #
            </TableCell>
            <TableCell header size="sm">
              物件名
            </TableCell>
            <TableCell header size="sm">
              工事種別
            </TableCell>
            <TableCell header size="xs">
              担当者
            </TableCell>
            <TableCell header size="sm">
              その他
            </TableCell>
            <TableCell header size="xs">
              単価
            </TableCell>
            <TableCell header size="xs">
              数量
            </TableCell>
            <TableCell header size="xs">
              単位
            </TableCell>
            <TableCell header size="xs">
              税区分
            </TableCell>
          </TableRow>
        }
      >
        {items.map((item, index) => (
          <TableRow>
            <TableCell size="xs">
              <Label text={index + 1} />
            </TableCell>
            <TableCell size="sm">{item.propertyName}</TableCell>
            <TableCell size="sm">{item.constructionType}</TableCell>
            <TableCell size="xs">{item.picName}</TableCell>
            <TableCell size="sm">{item.others}</TableCell>
            <TableCell size="xs">
              <Label text={item.unitPrice} format="amount" />
            </TableCell>
            <TableCell size="xs">
              <Label text={item.quantity} />
            </TableCell>
            <TableCell size="xs">
              <Label text={item.unit} />
            </TableCell>
            <TableCell size="xs">
              <TaxCategoryLabel category={item.taxBucket} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Dialog>
  )
}

import MuiBox from '@mui/material/Box'
import shortid from 'shortid'

type DotPaginationProps = {
  count: number
  current: number
}

export default function DotPagination({ count, current }: DotPaginationProps) {
  return (
    <MuiBox sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      {[...Array(count)].map((_, i) => (
        <MuiBox
          key={shortid()}
          sx={{
            width: 16,
            height: 16,
            borderRadius: '50%',
            backgroundColor: i <= current ? 'primary.main' : 'lightgray',
          }}
        />
      ))}
    </MuiBox>
  )
}

import { useCallback, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FlexColumn } from '../../components/atoms/layout/Flex'
import usePage from '../../hooks/usePage'
import useMoneyspace from '../../hooks/useMoneyspace'
import MoneyspaceSettingView from '../../components/organisms/moneyspace/MoneyspaceSettingView'
import TabView from '../../components/atoms/navigation/TabView'
import useRouter from '../../hooks/useRouter'
import MoneyspaceTabProcessingView from '../../components/organisms/moneyspace/MoneyspaceTabProcessingView'
import MoneyspaceTabPendingView from '../../components/organisms/moneyspace/MoneyspaceTabPendingView'
import MoneyspaceTabCompletedView from '../../components/organisms/moneyspace/MoneyspaceTabCompletedView'
import { MoneyspacesActions } from '../../store/moneyspaces'
import MoneyspaceTabInactiveView from '../../components/organisms/moneyspace/MoneyspaceTabInactiveView'

export default function MoneyspacePage() {
  const { changeTitle } = usePage()
  const { moneyspaceId } = useParams()
  const { find } = useMoneyspace()
  const moneyspace = find(moneyspaceId)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const tab = query.get('tab') ?? 'processing'
  const page = Number(query.get('page') ?? 1)
  const { moneyspace: moneyspacePage } = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    if (moneyspace) {
      changeTitle(moneyspace.name)
    }
  }, [changeTitle, moneyspace])
  useEffect(() => {
    if (moneyspaceId) {
      dispatch(MoneyspacesActions.fetchMembers({ moneyspaceId }))
    }
  }, [dispatch, moneyspaceId])

  const handleChangeTab = useCallback(
    (value: string) => {
      if (moneyspaceId) {
        moneyspacePage(moneyspaceId, value, 1)
      }
    },
    [moneyspaceId, moneyspacePage]
  )

  if (moneyspaceId === undefined || moneyspace === undefined) {
    return <></>
  }
  return (
    <FlexColumn>
      <MoneyspaceSettingView moneyspaceId={moneyspaceId} />
      <TabView
        value={tab}
        tabs={[
          {
            value: 'processing',
            label: '未処理・仕掛中',
            panel: <MoneyspaceTabProcessingView moneyspaceId={moneyspaceId} page={page} />,
          },
          {
            value: 'pending',
            label: '請求中',
            panel: <MoneyspaceTabPendingView moneyspaceId={moneyspaceId} />,
          },
          {
            value: 'completed',
            label: '取引完了',
            panel: <MoneyspaceTabCompletedView moneyspaceId={moneyspaceId} />,
          },
          {
            value: 'inactive',
            label: '中止済取引',
            panel: <MoneyspaceTabInactiveView />,
          },
        ]}
        onChange={handleChangeTab}
      />
    </FlexColumn>
  )
}

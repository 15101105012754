import Backdrop from '../../atoms/feedback/Backdrop'
import CircularProgress from '../../atoms/feedback/CircularProgress'
import Label from '../../atoms/display/Label'
import { FlexColumn } from '../../atoms/layout/Flex'

type LoadingLayerProps = {
  open: boolean
  message?: string
}

export default function LoadingLayer({ open, message }: LoadingLayerProps) {
  return (
    <Backdrop open={open}>
      <FlexColumn align="center" pa={4}>
        <CircularProgress size="large" />
        {message && <Label text={message} color="white.main" variant="subtitle" />}
      </FlexColumn>
    </Backdrop>
  )
}

import useConsts from '../../../hooks/useConsts'
import Label from '../../atoms/display/Label'

type TaxCategoryLabelProps = {
  category: 1 | 2 | 3
}

export default function TaxCategoryLabel({ category }: TaxCategoryLabelProps) {
  const { taxCategories } = useConsts()
  const taxCategory = taxCategories[category]
  return <Label text={taxCategory} />
}

import React from 'react'
import { convertMembershipFee, InvoiceLessPatternB, Moneyspace } from '../../../types/Moneyspace'
import Dialog from '../../atoms/feedback/Dialog'
import { useMoneyspaceViewDialog } from '../../../hooks/useDialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import MembershipFeeView from '../../molecules/display/MembershipFeeView'
import useConsts from '../../../hooks/useConsts'
import Alert from '../../atoms/feedback/Alert'

type MoneyspaceViewDialogProps = {
  moneyspace: Moneyspace
}

export default function MoneyspaceViewDialog({ moneyspace }: MoneyspaceViewDialogProps) {
  const { state, close } = useMoneyspaceViewDialog()
  const membershipFee = convertMembershipFee(moneyspace.membershipFee)
  const { monthName } = useConsts()

  return (
    <Dialog open={state !== undefined} onClose={close} title="マネースペース詳細">
      <FlexColumn space={4}>
        <FlexColumn>
          <Label text="協力会費" variant="subtitle" />
          {moneyspace.invoiceLessActionType === InvoiceLessPatternB && (
            <Alert
              severity="warning"
              title="インボイス対応で「取引先の消費税の請求を不可とする」を選択した場合"
              label="受注者が事業者登録番号未登録の場合は、「税抜き」請求金額に対して協力会費の計算がされます"
            />
          )}
          <MembershipFeeView membershipFee={membershipFee} />
        </FlexColumn>
        <FlexColumn>
          <Label text="取引の承認期限" variant="subtitle" />
          <FlexRow wrap={false}>
            <Label text="取引のおまとめ機能" />
            <Label text={moneyspace.aggregateTransactions ? '有効' : '無効'} />
          </FlexRow>
          {moneyspace.aggregateTransactions && (
            <FlexRow wrap>
              <Label text="取引の承認期限" />
              <FlexRow space={0}>
                <Label text="締め日の" />
                {moneyspace.fixMonth && <Label text={monthName(moneyspace.fixMonth)} />}
                {moneyspace.fixDay && <Label text={moneyspace.fixDay === 99 ? '末日' : `${moneyspace.fixDay}日`} />}
              </FlexRow>
            </FlexRow>
          )}
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

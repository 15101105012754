import { useDispatch } from 'react-redux'
import { useCallback, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMessageCreateDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexRow } from '../../atoms/layout/Flex'
import useDay from '../../../hooks/useDay'
import useScheduleExpression from '../../../hooks/useScheduleExpression'
import { ReservedMessageRepeatType } from '../../../types/Chat'
import { ChatActions } from '../../../store/chat'
import MessageForm, { FormParams } from './MessageForm'
import MoneyspaceSelect from './MoneyspaceSelect'

export default function MessageCreateDialog() {
  const { state, close } = useMessageCreateDialog()
  const [checkedMoneyspaceIds, setCheckedMoneyspaceIds] = useState<string[]>([])
  const { formatDate } = useDay()
  const form = useForm<FormParams>({
    defaultValues: {
      type: 1,
      date: formatDate(new Date()),
      time: new Date(),
      repeatType: '1',
      repeatDayOfWeek: 1,
      repeatDayOfMonth: 1,
      repeatTime: new Date(),
      repeatBaseDate: formatDate(new Date()),
      message: '',
    },
  })
  const { generate } = useScheduleExpression()
  const { formatTime } = useDay()
  const dispatch = useDispatch()

  const handleSend = useCallback(
    (params: FormParams) => {
      if (Number(params.type) === 1) {
        dispatch(
          ChatActions.postMessages({
            moneyspaceIds: checkedMoneyspaceIds,
            description: params.message,
          })
        )
      } else {
        const schedule = generate({
          type: Number(params.type) === 2 ? 'schedule' : 'repeat',
          date: params.date ?? '',
          time: params.time ? formatTime(params.time) : '',
          repeatType: (Number(params.repeatType) ?? 1) as ReservedMessageRepeatType,
          repeatTime: params.repeatTime ? formatTime(params.repeatTime) : '',
          repeatDayOfWeek: Number(params.repeatDayOfWeek ?? 1),
          repeatDayOfMonth: Number(params.repeatDayOfMonth ?? 1),
          repeatBaseDate: params.repeatBaseDate ?? '',
        })
        dispatch(
          ChatActions.postReservedMessage({
            moneyspaceIds: checkedMoneyspaceIds,
            description: params.message,
            scheduleExpression: schedule,
          })
        )
      }
    },
    [formatTime, generate, dispatch, checkedMoneyspaceIds]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="新規メッセージ"
      width="lg"
      onSubmit={form.handleSubmit(handleSend)}
    >
      <FormProvider {...form}>
        <FlexRow wrap>
          <MoneyspaceSelect moneyspaceIds={checkedMoneyspaceIds} onChangeMoneyspaceIds={setCheckedMoneyspaceIds} />
          <MessageForm moneyspaceIds={checkedMoneyspaceIds} />
        </FlexRow>
      </FormProvider>
    </Dialog>
  )
}

import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import useConsts from '../../../hooks/useConsts'
import useDay from '../../../hooks/useDay'
import useRouter from '../../../hooks/useRouter'
import Label from '../../atoms/display/Label'
import { useDeviceType } from '../../../hooks/useDeviceType'
import { FlexColumn } from '../../atoms/layout/Flex'
import {
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionSearchResultItem,
} from '../../../types/transaction'
import TransactionStatusLabel from './TransactionStatusLabel'
import TransactionAmountView from './TransactionAmountView'
import TransactionFavoriteButton from '../../organisms/transaction/TransactionFavoriteButton'

type TransactionTableProps = {
  items: TransactionSearchResultItem[]
}

function TransactionPhaseRow({ item, pc }: { item: TransactionSearchResultItem; pc: boolean }) {
  const { phaseName } = useConsts()
  const { formatDate } = useDay()
  const { transaction: transactionPage } = useRouter()
  const { fromNow } = useDay()
  const { moneyspace } = item
  return (
    <TableRow onClick={() => transactionPage(item.id)}>
      <TableCell size="xs3">
        <TransactionFavoriteButton transaction={item} />
      </TableCell>
      {pc ? (
        <>
          <TableCell size="sm">{moneyspace.name}</TableCell>
          <TableCell size="sm">{item.name}</TableCell>
        </>
      ) : (
        <TableCell size="sm">
          <FlexColumn>
            <Label text={moneyspace.name} />
            <Label text={item.name} />
          </FlexColumn>
        </TableCell>
      )}
      <TableCell size="xs" align="center">
        {phaseName(item.currentPhase)}
      </TableCell>
      <TableCell size="xs" align="center">
        <TransactionStatusLabel transaction={item} />
      </TableCell>
      <TableCell size="sm" align="right">
        <TransactionAmountView transaction={item} />
      </TableCell>
      <TableCell size="xs" align="center">
        {[TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(item.currentPhase) ? (
          <Label text={item.paymentDate ? formatDate(item.paymentDate) : '-'} />
        ) : (
          <Label text="-" />
        )}
      </TableCell>
      <TableCell size="xs" align="center">
        {[TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(item.currentPhase) ? (
          <Label text={item.closingDate ? formatDate(item.closingDate) : ''} />
        ) : (
          <Label text="-" />
        )}
      </TableCell>
      <TableCell size="xs">
        <Label text={item.moneyspace.contractee?.name ?? ''} />
      </TableCell>
      <TableCell size="xs">
        <Label text={item.moneyspace.contractor?.name ?? ''} />
      </TableCell>
      <TableCell size="xs" align="center">
        {fromNow(item.updatedAt)}
      </TableCell>
    </TableRow>
  )
}

export default function TransactionPhaseSearchResultTable({ items }: TransactionTableProps) {
  const { pc } = useDeviceType()
  return (
    <Table
      header={
        <TableRow>
          <TableCell size="xs3" />
          {pc ? (
            <>
              <TableCell header size="sm">
                マネースペース
              </TableCell>
              <TableCell header size="sm">
                取引名
              </TableCell>
            </>
          ) : (
            <TableCell header size="sm">
              マネースペース/取引名
            </TableCell>
          )}
          <TableCell header size="xs">
            フェーズ
          </TableCell>
          <TableCell header size="xs">
            ステータス
          </TableCell>
          <TableCell header size="sm">
            金額
          </TableCell>
          <TableCell header size="xs">
            支払期限
          </TableCell>
          <TableCell header size="xs">
            締め日
          </TableCell>
          <TableCell header size="xs">
            発注者
          </TableCell>
          <TableCell header size="xs">
            受注者
          </TableCell>
          <TableCell header size="xs">
            最終更新
          </TableCell>
        </TableRow>
      }
    >
      {items.map((item) => (
        <TransactionPhaseRow key={item.id} item={item} pc={pc} />
      ))}
    </Table>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import usePage from '../../hooks/usePage'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import SearchField from '../../components/molecules/inputs/SearchField'
import Button from '../../components/atoms/inputs/Button'
import UserTable from '../../components/organisms/user/UserTable'
import { User } from '../../types/User'
import { useUserCreateDialog } from '../../hooks/useDialog'
import { CompanySelectors } from '../../store/company/selectors'
import usePermission from '../../hooks/usePermission'
import { SessionSelectors } from '../../store/session/selectors'

export default function UsersPage() {
  const { userPermissions } = usePermission()
  const user = useSelector(SessionSelectors.user)
  const { changeTitle } = usePage()
  const users = useSelector(CompanySelectors.users)
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users)
  const userCreateDialog = useUserCreateDialog()
  useEffect(() => {
    changeTitle('ユーザー管理')
  }, [changeTitle])
  useEffect(() => {
    setFilteredUsers(users)
  }, [users, setFilteredUsers])
  const handleSearch = useCallback(
    (keyword?: string) => {
      if (keyword) {
        setFilteredUsers(users.filter((u) => u.name.includes(keyword)))
      } else {
        setFilteredUsers(users)
      }
    },
    [users, setFilteredUsers]
  )
  const handleCreate = useCallback(() => {
    userCreateDialog.open()
  }, [userCreateDialog])

  return (
    <FlexColumn>
      <FlexRow justify="space-between">
        <Button onClick={handleCreate} disabled={!userPermissions.create(user)}>
          新規作成
        </Button>
        <SearchField placeholder="ユーザー名で検索" onChange={handleSearch} />
      </FlexRow>
      <UserTable users={filteredUsers} />
    </FlexColumn>
  )
}

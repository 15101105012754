import MuiTableCell from '@mui/material/TableCell'
import { grey } from '@mui/material/colors'
import { Component } from '../../../types/react'
import useColor, { ColorName } from '../../../hooks/useColor'

type CellAlign = 'left' | 'center' | 'right'
export type CellSize = 'xxs' | 'xs' | 'xs2' | 'xs3' | 'sm' | 'sm2' | 'sm3' | 'md' | 'lg'

type TableCellProps = {
  align?: CellAlign
  cols?: number
  px?: number
  py?: number
  size?: CellSize
  color?: ColorName
  header?: boolean
  noBorder?: boolean
  rightBorder?: boolean
  children?: Component
  onClick?: () => void
}

function width(size: CellSize) {
  switch (size) {
    case 'xxs':
      return 32
    case 'xs':
      return 120
    case 'xs2':
      return 80
    case 'xs3':
      return 64
    case 'sm':
      return 240
    case 'sm2':
      return 200
    case 'sm3':
      return 160
    case 'md':
      return 320
    case 'lg':
      return 480
    default:
      return 320
  }
}

export default function TableCell({
  align,
  cols,
  px,
  py,
  size = 'md',
  color,
  header,
  noBorder,
  rightBorder,
  children,
  onClick,
}: TableCellProps) {
  const clickable = onClick !== undefined
  const { nameToColor } = useColor()
  if (header) {
    align = 'center'
  }
  return (
    <MuiTableCell
      align={align}
      colSpan={cols}
      sx={{
        px,
        py,
        minWidth: width(size),
        maxWidth: width(size),
        overflowWrap: 'break-word',
        cursor: clickable ? 'pointer' : undefined,
        '&:hover': clickable ? { backgroundColor: grey[100] } : undefined,
        backgroundColor: nameToColor(color).background,
        color: nameToColor(color).foreground,
        borderBottom: noBorder ? 'none' : undefined,
        borderRight: rightBorder ? '1px #ccc solid' : undefined,
      }}
      onClick={onClick}
    >
      {children}
    </MuiTableCell>
  )
}

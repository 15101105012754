import { Control, FieldError, FieldPath, FieldValues } from 'react-hook-form'
import SelectControl from '../../atoms/inputs/SelectControl'

type DayFieldControlProps<T extends FieldValues> = {
  control: Control<T>
  name: FieldPath<T>
  error?: FieldError
  disabled?: boolean
}

export default function DayFieldControl<T extends FieldValues>({
  control,
  name,
  error,
  disabled,
}: DayFieldControlProps<T>) {
  // return <TextField register={register} placeholder="31" size="xs" error={error?.message} />
  const items: { value: number; label: string }[] = []
  for (let day = 1; day < 28; day += 1) {
    items.push({
      value: day,
      label: `${day}日`,
    })
  }
  items.push({
    value: 99,
    label: '末日',
  })
  return (
    <SelectControl control={control} name={name} error={error?.message} items={items} size="xs" disabled={disabled} />
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type CompanyNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function CompanyNameField({ register, error, disabled }: CompanyNameFieldProps) {
  return (
    <TextField register={register} placeholder="株式会社monect" size="sm" error={error?.message} readonly={disabled} />
  )
}

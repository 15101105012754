import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type BankAccountNumberFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function BankAccountNumberField({ register, error, disabled }: BankAccountNumberFieldProps) {
  return <TextField register={register} placeholder="0123456" size="xs" error={error?.message} readonly={disabled} />
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FieldError } from 'react-hook-form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import FullNameField from '../../molecules/inputs/FullNameField'
import EmailField from '../../molecules/inputs/EmailField'
import Button from '../../atoms/inputs/Button'
import { useEmailChangeDialog, usePasswordChangeDialog } from '../../../hooks/useDialog'
import { User } from '../../../types/User'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import Form from '../../atoms/inputs/Form'
import { SessionActions } from '../../../store/session'
import useProcessing from '../../../hooks/useProcessing'

type ProfileFormProps = {
  user: User
}

type ChangeProfileParams = {
  name: string
}

export default function ProfileForm({ user }: ProfileFormProps) {
  const { processing, startProcess } = useProcessing(SessionActions.updateMe.typePrefix)
  const passwordChangeDialog = usePasswordChangeDialog()
  const emailChangeDialog = useEmailChangeDialog()
  const handleChangePassword = useCallback(() => {
    passwordChangeDialog.open(user)
  }, [user, passwordChangeDialog])
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.userName,
  })
  const { handleSubmit, register, errors } = useForm<ChangeProfileParams>(schema, user)
  const dispatch = useDispatch()
  const handleChangeProfile = useCallback(
    (values: ChangeProfileParams) => {
      startProcess()
      dispatch(SessionActions.updateMe({ user: { ...user, name: values.name } }))
    },
    [dispatch, startProcess, user]
  )
  return (
    <FlexColumn>
      <Form onSubmit={handleSubmit(handleChangeProfile)}>
        <FormItem label="ユーザー名">
          <FlexRow align="baseline" wrap>
            <FullNameField register={register('name')} error={errors.name as FieldError} />
            <Button type="submit" size="sm" disabled={processing}>
              更新
            </Button>
          </FlexRow>
        </FormItem>
      </Form>
      <FormItem label="メールアドレス">
        <FlexRow align="baseline" wrap>
          <EmailField value={user.email} readonly />
          <Button onClick={() => emailChangeDialog.open(user.email)} size="sm">
            変更
          </Button>
        </FlexRow>
      </FormItem>
      <FlexRow>
        <Button onClick={handleChangePassword}>パスワード変更</Button>
      </FlexRow>
    </FlexColumn>
  )
}

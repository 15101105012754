import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMoneyspaceGroupCreateDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import Button from '../../atoms/inputs/Button'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import MoneyspaceGroupForm from './MoneyspaceGroupForm'
import useProcessing from '../../../hooks/useProcessing'
import { FlexColumn } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { MoneyspaceFolder } from '../../../types/Moneyspace'

type MoneyspaceGroupCreateProps = {
  parentFolder: MoneyspaceFolder | null
}

type MoneyspaceGroupCreateParams = {
  name: string
}

export default function MoneyspaceGroupCreateDialog({ parentFolder }: MoneyspaceGroupCreateProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.createMoneyspaceFolder.typePrefix)
  const { state, close } = useMoneyspaceGroupCreateDialog()
  const yup = useYup()
  const dispatch = useDispatch()
  const schema = yup.object().shape({})
  const { handleSubmit, register, errors } = useForm<MoneyspaceGroupCreateParams>(schema, {})
  const handleCreate = useCallback(
    (values: MoneyspaceGroupCreateParams) => {
      startProcess()
      dispatch(
        MoneyspacesActions.createMoneyspaceFolder({
          name: values.name,
          parentFolderId: parentFolder ? parentFolder.id : null,
        })
      )
    },
    [dispatch, parentFolder, startProcess]
  )
  return (
    <Dialog
      open={state !== undefined}
      title="フォルダを作成する"
      actions={
        <Button type="submit" disabled={processing}>
          作成
        </Button>
      }
      onSubmit={handleSubmit(handleCreate)}
      onClose={close}
    >
      <FlexColumn>
        {parentFolder && <Label text={`「${parentFolder.name}」にフォルダを作成します`} />}
        <MoneyspaceGroupForm register={register} errors={errors} />
      </FlexColumn>
    </Dialog>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import CompanyNameField from '../../molecules/inputs/CompanyNameField'
import OfficialPositionField from '../../molecules/inputs/OfficialPositionField'
import FullNameField from '../../molecules/inputs/FullNameField'
import PostalCodeField from '../../molecules/inputs/PostalCodeField'
import AddressField from '../../molecules/inputs/AddressField'
import Form from '../../atoms/inputs/Form'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { CompanyActions } from '../../../store/company'
import useProcessing from '../../../hooks/useProcessing'
import { CompanySelectors } from '../../../store/company/selectors'
import { SessionSelectors } from '../../../store/session/selectors'
import { CompanyBasic } from '../../../types/Company'
import Alert from '../../atoms/feedback/Alert'
import Button from '../../atoms/inputs/Button'
import InvoiceLicenseNumberForm from './InvoiceLicenseNumberForm'
import FiscalPeriodSelect from '../../molecules/inputs/FiscalPeriodSelect'
import usePermission from '../../../hooks/usePermission'
import TextField from '../../atoms/inputs/TextField'

function disabledSave(
  processing: boolean,
  invoiceLicenseCompanyName: string | null | undefined,
  companyName?: string,
  representativeName?: string
) {
  if (processing) {
    return true
  }
  if (invoiceLicenseCompanyName === null) {
    return true
  }
  return (
    invoiceLicenseCompanyName !== undefined &&
    invoiceLicenseCompanyName !== companyName &&
    invoiceLicenseCompanyName !== representativeName
  )
}

export default function CompanyTabItemBasic() {
  const { processing, startProcess } = useProcessing(CompanyActions.saveCompany.typePrefix)
  const company = useSelector(CompanySelectors.company)
  const user = useSelector(SessionSelectors.user)
  const [companyName, setCompanyName] = useState(company?.name)
  const [representativeName, setRepresentativeName] = useState(company?.name)
  const invoiceLicenseCompanyName = useSelector(CompanySelectors.invoiceLicenseCompanyName)
  const { companyPermissions } = usePermission()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.companyName.required(),
    representativeName: rules.representativeName,
    representativeRole: rules.representativeRole,
    zipCode: rules.zipCode,
    address: rules.address,
    invoiceLicenseNumber: rules.invoiceLicenseNumber,
    phoneNumber: rules.phoneNumber,
    phoneNumberMobile: rules.phoneNumberMobile,
  })
  const { handleSubmit, register, control, errors, getValues, setValue, watch } = useForm<CompanyBasic>(schema, {
    ...company,
    invoiceLicenseNumber: company?.invoiceLicenseNumber?.substring(1),
  })
  useEffect(() => {
    watch((params, { name }) => {
      if (name === 'name') {
        setCompanyName(params.name)
      }
      if (name === 'representativeName') {
        setRepresentativeName(params.representativeName)
      }
    })
  }, [watch])
  useEffect(() => {
    watch((params, { name }) => {
      if (name === 'name') {
        setCompanyName(params.name)
      }
    })
  }, [watch])
  const handleSearchAddress = useCallback(
    (address: string) => {
      setValue('address', address)
    },
    [setValue]
  )
  const handleSave = useCallback(
    (values: CompanyBasic) => {
      if (company) {
        startProcess()
        const invoiceLicenseNumber = values.invoiceLicenseNumber ? `T${values.invoiceLicenseNumber}` : null
        dispatch(
          CompanyActions.saveCompany({
            id: company.id,
            company: {
              ...company,
              ...values,
              invoiceLicenseNumber,
            },
          })
        )
      }
    },
    [company, startProcess, dispatch]
  )
  const disabled = !companyPermissions.edit(user)
  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FlexColumn>
        <FlexRow wrap>
          <FormItem label="法人名 / 個人事業主名" required>
            <CompanyNameField register={register('name')} error={errors.name} disabled={disabled} />
          </FormItem>
          <FormItem label="代表者役職">
            <OfficialPositionField
              register={register('representativeRole')}
              error={errors.representativeRole}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="代表者氏名">
            <FullNameField
              register={register('representativeName')}
              error={errors.representativeName}
              disabled={disabled}
            />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <FormItem label="郵便番号">
            <PostalCodeField
              register={register('zipCode')}
              error={errors.zipCode}
              getValues={getValues}
              disabled={disabled}
              onSearchAddress={handleSearchAddress}
            />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <FormItem label="住所">
            <AddressField register={register('address')} error={errors.address} disabled={disabled} />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <FormItem label="電話番号（会社）">
            <TextField register={register('phoneNumber')} error={errors.phoneNumber?.message} readonly={disabled} />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <FormItem label="電話番号（携帯）">
            <TextField
              register={register('phoneNumberMobile')}
              error={errors.phoneNumberMobile?.message}
              readonly={disabled}
            />
          </FormItem>
        </FlexRow>
        <InvoiceLicenseNumberForm register={register} errors={errors} getValues={getValues} disabled={disabled} />
        <FiscalPeriodSelect control={control} name="fiscalPeriod" disabled={disabled} />
        <FlexColumn>
          <FlexRow>
            <Alert severity="warning" label="企業情報を保存すると下書き中の取引に情報が反映されます" />
          </FlexRow>
          <Button
            type="submit"
            disabled={
              !companyPermissions.edit(user) ||
              disabledSave(processing, invoiceLicenseCompanyName, companyName, representativeName)
            }
          >
            保存
          </Button>
        </FlexColumn>
      </FlexColumn>
    </Form>
  )
}

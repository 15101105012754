import MuiRadioGroup from '@mui/material/RadioGroup'
import MuiRadio from '@mui/material/Radio'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { Control, Controller } from 'react-hook-form'
import { ReactElement, useCallback } from 'react'

type RadioItem = {
  value: number | boolean | string
  label: string | ReactElement
}

type RadioGroupProps = {
  value?: number | boolean | string
  items: RadioItem[]
  row?: boolean
  name?: string
  control?: Control
  disabled?: boolean
  onChange?: (value: number | boolean | string) => void
}

export default function RadioGroup({ value, items, row = false, name, control, disabled, onChange }: RadioGroupProps) {
  const handleChange = useCallback(
    (newValue: number | boolean | string) => {
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange]
  )
  if (name && control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          <MuiRadioGroup defaultValue={value} row={row} {...field} value={field.value ?? ''}>
            {items.map((item) => (
              <MuiFormControlLabel
                key={String(item.value)}
                control={<MuiRadio />}
                value={item.value}
                label={item.label}
                disabled={disabled}
              />
            ))}
          </MuiRadioGroup>
        )}
      />
    )
  }
  return (
    <MuiRadioGroup value={value} row={row} onChange={(e) => handleChange(e.target.value)}>
      {items.map((item) => (
        <MuiFormControlLabel
          key={String(item.value)}
          control={<MuiRadio />}
          value={item.value}
          label={item.label}
          disabled={disabled}
        />
      ))}
    </MuiRadioGroup>
  )
}

import { DocumentStatus, DocumentType } from '../types/Document'
import {
  TransactionPhase,
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionPhaseDelivered,
  TransactionPhaseEFQ,
  TransactionPhaseOrder,
  TransactionPhaseOrderConfirm,
  TransactionPhaseQuotation,
  TransactionStatus,
  TransactionStatusCanceled,
  TransactionStatusClosed,
  TransactionStatusDraft,
  TransactionStatusReview,
  TransactionStatusReviewed,
} from '../types/transaction'

type TransactionPhaseConst = {
  id: TransactionPhase
  name: string
}

const transactionPhases: TransactionPhaseConst[] = [
  { id: TransactionPhaseEFQ, name: '見積依頼' },
  { id: TransactionPhaseQuotation, name: '見積' },
  { id: TransactionPhaseOrder, name: '発注' },
  { id: TransactionPhaseOrderConfirm, name: '発注請' },
  { id: TransactionPhaseDelivered, name: '完納' },
  { id: TransactionPhaseBillingDetail, name: '支払明細' },
  { id: TransactionPhaseBilling, name: '請求' },
]

type DocumentTypeConst = {
  id: DocumentType
  name: string
}

const documentTypes: DocumentTypeConst[] = [
  { id: 1, name: '見積依頼' },
  { id: 2, name: '見積' },
  { id: 3, name: '発注' },
  { id: 4, name: '発注請' },
  { id: 5, name: '完納' },
  { id: 6, name: '支払明細' },
  { id: 7, name: '請求' },
  { id: 8, name: '領収' },
]

type TransactionStatusConst = {
  id: TransactionStatus
  name: string
}
const transactionStatusConsts: TransactionStatusConst[] = [
  { id: TransactionStatusDraft, name: '下書き' },
  { id: TransactionStatusReview, name: '自社確認中' },
  { id: TransactionStatusReviewed, name: '取引先確認中' },
  { id: TransactionStatusClosed, name: '完了' },
  { id: TransactionStatusCanceled, name: '取引中止' },
]

type DocumentStatusConst = {
  id: DocumentStatus
  name: string
}
const documentStatusConsts: DocumentStatusConst[] = [
  { id: 1, name: '下書き' },
  { id: 2, name: '自社確認中' },
  { id: 3, name: '取引先確認中' },
  { id: 4, name: '完了' },
  { id: 5, name: '取引中止' },
]

function convertDocumentTypeList(types?: DocumentType[]): DocumentType[] {
  const documentTypeIds = documentTypes.map((type) => type.id)
  return documentTypeIds.map((typeId) => {
    const id = typeId as unknown as number
    if (types) {
      return types.includes(typeId) ? id : undefined
    }
    return undefined
  }) as unknown as DocumentType[]
}

export default function useConsts() {
  const transactionActivityActionNames = {
    1: 'フェーズを開始',
    2: 'フェーズを更新',
    3: '確認申請',
    4: '取引先に提出',
    5: '承認',
    6: '却下',
    7: 'フェーズ完了',
    8: '取引中止',
    9: '分割請求',
  }
  const taxCategories = {
    1: '10%',
    2: '軽減8%',
    3: '非課税',
  }
  const paymentMonth = {
    1: '当月',
    2: '翌月',
    3: '翌々月',
    4: '翌々々月',
  }
  const monthName = (value: number) => {
    switch (value) {
      case 1:
        return '当月'
      case 2:
        return '翌月'
      case 3:
        return '翌々月'
      case 4:
        return '翌々々月'
      default:
        return ''
    }
  }
  const siteBaseUrl = process.env.REACT_APP_SITE_BASE_URL
  return {
    documentTypes,
    documentTypeIds: documentTypes.map((type) => type.id),
    convertDocumentTypeList,
    documentTypeName: (type: DocumentType) =>
      documentTypes.find((documentType) => documentType.id === type)?.name ?? '不明',
    documentStatus: documentStatusConsts,
    documentStatusName: (status: DocumentStatus) =>
      documentStatusConsts.find((documentStatus) => documentStatus.id === status)?.name ?? '不明',
    transactionActivityActionNames,
    transactionPhases,
    phaseName: (phase: TransactionPhase) => transactionPhases.find((item) => item.id === phase)?.name ?? '不明',
    transactionStatus: transactionStatusConsts,
    transactionStatusName: (status: TransactionStatus) =>
      transactionStatusConsts.find((item) => item.id === status)?.name ?? '不明',
    taxCategories,
    paymentMonth,
    monthName,
    documentItemMax: 40,
    siteBaseUrl,
  }
}

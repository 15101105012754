import StepView from '../../atoms/display/StepView'
import Avatar from '../../atoms/display/Avatar'
import { ApprovalFlowMember } from '../../../types/ApprovalFlow'
import { AccountsIcon } from '../../atoms/display/Icons'

type AvatarStepperProps = {
  members: ApprovalFlowMember[]
  activeStep?: number
}

export default function AvatarStepper({ members, activeStep }: AvatarStepperProps) {
  return (
    <StepView
      steps={members.map((member, index) => ({
        id: member.user?.id ?? 'person',
        icon: member.user ? <Avatar alt={member.user.name} url={member.user?.icon} /> : <AccountsIcon size={40} />,
        label: member.user?.name ?? '担当者',
        badge: index === activeStep,
      }))}
      activeStep={activeStep}
    />
  )
}

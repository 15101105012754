import UserCreateDialog from '../organisms/user/UserCreateDialog'
import UserEditDialog from '../organisms/user/UserEditDialog'
import UserDeleteDialog from '../organisms/user/UserDeleteDialog'
import ClientCreateDialog from '../organisms/client/ClientCreateDialog'
import ClientDeleteDialog from '../organisms/client/ClientDeleteDialog'
import TagEditDialog from '../organisms/document/TagEditDialog'
import TagDeleteDialog from '../organisms/document/TagDeleteDialog'
import PasswordChangeDialog from '../organisms/user/PasswordChangeDialog'
import UserImageSettingDialog from '../organisms/user/UserImageSettingDialog'
import {
  useClientCreateDialog,
  useClientDeleteDialog,
  useDocumentDeleteDialog,
  useEmailChangeDialog,
  useMoneyspaceCreateDialog,
  useMoneyspaceDeleteDialog,
  useMoneyspaceEditDialog,
  useMoneyspaceGroupCreateDialog,
  useMoneyspaceGroupDeleteDialog,
  useMoneyspaceGroupEditDialog,
  useTagDeleteDialog,
  useTagEditDialog,
  useTransactionCreateDialog,
  useUserCreateDialog,
  useUserDeleteDialog,
  useUserEditDialog,
  useContractEditDialog,
  useContractDeleteDialog,
  useDocumentApplyDialog,
  useDocumentApproveDialog,
  useDocumentRejectDialog,
  useAttachmentDeleteDialog,
  useContractCopyDialog,
  useMoneyspaceMoveDialog,
  useMoneyspaceMembersSettingDialog,
  useApprovalFlowSettingDialog,
  useApprovalFlowDeleteDialog,
  useDocumentApprovalFlowSettingDialog,
  useMoneyspaceClientEditDialog,
  useDocumentCsvImportItemPreviewDialog,
  useClientAcceptDialog,
  useMoneyspaceFolderMoveDialog,
  useDocumentBulkApproveDialog,
  useDocumentDiffDialog,
  useMoneyspaceViewDialog,
  useDocumentSearchConditionListDialog,
  useDocumentSearchConditionFormDialog,
  useDocumentSearchConditionDeleteDialog,
  useAttachmentsViewDialog,
  useTransactionClientViewDialog,
  useTransactionCancelDialog,
  useTransactionApplyDialog,
  useTransactionApproveDialog,
  useTransactionApprovalFlowSettingDialog,
  useTransactionRejectDialog,
  useTransactionPhaseDiffDialog,
  useTransactionSearchConditionListDialog,
  useTransactionSearchConditionFormDialog,
  useTransactionSearchConditionDeleteDialog,
  useTransactionBulkApproveDialog,
  useTransactionCopyDialog,
  useTransactionReleaseDialog,
  useBillingPaidDialog,
  useTransactionCsvImportItemPreviewDialog,
  useTransactionPdfPreviewDialog,
  useBillingPdfPreviewDialog,
  useBillingBulkPaidDialog,
  useTransactionDeleteDialog,
  useTransactionSubmitDialog,
  useTransactionsApprovalFlowSettingDialog,
  useMessageCreateDialog,
  useReservedMessageDialog,
  useMessageEditDialog,
  useMessageDeleteDialog,
} from '../../hooks/useDialog'
import EmailChangeDialog from '../organisms/user/EmailChangeDialog'
import DocumentDeleteDialog from '../organisms/document/DocumentDeleteDialog'
import MoneyspaceCreateDialog from '../organisms/moneyspace/MoneyspaceCreateDialog'
import MoneyspaceDeleteDialog from '../organisms/moneyspace/MoneyspaceDeleteDialog'
import MoneyspaceEditDialog from '../organisms/moneyspace/MoneyspaceEditDialog'
import TransactionCreateDialog from '../organisms/transaction/TransactionCreateDialog'
import MoneyspaceGroupCreateDialog from '../organisms/moneyspace/MoneyspaceGroupCreateDialog'
import MoneyspaceGroupEditDialog from '../organisms/moneyspace/MoneyspaceGroupEditDialog'
import MoneyspaceGroupDeleteDialog from '../organisms/moneyspace/MoneyspaceGroupDeleteDialog'
import ContractEditDialog from '../organisms/document/ContractEditDialog'
import ContractDeleteDialog from '../organisms/document/ContractDeleteDialog'
import DocumentApplyDialog from '../organisms/document/DocumentApplyDialog'
import DocumentApproveDialog from '../organisms/document/DocumentApproveDialog'
import DocumentRejectDialog from '../organisms/document/DocumentRejectDialog'
import AttachmentDeleteDialog from '../organisms/document/AttachmentDeleteDialog'
import ContractCopyDialog from '../organisms/document/ContractCopyDialog'
import MoneyspaceMoveDialog from '../organisms/moneyspace/MoneyspaceMoveDialog'
import MoneyspaceMembersSettingDialog from '../organisms/moneyspace/MoneyspaceMembersSettingDialog'
import ApprovalFlowSettingDialog from '../organisms/approval_flows/ApprovalFlowSettingDialog'
import ApprovalFlowDeleteDialog from '../organisms/approval_flows/ApprovalFlowDeleteDialog'
import DocumentApprovalFlowSettingDialog from '../organisms/document/DocumentApprovalFlowSettingDialog'
import MoneyspaceClientEditDialog from '../organisms/moneyspace/MoneyspaceClientEditDialog'
import DocumentCsvImportItemPreviewDialog from '../organisms/document/DocumentCsvImportItemPreviewDialog'
import ClientAcceptDialog from '../organisms/client/ClientAcceptDialog'
import MoneyspaceFolderMoveDialog from '../organisms/moneyspace/MoneyspaceGroupMoveDialog'
import DocumentBulkApproveDialog from '../organisms/document/DocumentBulkApproveDialog'
import DocumentDiffDialog from '../organisms/document/DocumentDiffDialog'
import MoneyspaceViewDialog from '../organisms/moneyspace/MoneyspaceViewDialog'
import DocumentSearchConditionListDialog from '../organisms/document/DocumentSearchConditionListDialog'
import DocumentSearchConditionFormDialog from '../organisms/document/DocumentSearchConditionFormDialog'
import DocumentSearchConditionDeleteDialog from '../organisms/document/DocumentSearchConditionDeleteDialog'
import AttachmentsViewDialog from '../organisms/document/AttachmentsViewDialog'
import TransactionClientViewDialog from '../organisms/transaction/TransactionClientViewDialog'
import TransactionCancelDialog from '../organisms/transaction/TransactionCancelDialog'
import TransactionApplyDialog from '../organisms/transaction/TransactionApplyDialog'
import TransactionApproveDialog from '../organisms/transaction/TransactionApproveDialog'
import TransactionApprovalFlowSettingDialog from '../organisms/transaction/TransactionApprovalFlowSettingDialog'
import TransactionRejectDialog from '../organisms/transaction/TransactionRejectDialog'
import TransactionPhaseDiffDialog from '../organisms/transaction/TransactionPhaseDiffDialog'
import TransactionSearchConditionFormDialog from '../organisms/transaction/TransactionSearchConditionFormDialog'
import TransactionSearchConditionListDialog from '../organisms/transaction/TransactionSearchConditionListDialog'
import TransactionSearchConditionDeleteDialog from '../organisms/transaction/TransactionSearchConditionDeleteDialog'
import TransactionBulkApproveDialog from '../organisms/transaction/TransactionBulkApproveDialog'
import TransactionCopyDialog from '../organisms/transaction/TransactionCopyDialog'
import TransactionReleaseDialog from '../organisms/transaction/TransactionReleaseDialog'
import BillingPaidDialog from '../organisms/transaction/BillingPaidDialog'
import TransactionCsvImportItemPreviewDialog from '../organisms/transaction/TransactionCsvImportItemPreviewDialog'
import TransactionPdfPreviewDialog from '../organisms/transaction/TransactionPdfPreviewDialog'
import BillingPdfPreviewDialog from '../organisms/transaction/BillingPdfPreviewDialog'
import BillingBulkPaidDialog from '../organisms/transaction/BillingBulkPaidDialog'
import TransactionDeleteDialog from '../organisms/transaction/TransactionDeleteDialog'
import TransactionSubmitDialog from '../organisms/transaction/TransactionSubmitDialog'
import TransactionsApprovalFlowSettingDialog from '../organisms/transaction/TransactionsApprovalFlowSettingDialog'
import MessageCreateDialog from '../organisms/chat/MessageCreateDialog'
import ReservedMessageDialog from '../organisms/chat/ReservedMessageDialog'
import MessageEditDialog from '../organisms/chat/MessageEditDialog'
import MessageDeleteDialog from '../organisms/chat/MessageDeleteDialog'

export default function DialogProvider() {
  const userCreateDialog = useUserCreateDialog()
  const userEditDialog = useUserEditDialog()
  const userDeleteDialog = useUserDeleteDialog()
  const clientCreateDialog = useClientCreateDialog()
  const clientDeleteDialog = useClientDeleteDialog()
  const clientAcceptDialog = useClientAcceptDialog()
  const tagEditDialog = useTagEditDialog()
  const tagDeleteDialog = useTagDeleteDialog()
  const emailChangeDialog = useEmailChangeDialog()
  const moneyspaceGroupCreateDialog = useMoneyspaceGroupCreateDialog()
  const moneyspaceGroupEditDialog = useMoneyspaceGroupEditDialog()
  const moneyspaceFolderMoveDialog = useMoneyspaceFolderMoveDialog()
  const moneyspaceGroupDeleteDialog = useMoneyspaceGroupDeleteDialog()
  const moneyspaceCreateDialog = useMoneyspaceCreateDialog()
  const moneyspaceEditDialog = useMoneyspaceEditDialog()
  const moneyspaceMoveDialog = useMoneyspaceMoveDialog()
  const moneyspaceDeleteDialog = useMoneyspaceDeleteDialog()
  const moneyspaceMembersSettingDialog = useMoneyspaceMembersSettingDialog()
  const moneyspaceClientEditDialog = useMoneyspaceClientEditDialog()
  const moneyspaceViewDialog = useMoneyspaceViewDialog()
  const documentDeleteDialog = useDocumentDeleteDialog()
  const documentApplyDialog = useDocumentApplyDialog()
  const documentApprovalFlowSettingDialog = useDocumentApprovalFlowSettingDialog()
  const documentApproveDialog = useDocumentApproveDialog()
  const documentBulkApproveDialog = useDocumentBulkApproveDialog()
  const documentRejectDialog = useDocumentRejectDialog()
  const documentCsvImportItemPreviewDialog = useDocumentCsvImportItemPreviewDialog()
  const documentSearchConditionListDialog = useDocumentSearchConditionListDialog()
  const documentSearchConditionFormDialog = useDocumentSearchConditionFormDialog()
  const documentSearchConditionDeleteDialog = useDocumentSearchConditionDeleteDialog()
  const documentDiffDialog = useDocumentDiffDialog()
  const contractCreateDialog = useTransactionCreateDialog()
  const contractEditDialog = useContractEditDialog()
  const contractDeleteDialog = useContractDeleteDialog()
  const contractCopyDialog = useContractCopyDialog()
  const attachmentsViewDialog = useAttachmentsViewDialog()
  const attachmentDeleteDialog = useAttachmentDeleteDialog()
  const approvalFlowSettingDialog = useApprovalFlowSettingDialog()
  const approvalFlowDeleteDialog = useApprovalFlowDeleteDialog()
  const transactionClientDialog = useTransactionClientViewDialog()
  const transactionCancelDialog = useTransactionCancelDialog()
  const transactionDeleteDialog = useTransactionDeleteDialog()
  const transactionApplyDialog = useTransactionApplyDialog()
  const transactionSubmitDialog = useTransactionSubmitDialog()
  const transactionApproveDialog = useTransactionApproveDialog()
  const transactionBulkApproveDialog = useTransactionBulkApproveDialog()
  const transactionApprovalFlowSettingDialog = useTransactionApprovalFlowSettingDialog()
  const transactionsApprovalFlowSettingDialog = useTransactionsApprovalFlowSettingDialog()
  const transactionRejectDialog = useTransactionRejectDialog()
  const transactionPhaseDiffDialog = useTransactionPhaseDiffDialog()
  const transactionSearchConditionListDialog = useTransactionSearchConditionListDialog()
  const transactionSearchConditionFormDialog = useTransactionSearchConditionFormDialog()
  const transactionSearchConditionDeleteDialog = useTransactionSearchConditionDeleteDialog()
  const transactionCopyDialog = useTransactionCopyDialog()
  const transactionReleaseDialog = useTransactionReleaseDialog()
  const billingPaidDialog = useBillingPaidDialog()
  const billingBulkPaidDialog = useBillingBulkPaidDialog()
  const transactionCsvImportItemPreviewDialog = useTransactionCsvImportItemPreviewDialog()
  const transactionPdfPreviewDialog = useTransactionPdfPreviewDialog()
  const billingPdfPreviewDialog = useBillingPdfPreviewDialog()
  const messageCreateDialog = useMessageCreateDialog()
  const messageEditDialog = useMessageEditDialog()
  const messageDeleteDialog = useMessageDeleteDialog()
  const reservedMessageDialog = useReservedMessageDialog()
  return (
    <>
      {userCreateDialog.state && <UserCreateDialog />}
      {userEditDialog.state && <UserEditDialog user={userEditDialog.state?.user} />}
      {userDeleteDialog.state && <UserDeleteDialog user={userDeleteDialog.state.user} />}
      {clientCreateDialog.state && <ClientCreateDialog />}
      {clientDeleteDialog.state && <ClientDeleteDialog client={clientDeleteDialog.state.client} />}
      {clientAcceptDialog.state && <ClientAcceptDialog client={clientAcceptDialog.state.client} />}
      {tagEditDialog.state && <TagEditDialog tag={tagEditDialog.state.tag} />}
      {tagDeleteDialog.state && <TagDeleteDialog tag={tagDeleteDialog.state.tag} />}
      <PasswordChangeDialog />
      <UserImageSettingDialog />
      {emailChangeDialog.state && <EmailChangeDialog email={emailChangeDialog.state.email} />}
      {moneyspaceGroupCreateDialog.state && (
        <MoneyspaceGroupCreateDialog parentFolder={moneyspaceGroupCreateDialog.state.parentFolder} />
      )}
      {moneyspaceGroupEditDialog.state && (
        <MoneyspaceGroupEditDialog moneyspaceFolder={moneyspaceGroupEditDialog.state?.moneyspaceFolder} />
      )}
      {moneyspaceFolderMoveDialog.state && (
        <MoneyspaceFolderMoveDialog folder={moneyspaceFolderMoveDialog.state.folder} />
      )}
      {moneyspaceGroupDeleteDialog.state && (
        <MoneyspaceGroupDeleteDialog moneyspaceFolder={moneyspaceGroupDeleteDialog.state.moneyspaceFolder} />
      )}
      {moneyspaceCreateDialog.state && <MoneyspaceCreateDialog folder={moneyspaceCreateDialog.state.folder} />}
      {moneyspaceEditDialog.state && <MoneyspaceEditDialog moneyspace={moneyspaceEditDialog.state?.moneyspace} />}
      {moneyspaceMoveDialog.state && <MoneyspaceMoveDialog moneyspace={moneyspaceMoveDialog.state?.moneyspace} />}
      {moneyspaceDeleteDialog.state && <MoneyspaceDeleteDialog moneyspace={moneyspaceDeleteDialog.state.moneyspace} />}
      {moneyspaceMembersSettingDialog.state && (
        <MoneyspaceMembersSettingDialog moneyspace={moneyspaceMembersSettingDialog.state.moneyspace} />
      )}
      {moneyspaceClientEditDialog.state && (
        <MoneyspaceClientEditDialog moneyspace={moneyspaceClientEditDialog.state.moneyspace} />
      )}
      {moneyspaceViewDialog.state && <MoneyspaceViewDialog moneyspace={moneyspaceViewDialog.state.moneyspace} />}
      <DocumentDeleteDialog document={documentDeleteDialog.state?.document} />
      {documentApplyDialog.state && <DocumentApplyDialog document={documentApplyDialog.state.document} />}
      {documentApprovalFlowSettingDialog.state && (
        <DocumentApprovalFlowSettingDialog document={documentApprovalFlowSettingDialog.state.document} />
      )}
      {documentApproveDialog.state && (
        <DocumentApproveDialog
          moneyspaceId={documentApproveDialog.state.moneyspaceId}
          contract={documentApproveDialog.state.contract}
          document={documentApproveDialog.state.document}
        />
      )}
      {documentBulkApproveDialog.state && (
        <DocumentBulkApproveDialog
          documentIds={documentBulkApproveDialog.state.documentIds}
          page={documentBulkApproveDialog.state.page}
          sortColumn={documentBulkApproveDialog.state.sortColumn}
          sortDirection={documentBulkApproveDialog.state.sortDirection}
        />
      )}
      {documentRejectDialog.state && <DocumentRejectDialog document={documentRejectDialog.state.document} />}
      {documentCsvImportItemPreviewDialog.state && (
        <DocumentCsvImportItemPreviewDialog items={documentCsvImportItemPreviewDialog.state.items} />
      )}
      {documentDiffDialog.state && (
        <DocumentDiffDialog
          moneyspace={documentDiffDialog.state.moneyspace}
          contract={documentDiffDialog.state.contract}
          document={documentDiffDialog.state.document}
          previousDocument={documentDiffDialog.state.previousDocument}
          changeLogs={documentDiffDialog.state.changeLogs}
          approvalFlowState={documentDiffDialog.state.approvalFlowState}
        />
      )}
      {documentSearchConditionListDialog.state && <DocumentSearchConditionListDialog />}
      {documentSearchConditionFormDialog.state && (
        <DocumentSearchConditionFormDialog id={documentSearchConditionFormDialog.state.id} />
      )}
      {documentSearchConditionDeleteDialog.state && (
        <DocumentSearchConditionDeleteDialog id={documentSearchConditionDeleteDialog.state.id} />
      )}
      {contractCreateDialog.state && <TransactionCreateDialog moneyspaceId={contractCreateDialog.state.moneyspaceId} />}
      {contractEditDialog.state && <ContractEditDialog contract={contractEditDialog.state.contract} />}
      {contractDeleteDialog.state && (
        <ContractDeleteDialog
          contract={contractDeleteDialog.state.contract}
          moneyspaceId={contractDeleteDialog.state.moneyspaceId}
        />
      )}
      {contractCopyDialog.state && (
        <ContractCopyDialog
          moneyspace={contractCopyDialog.state.moneyspace}
          contract={contractCopyDialog.state.contract}
          page={contractCopyDialog.state.page}
          excludeClosed={contractCopyDialog.state.excludeClosed}
        />
      )}
      {attachmentsViewDialog.state && (
        <AttachmentsViewDialog
          activeId={attachmentsViewDialog.state.activeId}
          attachments={attachmentsViewDialog.state.attachments}
        />
      )}
      {attachmentDeleteDialog.state && (
        <AttachmentDeleteDialog
          contractId={attachmentDeleteDialog.state.contractId}
          attachment={attachmentDeleteDialog.state.attachment}
        />
      )}
      {approvalFlowSettingDialog.state && <ApprovalFlowSettingDialog flow={approvalFlowSettingDialog.state.flow} />}
      {approvalFlowDeleteDialog.state && <ApprovalFlowDeleteDialog flow={approvalFlowDeleteDialog.state.flow} />}
      {transactionClientDialog.state && (
        <TransactionClientViewDialog
          company={transactionClientDialog.state.company}
          isContractee={transactionClientDialog.state.isContractee}
        />
      )}
      {transactionCancelDialog.state && (
        <TransactionCancelDialog transaction={transactionCancelDialog.state.transaction} />
      )}
      {transactionDeleteDialog.state && (
        <TransactionDeleteDialog transaction={transactionDeleteDialog.state.transaction} />
      )}
      {transactionApplyDialog.state && (
        <TransactionApplyDialog
          transaction={transactionApplyDialog.state.transaction}
          installment={transactionApplyDialog.state.installment}
        />
      )}
      {transactionSubmitDialog.state && (
        <TransactionSubmitDialog transaction={transactionSubmitDialog.state.transaction} />
      )}
      {transactionApproveDialog.state && (
        <TransactionApproveDialog transaction={transactionApproveDialog.state.transaction} />
      )}
      {transactionBulkApproveDialog.state && (
        <TransactionBulkApproveDialog
          transactionIds={transactionBulkApproveDialog.state.transactionIds}
          page={transactionBulkApproveDialog.state.page}
          sortColumn={transactionBulkApproveDialog.state.sortColumn}
          sortDirection={transactionBulkApproveDialog.state.sortDirection}
        />
      )}
      {transactionApprovalFlowSettingDialog.state && (
        <TransactionApprovalFlowSettingDialog transaction={transactionApprovalFlowSettingDialog.state.transaction} />
      )}
      {transactionsApprovalFlowSettingDialog.state && (
        <TransactionsApprovalFlowSettingDialog
          transactions={transactionsApprovalFlowSettingDialog.state.transactions}
        />
      )}
      {transactionRejectDialog.state && (
        <TransactionRejectDialog transaction={transactionRejectDialog.state.transaction} />
      )}
      {transactionPhaseDiffDialog.state && (
        <TransactionPhaseDiffDialog
          moneyspace={transactionPhaseDiffDialog.state.moneyspace}
          transaction={transactionPhaseDiffDialog.state.transaction}
          previousPhase={transactionPhaseDiffDialog.state.previousPhase}
          changeLogs={transactionPhaseDiffDialog.state.changeLogs}
          approvalFlowState={transactionPhaseDiffDialog.state.approvalFlowState}
          processable={transactionPhaseDiffDialog.state.processable}
        />
      )}
      {transactionSearchConditionListDialog.state && <TransactionSearchConditionListDialog />}
      {transactionSearchConditionFormDialog.state && (
        <TransactionSearchConditionFormDialog id={transactionSearchConditionFormDialog.state.id} />
      )}
      {transactionSearchConditionDeleteDialog.state && (
        <TransactionSearchConditionDeleteDialog id={transactionSearchConditionDeleteDialog.state.id} />
      )}
      {transactionCopyDialog.state && (
        <TransactionCopyDialog
          transactionId={transactionCopyDialog.state.transactionId}
          name={transactionCopyDialog.state.name}
        />
      )}
      {transactionReleaseDialog.state && (
        <TransactionReleaseDialog
          moneyspaceId={transactionReleaseDialog.state.moneyspaceId}
          transactionId={transactionReleaseDialog.state.transactionId}
        />
      )}
      {billingPaidDialog.state && (
        <BillingPaidDialog
          moneyspaceId={billingPaidDialog.state.moneyspaceId}
          billingId={billingPaidDialog.state.billingId}
        />
      )}
      {billingBulkPaidDialog.state && (
        <BillingBulkPaidDialog
          billingIds={billingBulkPaidDialog.state.billingIds}
          searchParams={billingBulkPaidDialog.state.searchParams}
        />
      )}
      {transactionCsvImportItemPreviewDialog.state && (
        <TransactionCsvImportItemPreviewDialog details={transactionCsvImportItemPreviewDialog.state.details} />
      )}
      {transactionPdfPreviewDialog.state && (
        <TransactionPdfPreviewDialog
          transactionId={transactionPdfPreviewDialog.state.transactionId}
          phase={transactionPdfPreviewDialog.state.phase}
        />
      )}
      {billingPdfPreviewDialog.state && <BillingPdfPreviewDialog billingId={billingPdfPreviewDialog.state.billingId} />}
      {messageCreateDialog.state && <MessageCreateDialog />}
      {messageEditDialog.state && <MessageEditDialog />}
      {messageDeleteDialog.state && <MessageDeleteDialog />}
      {reservedMessageDialog.state && <ReservedMessageDialog />}
    </>
  )
}

import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import BillingSearchForm from './BillingSearchForm'
import { BillingSearchParams } from '../../../types/search'
import Form from '../../atoms/inputs/Form'
import Label from '../../atoms/display/Label'
import PaginationCountLabel from '../../molecules/display/PaginationCountLabel'
import Button from '../../atoms/inputs/Button'
import BillingSearchResultTable from '../../molecules/display/BillingSearchResultTable'
import Pagination from '../../atoms/navigation/Pagination'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { TransactionActions } from '../../../store/transactions'
import useDay from '../../../hooks/useDay'
import { SortDirection } from '../../../types/System'
import useRouter from '../../../hooks/useRouter'

export default function SearchTabBilling() {
  const dispatch = useDispatch()
  const searchParams = useSelector(TransactionSelectors.searchBillingParams)
  const searchBillingResult = useSelector(TransactionSelectors.searchBillingResult)
  const form = useForm<BillingSearchParams>({
    defaultValues: searchParams,
    mode: 'onChange',
  })
  const { search: searchPage } = useRouter()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = Number(query.get('page') ?? 1)
  const { timestamp } = useDay()
  const [sortColumn, setSortColumn] = useState<string>()
  const [sortDirection, setSortDirection] = useState<SortDirection>()

  useEffect(() => {
    dispatch(
      TransactionActions.searchBilling({
        page,
        searchParams,
      })
    )
  }, [dispatch, page, searchParams])

  const handleSearch = useCallback(
    (params: BillingSearchParams) => {
      dispatch(
        TransactionActions.saveSearchBillingParams({
          searchParams: params,
        })
      )
      searchPage('billing', 1)
    },
    [dispatch, searchPage]
  )

  const handleChangePage = useCallback(
    (newPage: number) => {
      searchPage('billing', newPage)
      dispatch(
        TransactionActions.searchBilling({
          page: newPage,
          searchParams,
        })
      )
      window.scrollTo(0, 0)
    },
    [dispatch, searchParams, searchPage]
  )

  const handlePdfDownload = useCallback(() => {
    dispatch(
      TransactionActions.downloadBillingsPdf({
        filename: `billings_${timestamp()}.zip`,
        searchParams,
      })
    )
  }, [dispatch, searchParams, timestamp])

  const handleDownload = useCallback(() => {
    dispatch(
      TransactionActions.downloadBillings({
        filename: `billings_${timestamp()}.csv`,
        searchParams,
      })
    )
  }, [dispatch, searchParams, timestamp])

  const handleSort = useCallback(
    (column: string, direction: SortDirection) => {
      setSortColumn(column)
      setSortDirection(direction)
      dispatch(
        TransactionActions.saveSearchBillingParams({
          searchParams: {
            ...searchParams,
            sortColumn: column,
            sortDirection: direction,
          },
        })
      )
    },
    [dispatch, searchParams]
  )

  return (
    <FlexColumn>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(handleSearch)}>
          <BillingSearchForm />
        </Form>
      </FormProvider>
      <FlexRow align="center" id="search_results" justify="space-between" wrap>
        <FlexRow align="center" wrap>
          <Label text="検索結果" variant="subtitle" />
          {searchBillingResult && <PaginationCountLabel page={page} contents={searchBillingResult} />}
        </FlexRow>
        <FlexRow align="center" wrap>
          <Button variant="outlined" onClick={handlePdfDownload} size="lg">
            PDF一括ダウンロード
          </Button>
          <Button variant="outlined" onClick={handleDownload}>
            CSVダウンロード
          </Button>
          <Button variant="outlined" size="sm" onClick={() => window.print()}>
            印刷
          </Button>
        </FlexRow>
      </FlexRow>
      {searchBillingResult && (
        <>
          {searchBillingResult.count === 0 ? (
            <FlexRow justify="center">
              <Label text="検索条件に一致する請求書は見つかりませんでした" variant="subtitle" />
            </FlexRow>
          ) : (
            <>
              <BillingSearchResultTable
                billings={searchBillingResult.results}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSort={handleSort}
              />
              <Pagination count={searchBillingResult.count} page={page} size={30} onChange={handleChangePage} />
            </>
          )}
        </>
      )}
    </FlexColumn>
  )
}

import { useSelector } from 'react-redux'
import { Moneyspace } from '../../../types/Moneyspace'
import { FlexRow } from '../../atoms/layout/Flex'
import AvatarGroup from '../../atoms/display/AvatarGroup'
import Avatar from '../../atoms/display/Avatar'
import IconButton from '../../atoms/inputs/IconButton'
import { UserAddIcon } from '../../atoms/display/Icons'
import { useMoneyspaceMembersSettingDialog } from '../../../hooks/useDialog'
import { SessionSelectors } from '../../../store/session/selectors'
import usePermission from '../../../hooks/usePermission'
import useMoneyspace from '../../../hooks/useMoneyspace'

type MoneyspaceUsersViewProps = {
  moneyspace: Moneyspace
  targetCompanyId: string
  editable: boolean
}

export default function MoneyspaceUsersView({ moneyspace, targetCompanyId, editable }: MoneyspaceUsersViewProps) {
  const MoneyspaceMemberSettingDialog = useMoneyspaceMembersSettingDialog()
  const sessionUser = useSelector(SessionSelectors.user)
  const { findMembers } = useMoneyspace()
  const { moneyspacePermissions } = usePermission()
  const users = findMembers(moneyspace.id).filter((user) => user.companyId === targetCompanyId)
  return (
    <FlexRow align="center" space={0}>
      <AvatarGroup total={users.length}>
        {users.map((user) => (
          <Avatar key={user.id} url={user.icon} alt={user.name} size="sm" />
        ))}
      </AvatarGroup>
      {editable && moneyspacePermissions.members(sessionUser) && (
        <IconButton label="メンバー設定" size="sm" onClick={() => MoneyspaceMemberSettingDialog.open(moneyspace)}>
          <UserAddIcon />
        </IconButton>
      )}
    </FlexRow>
  )
}

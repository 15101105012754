import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import Button from '../../components/atoms/inputs/Button'
import SearchField from '../../components/molecules/inputs/SearchField'
import ApprovalFlowTable from '../../components/organisms/approval_flows/ApprovalFlowTable'
import { ApprovalFlowSelectors } from '../../store/approval_flow/selectors'
import usePage from '../../hooks/usePage'
import { useApprovalFlowSettingDialog } from '../../hooks/useDialog'
import { ApprovalFlow } from '../../types/ApprovalFlow'
import usePermission from '../../hooks/usePermission'
import { SessionSelectors } from '../../store/session/selectors'

export default function ApprovalFlowsPage() {
  const { changeTitle } = usePage()
  const flows = useSelector(ApprovalFlowSelectors.flows)
  const [filteredFlows, setFilteredFlows] = useState<ApprovalFlow[]>(flows)
  const ApprovalFlowSettingDialog = useApprovalFlowSettingDialog()
  const { approvalFlowPermissions } = usePermission()
  const user = useSelector(SessionSelectors.user)
  useEffect(() => {
    setFilteredFlows(flows)
  }, [flows])
  useEffect(() => {
    changeTitle('承認フロー設定')
  }, [changeTitle])
  const handleFilter = useCallback(
    (keyword?: string) => {
      if (keyword) {
        setFilteredFlows(flows.filter((item) => item.name.includes(keyword)))
      } else {
        setFilteredFlows(flows)
      }
    },
    [flows]
  )
  return (
    <FlexColumn>
      <FlexRow>
        {approvalFlowPermissions.create(user) && (
          <Button onClick={() => ApprovalFlowSettingDialog.open()} disabled={flows.length >= 10}>
            新規作成
          </Button>
        )}
        <SearchField placeholder="フロー名で検索" onChange={handleFilter} />
      </FlexRow>
      <ApprovalFlowTable flows={filteredFlows} />
    </FlexColumn>
  )
}

import { useCallback } from 'react'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import Form from '../../atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import Button from '../../atoms/inputs/Button'
import PasswordField from '../../molecules/inputs/PasswordField'

type PasswordResetSettingParams = {
  password: string
  passwordConfirm: string
}

export default function PasswordResetSettingForm() {
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    password: rules.password.required(),
    passwordConfirm: rules.passwordConfirm.required(),
  })
  const { handleSubmit, register, errors } = useForm<PasswordResetSettingParams>(schema)
  const handlePasswordReset = useCallback(
    (/* values: PasswordResetSettingParams */) => {
      // TODO
    },
    []
  )
  return (
    <Form onSubmit={handleSubmit(handlePasswordReset)}>
      <FlexColumn>
        <FormItem label="パスワード" required>
          <PasswordField register={register('password')} error={errors.password} />
        </FormItem>
        <FormItem label="パスワード（確認用）" required>
          <PasswordField register={register('passwordConfirm')} error={errors.passwordConfirm} />
        </FormItem>
        <FlexRow justify="center">
          <Button type="submit">送信</Button>
        </FlexRow>
      </FlexColumn>
    </Form>
  )
}

import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import useConsts from '../../../hooks/useConsts'
import useRouter from '../../../hooks/useRouter'
import Label from '../../atoms/display/Label'
import { useDeviceType } from '../../../hooks/useDeviceType'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { TransactionDetailSearchResultItem } from '../../../types/transaction'
import TransactionStatusLabel from './TransactionStatusLabel'
import TransactionFavoriteButton from '../../organisms/transaction/TransactionFavoriteButton'

type TransactionDetailSearchResultTableProps = {
  items: TransactionDetailSearchResultItem[]
}

function TransactionDetailRow({ item, pc }: { item: TransactionDetailSearchResultItem; pc: boolean }) {
  const { phaseName } = useConsts()
  const { transaction: transactionPage } = useRouter()
  const { transaction } = item
  return (
    <TableRow onClick={() => transactionPage(transaction.id)}>
      <TableCell size="xs3">
        <TransactionFavoriteButton transaction={transaction} />
      </TableCell>
      {pc ? (
        <>
          <TableCell size="sm">{transaction.moneyspace.name}</TableCell>
          <TableCell size="sm">{transaction.name}</TableCell>
        </>
      ) : (
        <TableCell size="sm">
          <FlexColumn>
            <Label text={transaction.moneyspace.name} />
            <Label text={transaction.name} />
          </FlexColumn>
        </TableCell>
      )}
      <TableCell size="xs" align="center">
        {phaseName(transaction.currentPhase)}
      </TableCell>
      <TableCell size="xs" align="center">
        <TransactionStatusLabel transaction={transaction} />
      </TableCell>
      <TableCell size="sm">
        <FlexRow>
          <Label text={item.description} />
        </FlexRow>
      </TableCell>
      <TableCell size="xs" align="right">
        <FlexRow justify="flex-end">
          <Label text={item.amount} format="amountNoStyle" />
          <Label text="円" />
          <Label text={transaction.isTaxIn ? '（税込）' : '（税抜）'} />
        </FlexRow>
      </TableCell>
    </TableRow>
  )
}

export default function TransactionDetailSearchResultTable({ items }: TransactionDetailSearchResultTableProps) {
  const { pc } = useDeviceType()
  return (
    <Table
      header={
        <TableRow>
          <TableCell header size="xs3" />
          {pc ? (
            <>
              <TableCell header size="sm">
                マネースペース
              </TableCell>
              <TableCell header size="sm">
                取引名
              </TableCell>
            </>
          ) : (
            <TableCell header size="sm">
              マネースペース/取引名
            </TableCell>
          )}
          <TableCell header size="xs">
            フェーズ
          </TableCell>
          <TableCell header size="xs">
            ステータス
          </TableCell>
          <TableCell header size="sm">
            項目
          </TableCell>
          <TableCell header size="xs">
            金額
          </TableCell>
        </TableRow>
      }
    >
      {items.map((item) => (
        <TransactionDetailRow key={item.id} item={item} pc={pc} />
      ))}
    </Table>
  )
}

import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Contract } from '../../../types/Document'
import { useContractCopyDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import ContractNameField from '../../molecules/inputs/ContractNameField'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import Button from '../../atoms/inputs/Button'
import { TransactionActions } from '../../../store/transactions'
import useDay from '../../../hooks/useDay'
import useDocument from '../../../hooks/useDocument'
import { Moneyspace } from '../../../types/Moneyspace'
import useProcessing from '../../../hooks/useProcessing'

type ContractCopyDialogProps = {
  moneyspace: Moneyspace
  contract: Contract
  page: number
  excludeClosed?: boolean
}

type ContractCopyParams = {
  name: string
}

export default function ContractCopyDialog({ moneyspace, contract, page, excludeClosed }: ContractCopyDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.copyContract.typePrefix)
  const { state, close } = useContractCopyDialog()
  const { today } = useDay()
  const { load, document, convertContractee } = useDocument()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  useEffect(() => {
    load(contract.documents[0].id)
  }, [contract.documents, load])
  const schema = yup.object().shape({
    name: rules.transactionName.required(),
  })
  const { handleSubmit, register, errors } = useForm<ContractCopyParams>(schema, {
    name: contract.name,
  })
  const handleCopy = useCallback(
    (values: ContractCopyParams) => {
      if (document) {
        startProcess()
        dispatch(
          TransactionActions.copyContract({
            moneyspace,
            contract,
            contractee: convertContractee(moneyspace),
            contractor: moneyspace.contractor,
            document,
            name: values.name,
            publishedAt: today(),
            page,
            excludeClosed,
          })
        )
      }
    },
    [contract, convertContractee, dispatch, document, excludeClosed, moneyspace, page, startProcess, today]
  )

  return (
    <Dialog
      open={state !== undefined}
      title="取引のコピー"
      actions={
        <Button type="submit" disabled={processing}>
          コピー
        </Button>
      }
      onSubmit={handleSubmit(handleCopy)}
      onClose={close}
    >
      <FlexColumn>
        <FormItem label="取引名" required>
          <ContractNameField register={register('name')} error={errors.name} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

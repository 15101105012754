import MuiButton from '@mui/material/Button'
import { PdfParams } from '../../../hooks/usePdf'

type PdfDownloadButtonProps = {
  params: PdfParams
}

export default function PdfDownloadButton({ params }: PdfDownloadButtonProps) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { Download } = params.downloadPlugin
  return (
    <Download>
      {({ onClick }) => (
        <MuiButton variant="contained" sx={{ width: '160px' }} onClick={onClick}>
          ダウンロード
        </MuiButton>
      )}
    </Download>
  )
}

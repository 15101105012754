import { useTransactionCsvImportItemPreviewDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Label from '../../atoms/display/Label'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import TaxCategoryLabel from '../../molecules/display/TaxCategoryLabel'
import { TransactionDetail } from '../../../types/transaction'
import { FlexColumn } from '../../atoms/layout/Flex'

type TransactionCsvImportItemPreviewDialogProps = {
  details: TransactionDetail[]
}

export default function TransactionCsvImportItemPreviewDialog({ details }: TransactionCsvImportItemPreviewDialogProps) {
  const { state, close } = useTransactionCsvImportItemPreviewDialog()
  return (
    <Dialog open={state !== undefined} onClose={close} title={`明細の確認 ${state ? state.title : ''}`} width="xl">
      <FlexColumn>
        <Label text="現場住所" variant="caption" />
        <Label text={state?.siteAddress ?? ''} />
      </FlexColumn>
      <Table
        header={
          <TableRow>
            <TableCell header size="xs">
              #
            </TableCell>
            <TableCell header size="sm">
              工事種別
            </TableCell>
            <TableCell header size="sm">
              カスタム項目1
            </TableCell>
            <TableCell header size="sm">
              カスタム項目2
            </TableCell>
            <TableCell header size="xs">
              単価
            </TableCell>
            <TableCell header size="xs">
              数量
            </TableCell>
            <TableCell header size="xs">
              単位
            </TableCell>
            <TableCell header size="xs">
              税区分
            </TableCell>
          </TableRow>
        }
      >
        {details.map((item, index) => (
          <TableRow>
            <TableCell size="xs" align="center">
              <Label text={index + 1} />
            </TableCell>
            <TableCell size="sm">{item.defaultProp}</TableCell>
            <TableCell size="sm">{item.customProp1}</TableCell>
            <TableCell size="sm">{item.customProp2}</TableCell>
            <TableCell size="xs" align="right">
              <Label text={item.unitPrice} format="amount" />
            </TableCell>
            <TableCell size="xs" align="center">
              <Label text={item.quantity} />
            </TableCell>
            <TableCell size="xs" align="center">
              <Label text={item.unit} />
            </TableCell>
            <TableCell size="xs" align="center">
              <TaxCategoryLabel category={item.taxBucket} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Dialog>
  )
}

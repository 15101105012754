import { Control } from 'react-hook-form'
import { FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import DatePicker from '../../atoms/inputs/DatePicker'

type DocumentDeliveryDateFormProps = {
  control: Control
  disabled?: boolean
}

export default function DocumentDeliveryDateForm({ control, disabled }: DocumentDeliveryDateFormProps) {
  return (
    <FormItem label="納入期間（作業期間）">
      <FlexRow pt={1}>
        <DatePicker label="From" name="deliveryDateFrom" control={control} disabled={disabled} />
        <DatePicker label="To" name="deliveryDateTo" control={control} disabled={disabled} />
      </FlexRow>
    </FormItem>
  )
}

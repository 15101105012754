import MuiCircularProgress from '@mui/material/CircularProgress'

export type CircularProgressSize = 'normal' | 'large'

export type CircularProgressProps = {
  size: CircularProgressSize
}

const sizeToPoint = (size: CircularProgressSize): number => {
  switch (size) {
    case 'normal':
      return 40
    case 'large':
      return 120
    default:
      return 40
  }
}

export default function CircularProgress({ size }: CircularProgressProps) {
  return <MuiCircularProgress size={sizeToPoint(size)} />
}

import { Control, FieldErrors } from 'react-hook-form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import DayField from '../../molecules/inputs/DayField'
import PaymentMonthField from '../../molecules/inputs/PaymentMonthField'
import Checkbox from '../../atoms/inputs/Checkbox'
import ConsumptionTaxSelect from '../../molecules/inputs/ConsumptionTaxSelect'
import Label from '../../atoms/display/Label'
import { PaymentFormParams } from '../../../types/Moneyspace'
import ClosingDayGracePeriodField from '../../molecules/inputs/ClosingDayGracePeriodField'
import RadioControl from '../../atoms/inputs/RadioControl'

type CompanyPaymentFormProps = {
  control: Control
  errors: FieldErrors<PaymentFormParams>
  disabledBearingFee?: boolean
  disabledClosingDayGracePeriod?: boolean
  displayTaxRoundingType?: boolean
}

export default function CompanyPaymentForm({
  control,
  errors,
  disabledBearingFee,
  disabledClosingDayGracePeriod,
  displayTaxRoundingType,
}: CompanyPaymentFormProps) {
  return (
    <FlexColumn>
      <FlexRow wrap>
        <FormItem label="消費税の表示">
          <ConsumptionTaxSelect name="isTaxIn" control={control} />
        </FormItem>
        {displayTaxRoundingType && (
          <FormItem label="消費税の小数点以下の端数処理">
            <RadioControl
              control={control}
              name="taxRoundingType"
              items={[
                { label: '切り捨て', value: 1 },
                { label: '切り上げ', value: 2 },
                { label: '四捨五入', value: 3 },
              ]}
              row
              disabled
            />
          </FormItem>
        )}
      </FlexRow>
      <Label text="支払関連の設定" variant="subtitle" />
      <FlexRow wrap>
        <FormItem label="締め日" required>
          <DayField control={control} name="closingDay" error={errors.closingDay} />
        </FormItem>
        <FormItem label="締め日の自動設定の猶予期間">
          <FlexColumn space={0}>
            <FlexRow align="center">
              <Label text="締め日から" />
              <ClosingDayGracePeriodField
                name="fixGracePeriod"
                control={control}
                disabled={disabledClosingDayGracePeriod}
              />
            </FlexRow>
            <Label text="設定日の翌日から次の締め日が請求書に表示されます" variant="caption" />
          </FlexColumn>
        </FormItem>
      </FlexRow>
      <FlexRow wrap>
        <FormItem label="支払月">
          <PaymentMonthField name="paymentMonth" control={control} />
        </FormItem>
        <FormItem label="支払日" required>
          <DayField control={control} name="paymentDay" error={errors.paymentDay} />
        </FormItem>
      </FlexRow>
      <FlexRow wrap>
        <FormItem label="振込手数料の負担">
          <Checkbox label="受取人負担にする" name="bearingFee" control={control} disabled={disabledBearingFee} />
        </FormItem>
      </FlexRow>
    </FlexColumn>
  )
}

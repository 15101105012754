import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDocument from '../../hooks/useDocument'
import usePage from '../../hooks/usePage'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import ContractSteps from '../../components/organisms/document/ContractSteps'
import Button from '../../components/atoms/inputs/Button'
import TransactionTimeline from '../../components/molecules/display/TransactionTimeline'
import Label from '../../components/atoms/display/Label'
import { useContractDeleteDialog, useContractEditDialog, useDocumentDiffDialog } from '../../hooks/useDialog'
import { TransactionActions } from '../../store/transactions'
import { TransactionSelectors } from '../../store/transactions/selectors'
import usePdf from '../../hooks/usePdf'
import PdfDownloadButton from '../../components/atoms/inputs/PdfDownloadButton'
import PdfPrintButton from '../../components/atoms/inputs/PdfPrintButton'
import DocumentStatusLabel from '../../components/molecules/display/DocumentStatusLabel'
import DocumentButtons from '../../components/organisms/document/DocumentButtons'
import DocumentPdfViewer from '../../components/organisms/document/DocumentPdfViewer'
import useMoneyspace from '../../hooks/useMoneyspace'
import { CompanySelectors } from '../../store/company/selectors'
import AttachmentsForm from '../../components/organisms/document/AttachmentsForm'
import usePermission from '../../hooks/usePermission'
import { SessionSelectors } from '../../store/session/selectors'
import { useDeviceType } from '../../hooks/useDeviceType'
import useRouter from '../../hooks/useRouter'
import DocumentApprovalFlowStateView from '../../components/organisms/document/DocumentApprovalFlowStateView'
import Alert from '../../components/atoms/feedback/Alert'
import { MoneyspacesActions } from '../../store/moneyspaces'
import FactoringDialog from '../../components/molecules/feedback/FactoringDialog'
import useFactoring from '../../hooks/useFactoring'
import useDocumentDiff from '../../hooks/useDocumentDiff'
import { Contract } from '../../types/Document'

function unprogressed(contract: Contract): boolean {
  return !contract.documents.some((doc) => doc.status !== 1)
}

export default function DocumentPage() {
  const { moneyspaceId, contractId, documentId } = useParams()
  const { factoringUrl, canFactoringDocument } = useFactoring(moneyspaceId)
  const { document, approvalFlowState, load, loadDiffs, documentDiffs } = useDocument()
  const { find: findMoneyspace, isContractee } = useMoneyspace()
  const { changeTitle } = usePage()
  const contractEditDialog = useContractEditDialog()
  const contractDeleteDialog = useContractDeleteDialog()
  const contract = useSelector(TransactionSelectors.contract)
  const company = useSelector(CompanySelectors.company)
  const pdfParams = usePdf({ filename: document?.name })
  const { documentPermissions } = usePermission()
  const { pc } = useDeviceType()
  const user = useSelector(SessionSelectors.user)
  const [loading, setLoading] = useState(true)
  const [factoringDialogOpen, setFactoringDialogOpen] = useState(canFactoringDocument(document))
  const compareResult = useDocumentDiff(documentDiffs)
  const documentDiffDialog = useDocumentDiffDialog()
  const moneyspace = findMoneyspace(moneyspaceId)
  const { document: documentPage, moneyspace: moneyspacePage } = useRouter()
  const dispatch = useDispatch()
  useEffect(() => {
    setFactoringDialogOpen(canFactoringDocument(document))
  }, [canFactoringDocument, document])
  useEffect(() => {
    if (documentId) {
      setLoading(true)
      load(documentId, () => {
        setLoading(false)
      })
      loadDiffs(documentId)
    }
  }, [documentId, load, loadDiffs])
  useEffect(() => {
    if (contractId) {
      dispatch(TransactionActions.fetchContract({ id: contractId }))
    }
  }, [contractId, dispatch, document])
  useEffect(() => {
    if (moneyspaceId) {
      dispatch(MoneyspacesActions.fetchMembers({ moneyspaceId }))
    }
  }, [dispatch, moneyspaceId])
  useEffect(() => {
    if (moneyspace && contract) {
      changeTitle(moneyspace.name, contract.name)
    }
  }, [changeTitle, contract, moneyspace])
  useEffect(() => {
    if (
      moneyspaceId === undefined ||
      contractId === undefined ||
      contract === undefined ||
      document === undefined ||
      documentId === undefined
    ) {
      return
    }
    if (contract.id !== contractId || document.id !== documentId) {
      return
    }
    if (contract.documents.length === 0) {
      moneyspacePage(moneyspaceId)
      return
    }
    if (!contract.documents.map((doc) => doc.id).includes(documentId)) {
      documentPage(moneyspaceId, contractId, contract.documents[0].id)
    }
  }, [contract, contractId, document, documentId, documentPage, moneyspaceId, moneyspacePage])
  const handleOpenDiffDialog = useCallback(() => {
    if (moneyspace && contract && document && documentDiffs?.previousDocument) {
      documentDiffDialog.open(
        moneyspace,
        contract,
        document,
        documentDiffs.previousDocument.document,
        compareResult.changeLogs,
        approvalFlowState
      )
    }
  }, [approvalFlowState, compareResult.changeLogs, contract, document, documentDiffDialog, documentDiffs, moneyspace])
  const ContractView = useMemo(
    () => (
      <>
        {contract && moneyspace && (
          <>
            {contract.ownerUid === company?.uid && unprogressed(contract) && (
              <FlexRow>
                <Button onClick={() => contractEditDialog.open(contract)}>取引編集</Button>
                <Button color="caution" onClick={() => contractDeleteDialog.open(contract, moneyspace.id)}>
                  取引削除
                </Button>
              </FlexRow>
            )}
            <AttachmentsForm transactionId={contract.id} />
            <FlexRow width={pc ? 640 : 320}>
              <ContractSteps moneyspaceId={moneyspace.id} activeDocumentId={documentId ?? ''} contract={contract} />
            </FlexRow>
          </>
        )}
      </>
    ),
    [company?.uid, contract, contractDeleteDialog, contractEditDialog, documentId, moneyspace, pc]
  )
  if (contract === undefined || moneyspace === undefined) {
    return <></>
  }
  return (
    <FlexColumn space={4}>
      <>{ContractView}</>
      {!loading && (
        <>
          {document !== undefined && documentPermissions.view(isContractee(moneyspace, user), document, moneyspace) ? (
            <FlexRow wrap>
              <FlexColumn width={pc ? 640 : 320} space={0}>
                {[2, 3].includes(document.status) && compareResult.updated && (
                  <FlexRow align="center" justify="flex-end">
                    <Label text="この書類は前の書類に比べて、明細に差異があります！" color="error" />
                    <Button variant="text" height="small" onClick={handleOpenDiffDialog}>
                      変更点をレビュー
                    </Button>
                  </FlexRow>
                )}
                <DocumentPdfViewer documentId={document.id} pdfParams={pdfParams} />
              </FlexColumn>
              <FlexColumn space={4}>
                <FlexColumn>
                  <FlexRow>
                    <Label text="ステータス" variant="subtitle" />
                    <DocumentStatusLabel
                      documentStatus={document.status}
                      documentType={document.type}
                      moneyspace={moneyspace}
                      bold
                    />
                  </FlexRow>
                  {documentPermissions.viewApprovalFlowState(isContractee(moneyspace, user), document, moneyspace) && (
                    <DocumentApprovalFlowStateView document={document} moneyspace={moneyspace} />
                  )}
                  <DocumentButtons
                    moneyspace={moneyspace}
                    document={document}
                    contract={contract}
                    approvalFlowState={approvalFlowState}
                  />
                  <Label text="PDF操作" variant="caption" />
                  <FlexRow>
                    <PdfDownloadButton params={pdfParams} />
                    <PdfPrintButton params={pdfParams} />
                  </FlexRow>
                </FlexColumn>
                <TransactionTimeline activities={[]} />
              </FlexColumn>
            </FlexRow>
          ) : (
            <Alert severity="error" label="取引先が書類編集中のため閲覧できません" />
          )}
          <FactoringDialog
            url={factoringUrl}
            open={factoringDialogOpen}
            onClose={() => setFactoringDialogOpen(false)}
          />
        </>
      )}
    </FlexColumn>
  )
}

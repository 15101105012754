import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon } from '../../atoms/display/Icons'
import { useClientAcceptDialog, useClientDeleteDialog } from '../../../hooks/useDialog'
import AddressLabel from '../../molecules/display/AddressLabel'
import { Company } from '../../../types/Company'
import { FlexRow } from '../../atoms/layout/Flex'
import Chip from '../../atoms/display/Chip'
import Button from '../../atoms/inputs/Button'
import { SessionSelectors } from '../../../store/session/selectors'

type ClientTableProps = {
  clients: Company[]
}

export default function ClientTable({ clients }: ClientTableProps) {
  const clientAcceptDialog = useClientAcceptDialog()
  const clientDeleteDialog = useClientDeleteDialog()
  const user = useSelector(SessionSelectors.user)
  const handleDelete = useCallback(
    (client: Company) => {
      clientDeleteDialog.open(client)
    },
    [clientDeleteDialog]
  )
  return (
    <Table
      header={
        <TableRow>
          <TableCell header size="xs" />
          <TableCell header size="sm">
            企業ID
          </TableCell>
          <TableCell header size="sm">
            企業名
          </TableCell>
          <TableCell header size="sm">
            事業者登録番号
          </TableCell>
          <TableCell header size="sm">
            代表者
          </TableCell>
          <TableCell header size="md">
            住所
          </TableCell>
          <TableCell header size="sm">
            電話番号（会社）
          </TableCell>
          <TableCell header size="sm">
            電話番号（携帯）
          </TableCell>
          <TableCell header size="xs" />
        </TableRow>
      }
    >
      {clients.map((client) => (
        <TableRow key={client.id}>
          {client.isInviter && !client.isAccepted ? (
            <TableCell size="xs" align="center">
              <Button
                height="small"
                size="sm"
                disabled={user?.role === 3}
                onClick={() => clientAcceptDialog.open(client)}
              >
                <Label text="自社承認待ち" variant="caption" />
              </Button>
            </TableCell>
          ) : (
            <TableCell size="xs" align="center">
              {client.isAccepted ? <Chip label="承認済" /> : <Chip label="取引先未承認" color="primary" />}
            </TableCell>
          )}
          <TableCell size="sm">
            <Label text={client.uid ?? ''} />
          </TableCell>
          <TableCell size="sm">
            <Label text={client.name} />
          </TableCell>
          <TableCell size="xs">
            {client.invoiceLicenseNumber ? <Label text={client.invoiceLicenseNumber} /> : <Label text="-" />}
          </TableCell>
          <TableCell size="sm">
            <FlexRow>
              <Label text={client.representativeRole ?? ''} />
              <Label text={client.representativeName ?? ''} />
            </FlexRow>
          </TableCell>
          <TableCell size="md">
            <AddressLabel address={client} />
          </TableCell>
          <TableCell size="sm">{client.phoneNumber}</TableCell>
          <TableCell size="sm">{client.phoneNumberMobile}</TableCell>
          <TableCell size="xs">
            <IconButton
              label="削除"
              onClick={() => handleDelete(client)}
              disabled={client.isRelatedMs || user?.role === 3}
              color="caution"
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

import Label from '../components/atoms/display/Label'
import { FlexColumn } from '../components/atoms/layout/Flex'
import Card from '../components/atoms/surfaces/Card'
import LoginForm from '../components/organisms/session/LoginForm'
import Link from '../components/atoms/navigation/Link'
import usePath from '../hooks/usePath'

export default function LoginPage() {
  const { register, passwordReset, registerContracteeEmail } = usePath()

  return (
    <FlexColumn align="center" mt={4}>
      <Label text="ログイン" variant="title" />
      <Card size="lg">
        <FlexColumn pa={4} space={2}>
          <LoginForm />
          <FlexColumn align="center" space={2}>
            <Link href={passwordReset()}>パスワードをお忘れの方はこちら</Link>
            <FlexColumn align="center">
              <Label text="新規登録" variant="subtitle" />
              <Link href={registerContracteeEmail()}>受注者の方はこちら</Link>
              <Link href={register()}>発注者の方はこちら</Link>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
      </Card>
    </FlexColumn>
  )
}

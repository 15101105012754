export type Role = 1 | 2 | 3

export type User = {
  id: string
  name: string
  email: string
  icon: string
  companyId?: string
  companyDomain: string
  role: Role
  isRawPassword: boolean
  lineId?: string | null
  hasUnapproved: boolean
  registrationStatus?: {
    isContractee: boolean
    isInviteContractor: boolean
    isLinkedLineAccount: boolean
  }
}

export type RelatedUser = {
  id: string
  name: string
  icon: string
  companyId: string
  isDeleted?: boolean
}

export function toRelatedUser(user: User): RelatedUser {
  return {
    id: user.id,
    name: user.name,
    icon: user.icon,
    companyId: user.companyId ?? '',
    isDeleted: false,
  }
}

export type Notification = {
  id: number
  name: string
  emailEnable: boolean
  lineEnable: boolean
}

import { useCallback } from 'react'

export default function usePath() {
  return {
    root: useCallback(() => '/', []),
    pendingTransactions: useCallback(() => '/pending_transactions', []),
    receivedTransactions: useCallback(() => '/received_transactions', []),
    unreadMessages: useCallback(() => '/unread_messages', []),
    messages: useCallback(() => `/messages`, []),
    moneyspaceMessages: useCallback(
      (moneyspaceId: string | undefined = ':messageMsId') => `/messages/${moneyspaceId}`,
      []
    ),
    aggregation: useCallback(() => `aggregation`, []),
    paymentBillings: useCallback(() => '/payment_billings', []),
    activeTransactions: useCallback(() => '/active_transactions', []),
    billingTransactions: useCallback(() => '/billing_transactions', []),
    login: useCallback(() => '/login', []),
    register: useCallback((token?: string) => (token ? `/register?token=${token}` : '/register'), []),
    registerSendEmail: useCallback(() => '/register/send_email', []),
    registerCompleted: useCallback(() => '/register/completed', []),
    registerContracteeEmail: useCallback(() => '/register/contractee/email', []),
    registerContracteeUid: useCallback(() => '/register/contractee/uid', []),
    registerContracteeName: useCallback(() => '/register/contractee/name', []),
    registerContracteeTerms: useCallback(() => '/register/contractee/terms', []),
    registerContracteeEmailSupport: useCallback(() => '/register/contractee/email_support', []),
    registerContracteeCompleted: useCallback(() => '/register/contractee/completed', []),
    registerContracteeInvitation: useCallback(() => '/register/contractee/invitation', []),
    registerContracteeInvitationCompleted: useCallback(() => '/register/contractee/invitation_completed', []),
    registerContracteeAddress: useCallback(() => '/register/contractee/address', []),
    registerContracteePhone: useCallback(() => '/register/contractee/phone', []),
    registerContracteeInvoiceLicense: useCallback(() => '/register/contractee/invoice_license', []),
    registerContracteeBank: useCallback(() => '/register/contractee/bank', []),
    registerContracteeLine: useCallback(() => '/register/contractee/line', []),
    registerContracteeWellcome: useCallback(() => '/register/contractee/wellcome', []),
    passwordReset: useCallback(() => '/password_reset', []),
    passwordResetSetting: useCallback(() => '/password_reset/setting', []),
    passwordResetCompleted: useCallback(() => '/password_reset/completed', []),
    moneyspace: useCallback((moneyspaceId?: string, tab?: string, page?: number, billingId?: string) => {
      const params = []
      if (tab) {
        params.push(`tab=${tab}`)
      }
      if (page) {
        params.push(`page=${page}`)
      }
      if (billingId) {
        params.push(`billingId=${billingId}`)
      }
      if (params.length > 0) {
        return `/moneyspaces/${moneyspaceId ?? ':moneyspaceId'}?${params.join('&')}`
      }
      return `/moneyspaces/${moneyspaceId ?? ':moneyspaceId'}`
    }, []),
    document: useCallback(
      (
        moneyspaceId: string | undefined = ':moneyspaceId',
        contractId: string | undefined = ':contractId',
        documentId: string | undefined = ':documentId'
      ) => `/moneyspaces/${moneyspaceId}/contracts/${contractId}/documents/${documentId}`,
      []
    ),
    documentEdit: useCallback(
      (
        moneyspaceId: string | undefined = ':moneyspaceId',
        contractId: string | undefined = ':contractId',
        documentId: string | undefined = ':documentId'
      ) => `/moneyspaces/${moneyspaceId}/contracts/${contractId}/documents/${documentId}/edit`,
      []
    ),
    transaction: useCallback(
      (transactionId: string | undefined = ':transactionId') => `/transactions/${transactionId}`,
      []
    ),
    transactionEdit: useCallback(
      (transactionId: string | undefined = ':transactionId') => `/transactions/${transactionId}/edit`,
      []
    ),
    transactionInstallment: useCallback(
      (transactionId: string | undefined = ':transactionId') => `/transactions/${transactionId}/installment`,
      []
    ),
    settingsCompany: useCallback(() => '/settings/company', []),
    settingsUsers: useCallback(() => '/settings/users', []),
    settingsClients: useCallback(() => '/settings/clients', []),
    settingsProfile: useCallback(() => '/settings/profile', []),
    settingsApprovalFlows: useCallback(() => '/settings/approval_flows', []),
    search: useCallback(() => `/search`, []),
    factoring: useCallback(() => '/factoring', []),
    toolsTransactionImporter: useCallback(() => '/tools/transaction_importer', []),
    error500: useCallback(() => '/error/500', []),
  }
}

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import usePage from '../../hooks/usePage'
import { CompanySelectors } from '../../store/company/selectors'
import TabView from '../../components/atoms/navigation/TabView'
import Label from '../../components/atoms/display/Label'
import CompanyTabItemBasic from '../../components/organisms/company/CompanyTabItemBasic'
import CompanyTabItemBankAccount from '../../components/organisms/company/CompanyTabItemBankAccount'
import CompanyTabItemMembership from '../../components/organisms/company/CompanyTabItemMembership'
import CompanyTabItemBilling from '../../components/organisms/company/CompanyTabItemBilling'
import CompanyTabTransactionSetting from '../../components/organisms/company/CompanyTabTransactionSetting'
import CompanyTabInvoiceSetting from '../../components/organisms/company/CompanyTabInvoiceSetting'
import CompanyCovenant from '../../components/organisms/company/CompanyCovenant'

export default function CompanyPage() {
  const { changeTitle } = usePage()
  const company = useSelector(CompanySelectors.company)
  useEffect(() => {
    changeTitle('企業情報')
  }, [changeTitle, company])
  const [tab, setTab] = useState('basic')

  return (
    <FlexColumn>
      <FlexRow>
        <Label text="企業ID" variant="subtitle" />
        <Label text={company?.uid ?? ''} />
      </FlexRow>
      <TabView
        value={tab}
        onChange={setTab}
        tabs={[
          {
            value: 'basic',
            label: '基本情報',
            panel: <CompanyTabItemBasic />,
          },
          {
            value: 'account',
            label: '振込先口座情報',
            panel: <CompanyTabItemBankAccount />,
          },
          {
            value: 'membership',
            label: '協力会費',
            panel: <CompanyTabItemMembership />,
          },
          {
            value: 'billing',
            label: '取引の承認期限',
            panel: <CompanyTabItemBilling />,
          },
          {
            value: 'detailProp',
            label: '取引',
            panel: <CompanyTabTransactionSetting />,
          },
          {
            value: 'invoice',
            label: 'インボイス対応',
            panel: <CompanyTabInvoiceSetting />,
          },
          {
            value: 'covenant',
            label: '発注の誓約条項',
            panel: <CompanyCovenant />,
          },
        ]}
      />
    </FlexColumn>
  )
}

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import EmailField from '../../molecules/inputs/EmailField'
import PasswordField from '../../molecules/inputs/PasswordField'
import CompanyDomainField from '../../molecules/inputs/CompanyDomainField'
import Label from '../../atoms/display/Label'
import CompanyNameField from '../../molecules/inputs/CompanyNameField'
import Button from '../../atoms/inputs/Button'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import Form from '../../atoms/inputs/Form'
import { SessionActions } from '../../../store/session'
import usePath from '../../../hooks/usePath'
import Terms from '../../atoms/display/Terms'
import useProcessing from '../../../hooks/useProcessing'
import Alert from '../../atoms/feedback/Alert'

type RegisterFormProps = {
  token?: string
}

type RegisterParams = {
  email: string
  password: string
  passwordConfirm: string
  companyDomain: string
  companyName: string
}

export default function RegisterForm({ token }: RegisterFormProps) {
  const { processing, startProcess } = useProcessing(SessionActions.register.typePrefix)
  const [scrolledToBottom, setScrolledToBottom] = useState(false)
  const { registerSendEmail } = usePath()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    email: rules.email.required(),
    password: rules.password.required(),
    passwordConfirm: rules.passwordConfirm.required(),
    companyDomain: rules.companyDomain.required(),
    companyName: rules.companyName.required(),
  })
  const { handleSubmit, register, errors } = useForm<RegisterParams>(schema)
  const dispatch = useDispatch()
  const handleRegister = useCallback(
    (values: RegisterParams) => {
      startProcess()
      dispatch(SessionActions.register({ ...values, getRedirectPath: () => registerSendEmail(), token }))
    },
    [dispatch, registerSendEmail, startProcess, token]
  )
  return (
    <Form onSubmit={handleSubmit(handleRegister)}>
      <FlexColumn>
        <FormItem label="メールアドレス" required>
          <EmailField register={register('email')} error={errors.email} />
        </FormItem>
        <Alert
          severity="warning"
          label="メールアドレスが @docomo @ezweb @i.softbank @ymobile @au ですと、
          あなたのセキュリティ設定の関係でメールが届かない可能性があります。パソコンのメール（ @gmail @ocn 等）でのご登録を推奨します。"
        />
        <FormItem label="パスワード" required>
          <PasswordField register={register('password')} error={errors.password} />
        </FormItem>
        <FormItem label="パスワード（確認用）" required>
          <PasswordField register={register('passwordConfirm')} error={errors.passwordConfirm} />
        </FormItem>
        <FormItem label="あなたの企業ID" required>
          <CompanyDomainField register={register('companyDomain')} error={errors.companyDomain} />
        </FormItem>
        <FlexColumn space={0}>
          <Label text="今後あなたの企業のメンバーがログインする時入力する必要があります。" variant="caption" />
          <Label text="※企業IDは登録後に変更できません" variant="caption" color="error" />
        </FlexColumn>
        <FormItem label="企業名" required>
          <CompanyNameField register={register('companyName')} error={errors.companyName} />
        </FormItem>
        <Label
          text="今後あなたの企業のメンバーがログインすると、この企業名が見出し等に表示されます。"
          variant="caption"
        />
        <FlexColumn align="center">
          <Terms height={360} onScrolledToBottom={() => setScrolledToBottom(true)} />
          <Button type="submit" disabled={!scrolledToBottom || processing}>
            作成する
          </Button>
        </FlexColumn>
      </FlexColumn>
    </Form>
  )
}

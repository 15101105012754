import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import { useTagEditDialog } from '../../../hooks/useDialog'
import FormItem from '../../molecules/inputs/FormItem'
import Button from '../../atoms/inputs/Button'
import TagNameField from '../../molecules/inputs/TagNameField'
import { Tag } from '../../../types/Document'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { DocumentsActions } from '../../../store/documents'

type TagEditDialogProps = {
  tag: Tag
}

export default function TagEditDialog({ tag }: TagEditDialogProps) {
  const { state, close } = useTagEditDialog()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.tagName.required(),
  })
  const { handleSubmit, register, errors } = useForm<Tag>(schema, tag)
  const dispatch = useDispatch()
  const handleUpdate = useCallback(
    (values: Tag) => {
      if (values.name === tag.name) {
        return
      }
      dispatch(DocumentsActions.updateTag({ tag: { name: values.name, id: tag.id } }))
    },
    [dispatch, tag]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="タグの編集"
      actions={<Button type="submit">保存</Button>}
      onSubmit={handleSubmit(handleUpdate)}
    >
      <FlexColumn>
        <FormItem label="タグ名" required>
          <TagNameField register={register('name')} error={errors.name?.message} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

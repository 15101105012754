import { ReactElement, ReactNode } from 'react'
import MuiTooltip from '@mui/material/Tooltip'
import * as React from 'react'

type TooltipProps = {
  text: NonNullable<ReactNode>
  children: ReactElement
}

export default function Tooltip({ text, children }: TooltipProps) {
  return (
    <MuiTooltip title={text}>
      <span>{children}</span>
    </MuiTooltip>
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type BankAccountNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function BankAccountNameField({ register, error, disabled }: BankAccountNameFieldProps) {
  return <TextField register={register} placeholder="ｾｲｷｭｳ ﾀﾛｳ" size="sm" error={error?.message} readonly={disabled} />
}

import NumberFormat from 'react-number-format'
import { TextField } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { useDeviceType } from '../../../hooks/useDeviceType'

type NumberFieldType = 'number' | 'amount'
type TextSize = 'xs' | 'sm' | 'md' | 'lg'

type NumberFieldProps = {
  type?: NumberFieldType
  size?: TextSize
  readonly?: boolean
  placeholder?: string
  name?: string
  control?: Control
  error?: string
  value?: number
  suffix?: string
  disabled?: boolean
}

const width = (size?: TextSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'md':
      return 360
    case 'lg':
      return 480
    default:
      return '100%'
  }
}

function AsciiTextField(props: TextFieldProps) {
  const { onChange } = props
  const { pc } = useDeviceType()
  return (
    <TextField
      {...props}
      onChange={(e) => {
        if (onChange) {
          if (pc) {
            // PCのみ全角->半角変換に対応
            const nativeEvent = e.nativeEvent as InputEvent
            if (nativeEvent.isComposing && nativeEvent.data?.match(/[０-９]/g)) {
              e.target.value = e.target.value.replace(/[０-９]/g, (char: string) =>
                String.fromCharCode(char.charCodeAt(0) - 0xfee0)
              )
            }
          }
          onChange(e)
        }
      }}
    />
  )
}

export default function NumberField({
  type,
  size,
  readonly,
  placeholder,
  name,
  control,
  error,
  value,
  suffix,
  disabled,
}: NumberFieldProps) {
  if (name && control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <NumberFormat
            {...field}
            onValueChange={(newValue) => {
              field.onChange(newValue.floatValue ?? null)
            }}
            onChange={undefined}
            size="small"
            placeholder={placeholder}
            sx={{ width: width(size), textAlign: 'right' }}
            thousandSeparator={type === 'amount'}
            customInput={AsciiTextField}
            InputProps={{
              readOnly: readonly,
            }}
            error={error !== undefined}
            helperText={error ?? ''}
            suffix={suffix}
            disabled={disabled}
            inputMode="decimal"
          />
        )}
      />
    )
  }
  return (
    <NumberFormat
      size="small"
      sx={{ width: width(size), textAlign: 'right' }}
      thousandSeparator={type === 'amount'}
      customInput={TextField}
      InputProps={{
        readOnly: readonly,
      }}
      value={value}
      suffix={suffix}
      disabled={disabled}
    />
  )
}

import MuiRadioGroup from '@mui/material/RadioGroup'
import MuiRadio from '@mui/material/Radio'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { ReactElement } from 'react'

type RadioItem = {
  value: number
  label: string | ReactElement
}

type RadioControlProps<T extends FieldValues> = {
  items: RadioItem[]
  row?: boolean
  name: FieldPath<T>
  fontSize?: number
  control: Control<T>
  disabled?: boolean
}

export default function RadioControl<T extends FieldValues>({
  items,
  row = false,
  name,
  fontSize,
  control,
  disabled,
}: RadioControlProps<T>) {
  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field }) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <MuiRadioGroup row={row} {...field} value={field.value ?? ''}>
          {items.map((item) => (
            <MuiFormControlLabel
              key={String(item.value)}
              control={
                <MuiRadio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize,
                    },
                  }}
                />
              }
              value={item.value}
              label={item.label}
              disabled={disabled}
              sx={{
                fontSize,
              }}
            />
          ))}
        </MuiRadioGroup>
      )}
    />
  )
}

import { useCallback, useState } from 'react'
import FileDropField from '../../atoms/inputs/FileDropField'
import { FlexColumn } from '../../atoms/layout/Flex'
import { UploadIcon } from '../../atoms/display/Icons'
import Label from '../../atoms/display/Label'

type FileSelectFieldProps = {
  accepts: string[]
  maxFiles?: number
  maxSize?: number
  onSelect: (_files: File[]) => void
  onError?: () => void
}

export default function FileSelectField({ accepts, maxFiles, maxSize, onSelect, onError }: FileSelectFieldProps) {
  const [active, setActive] = useState(false)
  const handleActive = useCallback(
    (state: boolean) => {
      setActive(state)
    },
    [setActive]
  )

  return (
    <FileDropField
      accepts={accepts}
      maxFiles={maxFiles}
      onSelect={onSelect}
      onActive={handleActive}
      maxSize={maxSize}
      onError={onError}
    >
      <FlexColumn justify="center" align="center" height={320}>
        <UploadIcon size={64} color={active ? 'secondary' : 'primary'} />
        <Label text="ファイルをドロップしてください" />
      </FlexColumn>
    </FileDropField>
  )
}

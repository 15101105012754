import { ReactElement } from 'react'
import MuiListItem from '@mui/material/ListItem'
import MuiListItemButton from '@mui/material/ListItemButton'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import MuiListItemText from '@mui/material/ListItemText'
import { Link as RouterLink } from 'react-router-dom'
import { Component } from '../../../types/react'

type ListItemProps = {
  icon?: ReactElement
  label?: string
  href?: string
  disabled?: boolean
  children?: Component
  selected?: boolean
  onClick?: () => void
}

function Wrapper({
  href,
  disabled,
  onClick,
  children,
  selected,
}: {
  href?: string
  disabled?: boolean
  onClick?: () => void
  children: Component
  selected?: boolean
}) {
  if (href) {
    const external = href.startsWith('http') || href.startsWith('mailto:') || href.startsWith('tel:')
    const component = external ? 'a' : RouterLink
    const target = external ? '_blank' : '_self'
    return (
      <MuiListItem disablePadding>
        <MuiListItemButton component={component} href={href} target={target} selected={selected} disabled={disabled}>
          {children}
        </MuiListItemButton>
      </MuiListItem>
    )
  }
  if (onClick) {
    return (
      <MuiListItem disablePadding onClick={onClick}>
        <MuiListItemButton selected={selected} disabled={disabled}>
          {children}
        </MuiListItemButton>
      </MuiListItem>
    )
  }
  return <MuiListItem disablePadding>{children}</MuiListItem>
}

export default function ListItem({ icon, label, href, selected, disabled, children, onClick }: ListItemProps) {
  return (
    <Wrapper href={href} onClick={onClick} selected={selected} disabled={disabled}>
      {icon && <MuiListItemIcon>{icon}</MuiListItemIcon>}
      {label && <MuiListItemText inset={icon === undefined} primary={label} />}
      {children}
    </Wrapper>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import shortid from 'shortid'
import usePage from '../../hooks/usePage'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import Label from '../../components/atoms/display/Label'
import Card from '../../components/atoms/surfaces/Card'
import FileSelectField from '../../components/molecules/inputs/FileSelectField'
import FileSelectButton from '../../components/atoms/inputs/FileSelectButton'
import { ImportTransactionParamsData, ToolsActions } from '../../store/tools'
import useTransactionCsv, { TransactionCsvImportRecord } from '../../hooks/useTransactionCsv'
import useConsts from '../../hooks/useConsts'
import { MoneyspacesSelectors } from '../../store/moneyspaces/selectors'
import useValidationRule from '../../hooks/useValidationRule'
import { Moneyspace } from '../../types/Moneyspace'
import {
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionPhaseDelivered,
  TransactionPhaseEFQ,
  TransactionPhaseOrder,
  TransactionPhaseOrderConfirm,
  TransactionPhaseQuotation,
} from '../../types/transaction'
import Form from '../../components/atoms/inputs/Form'
import FormItem from '../../components/molecules/inputs/FormItem'
import CheckboxControl from '../../components/atoms/inputs/CheckboxControl'
import Button from '../../components/atoms/inputs/Button'
import TransactionImportSettingDialog from '../../components/organisms/transaction/TransactionImportSettingDialog'
import { CompanySelectors } from '../../store/company/selectors'
import { CsvImportSetting } from '../../types/Company'
import { disabledAddButton } from '../../components/organisms/moneyspace/MoneyspaceTabProcessingView'
import Alert from '../../components/atoms/feedback/Alert'
import RadioControl from '../../components/atoms/inputs/RadioControl'
import useProcessing from '../../hooks/useProcessing'
import { SessionSelectors } from '../../store/session/selectors'
import { User } from '../../types/User'

type CsvImportParams = {
  phase1: boolean
  phase2: boolean
  phase3: boolean
  phase4: boolean
  phase5: boolean
  phase6: boolean
  phase7: boolean
  proceedToReviewed: 1 | 2
  fixFollowingPhase: boolean
}

type CsvLoadError = {
  lineNumber: number
  type: 'error' | 'warning'
  message: string
}

function isBlank(value: string | null | undefined) {
  if (value == null) {
    return true
  }
  return value.trim() === ''
}

function isDate(value: string | null | undefined) {
  if (value == null) {
    return true
  }
  if (isBlank(value)) {
    return false
  }
  const dateFormat = /^[0-9]{4}\/(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])$/
  return value.match(dateFormat) !== null
}

function transactionKey(record: TransactionCsvImportRecord, setting: CsvImportSetting) {
  switch (setting.bundlingKeyField) {
    case 'name':
      return record.transactionName
    case 'custom_prop_1':
      return record.customProp1
    case 'custom_prop_2':
      return record.customProp2
    case 'custom_prop_3':
      return record.customProp3
    case 'custom_prop_4':
      return record.customProp4
    default:
      return record.transactionName
  }
}

function trimString(result: TransactionCsvImportRecord) {
  // 最大文字数を確認し、超えていた場合は最大文字数で切る
  // - 取引名 35
  result.transactionName = result.transactionName?.substring(0, 35) ?? null
  // - 備考 200
  result.note = result.note?.substring(0, 200) ?? ''
  // - 現場住所 128
  result.siteAddress = result.siteAddress?.substring(0, 128) ?? ''
  // - 単位 6
  result.itemUnit = result.itemUnit?.substring(0, 6) ?? ''

  return result
}

function validateValueRange(result: TransactionCsvImportRecord, lineNumber: number, setting: CsvImportSetting) {
  const errors: CsvLoadError[] = []

  // - 単価 -500000000 〜 9900000000
  if (result.itemUnitPrice < -500000000 || result.itemUnitPrice > 9900000000) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.detailUnitPrice}は-500,000,000〜9,900,000,000の範囲内で入力してください`,
    })
  }
  // - 数量 -999 〜 99999
  if (result.itemQuantity < -999 || result.itemQuantity > 99999) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.detailQuantity}は-999〜99,999の範囲内で入力してください`,
    })
  }

  return errors
}

function validateFormat(result: TransactionCsvImportRecord, lineNumber: number, setting: CsvImportSetting) {
  const errors: CsvLoadError[] = []

  // - 税区分 1 or 2 or 3
  if (result.itemTaxBucket && ![1, 2, 3].includes(Number(result.itemTaxBucket))) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.detailTaxBucketId}は10%、10、軽減8%、8%、8、非課税のいずれかで入力してください`,
    })
  }
  // - 消費税の表示 1 or 2
  if (result.isTaxIn && ![1, 2].includes(Number(result.isTaxIn))) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.isTaxIn}は税込、税込み、税抜、税抜きのいずれかで入力してください`,
    })
  }
  // - 発行日 yyyy/mmy/dd
  if (!isDate(result.publishedAt)) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.publishedAt}はyyyy/mm/dd形式で入力してください`,
    })
  }
  // - 支払日 yyyy/mmy/dd
  if (!isDate(result.paymentDate)) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.paymentDate}はyyyy/mm/dd形式で入力してください`,
    })
  }
  // - 納入期間開始日 yyyy/mmy/dd
  if (!isDate(result.deliveryDateFrom)) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.deliveryDateFrom}はyyyy/mm/dd形式で入力してください`,
    })
  }
  // - 納入期間終了日 yyyy/mmy/dd
  if (!isDate(result.deliveryDateTo)) {
    errors.push({
      lineNumber,
      type: 'error',
      message: `${setting.deliveryDateTo}はyyyy/mm/dd形式で入力してください`,
    })
  }

  return errors
}

function validateBundlingKey(
  result: TransactionCsvImportRecord,
  lineNumber: number,
  setting: CsvImportSetting
): CsvLoadError | null {
  switch (setting.bundlingKeyField) {
    case 'name':
      if (isBlank(result.transactionName)) {
        return {
          lineNumber,
          type: 'error',
          message: `${setting.name}は必須項目です`,
        }
      }
      break
    case 'custom_prop_1':
      if (isBlank(result.customProp1)) {
        return {
          lineNumber,
          type: 'error',
          message: `${setting.customProp1}は必須項目です`,
        }
      }
      break
    case 'custom_prop_2':
      if (isBlank(result.customProp2)) {
        return {
          lineNumber,
          type: 'error',
          message: `${setting.customProp2}は必須項目です`,
        }
      }
      break
    case 'custom_prop_3':
      if (isBlank(result.customProp3)) {
        return {
          lineNumber,
          type: 'error',
          message: `${setting.customProp3}は必須項目です`,
        }
      }
      break
    case 'custom_prop_4':
      if (isBlank(result.customProp4)) {
        return {
          lineNumber,
          type: 'error',
          message: `${setting.customProp4}は必須項目です`,
        }
      }
      break
    default:
      return null
  }
  return null
}

function validateExternalId(
  result: TransactionCsvImportRecord,
  lineNumber: number,
  setting: CsvImportSetting
): CsvLoadError | null {
  if (isBlank(result.externalId)) {
    return {
      lineNumber,
      type: 'error',
      message: `${setting.externalId}は必須項目です`,
    }
  }
  return null
}

function canCreateTransaction(moneyspace: Moneyspace, user?: User) {
  return !disabledAddButton(moneyspace, user)
}

function validateMoneyspace(
  result: TransactionCsvImportRecord,
  lineNumber: number,
  moneyspaces: Moneyspace[],
  setting: CsvImportSetting,
  user?: User
): CsvLoadError | null {
  if (isBlank(result.externalId)) {
    return null
  }
  const moneyspace = moneyspaces.find((ms) => ms.externalId === result.externalId)
  if (moneyspace == null) {
    return {
      lineNumber,
      type: 'warning',
      message: `${setting.externalId} ${result?.externalId ?? ''} は存在しないため、取引は作成されません`,
    }
  }
  if (!canCreateTransaction(moneyspace, user)) {
    return {
      lineNumber,
      type: 'warning',
      message: `${setting.externalId} ${result?.externalId ?? ''} は取引を作成できない状態です`,
    }
  }
  return null
}

export default function TransactionImporterPage() {
  const { changeTitle } = usePage()
  const [csvError, setCsvError] = useState<string | undefined>()
  const [results, setResults] = useState<TransactionCsvImportRecord[]>()
  const [csvLoadErrors, setCsvLoadErrors] = useState<CsvLoadError[]>([])
  const [openSetting, setOpenSetting] = useState<boolean>(false)
  const { processing, startProcess } = useProcessing(ToolsActions.importTransactions.typePrefix)
  const maxFileSize = 1024 * 1024
  const { load } = useTransactionCsv()
  const { phaseName } = useConsts()
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const csvImportSetting = useSelector(CompanySelectors.csvImportSetting)
  const user = useSelector(SessionSelectors.user)
  const dispatch = useDispatch()
  const rules = useValidationRule()
  const schema = rules.csvImport
  const form = useForm<CsvImportParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      phase1: false,
      phase2: false,
      phase3: false,
      phase4: false,
      phase5: false,
      phase6: false,
      phase7: false,
      proceedToReviewed: 1,
      fixFollowingPhase: false,
    },
  })
  const { errors } = form.formState
  const testError = (errors as { [key: string]: { message: string } })['']

  useEffect(() => {
    changeTitle('CSVインポート')
  }, [changeTitle])

  useEffect(() => {
    const loadErrors: CsvLoadError[] = []
    results?.map((result, index) => {
      // 最大文字数を確認し、超えていた場合は最大文字数で切る
      result = trimString(result)

      // 最大値、最小値を確認し、範囲外はばエラー
      loadErrors.push(...validateValueRange(result, index + 1, csvImportSetting))

      // 入力形式を確認し、形式が違っていた場合はエラー
      loadErrors.push(...validateFormat(result, index + 1, csvImportSetting))

      // 取引のキーの値がない場合はエラー
      const bundlingKeyError = validateBundlingKey(result, index + 1, csvImportSetting)
      if (bundlingKeyError) {
        loadErrors.push(bundlingKeyError)
      }

      // 仕入先コードの値がない場合はエラー
      const externalIdError = validateExternalId(result, index + 1, csvImportSetting)
      if (externalIdError) {
        loadErrors.push(externalIdError)
      }

      // 仕入先コードが存在しないコードの場合は警告、インポート対象から外す
      // 仕入先コード対象のMSが取引を作成できない場合は警告、インポート対象から外す
      const moneyspaceError = validateMoneyspace(result, index + 1, moneyspaces, csvImportSetting, user)
      if (moneyspaceError) {
        loadErrors.push(moneyspaceError)
      }

      return result
    })
    setCsvLoadErrors(loadErrors)
  }, [csvImportSetting, moneyspaces, results, user])

  const handleFileError = useCallback(() => {
    setCsvError('1MB以下のCSVファイルを選択してください')
  }, [setCsvError])
  const handleFileSelect = useCallback(
    (files: File[]) => {
      setCsvError(undefined)
      setResults(undefined)
      const file = files[0]
      if (file.size > maxFileSize) {
        setCsvError('1MB以下のCSVファイルを選択してください')
        return
      }
      load(file, (records) => setResults(records))
    },
    [load, maxFileSize]
  )

  const handleImport = useCallback(
    (params: CsvImportParams) => {
      startProcess()
      const externalIdMoneyspaces: { [key: string]: Moneyspace } = {}
      moneyspaces.forEach((moneyspace) => {
        if (moneyspace.externalId) {
          externalIdMoneyspaces[moneyspace.externalId] = moneyspace
        }
      })
      const data: ImportTransactionParamsData[] = []
      results?.forEach((result) => {
        const key = transactionKey(result, csvImportSetting)
        if (!result.externalId || !key) {
          return
        }
        const moneyspace = externalIdMoneyspaces[result.externalId]
        if (moneyspace) {
          data.push({
            msId: moneyspace.id,
            externalId: result.externalId,
            name: result.transactionName ?? '',
            isTaxIn: result.isTaxIn === 1,
            publishedAt: result.publishedAt,
            note: result.note,
            paymentDate: result.paymentDate,
            closingDate: result.closingDate,
            deliveryDateFrom: result.deliveryDateFrom,
            deliveryDateTo: result.deliveryDateTo,
            picId: result.picId,
            picSubId: result.picSubId,
            siteAddress: result.siteAddress,
            customProp1: result.customProp1,
            customProp2: result.customProp2,
            customProp3: result.customProp3,
            customProp4: result.customProp4,
            details: [
              {
                defaultProp: result.itemDefaultProp,
                customProp1: result.itemCustomProp1,
                customProp2: result.itemCustomProp2,
                unitPrice: result.itemUnitPrice,
                quantity: result.itemQuantity,
                unit: result.itemUnit,
                taxBucket: result.itemTaxBucket ? result.itemTaxBucket : 1,
                amount: 0,
              },
            ],
          })
        }
      })
      const phases = []
      if (params.phase1) {
        phases.push(TransactionPhaseEFQ)
      }
      if (params.phase2) {
        phases.push(TransactionPhaseQuotation)
      }
      if (params.phase3) {
        phases.push(TransactionPhaseOrder)
      }
      if (params.phase4) {
        phases.push(TransactionPhaseOrderConfirm)
      }
      if (params.phase5) {
        phases.push(TransactionPhaseDelivered)
      }
      if (params.phase6) {
        phases.push(TransactionPhaseBillingDetail)
      }
      if (params.phase7) {
        phases.push(TransactionPhaseBilling)
      }
      dispatch(
        ToolsActions.importTransactions({
          proceedToReviewed: Number(params.proceedToReviewed) === 2,
          fixFollowingPhase: params.fixFollowingPhase,
          phases,
          data,
        })
      )
    },
    [dispatch, moneyspaces, results, csvImportSetting, startProcess]
  )

  return (
    <FlexColumn>
      {user?.role !== 3 && <Button onClick={() => setOpenSetting(true)}>インポート設定</Button>}
      <TransactionImportSettingDialog open={openSetting} onClose={() => setOpenSetting(false)} />
      <Card>
        <FlexColumn align="center">
          <FileSelectField
            accepts={['text/csv']}
            onSelect={handleFileSelect}
            maxFiles={1}
            maxSize={maxFileSize}
            onError={handleFileError}
          />
          <FileSelectButton accepts={['text/csv']} onSelect={handleFileSelect}>
            CSVファイルを選択する
          </FileSelectButton>
          {csvError && <Label text={csvError} color="error" variant="caption" />}
        </FlexColumn>
      </Card>
      <FlexColumn>
        {csvLoadErrors?.map((error) => (
          <Alert key={shortid()} severity={error.type} title={`${error.lineNumber}行目`} label={error.message} />
        ))}
      </FlexColumn>
      <Form onSubmit={form.handleSubmit(handleImport)}>
        <FlexColumn space={2}>
          <Label text="取引を以下の条件で一括インポートします" />
          <FormItem label="インポート後に使用するフェーズを選択してください">
            <FlexColumn space={0}>
              <Label text="いずれか必須" variant="caption" color="caution.main" />
              <FlexRow>
                <CheckboxControl
                  name="phase6"
                  control={form.control}
                  label={phaseName(TransactionPhaseBillingDetail)}
                />
                <CheckboxControl name="phase7" control={form.control} label={phaseName(TransactionPhaseBilling)} />
              </FlexRow>
            </FlexColumn>
            <FlexColumn space={0}>
              <Label text="任意" variant="caption" />
              <FlexRow>
                <CheckboxControl name="phase1" control={form.control} label={phaseName(TransactionPhaseEFQ)} />
                <CheckboxControl name="phase2" control={form.control} label={phaseName(TransactionPhaseQuotation)} />
                <CheckboxControl name="phase3" control={form.control} label={phaseName(TransactionPhaseOrder)} />
                <CheckboxControl name="phase4" control={form.control} label={phaseName(TransactionPhaseOrderConfirm)} />
                <CheckboxControl name="phase5" control={form.control} label={phaseName(TransactionPhaseDelivered)} />
              </FlexRow>
            </FlexColumn>
          </FormItem>
          <FormItem label="インポート後の取引の状態を選択してください">
            <RadioControl
              items={[
                { label: '下書き中', value: 1 },
                { label: '取引先へ提出', value: 2 },
              ]}
              name="proceedToReviewed"
              control={form.control}
              row
            />
          </FormItem>
          <CheckboxControl
            name="fixFollowingPhase"
            control={form.control}
            label="最初のフェーズが完了後、次のフェーズの明細を編集不可にする"
          />
        </FlexColumn>
        <FlexColumn>
          {testError && <Label text={testError.message} color="error" variant="caption" />}
          <Button
            type="submit"
            disabled={
              results === undefined || csvLoadErrors.find((error) => error.type === 'error') != null || processing
            }
          >
            インポート
          </Button>
        </FlexColumn>
      </Form>
    </FlexColumn>
  )
}

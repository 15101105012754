import AppBar from '../atoms/surfaces/AppBar'
import { FlexRow } from '../atoms/layout/Flex'
import ServiceLogo from '../molecules/display/ServiceLogo'
import IconButton from '../atoms/inputs/IconButton'
import { BackIcon } from '../atoms/display/Icons'
import RelativeBox from '../atoms/layout/RelativeBox'
import AbsoluteBox from '../atoms/layout/AbsoluteBox'
import useSidebar from '../../hooks/useSidebar'
import { useDeviceType } from '../../hooks/useDeviceType'

type SiteHeaderProps = {
  size: 'large' | 'small'
  position: 'static' | 'fixed'
}

export default function SiteHeader({ size, position }: SiteHeaderProps) {
  const { close } = useSidebar()
  const { mobile } = useDeviceType()
  const width = size === 'large' ? 205 : 103
  const height = size === 'large' ? 48 : 24
  return (
    <AppBar position={position}>
      <RelativeBox>
        <FlexRow justify="center" my={2} grow>
          <ServiceLogo width={width} height={height} />
        </FlexRow>
        {mobile && (
          <AbsoluteBox right={8} top={12}>
            <IconButton label="閉じる" onClick={close}>
              <BackIcon color="white" />
            </IconButton>
          </AbsoluteBox>
        )}
      </RelativeBox>
    </AppBar>
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type OfficialPositionFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function OfficialPositionField({ register, error, disabled }: OfficialPositionFieldProps) {
  return <TextField register={register} placeholder="代表取締役" size="xs" error={error?.message} readonly={disabled} />
}

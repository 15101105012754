import { useSelector } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { useDocumentApproveDialog, useDocumentDiffDialog, useDocumentRejectDialog } from '../../../hooks/useDialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import DocumentDiffView from '../../molecules/display/DocumentDiffView'
import { approvalButtonLabel } from './DocumentButtons'
import { ChangeLog, Contract, Document, DocumentAttributes } from '../../../types/Document'
import { Moneyspace } from '../../../types/Moneyspace'
import { ApprovalFlowState } from '../../../types/ApprovalFlow'
import Button from '../../atoms/inputs/Button'
import usePermission from '../../../hooks/usePermission'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { SessionSelectors } from '../../../store/session/selectors'

type DocumentDiffDialogProps = {
  moneyspace: Moneyspace
  contract: Contract
  document: Document
  previousDocument: DocumentAttributes
  changeLogs: ChangeLog[]
  approvalFlowState?: ApprovalFlowState
}

export default function DocumentDiffDialog({
  moneyspace,
  contract,
  document,
  previousDocument,
  changeLogs,
  approvalFlowState,
}: DocumentDiffDialogProps) {
  const { state, close } = useDocumentDiffDialog()
  const { documentPermissions } = usePermission()
  const { isContractee } = useMoneyspace()
  const user = useSelector(SessionSelectors.user)
  const documentApproveDialog = useDocumentApproveDialog()
  const documentRejectDialog = useDocumentRejectDialog()
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="書類の明細差異を確認する"
      width="xl"
      actions={
        <FlexRow>
          {documentPermissions.approve(
            isContractee(moneyspace, user),
            document,
            moneyspace,
            user,
            approvalFlowState
          ) && (
            <Button onClick={() => documentApproveDialog.open(moneyspace.id, contract, document)}>
              {approvalButtonLabel(contract, document)}
            </Button>
          )}
          {documentPermissions.reject(
            isContractee(moneyspace, user),
            document,
            moneyspace,
            user,
            approvalFlowState
          ) && (
            <Button color="caution" onClick={() => documentRejectDialog.open(document)}>
              却下
            </Button>
          )}
        </FlexRow>
      }
    >
      <FlexColumn>
        <FlexRow>
          <Label text="二つの書類の明細差異を確認ください。" />
        </FlexRow>
        <FlexRow maxHeight={640}>
          <DocumentDiffView previousDocument={previousDocument} currentDocument={document} changeLogs={changeLogs} />
        </FlexRow>
      </FlexColumn>
    </Dialog>
  )
}

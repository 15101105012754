import { Control, FieldError } from 'react-hook-form'
import DatePicker from '../../atoms/inputs/DatePicker'

type PublishDateFieldProps = {
  name?: string
  control?: Control
  error?: FieldError
}

export default function PublishDateField({ name, control, error }: PublishDateFieldProps) {
  return <DatePicker control={control} name={name} error={error?.message} />
}

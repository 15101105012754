import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { MoneyspaceFolder } from '../../../types/Moneyspace'
import { useMoneyspaceGroupDeleteDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import useProcessing from '../../../hooks/useProcessing'

type MoneyspaceGroupDeleteDialogProps = {
  moneyspaceFolder: MoneyspaceFolder
}

export default function MoneyspaceGroupDeleteDialog({ moneyspaceFolder }: MoneyspaceGroupDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.deleteMoneyspaceFolder.typePrefix)
  const { state, close } = useMoneyspaceGroupDeleteDialog()
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    startProcess()
    dispatch(MoneyspacesActions.deleteMoneyspaceFolder({ id: moneyspaceFolder.id }))
  }, [dispatch, moneyspaceFolder.id, startProcess])

  return (
    <Dialog
      open={state !== undefined}
      title="フォルダの削除"
      actions={
        <Button color="caution" onClick={handleDelete} disabled={processing}>
          削除
        </Button>
      }
      onClose={close}
    >
      <FlexColumn space={4}>
        <FlexRow>
          <Label text="フォルダ名" />
          <Label text={moneyspaceFolder.name} />
        </FlexRow>
        <Label text="このフォルダを削除しますか？" />
      </FlexColumn>
    </Dialog>
  )
}

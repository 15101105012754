import MuiIconButton from '@mui/material/IconButton'
import MuiTooltip from '@mui/material/Tooltip'
import { ReactNode } from 'react'
import { Component } from '../../../types/react'
import { Color } from '../../../types/mui'

type ButtonType = 'button' | 'submit'
export type IconButtonSize = 'ss' | 'sm' | 'md' | 'lg' | 'xl'

export function sizeToPx(size?: IconButtonSize) {
  if (size === undefined) {
    return undefined
  }
  switch (size) {
    case 'ss':
      return 16
    case 'sm':
      return 24
    case 'md':
      return 40
    case 'lg':
      return 64
    case 'xl':
      return 128
    default:
      return 40
  }
}

type IconButtonProps = {
  type?: ButtonType
  label: string
  color?: Color
  disabled?: boolean
  size?: IconButtonSize
  children: Component
  onClick?: (_target: Element) => void
}

function Tooltip(props: { label: string; children: ReactNode }) {
  const { label, children } = props
  if (label.length > 0) {
    return (
      <MuiTooltip title={label}>
        <span>{children}</span>
      </MuiTooltip>
    )
  }
  return <>{children}</>
}

export default function IconButton({
  type = 'button',
  label,
  color,
  disabled = false,
  size,
  children,
  onClick,
}: IconButtonProps) {
  return (
    <Tooltip label={label}>
      <MuiIconButton
        type={type}
        aria-label={label}
        color={color}
        disabled={disabled}
        sx={{
          width: sizeToPx(size),
          height: sizeToPx(size),
        }}
        onClick={(e) => {
          e.stopPropagation()
          if (onClick) {
            onClick(e.target as Element)
          }
        }}
      >
        {children}
      </MuiIconButton>
    </Tooltip>
  )
}

import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import Form from '../../../components/atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import FormItem from '../../../components/molecules/inputs/FormItem'
import PostalCodeField from '../../../components/molecules/inputs/PostalCodeField'
import AddressField from '../../../components/molecules/inputs/AddressField'
import Label from '../../../components/atoms/display/Label'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import useRouter from '../../../hooks/useRouter'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import { CompanyActions } from '../../../store/company'
import { CompanySelectors } from '../../../store/company/selectors'

type AddressParams = {
  zipCode?: string
  address?: string
}

export default function RegisterContracteeAddressPage() {
  const company = useSelector(CompanySelectors.company)
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    zipCode: rules.zipCode.required(),
    address: rules.address.required(),
  })
  const form = useForm<AddressParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      zipCode: company?.zipCode,
      address: company?.address,
    },
  })
  const router = useRouter()
  const dispatch = useDispatch()

  const handleSearchAddress = useCallback(
    (address: string) => {
      form.setValue('address', address)
    },
    [form]
  )

  const handleNext = useCallback(
    (params: AddressParams) => {
      if (company) {
        dispatch(
          CompanyActions.saveCompany({
            id: company.id,
            company: {
              ...company,
              zipCode: params.zipCode,
              address: params.address,
            },
          })
        )
      }
      router.registerContracteePhone()
    },
    [dispatch, router, company]
  )

  return (
    <>
      <Form onSubmit={form.handleSubmit(handleNext)}>
        <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
          <FlexColumn align="center">
            <Label text="企業情報" variant="title" />
            <FlexColumn width="360px">
              <FormItem label="郵便番号">
                <PostalCodeField
                  register={form.register('zipCode')}
                  error={form.formState.errors.zipCode}
                  getValues={form.getValues}
                  onSearchAddress={handleSearchAddress}
                />
              </FormItem>
              <FormItem label="住所">
                <AddressField register={form.register('address')} error={form.formState.errors.address} size="md" />
              </FormItem>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn align="center">
            <Button type="submit" size="lg">
              次へ
            </Button>
            <FlexRow justify="center">
              <DotPagination count={11} current={6} />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </Form>
    </>
  )
}

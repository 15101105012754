import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import SearchField from '../../molecules/inputs/SearchField'
import { MoneyspacesSelectors } from '../../../store/moneyspaces/selectors'
import { Moneyspace } from '../../../types/Moneyspace'

type MoneyspaceSearchFormProps = {
  onSearch: (moneyspaces: Moneyspace[] | null) => void
}

export default function MoneyspaceSearchForm({ onSearch }: MoneyspaceSearchFormProps) {
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const handleChangeSearch = useCallback(
    (keyword?: string) => {
      if (keyword) {
        const results = moneyspaces.filter((moneyspace) => {
          if (moneyspace.name.includes(keyword)) {
            return true
          }
          if (moneyspace.contractee && moneyspace.contractee.name.includes(keyword)) {
            return true
          }
          if (moneyspace.contractor && moneyspace.contractor.name.includes(keyword)) {
            return true
          }
          return false
        })
        onSearch(results)
      } else {
        onSearch(null)
      }
    },
    [moneyspaces, onSearch]
  )

  return (
    <SearchField
      placeholder="検索キーワードを入力"
      helperText="マネースペース名・取引先名を入力"
      onChange={handleChangeSearch}
    />
  )
}

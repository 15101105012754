import { ReactNode } from 'react'
import Card from '../../atoms/surfaces/Card'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Badge from '../../atoms/display/Badge'
import Chip from '../../atoms/display/Chip'
import Label from '../../atoms/display/Label'

type DashboardCardProps = {
  label: string
  icon: ReactNode
  count: number
  hasNew?: boolean
  onClick?: () => void
}

export default function DashboardCard({ label, icon, count, hasNew, onClick }: DashboardCardProps) {
  return (
    <Card size="sm" onClick={onClick}>
      <FlexColumn>
        <FlexRow justify="flex-end" height={32}>
          {count > 0 && (
            <>
              {hasNew ? (
                <Badge label="" color="error" variant="dot">
                  <Chip label={count > 9 ? '9+' : count} />
                </Badge>
              ) : (
                <Chip label={count > 9 ? '9+' : count} />
              )}
            </>
          )}
        </FlexRow>
        <FlexColumn align="center">
          <>{icon}</>
          <Label text={label} />
        </FlexColumn>
      </FlexColumn>
    </Card>
  )
}

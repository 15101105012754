import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useMessageDeleteDialog, useMessageEditDialog, useReservedMessageDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import List from '../../atoms/display/List'
import { ChatActions } from '../../../store/chat'
import { ChatSelectors } from '../../../store/chat/selectors'
import ListItem from '../../atoms/display/ListItem'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import useMoneyspace from '../../../hooks/useMoneyspace'
import Label from '../../atoms/display/Label'
import LabelEllipsis from '../../atoms/display/LabelEllipsis'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon } from '../../atoms/display/Icons'
import useScheduleExpression from '../../../hooks/useScheduleExpression'
import { ReservedMessageSetting } from '../../../types/Chat'

const dayOfWeeks = ['', '日', '月', '火', '水', '木', '金', '土']

export function reservedMessageSettingToText(setting: ReservedMessageSetting) {
  if (setting.type === 'schedule') {
    return `予約日時 ${setting.date} ${setting.time}`
  }
  switch (setting.repeatType) {
    case 1:
      return `毎日 ${setting.repeatTime}`
    case 2:
      return `毎週 ${dayOfWeeks[setting.repeatDayOfWeek]}曜日 ${setting.repeatTime}`
    case 3:
      return `隔週 ${dayOfWeeks[setting.repeatDayOfWeek]}曜日 ${setting.repeatTime}`
    case 4:
      return `毎月 ${setting.repeatDayOfMonth}日 ${setting.repeatTime}`
    case 5:
      return `四半期ごと ${setting.repeatBaseDate} ${setting.repeatTime}`
    case 6:
      return `半年ごと ${setting.repeatBaseDate} ${setting.repeatTime}`
    case 7:
      return `毎年 ${setting.repeatBaseDate} ${setting.repeatTime}`
    default:
      return ''
  }
}

export default function ReservedMessageDialog() {
  const { state, close } = useReservedMessageDialog()
  const dispatch = useDispatch()
  const reservedMessages = useSelector(ChatSelectors.reservedMessages)
  const { find } = useMoneyspace()
  const { parse } = useScheduleExpression()
  const MessageEditDialog = useMessageEditDialog()
  const MessageDeleteDialog = useMessageDeleteDialog()

  useEffect(() => {
    dispatch(ChatActions.loadReservedMessages())
  }, [dispatch])

  return (
    <Dialog open={state !== undefined} onClose={close} title="送信予約一覧">
      <List>
        {reservedMessages.map((message) => (
          <ListItem key={message.id} onClick={() => MessageEditDialog.open(message)}>
            <FlexRow justify="space-between" grow>
              <FlexColumn space={0} scroll={false}>
                <FlexRow align="flex-end">
                  <Label text={find(message.msIds[0])?.name ?? ''} />
                  {message.msIds.length > 1 && <Label text={`他${message.msIds.length - 1}社`} variant="caption" />}
                </FlexRow>
                <Label text={reservedMessageSettingToText(parse(message.scheduleExpression))} variant="caption" />
                <LabelEllipsis text={message.description} width="440px" variant="caption" />
              </FlexColumn>
              <IconButton label="削除" onClick={() => MessageDeleteDialog.open(message)}>
                <DeleteIcon />
              </IconButton>
            </FlexRow>
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

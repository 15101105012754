import { useEffect } from 'react'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import { ErrorIcon } from '../../components/atoms/display/Icons'
import Label from '../../components/atoms/display/Label'
import Link from '../../components/atoms/navigation/Link'
import Button from '../../components/atoms/inputs/Button'
import useRouter from '../../hooks/useRouter'
import Card from '../../components/atoms/surfaces/Card'
import usePage from '../../hooks/usePage'

export default function Error500Page() {
  const { root } = useRouter()
  const { changeTitle } = usePage()
  useEffect(() => {
    changeTitle('')
  }, [changeTitle])
  return (
    <FlexRow>
      <ErrorIcon size={64} />
      <FlexColumn space={2}>
        <FlexColumn>
          <Label text="エラーが発生しました" variant="title" />
          <Label text="お手数ですが、管理者にお問い合わせください。" variant="subtitle" />
        </FlexColumn>
        <Card>
          <FlexColumn align="center">
            <Label text="【monect 問い合わせ窓口】" />
            <Link href="mailto:info@invest-d.com">info@invest-d.com</Link>
            <Label text="TEL: 050-3000-0768（受付時間10:00～18:00）" />
          </FlexColumn>
        </Card>
        <Button variant="outlined" onClick={root} size="lg">
          ダッシュボードへ戻る
        </Button>
      </FlexColumn>
    </FlexRow>
  )
}

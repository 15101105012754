import { Control, FieldPath, FieldValues } from 'react-hook-form'
import useConsts from '../../../hooks/useConsts'
import RadioControl from '../../atoms/inputs/RadioControl'
import { TransactionStatus } from '../../../types/transaction'

type DocumentStatusSingleSelectProps<T extends FieldValues> = {
  name: FieldPath<T>
  control: Control<T>
}

export default function PhaseStatusSingleSelect<T extends FieldValues>({
  name,
  control,
}: DocumentStatusSingleSelectProps<T>) {
  const { transactionStatus } = useConsts()
  const items: { value: TransactionStatus | 0; label: string }[] = transactionStatus.map((status) => ({
    value: status.id,
    label: status.name,
  }))
  items.unshift({ value: 0, label: '全て' })
  return <RadioControl<T> items={items} name={name} control={control} row />
}

import { Control } from 'react-hook-form'
import Select from '../../atoms/inputs/Select'
import FormItem from './FormItem'
import Label from '../../atoms/display/Label'
import { FlexColumn } from '../../atoms/layout/Flex'

type FiscalPeriodSelectProps = {
  name: string
  control: Control
  disabled?: boolean
}

export default function FiscalPeriodSelect({ name, control, disabled }: FiscalPeriodSelectProps) {
  const items = [
    { value: 1, label: '1月末' },
    { value: 2, label: '2月末' },
    { value: 3, label: '3月末' },
    { value: 4, label: '4月末' },
    { value: 5, label: '5月末' },
    { value: 6, label: '6月末' },
    { value: 7, label: '7月末' },
    { value: 8, label: '8月末' },
    { value: 9, label: '9月末' },
    { value: 10, label: '10月末' },
    { value: 11, label: '11月末' },
    { value: 12, label: '12月末' },
  ]
  return (
    <FormItem label="決算月">
      <FlexColumn space={0}>
        <Select items={items} name={name} control={control} size="xs" disabled={disabled} />
        <Label text="個人事業主の方は12月末を選択ください" variant="caption" />
      </FlexColumn>
    </FormItem>
  )
}

import { useCallback } from 'react'
import { Document } from '../../../types/Document'
import { useDocumentRejectDialog } from '../../../hooks/useDialog'
import useDocument from '../../../hooks/useDocument'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import { FlexColumn } from '../../atoms/layout/Flex'
import TextField from '../../atoms/inputs/TextField'
import FormItem from '../../molecules/inputs/FormItem'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useProcessing from '../../../hooks/useProcessing'
import { DocumentsActions } from '../../../store/documents'

type DocumentRejectDialogProps = {
  document: Document
}

type RejectParams = {
  note?: string
}

export default function DocumentRejectDialog({ document }: DocumentRejectDialogProps) {
  const { processing, startProcess } = useProcessing(DocumentsActions.rejectDocument.typePrefix)
  const { state, close } = useDocumentRejectDialog()
  const { rejectDocument } = useDocument()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    memo: rules.documentRejectMemo,
  })
  const { handleSubmit, register } = useForm<RejectParams>(schema)
  const handleReject = useCallback(
    (params: RejectParams) => {
      startProcess()
      rejectDocument(document.id, params.note)
    },
    [startProcess, rejectDocument, document.id]
  )
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="書類を差し戻す"
      actions={
        <Button type="submit" disabled={processing}>
          差し戻す
        </Button>
      }
      onSubmit={handleSubmit(handleReject)}
    >
      <FlexColumn>
        <Label text="この書類を差し戻しますか？" />
        <FormItem label="却下理由">
          <TextField multiline rows={5} register={register('note')} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

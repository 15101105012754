import MuiAvatarGroup from '@mui/material/AvatarGroup'
import { Component } from '../../../types/react'

type AvatarGroupProps = {
  total: number
  children: Component
}

export default function AvatarGroup({ total, children }: AvatarGroupProps) {
  return (
    <MuiAvatarGroup total={total} max={total > 1 ? total : undefined}>
      {children}
    </MuiAvatarGroup>
  )
}

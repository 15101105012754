import { useCallback } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'
import { SearchIcon } from '../../atoms/display/Icons'
import IconButton from '../../atoms/inputs/IconButton'

type SearchFieldProps = {
  placeholder?: string
  helperText?: string
  onChange?: (keyword?: string) => void
  onSearch?: () => void
  register?: UseFormRegisterReturn
}

export default function SearchField({
  placeholder = '検索キーワードを入力してください',
  helperText,
  onChange,
  onSearch,
  register,
}: SearchFieldProps) {
  const handleSearch = useCallback(() => {
    if (onSearch) {
      onSearch()
    }
  }, [onSearch])
  return (
    <TextField
      placeholder={placeholder}
      suffix={
        <IconButton label="検索" onClick={handleSearch}>
          <SearchIcon />
        </IconButton>
      }
      helperText={helperText}
      onChange={onChange}
      register={register}
    />
  )
}

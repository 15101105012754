import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import BankAccountTypeField from '../../molecules/inputs/BankAccountTypeField'
import BankAccountNumberField from '../../molecules/inputs/BankAccountNumberField'
import BankAccountNameField from '../../molecules/inputs/BankAccountNameField'
import PayAdjustmentTimingField from '../../molecules/inputs/PayAdjustmentTimingField'
import BankCodeField from '../../molecules/inputs/BankCodeField'
import BankNameField from '../../molecules/inputs/BankNameField'
import BankBranchOfficeCodeField from '../../molecules/inputs/BankBranchOfficeCodeField'
import BankBranchOfficeNameField from '../../molecules/inputs/BankBranchOfficeNameField'
import Form from '../../atoms/inputs/Form'
import Alert from '../../atoms/feedback/Alert'
import Button from '../../atoms/inputs/Button'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { CompanyActions } from '../../../store/company'
import { CompanySelectors } from '../../../store/company/selectors'
import useProcessing from '../../../hooks/useProcessing'
import { SessionSelectors } from '../../../store/session/selectors'
import usePermission from '../../../hooks/usePermission'

type BankAccountParams = {
  bankName?: string
  bankCode?: string
  branchName?: string
  branchCode?: string
  accountType: number
  accountNumber?: string
  accountOwner?: string
  payAfterHolidays: number
}

export default function CompanyTabItemBankAccount() {
  const { processing, startProcess } = useProcessing(CompanyActions.saveCompany.typePrefix)
  const company = useSelector(CompanySelectors.company)
  const user = useSelector(SessionSelectors.user)
  const { companyPermissions } = usePermission()
  const disabled = !companyPermissions.edit(user)
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    bankName: rules.bankName,
    bankCode: rules.bankCode,
    branchName: rules.branchName,
    branchCode: rules.branchCode,
    accountNumber: rules.accountNumber,
    accountOwner: rules.accountOwner,
  })
  const { handleSubmit, register, control, errors } = useForm<BankAccountParams>(schema, {
    ...company,
    payAfterHolidays: company?.payAfterHolidays ? 1 : 0,
  })
  const handleSave = useCallback(
    (values: BankAccountParams) => {
      if (company) {
        startProcess()
        dispatch(
          CompanyActions.saveCompany({
            id: company.id,
            company: {
              ...company,
              ...values,
              payAfterHolidays: values.payAfterHolidays === 1,
            },
          })
        )
      }
    },
    [company, startProcess, dispatch]
  )
  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FlexColumn>
        <FlexRow wrap>
          <FormItem label="銀行コード">
            <BankCodeField register={register('bankCode')} error={errors.bankCode} disabled={disabled} />
          </FormItem>
          <FormItem label="銀行名">
            <BankNameField register={register('bankName')} error={errors.bankName} disabled={disabled} />
          </FormItem>
        </FlexRow>
        <FlexRow wrap>
          <FormItem label="支店コード">
            <BankBranchOfficeCodeField
              register={register('branchCode')}
              error={errors.branchCode}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="支店名">
            <BankBranchOfficeNameField
              register={register('branchName')}
              error={errors.branchName}
              disabled={disabled}
            />
          </FormItem>
        </FlexRow>
        <FlexRow wrap>
          <FormItem label="口座種別">
            <BankAccountTypeField name="accountType" control={control} disabled={disabled} />
          </FormItem>
          <FormItem label="口座番号">
            <BankAccountNumberField
              register={register('accountNumber')}
              error={errors.accountNumber}
              disabled={disabled}
            />
          </FormItem>
          <FormItem label="口座名（半角カナ）">
            <BankAccountNameField register={register('accountOwner')} error={errors.accountOwner} disabled={disabled} />
          </FormItem>
        </FlexRow>
        <FlexRow>
          <FormItem label="支払日が休祝日の場合">
            <PayAdjustmentTimingField control={control} name="payAfterHolidays" disabled={disabled} />
          </FormItem>
        </FlexRow>
        <FlexColumn>
          <FlexRow>
            <Alert severity="warning" label="企業情報を保存すると下書き中の取引に情報が反映されます" />
          </FlexRow>
          <FlexRow>
            <Alert
              severity="warning"
              label="口座情報を変更しても取引先に通知されません。取引先にメッセージを送るなどして別途通知をしてください"
            />
          </FlexRow>
          <Button type="submit" disabled={disabled || processing}>
            保存
          </Button>
        </FlexColumn>
      </FlexColumn>
    </Form>
  )
}

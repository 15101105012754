import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useMoneyspaceGroupEditDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import Button from '../../atoms/inputs/Button'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import MoneyspaceGroupForm from './MoneyspaceGroupForm'
import { MoneyspaceFolder } from '../../../types/Moneyspace'
import useProcessing from '../../../hooks/useProcessing'

type MoneyspaceGroupEditParams = {
  name: string
}

type MoneyspaceGroupEditProps = {
  moneyspaceFolder: MoneyspaceFolder
}

export default function MoneyspaceGroupEditDialog({ moneyspaceFolder }: MoneyspaceGroupEditProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.saveMoneyspaceFolder.typePrefix)
  const { state, close } = useMoneyspaceGroupEditDialog()
  const yup = useYup()
  const dispatch = useDispatch()
  const schema = yup.object().shape({})
  const { handleSubmit, register, errors } = useForm<MoneyspaceGroupEditParams>(schema, moneyspaceFolder)
  const handleSave = useCallback(
    (values: MoneyspaceGroupEditParams) => {
      startProcess()
      dispatch(
        MoneyspacesActions.saveMoneyspaceFolder({
          id: moneyspaceFolder.id,
          name: values.name,
        })
      )
    },
    [dispatch, moneyspaceFolder.id, startProcess]
  )
  return (
    <Dialog
      open={state !== undefined}
      title="フォルダを編集する"
      actions={
        <Button type="submit" disabled={processing}>
          保存
        </Button>
      }
      onSubmit={handleSubmit(handleSave)}
      onClose={close}
    >
      <MoneyspaceGroupForm register={register} errors={errors} />
    </Dialog>
  )
}

import { useSelector } from 'react-redux'
import { FlexRow } from '../../atoms/layout/Flex'
import IconButton from '../../atoms/inputs/IconButton'
import { InfoIcon } from '../../atoms/display/Icons'
import Label from '../../atoms/display/Label'
import MoneyspaceClientView from './MoneyspaceClientView'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { useMoneyspaceViewDialog } from '../../../hooks/useDialog'
import { SessionSelectors } from '../../../store/session/selectors'

type MoneyspaceSettingViewProps = {
  moneyspaceId: string
}

export default function MoneyspaceSettingView({ moneyspaceId }: MoneyspaceSettingViewProps) {
  const { find } = useMoneyspace()
  const moneyspace = find(moneyspaceId)
  const moneyspaceViewDialog = useMoneyspaceViewDialog()
  const companyId = useSelector(SessionSelectors.companyId)

  if (moneyspace) {
    return (
      <FlexRow align="center">
        <IconButton label="詳細" onClick={() => moneyspaceViewDialog.open(moneyspace)}>
          <InfoIcon />
        </IconButton>
        <FlexRow space={4} wrap align="center">
          <FlexRow align="center">
            <Label text="発注者" />
            <MoneyspaceClientView
              client={moneyspace.client}
              company={moneyspace.contractee}
              moneyspace={moneyspace}
              editableMember={moneyspace.contractee?.id === companyId}
            />
          </FlexRow>
          <FlexRow align="center">
            <Label text="受注者" />
            <MoneyspaceClientView
              client={moneyspace.client}
              company={moneyspace.contractor}
              moneyspace={moneyspace}
              editableMember={moneyspace.contractor?.id === companyId}
            />
          </FlexRow>
        </FlexRow>
      </FlexRow>
    )
  }
  return <></>
}

import MuiCheckbox from '@mui/material/Checkbox'
import MuiFormControlLabel from '@mui/material/FormControlLabel'
import { Control, Controller } from 'react-hook-form'
import { ChangeEvent, useCallback } from 'react'

type CheckboxProps = {
  label?: string
  name?: string
  control?: Control
  indeterminate?: boolean
  checked?: boolean
  disabled?: boolean
  onChange?: (checked: boolean) => void
}

export default function Checkbox({
  label,
  name,
  control,
  indeterminate,
  checked = false,
  disabled,
  onChange,
}: CheckboxProps) {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: boolean) => {
      if (onChange) {
        e.stopPropagation()
        onChange(value)
      }
    },
    [onChange]
  )
  if (name && control) {
    return (
      <MuiFormControlLabel
        control={
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              <MuiCheckbox {...field} value={field.value} defaultChecked={field.value} disabled={disabled} />
            )}
          />
        }
        label={label ?? ''}
      />
    )
  }
  return (
    <MuiFormControlLabel
      control={
        <MuiCheckbox
          indeterminate={indeterminate}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        />
      }
      label={label ?? ''}
    />
  )
}

import React, { useCallback, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { DeleteIcon, PlusIcon } from '../../atoms/display/Icons'
import Label from '../../atoms/display/Label'
import NumberControl from '../../atoms/inputs/NumberControl'
import IconButton from '../../atoms/inputs/IconButton'
import { MembershipFeeConditionParams, MembershipFeeParams } from './CompanyTabItemMembership'
import { SessionSelectors } from '../../../store/session/selectors'
import usePermission from '../../../hooks/usePermission'

function conditionAmountError(index: number, conditions: MembershipFeeConditionParams[]) {
  if (index === 0) {
    return undefined
  }
  if (conditions[index].upperAmount < conditions[index - 1].upperAmount + 2) {
    return `${conditions[index - 1].upperAmount + 2}以上を入力してください`
  }
  return undefined
}

export default function CompanyMembershipRangeFee() {
  const user = useSelector(SessionSelectors.user)
  const { companyPermissions } = usePermission()
  const disabled = !companyPermissions.edit(user)
  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext<MembershipFeeParams>()
  const { fields, insert, remove } = useFieldArray({ control, name: 'conditions' })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reset, setReset] = useState(false)
  useEffect(() => {
    watch((params, { name }) => {
      if ((name && name.startsWith('conditions')) || name === 'type') {
        setReset(true)
        setInterval(() => {
          setReset(false)
        }, 0)
      }
    })
  }, [watch])
  const handleClickAdd = useCallback(
    (index: number) => {
      if (index === 0) {
        const { amount } = getValues().conditions[0]
        insert(index, { upperAmount: amount, amount: 0, rate: 0 })
      } else {
        const { amount } = getValues().conditions[index - 1]
        insert(index, { upperAmount: amount, amount: 0, rate: 0 })
      }
    },
    [getValues, insert]
  )
  const handleClickRemove = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove]
  )
  return (
    <FlexColumn>
      <FlexColumn>
        {fields.map((field, index) => (
          <FlexRow key={field.id}>
            <IconButton
              label="行を追加"
              color="primary"
              disabled={disabled || fields.length >= 7}
              onClick={() => handleClickAdd(index)}
            >
              <PlusIcon />
            </IconButton>
            <FlexRow wrap>
              <FlexRow width={176} my={1} justify="flex-end">
                <Label
                  text={index > 0 ? getValues(`conditions.${index - 1}.upperAmount`) + 1 : 0}
                  format="amountNoStyle"
                />
                <Label text="円以上" />
              </FlexRow>
              <FlexRow width={176}>
                {fields.length - 1 > index && (
                  <NumberControl<MembershipFeeParams>
                    control={control}
                    name={`conditions.${index}.upperAmount`}
                    suffix="円以下"
                    type="amount"
                    error={
                      (errors.conditions && errors.conditions[index] && errors.conditions[index].amount?.message) ||
                      conditionAmountError(index, getValues().conditions)
                    }
                    disabled={disabled}
                  />
                )}
              </FlexRow>
              <FlexRow width={176}>
                {Number(getValues().type) === 1 ? (
                  <NumberControl<MembershipFeeParams>
                    control={control}
                    name={`conditions.${index}.amount`}
                    suffix="円"
                    type="amount"
                    error={errors.conditions && errors.conditions[index] && errors.conditions[index].amount?.message}
                    disabled={disabled}
                  />
                ) : (
                  <NumberControl<MembershipFeeParams>
                    control={control}
                    name={`conditions.${index}.rate`}
                    suffix="%"
                    type="amount"
                    error={errors.conditions && errors.conditions[index] && errors.conditions[index].rate?.message}
                    disabled={disabled}
                  />
                )}
              </FlexRow>
              {fields.length - 1 > index && (
                <IconButton
                  label="行を削除"
                  color="caution"
                  onClick={() => handleClickRemove(index)}
                  disabled={disabled}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </FlexRow>
          </FlexRow>
        ))}
      </FlexColumn>
    </FlexColumn>
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import { useTransactionCreateDialog } from '../../../hooks/useDialog'
import ContractNameField from '../../molecules/inputs/ContractNameField'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import { TransactionActions } from '../../../store/transactions'
import useProcessing from '../../../hooks/useProcessing'
import CheckboxControl from '../../atoms/inputs/CheckboxControl'
import useConsts from '../../../hooks/useConsts'
import Label from '../../atoms/display/Label'
import {
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionPhaseDelivered,
  TransactionPhaseEFQ,
  TransactionPhaseOrder,
  TransactionPhaseOrderConfirm,
  TransactionPhaseQuotation,
} from '../../../types/transaction'
import usePath from '../../../hooks/usePath'

type TransactionCreateDialogProps = {
  moneyspaceId: string
}

type TransactionCreateParams = {
  name: string
  optionalPhases: {
    phase1: boolean
    phase2: boolean
    phase3: boolean
    phase4: boolean
    phase5: boolean
  }
  requiredPhases: {
    phase6: boolean
    phase7: boolean
  }
  fixFollowingPhase: boolean
}

export default function TransactionCreateDialog({ moneyspaceId }: TransactionCreateDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.createTransaction.typePrefix)
  const { state, close } = useTransactionCreateDialog()
  const { phaseName } = useConsts()
  const { transaction: transactionPage } = usePath()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.transactionName.required(),
    requiredPhases: yup
      .object()
      .test('TransactionRequiredPhase', '1つ以上選択してください', (item) => item.phase6 || item.phase7),
  })
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<TransactionCreateParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const handleCreate = useCallback(
    (values: TransactionCreateParams) => {
      startProcess()
      dispatch(
        TransactionActions.createTransaction({
          moneyspaceId,
          name: values.name,
          ...values.optionalPhases,
          ...values.requiredPhases,
          fixFollowingPhase: values.fixFollowingPhase,
          getRedirectPath: (transaction) => transactionPage(transaction.id),
        })
      )
    },
    [dispatch, moneyspaceId, startProcess, transactionPage]
  )
  return (
    <Dialog
      open={state !== undefined}
      title="取引を作成する"
      actions={
        <Button type="submit" disabled={processing}>
          作成
        </Button>
      }
      onSubmit={handleSubmit(handleCreate)}
      onClose={close}
    >
      <FlexColumn>
        <FormItem label="取引名" required>
          <ContractNameField register={register('name')} error={errors.name} />
        </FormItem>
        <FormItem label="この取引で使用するフェーズを選択してください">
          <FlexColumn space={2}>
            <FlexColumn space={0}>
              <Label text="いずれか必須" variant="caption" color="caution.main" />
              <FlexRow>
                <CheckboxControl
                  name="requiredPhases.phase6"
                  control={control}
                  label={phaseName(TransactionPhaseBillingDetail)}
                />
                <CheckboxControl
                  name="requiredPhases.phase7"
                  control={control}
                  label={phaseName(TransactionPhaseBilling)}
                />
              </FlexRow>
              {errors.requiredPhases && (
                <Label text={(errors.requiredPhases as { message: string }).message} color="error" variant="caption" />
              )}
            </FlexColumn>
            <FlexColumn space={0}>
              <Label text="任意" variant="caption" />
              <FlexRow>
                <CheckboxControl
                  name="optionalPhases.phase1"
                  control={control}
                  label={phaseName(TransactionPhaseEFQ)}
                />
                <CheckboxControl
                  name="optionalPhases.phase2"
                  control={control}
                  label={phaseName(TransactionPhaseQuotation)}
                />
                <CheckboxControl
                  name="optionalPhases.phase3"
                  control={control}
                  label={phaseName(TransactionPhaseOrder)}
                />
                <CheckboxControl
                  name="optionalPhases.phase4"
                  control={control}
                  label={phaseName(TransactionPhaseOrderConfirm)}
                />
                <CheckboxControl
                  name="optionalPhases.phase5"
                  control={control}
                  label={phaseName(TransactionPhaseDelivered)}
                />
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        </FormItem>
        <CheckboxControl
          name="fixFollowingPhase"
          control={control}
          label="最初のフェーズが完了後、次のフェーズの明細を編集不可にする"
        />
      </FlexColumn>
    </Dialog>
  )
}

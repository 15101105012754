import Table from '../../atoms/display/Table'
import TableCell from '../../atoms/display/TableCell'
import TableRow from '../../atoms/display/TableRow'
import { FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import TaxCategoryLabel from '../../molecules/display/TaxCategoryLabel'
import { TransactionDetail } from '../../../types/transaction'

type TransactionDetailTableProps = {
  details: TransactionDetail[]
}

export default function TransactionDetailTable({ details }: TransactionDetailTableProps) {
  return (
    <Table
      header={
        <TableRow>
          <TableCell header align="center">
            項目
          </TableCell>
          <TableCell header align="center" size="xs">
            単価
          </TableCell>
          <TableCell header align="center" size="xs2">
            数量
          </TableCell>
          <TableCell header align="center" size="xs2">
            単位
          </TableCell>
          <TableCell header align="center" size="xs2">
            税区分
          </TableCell>
          <TableCell header align="center" size="xs">
            金額
          </TableCell>
        </TableRow>
      }
    >
      {details.length === 0 && (
        <FlexRow justify="center">
          <Label text="明細が入力されていません" color="error" />
        </FlexRow>
      )}
      {details.map((detail) => (
        <TableRow key={detail.id}>
          <TableCell>
            <FlexRow wrap>
              <Label text={detail.defaultProp ?? ''} />
              <Label text={detail.customProp1 ?? ''} />
              <Label text={detail.customProp2 ?? ''} />
            </FlexRow>
          </TableCell>
          <TableCell align="right" size="sm3">
            <Label text={detail.unitPrice} format="amountWithUnit" />
          </TableCell>
          <TableCell align="right" size="xs2">
            <Label text={detail.quantity} />
          </TableCell>
          <TableCell align="center" size="xs2">
            <Label text={detail.unit} />
          </TableCell>
          <TableCell align="center" size="xs2">
            <TaxCategoryLabel category={detail.taxBucket} />
          </TableCell>
          <TableCell align="right" size="sm2">
            <Label text={detail.amount} format="amountWithUnit" />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

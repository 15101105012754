import { useForm } from 'react-hook-form'
import { useCallback, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import SelectControl from '../../atoms/inputs/SelectControl'
import { FlexRow } from '../../atoms/layout/Flex'
import { CalendarIcon } from '../../atoms/display/Icons'

export type Period = {
  fromDate: Dayjs
  toDate: Dayjs
}

type FormParams = {
  period: string
}

type Props = {
  onChange: (period: Period | null) => void
}

export default function PeriodSelect({ onChange }: Props) {
  const form = useForm<FormParams>({
    defaultValues: {
      period: '3Month',
    },
  })
  const { watch, setValue } = form

  const handleChangePeriods = useCallback(
    (period: string) => {
      if (period === '3Month') {
        const now = dayjs()
        onChange({
          fromDate: now.subtract(2, 'month').startOf('month'),
          toDate: now.endOf('month'),
        })
      }
      if (period === 'thisYear') {
        const now = dayjs()
        onChange({
          fromDate: now.month(0).startOf('month'),
          toDate: now.endOf('month'),
        })
      }
      if (period === 'lastYear') {
        const now = dayjs()
        const lastYear = now.subtract(1, 'year')
        onChange({
          fromDate: lastYear.month(0).startOf('month'),
          toDate: lastYear.month(11).endOf('month'),
        })
      }
      if (period === 'all') {
        onChange(null)
      }
    },
    [onChange]
  )

  useEffect(() => {
    watch((value) => {
      handleChangePeriods(value.period ?? '3Month')
    })
    setValue('period', '3Month')
  }, [watch, setValue]) // eslint-disable-line

  return (
    <FlexRow align="center" scroll={false}>
      <CalendarIcon />
      <SelectControl
        name="period"
        control={form.control}
        items={[
          { value: '3Month', label: '過去3ヶ月間' },
          { value: 'thisYear', label: '今年' },
          { value: 'lastYear', label: '前年' },
          { value: 'all', label: '全期間' },
        ]}
        size="sm"
      />
    </FlexRow>
  )
}

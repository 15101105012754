import { useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import SectionPanel from '../../molecules/SectionPanel'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import SearchField from '../../molecules/inputs/SearchField'
import NumberField from '../../atoms/inputs/NumberField'
import Label from '../../atoms/display/Label'
import DateControl from '../../atoms/inputs/DateControl'
import { BillingSearchParams } from '../../../types/search'
import Button from '../../atoms/inputs/Button'
import RadioControl from '../../atoms/inputs/RadioControl'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import NumberControl from '../../atoms/inputs/NumberControl'
import { TransactionActions } from '../../../store/transactions'

export default function BillingSearchForm() {
  const searching = useSelector(TransactionSelectors.searchingBillings)
  const dispatch = useDispatch()
  const { control, register, setValue } = useFormContext<BillingSearchParams>()

  const handleReset = useCallback(() => {
    dispatch(
      TransactionActions.saveSearchBillingParams({
        searchParams: {
          clientName: undefined,
          paymentDateFrom: undefined,
          paymentDateTo: undefined,
          closingDateFrom: undefined,
          closingDateTo: undefined,
          amountMin: null,
          amountMax: null,
          isPaid: 2,
          invoiceLessActionType: 2,
        },
      })
    )
    setValue('clientName', undefined)
    setValue('paymentDateFrom', undefined)
    setValue('paymentDateTo', undefined)
    setValue('closingDateFrom', undefined)
    setValue('closingDateTo', undefined)
    setValue('amountMin', null)
    setValue('amountMax', null)
    setValue('isPaid', 2)
  }, [dispatch, setValue])

  return (
    <SectionPanel title="検索条件" size="full">
      <FlexColumn>
        <FormItem label="取引先名">
          <SearchField register={register('clientName')} placeholder="" />
        </FormItem>
        <FlexRow wrap>
          <FormItem label="合計金額">
            {searching ? (
              <FlexRow align="center">
                <NumberField size="xs" type="amount" />
                <Label text="〜" />
                <NumberField size="xs" type="amount" />
              </FlexRow>
            ) : (
              <FlexRow align="center">
                <NumberControl name="amountMin" control={control} size="xs" type="amount" />
                <Label text="〜" />
                <NumberControl name="amountMax" control={control} size="xs" type="amount" />
              </FlexRow>
            )}
          </FormItem>
          <FormItem label="支払期限">
            <FlexRow align="center">
              <DateControl name="paymentDateFrom" control={control} />
              <Label text="〜" />
              <DateControl name="paymentDateTo" control={control} />
            </FlexRow>
          </FormItem>
          <FormItem label="締め日">
            <FlexRow align="center">
              <DateControl name="closingDateFrom" control={control} />
              <Label text="〜" />
              <DateControl name="closingDateTo" control={control} />
            </FlexRow>
          </FormItem>
        </FlexRow>
        <FormItem label="支払状態">
          <FlexRow>
            <RadioControl
              items={[
                { label: '全て', value: 2 },
                { label: '未払い', value: 0 },
                { label: '支払済', value: 1 },
              ]}
              name="isPaid"
              control={control}
              row
            />
          </FlexRow>
        </FormItem>
        <FormItem label="事業者登録番号の有無">
          <FlexRow>
            <RadioControl
              items={[
                { label: '全て', value: 2 },
                { label: 'あり', value: 1 },
                { label: 'なし', value: 0 },
              ]}
              name="invoiceLessActionType"
              control={control}
              row
            />
          </FlexRow>
        </FormItem>
        <FlexRow>
          <Button type="submit">検索</Button>
          <Button variant="outlined" onClick={handleReset}>
            リセット
          </Button>
        </FlexRow>
      </FlexColumn>
    </SectionPanel>
  )
}

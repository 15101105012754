import MuiButton from '@mui/material/Button'
import { MouseEvent, ReactElement, useCallback } from 'react'
import { Component } from '../../../types/react'

type ButtonType = 'button' | 'submit'
type ButtonColor = 'primary' | 'secondary' | 'caution'
type ButtonSize = 'sm' | 'md' | 'lg' | 'xl'
type ButtonHeight = 'small' | 'medium' | 'large'

type ButtonProps = {
  type?: ButtonType
  icon?: ReactElement
  variant?: 'text' | 'contained' | 'outlined'
  disabled?: boolean
  color?: ButtonColor
  size?: ButtonSize
  height?: ButtonHeight
  fullWidth?: boolean
  children: Component
  onClick?: (_target: EventTarget) => void
}

const width = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
      return 80
    case 'md':
      return 160
    case 'lg':
      return 240
    case 'xl':
      return 360
    default:
      return 160
  }
}

export default function Button({
  type = 'button',
  icon,
  variant = 'contained',
  disabled = false,
  color = 'primary',
  size = 'md',
  height = 'medium',
  fullWidth = false,
  children,
  onClick,
}: ButtonProps) {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      if (onClick) {
        onClick(e.target)
      }
    },
    [onClick]
  )
  return (
    <MuiButton
      type={type}
      startIcon={icon}
      variant={variant}
      disabled={disabled}
      color={color}
      disableElevation
      size={height}
      sx={{
        textTransform: 'none',
        width: fullWidth ? '100%' : width(size),
      }}
      onClick={handleClick}
    >
      {children}
    </MuiButton>
  )
}

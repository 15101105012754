import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useDispatch } from 'react-redux'
import { useCallback, useState } from 'react'
import useValidationRule from '../../../hooks/useValidationRule'
import useYup from '../../../hooks/useYup'
import useRouter from '../../../hooks/useRouter'
import Form from '../../../components/atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import FormItem from '../../../components/molecules/inputs/FormItem'
import TextField from '../../../components/atoms/inputs/TextField'
import Alert from '../../../components/atoms/feedback/Alert'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import { NGIcon, OKIcon } from '../../../components/atoms/display/Icons'
import ToggleButton from '../../../components/atoms/inputs/ToggleButton'
import registerSlice from '../../../store/register'

type CompanyNameParams = {
  name?: string
}

type PersonalNameParams = {
  name?: string
  lastName?: string
  firstName?: string
}

export default function RegisterContracteeNamePage() {
  const { registerContracteeTerms } = useRouter()
  const [type, setType] = useState<string>('company')
  const yup = useYup()
  const rules = useValidationRule()
  const companySchema = yup.object().shape({
    name: rules.companyName.required(),
  })
  const companyForm = useForm<CompanyNameParams>({
    resolver: yupResolver(companySchema),
    mode: 'onChange',
  })
  const personalSchema = yup.object().shape({
    lastName: yup.string().label('姓').max(64).nullable().required(),
    firstName: yup.string().label('名').max(64).nullable().required(),
  })
  const personalForm = useForm<PersonalNameParams>({
    resolver: yupResolver(personalSchema),
    mode: 'onChange',
  })
  const dispatch = useDispatch()

  const handleCompanyNext = useCallback(
    (params: CompanyNameParams) => {
      dispatch(
        registerSlice.actions.setContractee({
          name: params.name,
        })
      )
      registerContracteeTerms()
    },
    [dispatch, registerContracteeTerms]
  )

  const handlePersonalNext = useCallback(
    (params: PersonalNameParams) => {
      dispatch(
        registerSlice.actions.setContractee({
          // eslint-disable-next-line no-irregular-whitespace
          representativeName: `${params.lastName ?? ''}　${params.firstName ?? ''}`,
          name: params.name,
        })
      )
      registerContracteeTerms()
    },
    [dispatch, registerContracteeTerms]
  )
  return (
    <>
      <Form
        onSubmit={
          type === 'company'
            ? companyForm.handleSubmit(handleCompanyNext)
            : personalForm.handleSubmit(handlePersonalNext)
        }
      >
        <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
          <FlexColumn align="center">
            <Label text="アカウント登録" variant="title" />
            <FormItem label="法人/個人事業主を選択">
              <FlexRow width={240}>
                <ToggleButton
                  items={[
                    { value: 'company', content: '法人' },
                    { value: 'personal', content: '個人事業主' },
                  ]}
                  orientation="horizontal"
                  value={type}
                  onChange={setType}
                />
              </FlexRow>
            </FormItem>
            {type === 'company' ? (
              <>
                <FormItem label="企業名を入力">
                  <TextField
                    register={companyForm.register('name')}
                    error={companyForm.formState.errors.name?.message}
                    size="md"
                  />
                </FormItem>
                <FlexColumn width="360px">
                  <Alert severity="warning">
                    <FlexRow>スペースは入れずに入力してください</FlexRow>
                    <FlexRow>
                      <FlexColumn>例</FlexColumn>
                      <FlexColumn>
                        <FlexRow align="center">
                          <OKIcon size={16} /> 株式会社マネクト
                        </FlexRow>
                        <FlexRow align="center">
                          <NGIcon size={16} />
                          株式会社 マネクト
                        </FlexRow>
                      </FlexColumn>
                    </FlexRow>
                  </Alert>
                </FlexColumn>
              </>
            ) : (
              <FlexColumn>
                <FormItem label="代表者名を入力">
                  <FlexRow>
                    <TextField
                      register={personalForm.register('lastName')}
                      error={personalForm.formState.errors.lastName?.message}
                      size="xs"
                      placeholder="姓"
                    />
                    <TextField
                      register={personalForm.register('firstName')}
                      error={personalForm.formState.errors.firstName?.message}
                      size="xs"
                      placeholder="名"
                    />
                  </FlexRow>
                </FormItem>
                <FormItem label="屋号名">
                  <TextField
                    register={personalForm.register('name')}
                    error={personalForm.formState.errors.name?.message}
                    placeholder="屋号名"
                  />
                </FormItem>
              </FlexColumn>
            )}
          </FlexColumn>
          <FlexColumn align="center">
            <Button type="submit" size="lg">
              次へ
            </Button>
            <FlexRow justify="center">
              <DotPagination count={11} current={2} />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </Form>
    </>
  )
}

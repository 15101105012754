import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Fab from '../../atoms/inputs/Fab'
import { ChatIcon, CloseIcon } from '../../atoms/display/Icons'
import FixedBox from '../../atoms/layout/FixedBox'
import ChatWindow from './ChatWindow'
import { FlexColumn } from '../../atoms/layout/Flex'
import Badge from '../../atoms/display/Badge'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { ChatActions } from '../../../store/chat'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { Transaction } from '../../../types/transaction'

type ChatButtonProps = {}

function findMoneyspaceId(moneyspaceId?: string, transactionId?: string, transaction?: Transaction) {
  if (moneyspaceId) {
    return moneyspaceId
  }

  if (transactionId && transaction) {
    return transaction.moneyspace.id
  }
  return undefined
}

export default function ChatButton({}: ChatButtonProps) {
  const { moneyspaceId, transactionId } = useParams()
  const { find } = useMoneyspace()
  const transaction = useSelector(TransactionSelectors.transaction)
  const msId = findMoneyspaceId(moneyspaceId, transactionId, transaction)
  const moneyspace = find(msId)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (state: boolean) => {
      if (!state && msId !== undefined) {
        dispatch(ChatActions.readMessages({ moneyspaceId: msId }))
      }
      setOpen(state)
    },
    [dispatch, msId]
  )
  useEffect(
    () => () => {
      if (open && msId) {
        dispatch(ChatActions.readMessages({ moneyspaceId: msId }))
      }
    },
    [dispatch, msId, open]
  )
  useEffect(() => {
    setOpen(false)
  }, [msId])
  return (
    <>
      {msId && moneyspace && (
        <FixedBox bottom={0} right={0}>
          <FlexColumn align="flex-end" pa={2}>
            <ChatWindow moneyspaceId={msId} open={open} />
            <Badge
              label={open || (moneyspace.unreadMessageCount ?? 0) < 0 ? undefined : moneyspace.unreadMessageCount}
              color="secondary"
            >
              <Fab onClick={() => handleClick(!open)}>{open ? <CloseIcon /> : <ChatIcon />}</Fab>
            </Badge>
          </FlexColumn>
        </FixedBox>
      )}
    </>
  )
}

import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import FormItem from '../../molecules/inputs/FormItem'
import CheckboxControl from '../../atoms/inputs/CheckboxControl'
import TextField from '../../atoms/inputs/TextField'
import Button from '../../atoms/inputs/Button'
import Form from '../../atoms/inputs/Form'
import { CompanyActions } from '../../../store/company'
import { CompanySelectors } from '../../../store/company/selectors'
import Alert from '../../atoms/feedback/Alert'
import RadioControl from '../../atoms/inputs/RadioControl'
import { DetailPropConfig, TransactionPropConfig } from '../../../types/Company'

type TransactionConfigParams = {
  taxRoundingType: number
  detailPropConfig: DetailPropConfig
  transactionPropConfig: TransactionPropConfig
}

export default function CompanyTabTransactionSetting() {
  const company = useSelector(CompanySelectors.company)
  const { control, register, handleSubmit } = useForm<TransactionConfigParams>({
    defaultValues: {
      detailPropConfig: company?.detailPropConfig,
      taxRoundingType: company?.taxRoundingType ?? 1,
      transactionPropConfig: company?.transactionPropConfig,
    },
  })
  const dispatch = useDispatch()

  const handleSave = useCallback(
    (params: TransactionConfigParams) => {
      if (company) {
        dispatch(
          CompanyActions.saveTransactionConfig({
            companyId: company.id,
            taxRoundingType: params.taxRoundingType,
            detailPropConfig: params.detailPropConfig,
            transactionPropConfig: params.transactionPropConfig,
          })
        )
      }
    },
    [company, dispatch]
  )

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FlexColumn space={4}>
        <Label text="取引項目は発注者の設定内容が反映されます" variant="subtitle" />
        <FlexColumn>
          <Label text="取引項目の設定" />
          <FlexRow>
            <FormItem label="担当者の設定">
              <CheckboxControl name="detailPropConfig.requiredPic" control={control} label="必須" />
            </FormItem>
            <FormItem label="工事期間の設定">
              <CheckboxControl name="detailPropConfig.requiredDeliveryDate" control={control} label="必須" />
            </FormItem>
          </FlexRow>
          <FlexRow align="center">
            <FlexRow width={120}>
              <Label text="カスタム項目1" />
            </FlexRow>
            <TextField register={register('transactionPropConfig.customProp1.name')} placeholder="項目名" size="sm" />
            <CheckboxControl name="transactionPropConfig.customProp1.required" control={control} label="必須" />
          </FlexRow>
          <FlexRow align="center">
            <FlexRow width={120}>
              <Label text="カスタム項目2" />
            </FlexRow>
            <TextField register={register('transactionPropConfig.customProp2.name')} placeholder="項目名" size="sm" />
            <CheckboxControl name="transactionPropConfig.customProp2.required" control={control} label="必須" />
          </FlexRow>
          <FlexRow align="center">
            <FlexRow width={120}>
              <Label text="カスタム項目3" />
            </FlexRow>
            <TextField register={register('transactionPropConfig.customProp3.name')} placeholder="項目名" size="sm" />
            <CheckboxControl name="transactionPropConfig.customProp3.required" control={control} label="必須" />
          </FlexRow>
          <FlexRow align="center">
            <FlexRow width={120}>
              <Label text="カスタム項目4" />
            </FlexRow>
            <TextField register={register('transactionPropConfig.customProp4.name')} placeholder="項目名" size="sm" />
            <CheckboxControl name="transactionPropConfig.customProp4.required" control={control} label="必須" />
          </FlexRow>
        </FlexColumn>
        <FlexColumn>
          <Label text="明細項目の設定" />
          <FlexRow align="center">
            <FlexRow width={120}>
              <Label text="デフォルト" />
            </FlexRow>
            <TextField
              register={register('detailPropConfig.defaultProp.name')}
              placeholder="項目名"
              size="sm"
              readonly
            />
            <CheckboxControl name="detailPropConfig.defaultProp.required" control={control} label="必須" />
          </FlexRow>
          <FlexRow align="center">
            <FlexRow width={120}>
              <Label text="カスタム項目1" />
            </FlexRow>
            <TextField register={register('detailPropConfig.customProp1.name')} placeholder="項目名" size="sm" />
            <CheckboxControl name="detailPropConfig.customProp1.required" control={control} label="必須" />
          </FlexRow>
          <FlexRow align="center">
            <FlexRow width={120}>
              <Label text="カスタム項目2" />
            </FlexRow>
            <TextField register={register('detailPropConfig.customProp2.name')} placeholder="項目名" size="sm" />
            <CheckboxControl name="detailPropConfig.customProp2.required" control={control} label="必須" />
          </FlexRow>
          <FormItem label="消費税の小数点以下の端数処理">
            <RadioControl
              control={control}
              name="taxRoundingType"
              items={[
                { label: '切り捨て', value: 1 },
                { label: '切り上げ', value: 2 },
                { label: '四捨五入', value: 3 },
              ]}
              row
            />
          </FormItem>
        </FlexColumn>
        <FlexRow>
          <Alert
            severity="warning"
            title="作成済みのマネースペースには、変更内容は反映されません"
            label="設定保存後に新しくマネースペースを作成してください"
          />
        </FlexRow>
        <Button type="submit">保存</Button>
      </FlexColumn>
    </Form>
  )
}

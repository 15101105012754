import { Control, FieldError } from 'react-hook-form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import DatePicker from '../../atoms/inputs/DatePicker'
import { DocumentType } from '../../../types/Document'

type DocumentPaymentFormProps = {
  control: Control
  documentType: DocumentType
  errors?: { closingDate?: FieldError; paymentDate?: FieldError }
  disabled?: boolean
}

export default function DocumentPaymentForm({ control, documentType, errors, disabled }: DocumentPaymentFormProps) {
  return (
    <FlexColumn>
      <FlexRow wrap>
        {[6, 7].includes(documentType) && (
          <>
            <FormItem label="締め日" required>
              <DatePicker
                name="contractee.closingDate"
                control={control}
                error={errors?.closingDate?.message}
                disabled={disabled}
              />
            </FormItem>
            <FormItem label="支払期限" required>
              <DatePicker
                name="contractee.paymentDate"
                control={control}
                error={errors?.paymentDate?.message}
                disabled={disabled}
              />
            </FormItem>
          </>
        )}
      </FlexRow>
    </FlexColumn>
  )
}

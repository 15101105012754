import { ReactNode } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { Component } from '../../../types/react'

type FormItemProps = {
  label?: string
  required?: boolean
  icon?: ReactNode
  children: Component
}

export default function FormItem({ label, required, icon, children }: FormItemProps) {
  return (
    <FlexColumn space={0}>
      <FlexRow align="center">
        <FlexRow height={20}>
          <Label text={label ?? ''} variant="caption" />
        </FlexRow>
        {required && <Label text="*" color="error" variant="caption" />}
        {icon}
      </FlexRow>
      {children}
    </FlexColumn>
  )
}

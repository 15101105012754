import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Transaction, TransactionSearchResultItem } from '../../../types/transaction'
import { FavoriteIcon, FavoriteOutlinedIcon } from '../../atoms/display/Icons'
import IconButton from '../../atoms/inputs/IconButton'
import { TransactionActions } from '../../../store/transactions'

type Props = {
  transaction: Transaction | TransactionSearchResultItem
}

export default function TransactionFavoriteButton({ transaction }: Props) {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    if (transaction.isFavorite) {
      dispatch(TransactionActions.removeFavorite({ transactionId: transaction.id }))
    } else {
      dispatch(TransactionActions.addFavorite({ transactionId: transaction.id }))
    }
  }, [dispatch, transaction.id, transaction.isFavorite])

  return (
    <IconButton label="取引お気に入り" onClick={handleClick}>
      {transaction.isFavorite ? <FavoriteIcon /> : <FavoriteOutlinedIcon />}
    </IconButton>
  )
}

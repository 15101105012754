import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { MoneyspaceChatMessage } from '../../../types/Chat'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Badge from '../../atoms/display/Badge'
import Chip from '../../atoms/display/Chip'
import useDay from '../../../hooks/useDay'
import LabelEllipsis from '../../atoms/display/LabelEllipsis'
import ChatWindow from '../../organisms/chat/ChatWindow'
import FixedBox from '../../atoms/layout/FixedBox'
import { FlexColumn } from '../../atoms/layout/Flex'
import Fab from '../../atoms/inputs/Fab'
import { CloseIcon } from '../../atoms/display/Icons'
import { ChatActions } from '../../../store/chat'

type ChatMessageTableProps = {
  messages: MoneyspaceChatMessage[]
}

export default function ChatMessageTable({ messages }: ChatMessageTableProps) {
  const { formatDateTime } = useDay()
  const [selectedMoneyspaceId, setSelectedMoneyspaceId] = useState<string | undefined>()
  const dispatch = useDispatch()
  const handleClick = useCallback(
    (moneyspaceId: string) => {
      dispatch(ChatActions.readMessages({ moneyspaceId }))
      setSelectedMoneyspaceId(moneyspaceId)
    },
    [dispatch]
  )

  return (
    <>
      <Table>
        {messages.map((message) => (
          <TableRow key={message.id} onClick={() => handleClick(message.moneyspace.id)}>
            <TableCell size="xxs">
              {message.hasToMentioned ? (
                <Badge label="" variant="dot" color="error">
                  <Chip label={message.unreadCount} />
                </Badge>
              ) : (
                <Chip label={message.unreadCount} />
              )}
            </TableCell>
            <TableCell size="xs">{message.moneyspace.name}</TableCell>
            <TableCell size="xs">{message.moneyspace.clientName}</TableCell>
            <TableCell size="xs">{message.latestMessage.createdBy.name}</TableCell>
            <TableCell size="sm">
              <LabelEllipsis text={message.latestMessage.description} width="240px" />
            </TableCell>
            <TableCell size="xs">{formatDateTime(message.latestMessage.createdAt)}</TableCell>
          </TableRow>
        ))}
      </Table>
      {selectedMoneyspaceId && (
        <FixedBox bottom={0} right={0} zIndex={1000}>
          <FlexColumn align="flex-end" pa={2}>
            <ChatWindow moneyspaceId={selectedMoneyspaceId} open showMoneyspaceButton />
            <Fab onClick={() => setSelectedMoneyspaceId(undefined)}>
              <CloseIcon />
            </Fab>
          </FlexColumn>
        </FixedBox>
      )}
    </>
  )
}

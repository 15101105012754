import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell, { CellSize } from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import useConsts from '../../../hooks/useConsts'
import { PhaseChangeLog, Transaction } from '../../../types/transaction'
import useMoneyspace from '../../../hooks/useMoneyspace'

type TransactionDiffViewProps = {
  previousPhase: Transaction
  currentPhase: Transaction
  changeLogs: PhaseChangeLog[]
}

function HeaderCell(props: { size: CellSize; text: string }) {
  const { size, text } = props
  return (
    <TableCell header px={1} size={size} align="center">
      <Label text={text} variant="caption" />
    </TableCell>
  )
}

function PlusCell(props: { size: CellSize; text?: string | number; diff?: boolean; amount?: boolean }) {
  const { size, text, diff, amount } = props
  return (
    <TableCell px={1} size={size} color={diff ? 'diffPlus' : 'diffPlusLight'} align="center">
      <Label text={text ?? ''} variant="caption" format={amount ? 'amountNoStyle' : undefined} />
    </TableCell>
  )
}

function MinusCell(props: { size: CellSize; text?: string | number; diff?: boolean; amount?: boolean }) {
  const { size, text, diff, amount } = props
  return (
    <TableCell px={1} size={size} color={diff ? 'diffMinus' : 'diffMinusLight'} align="center">
      <Label text={text ?? ''} variant="caption" format={amount ? 'amountNoStyle' : undefined} />
    </TableCell>
  )
}

function EmptyCell() {
  return (
    <TableCell px={1} cols={9}>
      <Label text="" />
    </TableCell>
  )
}

export default function TransactionDiffView({ previousPhase, currentPhase, changeLogs }: TransactionDiffViewProps) {
  const { taxCategories, phaseName } = useConsts()
  const { find } = useMoneyspace()
  const moneyspace = find(currentPhase.moneyspace.id)

  const taxText = (value?: 1 | 2 | 3) => {
    if (value) {
      return taxCategories[value]
    }
    return undefined
  }
  if (moneyspace === undefined) {
    return <></>
  }
  return (
    <Table
      header={
        <>
          <TableRow>
            <TableCell header cols={10} align="center">
              <Label text={phaseName(previousPhase.currentPhase)} />
            </TableCell>
            <TableCell header cols={10} align="center">
              <Label text={phaseName(currentPhase.currentPhase)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <HeaderCell size="xs3" text="No." />
            <HeaderCell size="xs2" text={moneyspace.detailPropConfig.defaultProp.name} />
            <HeaderCell size="sm2" text={moneyspace.detailPropConfig.customProp1.name} />
            <HeaderCell size="sm2" text={moneyspace.detailPropConfig.customProp2.name} />
            <HeaderCell size="xs" text="単価" />
            <HeaderCell size="xs3" text="数量" />
            <HeaderCell size="xs3" text="単位" />
            <HeaderCell size="xs3" text="税区分" />
            <HeaderCell size="xs" text="金額" />
            <HeaderCell size="xs3" text="No." />
            <HeaderCell size="xs2" text={moneyspace.detailPropConfig.defaultProp.name} />
            <HeaderCell size="sm2" text={moneyspace.detailPropConfig.customProp1.name} />
            <HeaderCell size="sm2" text={moneyspace.detailPropConfig.customProp2.name} />
            <HeaderCell size="xs" text="単価" />
            <HeaderCell size="xs3" text="数量" />
            <HeaderCell size="xs3" text="単位" />
            <HeaderCell size="xs3" text="税区分" />
            <HeaderCell size="xs" text="金額" />
          </TableRow>
        </>
      }
    >
      {changeLogs.map((changeLog) => (
        <TableRow key={`${changeLog.previousDetail?.id ?? ''}-${changeLog.currentDetail?.id ?? ''}`}>
          {changeLog.status === 'change' && (
            <>
              <MinusCell size="xs3" text={changeLog.previousNo} />
              <MinusCell
                size="xs2"
                text={changeLog.previousDetail?.defaultProp ?? ''}
                diff={changeLog.previousDetail?.defaultProp !== changeLog.currentDetail?.defaultProp}
              />
              <MinusCell
                size="sm2"
                text={changeLog.previousDetail?.customProp1 ?? ''}
                diff={changeLog.previousDetail?.customProp1 !== changeLog.currentDetail?.customProp1}
              />
              <MinusCell
                size="sm2"
                text={changeLog.previousDetail?.customProp2 ?? ''}
                diff={changeLog.previousDetail?.customProp2 !== changeLog.currentDetail?.customProp2}
              />
              <MinusCell
                size="xs"
                text={changeLog.previousDetail?.unitPrice}
                diff={changeLog.previousDetail?.unitPrice !== changeLog.currentDetail?.unitPrice}
                amount
              />
              <MinusCell
                size="xs3"
                text={changeLog.previousDetail?.quantity}
                diff={changeLog.previousDetail?.quantity !== changeLog.currentDetail?.quantity}
              />
              <MinusCell
                size="xs3"
                text={changeLog.previousDetail?.unit}
                diff={changeLog.previousDetail?.unit !== changeLog.currentDetail?.unit}
              />
              <MinusCell
                size="xs3"
                text={taxText(changeLog.previousDetail?.taxBucket)}
                diff={changeLog.previousDetail?.taxBucket !== changeLog.currentDetail?.taxBucket}
              />
              <MinusCell
                size="xs"
                text={changeLog.previousDetail?.amount}
                diff={changeLog.previousDetail?.amount !== changeLog.currentDetail?.amount}
                amount
              />
              <PlusCell size="xs3" text={changeLog.currentNo} />
              <PlusCell
                size="xs2"
                text={changeLog.currentDetail?.defaultProp ?? ''}
                diff={changeLog.previousDetail?.defaultProp !== changeLog.currentDetail?.defaultProp}
              />
              <PlusCell
                size="sm2"
                text={changeLog.currentDetail?.customProp1 ?? ''}
                diff={changeLog.previousDetail?.customProp1 !== changeLog.currentDetail?.customProp1}
              />
              <PlusCell
                size="sm2"
                text={changeLog.currentDetail?.customProp2 ?? ''}
                diff={changeLog.previousDetail?.customProp2 !== changeLog.currentDetail?.customProp2}
              />
              <PlusCell
                size="xs"
                text={changeLog.currentDetail?.unitPrice}
                diff={changeLog.previousDetail?.unitPrice !== changeLog.currentDetail?.unitPrice}
                amount
              />
              <PlusCell
                size="xs3"
                text={changeLog.currentDetail?.quantity}
                diff={changeLog.previousDetail?.quantity !== changeLog.currentDetail?.quantity}
              />
              <PlusCell
                size="xs3"
                text={changeLog.currentDetail?.unit}
                diff={changeLog.previousDetail?.unit !== changeLog.currentDetail?.unit}
              />
              <PlusCell
                size="xs3"
                text={taxText(changeLog.currentDetail?.taxBucket)}
                diff={changeLog.previousDetail?.taxBucket !== changeLog.currentDetail?.taxBucket}
              />
              <PlusCell
                size="xs"
                text={changeLog.currentDetail?.amount}
                diff={changeLog.previousDetail?.amount !== changeLog.currentDetail?.amount}
                amount
              />
            </>
          )}
          {changeLog.status === 'add' && (
            <>
              <EmptyCell />
              <PlusCell size="xs3" text={changeLog.currentNo} />
              <PlusCell size="xs2" text={changeLog.currentDetail?.defaultProp ?? ''} />
              <PlusCell size="sm2" text={changeLog.currentDetail?.customProp1 ?? ''} />
              <PlusCell size="sm2" text={changeLog.currentDetail?.customProp2 ?? ''} />
              <PlusCell size="xs" text={changeLog.currentDetail?.unitPrice} amount />
              <PlusCell size="xs3" text={changeLog.currentDetail?.quantity} />
              <PlusCell size="xs3" text={changeLog.currentDetail?.unit} />
              <PlusCell size="xs3" text={taxText(changeLog.currentDetail?.taxBucket)} />
              <PlusCell size="xs" text={changeLog.currentDetail?.amount} amount />
            </>
          )}
          {changeLog.status === 'delete' && (
            <>
              <MinusCell size="xs3" text={changeLog.previousNo} />
              <MinusCell size="xs2" text={changeLog.previousDetail?.defaultProp ?? ''} />
              <MinusCell size="sm2" text={changeLog.previousDetail?.customProp1 ?? ''} />
              <MinusCell size="sm2" text={changeLog.previousDetail?.customProp2 ?? ''} />
              <MinusCell size="xs" text={changeLog.previousDetail?.unitPrice} amount />
              <MinusCell size="xs3" text={changeLog.previousDetail?.quantity} />
              <MinusCell size="xs3" text={changeLog.previousDetail?.unit} />
              <MinusCell size="xs3" text={taxText(changeLog.previousDetail?.taxBucket)} />
              <MinusCell size="xs" text={changeLog.previousDetail?.amount} amount />
              <EmptyCell />
            </>
          )}
        </TableRow>
      ))}
    </Table>
  )
}

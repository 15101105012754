import { useCallback } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/ja'

export default function useDay() {
  dayjs.extend(relativeTime)
  dayjs.locale('ja')

  const formatDate = useCallback((date: string | Date) => dayjs(date).format('YYYY/MM/DD'), [])
  const formatDateJa = useCallback((date: string | Date) => dayjs(date).format('YYYY年MM月DD'), [])
  const formatDateTime = useCallback((date: string | Date) => dayjs(date).format('YYYY/MM/DD HH:mm'), [])
  const formatTime = useCallback((date: string | Date) => dayjs(date).format('HH:mm'), [])
  const timestamp = useCallback(() => dayjs().format('YYYYMMDDHHmmss'), [])
  const closingDate = useCallback((day: number) => {
    const date = dayjs()
    const lastDay = date.endOf('month').get('date')
    return date.date(Math.min(day, lastDay)).format('YYYY/MM/DD')
  }, [])
  const paymentDate = useCallback((paymentMonth: number, day: number) => {
    let date = dayjs()
    switch (paymentMonth) {
      case 2:
        date = dayjs().add(1, 'month')
        break
      case 3:
        date = dayjs().add(2, 'month')
        break
      case 4:
        date = dayjs().add(3, 'month')
        break
      default:
        break
    }
    const lastDay = date.endOf('month').get('date')
    return date.date(Math.min(day, lastDay)).format('YYYY/MM/DD')
  }, [])
  const fromNow = useCallback((dateTime: string) => dayjs(dateTime).fromNow(), [])
  const today = useCallback(() => dayjs().format('YYYY/MM/DD'), [])
  const isAfter = useCallback((date1: string | Date, date2: string | Date) => dayjs(date1).isAfter(date2), [])
  const afterDay = useCallback(
    (value: number, date?: string) => dayjs(date).add(value, 'days').format('YYYY/MM/DD'),
    []
  )
  const before = useCallback((date: string) => dayjs().isBefore(date), [])
  return {
    formatDate,
    formatDateJa,
    formatDateTime,
    formatTime,
    timestamp,
    closingDate,
    paymentDate,
    fromNow,
    today,
    isAfter,
    afterDay,
    before,
  }
}

import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#29C601',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#252A36',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF2C2C',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#DDDDDD',
      contrastText: '#4C4A4A',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#000000',
    },
    black: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    caution: {
      main: '#FF2C2C',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    h5: {
      fontWeight: 800,
    },
    subtitle1: {
      fontWeight: 800,
    },
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        badge: {
          zIndex: 900,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          zIndex: 800,
        },
      },
    },
  },
})

export default theme

import { useCallback } from 'react'

export default function useFile() {
  const base64 = useCallback(
    (file: File) =>
      new Promise<string>((resolve) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result as string)
        }
        reader.readAsDataURL(file)
      }),
    []
  )
  const base64ToFile = useCallback((text: string, filename: string, filetype: string) => {
    const bin = atob(text.replace(/^.*,/, ''))
    const buffer = new Uint8Array(bin.length)
    for (let index = 0; index < bin.length; index += 1) {
      buffer[index] = bin.charCodeAt(index)
    }
    return new File([buffer.buffer], filename, { type: filetype })
  }, [])
  return {
    base64,
    base64ToFile,
  }
}

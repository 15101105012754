import { useCallback } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'

type TransactionInstallmentConfirmDialogProps = {
  onNext: () => void
  onCancel: () => void
}

export default function TransactionInstallmentConfirmDialog({
  onNext,
  onCancel,
}: TransactionInstallmentConfirmDialogProps) {
  const handleNext = useCallback(() => {
    onNext()
  }, [onNext])
  const handleClose = useCallback(() => {
    onCancel()
  }, [onCancel])
  return (
    <Dialog
      open
      onClose={handleClose}
      title="分割請求"
      actions={
        <FlexRow>
          <Button color="secondary" onClick={handleClose}>
            キャンセル
          </Button>
          <Button color="primary" onClick={handleNext}>
            次へ
          </Button>
        </FlexRow>
      }
    >
      <FlexColumn space={4}>
        <FlexColumn>
          <Label text="一度 分割請求を選択すると、明細項目の編集・追加ができなくなります。" />
          <Label text="編集・追加が必要な場合は、先に明細を編集してください。" />
        </FlexColumn>
        <Label text="分割請求の操作に進みますか？" />
      </FlexColumn>
    </Dialog>
  )
}

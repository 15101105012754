import MuiAutocomplete from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import { Control, Controller } from 'react-hook-form'
import MuiFormControl from '@mui/material/FormControl/FormControl'
import { FormHelperText } from '@mui/material'

type ComboboxSize = 'xs' | 'sm' | 'md' | 'lg'

export type ComboboxItem = {
  value: string
  label: string
  description?: string
}

type MultipleComboboxProps = {
  items: ComboboxItem[]
  size?: ComboboxSize
  name: string
  control: Control
  error?: string
  placeholder?: string
}

const width = (size?: ComboboxSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'md':
      return 360
    case 'lg':
      return 480
    default:
      return '100%'
  }
}

export default function MultipleCombobox({ items, size, name, control, error, placeholder }: MultipleComboboxProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <MuiFormControl error={error !== undefined}>
          <MuiAutocomplete
            {...field}
            multiple
            options={items}
            size="small"
            sx={{ width: width(size) }}
            renderInput={(params) => <MuiTextField {...params} placeholder={placeholder} />}
            onChange={(_, data: ComboboxItem[]) => {
              field.onChange(data)
            }}
          />
          <FormHelperText>{error}</FormHelperText>
        </MuiFormControl>
      )}
    />
  )
}

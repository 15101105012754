import MuiTypography from '@mui/material/Typography'

type LabelVariant = 'body' | 'caption' | 'title' | 'subtitle' | 'headline'
type LabelColor = 'primary' | 'secondary' | 'warning' | 'warning.main' | 'error' | 'caution.main' | 'white.main'
type WhiteSpace = 'normal' | 'nowrap' | 'pre' | 'pre-wrap'

const typographyVariant = (variant: LabelVariant) => {
  switch (variant) {
    case 'body':
      return 'body1'
    case 'caption':
      return 'caption'
    case 'title':
      return 'h5'
    case 'subtitle':
      return 'subtitle1'
    case 'headline':
      return 'h4'
    default:
      return 'body1'
  }
}

type LabelFormat = 'amount' | 'amountNoStyle' | 'amountWithUnit'

type LabelProps = {
  variant?: LabelVariant
  text: string | number
  format?: LabelFormat
  color?: LabelColor
  whiteSpace?: WhiteSpace
}

export function formatText(text: string | number, format?: LabelFormat) {
  if (format === 'amount') {
    return Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(Number(text))
  }
  if (format === 'amountNoStyle') {
    return Intl.NumberFormat('ja-JP').format(Number(text))
  }
  if (format === 'amountWithUnit') {
    return `${Intl.NumberFormat('ja-JP').format(Number(text))}円`
  }
  return text
}

export default function Label({ variant = 'body', text, format, color, whiteSpace = 'normal' }: LabelProps) {
  const formattedText = formatText(text, format)
  return (
    <MuiTypography variant={typographyVariant(variant)} color={color} sx={{ whiteSpace }}>
      {formattedText}
    </MuiTypography>
  )
}

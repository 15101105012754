import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from '../../atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import RadioControl from '../../atoms/inputs/RadioControl'
import Button from '../../atoms/inputs/Button'
import Alert from '../../atoms/feedback/Alert'
import { CompanySelectors } from '../../../store/company/selectors'
import { CompanyActions } from '../../../store/company'
import { InvoiceLessPatternA, InvoiceLessPatternC } from '../../../types/Moneyspace'

type InvoiceSettingParams = {
  invoiceLessActionType: number
}

export default function CompanyTabInvoiceSetting() {
  const company = useSelector(CompanySelectors.company)
  const dispatch = useDispatch()
  const form = useForm<InvoiceSettingParams>({
    defaultValues: {
      invoiceLessActionType: company?.invoiceLessActionType ?? InvoiceLessPatternC,
    },
  })

  const handleSave = useCallback(
    (params: InvoiceSettingParams) => {
      if (company) {
        dispatch(
          CompanyActions.saveInvoice({ companyId: company.id, invoiceLessActionType: params.invoiceLessActionType })
        )
      }
    },
    [company, dispatch]
  )

  return (
    <Form onSubmit={form.handleSubmit(handleSave)}>
      <FlexColumn space={4}>
        <Label text="発注者としてマネースペースに参加する場合は設定してください" />
        <FlexColumn>
          <Label text="インボイス制度へ申請していない取引先とは" />
          <RadioControl
            items={[
              { label: '取引しない', value: InvoiceLessPatternA },
              { label: '消費税相当額の請求を許可する', value: InvoiceLessPatternC },
            ]}
            name="invoiceLessActionType"
            control={form.control}
          />
        </FlexColumn>
        <FlexRow>
          <Alert
            severity="warning"
            title="この設定は、既存のマネースペースで作成する取引には反映されません"
            label="保存後に新しくマネースペースを作成してください"
          />
        </FlexRow>
        <Button type="submit">保存</Button>
      </FlexColumn>
    </Form>
  )
}

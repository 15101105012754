import { useSelector } from 'react-redux'
import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { SessionSelectors } from '../../store/session/selectors'
import usePath from '../../hooks/usePath'

type UnLoggedPageProps = {
  children: ReactNode
}

export default function UnLoggedPage({ children }: UnLoggedPageProps) {
  const loggedIn = useSelector(SessionSelectors.loggedIn)
  const { root } = usePath()
  if (!loggedIn) {
    return <>{children}</>
  }
  return <Navigate to={root()} />
}

import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Table from '../../atoms/display/Table'
import { ApprovalFlow } from '../../../types/ApprovalFlow'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon, EditIcon, InfoIcon } from '../../atoms/display/Icons'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import { useApprovalFlowDeleteDialog, useApprovalFlowSettingDialog } from '../../../hooks/useDialog'
import usePermission from '../../../hooks/usePermission'
import { SessionSelectors } from '../../../store/session/selectors'
import Checkbox from '../../atoms/inputs/Checkbox'
import { ApprovalFlowActions } from '../../../store/approval_flow'
import Dialog from '../../atoms/feedback/Dialog'

type ApprovalFlowTableProps = {
  flows: ApprovalFlow[]
}

export default function ApprovalFlowTable({ flows }: ApprovalFlowTableProps) {
  const ApprovalFlowSettingDialog = useApprovalFlowSettingDialog()
  const ApprovalFlowDeleteDialog = useApprovalFlowDeleteDialog()
  const { approvalFlowPermissions } = usePermission()
  const user = useSelector(SessionSelectors.user)
  const [autoApprovalFlowId, setAutoApprovalFlowId] = useState<string | null>(
    flows.filter((flow) => flow.autoReviewBillingPhase)[0]?.id
  )
  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false)
  const companyId = useSelector(SessionSelectors.companyId)
  const dispatch = useDispatch()

  const handleChangeAutoApprovalFlow = (flow: ApprovalFlow, checked: boolean) => {
    if (companyId) {
      dispatch(
        ApprovalFlowActions.saveAutoReviewBillingPhase({
          flowId: flow?.id,
          oldFlowId: autoApprovalFlowId,
          autoReviewBillingPhase: checked,
        })
      )
    }
    setAutoApprovalFlowId(checked ? flow.id : null)
  }

  return (
    <>
      <Table
        header={
          <TableRow>
            <TableCell header size="xs" />
            <TableCell header />
            <TableCell header size="xs3" />
            <TableCell header size="xs2">
              <FlexRow align="center" space={0}>
                <Label text="自動化" />
                <IconButton label="自動化について" onClick={() => setOpenInfoDialog(true)}>
                  <InfoIcon />
                </IconButton>
              </FlexRow>
            </TableCell>
          </TableRow>
        }
      >
        {flows.map((flow) => (
          <TableRow key={flow.id}>
            <TableCell size="xs">
              <Label text={flow.name} />
            </TableCell>
            <TableCell>
              <AvatarStepper members={flow.members} />
            </TableCell>
            <TableCell size="xs3">
              <FlexRow wrap>
                {approvalFlowPermissions.edit(user) && (
                  <IconButton label="編集" onClick={() => ApprovalFlowSettingDialog.open(flow)}>
                    <EditIcon />
                  </IconButton>
                )}
                {approvalFlowPermissions.delete(user) && (
                  <IconButton label="削除" color="caution" onClick={() => ApprovalFlowDeleteDialog.open(flow)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </FlexRow>
            </TableCell>
            <TableCell size="xs2" align="center">
              <FlexRow ml={4}>
                <Checkbox
                  checked={flow.id === autoApprovalFlowId}
                  disabled={!approvalFlowPermissions.edit(user)}
                  onChange={(checked) => handleChangeAutoApprovalFlow(flow, checked)}
                />
              </FlexRow>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <Dialog open={openInfoDialog} onClose={() => setOpenInfoDialog(false)} title="承認フローの自動化について">
        <FlexColumn>
          <Label text="チェックを入れると、請求フェーズの受け取り時の承認フローを自動で設定することが可能になります。" />
        </FlexColumn>
      </Dialog>
    </>
  )
}

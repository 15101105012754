import { useCallback, useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import shortid from 'shortid'
import { useDispatch } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import PublishDateField from '../../molecules/inputs/PublishDateField'
import ConsumptionTaxSelect from '../../molecules/inputs/ConsumptionTaxSelect'
import DocumentItemForm from './DocumentItemForm'
import Button from '../../atoms/inputs/Button'
import { DeleteIcon, PlusIcon } from '../../atoms/display/Icons'
import Label from '../../atoms/display/Label'
import IconButton from '../../atoms/inputs/IconButton'
import DocumentNoteField from '../../molecules/inputs/DocumentNoteField'
import DocumentAmountView from '../../molecules/display/DocumentAmountView'
import { DocumentAttributes, DocumentItem, DocumentType } from '../../../types/Document'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import Form from '../../atoms/inputs/Form'
import useDocument from '../../../hooks/useDocument'
import DocumentPaymentForm from './DocumentPaymentForm'
import { useDeviceType } from '../../../hooks/useDeviceType'
import useConsts from '../../../hooks/useConsts'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { Moneyspace } from '../../../types/Moneyspace'
import { RelatedUser } from '../../../types/User'
import DocumentOcrDialog from './DocumentOcrDialog'
import { ToolsActions } from '../../../store/tools'
import Alert from '../../atoms/feedback/Alert'
import DocumentDeliveryDateForm from './DocumentDeliveryDateForm'

type DocumentFormProps = {
  document: DocumentAttributes
  documentType: DocumentType
  processing: boolean
  moneyspaceId: string
  disabledDetails?: boolean
  onSubmit: (values: DocumentAttributes, ocrFile?: File) => void
  onCancel: () => void
}

function findContracteeMembers(members: RelatedUser[], moneyspace?: Moneyspace): RelatedUser[] {
  if (moneyspace === undefined || moneyspace.contractee == null) {
    return []
  }
  const contracteeId = moneyspace.contractee.id
  return members.filter((member) => member.companyId === contracteeId)
}

export default function DocumentForm({
  document,
  documentType,
  processing,
  moneyspaceId,
  disabledDetails,
  onSubmit,
  onCancel,
}: DocumentFormProps) {
  const [editingDocument, setEditingDocument] = useState<DocumentAttributes>(document)
  const [ocrDialogOpen, setOcrDialogOpen] = useState<boolean>(false)
  const { calcDetailAmount } = useDocument()
  const { find, findMembers } = useMoneyspace()
  const moneyspace = find(moneyspaceId)
  const members = findMembers(moneyspaceId)
  const contracteeMembers = findContracteeMembers(members, moneyspace)
  const { pc } = useDeviceType()
  const { documentItemMax } = useConsts()
  const [error, setError] = useState(false)
  const [ocrFile, setOcrFile] = useState<File | undefined>()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    publishedAt: rules.publishedAt.required(),
    contractee: yup.object().shape({
      closingDate: rules.closingDate.required(),
      paymentDate: rules.paymentDate.required(),
    }),
    note: rules.note,
    details: rules.transactionDetails({
      defaultProp: { name: '', required: true },
      customProp1: { name: '', required: false },
      customProp2: { name: '', required: false },
    }),
  })
  const { handleSubmit, register, control, errors, watch } = useForm<DocumentAttributes>(schema, document)
  const { fields, append, remove } = useFieldArray({ control, name: 'details' })
  useEffect(() => {
    if (document.details.length === 0) {
      append({
        picName: '',
        unitPrice: null,
        quantity: null,
        unit: '件',
        taxBucket: 1,
        tags: [],
        amount: 0,
      })
    }
  }, [document, append])
  useEffect(() => {
    watch((value) => {
      const doc = value as DocumentAttributes
      doc.details = doc.details.map((detail) => ({
        ...detail,
        amount: calcDetailAmount(detail),
      }))
      setEditingDocument(doc)
    })
  }, [watch, setEditingDocument, calcDetailAmount])
  const handleAddItem = useCallback(() => {
    const newDetail = {
      id: shortid(),
      picName: '',
      unitPrice: null,
      quantity: null,
      unit: '件',
      taxBucket: 1,
      tags: [],
      amount: 0,
    }
    append(newDetail)
  }, [append])
  const handleRemoveItem = useCallback(
    (id: string) => {
      remove(fields.findIndex((field) => field.id === id))
    },
    [fields, remove]
  )
  const handleOcrResult = useCallback(
    (details: DocumentItem[], file: File) => {
      if (details.length === 0) {
        return
      }
      const size = fields.length
      for (let index = 0; index < size; index += 1) {
        remove(0)
      }
      details.forEach((detail) => {
        append({
          propertyName: detail.propertyName,
          constructionType: detail.constructionType,
          picName: detail.picName,
          others: detail.others,
          unitPrice: detail.unitPrice,
          quantity: detail.quantity,
          unit: detail.unit,
          taxBucket: 1,
          tags: [],
          amount: 0,
        })
      })
      setOcrFile(file)
      setOcrDialogOpen(false)
      dispatch(ToolsActions.resetOcrResult())
    },
    [append, dispatch, fields.length, remove]
  )
  return (
    <Form onSubmit={handleSubmit((values) => onSubmit(values, ocrFile))}>
      <FlexColumn space={4}>
        <FlexColumn>
          <FlexRow wrap align="flex-end">
            <FormItem label="発行日" required>
              <PublishDateField control={control} name="publishedAt" error={errors.publishedAt} />
            </FormItem>
            {[6, 7].includes(document.type) && (
              <FlexColumn>
                <Alert
                  severity="success"
                  icon={false}
                  label={document.contractee?.bearingFee ? '振込手数料：受注者負担' : '振込手数料：発注者負担'}
                />
              </FlexColumn>
            )}
          </FlexRow>
          <FlexRow wrap>
            <FormItem label="消費税の表示">
              <ConsumptionTaxSelect name="isTaxIn" control={control} disabled={disabledDetails} />
            </FormItem>
            <DocumentPaymentForm control={control} documentType={documentType} errors={errors.contractee} />
            {documentType === 5 && <DocumentDeliveryDateForm control={control} />}
          </FlexRow>
        </FlexColumn>
        <FlexColumn space={4}>
          {fields.map((detail, index) => (
            <FlexRow align="center" key={detail.id}>
              <DocumentItemForm
                contracteeMembers={contracteeMembers}
                register={register}
                control={control}
                errors={errors.details && errors.details[index]}
                index={index}
                amount={editingDocument.details[index]?.amount ?? 0}
                disabled={disabledDetails}
              />
              <IconButton
                label="項目を削除する"
                color="caution"
                disabled={fields.length === 1 || disabledDetails}
                onClick={() => {
                  handleRemoveItem(detail.id)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </FlexRow>
          ))}
          <FlexRow>
            <Button size="lg" disabled={fields.length >= documentItemMax || disabledDetails} onClick={handleAddItem}>
              <FlexRow align="center">
                <PlusIcon />
                <Label text="項目を追加する" />
              </FlexRow>
            </Button>
            <Button size="lg" variant="text" disabled={disabledDetails} onClick={() => setOcrDialogOpen(true)}>
              書類（PDF）の明細を読み込む
            </Button>
          </FlexRow>
        </FlexColumn>
        <FlexRow mt={4} align="flex-end" justify="center" wrap>
          <FlexColumn width={pc ? 640 : 320}>
            <FormItem label="備考">
              <DocumentNoteField register={register('note')} error={errors.note} />
            </FormItem>
          </FlexColumn>
          {moneyspace && <DocumentAmountView document={editingDocument} moneyspace={moneyspace} onError={setError} />}
        </FlexRow>
        <FlexRow justify="center">
          <Button onClick={onCancel} color="secondary">
            キャンセル
          </Button>
          <Button type="submit" disabled={error || processing}>
            保存
          </Button>
        </FlexRow>
      </FlexColumn>
      <DocumentOcrDialog open={ocrDialogOpen} onResult={handleOcrResult} onClose={() => setOcrDialogOpen(false)} />
    </Form>
  )
}

import { ReactSVG } from 'react-svg'
import { styled } from '@mui/material'

export type SvgColor = 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success' | 'white' | 'black'

type SvgProps = {
  src: string
  color: SvgColor
  width: number
  height: number
}

const StyledSVG = styled(ReactSVG)({})

export default function Svg({ src, color, width, height }: SvgProps) {
  return (
    <StyledSVG
      src={src}
      sx={{
        '& path': {
          fill: color,
        },
      }}
      beforeInjection={(svg) => {
        svg.setAttribute('style', `width: ${width}; height: ${height};`)
      }}
    />
  )
}

import { useCallback, useState } from 'react'
import TreeView from '../../atoms/navigation/TreeView'
import { MoneyspaceFolder } from '../../../types/Moneyspace'
import TreeItem from '../../atoms/navigation/TreeItem'
import { MoneyspaceGroupIcon } from '../../atoms/display/Icons'
import Label from '../../atoms/display/Label'
import useMoneyspaceFolder from '../../../hooks/useMoneyspaceFolder'

type MoneyspaceFolderSelectFieldProps = {
  initialSelectedId?: string
  onSelectFolder?: (folder?: MoneyspaceFolder) => void
}

function MoneyspaceFolderTreeItem(params: { folder: MoneyspaceFolder }) {
  const { folder } = params
  const { folders } = useMoneyspaceFolder(folder)
  return (
    <TreeItem key={folder.id} id={folder.id} icon={<MoneyspaceGroupIcon />} label={<Label text={folder.name} />}>
      {folders.map((childFolder) => (
        <MoneyspaceFolderTreeItem folder={childFolder} />
      ))}
    </TreeItem>
  )
}

export default function MoneyspaceFolderSelectField({
  initialSelectedId,
  onSelectFolder,
}: MoneyspaceFolderSelectFieldProps) {
  const { folders, findFolder } = useMoneyspaceFolder(null)
  const [selectedId, setSelectedId] = useState(initialSelectedId)
  const handleSelect = useCallback(
    (value: string) => {
      setSelectedId(value)
      const folder = findFolder(value)
      if (onSelectFolder) {
        onSelectFolder(folder ?? undefined)
      }
    },
    [findFolder, onSelectFolder]
  )
  return (
    <TreeView selectedId={selectedId} onSelect={handleSelect}>
      {folders.map((folder) => (
        <MoneyspaceFolderTreeItem folder={folder} />
      ))}
    </TreeView>
  )
}

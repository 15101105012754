import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import usePage from '../../hooks/usePage'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import Button from '../../components/atoms/inputs/Button'
import SearchField from '../../components/molecules/inputs/SearchField'
import ClientTable from '../../components/organisms/client/ClientTable'
import { useClientCreateDialog } from '../../hooks/useDialog'
import { Company } from '../../types/Company'
import { CompanySelectors } from '../../store/company/selectors'
import { CompanyActions } from '../../store/company'
import usePermission from '../../hooks/usePermission'
import { SessionSelectors } from '../../store/session/selectors'
import RadioGroup from '../../components/atoms/inputs/RadioGroup'
import FormItem from '../../components/molecules/inputs/FormItem'
import useDay from '../../hooks/useDay'

export default function ClientsPage() {
  const { changeTitle } = usePage()
  const clients = useSelector(CompanySelectors.clients)
  const [searchParamName, setSearchParamName] = useState<string>()
  const [searchParamInvoice, setSearchParamInvoice] = useState<number>(2)
  const [filteredClients, setFilteredClients] = useState<Company[]>(clients)
  const clientCreateDialog = useClientCreateDialog()
  const user = useSelector(SessionSelectors.user)
  const { clientPermissions } = usePermission()
  const dispatch = useDispatch()
  const { timestamp } = useDay()

  useEffect(() => {
    dispatch(CompanyActions.fetchClients())
  }, [dispatch])
  useEffect(() => {
    changeTitle('取引先管理')
  }, [changeTitle])
  useEffect(() => {
    setFilteredClients(clients)
  }, [clients, setFilteredClients])
  useEffect(() => {
    setFilteredClients(
      clients.filter((client) => {
        if (searchParamInvoice === 0 && client.invoiceLicenseNumber) {
          return false
        }
        if (searchParamInvoice === 1 && !client.invoiceLicenseNumber) {
          return false
        }
        if (searchParamName) {
          return client.name.includes(searchParamName)
        }
        return true
      })
    )
  }, [clients, searchParamInvoice, searchParamName])
  const handleSearch = useCallback(
    (keyword?: string) => {
      setSearchParamName(keyword)
    },
    [setSearchParamName]
  )
  const handleChangeInvoice = useCallback(
    (value: number | boolean | string) => {
      setSearchParamInvoice(Number(value))
    },
    [setSearchParamInvoice]
  )
  const handleCreate = useCallback(() => {
    clientCreateDialog.open()
  }, [clientCreateDialog])

  const handleDownload = useCallback(() => {
    dispatch(
      CompanyActions.downloadClientCsv({
        filename: `clients_${timestamp()}.csv`,
        searchParamName,
        searchParamInvoice,
      })
    )
  }, [dispatch, searchParamInvoice, searchParamName, timestamp])

  return (
    <FlexColumn>
      <FlexRow justify="space-between">
        <Button onClick={handleCreate} disabled={!clientPermissions.create(user)}>
          取引先を招待
        </Button>
        <SearchField placeholder="企業名で検索" onChange={handleSearch} />
      </FlexRow>
      <FlexRow justify="space-between" align="flex-end" wrap>
        <FormItem label="事業者登録番号の有無">
          <RadioGroup
            items={[
              { value: 2, label: '全て' },
              { value: 1, label: 'あり' },
              { value: 0, label: 'なし' },
            ]}
            row
            value={searchParamInvoice}
            onChange={handleChangeInvoice}
          />
        </FormItem>
        <Button variant="outlined" onClick={handleDownload}>
          CSVダウンロード
        </Button>
      </FlexRow>
      <FlexColumn height="calc(100vh - 206px)">
        <ClientTable clients={filteredClients} />
      </FlexColumn>
    </FlexColumn>
  )
}

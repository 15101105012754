import MuiAdapterDayjs from '@mui/lab/AdapterDayjs'
import MuiLocalizationProvider from '@mui/lab/LocalizationProvider'
import MuiDatePicker from '@mui/lab/DatePicker'
import MuiTextField from '@mui/material/TextField'
import { useCallback } from 'react'
import { Control, Controller } from 'react-hook-form'

type DatePickerProps = {
  label?: string
  value?: Date
  name?: string
  control?: Control
  error?: string
  disabled?: boolean
  onChange?: (value: Date | null) => void
}

export default function DatePicker({ label, value, name, control, error, disabled, onChange }: DatePickerProps) {
  const handleChange = useCallback(
    (newValue: Date | null) => {
      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange]
  )
  return (
    <MuiLocalizationProvider dateAdapter={MuiAdapterDayjs}>
      {name && control ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            const val = field.value as string | undefined
            return (
              <MuiDatePicker
                {...field}
                value={val === undefined ? null : val}
                label={label}
                inputFormat="YYYY/MM/DD"
                mask="____/__/__"
                views={['year', 'month', 'day']}
                clearable
                disabled={disabled}
                onChange={(newValue: Date | null) => {
                  try {
                    field.onChange(newValue?.toISOString() ?? null)
                  } catch {
                    field.onChange(null)
                  }
                }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    size="small"
                    sx={{ width: 152 }}
                    error={error !== undefined}
                    helperText={error ?? ''}
                  />
                )}
              />
            )
          }}
        />
      ) : (
        <MuiDatePicker
          label={label}
          value={value}
          views={['year', 'month', 'day']}
          onChange={handleChange}
          renderInput={(params) => <MuiTextField {...params} size="small" sx={{ width: 160 }} />}
        />
      )}
    </MuiLocalizationProvider>
  )
}

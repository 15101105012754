import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { useMoneyspaceEditDialog } from '../../../hooks/useDialog'
import Button from '../../atoms/inputs/Button'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { Moneyspace, MoneyspaceEditParams } from '../../../types/Moneyspace'
import { FlexColumn } from '../../atoms/layout/Flex'
import MoneyspaceNameField from '../../molecules/inputs/MoneyspaceNameField'
import FormItem from '../../molecules/inputs/FormItem'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import CompanyPaymentForm from '../company/CompanyPaymentForm'
import useProcessing from '../../../hooks/useProcessing'
import Checkbox from '../../atoms/inputs/Checkbox'
import TextField from '../../atoms/inputs/TextField'

type MoneyspaceEditDialogProps = {
  moneyspace: Moneyspace
}

export default function MoneyspaceEditDialog({ moneyspace }: MoneyspaceEditDialogProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.saveMoneyspace.typePrefix)
  const { state, close } = useMoneyspaceEditDialog()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.moneyspaceName.required(),
    closingDay: rules.closingDay.required(),
    paymentDay: rules.paymentDay.required(),
  })
  const { handleSubmit, register, control, errors } = useForm<MoneyspaceEditParams>(schema, {
    name: moneyspace.name,
    closingDay: moneyspace.closingDay,
    paymentMonth: moneyspace.paymentMonth,
    paymentDay: moneyspace.paymentDay,
    bearingFee: moneyspace.bearingFee,
    isTaxIn: moneyspace.isTaxIn,
    alertBillingDeadline: moneyspace.alertBillingDeadline,
    fixGracePeriod: moneyspace.fixGracePeriod,
    taxRoundingType: moneyspace.taxRoundingType,
    externalId: moneyspace.externalId,
  })
  const handleSave = useCallback(
    (values: MoneyspaceEditParams) => {
      startProcess()
      dispatch(
        MoneyspacesActions.saveMoneyspace({
          moneyspace: {
            ...moneyspace,
            name: values.name,
            closingDay: values.closingDay,
            paymentMonth: values.paymentMonth,
            paymentDay: values.paymentDay,
            bearingFee: values.bearingFee,
            isTaxIn: values.isTaxIn,
            alertBillingDeadline: values.alertBillingDeadline,
            fixGracePeriod: values.fixGracePeriod,
            externalId: values.externalId,
          },
        })
      )
    },
    [dispatch, moneyspace, startProcess]
  )
  return (
    <Dialog
      open={state !== undefined}
      title="マネースペースの編集"
      actions={
        <Button type="submit" disabled={processing}>
          保存
        </Button>
      }
      onClose={close}
      onSubmit={handleSubmit(handleSave)}
    >
      <FlexColumn space={4}>
        <FormItem label="マネースペース名" required>
          <MoneyspaceNameField register={register('name')} error={errors.name} />
        </FormItem>
        <FormItem label="仕入先コード">
          <TextField register={register('externalId')} />
        </FormItem>
        <FlexColumn>
          <CompanyPaymentForm
            control={control}
            errors={errors}
            disabledBearingFee
            disabledClosingDayGracePeriod
            displayTaxRoundingType
          />
        </FlexColumn>
        {moneyspace.aggregateTransactions && (
          <Checkbox
            label="締め日に受注者全員へ請求の提出をリマインドする"
            name="alertBillingDeadline"
            control={control}
          />
        )}
      </FlexColumn>
    </Dialog>
  )
}

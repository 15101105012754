import { useLocation } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import TabView from '../components/atoms/navigation/TabView'
import usePage from '../hooks/usePage'
import useRouter from '../hooks/useRouter'
import SearchTabTransaction from '../components/organisms/search/SearchTabTransaction'
import SearchTabBilling from '../components/organisms/search/SearchTabBilling'

export default function SearchPage() {
  const { changeTitle } = usePage()
  const { search: searchPage } = useRouter()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const tab = query.get('tab') ?? 'transaction'

  useEffect(() => {
    changeTitle('検索')
  }, [changeTitle])

  const handleChangeTab = useCallback(
    (value: string) => {
      searchPage(value)
    },
    [searchPage]
  )

  return (
    <TabView
      value={tab}
      tabs={[
        {
          value: 'transaction',
          label: '取引の検索',
          panel: <SearchTabTransaction />,
        },
        {
          value: 'billing',
          label: '請求書の検索',
          panel: <SearchTabBilling />,
        },
      ]}
      onChange={handleChangeTab}
    />
  )
}

import { Control } from 'react-hook-form'
import Select from '../../atoms/inputs/Select'

type PayAdjustmentTimingFieldProps = {
  name: string
  control: Control
  disabled?: boolean
}

export default function PayAdjustmentTimingField({ name, control, disabled }: PayAdjustmentTimingFieldProps) {
  return (
    <Select
      items={[
        { value: 0, label: '前営業日払' },
        { value: 1, label: '翌営業日払' },
      ]}
      size="sm"
      name={name}
      control={control}
      disabled={disabled}
    />
  )
}

import Label from '../../atoms/display/Label'
import { Address } from '../../../types/Company'

type AddressLabelProps = {
  address: Address
}

export default function AddressLabel({ address }: AddressLabelProps) {
  return <Label text={address.address ?? ''} />
}

import { createSlice } from '@reduxjs/toolkit'
import { ApprovalFlow } from '../../types/ApprovalFlow'
import { createAsyncAction } from '../actions'
import { RelatedUser } from '../../types/User'
import { CompanyActions } from '../company'

export type ApprovalFlowState = {
  flows: ApprovalFlow[]
}

export const initialApprovalFlowState: ApprovalFlowState = {
  flows: [],
}

export const ApprovalFlowActions = {
  fetchFlows: createAsyncAction<{ companyId: string }, { flows: ApprovalFlow[] }>(
    'fetchFlows',
    async (params, { companyRepository }) => {
      const flows = await companyRepository.loadApprovalFlows(params.companyId)
      return { flows }
    }
  ),
  saveFlow: createAsyncAction<
    { companyId: string; id?: string; name: string; users: (RelatedUser | null)[] },
    { flow: ApprovalFlow }
  >('saveFlow', async (params, { companyRepository }) => {
    if (params.id) {
      const flow = await companyRepository.saveApprovalFlow(
        params.companyId,
        params.id,
        params.name,
        params.users.map((user) => user?.id ?? null)
      )
      return { flow }
    }
    const flow = await companyRepository.createApprovalFlow(
      params.companyId,
      params.name,
      params.users.map((user) => user?.id ?? null)
    )
    return { flow }
  }),
  saveAutoReviewBillingPhase: createAsyncAction<
    { flowId: string; oldFlowId: string | null; autoReviewBillingPhase: boolean },
    { flowId: string; oldFlowId: string | null; autoReviewBillingPhase: boolean }
  >('saveAutoReviewBillingPhase', async (params, { companyRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      if (params.oldFlowId) {
        await companyRepository.saveAutoReviewBillingPhase(companyId, params.oldFlowId, false)
      }
      await companyRepository.saveAutoReviewBillingPhase(companyId, params.flowId, params.autoReviewBillingPhase)
    }
    return params
  }),
  deleteFlow: createAsyncAction<{ companyId: string; id: string }, { id: string }>(
    'deleteFlow',
    async (params, { companyRepository }) => {
      await companyRepository.deleteApprovalFlow(params.companyId, params.id)
      return { id: params.id }
    }
  ),
}

const approvalFlowSlice = createSlice({
  name: 'approval_flow',
  initialState: initialApprovalFlowState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ApprovalFlowActions.fetchFlows.fulfilled, (state, action) => {
        state.flows = action.payload.flows
      })
      .addCase(ApprovalFlowActions.saveFlow.fulfilled, (state, action) => {
        if (state.flows.map((flow) => flow.id).includes(action.payload.flow.id)) {
          state.flows = state.flows.map((flow) => {
            if (flow.id === action.payload.flow.id) {
              return action.payload.flow
            }
            return flow
          })
        } else {
          state.flows.unshift(action.payload.flow)
        }
      })
      .addCase(ApprovalFlowActions.deleteFlow.fulfilled, (state, action) => {
        state.flows = state.flows.filter((flow) => flow.id !== action.payload.id)
      })
      .addCase(CompanyActions.deleteUser.fulfilled, (state, action) => {
        state.flows = action.payload.approvalFlows
      })
      .addCase(ApprovalFlowActions.saveAutoReviewBillingPhase.fulfilled, (state, action) => {
        state.flows = state.flows.map((flow) => {
          if (flow.id === action.payload.oldFlowId) {
            flow.autoReviewBillingPhase = false
          }
          if (flow.id === action.payload.flowId) {
            flow.autoReviewBillingPhase = action.payload.autoReviewBillingPhase
          }
          return flow
        })
      })
  },
})

export default approvalFlowSlice

import { Request } from './Request'
import { Moneyspace, MoneyspaceFolder, MoneyspaceWithLatestMessage, Payment } from '../types/Moneyspace'
import { RelatedUser } from '../types/User'
import { CompanyAttributes } from '../types/Company'

export default class MoneyspaceRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadMoneyspaces(silent?: boolean) {
    const response = await this.request.get<{
      moneyspaces: Moneyspace[]
    }>('/moneyspaces', undefined, undefined, silent)
    return response.data.moneyspaces
  }

  async loadMoneyspaceSortMessages(silent?: boolean) {
    const response = await this.request.get<{
      moneyspaces: MoneyspaceWithLatestMessage[]
    }>('/moneyspaces', { sort: 'messages' }, undefined, silent)
    return response.data.moneyspaces
  }

  async loadMoneyspace(id: string) {
    const response = await this.request.get<Moneyspace>(`/moneyspaces/${id}`)
    return response.data
  }

  async createMoneyspace(
    folderId: string | null,
    name: string,
    isContractee: boolean,
    payment: Payment,
    alertBillingDeadline: boolean,
    fixGracePeriod: number
  ) {
    const response = await this.request.post<Moneyspace>(`/moneyspaces`, {
      folderId,
      name,
      isContractee,
      closingDay: payment.closingDay,
      paymentMonth: payment.paymentMonth,
      paymentDay: payment.paymentDay,
      bearingFee: payment.bearingFee,
      isTaxIn: payment.isTaxIn,
      alertBillingDeadline,
      fixGracePeriod,
    })
    return response.data
  }

  async saveMoneyspace(moneyspace: Moneyspace) {
    const response = await this.request.put<Moneyspace>(`/moneyspaces/${moneyspace.id}`, { ...moneyspace })
    return response.data
  }

  async moveMoneyspace(id: string, folderId: string | null) {
    if (folderId) {
      await this.request.put(`/moneyspaces/${id}/folder`, { folderId })
    } else {
      await this.request.delete(`/moneyspaces/${id}/folder`)
    }
  }

  async deleteMoneyspace(id: string) {
    await this.request.delete(`/moneyspaces/${id}`)
  }

  async pinnedMoneyspace(id: string) {
    await this.request.put(`/moneyspaces/${id}/pinned`)
  }

  async unPinnedMoneyspace(id: string) {
    await this.request.delete(`/moneyspaces/${id}/pinned`)
  }

  async inviteMoneyspace(id: string, companyDomain: string) {
    await this.request.post(`/moneyspaces/${id}/invite`, { companyUid: companyDomain })
  }

  async setMembershipFee(id: string) {
    await this.request.put(`/moneyspaces/${id}/membership-fee`)
  }

  async loadMembers(id: string) {
    const response = await this.request.get<RelatedUser[]>(`/moneyspaces/${id}/members`)
    return response.data
  }

  async saveMembers(id: string, members: RelatedUser[]) {
    await this.request.put(`/moneyspaces/${id}/members`, { userIdList: members.map((member) => member.id) })
  }

  async deleteMembers(id: string, members: RelatedUser[]) {
    await this.request.delete(`/moneyspaces/${id}/members`, { userIdList: members.map((member) => member.id) })
  }

  async saveClient(id: string, client: CompanyAttributes) {
    await this.request.put(`/moneyspaces/${id}`, { client })
  }

  async loadFolders() {
    const response = await this.request.get<{ folders: MoneyspaceFolder[] }>(`/moneyspace-folders`)
    return response.data.folders
  }

  async createFolder(name: string) {
    const response = await this.request.post<MoneyspaceFolder>(`/moneyspace-folders`, { name })
    return response.data
  }

  async moveFolder(id: string, parentId: string | null) {
    if (parentId) {
      await this.request.post(`/moneyspace-folders/${id}/parent`, { folderId: parentId })
      return
    }
    await this.request.delete(`/moneyspace-folders/${id}/parent`)
  }

  async saveFolder(id: string, name: string) {
    await this.request.put(`/moneyspace-folders/${id}`, { name })
  }

  async deleteFolder(id: string) {
    await this.request.delete(`/moneyspace-folders/${id}`)
  }
}

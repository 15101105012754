import MuiStepper from '@mui/material/Stepper'
import MuiStep from '@mui/material/Step'
import MuiStepButton from '@mui/material/StepButton'
import MuiBadge from '@mui/material/Badge'
import { styled } from '@mui/material'

type Step = {
  id: string | number
  label: string
  completed: boolean
  badge?: boolean
  onClick?: () => void
}

type StepperProps = {
  active: number
  steps: Step[]
}

const StepBadge = styled(MuiBadge)(() => ({
  '.MuiBadge-dot': {
    top: '6%',
  },
}))

export default function Stepper({ active, steps }: StepperProps) {
  return (
    <MuiStepper
      activeStep={active}
      nonLinear
      alternativeLabel
      sx={{ width: '100%', overflow: 'hidden', paddingTop: '2px' }}
    >
      {steps.map((step) => {
        if (step.badge) {
          return (
            <MuiStep key={step.id} completed={step.completed} color="secondary" sx={{ textAlign: 'center' }}>
              <StepBadge color="warning" variant="dot" overlap="circular">
                <MuiStepButton
                  onClick={(e) => {
                    e.stopPropagation()
                    if (step.onClick) {
                      step.onClick()
                    }
                  }}
                >
                  {step.label}
                </MuiStepButton>
              </StepBadge>
            </MuiStep>
          )
        }
        return (
          <MuiStep key={step.id} completed={step.completed}>
            <MuiStepButton
              onClick={(e) => {
                e.stopPropagation()
                if (step.onClick) {
                  step.onClick()
                }
              }}
            >
              {step.label}
            </MuiStepButton>
          </MuiStep>
        )
      })}
    </MuiStepper>
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTransactionRejectDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import { FlexColumn } from '../../atoms/layout/Flex'
import TextField from '../../atoms/inputs/TextField'
import FormItem from '../../molecules/inputs/FormItem'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useProcessing from '../../../hooks/useProcessing'
import { Transaction } from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import useConsts from '../../../hooks/useConsts'

type TransactionRejectDialogProps = {
  transaction: Transaction
}

type RejectParams = {
  note?: string
}

export default function TransactionRejectDialog({ transaction }: TransactionRejectDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.rejectTransaction.typePrefix)
  const { state, close } = useTransactionRejectDialog()
  const dispatch = useDispatch()
  const { phaseName } = useConsts()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    memo: rules.documentRejectMemo,
  })
  const { handleSubmit, register } = useForm<RejectParams>(schema)
  const handleReject = useCallback(
    (params: RejectParams) => {
      startProcess()
      dispatch(TransactionActions.rejectTransaction({ transactionId: transaction.id, note: params.note }))
    },
    [dispatch, startProcess, transaction.id]
  )
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title={`${phaseName(transaction.currentPhase)}を却下する`}
      actions={
        <Button type="submit" disabled={processing}>
          却下する
        </Button>
      }
      onSubmit={handleSubmit(handleReject)}
    >
      <FlexColumn>
        <Label text={`${phaseName(transaction.currentPhase)}を却下し、下書き状態に戻しますか？`} />
        <FormItem label="却下理由">
          <TextField multiline rows={5} register={register('note')} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

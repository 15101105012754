import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell, { CellSize } from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import { ChangeLog, DocumentAttributes } from '../../../types/Document'
import useConsts from '../../../hooks/useConsts'

type DocumentDiffViewProps = {
  previousDocument: DocumentAttributes
  currentDocument: DocumentAttributes
  changeLogs: ChangeLog[]
}

function HeaderCell(props: { size: CellSize; text: string }) {
  const { size, text } = props
  return (
    <TableCell header px={1} size={size} align="center">
      <Label text={text} variant="caption" />
    </TableCell>
  )
}

function PlusCell(props: { size: CellSize; text?: string | number; diff?: boolean; amount?: boolean }) {
  const { size, text, diff, amount } = props
  return (
    <TableCell px={1} size={size} color={diff ? 'diffPlus' : 'diffPlusLight'} align="center">
      <Label text={text ?? ''} variant="caption" format={amount ? 'amountNoStyle' : undefined} />
    </TableCell>
  )
}

function MinusCell(props: { size: CellSize; text?: string | number; diff?: boolean; amount?: boolean }) {
  const { size, text, diff, amount } = props
  return (
    <TableCell px={1} size={size} color={diff ? 'diffMinus' : 'diffMinusLight'} align="center">
      <Label text={text ?? ''} variant="caption" format={amount ? 'amountNoStyle' : undefined} />
    </TableCell>
  )
}

function EmptyCell() {
  return (
    <TableCell px={1} cols={10}>
      <Label text="" />
    </TableCell>
  )
}

export default function DocumentDiffView({ previousDocument, currentDocument, changeLogs }: DocumentDiffViewProps) {
  const { taxCategories, documentTypeName } = useConsts()
  const taxText = (value?: 1 | 2 | 3) => {
    if (value) {
      return taxCategories[value]
    }
    return undefined
  }
  return (
    <Table
      header={
        <>
          <TableRow>
            <TableCell header cols={10} align="center">
              <Label text={documentTypeName(previousDocument.type)} />
            </TableCell>
            <TableCell header cols={10} align="center">
              <Label text={documentTypeName(currentDocument.type)} />
            </TableCell>
          </TableRow>
          <TableRow>
            <HeaderCell size="xs3" text="No." />
            <HeaderCell size="sm2" text="物件名" />
            <HeaderCell size="xs2" text="工事種別" />
            <HeaderCell size="xs2" text="担当者名" />
            <HeaderCell size="sm3" text="その他" />
            <HeaderCell size="xs" text="単価" />
            <HeaderCell size="xs3" text="数量" />
            <HeaderCell size="xs3" text="単位" />
            <HeaderCell size="xs3" text="税区分" />
            <HeaderCell size="xs" text="金額" />
            <HeaderCell size="xs3" text="No." />
            <HeaderCell size="sm2" text="物件名" />
            <HeaderCell size="xs2" text="工事種別" />
            <HeaderCell size="xs2" text="担当者名" />
            <HeaderCell size="sm3" text="その他" />
            <HeaderCell size="xs" text="単価" />
            <HeaderCell size="xs3" text="数量" />
            <HeaderCell size="xs3" text="単位" />
            <HeaderCell size="xs3" text="税区分" />
            <HeaderCell size="xs" text="金額" />
          </TableRow>
        </>
      }
    >
      {changeLogs.map((changeLog) => (
        <TableRow key={`${changeLog.previousDetail?.id ?? ''}-${changeLog.currentDetail?.id ?? ''}`}>
          {changeLog.status === 'change' && (
            <>
              <MinusCell size="xs3" text={changeLog.previousNo} />
              <MinusCell
                size="sm2"
                text={changeLog.previousDetail?.propertyName}
                diff={changeLog.previousDetail?.propertyName !== changeLog.currentDetail?.propertyName}
              />
              <MinusCell
                size="xs2"
                text={changeLog.previousDetail?.constructionType}
                diff={changeLog.previousDetail?.constructionType !== changeLog.currentDetail?.constructionType}
              />
              <MinusCell
                size="xs2"
                text={changeLog.previousDetail?.picName}
                diff={changeLog.previousDetail?.picName !== changeLog.currentDetail?.picName}
              />
              <MinusCell
                size="sm3"
                text={changeLog.previousDetail?.others}
                diff={changeLog.previousDetail?.others !== changeLog.currentDetail?.others}
              />
              <MinusCell
                size="xs"
                text={changeLog.previousDetail?.unitPrice}
                diff={changeLog.previousDetail?.unitPrice !== changeLog.currentDetail?.unitPrice}
                amount
              />
              <MinusCell
                size="xs3"
                text={changeLog.previousDetail?.quantity}
                diff={changeLog.previousDetail?.quantity !== changeLog.currentDetail?.quantity}
              />
              <MinusCell
                size="xs3"
                text={changeLog.previousDetail?.unit}
                diff={changeLog.previousDetail?.unit !== changeLog.currentDetail?.unit}
              />
              <MinusCell
                size="xs3"
                text={taxText(changeLog.previousDetail?.taxBucket)}
                diff={changeLog.previousDetail?.taxBucket !== changeLog.currentDetail?.taxBucket}
              />
              <MinusCell
                size="xs"
                text={changeLog.previousDetail?.amount}
                diff={changeLog.previousDetail?.amount !== changeLog.currentDetail?.amount}
                amount
              />
              <PlusCell size="xs3" text={changeLog.currentNo} />
              <PlusCell
                size="sm2"
                text={changeLog.currentDetail?.propertyName}
                diff={changeLog.previousDetail?.propertyName !== changeLog.currentDetail?.propertyName}
              />
              <PlusCell
                size="xs2"
                text={changeLog.currentDetail?.constructionType}
                diff={changeLog.previousDetail?.constructionType !== changeLog.currentDetail?.constructionType}
              />
              <PlusCell
                size="xs2"
                text={changeLog.currentDetail?.picName}
                diff={changeLog.previousDetail?.picName !== changeLog.currentDetail?.picName}
              />
              <PlusCell
                size="sm3"
                text={changeLog.currentDetail?.others}
                diff={changeLog.previousDetail?.others !== changeLog.currentDetail?.others}
              />
              <PlusCell
                size="xs"
                text={changeLog.currentDetail?.unitPrice}
                diff={changeLog.previousDetail?.unitPrice !== changeLog.currentDetail?.unitPrice}
                amount
              />
              <PlusCell
                size="xs3"
                text={changeLog.currentDetail?.quantity}
                diff={changeLog.previousDetail?.quantity !== changeLog.currentDetail?.quantity}
              />
              <PlusCell
                size="xs3"
                text={changeLog.currentDetail?.unit}
                diff={changeLog.previousDetail?.unit !== changeLog.currentDetail?.unit}
              />
              <PlusCell
                size="xs3"
                text={taxText(changeLog.currentDetail?.taxBucket)}
                diff={changeLog.previousDetail?.taxBucket !== changeLog.currentDetail?.taxBucket}
              />
              <PlusCell
                size="xs"
                text={changeLog.currentDetail?.amount}
                diff={changeLog.previousDetail?.amount !== changeLog.currentDetail?.amount}
                amount
              />
            </>
          )}
          {changeLog.status === 'add' && (
            <>
              <EmptyCell />
              <PlusCell size="xs3" text={changeLog.currentNo} />
              <PlusCell size="sm2" text={changeLog.currentDetail?.propertyName} />
              <PlusCell size="xs2" text={changeLog.currentDetail?.constructionType} />
              <PlusCell size="xs2" text={changeLog.currentDetail?.picName} />
              <PlusCell size="sm3" text={changeLog.currentDetail?.others} />
              <PlusCell size="xs" text={changeLog.currentDetail?.unitPrice} amount />
              <PlusCell size="xs3" text={changeLog.currentDetail?.quantity} />
              <PlusCell size="xs3" text={changeLog.currentDetail?.unit} />
              <PlusCell size="xs3" text={taxText(changeLog.currentDetail?.taxBucket)} />
              <PlusCell size="xs" text={changeLog.currentDetail?.amount} amount />
            </>
          )}
          {changeLog.status === 'delete' && (
            <>
              <MinusCell size="xs3" text={changeLog.previousNo} />
              <MinusCell size="sm2" text={changeLog.previousDetail?.propertyName} />
              <MinusCell size="xs2" text={changeLog.previousDetail?.constructionType} />
              <MinusCell size="xs2" text={changeLog.previousDetail?.picName} />
              <MinusCell size="sm3" text={changeLog.previousDetail?.others} />
              <MinusCell size="xs" text={changeLog.previousDetail?.unitPrice} amount />
              <MinusCell size="xs3" text={changeLog.previousDetail?.quantity} />
              <MinusCell size="xs3" text={changeLog.previousDetail?.unit} />
              <MinusCell size="xs3" text={taxText(changeLog.previousDetail?.taxBucket)} />
              <MinusCell size="xs" text={changeLog.previousDetail?.amount} amount />
              <EmptyCell />
            </>
          )}
        </TableRow>
      ))}
    </Table>
  )
}

import { ChangeLog, DocumentDiffs, DocumentItem } from '../types/Document'

function same(detail1: DocumentItem, detail2: DocumentItem): boolean {
  if (detail1.propertyName !== detail2.propertyName) {
    return false
  }
  if (detail1.constructionType !== detail2.constructionType) {
    return false
  }
  if (detail1.picName !== detail2.picName) {
    return false
  }
  if (detail1.others !== detail2.others) {
    return false
  }
  if (detail1.unitPrice !== detail2.unitPrice) {
    return false
  }
  if (detail1.quantity !== detail2.quantity) {
    return false
  }
  if (detail1.unit !== detail2.unit) {
    return false
  }
  if (detail1.taxBucket !== detail2.taxBucket) {
    return false
  }
  return detail1.amount === detail2.amount
}

export default function useDocumentDiff(diffs?: DocumentDiffs) {
  if (diffs === undefined) {
    return {
      changeLogs: [],
      updated: false,
    }
  }

  const currentDocumentDetailIds = diffs.currentDocument.document.details.map((detail) => detail.id)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const addedDetailIds = currentDocumentDetailIds.filter((id) => !diffs.currentDocument.detailIds.includes(id!))
  const deletedDetailIds = diffs.currentDocument.detailIds.filter((id) => !currentDocumentDetailIds.includes(id))
  let previousIndex = 0
  let currentIndex = 0
  const changeLogs: ChangeLog[] = diffs.currentDocument.detailIds.map((id) => {
    if (deletedDetailIds.includes(id)) {
      const prevIndex = previousIndex
      previousIndex += 1
      return {
        status: 'delete',
        previousNo: prevIndex + 1,
        previousDetail: diffs.previousDocument.document.details[prevIndex],
        currentDetail: undefined,
      }
    }
    const prevIndex = previousIndex
    const curIndex = currentIndex
    previousIndex += 1
    currentIndex += 1
    if (same(diffs.previousDocument.document.details[prevIndex], diffs.currentDocument.document.details[curIndex])) {
      return {
        status: 'same',
        previousNo: prevIndex + 1,
        currentNo: curIndex + 1,
        previousDetail: diffs.previousDocument.document.details[prevIndex],
        currentDetail: diffs.currentDocument.document.details[curIndex],
      }
    }
    return {
      status: 'change',
      previousNo: prevIndex + 1,
      currentNo: curIndex + 1,
      previousDetail: diffs.previousDocument.document.details[prevIndex],
      currentDetail: diffs.currentDocument.document.details[curIndex],
    }
  })
  addedDetailIds.forEach((id) => {
    const detail = diffs.currentDocument.document.details.find((item) => item.id === id)
    if (detail) {
      const curIndex = currentIndex
      currentIndex += 1
      changeLogs.push({
        status: 'add',
        currentNo: curIndex + 1,
        previousDetail: undefined,
        currentDetail: detail,
      })
    }
  })
  const updated = changeLogs.some((log) => log.status !== 'same')

  return {
    changeLogs,
    updated,
  }
}

import { useDispatch } from 'react-redux'
import { SubmitHandler } from 'react-hook-form'
import Form from '../../atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import CompanyDomainField from '../../molecules/inputs/CompanyDomainField'
import EmailField from '../../molecules/inputs/EmailField'
import PasswordField from '../../molecules/inputs/PasswordField'
import FormItem from '../../molecules/inputs/FormItem'
import useYup from '../../../hooks/useYup'
import useForm from '../../../hooks/useForm'
import useValidationRule from '../../../hooks/useValidationRule'
import Button from '../../atoms/inputs/Button'
import { SessionActions } from '../../../store/session'
import useProcessing from '../../../hooks/useProcessing'

type LoginFormProps = {}

type LoginParams = {
  companyDomain: string
  email: string
  password: string
}

export default function LoginForm({}: LoginFormProps) {
  const { processing, startProcess } = useProcessing(SessionActions.login.typePrefix)
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    companyDomain: rules.companyDomain.required(),
    email: rules.email.required(),
    password: rules.password.required(),
  })
  const { handleSubmit, register, errors } = useForm<LoginParams>(schema)
  const dispatch = useDispatch()

  const handleLogin: SubmitHandler<LoginParams> = (values) => {
    startProcess()
    dispatch(
      SessionActions.login({ companyDomain: values.companyDomain, email: values.email, password: values.password })
    )
  }
  return (
    <Form onSubmit={handleSubmit(handleLogin)}>
      <FlexColumn space={2} align="center">
        <FormItem label="あなたの企業ID" required>
          <CompanyDomainField register={register('companyDomain')} error={errors.companyDomain} />
        </FormItem>
        <FormItem label="メールアドレス" required>
          <EmailField register={register('email')} error={errors.email} />
        </FormItem>
        <FormItem label="パスワード" required>
          <PasswordField register={register('password')} error={errors.password} />
        </FormItem>
        <FlexRow justify="center">
          <Button type="submit" disabled={processing}>
            ログイン
          </Button>
        </FlexRow>
      </FlexColumn>
    </Form>
  )
}

import { useEffect, useRef, useState } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Avatar from '../../atoms/display/Avatar'
import Card from '../../atoms/surfaces/Card'
import TextBox from '../../atoms/display/TextBox'
import { ChatMessage } from '../../../types/Chat'
import useDay from '../../../hooks/useDay'
import Divider from '../../atoms/display/Divider'
import Alert from '../../atoms/feedback/Alert'
import { useDeviceType } from '../../../hooks/useDeviceType'

type MessageBoardProps = {
  currentUserId: string
  messages: ChatMessage[]
  height?: string
}

function isShowDate(index: number, messages: ChatMessage[], formatDate: (date: string) => string): boolean {
  if (index === 0) {
    return true
  }
  return formatDate(messages[index - 1].createdAt) !== formatDate(messages[index].createdAt)
}

function isShowNew(index: number, messages: ChatMessage[]) {
  if (index === 0) {
    return !messages[index].isRead
  }
  return !messages[index].isRead && messages[index - 1].isRead
}

export default function MessageBoard({ currentUserId, messages, height }: MessageBoardProps) {
  const { pc } = useDeviceType()
  const ref = useRef<HTMLDivElement>()
  const [messageCount, setMessageCount] = useState<number>()
  const { formatDate, formatTime, isAfter } = useDay()
  useEffect(() => {
    if ((ref && messageCount === undefined) || messageCount !== messages.length) {
      ref.current?.scrollIntoView()
      setMessageCount(messages.length)
    }
  }, [ref, messages, messageCount])
  const sortedMessages = messages
    .slice()
    .sort((message1, message2) => (isAfter(message1.createdAt, message2.createdAt) ? 1 : -1))
  let computedHeight = height
  if (!computedHeight) {
    if (pc) {
      computedHeight = '480px'
    } else {
      computedHeight = '360px'
    }
  }
  return (
    <FlexColumn height={computedHeight} space={2}>
      {sortedMessages.length === 0 && <Alert severity="success">メッセージはありません</Alert>}
      {sortedMessages.map((message, index) => (
        <FlexColumn key={message.id} scroll={false}>
          {isShowDate(index, sortedMessages, formatDate) && (
            <FlexRow justify="center" scroll={false}>
              <Label text={formatDate(message.createdAt)} />
            </FlexRow>
          )}
          {isShowNew(index, sortedMessages) && (
            <Divider>
              <Label text="New" color="primary" />
            </Divider>
          )}
          <FlexColumn space={0} align={message.createdBy?.id === currentUserId ? 'flex-end' : 'flex-start'} px={2}>
            <FlexRow align="flex-end" scroll={false} ref={index + 1 === sortedMessages.length ? ref : undefined}>
              {message.createdBy?.id === currentUserId ? (
                <Label text={formatTime(message.createdAt)} variant="caption" />
              ) : (
                <Avatar alt={message.createdBy?.name ?? ''} url={message.createdBy?.icon} />
              )}
              <Card color={message.createdBy?.id === currentUserId ? 'primary' : 'info'} size="full">
                <FlexColumn>
                  {message.destinations?.map((user) => (
                    <FlexRow align="flex-end">
                      <Label text="TO" variant="caption" />
                      <Avatar alt={user.name} url={user.icon} size="sm" />
                      <Label text={user.name} variant="caption" />
                    </FlexRow>
                  ))}
                  <TextBox>{message.description}</TextBox>
                </FlexColumn>
              </Card>
              {message.createdBy?.id === currentUserId ? (
                <Avatar alt={message.createdBy.name} url={message.createdBy.icon} />
              ) : (
                <Label text={formatTime(message.createdAt)} variant="caption" />
              )}
            </FlexRow>
            <Label text={message.createdBy?.name ?? ''} variant="caption" />
          </FlexColumn>
        </FlexColumn>
      ))}
    </FlexColumn>
  )
}

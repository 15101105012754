import { useSelector } from 'react-redux'
import { TransactionPhase } from '../../../types/transaction'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexRow } from '../../atoms/layout/Flex'
import PdfViewer from '../../atoms/display/PdfViewer'
import { useTransactionPdfPreviewDialog } from '../../../hooks/useDialog'
import usePdf from '../../../hooks/usePdf'
import useTransaction from '../../../hooks/useTransaction'
import { SessionSelectors } from '../../../store/session/selectors'
import { useDeviceType } from '../../../hooks/useDeviceType'

type TransactionPdfPreviewDialogProps = {
  transactionId: string
  phase: TransactionPhase
}

export default function TransactionPdfPreviewDialog({ transactionId, phase }: TransactionPdfPreviewDialogProps) {
  const { state, close } = useTransactionPdfPreviewDialog()
  const pdfParams = usePdf({})
  const { pdfUrl } = useTransaction()
  const token = useSelector(SessionSelectors.token)
  const { pc } = useDeviceType()

  if (token) {
    return (
      <Dialog open={state !== undefined} width="lg" title="取引PDF" onClose={close}>
        <FlexRow align="center" justify="space-between">
          <PdfViewer
            url={pdfUrl(transactionId, phase)}
            httpHeaders={{
              authorization: `Token ${token}`,
            }}
            height={pc ? 960 : 480}
            scale={pc ? 1.15 : 0.42}
            params={pdfParams}
          />
        </FlexRow>
      </Dialog>
    )
  }
  return <></>
}

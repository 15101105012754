import Label from '../../atoms/display/Label'
import { Moneyspace } from '../../../types/Moneyspace'
import { DocumentStatus, DocumentType } from '../../../types/Document'

type DocumentStatusLabelProps = {
  documentStatus: DocumentStatus
  documentType: DocumentType
  moneyspace?: Moneyspace
  bold?: boolean
}

function statusName(documentStatus: DocumentStatus, documentType: DocumentType, moneyspace?: Moneyspace) {
  switch (documentStatus) {
    case 1:
      return '下書き'
    case 2:
      if ([1, 3, 6].includes(documentType)) {
        return `${moneyspace?.contractee?.name ?? '社内'} 確認中`
      }
      return `${moneyspace?.contractor?.name ?? '社内'} 確認中`
    case 3:
      if ([1, 3, 6].includes(documentType)) {
        return `${moneyspace?.contractor?.name ?? '取引先'} 確認中`
      }
      return `${moneyspace?.contractee?.name ?? '取引先'} 確認中`
    case 4:
      return '完了'
    default:
      return '不明'
  }
}

export default function DocumentStatusLabel({
  documentStatus,
  documentType,
  moneyspace,
  bold,
}: DocumentStatusLabelProps) {
  const name = statusName(documentStatus, documentType, moneyspace)
  return <Label text={name} variant={bold ? 'subtitle' : undefined} />
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentsSelectors } from '../store/documents/selectors'
import useMoneyspace from './useMoneyspace'
import { SessionSelectors } from '../store/session/selectors'
import useDay from './useDay'
import { Document } from '../types/Document'
import { MoneyspacesSelectors } from '../store/moneyspaces/selectors'
import { Transaction, TransactionPhaseBilling, TransactionStatusClosed } from '../types/transaction'
import { TransactionSelectors } from '../store/transactions/selectors'
import { TransactionActions } from '../store/transactions'

export default function useFactoring(moneyspaceId?: string) {
  const { find, isContractee } = useMoneyspace()
  const user = useSelector(SessionSelectors.user)
  const companyId = useSelector(SessionSelectors.companyId)
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const { afterDay, before } = useDay()
  const moneyspace = find(moneyspaceId)
  const dispatch = useDispatch()
  const factoringDocuments = useSelector(DocumentsSelectors.factoringDocuments)
  const factoringTransactions = useSelector(TransactionSelectors.factoringTransactions)
  const [factoringUrl, setFactoringUrl] = useState<string | undefined>()
  const hasFactoringAvailableContactee =
    moneyspaces
      .map((ms) => ms.contractee)
      .filter((contractee) => contractee?.id !== companyId)
      .filter((contractee) => contractee?.hasLagLessAccount).length > 0
  useEffect(() => {
    setFactoringUrl(undefined)
    if (moneyspace === undefined || isContractee(moneyspace, user) || !moneyspace.contractee?.hasLagLessAccount) {
      return
    }
    if (factoringTransactions) {
      const transaction = factoringTransactions.find((item) => item.moneyspace.id === moneyspaceId)
      if (transaction) {
        setFactoringUrl(transaction.factoringUrl)
      }
    }
  }, [factoringDocuments, factoringTransactions, isContractee, moneyspace, moneyspaceId, user])
  const canFactoringDocument = useCallback(
    (document?: Document) => {
      if (!document) {
        return false
      }
      if (moneyspace && document && document.contractee) {
        if (!isContractee(moneyspace, user)) {
          if (!moneyspace.contractee?.hasLagLessAccount || !factoringUrl) {
            return false
          }
          if (document.type === 7 && document.status === 4 && before(afterDay(-20, document.contractee.paymentDate))) {
            return true
          }
        }
      }
      return false
    },
    [afterDay, before, factoringUrl, isContractee, moneyspace, user]
  )
  const canFactoringTransaction = useCallback(
    (transaction?: Transaction) => {
      if (!transaction) {
        return false
      }
      if (moneyspace && transaction.contractee && transaction.paymentDate) {
        if (!isContractee(moneyspace, user)) {
          if (!moneyspace.contractee?.hasLagLessAccount || !factoringUrl) {
            return false
          }
          if (
            transaction.currentPhase === TransactionPhaseBilling &&
            transaction.status === TransactionStatusClosed &&
            before(afterDay(-20, transaction.paymentDate))
          ) {
            return true
          }
        }
      }
      return false
    },
    [afterDay, before, factoringUrl, isContractee, moneyspace, user]
  )
  useEffect(() => {
    dispatch(TransactionActions.loadFactoringTransaction())
  }, [dispatch])
  return {
    factoringUrl,
    canFactoringDocument,
    canFactoringTransaction,
    hasFactoringAvailableContactee,
  }
}

import { Control, FieldErrors, UseFormRegister } from 'react-hook-form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import BankAccountTypeField from '../../molecules/inputs/BankAccountTypeField'
import BankAccountNumberField from '../../molecules/inputs/BankAccountNumberField'
import BankAccountNameField from '../../molecules/inputs/BankAccountNameField'
import { BankAccount } from '../../../types/Company'
import PayAdjustmentTimingField from '../../molecules/inputs/PayAdjustmentTimingField'
import BankCodeField from '../../molecules/inputs/BankCodeField'
import BankNameField from '../../molecules/inputs/BankNameField'
import BankBranchOfficeCodeField from '../../molecules/inputs/BankBranchOfficeCodeField'
import BankBranchOfficeNameField from '../../molecules/inputs/BankBranchOfficeNameField'

type BankAccountFormProps = {
  register: UseFormRegister<BankAccount>
  control: Control
  errors: FieldErrors<BankAccount>
}

export default function BankAccountForm({ register, control, errors }: BankAccountFormProps) {
  return (
    <FlexColumn>
      <FlexRow wrap>
        <FormItem label="銀行コード">
          <BankCodeField register={register('bankCode')} error={errors.bankCode} />
        </FormItem>
        <FormItem label="銀行名">
          <BankNameField register={register('bankName')} error={errors.bankName} />
        </FormItem>
      </FlexRow>
      <FlexRow wrap>
        <FormItem label="支店コード">
          <BankBranchOfficeCodeField register={register('branchCode')} error={errors.branchCode} />
        </FormItem>
        <FormItem label="支店名">
          <BankBranchOfficeNameField register={register('branchName')} error={errors.branchName} />
        </FormItem>
      </FlexRow>
      <FlexRow wrap>
        <FormItem label="口座種別">
          <BankAccountTypeField name="accountType" control={control} />
        </FormItem>
        <FormItem label="口座番号">
          <BankAccountNumberField register={register('accountNumber')} error={errors.accountNumber} />
        </FormItem>
        <FormItem label="口座名（半角カナ）">
          <BankAccountNameField register={register('accountOwner')} error={errors.accountOwner} />
        </FormItem>
      </FlexRow>
      <FlexRow>
        <FormItem label="支払日が休祝日の場合">
          <PayAdjustmentTimingField control={control} name="payAfterHolidays" />
        </FormItem>
      </FlexRow>
    </FlexColumn>
  )
}

import { useFormContext } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import RadioControl from '../../atoms/inputs/RadioControl'
import FormItem from '../../molecules/inputs/FormItem'
import DateControl from '../../atoms/inputs/DateControl'
import TimeControl from '../../atoms/inputs/TimeControl'
import SelectControl from '../../atoms/inputs/SelectControl'
import DayFieldControl from '../../molecules/inputs/DayFieldControl'
import TextField from '../../atoms/inputs/TextField'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import { useDeviceType } from '../../../hooks/useDeviceType'

type Props = {
  moneyspaceIds: string[]
  edit?: boolean
}

export type FormParams = {
  type: number
  date?: string
  time?: Date
  repeatType?: string
  repeatDayOfWeek?: number
  repeatDayOfMonth?: number
  repeatTime?: Date
  repeatBaseDate?: string
  message: string
}

export default function MessageForm({ moneyspaceIds, edit }: Props) {
  const { pc } = useDeviceType()
  const [type, setType] = useState<number>(1)
  const [repeatType, setRepeatType] = useState<string>('1')
  const [selection, setSelection] = useState<{ start: number; end: number }>({ start: 0, end: 0 })
  const form = useFormContext<FormParams>()
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    form.watch((value, { name }) => {
      setError(null)
      if (value.message && value.message.length > 200) {
        setError('メッセージは200文字以下で入力してください')
      }
      if (name === 'type') {
        setType(Number(value.type ?? 1))
      }
      if (name === 'repeatType') {
        setRepeatType(value.repeatType ?? '1')
      }
    })
  }, [form, type])

  const handleAddText = useCallback(
    (text: string) => {
      if (selection) {
        const { start, end } = selection
        const { message } = form.getValues()
        form.setValue('message', `${message.slice(0, start)}${text}${message.slice(end)}`)
      }
    },
    [form, selection]
  )
  const typeItems = edit
    ? [
        {
          label: '予約送信',
          value: 2,
        },
        {
          label: '繰り返し送信',
          value: 3,
        },
      ]
    : [
        { label: '今すぐ送信', value: 1 },
        {
          label: '予約送信',
          value: 2,
        },
        {
          label: '繰り返し送信',
          value: 3,
        },
      ]

  return (
    <FlexColumn width={pc ? 'calc(100% - 370px)' : '100%'}>
      <FlexRow>
        <FlexColumn width={pc ? undefined : '100px'}>
          <RadioControl items={typeItems} name="type" control={form.control} />
        </FlexColumn>
        <FlexColumn>
          {type === 2 && (
            <FlexRow align="center" wrap>
              <FormItem label="送信日">
                <DateControl name="date" control={form.control} clearable={false} />
              </FormItem>
              <FormItem label="時刻">
                <TimeControl name="time" control={form.control} />
              </FormItem>
            </FlexRow>
          )}
          {type === 3 && (
            <FlexRow align="center" wrap>
              <FormItem label="繰り返し方">
                <SelectControl
                  name="repeatType"
                  items={[
                    { label: '毎日', value: '1' },
                    { label: '毎週', value: '2' },
                    { label: '隔週', value: '3' },
                    { label: '毎月', value: '4' },
                    { label: '四半期ごと', value: '5' },
                    { label: '半年ごと', value: '6' },
                    { label: '毎年', value: '7' },
                  ]}
                  control={form.control}
                />
              </FormItem>
              {['2', '3'].includes(repeatType) && (
                <FormItem label="曜日">
                  <SelectControl
                    name="repeatDayOfWeek"
                    items={[
                      { label: '月曜日', value: 2 },
                      { label: '火曜日', value: 3 },
                      { label: '水曜日', value: 4 },
                      { label: '木曜日', value: 5 },
                      { label: '金曜日', value: 6 },
                      { label: '土曜日', value: 7 },
                      { label: '日曜日', value: 1 },
                    ]}
                    control={form.control}
                  />
                </FormItem>
              )}
              {repeatType === '4' && (
                <FormItem label="日付">
                  <DayFieldControl control={form.control} name="repeatDayOfMonth" />
                </FormItem>
              )}
              {['5', '6', '7'].includes(repeatType) && (
                <FormItem label="基準日">
                  <DateControl name="repeatBaseDate" control={form.control} clearable={false} />
                </FormItem>
              )}
              <FormItem label="時刻">
                <TimeControl name="repeatTime" control={form.control} />
              </FormItem>
            </FlexRow>
          )}
        </FlexColumn>
      </FlexRow>
      <FormItem label="メッセージ">
        <TextField
          multiline
          rows={pc ? 10 : 3}
          register={form.register('message')}
          onSelect={(start, end) => setSelection({ start, end })}
        />
      </FormItem>
      {error && (
        <FlexRow>
          <Label color="error" text={error} variant="caption" />
        </FlexRow>
      )}
      <FlexRow>
        <Button size="lg" variant="outlined" onClick={() => handleAddText('{{取引先名}}')}>
          取引先名をメッセージに追加
        </Button>
      </FlexRow>
      <FlexRow justify="flex-end">
        <Button size="sm" type="submit" disabled={moneyspaceIds.length === 0 || error != null}>
          {type === 1 && <Label text="送信" />}
          {[2, 3].includes(type) && <Label text="設定" />}
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}

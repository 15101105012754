import RoutesProvider from './components/systems/RoutesProvider'
import Style from './components/systems/Style'
import Store from './components/systems/Store'
import System from './components/systems/System'
import DialogProvider from './components/systems/DialogProvider'
import DataProvider from './components/systems/DataProvider'
import MaintenancePage from './pages/maintenance'

function App() {
  const maintenance = process.env.REACT_APP_MAINTENANCE
  return (
    <Store>
      <Style>
        {maintenance ? (
          <MaintenancePage />
        ) : (
          <System>
            <DataProvider>
              <RoutesProvider />
              <DialogProvider />
            </DataProvider>
          </System>
        )}
      </Style>
    </Store>
  )
}

export default App

import { useCallback, useState } from 'react'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import { Transaction } from '../../../types/transaction'
import { FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import IconButton from '../../atoms/inputs/IconButton'
import { DownIcon, UpIcon } from '../../atoms/display/Icons'
import Collapse from '../../atoms/display/Collapse'
import TaxCategoryLabel from '../../molecules/display/TaxCategoryLabel'

type TransactionInstallmentDetailTableProps = {
  transaction: Transaction
}

function findInstallmentDetails(transaction: Transaction) {
  if (transaction.installment && transaction.times) {
    if (transaction.times in transaction.installment.details.times) {
      return transaction.installment.details.times[transaction.times]
    }
    if (transaction.times - 1 in transaction.installment.details.times) {
      return transaction.installment.details.times[transaction.times - 1]
    }
  }
  return []
}

export default function TransactionInstallmentDetailTable({ transaction }: TransactionInstallmentDetailTableProps) {
  const [rowStates, setRowStates] = useState<boolean[]>(transaction.details.map(() => false))
  const installmentDetails = findInstallmentDetails(transaction)

  const handleOpen = useCallback(
    (index: number) => {
      setRowStates(
        rowStates.map((state, i) => {
          if (i === index) {
            return !state
          }
          return state
        })
      )
    },
    [rowStates]
  )

  if (transaction.installment && transaction.times) {
    return (
      <Table
        header={
          <TableRow>
            <TableCell header size="xxs" />
            <TableCell header align="center">
              項目
            </TableCell>
            <TableCell header align="center" size="sm2">
              今回の請求額
            </TableCell>
            <TableCell header align="center" size="sm2">
              残額
            </TableCell>
          </TableRow>
        }
      >
        {installmentDetails.map((detail, index) => (
          <>
            <TableRow noBorder>
              <TableCell size="xxs" noBorder>
                <IconButton label="" onClick={() => handleOpen(index)}>
                  {rowStates[index] ? <DownIcon /> : <UpIcon />}
                </IconButton>
              </TableCell>
              <TableCell noBorder>
                <FlexRow wrap>
                  <Label text={transaction.installment?.details.original[index].defaultProp ?? ''} />
                  <Label text={transaction.installment?.details.original[index].customProp1 ?? ''} />
                  <Label text={transaction.installment?.details.original[index].customProp2 ?? ''} />
                </FlexRow>
              </TableCell>
              <TableCell align="right" size="sm2" noBorder>
                <Label text={transaction.details[index].amount} format="amountWithUnit" />
              </TableCell>
              <TableCell align="right" size="sm2" noBorder>
                <Label text={detail.balance - detail.paid} format="amountWithUnit" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell py={0} cols={4}>
                <Collapse open={rowStates[index]}>
                  <FlexRow pl={32}>
                    <Table
                      header={
                        <TableRow>
                          <TableCell header align="center" size="xs">
                            単価
                          </TableCell>
                          <TableCell header align="center" size="xs2">
                            数量
                          </TableCell>
                          <TableCell header align="center" size="xs2">
                            単位
                          </TableCell>
                          <TableCell header align="center" size="xs2">
                            税区分
                          </TableCell>
                          <TableCell header align="center" size="sm2">
                            金額
                          </TableCell>
                        </TableRow>
                      }
                    >
                      <TableRow noBorder>
                        <TableCell align="right" size="sm3" noBorder>
                          <Label
                            text={transaction.installment?.details.original[index].unitPrice ?? 0}
                            format="amountWithUnit"
                          />
                        </TableCell>
                        <TableCell align="right" size="xs2" noBorder>
                          <Label text={transaction.installment?.details.original[index].quantity ?? 0} />
                        </TableCell>
                        <TableCell align="center" size="xs2" noBorder>
                          <Label text={transaction.installment?.details.original[index].unit ?? 0} />
                        </TableCell>
                        <TableCell align="center" size="xs2" noBorder>
                          <TaxCategoryLabel
                            category={transaction.installment?.details.original[index].taxBucket ?? 1}
                          />
                        </TableCell>
                        <TableCell align="right" size="sm2" noBorder>
                          <Label
                            text={transaction.installment?.details.original[index].amount ?? 0}
                            format="amountWithUnit"
                          />
                        </TableCell>
                      </TableRow>
                    </Table>
                  </FlexRow>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))}
      </Table>
    )
  }
  return <></>
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn } from '../../atoms/layout/Flex'
import TextField from '../../atoms/inputs/TextField'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import useValidationRule from '../../../hooks/useValidationRule'
import Form from '../../atoms/inputs/Form'
import { CompanySelectors } from '../../../store/company/selectors'
import { CompanyActions } from '../../../store/company'
import { MoneyspacesActions } from '../../../store/moneyspaces'

type FormParams = {
  text: string | null
}

export default function CompanyCovenant() {
  const company = useSelector(CompanySelectors.company)
  const dispatch = useDispatch()
  const rules = useValidationRule()
  const form = useForm<FormParams>({
    resolver: yupResolver(rules.covenant),
    defaultValues: {
      text: company?.covenant,
    },
  })
  const handleSave = useCallback(
    (params: FormParams) => {
      dispatch(
        CompanyActions.saveCovenant({
          covenant: params.text,
          onDone: () => {
            dispatch(MoneyspacesActions.fetchMoneyspaces({}))
          },
        })
      )
    },
    [dispatch]
  )

  return (
    <Form onSubmit={form.handleSubmit(handleSave)}>
      <FlexColumn width={640}>
        <Label text="発注フェーズに表示する誓約条項" />
        <TextField multiline rows={20} register={form.register('text')} error={form.formState.errors.text?.message} />
        <Button type="submit">保存</Button>
      </FlexColumn>
    </Form>
  )
}

import { Control } from 'react-hook-form'
import RadioGroup from '../../atoms/inputs/RadioGroup'

type MoneyspacePositionSelectProps = {
  name: string
  control: Control
}

export default function MoneyspacePositionSelect({ name, control }: MoneyspacePositionSelectProps) {
  return (
    <RadioGroup
      items={[
        {
          value: true,
          label: '発注者としてマネースペースに参加する',
        },
        {
          value: false,
          label: '受注者としてマネースペースに参加する',
        },
      ]}
      name={name}
      control={control}
    />
  )
}

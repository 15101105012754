import MuiBox from '@mui/material/Box'
import { UIEvent, useCallback } from 'react'

type TermsProps = {
  height: number
  onScrolledToBottom?: () => void
}

export default function Terms({ height, onScrolledToBottom }: TermsProps) {
  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      if (onScrolledToBottom) {
        if (e.currentTarget.scrollTop + e.currentTarget.offsetHeight >= e.currentTarget.scrollHeight)
          onScrolledToBottom()
      }
    },
    [onScrolledToBottom]
  )
  return (
    <MuiBox
      height={height}
      sx={{
        whiteSpace: 'pre-wrap',
        border: 'solid 1px #ccc',
        overflowY: 'scroll',
        '& > p': {
          fontSize: '0.8rem',
        },
      }}
      padding={2}
      component="div"
      onScroll={handleScroll}
    >
      <p style={{ textAlign: 'center' }}>monect サービス利用規約</p>
      <p>
        この利用規約（以下、「本規約」といいます。）は、インベストデザイン株式会社（以下、「当社」といいます。）がこのウェブサイト上で提供する本サービス（第2条に定義）の利用条件を定めるものです。本サービスを利用する全ての方には、本規約に従って、本サービスをご利用いただきます。
      </p>
      <p />
      <p />
      <p>第1条 （適用）</p>
      <p>1. 本規約は、利用者と当社との間で、本サービスの利用に関わる一切の関係に適用されるものとします。</p>
      <p>
        2.
        当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
      </p>
      <p>
        3.
        本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
      </p>
      <p />
      <p>第2条 （定義）</p>
      <p>
        本規約において用いられる用語は、本規約において別途定義される場合または文脈上別異に解することが明らかである場合を除き、それぞれ以下において定義される意味を有するものとします。
      </p>
      <p>
        (1)「本サービス」とは、当社が「monect」という名称にて提供する、請求書等管理サービス、支払代行サービスおよびこれらに付帯するサービスの総称をいいます。
      </p>
      <p>
        (2)「請求等管理サービス」とは、本サービスのうち、見積書、発注書、請求書等の各種経理関連書類の作成、保管および記録並びに利用者間における各種請求等の管理サービスをいいます。
      </p>
      <p>
        (3)「支払代行サービス」とは、本サービスのうち、受注者が発注者に対して有する利用者間契約に基づく債権（以下、「本件債権」といいます。）について、受注者からの委託を受けた範囲内において、発注者から受注者に対して行う支払いの代行サービスをいいます。
      </p>
      <p>(4)「利用契約」とは、当社と利用者との間で締結される本サービスの利用契約をいいます。</p>
      <p>(5)「利用者」とは、利用契約の当事者となる法人、団体または個人事業主をいいます。</p>
      <p>
        (6)「ユーザー」とは、利用者である法人、団体に所属する者であって、利用契約に基づき利用者が本サービスを利用できる範囲内で、かつ、所定の方法により本サービス上で登録されることによって本サービスの利用が可能となる者をいいます。
      </p>
      <p>
        (7)「利用者間契約」とは、本件債権の発生原因となる、発注書および受注者との間で締結される業務委託契約、請負契約その他の各種契約をいいます。
      </p>
      <p>(8)「発注者」とは、利用者のうち、受注者に対して利用者間契約に基づき業務を発注した者をいいます。</p>
      <p>
        (9)「受注者」とは、利用者のうち、発注者から利用者間契約に基づき業務を受注した者であって、本件債権を有する者をいいます。
      </p>
      <p>(10)「本サイト」とは、当社所定の本サービスのサイトをいいます。</p>
      <p />
      <p>第3条 （利用登録）</p>
      <p />
      <p />
      <p>
        1.
        本サービスの利用を希望する場合、利用者となることを希望する者（以下、「利用希望者」といいます。）は、本サイトにおいて本規約に同意の上、当社所定の方法により、利用登録に必要な事項に関する情報を当社に提供すると共に、本サービスの利用申請をするものとします。
      </p>
      <p>
        2.
        利用希望者は当社に提供する情報または当社に届け出る事項が正確かつ最新の内容であること、並びに、利用希望者に所属し、前項の利用申請をする者が、本サービスの契約締結権限を有する者であることまたは契約締結権限を有する者から契約締結手続きの代行権限を付与されていることを表明し、保証するものとします。
      </p>
      <p>
        3.
        第1項に基づく利用申請に対し、当社が所定の基準および方法によって審査をしたうえで、当社が利用を認めることによって、利用者の利用登録が完了するものとします。
      </p>
      <p>
        4.
        当社は、利用希望者の利用申込みに関し、以下の事由があると判断した場合、利用登録を拒否することができるものとし、また、利用登録後に利用者に以下の事由があると事後的に判明した場合にも、利用者の利用を停止または利用者の削除を行うことができるものとし、当社はその理由については一切の開示義務を負わないものとします。
      </p>
      <p>(1) 利用登録の申請に際して虚偽または誤った事項を登録した場合</p>
      <p>
        (2) 本規約その他当社との間で締結中の契約もしくは過去に締結した契約に違反したことがある者からの申請である場合
      </p>
      <p>
        (3)
        反社会的勢力等（現在、暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標榜ゴロまたは特殊知能暴力集団等、その他これらに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
      </p>
      <p>(4) その他、当社が利用登録を相当でないと判断した場合</p>
      <p>5. 第3項の利用登録の完了をもって、当社と利用者との間で本サービスの利用契約が成立するものとします。</p>
      <p />
      <p>第4条 （利用者IDおよびパスワードの管理）</p>
      <p />
      <p />
      <p>1. 前条に基づく利用契約が成立した場合、利用者は所定の方法によりユーザーを登録することができるものとします。</p>
      <p>
        2.
        当社は、前項に基づき利用者が登録したユーザーに対して本サービスのユーザーIDおよびパスワード等のユーザーを識別する符号（以下、「アカウント等」といいます。）を付与するものとし、ユーザーは、自己の費用と責任において、本サービスのアカウント等を適切に管理するものとします。
      </p>
      <p>
        3.
        ユーザーは、いかなる場合にも、アカウント等を第三者に譲渡、貸与もしくは第三者との共用、売買、担保提供その他一切の処分をすることはできません。当社は、ユーザーのものとしてあらかじめ登録されたアカウント等の組み合わせが一致してログインされた場合には、当該アカウント等を登録しているユーザー自身による利用とみなすことができるものとします。
      </p>
      <p>
        4.
        アカウント等が第三者によって使用されたことによって生じた損害は、当社に故意又は重大な過失がある場合を除き、当社は一切の責任を負わないものとします。
      </p>
      <p>
        5.
        利用者およびユーザーは、アカウント等の盗難、第三者の使用又はそれらのおそれがあることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。
      </p>
      <p />
      <p>第5条 （本サービスの目的等）</p>
      <p>
        1.
        本サービスは、利用者における請求関連書類、債権管理、支払対応等に関連する各種業務の利便性向上を目的としたサービスです。
      </p>
      <p>
        2.
        当社は、利用者間契約の有効性、本件債権の有効性およびこれらに関連する利用者のいかなる行為、業務の適法性や妥当性を保証するものではなく、また、当社が利用者間契約や本件債権の主体となることもありません。
      </p>
      <p>
        3.
        利用者は、自らが利用者間契約および本件債権の債権者若しくは債務者の主体として、自らに適用される各種関連法令、監督官庁のガイドライン、利用者が所属する業界団体の内部規則等を遵守したうえで、本サービスを利用するものとし、利用者間契約および本件債権に関して法令違反等があった場合には自らの費用と責任でこれを解決し、当社の故意または重大な過失がある場合を除き、当社に一切の迷惑を及ぼさないものとします。
      </p>
      <p>第6条 （他の利用者の招待および情報開示についての承諾）</p>
      <p>
        1.
        利用者は、本サービスを利用するにあたり、当社所定の方法により第三者に対して、当該第三者を本サービスの利用者とするための招待メールを送付することができます。この場合、当該招待メールを受信した者は第3条に定める方法で利用登録をすることができます。
      </p>
      <p>
        2.
        前条に基づき、利用登録をした利用者が本サービスを利用する場合、招待メールを送信した利用者および招待メールを受信した利用者間に限り、以下に掲げる情報が本サイト上で相互に閲覧可能な状態になることとし、利用者はこれを予め承諾するものとします。
      </p>
      <p>① 企業ID</p>
      <p>② 企業属性情報（企業名/屋号名、住所、代表者氏名、事業者登録番号）</p>
      <p>③ 自らが発注者となる場合における締日・支払月・支払日</p>
      <p>④ 振込手数料の負担</p>
      <p>⑤ 消費税の表示</p>
      <p>⑥ 口座情報</p>
      <p />
      <p>第7条 （支払代行サービス）</p>
      <p />
      <p>
        1.
        発注者は、支払代行サービスを利用するにあたり、以下に定める各号の条件をすべて満たし、かつ、発注者が支払代行サービスの対象とすることを当社所定の方法により申し出た本件債権（以下、当該本件債権を「支払代行サービス対象債権」といいます。）についてのみ、支払代行サービスの対象となることをあらかじめ承諾するものとします。
      </p>
      <p>(1) 受注者が本規約に同意していること</p>
      <p>(2) 受注者が当社に対して代理受領権を付与していること</p>
      <p>
        2.
        前項に基づき、支払代行サービス対象債権が支払代行サービスの利用対象となった場合、発注者は、当社と別段の合意をする場合を除き、本件債権の所定の支払期日の前日午前１１時までに受注者から代理受領権を付与された当社に対して、本件債権の弁済金を当社指定の口座に振り込む方法により支払うものとします。なお、支払いにかかる振込手数料については、発注者が負担するものとします。
      </p>
      <p>
        3.
        当社は、前項に基づき支払代行サービス対象債権に基づく支払いを受けた場合、支払代行サービス対象債権の所定の支払期日までに、受注者に対して受領した本件債権の弁済金を引き渡すものとします。なお、支払いにかかる振込手数料については、当社が負担するものとします。
      </p>
      <p>
        4.
        第10条第1項第2号に定める支払代行サービス手数料は、利用者のうち受注者が負担するものとし、当社は、前項に基づき本件債権の弁済金を受注者に引き渡すにあたり、当該弁済金から支払代行サービス手数料を控除した金額を受注者に対して引き渡すことができ、利用者はこれをあらかじめ承諾するものとします。
      </p>
      <p>
        5. 第2項の発注者から当社への支払いにより、発注者の受注者に対する本件債権に基づく支払義務は消滅するものとします。
      </p>
      <p />
      <p>第8条 （受注者から当社への代理受領権）</p>
      <p>
        1.
        利用者は、自らが受注者として本サービスを利用して発注者に本件債権に基づく弁済の請求をするにあたり、当社に対し、あらかじめ当該発注者に対して有する本件債権の弁済金（消費税、振込手数料その他の弁済に要する各種費用を含みます。）にかかる代理受領権限を包括的に授与するものとし、このうち支払代行サービス対象債権となった債権にかかる弁済金の収納業務を委託するものとします。
      </p>
      <p>
        2.
        当社は前項に基づき、受注者から代理受領権限を受けた本件債権のうち、発注者から支払代行サービスの利用申出を受けた支払代行サービス対象債権を前条に定める方法により受注者に代わって発注者から受領し、受注者に対して支払い処理をする収納業務を行うものとします。
      </p>
      <p />
      <p>第9条 （支払代行サービスの対象外債権）</p>
      <p>
        1.
        当社は、利用者間のいかなる交渉、紛争等にも関与しないものとし、利用者は、利用者間契約の有効性および本件債権の成立、内容その他債権の有効性に関連して利用者間において問題が生じている場合には、該当する本件債権に関して支払代行サービスを利用してはならないものとします。
      </p>
      <p>
        2.
        前項に反して、問題のある本件債権に関して支払代行サービスを利用したことが判明した場合、当社は、返金その他必要があると当社が判断する対応をとることができるものとし、利用者はこれに対して異議を唱えないものとします。
      </p>
      <p>
        3.
        第7条第2項に定める時点までに、発注者から当社への支払代行サービス対象債権の支払いがない場合にも、当社は当該支払代行サービス対象債権を支払い代行サービスの対象外として取り扱うことができるものとし、利用者は、当社から取り扱わない旨の通知があったときは利用者の責任で解決をするものとします。
      </p>
      <p />
      <p>第10条 （利用料金および支払方法）</p>
      <p />
      <p />
      <p>
        1
        利用者は、本サービスの利用対価として、以下の各号に定める料金（以下、「利用料金」といいます。）を毎月当社が指定する方法により支払うものとします。ただし、支払代行サービス手数料の支払い方法については、7条4項に定めるとおりとします。
      </p>
      <p>(1) 月額利用料（税込）</p>
      <p>当月における登録ユーザー数（※）×５５０円</p>
      <p>
        ※なお、月の途中で新規登録または登録削除された場合であっても、登録ユーザー数としてカウントするものとし、日割りはしないものとします。
      </p>
      <p>(2) 支払代行サービス手数料（税込）</p>
      <p>支払代行の実施件数（※）×５５０円</p>
      <p>
        ※なお、支払代行１回につき１件とカウントするものとし、１回の支払代行に支払代行サービス対象債権が複数含まれている場合であっても同様とします。
      </p>
      <p>2 利用者が利用料金の支払を遅滞した場合には、利用者は年14．6％の割合による遅延損害金を支払うものとします。</p>
      <p />
      <p>第11条 （禁止事項）</p>
      <p />
      <p />
      <p>利用者は、本サービスの利用にあたり、以下の行為をしてはなりません。</p>
      <p>
        (1)
        本サービス、当社のシステム若しくはプログラムの全部または一部を、複製若しくは改変し、または逆コンパイル、逆アセンブル等のリバースエンジニアリングする行為
      </p>
      <p>
        (2)
        本サービス、当社のシステム若しくはプログラムの全部または一部を第三者に使用許諾、販売、貸与、譲渡、開示またはリースする行為
      </p>
      <p>(3) 本規約、法令または公序良俗に違反する行為</p>
      <p>(4) 犯罪行為に関連する行為</p>
      <p>
        (5)
        当社、または本サービスの他の利用者、ユーザーその他の第三者の知的財産権を侵害し、これらの者の財産・信用・名誉等を毀損し、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為（これらの侵害を直接または間接に惹起する行為を含みます。）
      </p>
      <p>
        (6)
        当社、または本サービスの他の利用者、ユーザー、またはその他第三者のサイト、サーバーまたはネットワークの機能を破壊したり、妨害したりする行為
      </p>
      <p>(7) 手段を問わず、当社のサービスの運営を妨害するおそれのある行為</p>
      <p>
        (8)
        当社の運営するサイト、サーバー、当社のシステムまたはプログラムに関し、コンピュータウィルスを感染させ、ハッキング、改ざん、若しくはその他不正アクセスを行ったり、またはこれらを試みたりする行為等、当社のシステムまたはプログラムの安全性を低下させる行為
      </p>
      <p>(9) 他の利用者、ユーザーに関する個人情報等を収集または蓄積する行為</p>
      <p>(10) 不正な目的を持って本サービスを利用する行為</p>
      <p>(11) 本サービスの他の利用者、ユーザーまたはその他の第三者に不利益、損害、不快感を与える行為</p>
      <p>(12) 他の利用者、ユーザーに成りすます行為</p>
      <p>(13) 当社が許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</p>
      <p>(14) 当社のサービスに関連して、反社会的勢力等に対して直接または間接に利益を供与する行為</p>
      <p>(15) その他、当社が不適切と判断する行為</p>
      <p />
      <p />
      <p>第12条 （本サービスの提供の停止等）</p>
      <p />
      <p />
      <p>
        1.
        当社は、以下のいずれかの事由があると判断した場合、利用者に事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
      </p>
      <p>
        (1)
        本サービスの提供を行うために必要なコンピュータ、通信設備その他の機器、ソフトウェアおよび通信回線等の点検若しくは保守作業を行う場合、またはこれらに障害が生じ、若しくは障害が生じるおそれがある場合
      </p>
      <p>
        (2)
        電気通信事業者または国外の電気通信事業体が電気通信サービスの提供を停止すること等により本サービスの提供を行うことが不可能または困難になった場合
      </p>
      <p>
        (3)
        天災、労働紛争、停電、通信インフラの故障、公共サービスの停止、自然現象、暴動、政府の行為、テロ、戦争その他の不可抗力により本サービスの提供ができなくなった場合
      </p>
      <p>
        (4)
        第三者からの不正アクセス、ハッキング、コンピュータウィルスの感染等により、本サービスの提供が不可能または困難になった場合
      </p>
      <p>
        (5) アカウント等の不正利用等の調査を行う場合、または利用者、ユーザーのセキュリティを確保する必要が生じた場合
      </p>
      <p>(6) その他、当社が停止を必要と判断した場合</p>
      <p>
        2.
        当社は、本条に基づく本サービスの提供の停止または中断により、利用者または第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
      </p>
      <p />
      <p />
      <p />
      <p>第13条 （利用制限および解除）</p>
      <p />
      <p />
      <p>
        1.
        当社は、利用者が以下のいずれかに該当する場合には、事前の通知なく、利用者に対して、本サービスの全部もしくは一部の利用を制限し、または利用契約を解除することができるものとします。
      </p>
      <p>(1) 本規約のいずれかの条項に違反した場合</p>
      <p>(2) 利用申請時に登録した事項に虚偽の事実があることが判明した場合</p>
      <p>(3) 料金等の支払債務の不履行があった場合</p>
      <p>
        (4) 支払の停止または破産手続開始、民事再生手続開始、会社更生手続開始若しくは特別清算開始の申立があったとき。
      </p>
      <p>(5) 手形若しくは小切手の不渡りを発生させたとき。</p>
      <p>(6) 仮差押、仮処分、強制執行、競売の申立を受けたとき。</p>
      <p>(7) 公租公課を滞納して督促を受けたときまたは滞納処分を受けたとき。</p>
      <p>(8) 解散の決議を行い、または、解散命令を受けたとき。</p>
      <p>
        (9)
        他の法人と合併し、事業の全部若しくは重要な一部を第三者に譲渡し、または会社分割をしたときであって、利用契約の継続が困難であると当社が認めたとき。
      </p>
      <p>(10) 事業を廃止し、官公庁から営業の停止または営業免許若しくは営業の取消等の処分を受けたとき。</p>
      <p>
        (11)
        支払不能、債務超過に陥ったとき、または財産、経営、信用等が著しく悪化し、若しくはそのおそれがあると認められる相当の理由があるとき。
      </p>
      <p>(12) 当社からの連絡に対し、一定期間返答がない場合</p>
      <p>(13) 本サービスについて、最終の利用から一定期間利用がない場合</p>
      <p>(14) その他、当社が本サービスの利用を適当でないと判断した場合</p>
      <p>2. 当社は、本条に基づき当社が行った行為により利用者に生じた損害について、一切の責任を負いません。</p>
      <p />
      <p />
      <p>第14条 （契約期間）</p>
      <p>
        1.
        利用契約の有効期間は、契約成立日より1年とし、期間満了前1ヶ月までに当事者のいずれか一方より何らかの意思表示がない限り、自動的に1年延長し、以後も同様とします。
      </p>
      <p>
        2.
        利用契約の終了時に未履行の債務がある場合には、当該債務についてはその履行が完了するまで利用契約が適用されるものとします。
      </p>
      <p />
      <p />
      <p />
      <p />
      <p>第15条 （退会）</p>
      <p />
      <p />
      <p>利用者は、当社の定める退会手続により、本サービスから退会できるものとします。</p>
      <p />
      <p />
      <p>第16条 （保証の否認および免責事項）</p>
      <p />
      <p />
      <p>
        1.
        当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
      </p>
      <p>
        2.
        当社は、本サービスに起因して利用者に生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社と利用者との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
      </p>
      <p>
        3.
        前項ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為により利用者に生じた損害のうち特別な事情から生じた損害（当社または利用者が損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為により利用者に生じた損害の賠償は、利用者から当該損害が発生した月に受領した利用料の額を上限とします。
      </p>
      <p>
        4.
        当社は、本サービスに関して、利用者と他の利用者、ユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
      </p>
      <p />
      <p>第17条 （損害賠償）</p>
      <p>
        利用者は、本規約の違反その他利用者の責めに帰すべき事由により、当社が損害、損失または費用等を被った場合、その補償および賠償をするものとします。
      </p>
      <p />
      <p>第18条 （第三者との紛争）</p>
      <p>
        利用者は、本サービスの利用に関し、本サービスの他の利用者、ユーザーを含む第三者との間で紛争が生じた場合には、自己の責任と費用をもってこれを処理解決するものとし、当該紛争に関連して当社が損害、損失または費用等を被った場合（弁護士費用を含みます）には、これを直ちに補償および賠償するものとします。なお、当社が当該紛争を処理解決した場合には、その処理解決に要した全ての費用（合理的な弁護士費用を含みます）について、利用者が負担するものとします。
      </p>
      <p />
      <p>第19条 （権利帰属）</p>
      <p>
        本サービスおよび当社ウェブサイトに関する著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます）および所有権その他の権利は全て当社または当社にライセンスを許諾している者に帰属しており、利用契約に基づく本サービスの利用は、本サイトまたは本サービスに関する当社または当社にライセンスを許諾している者のこれらの権利の取得または使用許諾を意味するものではありません。
      </p>
      <p />
      <p />
      <p>第20条 （反社会的勢力の排除）</p>
      <p>
        1.
        利用者は、当社に対し、自らが反社会的勢力等に該当しないこと、および次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
      </p>
      <p>(1) 反社会的勢力等が経営を支配していると認められる関係を有すること。</p>
      <p>(2) 反社会的勢力等が経営に実質的に関与していると認められる関係を有すること。</p>
      <p>
        (3)
        自己、自社若しくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってするなど、不当に反社会的勢力等を利用していると認められる関係を有すること。
      </p>
      <p>
        (4)
        反社会的勢力等に対して資金等を提供し、または便宜を供与するなど、反社会的勢力等の維持運営に協力し、または関与していないこと。
      </p>
      <p>(5) 役員または経営に実質的に関与している者が反社会的勢力等と社会的に非難されるべき関係を有すること。</p>
      <p>2. 利用者は、当社に対し、自らまたは第三者を利用して次の各号に該当する行為を行わないことを確約します。</p>
      <p>(1) 暴力的な要求行為。</p>
      <p>(2) 法的な責任を超えた不当な要求行為。</p>
      <p>(3) 取引に関して、脅迫的な言動をし、または暴力を用いる行為。</p>
      <p>(4) 風説を流布し、偽計を用いまたは威力を用いて当社の信用を毀損し、または当社の業務を妨害する行為。</p>
      <p>(5) その他前各号に準ずる行為。</p>
      <p>
        3.
        利用者が、本条第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、または本条第1項各号に関して虚偽の申告をしたことが判明した場合には、当社は、利用契約を解除することができるものとし、かつ、これによって生じた損害、損失または費用等を、直ちに違反者に請求することができるものとします。
      </p>
      <p>4. 利用者は、前項の規定の適用により、自らに損害が生じた場合にも、当社に何らの請求をしないものとします。</p>
      <p />
      <p>第21条 （サービス内容の変更等）</p>
      <p />
      <p />
      <p>
        当社は、利用者に通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによって利用者に生じた損害について一切の責任を負いません。
      </p>
      <p />
      <p />
      <p />
      <p />
      <p />
      <p>第22条 （利用規約の変更）</p>
      <p />
      <p />
      <p>
        1.
        当社は、必要と判断した場合には、いつでも本規約を変更することができるものとします。また、かかる変更のために、本サービスの全部または一部の利用を停止することがあります。
      </p>
      <p>
        2.
        当社は、本規約を変更する場合には、その効力発生日を定め、かつ、事前に、本規約を変更する旨、当該変更内容および当該変更の効力発生日を本サイトにおいて掲載し、また、利用者に通知するものとします。利用者は、本規約の当該変更に同意できない場合、上記掲載から1ヶ月以内に利用契約を解約することができるものとします。なお、変更の効力発生日以降に、利用者が本サービスを利用した場合または上記期間内に本利用契約の解約の手続をとらなかった場合には、利用者は、本規約の変更に同意したものとみなします。
      </p>
      <p>
        3.
        当社は、本条による変更または本条第1項による停止により利用者に損害が発生した場合であっても、賠償する責任を一切負わないものとします。
      </p>
      <p />
      <p />
      <p>第23条 （個人情報の取扱い）</p>
      <p />
      <p />
      <p>
        当社は、本サービスの利用によって取得する個人情報については、利用者が本サービスを利用するのに必要な範囲および当社のプライバシーポリシーに掲げる利用目的の範囲でのみ、取り扱えるものとします。
      </p>
      <p />
      <p />
      <p>第24条 （通知または連絡）</p>
      <p />
      <p />
      <p>
        利用者と当社との間の通知または連絡は、当社の定める方法によって行うものとします。当社は、利用者から、当社が別途定める方式に従った変更届け出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発信時に利用者へ到達したものとみなします。
      </p>
      <p />
      <p />
      <p />
      <p>第25条 （権利義務の譲渡の禁止）</p>
      <p />
      <p />
      <p>
        利用者は、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
      </p>
      <p />
      <p />
      <p>第26条 （準拠法・裁判管轄）</p>
      <p />
      <p />
      <p>本規約の解釈にあたっては、日本法を準拠法とします。</p>
      <p>本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を第一審の専属的合意管轄とします。</p>
      <p />
    </MuiBox>
  )
}

import { Request } from './Request'
import { Role, User } from '../types/User'

type LoadUsersResponse = {
  users: User[]
}

type CreateUserResponse = User
type UpdateUserResponse = User

export default class UserRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadUsers() {
    const response = await this.request.get<LoadUsersResponse>('/users')
    return response.data.users
  }

  async createUser(name: string, email: string, password: string, role: Role) {
    const response = await this.request.post<CreateUserResponse>('/users', {
      name,
      email,
      password,
      role,
    })
    return response.data
  }

  async saveUser(id: string, name?: string, email?: string, role?: Role) {
    const response = await this.request.put<UpdateUserResponse>(`/users/${id}`, {
      name,
      email,
      role,
    })
    return response.data
  }

  async deleteUser(user: User) {
    await this.request.delete(`/users/${user.id}`)
  }
}

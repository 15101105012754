import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import useValidationRule from '../../../hooks/useValidationRule'
import useYup from '../../../hooks/useYup'
import Form from '../../../components/atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import FormItem from '../../../components/molecules/inputs/FormItem'
import TextField from '../../../components/atoms/inputs/TextField'
import Alert from '../../../components/atoms/feedback/Alert'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import usePath from '../../../hooks/usePath'
import { RegisterActions } from '../../../store/register'
import { RegisterSelectors } from '../../../store/register/selectors'

type UidParams = {
  uid?: string
}

function companyUidVerifiedMessage(companyUiVerified?: boolean) {
  if (!companyUiVerified) {
    return undefined
  }
  return '対象の企業は存在しません。企業IDを再確認してください。'
}

export default function RegisterContracteeInvitationPage() {
  const { registerContracteeInvitationCompleted } = usePath()
  const companyUiVerified = useSelector(RegisterSelectors.companyUiVerified)
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    uid: rules.companyDomain.required(),
  })
  const form = useForm<UidParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = form

  useEffect(() => {
    watch((params) => {
      if (params.uid) {
        dispatch(RegisterActions.validateCompanyUid({ uid: params.uid }))
      }
    })
  }, [dispatch, watch])

  const handleNext = useCallback(
    (params: UidParams) => {
      if (params.uid) {
        dispatch(
          RegisterActions.invite({ uid: params.uid, getRedirectPath: () => registerContracteeInvitationCompleted() })
        )
      }
    },
    [dispatch, registerContracteeInvitationCompleted]
  )
  return (
    <Form onSubmit={handleSubmit(handleNext)}>
      <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
        <FlexColumn align="center">
          <Label text="アカウント登録" variant="title" />
          <FlexColumn width="360px" align="center">
            <Label text="元請けさんの企業IDを入力しmonectで繋がりましょう" />
            <FormItem label="元請けさんの企業IDを入力">
              <TextField
                register={register('uid')}
                error={errors.uid?.message || companyUidVerifiedMessage(companyUiVerified)}
                size="md"
              />
            </FormItem>
          </FlexColumn>
          <FlexColumn width="360px" align="center">
            <Alert
              severity="warning"
              label="元請さんの企業IDが分からない場合はmonectサポートセンターに問い合わせください"
            />
          </FlexColumn>
        </FlexColumn>
        <FlexColumn align="center">
          <Button type="submit" size="lg">
            送信
          </Button>
          <FlexRow justify="center">
            <DotPagination count={11} current={5} />
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Form>
  )
}

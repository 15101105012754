import { FlexColumn } from '../../components/atoms/layout/Flex'
import PasswordResetStepView from '../../components/molecules/navigation/PasswordResetStepView'
import Label from '../../components/atoms/display/Label'
import Card from '../../components/atoms/surfaces/Card'
import PasswordResetSettingForm from '../../components/organisms/session/PasswordResetSettingForm'

export default function PasswordResetSettingPage() {
  return (
    <FlexColumn align="center" mt={4} space={4} mbWidth={360}>
      <PasswordResetStepView step={1} />
      <Label text="パスワードを設定" variant="title" />
      <FlexColumn>
        <Label text="新しいパスワードを設定してください。" />
        <Card size="lg">
          <FlexColumn pa={4}>
            <PasswordResetSettingForm />
          </FlexColumn>
        </Card>
      </FlexColumn>
    </FlexColumn>
  )
}

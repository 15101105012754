import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { useTransactionApplyDialog } from '../../../hooks/useDialog'
import { Transaction } from '../../../types/transaction'
import useConsts from '../../../hooks/useConsts'
import { TransactionInstallmentFormParams } from './TransactionInstallmentForm'
import { TransactionActions } from '../../../store/transactions'
import useDay from '../../../hooks/useDay'
import usePath from '../../../hooks/usePath'

type TransactionInstallmentDialogProps = {
  transaction: Transaction
  params: TransactionInstallmentFormParams
  onClose: () => void
}

function calcAmount(params: TransactionInstallmentFormParams) {
  return params.details.reduce((total, detail) => total + detail.amount, 0)
}

function calcUnclaimedAmount(transaction: Transaction, params: TransactionInstallmentFormParams) {
  const amount = calcAmount(params)
  const totalAmount = transaction.details.reduce((total, detail) => total + detail.amount, 0)
  return totalAmount - amount
}

export default function TransactionInstallmentDialog({
  transaction,
  params,
  onClose,
}: TransactionInstallmentDialogProps) {
  const { phaseName } = useConsts()
  const transactionApplyDialog = useTransactionApplyDialog()
  const dispatch = useDispatch()
  const { formatDate } = useDay()
  const { transaction: transactionPage } = usePath()

  const handleApply = useCallback(() => {
    if (params.publishedAt === null || params.closingDate === null || params.paymentDate === null) {
      return
    }
    const paramsDeliveryDateTo = Number(params.deliveryDateType) === 2 ? params.deliveryDateTo : null
    transactionApplyDialog.open(transaction, {
      publishedAt: params.publishedAt,
      closingDate: params.closingDate,
      paymentDate: params.paymentDate,
      deliveryDateFrom: params.deliveryDateFrom ?? null,
      deliveryDateTo: paramsDeliveryDateTo ?? null,
      pic: params.pic ?? null,
      picSub: params.picSub ?? null,
      details: params.details,
    })
    onClose()
  }, [transaction, params, onClose, transactionApplyDialog])
  const handleSubmit = useCallback(() => {
    if (params.publishedAt === null || params.closingDate === null || params.paymentDate === null) {
      return
    }
    const paramsDeliveryDateTo = Number(params.deliveryDateType) === 2 ? params.deliveryDateTo : null
    dispatch(
      TransactionActions.saveInstallment({
        transaction,
        publishedAt: formatDate(params.publishedAt),
        closingDate: formatDate(params.closingDate),
        paymentDate: formatDate(params.paymentDate),
        deliveryDateFrom: params.deliveryDateFrom ? formatDate(params.deliveryDateFrom) : null,
        deliveryDateTo: paramsDeliveryDateTo ? formatDate(paramsDeliveryDateTo) : null,
        picId: params.pic,
        picSubId: params.picSub,
        details: params.details,
        submit: true,
        getRedirectPath: () => transactionPage(transaction.id),
      })
    )
    onClose()
  }, [transaction, params, onClose, formatDate, dispatch, transactionPage])
  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  return (
    <Dialog
      open
      onClose={handleClose}
      title={`取引（${phaseName(transaction.currentPhase)}）を取引先へ提出する`}
      actions={
        <FlexRow>
          <Button color="primary" onClick={handleSubmit}>
            取引先へ提出
          </Button>
        </FlexRow>
      }
    >
      <FlexColumn space={4}>
        <FlexColumn>
          <Label text="今回の請求内容" />
          <FlexColumn ml={2}>
            <FlexRow>
              <FlexRow width={120}>
                <Label text="取引名" />
              </FlexRow>
              <Label text={transaction.name} />
            </FlexRow>
            <FlexRow>
              <FlexRow width={120}>
                <Label text="分割請求" />
              </FlexRow>
              <Label text={`第${transaction.times ?? 1}回`} />
            </FlexRow>
            <FlexRow>
              <FlexRow width={120}>
                <Label text="今回の請求額" />
              </FlexRow>
              <Label text={calcAmount(params)} format="amountWithUnit" />
              <Label text={`（${transaction.isTaxIn ? '税込' : '税抜'}）`} />
            </FlexRow>
            <FlexRow>
              <FlexRow width={120}>
                <Label text="未請求額" />
              </FlexRow>
              <Label text={calcUnclaimedAmount(transaction, params)} format="amountWithUnit" />
              <Label text={`（${transaction.isTaxIn ? '税込' : '税抜'}）`} />
            </FlexRow>
          </FlexColumn>
          <Label
            text="今回の請求が完了すると、残高分の取引が自動作成され、「未処理・仕掛中」の画面に表示されます。"
            color="caution.main"
            variant="caption"
          />
          <Label
            text="分割請求を選択すると、以降明細の追加・編集ができなくなります。"
            color="caution.main"
            variant="caption"
          />
          <Button variant="outlined" size="lg" onClick={handleApply}>
            提出前に社内で内容を確認する
          </Button>
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { useMoneyspaceMoveDialog } from '../../../hooks/useDialog'
import Button from '../../atoms/inputs/Button'
import { Moneyspace, MoneyspaceFolder } from '../../../types/Moneyspace'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import useProcessing from '../../../hooks/useProcessing'
import MoneyspaceFolderSelectField from './MoneyspaceFolderSelectField'
import TreeView from '../../atoms/navigation/TreeView'
import TreeItem from '../../atoms/navigation/TreeItem'
import { FolderIcon } from '../../atoms/display/Icons'

type MoneyspaceMoveDialogProps = {
  moneyspace: Moneyspace
}

export default function MoneyspaceMoveDialog({ moneyspace }: MoneyspaceMoveDialogProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.moveMoneyspace.typePrefix)
  const { state, close } = useMoneyspaceMoveDialog()
  const [selectedFolder, setSelectedFolder] = useState<MoneyspaceFolder | null>(null)
  const dispatch = useDispatch()
  const handleMove = useCallback(() => {
    startProcess()
    dispatch(
      MoneyspacesActions.moveMoneyspace({ id: moneyspace.id, folderId: selectedFolder ? selectedFolder.id : null })
    )
  }, [dispatch, moneyspace.id, selectedFolder, startProcess])
  return (
    <Dialog
      open={state !== undefined}
      title="マネースペースの移動"
      actions={
        <Button onClick={handleMove} disabled={processing}>
          移動
        </Button>
      }
      onClose={close}
    >
      <FlexColumn space={4}>
        <FormItem label="移動先フォルダ">
          <TreeView onSelect={() => setSelectedFolder(null)}>
            <TreeItem id="none" icon={<FolderIcon />} label="フォルダなし" />
          </TreeView>
          <MoneyspaceFolderSelectField
            onSelectFolder={(folder) => setSelectedFolder(folder ?? null)}
            initialSelectedId={undefined}
          />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

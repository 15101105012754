import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import { useContractEditDialog } from '../../../hooks/useDialog'
import ContractNameField from '../../molecules/inputs/ContractNameField'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { Contract } from '../../../types/Document'
import { TransactionActions } from '../../../store/transactions'
import useProcessing from '../../../hooks/useProcessing'

type ContractEditDialogProps = {
  contract: Contract
}

type ContractEditParams = {
  name: string
}

export default function ContractEditDialog({ contract }: ContractEditDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.saveContract.typePrefix)
  const { state, close } = useContractEditDialog()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.transactionName.required(),
  })
  const { handleSubmit, register, errors } = useForm<ContractEditParams>(schema, {
    name: contract.name,
  })
  const handleSave = useCallback(
    (values: ContractEditParams) => {
      startProcess()
      dispatch(
        TransactionActions.saveContract({
          id: contract.id,
          name: values.name,
        })
      )
    },
    [contract.id, dispatch, startProcess]
  )
  return (
    <Dialog
      open={state !== undefined}
      title="取引を編集する"
      actions={
        <Button type="submit" disabled={processing}>
          保存
        </Button>
      }
      onSubmit={handleSubmit(handleSave)}
      onClose={close}
    >
      <FlexColumn>
        <FormItem label="取引名" required>
          <ContractNameField register={register('name')} error={errors.name} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

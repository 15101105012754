import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Company } from '../../../types/Company'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import { CompanyActions } from '../../../store/company'
import { useClientAcceptDialog } from '../../../hooks/useDialog'

type ClientAcceptDialogProps = {
  client: Company
}

export default function ClientAcceptDialog({ client }: ClientAcceptDialogProps) {
  const { state, close } = useClientAcceptDialog()
  const dispatch = useDispatch()
  const handleAccept = useCallback(() => {
    if (client) {
      dispatch(CompanyActions.acceptClient({ clientId: client.id }))
    }
  }, [client, dispatch])
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="取引先追加の申請を承認しますか"
      actions={
        <FlexRow>
          <Button color="primary" onClick={handleAccept}>
            承認する
          </Button>
          <Button variant="text" onClick={close}>
            キャンセル
          </Button>
        </FlexRow>
      }
    >
      <FlexColumn>
        <FlexRow>
          <Label text={client.name} variant="title" />
        </FlexRow>
        <FlexRow>
          <Label text="企業ID:" />
          <Label text={client.uid} />
        </FlexRow>
        <FlexRow>
          <Label text="事業者登録番号:" />
          <Label text={client.invoiceLicenseNumber ?? '-'} />
        </FlexRow>
        <FlexRow>
          <Label text="代表者:" />
          <Label text={client.representativeRole ?? ''} />
          <Label text={client.representativeName ?? ''} />
        </FlexRow>
        <FlexRow>
          <Label text="住所:" />
          <Label text={client.address ?? ''} />
        </FlexRow>
      </FlexColumn>
    </Dialog>
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type MoneyspaceNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
}

export default function MoneyspaceNameField({ register, error }: MoneyspaceNameFieldProps) {
  return <TextField register={register} placeholder="〇〇社⇔△△社" error={error?.message} />
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTransactionCopyDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import Button from '../../atoms/inputs/Button'
import { TransactionActions } from '../../../store/transactions'
import useProcessing from '../../../hooks/useProcessing'
import TextField from '../../atoms/inputs/TextField'
import usePath from '../../../hooks/usePath'

type TransactionCopyDialogProps = {
  transactionId: string
  name: string
}

type ContractCopyParams = {
  name: string
}

export default function TransactionCopyDialog({ transactionId, name }: TransactionCopyDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.copyTransaction.typePrefix)
  const { state, close } = useTransactionCopyDialog()
  const dispatch = useDispatch()
  const { transaction: transactionPath } = usePath()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.transactionName.required(),
  })
  const { handleSubmit, register, errors } = useForm<ContractCopyParams>(schema, {
    name,
  })
  const handleCopy = useCallback(
    (values: ContractCopyParams) => {
      if (document) {
        startProcess()
        dispatch(
          TransactionActions.copyTransaction({
            transactionId,
            name: values.name,
            getRedirectPath: (id) => transactionPath(id),
          })
        )
      }
    },
    [dispatch, startProcess, transactionId, transactionPath]
  )

  return (
    <Dialog
      open={state !== undefined}
      title="取引のコピー"
      actions={
        <Button type="submit" disabled={processing}>
          コピー
        </Button>
      }
      onSubmit={handleSubmit(handleCopy)}
      onClose={close}
    >
      <FlexColumn>
        <FormItem label="取引名" required>
          <TextField register={register('name')} error={errors.name?.message} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

import { createSlice } from '@reduxjs/toolkit'
import { Contract, DocumentAttributes, DocumentType } from '../../types/Document'
import { createAsyncAction } from '../actions'
import { Transaction, TransactionDetail } from '../../types/transaction'

export type ToolsState = {
  documentImporter: {
    moneyspaceContracts: {
      moneyspaceId: string
      contracts: Contract[]
    }[]
  }
  transactionImporter: {
    moneyspaceTransactions: {
      moneyspaceId: string
      transactions: Transaction[]
    }[]
  }
  ocrResult?: {
    transactionDetails: TransactionDetail[]
  }
}

export const initialToolsState: ToolsState = {
  documentImporter: {
    moneyspaceContracts: [],
  },
  transactionImporter: {
    moneyspaceTransactions: [],
  },
  ocrResult: undefined,
}

export type ImportDocumentsParams = {
  contractCreateParams: {
    moneyspaceId: string
    name: string
    types: DocumentType[]
    document: DocumentAttributes
  }[]
  contractUpdateParams: {
    moneyspaceId: string
    contractId: string
    types: DocumentType[]
    document: DocumentAttributes
  }[]
}

export type ImportTransactionDetail = Omit<TransactionDetail, 'id'>

export type ImportTransactionParamsData = {
  msId: string
  name: string
  isTaxIn: boolean
  publishedAt: string
  note: string
  paymentDate: string | null
  closingDate: string | null
  deliveryDateFrom: string | null
  deliveryDateTo: string | null
  picId: string | null
  picSubId: string | null
  siteAddress: string
  externalId?: string | null
  customProp1?: string | null
  customProp2?: string | null
  customProp3?: string | null
  customProp4?: string | null
  details: ImportTransactionDetail[]
}

export type ImportTransactionParams = {
  proceedToReviewed: boolean // 先方へ送付するか否か
  fixFollowingPhase: boolean
  phases: number[]
  data: ImportTransactionParamsData[]
}

export const ToolsActions = {
  fetchDocumentImporterMoneyspaceContracts: createAsyncAction<
    {
      moneyspaceId: string
    },
    {
      moneyspaceId: string
      contracts: Contract[]
    }
  >('fetchDocumentImporterMoneyspaceContracts', async (params, { contractRepository }) => {
    const contracts = await contractRepository.loadContracts(params.moneyspaceId, true)
    return {
      moneyspaceId: params.moneyspaceId,
      contracts: contracts.filter((contract) => !contract.fixFollowingDocs),
    }
  }),
  fetchTransactionImporterMoneyspaceTransactions: createAsyncAction<
    {
      moneyspaceId: string
    },
    {
      moneyspaceId: string
      transactions: Transaction[]
    }
  >('fetchTransactionImporterMoneyspaceTransactions', async (params, { transactionRepository }) => {
    const transactions = await transactionRepository.loadAllTransactions(params.moneyspaceId)
    return {
      moneyspaceId: params.moneyspaceId,
      transactions: transactions.filter((transaction) => !transaction.fixFollowingPhase),
    }
  }),
  importDocuments: createAsyncAction<ImportDocumentsParams, void>(
    'importDocuments',
    async (params, { documentRepository }) => documentRepository.import(params)
  ),
  importTransactions: createAsyncAction<ImportTransactionParams, void>(
    'importTransactions',
    async (params, { transactionRepository }) => transactionRepository.import(params)
  ),
  clearDocumentImporter: createAsyncAction<void, void>('clearDocumentImporter', async () => Promise.resolve()),
  clearTransactionImporter: createAsyncAction<void, void>('clearTransactionImporter', async () => Promise.resolve()),
  ocrDocument: createAsyncAction<
    {
      file: File
    },
    {
      transactionDetails: TransactionDetail[]
    }
    // eslint-disable-next-line no-return-await
  >('ocrDocument', async (params, { documentRepository }) => await documentRepository.uploadOcrFile(params.file)),
  resetOcrResult: createAsyncAction<void, void>('resetOcrResult', async () => Promise.resolve()),
}

const toolsSlice = createSlice({
  name: 'tools',
  initialState: initialToolsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ToolsActions.fetchDocumentImporterMoneyspaceContracts.fulfilled, (state, action) => {
      state.documentImporter.moneyspaceContracts.push({
        moneyspaceId: action.payload.moneyspaceId,
        contracts: action.payload.contracts,
      })
    })
    builder.addCase(ToolsActions.fetchTransactionImporterMoneyspaceTransactions.fulfilled, (state, action) => {
      state.transactionImporter.moneyspaceTransactions.push({
        moneyspaceId: action.payload.moneyspaceId,
        transactions: action.payload.transactions,
      })
    })
    builder.addCase(ToolsActions.clearDocumentImporter.fulfilled, (state) => {
      state.documentImporter = {
        moneyspaceContracts: [],
      }
    })
    builder.addCase(ToolsActions.clearTransactionImporter.fulfilled, (state) => {
      state.transactionImporter = {
        moneyspaceTransactions: [],
      }
    })
    builder.addCase(ToolsActions.ocrDocument.fulfilled, (state, action) => {
      state.ocrResult = action.payload
    })
    builder.addCase(ToolsActions.resetOcrResult.fulfilled, (state) => {
      state.ocrResult = undefined
    })
  },
})

export default toolsSlice

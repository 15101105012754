import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type BankNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function BankNameField({ register, error, disabled }: BankNameFieldProps) {
  return (
    <TextField register={register} placeholder="マネクト銀行" size="sm" error={error?.message} readonly={disabled} />
  )
}

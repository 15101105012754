import { useCallback } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import { Component } from '../../../types/react'

type DrawerAnchor = 'left' | 'right' | 'top' | 'bottom'

type DrawerProps = {
  anchor?: DrawerAnchor
  open: boolean
  variant: 'permanent' | 'persistent' | 'temporary'
  children: Component
  onClose?: () => void
}

export default function Drawer({ anchor = 'left', open, variant, children, onClose }: DrawerProps) {
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  return (
    <MuiDrawer anchor={anchor} open={open} variant={variant} onClose={handleClose}>
      {children}
    </MuiDrawer>
  )
}

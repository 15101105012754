import React, { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import NumberControl from '../../atoms/inputs/NumberControl'
import { MembershipFeeParams } from './CompanyTabItemMembership'
import { SessionSelectors } from '../../../store/session/selectors'
import usePermission from '../../../hooks/usePermission'
import CheckboxControl from '../../atoms/inputs/CheckboxControl'

export default function CompanyMembershipFlatFee() {
  const user = useSelector(SessionSelectors.user)
  const { companyPermissions } = usePermission()
  const disabled = !companyPermissions.edit(user)
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext<MembershipFeeParams>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reset, setReset] = useState(false)
  useEffect(() => {
    watch((params, { name }) => {
      if (name === 'type') {
        setReset(true)
        setInterval(() => {
          setReset(false)
        }, 0)
      }
      if (name === 'enableMaximumFee') {
        setValue('maximumFee', 0)
      }
    })
  }, [setValue, watch])
  return (
    <FlexColumn>
      <FlexRow>
        <FlexRow my={1}>
          <Label text="一律" />
        </FlexRow>
        <FlexRow>
          {Number(getValues().type) === 1 ? (
            <NumberControl<MembershipFeeParams>
              control={control}
              name={`conditions.${0}.amount`}
              suffix="円"
              type="amount"
              error={errors.conditions && errors.conditions[0] && errors.conditions[0].amount?.message}
              disabled={disabled}
            />
          ) : (
            <FlexColumn>
              <FlexRow>
                <NumberControl<MembershipFeeParams>
                  control={control}
                  name={`conditions.${0}.rate`}
                  suffix="%"
                  type="amount"
                  size="xs"
                  error={errors.conditions && errors.conditions[0] && errors.conditions[0].rate?.message}
                  disabled={disabled}
                />
              </FlexRow>
              <FlexRow>
                <FlexRow width={200}>
                  <CheckboxControl<MembershipFeeParams>
                    control={control}
                    name="enableMaximumFee"
                    label="上限金額を設定する"
                    disabled={disabled}
                  />
                </FlexRow>
                <NumberControl
                  name="maximumFee"
                  control={control}
                  suffix="円"
                  type="amount"
                  error={errors.maximumFee?.message}
                  disabled={disabled || !getValues().enableMaximumFee}
                />
              </FlexRow>
            </FlexColumn>
          )}
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  )
}

import { Component } from '../../types/react'
import { FlexColumn, FlexRow } from '../atoms/layout/Flex'
import AppBar from '../atoms/surfaces/AppBar'
import ServiceLogo from '../molecules/display/ServiceLogo'
import RelativeBox from '../atoms/layout/RelativeBox'

type NotLoggedInLayoutProps = {
  mt?: 8 | 16
  mb?: 4 | 8
  children: Component
}

export default function NotLoggedInLayout({ mt = 16, mb = 8, children }: NotLoggedInLayoutProps) {
  return (
    <FlexColumn align="center">
      <AppBar position="fixed">
        <RelativeBox>
          <FlexRow justify="center" my={2} grow>
            <ServiceLogo width={205} height={48} />
          </FlexRow>
        </RelativeBox>
      </AppBar>
      <FlexColumn mt={mt} mb={mb}>
        {children}
      </FlexColumn>
    </FlexColumn>
  )
}

import { Control } from 'react-hook-form'
import Select from '../../atoms/inputs/Select'
import { Role } from '../../../types/User'

type RoleSelectProps = {
  name: string
  userRole?: Role
  control: Control
}

function roleItems(role?: Role) {
  const items = [
    { value: 1, label: 'オーナー' },
    { value: 2, label: 'リーダー' },
    { value: 3, label: 'メンバー' },
  ]
  if (role) {
    return items.filter((item) => item.value >= role)
  }
  return items
}

export default function RoleSelect({ name, userRole, control }: RoleSelectProps) {
  return <Select items={roleItems(userRole)} name={name} control={control} size="xs" />
}

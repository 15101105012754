import { createSlice } from '@reduxjs/toolkit'
import {
  AggregateTransaction,
  Bank,
  BankBranch,
  Company,
  CompanyAttributes,
  CsvImportSetting,
  DetailPropConfig,
  TransactionPropConfig,
} from '../../types/Company'
import { createAsyncAction } from '../actions'
import { Role, User } from '../../types/User'
import { ApprovalFlow } from '../../types/ApprovalFlow'
import { convertMembershipFee, MembershipFee2, Moneyspace } from '../../types/Moneyspace'

export type CompanyState = {
  company?: Company
  clients: Company[]
  users: User[]
  bankSearchResults: Bank[]
  bankBranchSearchResults: BankBranch[]
  inviteToken?: string
  invoiceLicenseCompanyName?: string | null
  membershipFee: MembershipFee2
  csvImportSetting: CsvImportSetting
}

export const initialCompanyState: CompanyState = {
  company: undefined,
  clients: [],
  users: [],
  bankSearchResults: [],
  bankBranchSearchResults: [],
  membershipFee: {
    taxIn: 1,
    type: 1,
    conditions: [],
    maximumFee: null,
    roundingType: 1,
  },
  csvImportSetting: {
    bundlingKeyField: 'name',
    externalId: '仕入先コード',
    name: '取引名',
    isTaxIn: '消費税の表示',
    publishedAt: '発行日',
    note: '備考',
    paymentDate: '支払日',
    closingDate: '締め日',
    deliveryDateFrom: '納入期間開始日',
    deliveryDateTo: '納入期間終了日',
    picId: '担当者1ID',
    picSubId: '担当者2ID',
    siteAddress: '現場住所',
    customProp1: 'カスタム項目1',
    customProp2: 'カスタム項目2',
    customProp3: 'カスタム項目3',
    customProp4: 'カスタム項目4',
    detailDefaultProp: '明細デフォルト項目',
    detailCustomProp1: '明細カスタム項目1',
    detailCustomProp2: '明細カスタム項目2',
    detailUnitPrice: '単価',
    detailQuantity: '数量',
    detailUnit: '単位',
    detailTaxBucketId: '税区分',
    skippingKeyField: null,
    skippingTargetValues: [{ value: '' }],
  },
}

export const CompanyActions = {
  fetchCompany: createAsyncAction<
    void,
    {
      company?: Company
      inviteToken?: string
      membershipFee?: MembershipFee2
      csvImportSetting?: CsvImportSetting | null
    }
  >('fetchCompany', async (params, { companyRepository }, state) => {
    if (state.session.companyId) {
      const myCompany = await companyRepository.loadCompany(state.session.companyId)
      const inviteToken = await companyRepository.inviteToken(myCompany.id)
      const membershipFee = convertMembershipFee(myCompany.membershipFee)
      const csvImportSetting = await companyRepository.loadCsvImportSetting(myCompany.id)
      return { company: myCompany, inviteToken, membershipFee, csvImportSetting }
    }
    return { company: undefined, inviteToken: undefined, membershipFee: undefined, csvImportSetting: undefined }
  }),
  saveCompany: createAsyncAction<
    {
      id: string
      company: CompanyAttributes
    },
    {
      company: Company
      moneyspaces: Moneyspace[]
    }
  >('saveCompany', async (params, { companyRepository, moneyspaceRepository }) => {
    const company = await companyRepository.saveCompany(params.id, params.company)
    const moneyspaces = await moneyspaceRepository.loadMoneyspaces()
    return { company, moneyspaces }
  }),
  saveTransactionConfig: createAsyncAction<
    {
      companyId: string
      detailPropConfig: DetailPropConfig
      taxRoundingType: number
      transactionPropConfig: TransactionPropConfig
    },
    {
      company: Company
    }
  >('saveTransactionConfig', async (params, { companyRepository }) => {
    const company = await companyRepository.saveTransactionConfig(
      params.companyId,
      params.detailPropConfig,
      params.taxRoundingType,
      params.transactionPropConfig
    )
    return { company }
  }),
  saveInvoice: createAsyncAction<
    {
      companyId: string
      invoiceLessActionType: number
    },
    {
      company: Company
    }
  >('saveInvoice', async (params, { companyRepository }) => {
    const company = await companyRepository.saveInvoice(params.companyId, params.invoiceLessActionType)
    return { company }
  }),
  saveCovenant: createAsyncAction<
    {
      covenant: string | null
      onDone: () => void
    },
    void
  >('saveCovenant', async (params, { companyRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      await companyRepository.saveCovenant(companyId, params.covenant)
      params.onDone()
    }
  }),
  fetchClients: createAsyncAction<
    void,
    {
      clients: Company[]
    }
  >('fetchClients', async (params, { companyRepository }, state) => {
    if (state.session.companyId) {
      const clients = await companyRepository.loadClients(state.session.companyId)
      return { clients }
    }
    return { clients: [] }
  }),
  createClient: createAsyncAction<
    {
      companyUid: string
    },
    {
      clients: Company[]
    }
  >('createClient', async (params, { companyRepository }, state) => {
    if (state.session.companyId) {
      await companyRepository.createClient(state.session.companyId, params.companyUid)
      const clients = await companyRepository.loadClients(state.session.companyId)
      return { clients }
    }
    return { clients: [] }
  }),
  inviteUser: createAsyncAction<
    {
      email: string
    },
    void
  >('inviteUser', async (params, { companyRepository }, state) => {
    if (state.session.companyId) {
      await companyRepository.inviteToken(state.session.companyId, params.email)
    }
  }),
  updateClient: createAsyncAction<
    {
      id: string
      client: CompanyAttributes
    },
    {
      client?: Company
    }
  >('updateClient', async (params, { companyRepository }, state) => {
    if (state.session.companyId) {
      const client = await companyRepository.saveClient(state.session.companyId, params.id, params.client)
      return { client }
    }
    return { client: undefined }
  }),
  deleteClient: createAsyncAction<
    {
      client: Company
    },
    {
      client: Company
    }
  >('deleteClient', async (params, { companyRepository }, state) => {
    if (state.session.companyId) {
      await companyRepository.deleteClient(state.session.companyId, params.client)
    }
    return { client: params.client }
  }),
  acceptClient: createAsyncAction<
    {
      clientId: string
    },
    {
      clientId: string
    }
  >('acceptClient', async (params, { companyRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      await companyRepository.acceptClient(companyId, params.clientId)
    }
    return {
      clientId: params.clientId,
    }
  }),
  fetchUsers: createAsyncAction<
    void,
    {
      users: User[]
    }
  >('fetchUsers', async (params, { userRepository }, state) => {
    const users = await userRepository.loadUsers()
    const companyId = state.session.companyId ?? ''
    return { users: users.map((user) => ({ ...user, companyId })) }
  }),
  createUser: createAsyncAction<
    {
      name: string
      email: string
      password: string
      role: Role
    },
    {
      user: User
    }
  >('createUser', async (params, { userRepository }) => {
    const newUser = await userRepository.createUser(params.name, params.email, params.password, params.role)
    return { user: newUser }
  }),
  updateUser: createAsyncAction<
    {
      id: string
      name?: string
      email?: string
      role?: Role
    },
    {
      user: User
    }
  >('updateUser', async (params, { userRepository }) => {
    const updatedUser = await userRepository.saveUser(params.id, params.name, params.email, params.role)
    return { user: updatedUser }
  }),
  deleteUser: createAsyncAction<
    {
      user: User
    },
    {
      user: User
      approvalFlows: ApprovalFlow[]
    }
  >('deleteUser', async (params, { userRepository, companyRepository }) => {
    await userRepository.deleteUser(params.user)
    // ユーザーを削除したことにより、承認フローが更新されている可能性があるため、再fetchする
    const approvalFlows = params.user.companyId ? await companyRepository.loadApprovalFlows(params.user.companyId) : []
    return { user: params.user, approvalFlows }
  }),
  searchBanks: createAsyncAction<
    {
      code?: string
      name?: string
    },
    {
      searchResults: Bank[]
    }
  >('searchBanks', async (params, { bankRepository }) => {
    const searchResults = await bankRepository.searchBanks({ code: params.code, name: params.name })
    return { searchResults }
  }),
  searchBankBranches: createAsyncAction<
    {
      bankCode: string
      code?: string
      name?: string
    },
    {
      searchResults: BankBranch[]
    }
  >('searchBankBranches', async (params, { bankRepository }) => {
    const searchResults = await bankRepository.searchBankBranches(params.bankCode, {
      code: params.code,
      name: params.name,
    })
    return { searchResults }
  }),
  searchInvoiceLicense: createAsyncAction<
    {
      invoiceLicenseNumber?: string
    },
    {
      companyName?: string | null
    }
  >('searchInvoiceLicense', async (params, { companyRepository }) => {
    if (params.invoiceLicenseNumber) {
      const companyName = await companyRepository.searchInvoiceLicense(params.invoiceLicenseNumber)
      return {
        companyName,
      }
    }
    return {}
  }),
  saveMembershipFee: createAsyncAction<
    {
      membershipFee: MembershipFee2
    },
    {
      membershipFee: MembershipFee2
    }
  >('saveMembershipFee', async (params, { companyRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      await companyRepository.saveMembershipFee(companyId, params.membershipFee)
    }
    return { membershipFee: params.membershipFee }
  }),
  saveAggregateTransaction: createAsyncAction<AggregateTransaction, AggregateTransaction>(
    'saveAggregateTransaction',
    async (params, { companyRepository }, state) => {
      const { companyId } = state.session
      if (companyId) {
        await companyRepository.saveAggregateTransaction(
          companyId,
          params.aggregateTransactions,
          params.fixMonth,
          params.fixDay
        )
      }
      return params
    }
  ),
  saveCsvImportSetting: createAsyncAction<CsvImportSetting, CsvImportSetting>(
    'saveCsvImportSetting',
    async (params, { companyRepository }, state) => {
      const { companyId } = state.session
      if (companyId) {
        await companyRepository.saveCsvImportSetting(companyId, params)
      }
      return params
    }
  ),
  downloadClientCsv: createAsyncAction<
    {
      filename: string
      searchParamName?: string
      searchParamInvoice?: number
    },
    void
  >('company:downloadClientCsv', async (params, { companyRepository }, state) => {
    const { companyId } = state.session
    if (companyId) {
      await companyRepository.downloadClientCsv(
        params.filename,
        companyId,
        params.searchParamName,
        params.searchParamInvoice
      )
    }
  }),
}

const companySlice = createSlice({
  name: 'company',
  initialState: initialCompanyState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(CompanyActions.fetchClients.fulfilled, (state, action) => {
        state.clients = action.payload.clients
      })
      .addCase(CompanyActions.fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload.users
      })
      .addCase(CompanyActions.fetchCompany.fulfilled, (state, action) => {
        state.company = action.payload.company
        state.inviteToken = action.payload.inviteToken
        if (action.payload.membershipFee) {
          state.membershipFee = action.payload.membershipFee
        }
        if (action.payload.csvImportSetting) {
          state.csvImportSetting = action.payload.csvImportSetting
        }
      })
      .addCase(CompanyActions.saveCompany.fulfilled, (state, action) => {
        state.company = action.payload.company
      })
      .addCase(CompanyActions.saveTransactionConfig.fulfilled, (state, action) => {
        state.company = action.payload.company
      })
      .addCase(CompanyActions.saveInvoice.fulfilled, (state, action) => {
        state.company = action.payload.company
      })
      .addCase(CompanyActions.createUser.fulfilled, (state, action) => {
        state.users.unshift(action.payload.user)
      })
      .addCase(CompanyActions.updateUser.fulfilled, (state, action) => {
        state.users = state.users.map((user) => {
          if (user.id === action.payload.user.id) {
            return action.payload.user
          }
          return user
        })
      })
      .addCase(CompanyActions.deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload.user.id)
      })
      .addCase(CompanyActions.createClient.fulfilled, (state, action) => {
        state.clients = action.payload.clients
      })
      .addCase(CompanyActions.updateClient.fulfilled, (state, action) => {
        const { client } = action.payload
        if (client) {
          state.clients = state.clients.map((item) => {
            if (item.id === client.id) {
              return client
            }
            return item
          })
        }
      })
      .addCase(CompanyActions.deleteClient.fulfilled, (state, action) => {
        state.clients = state.clients.filter((client) => client.id !== action.payload.client.id)
      })
      .addCase(CompanyActions.acceptClient.fulfilled, (state, action) => {
        const client = state.clients.find((item) => item.id === action.payload.clientId)
        if (client) {
          client.isAccepted = true
        }
      })
      .addCase(CompanyActions.searchBanks.fulfilled, (state, action) => {
        state.bankSearchResults = action.payload.searchResults
      })
      .addCase(CompanyActions.searchBankBranches.fulfilled, (state, action) => {
        state.bankBranchSearchResults = action.payload.searchResults
      })
      .addCase(CompanyActions.searchInvoiceLicense.pending, (state) => {
        state.invoiceLicenseCompanyName = undefined
      })
      .addCase(CompanyActions.searchInvoiceLicense.fulfilled, (state, action) => {
        state.invoiceLicenseCompanyName = action.payload.companyName
      })
      .addCase(CompanyActions.saveMembershipFee.fulfilled, (state, action) => {
        state.membershipFee = action.payload.membershipFee
      })
      .addCase(CompanyActions.saveAggregateTransaction.fulfilled, (state, action) => {
        if (state.company) {
          state.company.aggregateTransactions = action.payload.aggregateTransactions
          state.company.fixMonth = action.payload.fixMonth
          state.company.fixDay = action.payload.fixDay
        }
      })
      .addCase(CompanyActions.saveCsvImportSetting.fulfilled, (state, action) => {
        state.csvImportSetting = action.payload
      })
  },
})

export default companySlice

import { FlexColumn } from '../../components/atoms/layout/Flex'
import PasswordResetStepView from '../../components/molecules/navigation/PasswordResetStepView'
import Label from '../../components/atoms/display/Label'
import Link from '../../components/atoms/navigation/Link'
import usePath from '../../hooks/usePath'

export default function PasswordResetCompletedPage() {
  const { login } = usePath()
  return (
    <FlexColumn align="center" mt={4} space={4} width={640} mbWidth={360}>
      <PasswordResetStepView step={1} />
      <Label text="パスワードのリセットが完了しました" variant="title" />
      <Label text="ご入力いただいたメールアドレス宛に、新しいパスワードを送信しました" />
      <Label text="ログイン後、必ずパスワードの再設定をお願いします" />
      <FlexColumn align="center">
        <Link href={login()}>
          <Label text="ログインはこちら" />
        </Link>
      </FlexColumn>
    </FlexColumn>
  )
}

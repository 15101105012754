import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FlexColumn, FlexRow } from '../components/atoms/layout/Flex'
import Label from '../components/atoms/display/Label'
import usePage from '../hooks/usePage'
import useRouter from '../hooks/useRouter'
import { TransactionSelectors } from '../store/transactions/selectors'
import { TransactionActions } from '../store/transactions'
import PaginationCountLabel from '../components/molecules/display/PaginationCountLabel'
import Pagination from '../components/atoms/navigation/Pagination'
import Table from '../components/atoms/display/Table'
import TableRow from '../components/atoms/display/TableRow'
import TableCell from '../components/atoms/display/TableCell'
import TransactionAmountView from '../components/molecules/display/TransactionAmountView'
import useDay from '../hooks/useDay'
import Checkbox from '../components/atoms/inputs/Checkbox'
import { TransactionSearchResultItem } from '../types/transaction'
import Button from '../components/atoms/inputs/Button'
import { useTransactionsApprovalFlowSettingDialog } from '../hooks/useDialog'

export default function BillingTransactionsPage() {
  const { changeTitle } = usePage()
  const { billingTransactions, transaction: transactionPage } = useRouter()
  const dispatch = useDispatch()
  const transactions = useSelector(TransactionSelectors.billingTransactions)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = Number(query.get('page') ?? 1)
  const { formatDate } = useDay()
  const [selectedTransactionIds, setSelectedTransactionIds] = useState<string[]>([])
  const transactionsApprovalFlowSettingDialog = useTransactionsApprovalFlowSettingDialog()

  useEffect(() => {
    changeTitle('請求の確認')
  }, [changeTitle])
  useEffect(() => {
    dispatch(TransactionActions.searchBillingTransactions({ page, pageSize: 100 }))
  }, [dispatch, page])
  useEffect(() => {
    const transactionIds = transactions.results.map((transaction) => transaction.id)
    setSelectedTransactionIds(selectedTransactionIds.filter((id) => transactionIds.includes(id)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactions])
  const handlePage = (pageNumber: number) => {
    billingTransactions(pageNumber)
    window.scrollTo(0, 0)
  }

  const handleSelect = useCallback(
    (transaction: TransactionSearchResultItem, checked: boolean) => {
      if (checked) {
        setSelectedTransactionIds([...selectedTransactionIds, transaction.id])
      } else {
        setSelectedTransactionIds(selectedTransactionIds.filter((id) => id !== transaction.id))
      }
    },
    [selectedTransactionIds]
  )

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedTransactionIds(transactions.results.map((transaction) => transaction.id))
      } else {
        setSelectedTransactionIds([])
      }
    },
    [transactions]
  )

  const handleClickTransaction = useCallback(
    (transaction: TransactionSearchResultItem) => {
      transactionPage(transaction.id)
    },
    [transactionPage]
  )

  const handleClickSetting = useCallback(() => {
    const items = transactions.results.filter((transaction) => selectedTransactionIds.includes(transaction.id))
    transactionsApprovalFlowSettingDialog.open(items)
  }, [selectedTransactionIds, transactions.results, transactionsApprovalFlowSettingDialog])

  return (
    <FlexColumn>
      {transactions.count === 0 ? (
        <Label text="確認が必要な請求はありません" />
      ) : (
        <FlexColumn>
          <FlexRow justify="space-between">
            <PaginationCountLabel page={page} pageSize={100} contents={transactions} />
            <Button size="lg" disabled={selectedTransactionIds.length === 0} onClick={handleClickSetting}>
              承認フローを設定する
            </Button>
          </FlexRow>
          <Table
            header={
              <TableRow>
                <TableCell header size="xxs">
                  <Checkbox
                    onChange={(checked) => handleSelectAll(checked)}
                    checked={selectedTransactionIds.length === transactions.results.length}
                    indeterminate={
                      selectedTransactionIds.length > 0 && selectedTransactionIds.length < transactions.results.length
                    }
                  />
                </TableCell>
                <TableCell header size="sm">
                  マネースペース
                </TableCell>
                <TableCell header size="xs">
                  受注者
                </TableCell>
                <TableCell header size="sm">
                  合計金額
                </TableCell>
                <TableCell header size="xs">
                  支払い期限
                </TableCell>
                <TableCell header size="xs">
                  締め日
                </TableCell>
                <TableCell header size="sm">
                  取引名
                </TableCell>
              </TableRow>
            }
          >
            {transactions.results.map((transaction) => (
              <TableRow key={transaction.id} onClick={() => handleClickTransaction(transaction)}>
                <TableCell size="xxs">
                  <Checkbox
                    onChange={(checked) => handleSelect(transaction, checked)}
                    checked={selectedTransactionIds.includes(transaction.id)}
                  />
                </TableCell>
                <TableCell size="sm">{transaction.moneyspace.name}</TableCell>
                <TableCell size="xs">{transaction.moneyspace.contractor?.name}</TableCell>
                <TableCell size="sm">
                  <TransactionAmountView transaction={transaction} />
                </TableCell>
                <TableCell size="xs">
                  <Label text={transaction.paymentDate ? formatDate(transaction.paymentDate) : '-'} />
                </TableCell>
                <TableCell size="xs">
                  <Label text={transaction.closingDate ? formatDate(transaction.closingDate) : '-'} />
                </TableCell>
                <TableCell size="sm">
                  <Label text={transaction.name} />
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <Pagination count={transactions.count} page={page} size={100} onChange={handlePage} />
        </FlexColumn>
      )}
    </FlexColumn>
  )
}

import Label from '../../atoms/display/Label'
import {
  Transaction,
  TransactionPhase,
  TransactionPhaseBillingDetail,
  TransactionPhaseEFQ,
  TransactionPhaseOrder,
  TransactionSearchResultItem,
  TransactionStatus,
  TransactionStatusCanceled,
  TransactionStatusClosed,
  TransactionStatusDraft,
  TransactionStatusReview,
  TransactionStatusReviewed,
} from '../../../types/transaction'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { Moneyspace } from '../../../types/Moneyspace'

type TransactionStatusLabelProps = {
  transaction: Transaction | TransactionSearchResultItem
  bold?: boolean
}

function statusName(
  status: TransactionStatus,
  phase: TransactionPhase,
  contracteeName?: string,
  contractorName?: string
) {
  switch (status) {
    case TransactionStatusDraft:
      return '下書き'
    case TransactionStatusReview:
      if ([TransactionPhaseEFQ, TransactionPhaseOrder, TransactionPhaseBillingDetail].includes(phase)) {
        return `${contracteeName ?? '社内'} 確認中`
      }
      return `${contractorName ?? '社内'} 確認中`
    case TransactionStatusReviewed:
      if ([TransactionPhaseEFQ, TransactionPhaseOrder, TransactionPhaseBillingDetail].includes(phase)) {
        return `${contractorName ?? '取引先'} 確認中`
      }
      return `${contracteeName ?? '取引先'} 確認中`
    case TransactionStatusClosed:
      return '完了'
    case TransactionStatusCanceled:
      return '取引中止'
    default:
      return '不明'
  }
}

function noClientStatusName(status: TransactionStatus, companyName?: string) {
  switch (status) {
    case TransactionStatusDraft:
      return '下書き'
    case TransactionStatusReview:
      return `${companyName ?? '社内'} 確認中`
    case TransactionStatusReviewed:
      return `${companyName ?? '取引先'} 確認中`
    case TransactionStatusClosed:
      return '完了'
    case TransactionStatusCanceled:
      return '取引中止'
    default:
      return '不明'
  }
}

function ownerCompanyName(moneyspace: Moneyspace) {
  if (moneyspace.client) {
    if (moneyspace.contractee?.id === moneyspace.client.uid) {
      return moneyspace.contractor?.name
    }
    return moneyspace.contractee?.name
  }
  return undefined
}

export default function TransactionStatusLabel({ transaction, bold }: TransactionStatusLabelProps) {
  const { find } = useMoneyspace()
  const moneyspace = find(transaction.moneyspace.id)
  if (moneyspace?.client) {
    // ぼっちMSは、常にオーナー企業（自社）の確認中として表示する
    const text = noClientStatusName(transaction.status, ownerCompanyName(moneyspace))
    return <Label text={text} variant={bold ? 'subtitle' : undefined} />
  }
  const text = statusName(
    transaction.status,
    transaction.currentPhase,
    transaction.moneyspace.contractee?.name,
    transaction.moneyspace.contractor?.name
  )
  return <Label text={text} variant={bold ? 'subtitle' : undefined} />
}

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import Button from '../../../components/atoms/inputs/Button'
import useRouter from '../../../hooks/useRouter'
import { SessionSelectors } from '../../../store/session/selectors'
import { RegisterActions } from '../../../store/register'
import { SessionActions } from '../../../store/session'

export default function RegisterContracteeLinePage() {
  const { registerContracteeWellcome } = useRouter()
  const user = useSelector(SessionSelectors.user)
  const lineConnectUrl = useSelector(SessionSelectors.lineConnectUrl)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user && !user.lineId) {
      dispatch(SessionActions.getLineConnectUrl())
    }
  }, [dispatch, user])

  const handleConnectLine = useCallback(() => {
    window.open(lineConnectUrl)
    dispatch(RegisterActions.connectLine())
    registerContracteeWellcome()
  }, [dispatch, lineConnectUrl, registerContracteeWellcome])

  const handleSkip = useCallback(() => {
    dispatch(RegisterActions.connectLine())
    registerContracteeWellcome()
  }, [dispatch, registerContracteeWellcome])

  return (
    <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
      <FlexColumn align="center">
        <Label text="アカウント登録" variant="title" />
        <Label text="LINE IDとmonect IDを連携して" />
        <Label text="あなたへの通知をゲット！" />
      </FlexColumn>
      <FlexColumn align="center">
        {user && user.lineId ? (
          <Label text="LINE連携済み" />
        ) : (
          <Button size="lg" onClick={handleConnectLine}>
            LINE連携はこちら
          </Button>
        )}
        <Button size="lg" variant="text" onClick={handleSkip}>
          スキップ
        </Button>
        <FlexRow justify="center">
          <DotPagination count={11} current={10} />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}

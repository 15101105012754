import { FieldError, UseFormGetValues, UseFormRegisterReturn } from 'react-hook-form'
import { useCallback, useState } from 'react'
import TextField from '../../atoms/inputs/TextField'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import ZipcodeRepository from '../../../repositories/ZipcodeRepository'
import Label from '../../atoms/display/Label'

type PostalCodeFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
  onSearchAddress: (address: string) => void
}

export default function PostalCodeField({
  register,
  error,
  disabled,
  getValues,
  onSearchAddress,
}: PostalCodeFieldProps) {
  const [errorMessage, setErrorMessage] = useState<string>()
  const handleSearch = useCallback(async () => {
    setErrorMessage(undefined)
    const repository = new ZipcodeRepository()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const zipcode: string | undefined = getValues('zipCode')
    if (zipcode) {
      const address = await repository.searchAddress({ zipcode })
      if (address === null) {
        setErrorMessage('住所が見つかりません')
      } else {
        onSearchAddress(address)
      }
    }
  }, [getValues, onSearchAddress, setErrorMessage])
  return (
    <FlexRow align="flex-start">
      <TextField
        register={register}
        placeholder="1010051"
        size="xs"
        error={error?.message}
        convertToAscii
        readonly={disabled}
      />
      <FlexColumn>
        <Button onClick={handleSearch} size="md" disabled={disabled}>
          住所検索
        </Button>
        {errorMessage && <Label text={errorMessage} variant="caption" color="error" />}
      </FlexColumn>
    </FlexRow>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FileSelectButton from '../../atoms/inputs/FileSelectButton'
import ClickableLink from '../../atoms/navigation/Link'
import Label from '../../atoms/display/Label'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon } from '../../atoms/display/Icons'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { TransactionActions } from '../../../store/transactions'
import { useAttachmentDeleteDialog, useAttachmentsViewDialog } from '../../../hooks/useDialog'
import { SessionSelectors } from '../../../store/session/selectors'

type AttachmentsFormProps = {
  transactionId: string
  disabled?: boolean
}

export default function AttachmentsForm({ transactionId, disabled }: AttachmentsFormProps) {
  const max = 10
  const attachments = useSelector(TransactionSelectors.attachments)
  const user = useSelector(SessionSelectors.user)
  const attachmentsViewDialog = useAttachmentsViewDialog()
  const attachmentDeleteDialog = useAttachmentDeleteDialog()
  const [error, setError] = useState<string | undefined>()
  const dispatch = useDispatch()
  useEffect(() => {
    if (document) {
      dispatch(TransactionActions.fetchAttachments({ id: transactionId }))
    }
  }, [transactionId, dispatch])
  const handleUploadAttachment = useCallback(
    (files: File[]) => {
      setError(undefined)
      if (files.length + attachments.length > 10) {
        setError('添付ファイルは最大10件までアップロードできます')
        return
      }
      if (files.some((file) => file.size > 20 * 1024 * 1024)) {
        setError('添付ファイルは最大20MBまでアップロードできます')
        return
      }
      dispatch(TransactionActions.uploadAttachments({ transactionId, files }))
    },
    [attachments.length, transactionId, dispatch]
  )
  return (
    <FlexColumn>
      <FlexRow>
        <FlexRow width={128}>
          <FileSelectButton
            accepts={['text/*', 'image/*', 'application/*', 'audio/*', 'model/*', 'font/*']}
            multiple
            disabled={attachments.length >= max || user?.role === 3}
            onSelect={handleUploadAttachment}
          >
            添付ファイル
          </FileSelectButton>
        </FlexRow>
        <FlexRow wrap>
          {attachments.map((attachment) => (
            <FlexRow align="center" space={0}>
              <ClickableLink onClick={() => attachmentsViewDialog.open(attachment.id, attachments)}>
                <Label text={attachment.name} />
              </ClickableLink>
              <IconButton
                label="削除"
                disabled={user?.role === 3 || disabled}
                onClick={() => attachmentDeleteDialog.open(transactionId, attachment)}
                color="caution"
              >
                <DeleteIcon />
              </IconButton>
            </FlexRow>
          ))}
        </FlexRow>
      </FlexRow>
      {error && <Label text={error} color="error" variant="caption" />}
    </FlexColumn>
  )
}

import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FlexColumn, FlexRow } from '../components/atoms/layout/Flex'
import Button from '../components/atoms/inputs/Button'
import SearchField from '../components/molecules/inputs/SearchField'
import usePage from '../hooks/usePage'
import { MoneyspacesSelectors } from '../store/moneyspaces/selectors'
import List from '../components/atoms/display/List'
import ListItem from '../components/atoms/display/ListItem'
import useRouter from '../hooks/useRouter'
import ChatPanel from '../components/organisms/chat/ChatPanel'
import { useMessageCreateDialog, useReservedMessageDialog } from '../hooks/useDialog'
import { useDeviceType } from '../hooks/useDeviceType'
import DecorationBox from '../components/atoms/display/DecorationBox'
import { MoneyspacesActions } from '../store/moneyspaces'
import Label from '../components/atoms/display/Label'
import Avatar from '../components/atoms/display/Avatar'
import LabelEllipsis from '../components/atoms/display/LabelEllipsis'
import Badge from '../components/atoms/display/Badge'
import { MoneyspaceIcon, UpdateIcon } from '../components/atoms/display/Icons'
import { ChatActions } from '../store/chat'
import IconButton from '../components/atoms/inputs/IconButton'

function BadgedIcon(props: { count?: number; children: ReactNode }) {
  const { count, children } = props
  if (count && count > 0) {
    return (
      <Badge label="" color="error" variant="dot">
        {children}
      </Badge>
    )
  }
  return <>{children}</>
}

export default function MessagesPage() {
  const { changeTitle } = usePage()
  const { pc } = useDeviceType()
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const { moneyspaceMessages } = useRouter()
  const { messageMsId } = useParams()
  const moneyspaces = useSelector(MoneyspacesSelectors.latestMessages)
  const messageSendDialog = useMessageCreateDialog()
  const reservedMessageDialog = useReservedMessageDialog()
  const dispatch = useDispatch()

  useEffect(() => {
    changeTitle('メッセージ管理')
  }, [changeTitle])

  useEffect(() => {
    dispatch(MoneyspacesActions.fetchMoneyspaceSortMessages())
  }, [dispatch])

  useEffect(() => {
    if (!messageMsId && moneyspaces.length > 0) {
      moneyspaceMessages(moneyspaces[0].id)
    }
  }, [messageMsId, moneyspaceMessages, moneyspaces])

  useEffect(() => {
    dispatch(MoneyspacesActions.fetchMoneyspaceSortMessages())
    if (messageMsId) {
      dispatch(ChatActions.fetchMessages({ moneyspaceId: messageMsId }))
    }
    return () => {
      if (messageMsId) {
        dispatch(ChatActions.readMessages({ moneyspaceId: messageMsId }))
      }
    }
  }, [dispatch, messageMsId])

  const handleSelectMoneyspace = (msId: string) => {
    moneyspaceMessages(msId)
  }

  const handleUpdate = useCallback(() => {
    dispatch(MoneyspacesActions.fetchMoneyspaceSortMessages())
    if (messageMsId) {
      dispatch(ChatActions.fetchMessages({ moneyspaceId: messageMsId }))
    }
  }, [dispatch, messageMsId])

  return (
    <FlexRow wrap>
      <DecorationBox type="Border" color="lightgray">
        <FlexColumn width={pc ? '240px' : '100%'} pa={1}>
          <FlexColumn align="flex-end">
            <Button size="lg" onClick={messageSendDialog.open}>
              新規メッセージ
            </Button>
            <FlexRow justify="flex-end" space={0}>
              <Button variant="text" onClick={() => reservedMessageDialog.open()}>
                予約送信の確認
              </Button>
              <IconButton label="更新" onClick={handleUpdate}>
                <UpdateIcon />
              </IconButton>
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <SearchField placeholder="マネースペースを検索" onChange={(keyword) => setSearchKeyword(keyword ?? '')} />
            <FlexColumn height={pc ? 'calc(100vh - 243px)' : '240px'}>
              <List>
                {moneyspaces
                  .filter((moneyspace) => moneyspace.name.includes(searchKeyword))
                  .map((moneyspace) => (
                    <ListItem
                      key={moneyspace.id}
                      selected={moneyspace.id === messageMsId}
                      onClick={() => handleSelectMoneyspace(moneyspace.id)}
                    >
                      <FlexColumn scroll={false}>
                        <FlexRow justify="space-between" scroll={false} align="center">
                          <BadgedIcon count={moneyspace.unreadMessageCount}>
                            <MoneyspaceIcon size={16} />
                          </BadgedIcon>
                          <Label text={moneyspace.name} />
                          {moneyspace.latestMessage && (
                            <FlexRow align="center">
                              <Avatar
                                alt={moneyspace.latestMessage.createdBy.name}
                                url={moneyspace.latestMessage.createdBy.icon}
                              />
                              <Label text={moneyspace.latestMessage.createdBy.name} />
                            </FlexRow>
                          )}
                        </FlexRow>
                        {moneyspace.latestMessage && (
                          <LabelEllipsis text={moneyspace.latestMessage.description} width="240px" />
                        )}
                      </FlexColumn>
                    </ListItem>
                  ))}
              </List>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
      </DecorationBox>
      <FlexColumn width={pc ? 'calc(100% - 272px)' : '100%'}>
        {messageMsId && (
          <ChatPanel
            moneyspaceId={messageMsId}
            height={pc ? 'calc(100vh - 280px)' : 'calc(100vh - 684px)'}
            minRows={5}
          />
        )}
      </FlexColumn>
    </FlexRow>
  )
}

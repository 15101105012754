import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { useBillingPaidDialog } from '../../../hooks/useDialog'
import useProcessing from '../../../hooks/useProcessing'
import { TransactionActions } from '../../../store/transactions'

type BillingPaidDialogProps = {
  moneyspaceId: string
  billingId: string
}

export default function BillingPaidDialog({ moneyspaceId, billingId }: BillingPaidDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.paidBilling.typePrefix)
  const { close } = useBillingPaidDialog()
  const dispatch = useDispatch()
  const handlePaid = useCallback(() => {
    startProcess()
    dispatch(
      TransactionActions.paidBilling({
        moneyspaceId,
        billingId,
      })
    )
  }, [startProcess, dispatch, moneyspaceId, billingId])
  return (
    <Dialog
      open
      onClose={close}
      title="支払い"
      actions={
        <Button color="caution" onClick={handlePaid} disabled={processing}>
          確定する
        </Button>
      }
    >
      <FlexColumn space={4}>
        <FlexColumn>
          <Label text="支払済にしますか？" />
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

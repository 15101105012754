import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import {
  useTransactionSearchConditionDeleteDialog,
  useTransactionSearchConditionFormDialog,
  useTransactionSearchConditionListDialog,
} from '../../../hooks/useDialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon, EditIcon, SearchIcon } from '../../atoms/display/Icons'
import useDay from '../../../hooks/useDay'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { TransactionActions } from '../../../store/transactions'
import { TransactionSearchCondition } from '../../../types/transaction'

export default function TransactionSearchConditionListDialog() {
  const { state, close } = useTransactionSearchConditionListDialog()
  const transactionSearchConditions = useSelector(TransactionSelectors.searchConditions)
  const transactionSearchConditionFormDialog = useTransactionSearchConditionFormDialog()
  const transactionSearchConditionDeleteDialog = useTransactionSearchConditionDeleteDialog()
  const { formatDate } = useDay()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(TransactionActions.fetchSearchCondition())
  }, [dispatch])
  const handleSearch = useCallback(
    (condition: TransactionSearchCondition) => {
      const { params } = condition
      dispatch(
        TransactionActions.saveSearchParams({
          searchParams: {
            keyword: params.keyword,
            phases: params.phases,
            status: params.status,
            paymentDateFrom: params.paymentDateFrom && formatDate(params.paymentDateFrom),
            paymentDateTo: params.paymentDateTo && formatDate(params.paymentDateTo),
            closingDateFrom: params.closingDateFrom && formatDate(params.closingDateFrom),
            closingDateTo: params.closingDateTo && formatDate(params.closingDateTo),
            amountMin: params.amountMin,
            amountMax: params.amountMax,
            searchType: params.searchType,
            isFavorite: params.isFavorite,
          },
        })
      )
    },
    [dispatch, formatDate]
  )
  return (
    <Dialog open={state !== undefined} onClose={close} title="お気に入り検索条件">
      <FlexColumn>
        {transactionSearchConditions.length === 0 && <Label text="お気に入りの検索条件はありません" />}
        {transactionSearchConditions.map((condition) => (
          <FlexRow justify="space-between" align="center" key={condition.id}>
            <Label text={condition.name} />
            <FlexRow>
              <IconButton label="検索" onClick={() => handleSearch(condition)}>
                <SearchIcon />
              </IconButton>
              <IconButton label="編集" onClick={() => transactionSearchConditionFormDialog.open(condition.id)}>
                <EditIcon />
              </IconButton>
              <IconButton label="削除" onClick={() => transactionSearchConditionDeleteDialog.open(condition.id)}>
                <DeleteIcon />
              </IconButton>
            </FlexRow>
          </FlexRow>
        ))}
      </FlexColumn>
    </Dialog>
  )
}

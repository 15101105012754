import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import Select from '../../atoms/inputs/Select'
import IconButton from '../../atoms/inputs/IconButton'
import { CopyIcon } from '../../atoms/display/Icons'
import Label, { formatText } from '../../atoms/display/Label'
import BillingAmountView from '../../molecules/display/BillingAmountView'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { BillingSearchResultItem } from '../../../types/transaction'
import useDay from '../../../hooks/useDay'
import { TransactionActions } from '../../../store/transactions'
import useRouter from '../../../hooks/useRouter'
import BillingPdfButtons from '../transaction/BillingPdfButtons'
import TransactionAmountView from '../../molecules/display/TransactionAmountView'
import useConsts from '../../../hooks/useConsts'
import { useTransactionCopyDialog } from '../../../hooks/useDialog'
import { SessionSelectors } from '../../../store/session/selectors'

type MoneyspaceTabCompletedViewProps = {
  moneyspaceId: string
}

export default function MoneyspaceTabCompletedView({ moneyspaceId }: MoneyspaceTabCompletedViewProps) {
  const dispatch = useDispatch()
  const billings = useSelector(TransactionSelectors.billings)
  const user = useSelector(SessionSelectors.user)
  const [selectedBilling, setSelectedBilling] = useState<BillingSearchResultItem | undefined>()
  const { formatDate } = useDay()
  const { find } = useMoneyspace()
  const moneyspace = find(moneyspaceId)
  const { transaction: transactionPage, moneyspace: moneyspacePage } = useRouter()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const billingId = query.get('billingId')
  const { phaseName } = useConsts()
  const transactionCopyDialog = useTransactionCopyDialog()

  useEffect(() => {
    if (billings.length === 0) {
      setSelectedBilling(undefined)
    } else {
      setSelectedBilling(billings[0])
      const findBilling = billings.find((billing) => billing.id === billingId)
      if (findBilling) {
        setSelectedBilling(findBilling)
      }
    }
  }, [billingId, billings])

  useEffect(() => {
    dispatch(
      TransactionActions.loadBillings({
        searchParams: {
          moneyspaceId,
          isPaid: 1,
        },
      })
    )
  }, [dispatch, moneyspaceId])

  const handleChangeBilling = useCallback(
    (index: number) => {
      if (billings.length > index) {
        setSelectedBilling(billings[index])
        moneyspacePage(moneyspaceId, 'completed', undefined, billings[index].id)
      }
    },
    [billings, moneyspaceId, moneyspacePage]
  )

  const billingItems = billings.map((billing, index) => ({
    value: index,
    label: `支払日 ${billing.paymentDate ? formatDate(billing.paymentDate) : '-'} (${
      billing.transactions.length
    }件 / ${formatText(billing.totalAmount, 'amountNoStyle')}円) ${phaseName(billing.type)}`,
  }))
  const transactions = selectedBilling?.transactions ?? []
  return (
    <FlexColumn space={4}>
      <FlexRow justify="space-between" wrap>
        <FormItem label="支払日（取引数/合計金額）を選択">
          <Select
            items={billingItems}
            value={billings.findIndex((billing) => billing.id === selectedBilling?.id)}
            size="lg"
            onChange={handleChangeBilling}
          />
        </FormItem>
      </FlexRow>
      {selectedBilling && (
        <FlexRow wrap space={4}>
          <FlexColumn>
            <FlexRow align="center">
              {selectedBilling.closingDate && <Label text={`${formatDate(selectedBilling.closingDate)} 締め分`} />}
              <Label text="支払状態" variant="caption" />
              <Label text="支払い済" color="primary" variant="caption" />
            </FlexRow>
            {moneyspace && <BillingAmountView moneyspace={moneyspace} billing={selectedBilling} isFixed />}
          </FlexColumn>
          <FlexColumn>
            <FlexRow justify="space-between" align="center">
              <Label text="取引一覧" variant="subtitle" />
              <BillingPdfButtons billingId={selectedBilling.id} />
            </FlexRow>
            <Table
              header={
                <TableRow>
                  <TableCell header size="lg">
                    取引名
                  </TableCell>
                  <TableCell header size="sm">
                    金額
                  </TableCell>
                  <TableCell header size="xs">
                    分割請求
                  </TableCell>
                  <TableCell header size="xs" />
                </TableRow>
              }
            >
              {transactions.map((transaction) => (
                <TableRow key={transaction.id} onClick={() => transactionPage(transaction.id)}>
                  <TableCell size="lg">{transaction.name}</TableCell>
                  <TableCell size="sm" align="right">
                    <TransactionAmountView transaction={transaction} />
                  </TableCell>
                  <TableCell size="xs" align="center">
                    <>{transaction.times && <Label text={`第${transaction.times}回`} />}</>
                  </TableCell>
                  <TableCell size="xs" align="center">
                    <IconButton
                      label="コピー"
                      disabled={user?.role === 3}
                      onClick={() => transactionCopyDialog.open(transaction.id, transaction.name)}
                    >
                      <CopyIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </FlexColumn>
        </FlexRow>
      )}
    </FlexColumn>
  )
}

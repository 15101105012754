import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type MoneyspaceGroupNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
}

export default function MoneyspaceGroupNameField({ register, error }: MoneyspaceGroupNameFieldProps) {
  return <TextField register={register} placeholder="" error={error?.message} />
}

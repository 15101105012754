import { Control } from 'react-hook-form'
import Select from '../../atoms/inputs/Select'
import useConsts from '../../../hooks/useConsts'

type TaxCategoryFieldProps = {
  name?: string
  control?: Control
  disabled?: boolean
}

export default function TaxCategoryField({ name, control, disabled }: TaxCategoryFieldProps) {
  const { taxCategories } = useConsts()
  const items = Object.entries(taxCategories).map((entry) => ({ value: Number(entry[0]), label: entry[1] }))

  return <Select items={items} size="xs" name={name} control={control} disabled={disabled} />
}

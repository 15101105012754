import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTransactionApplyDialog, useTransactionSubmitDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useProcessing from '../../../hooks/useProcessing'
import { Transaction } from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import { FlexColumn } from '../../atoms/layout/Flex'
import useConsts from '../../../hooks/useConsts'

type TransactionSubmitDialogProps = {
  transaction: Transaction
}

export default function TransactionSubmitDialog({ transaction }: TransactionSubmitDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.submitTransaction.typePrefix)
  const { state, close } = useTransactionSubmitDialog()
  const transactionApplyDialog = useTransactionApplyDialog()
  const { phaseName } = useConsts()
  const dispatch = useDispatch()

  const handleApply = useCallback(() => {
    transactionApplyDialog.open(transaction)
    close()
  }, [close, transaction, transactionApplyDialog])

  const handleSubmit = useCallback(() => {
    startProcess()
    dispatch(
      TransactionActions.submitTransaction({
        transaction,
      })
    )
  }, [dispatch, startProcess, transaction])
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title={`取引（${phaseName(transaction.currentPhase)}）を取引先へ提出する`}
      actions={
        <Button onClick={handleSubmit} disabled={processing}>
          提出
        </Button>
      }
    >
      <FlexColumn space={4}>
        <FlexColumn>
          <Label text="取引をこのマネースペースの取引先に提出しますか？" />
          <Label text="提出すると取引の編集ができなくなります" />
        </FlexColumn>
        <Button onClick={handleApply} size="lg" variant="outlined">
          提出前に社内で内容を確認する
        </Button>
      </FlexColumn>
    </Dialog>
  )
}

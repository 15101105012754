import { FlexColumn } from '../../components/atoms/layout/Flex'
import RegisterStepView from '../../components/molecules/navigation/RegisterStepView'
import Label from '../../components/atoms/display/Label'
import Link from '../../components/atoms/navigation/Link'
import usePath from '../../hooks/usePath'

export default function RegisterCompletedPage() {
  const { login } = usePath()
  return (
    <FlexColumn align="center" mt={4} space={4} width={640} mbWidth={360}>
      <RegisterStepView step={2} />
      <Label text="本登録が完了しました" variant="title" />
      <Link href={login()}>
        <Label text="monectを始める" />
      </Link>
    </FlexColumn>
  )
}

import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { MoneyspaceFolder } from '../../../types/Moneyspace'
import Button from '../../atoms/inputs/Button'
import { useMoneyspaceFolderMoveDialog } from '../../../hooks/useDialog'
import MoneyspaceFolderSelectField from './MoneyspaceFolderSelectField'
import { FlexColumn } from '../../atoms/layout/Flex'
import TreeView from '../../atoms/navigation/TreeView'
import TreeItem from '../../atoms/navigation/TreeItem'
import { FolderIcon } from '../../atoms/display/Icons'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import FormItem from '../../molecules/inputs/FormItem'

type MoneyspaceFolderMoveDialogProps = {
  folder: MoneyspaceFolder
}

function disabledSelectedFolder(folder: MoneyspaceFolder | null, selectedFolder: MoneyspaceFolder | null) {
  if (folder === null) {
    return selectedFolder === null
  }
  if (selectedFolder === null) {
    return false
  }
  if (folder.id === selectedFolder.id) {
    return true
  }
  for (let i = 0; i < folder.children.length; i += 1) {
    const child = folder.children[i]
    if (disabledSelectedFolder(child, selectedFolder)) {
      return true
    }
  }
  return false
}

export default function MoneyspaceFolderMoveDialog({ folder }: MoneyspaceFolderMoveDialogProps) {
  const { state, close } = useMoneyspaceFolderMoveDialog()
  const [selectedFolder, setSelectedFolder] = useState<MoneyspaceFolder | null>(folder)
  const dispatch = useDispatch()
  const handleMove = useCallback(() => {
    dispatch(MoneyspacesActions.moveMoneyspaceFolder({ folder, parentFolderId: selectedFolder?.id ?? null }))
  }, [dispatch, folder, selectedFolder])

  return (
    <Dialog
      open={state !== undefined}
      title="フォルダを移動する"
      actions={
        <Button disabled={disabledSelectedFolder(folder, selectedFolder)} onClick={handleMove}>
          移動
        </Button>
      }
      onClose={close}
    >
      <FlexColumn>
        <FormItem label="移動先フォルダ">
          <TreeView onSelect={() => setSelectedFolder(null)}>
            <TreeItem id="none" icon={<FolderIcon />} label="フォルダなし" />
          </TreeView>
          <MoneyspaceFolderSelectField
            initialSelectedId={folder.id}
            onSelectFolder={(selectFolder) => setSelectedFolder(selectFolder ?? null)}
          />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

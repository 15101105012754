import { FieldErrors, useFieldArray, useFormContext } from 'react-hook-form'
import { useCallback, useEffect } from 'react'
import shortid from 'shortid'
import Decimal from 'decimal.js-light'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { TransactionFormParams } from './TransactionForm'
import FormItem from '../../molecules/inputs/FormItem'
import TextField from '../../atoms/inputs/TextField'
import NumberControl from '../../atoms/inputs/NumberControl'
import SelectControl from '../../atoms/inputs/SelectControl'
import useConsts from '../../../hooks/useConsts'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon, PlusIcon } from '../../atoms/display/Icons'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useDocument from '../../../hooks/useDocument'
import { TransactionDetail } from '../../../types/transaction'
import { DetailPropConfig } from '../../../types/Company'

type TransactionDetailsFormProps = {
  detailPropConfig: DetailPropConfig
  disabled: boolean
}

function ErrorLabel({ errors }: { errors?: FieldErrors<TransactionDetail> }) {
  if (errors) {
    const { message } = errors as unknown as { message: string }
    if (message) {
      return <Label text={message} color="error" variant="caption" />
    }
  }
  return <></>
}

export default function TransactionDetailsForm({ detailPropConfig, disabled }: TransactionDetailsFormProps) {
  const { calcDetailAmount } = useDocument()
  const {
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<TransactionFormParams>()
  const { fields, append, remove } = useFieldArray({ control, name: 'details' })
  const { taxCategories, documentItemMax } = useConsts()
  const taxBucketItems = Object.entries(taxCategories).map((entry) => ({ value: Number(entry[0]), label: entry[1] }))

  useEffect(() => {
    watch((value, { name }) => {
      if (name) {
        if (name.startsWith('details.') && value && value.details) {
          const nodes = name.split('.')
          const index = Number(nodes[1])
          const property = nodes[2]
          const detail = value.details[index]
          if (detail && (property === 'unitPrice' || property === 'quantity')) {
            const { unitPrice, quantity } = detail
            const amount = Math.floor(new Decimal(unitPrice ?? 0).times(quantity ?? 0).toNumber())
            setValue(`details.${index}.amount`, amount)
          }
        }
      }
    })
  }, [calcDetailAmount, setValue, watch])

  useEffect(() => {
    if (fields.length === 0) {
      append({
        id: shortid(),
        unitPrice: undefined,
        quantity: undefined,
        unit: '件',
        taxBucket: 1,
        amount: 0,
      })
    }
  }, [append, fields.length])

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleAdd = useCallback(() => {
    append({
      id: shortid(),
      unitPrice: undefined,
      quantity: undefined,
      unit: '件',
      taxBucket: 1,
      amount: 0,
    })
  }, [append])
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleRemove = useCallback(
    (index: number) => {
      remove(index)
    },
    [remove]
  )

  return (
    <FlexColumn space={4}>
      {fields.map((detail, index) => (
        <FlexRow align="center" key={detail.id}>
          <FlexColumn>
            {errors.details && <ErrorLabel errors={errors.details[index]} />}
            <FlexRow wrap>
              <FormItem label={detailPropConfig.defaultProp.name} required={detailPropConfig.defaultProp.required}>
                <TextField
                  register={register(`details.${index}.defaultProp`)}
                  readonly={disabled}
                  error={errors.details && errors.details[index] && errors.details[index].defaultProp?.message}
                />
              </FormItem>
              {detailPropConfig.customProp1.name.length > 0 && (
                <FormItem label={detailPropConfig.customProp1.name} required={detailPropConfig.customProp1.required}>
                  <TextField
                    register={register(`details.${index}.customProp1`)}
                    readonly={disabled}
                    error={errors.details && errors.details[index] && errors.details[index].customProp1?.message}
                  />
                </FormItem>
              )}
              {detailPropConfig.customProp2.name.length > 0 && (
                <FormItem label={detailPropConfig.customProp2.name} required={detailPropConfig.customProp2.required}>
                  <TextField
                    register={register(`details.${index}.customProp2`)}
                    readonly={disabled}
                    error={errors.details && errors.details[index] && errors.details[index].customProp2?.message}
                  />
                </FormItem>
              )}
            </FlexRow>
            <FlexRow wrap>
              <FormItem label="単価" required>
                <NumberControl
                  name={`details.${index}.unitPrice`}
                  control={control}
                  type="amount"
                  size="sm"
                  placeholder="100,000"
                  disabled={disabled}
                  error={errors.details && errors.details[index] && errors.details[index].unitPrice?.message}
                />
              </FormItem>
              <FormItem label="数量" required>
                <NumberControl
                  name={`details.${index}.quantity`}
                  control={control}
                  size="xs"
                  placeholder="1"
                  disabled={disabled}
                  error={errors.details && errors.details[index] && errors.details[index].quantity?.message}
                />
              </FormItem>
              <FormItem label="単位" required>
                <TextField
                  register={register(`details.${index}.unit`)}
                  size="xs"
                  readonly={disabled}
                  error={errors.details && errors.details[index] && errors.details[index].unit?.message}
                />
              </FormItem>
              <FormItem label="税区分" required>
                <SelectControl
                  name={`details.${index}.taxBucket`}
                  items={taxBucketItems}
                  control={control}
                  size="xs"
                  disabled={disabled}
                />
              </FormItem>
              <FormItem label="金額">
                <NumberControl name={`details.${index}.amount`} control={control} readonly type="amount" />
              </FormItem>
            </FlexRow>
          </FlexColumn>
          <IconButton
            label="項目を削除する"
            color="caution"
            disabled={fields.length === 1 || disabled}
            onClick={() => handleRemove(index)}
          >
            <DeleteIcon />
          </IconButton>
        </FlexRow>
      ))}
      <FlexRow>
        <Button size="lg" disabled={fields.length >= documentItemMax || disabled} onClick={handleAdd}>
          <FlexRow align="center">
            <PlusIcon />
            <Label text="項目を追加する" />
          </FlexRow>
        </Button>
      </FlexRow>
    </FlexColumn>
  )
}

import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import { Moneyspace } from '../../../types/Moneyspace'
import MoneyspaceGroupNameField from '../../molecules/inputs/MoneyspaceGroupNameField'

type MoneyspaceGroupFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  errors: FieldErrors<Moneyspace>
}

export default function MoneyspaceGroupForm({ register, errors }: MoneyspaceGroupFormProps) {
  return (
    <FlexColumn>
      <FormItem label="フォルダ名" required>
        <MoneyspaceGroupNameField register={register('name')} error={errors.name} />
      </FormItem>
    </FlexColumn>
  )
}

import MuiAutocomplete from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import { Control, Controller } from 'react-hook-form'
import MuiFormControl from '@mui/material/FormControl/FormControl'
import { FormHelperText } from '@mui/material'
import MuiBox from '@mui/material/Box'
import MuiTypography from '@mui/material/Typography'

type ComboboxSize = 'xs' | 'sm' | 'md' | 'lg'

export type ComboboxItem = {
  value?: string
  label: string
  description?: string
}

type ComboboxProps = {
  items: ComboboxItem[]
  size?: ComboboxSize
  multiple?: boolean
  freeSolo?: boolean
  disabled?: boolean
  disabledFilter?: boolean
  name?: string
  control?: Control
  error?: string
  onInput?: (value?: string) => void
  onSelected?: (value?: string) => void
}

const width = (size?: ComboboxSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'md':
      return 360
    case 'lg':
      return 480
    default:
      return '100%'
  }
}

export default function Combobox({
  items,
  size,
  multiple = false,
  freeSolo,
  disabled,
  disabledFilter,
  name,
  control,
  error,
  onInput,
  onSelected,
}: ComboboxProps) {
  if (name && control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiFormControl error={error !== undefined}>
            <MuiAutocomplete
              {...field}
              disabled={disabled}
              multiple={multiple}
              freeSolo={freeSolo}
              filterOptions={disabledFilter ? (x) => x : undefined}
              options={items}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === 'string') {
                  if (typeof value === 'string') {
                    return option === value
                  }
                  return option === value.value
                }
                if (typeof value === 'string') {
                  return option.value === value
                }
                return option.value === value.value
              }}
              getOptionLabel={(option: ComboboxItem | string) => {
                if (typeof option === 'string') {
                  const result = items.find((item) => item.value === option)
                  if (result) {
                    return result.label
                  }
                  return option
                }
                return option.label
              }}
              size="small"
              sx={{ width: width(size) }}
              renderInput={(params) => (
                <MuiTextField {...params} onChange={(e) => onInput && onInput(e.target.value)} />
              )}
              renderOption={(props, option) => (
                <MuiBox component="li" {...props}>
                  <MuiBox
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    {typeof option === 'string' ? (
                      <MuiTypography>{option}</MuiTypography>
                    ) : (
                      <>
                        <MuiTypography>{option.label}</MuiTypography>
                        {option.description && <MuiTypography variant="caption">{option.description}</MuiTypography>}
                      </>
                    )}
                  </MuiBox>
                </MuiBox>
              )}
              onChange={(_, data) => {
                if (data) {
                  if (Array.isArray(data)) {
                    field.onChange(
                      data.map((item) => {
                        if (typeof item === 'string') {
                          return item
                        }
                        return item.value
                      })
                    )
                  } else if (typeof data === 'string') {
                    field.onChange(data)
                  } else {
                    field.onChange(data.value)
                    if (onSelected) {
                      onSelected(data.value)
                    }
                  }
                } else {
                  field.onChange(data)
                }
              }}
            />
            <FormHelperText>{error}</FormHelperText>
          </MuiFormControl>
        )}
      />
    )
  }
  return (
    <MuiAutocomplete
      options={items}
      getOptionLabel={(option: ComboboxItem) => option.label}
      multiple
      size="small"
      sx={{ width: width(size) }}
      renderInput={(params) => <MuiTextField {...params} />}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  )
}

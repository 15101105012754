import { FlexColumn } from '../../components/atoms/layout/Flex'
import PasswordResetStepView from '../../components/molecules/navigation/PasswordResetStepView'
import Label from '../../components/atoms/display/Label'
import Card from '../../components/atoms/surfaces/Card'
import Link from '../../components/atoms/navigation/Link'
import usePath from '../../hooks/usePath'
import PasswordResetForm from '../../components/organisms/session/PasswordResetForm'

export default function PasswordResetPage() {
  const { login } = usePath()
  return (
    <FlexColumn align="center" mt={4} space={4} mbWidth={360}>
      <PasswordResetStepView step={0} />
      <Label text="パスワードをリセット" variant="title" />
      <FlexColumn>
        <Label text="ご利用いただいているユーザーの企業ID、メールアドレスを入力ください。" />
        <Card size="lg">
          <FlexColumn pa={4} align="center">
            <PasswordResetForm />
            <FlexColumn align="center">
              <Link href={login()}>
                <Label text="ログインはこちら" />
              </Link>
            </FlexColumn>
          </FlexColumn>
        </Card>
      </FlexColumn>
    </FlexColumn>
  )
}

import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'
import MuiBox from '@mui/material/Box'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { PdfParams } from '../../../hooks/usePdf'

type PdfViewerProps = {
  url: string
  height: number
  httpHeaders: { [key: string]: string }
  scale?: number
  params: PdfParams
}

export default function PdfViewer({ url, height, httpHeaders, scale, params }: PdfViewerProps) {
  const plugins = [params.downloadPlugin, params.printPlugin]
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <MuiBox sx={{ height, width: '100%', '& *': { overflow: 'hidden' } }}>
        <Viewer
          fileUrl={url}
          defaultScale={scale || SpecialZoomLevel.PageFit}
          plugins={plugins}
          httpHeaders={httpHeaders}
        />
      </MuiBox>
    </Worker>
  )
}

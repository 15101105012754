import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import Form from '../../atoms/inputs/Form'
import { SessionSelectors } from '../../../store/session/selectors'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { SessionActions } from '../../../store/session'
import Label from '../../atoms/display/Label'
import CheckboxControl from '../../atoms/inputs/CheckboxControl'
import { useDeviceType } from '../../../hooks/useDeviceType'

type NotificationSettingsFormProps = {
  disabledLine?: boolean
}

type NotificationSettingsParams = {
  notifications: {
    id: number
    name: string
    emailEnable: boolean
    lineEnable: boolean
  }[]
}

export default function NotificationSettingsForm({ disabledLine }: NotificationSettingsFormProps) {
  const { pc } = useDeviceType()
  const notifications = useSelector(SessionSelectors.notifications)
  const dispatch = useDispatch()
  const { handleSubmit, control } = useForm<NotificationSettingsParams>({
    mode: 'onChange',
    defaultValues: { notifications },
  })
  const { fields } = useFieldArray<NotificationSettingsParams>({ control, name: 'notifications' })
  const handleSave = useCallback(
    (params: NotificationSettingsParams) => {
      params.notifications.forEach((notification) => {
        dispatch(
          SessionActions.updateNotificationSetting({
            notificationId: notification.id,
            emailEnable: notification.emailEnable,
            lineEnable: notification.lineEnable,
          })
        )
      })
    },
    [dispatch]
  )
  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      {notifications.length > 0 && (
        <FlexColumn>
          <Label text="通知設定" variant="subtitle" />
          <FlexRow scroll={false}>
            <FlexRow width={pc ? 240 : 160} />
            <FlexRow width={60} justify="center">
              <Label text="メール" />
            </FlexRow>
            <FlexRow width={60} justify="center">
              <Label text="LINE" />
            </FlexRow>
          </FlexRow>
          {fields.map((field, index) => (
            <FlexRow key={field.id} align="center" scroll={false}>
              <FlexRow width={pc ? 240 : 160} scroll={false}>
                <Label text={field.name} />
              </FlexRow>
              <FlexRow width={60} justify="center" pl={4} scroll={false}>
                <CheckboxControl name={`notifications.${index}.emailEnable`} control={control} />
              </FlexRow>
              <FlexRow width={60} justify="center" pl={4} scroll={false}>
                <CheckboxControl name={`notifications.${index}.lineEnable`} control={control} disabled={disabledLine} />
              </FlexRow>
            </FlexRow>
          ))}
          <Button type="submit">更新</Button>
        </FlexColumn>
      )}
    </Form>
  )
}

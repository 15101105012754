import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import List from '../../../components/atoms/display/List'
import ListItem from '../../../components/atoms/display/ListItem'

export default function RegisterContracteeEmailSupportPage() {
  return (
    <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
      <FlexColumn align="center" mx={2}>
        <Label text="アカウント登録" variant="title" />
        <FlexColumn width={320} space={4}>
          <FlexColumn space={0}>
            <Label text="メールが届かない場合" variant="subtitle" />
            <FlexColumn ml={2}>
              <List>
                <ListItem>迷惑メールフォルダをご確認ください</ListItem>
                <ListItem>メール受信制限を設定変更してください</ListItem>
                <ListItem>他のメールアドレスで登録をお試しください</ListItem>
              </List>
            </FlexColumn>
          </FlexColumn>
          <Label text="上記の操作をしてもメールが届かない場合は、お問い合わせください" />
          <FlexColumn>
            <Label text="monectサポートセンター" variant="subtitle" />
            <FlexRow>
              <FlexColumn width={64}>
                <Label text="TEL" />
              </FlexColumn>
              <Label text="050-3000-0768" />
            </FlexRow>
            <FlexRow>
              <FlexColumn width={64}>
                <Label text="営業時間" />
              </FlexColumn>
              <Label text="10:00~18:00（土日祝除く）" />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  )
}

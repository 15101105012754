import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'

export type Params =
  | {
      [key: string]:
        | string
        | string[]
        | number
        | number[]
        | (number | null)[][]
        | boolean
        | undefined
        | null
        | Params
        | Params[]
    }
  | Params[]

export type ErrorHandler<T> = {
  forbidden?: (e: AxiosError) => T
  notFound?: (e: AxiosError) => T
}

export interface Request {
  setAuthToken(authToken?: string): void

  get<T>(path: string, params?: Params, errorHandler?: ErrorHandler<T>, silent?: boolean): Promise<AxiosResponse<T>>
  download(
    filename: string,
    path: string,
    params?: Params,
    errorHandler?: ErrorHandler<void>,
    silent?: boolean
  ): Promise<AxiosResponse<void>>
  post<T>(path: string, params?: Params | FormData, silent?: boolean): Promise<AxiosResponse<T>>
  patch<T>(path: string, params?: Params, silent?: boolean): Promise<AxiosResponse<T>>
  put<T>(path: string, params?: Params | FormData, silent?: boolean): Promise<AxiosResponse<T>>
  delete<T>(path: string, params?: Params, silent?: boolean): Promise<AxiosResponse<T>>
}

function handleError<T>(e: AxiosError, errorHandler: ErrorHandler<T>): Promise<AxiosResponse<T>> {
  const { response } = e
  if (response) {
    switch (response.status) {
      case 403:
        if (errorHandler.forbidden) {
          return Promise.resolve<AxiosResponse<T>>({
            ...response,
            data: errorHandler.forbidden(e),
          })
        }
        break
      case 404:
        if (errorHandler.notFound) {
          return Promise.resolve<AxiosResponse<T>>({
            ...response,
            data: errorHandler.notFound(e),
          })
        }
        break
      default:
        throw e
    }
  }
  throw e
}

class AxiosRequest implements Request {
  instance: AxiosInstance

  authToken?: string = undefined

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json',
    })
  }

  setAuthToken(authToken?: string): void {
    this.authToken = authToken
  }

  async get<T>(path: string, params?: Params, errorHandler?: ErrorHandler<T>): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
      headers: this.headers(),
      params,
      paramsSerializer: (values) => qs.stringify(values, { arrayFormat: 'repeat' }),
    }
    try {
      return await this.instance.get<T>(path, config)
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && errorHandler) {
          return handleError<T>(e, errorHandler)
        }
      }
      throw e
    }
  }

  async download(
    filename: string,
    path: string,
    params?: Params,
    errorHandler?: ErrorHandler<void>
  ): Promise<AxiosResponse<void>> {
    const config: AxiosRequestConfig = {
      headers: {
        ...this.headers(),
        'Content-Type': 'text/csv',
      },
      responseType: 'blob',
      params,
      paramsSerializer: (values) => qs.stringify(values, { arrayFormat: 'repeat' }),
    }
    try {
      const response = await this.instance.get<never>(path, config)
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      return response
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && errorHandler) {
          return handleError<void>(e, errorHandler)
        }
      }
      throw e
    }
  }

  post<T>(path: string, params?: Params | FormData): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
      headers: this.headers(),
    }
    return this.instance.post<T>(path, params, config)
  }

  patch<T>(path: string, params?: Params): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
      headers: this.headers(),
    }
    return this.instance.patch<T>(path, params, config)
  }

  put<T>(path: string, params?: Params | FormData): Promise<AxiosResponse<T>> {
    const config: AxiosRequestConfig = {
      headers: this.headers(),
    }
    return this.instance.put<T>(path, params, config)
  }

  delete<T>(path: string, params?: Params): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      headers: this.headers(),
      data: params,
    }
    return this.instance.delete<T>(path, config)
  }

  headers(): { [key: string]: string } {
    if (this.authToken) {
      return {
        Authorization: `Token ${this.authToken}`,
      }
    }
    return {}
  }
}

export default new AxiosRequest()

import MuiDialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import { ReactNode } from 'react'

type ImageDialogProps = {
  open: boolean
  width: number
  children: ReactNode
  onClose: () => void
}

export default function ImageDialog({ open, width, children, onClose }: ImageDialogProps) {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      sx={{ padding: 0 }}
      PaperProps={{ sx: { maxWidth: width, overflow: 'hidden' } }}
    >
      <MuiDialogContent sx={{ padding: 0, marginBottom: '-7px', overflow: 'hidden' }}>{children}</MuiDialogContent>
    </MuiDialog>
  )
}

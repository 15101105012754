import MuiSelect, { SelectChangeEvent } from '@mui/material/Select'
import MuiMenuItem from '@mui/material/MenuItem'
import { useCallback } from 'react'
import { Control, Controller } from 'react-hook-form'
import MuiFormControl from '@mui/material/FormControl/FormControl'
import { FormHelperText } from '@mui/material'
import { useDeviceType } from '../../../hooks/useDeviceType'

export type SelectItem = {
  value?: number | string
  label: string
}

type SelectSize = 'xs' | 'sm' | 'sm2' | 'md' | 'lg'

type SelectProps = {
  value?: number | string
  items: SelectItem[]
  label?: string
  size?: SelectSize
  onChange?: (_value: number) => void
  onChangeString?: (_value: string) => void
  name?: string
  control?: Control
  error?: string
  disabled?: boolean
}

const width = (pc: boolean, size?: SelectSize) => {
  switch (size) {
    case 'xs':
      return 120
    case 'sm':
      return 240
    case 'sm2':
      return 200
    case 'md':
      return pc ? 360 : 240
    case 'lg':
      return pc ? 480 : 240
    default:
      return '100%'
  }
}

export default function Select({
  value,
  items,
  label,
  size = 'md',
  name,
  control,
  error,
  disabled,
  onChange,
  onChangeString,
}: SelectProps) {
  const { pc } = useDeviceType()
  const handleChange = useCallback(
    (e: SelectChangeEvent<number>) => {
      if (onChange) {
        onChange(Number(e.target.value))
      }
      if (onChangeString) {
        onChangeString(String(e.target.value))
      }
    },
    [onChange, onChangeString]
  )
  if (name && control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiFormControl error={error !== undefined}>
            <MuiSelect
              label={label}
              variant="outlined"
              size="small"
              sx={{ width: width(pc, size) }}
              disabled={disabled}
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value ?? null)
              }}
            >
              {items.map((item) => (
                <MuiMenuItem key={item.value ?? 'none'} value={item.value}>
                  {item.label}
                </MuiMenuItem>
              ))}
            </MuiSelect>
            <FormHelperText>{error}</FormHelperText>
          </MuiFormControl>
        )}
      />
    )
  }
  return (
    <MuiSelect
      value={Number(value)}
      label={label}
      onChange={handleChange}
      variant="outlined"
      size="small"
      sx={{ width: width(pc, size) }}
    >
      {items.map((item) => (
        <MuiMenuItem key={item.value} value={item.value}>
          {item.label}
        </MuiMenuItem>
      ))}
    </MuiSelect>
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import { useClientDeleteDialog } from '../../../hooks/useDialog'
import { Company } from '../../../types/Company'
import { CompanyActions } from '../../../store/company'
import useProcessing from '../../../hooks/useProcessing'

type ClientDeleteDialogProps = {
  client: Company
}

export default function ClientDeleteDialog({ client }: ClientDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(CompanyActions.deleteClient.typePrefix)
  const { state, close } = useClientDeleteDialog()
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    startProcess()
    dispatch(CompanyActions.deleteClient({ client }))
  }, [startProcess, dispatch, client])

  if (state) {
    return (
      <Dialog
        open={state !== undefined}
        onClose={close}
        title="取引先の削除"
        actions={
          <Button color="caution" onClick={handleDelete} disabled={processing}>
            削除
          </Button>
        }
      >
        <FlexColumn space={4}>
          <FlexColumn>
            <FlexRow>
              <Label text="削除する取引先" />
              <Label text={state.client.name} />
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <Label text="この取引先を削除しますか？" />
          </FlexColumn>
        </FlexColumn>
      </Dialog>
    )
  }
  return <></>
}

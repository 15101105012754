import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { useTransactionCancelDialog } from '../../../hooks/useDialog'
import useProcessing from '../../../hooks/useProcessing'
import { Transaction } from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import FormItem from '../../molecules/inputs/FormItem'
import TextField from '../../atoms/inputs/TextField'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'

type TransactionCancelDialogProps = {
  transaction?: Transaction
}

type CancelParams = {
  note?: string
}

export default function TransactionCancelDialog({ transaction }: TransactionCancelDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.cancelTransaction.typePrefix)
  const { close } = useTransactionCancelDialog()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    memo: rules.transactionCancelMemo,
  })
  const { handleSubmit, register } = useForm<CancelParams>(schema)
  const handleCancel = useCallback(
    (params: CancelParams) => {
      if (transaction) {
        startProcess()
        dispatch(
          TransactionActions.cancelTransaction({
            transactionId: transaction.id,
            note: params.note,
          })
        )
      }
    },
    [dispatch, startProcess, transaction]
  )
  if (transaction) {
    return (
      <Dialog
        open
        onClose={close}
        title="取引の中止"
        actions={
          <Button type="submit" color="caution" disabled={processing}>
            中止
          </Button>
        }
        onSubmit={handleSubmit(handleCancel)}
      >
        <FlexColumn space={4}>
          <FlexColumn>
            <FlexRow>
              <Label text="中止する取引" />
              <Label text={transaction?.name} />
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <Label text="この取引を中止しますか？" />
            <FormItem label="中止理由">
              <TextField multiline rows={5} register={register('note')} />
            </FormItem>
          </FlexColumn>
        </FlexColumn>
      </Dialog>
    )
  }
  return <></>
}

import MuiAppBar from '@mui/material/AppBar'
import MuiToolbar from '@mui/material/Toolbar'
import { Component } from '../../../types/react'

type Color = 'inherit' | 'primary' | 'secondary' | 'default'

type AppBarProps = {
  color?: Color
  position?: 'fixed' | 'static'
  children: Component
}

export default function AppBar({ color = 'secondary', position = 'fixed', children }: AppBarProps) {
  return (
    <MuiAppBar position={position} elevation={0} color={color}>
      <MuiToolbar variant="dense" disableGutters>
        {children}
      </MuiToolbar>
    </MuiAppBar>
  )
}

import { useSelector } from 'react-redux'
import PdfViewer from '../../atoms/display/PdfViewer'
import { SessionSelectors } from '../../../store/session/selectors'
import useDocument from '../../../hooks/useDocument'
import { PdfParams } from '../../../hooks/usePdf'
import { useDeviceType } from '../../../hooks/useDeviceType'

type DocumentPdfViewerProps = {
  documentId: string
  pdfParams: PdfParams
}

export default function DocumentPdfViewer({ documentId, pdfParams }: DocumentPdfViewerProps) {
  const token = useSelector(SessionSelectors.token)
  const { pc } = useDeviceType()
  const { pdfUrl } = useDocument()
  if (token === undefined) {
    return <></>
  }
  return (
    <PdfViewer
      url={pdfUrl(documentId)}
      httpHeaders={{
        authorization: `Token ${token}`,
      }}
      height={pc ? 960 : 480}
      scale={pc ? 1.15 : 0.58}
      params={pdfParams}
    />
  )
}

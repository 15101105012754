import MuiButton from '@mui/material/Button'
import { PdfParams } from '../../../hooks/usePdf'
import '../../../styles/print.css'

type PdfPrintButtonProps = {
  params: PdfParams
}

export default function PdfPrintButton({ params }: PdfPrintButtonProps) {
  const { Print } = params.printPlugin
  return (
    <Print>
      {({ onClick }) => (
        <MuiButton variant="contained" sx={{ width: '160px' }} onClick={onClick}>
          印刷
        </MuiButton>
      )}
    </Print>
  )
}

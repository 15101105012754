import { Viewer, Worker } from '@react-pdf-viewer/core'

import { useSelector } from 'react-redux'
import { FlexRow } from '../../atoms/layout/Flex'
import IconButton from '../../atoms/inputs/IconButton'
import { DownloadIcon, PdfIcon, PrintIcon } from '../../atoms/display/Icons'
import usePdf from '../../../hooks/usePdf'
import useTransaction from '../../../hooks/useTransaction'
import { SessionSelectors } from '../../../store/session/selectors'
import { TransactionPhase } from '../../../types/transaction'
import { useTransactionPdfPreviewDialog } from '../../../hooks/useDialog'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/print/lib/styles/index.css'

type TransactionPdfButtonsProps = {
  transactionId: string
  phase: TransactionPhase
}

export default function TransactionPdfButtons({ transactionId, phase }: TransactionPdfButtonsProps) {
  const pdfParams = usePdf({ filename: 'pdf' })
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { Download } = pdfParams.downloadPlugin
  const { Print } = pdfParams.printPlugin
  const { pdfUrl } = useTransaction()
  const token = useSelector(SessionSelectors.token)
  const TransactionPdfPreviewDialog = useTransactionPdfPreviewDialog()

  if (token === undefined) {
    return <></>
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <FlexRow>
        <Download>
          {({ onClick }) => (
            <IconButton label="ダウンロード" onClick={onClick}>
              <DownloadIcon />
            </IconButton>
          )}
        </Download>
        <Print>
          {({ onClick }) => (
            <IconButton label="印刷" onClick={onClick}>
              <PrintIcon />
            </IconButton>
          )}
        </Print>
        <IconButton label="プレビュー" onClick={() => TransactionPdfPreviewDialog.open(transactionId, phase)}>
          <PdfIcon />
        </IconButton>
        <FlexRow height={0}>
          <Viewer
            fileUrl={pdfUrl(transactionId, phase)}
            plugins={[pdfParams.downloadPlugin, pdfParams.printPlugin]}
            httpHeaders={{ authorization: `Token ${token}` }}
          />
        </FlexRow>
      </FlexRow>
    </Worker>
  )
}

import { User } from '../types/User'
import { Document } from '../types/Document'
import { Moneyspace } from '../types/Moneyspace'
import { ApprovalFlowState } from '../types/ApprovalFlow'
import {
  Transaction,
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionPhaseDelivered,
  TransactionPhaseEFQ,
  TransactionPhaseOrder,
  TransactionPhaseOrderConfirm,
  TransactionPhaseQuotation,
  TransactionStatusCanceled,
  TransactionStatusClosed,
  TransactionStatusDraft,
  TransactionStatusReview,
  TransactionStatusReviewed,
} from '../types/transaction'

type CompanyPermissions = {
  edit: (user?: User) => boolean
}

type MoneyspacePermissions = {
  view: () => boolean
  create: (user?: User) => boolean
  edit: (moneyspace: Moneyspace, user?: User) => boolean
  move: (user?: User) => boolean
  pinned: (user?: User) => boolean
  delete: (moneyspace: Moneyspace, user?: User) => boolean
  members: (user?: User) => boolean
}

type MoneyspaceFolderPermissions = {
  view: () => boolean
  create: (user?: User) => boolean
  edit: (user?: User) => boolean
  delete: (user?: User) => boolean
}

export type DocumentPermissions = {
  view: (contractee: boolean, document: Document, moneyspace: Moneyspace) => boolean
  edit: (contractee: boolean, document: Document, moneyspace: Moneyspace) => boolean
  copy: (document: Document) => boolean
  delete: (contractee: boolean, document: Document, moneyspace: Moneyspace) => boolean
  apply: (contractee: boolean, document: Document, moneyspace: Moneyspace) => boolean
  viewApprovalFlowState: (contractee: boolean, document: Document, moneyspace: Moneyspace) => boolean
  approve: (
    contractee: boolean,
    document: Document,
    moneyspace: Moneyspace,
    user?: User,
    approvalFlowState?: ApprovalFlowState
  ) => boolean
  reject: (
    contractee: boolean,
    document: Document,
    moneyspace: Moneyspace,
    user?: User,
    approvalFlowState?: ApprovalFlowState
  ) => boolean
}

export type TransactionPermissions = {
  view: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace) => boolean
  create: (user?: User) => boolean
  edit: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => boolean
  editClosingPaymentDate: (contractee: boolean, transaction: Transaction, user?: User) => boolean
  copy: (transaction: Transaction, user?: User) => boolean
  cancel: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => boolean
  apply: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => boolean
  installment: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => boolean
  viewApprovalFlowState: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace) => boolean
  approve: (
    contractee: boolean,
    transaction: Transaction,
    moneyspace: Moneyspace,
    user?: User,
    approvalFlowState?: ApprovalFlowState
  ) => boolean
  reject: (
    contractee: boolean,
    transaction: Transaction,
    moneyspace: Moneyspace,
    user?: User,
    approvalFlowState?: ApprovalFlowState
  ) => boolean
  delete: (user?: User) => boolean
}

type UserPermissions = {
  view: () => boolean
  create: (user?: User) => boolean
  edit: (target: User, user?: User) => boolean
  delete: (target: User, user?: User) => boolean
}

type ApprovalFlowPermissions = {
  create: (user?: User) => boolean
  edit: (user?: User) => boolean
  delete: (user?: User) => boolean
}

type ClientPermissions = {
  create: (user?: User) => boolean
}

function isDocumentOwner(contractee: boolean, document: Document) {
  if (contractee) {
    return [1, 3, 6].includes(document.type) ? [1, 2, 4].includes(document.status) : [3].includes(document.status)
  }
  return [2, 4, 5, 7, 8].includes(document.type) ? [1, 2, 4].includes(document.status) : [3].includes(document.status)
}

function isTransactionOwner(contractee: boolean, transaction: Transaction) {
  if (contractee) {
    return [TransactionPhaseEFQ, TransactionPhaseOrder, TransactionPhaseBillingDetail].includes(
      transaction.currentPhase
    )
      ? [TransactionStatusDraft, TransactionStatusReview, TransactionStatusClosed].includes(transaction.status)
      : [TransactionStatusReviewed].includes(transaction.status)
  }
  return [
    TransactionPhaseQuotation,
    TransactionPhaseOrderConfirm,
    TransactionPhaseDelivered,
    TransactionPhaseBilling,
  ].includes(transaction.currentPhase)
    ? [TransactionStatusDraft, TransactionStatusReview, TransactionStatusClosed].includes(transaction.status)
    : [TransactionStatusReviewed].includes(transaction.status)
}

export default function usePermission() {
  const companyPermissions: CompanyPermissions = {
    edit: (user?: User) => user?.role === 1,
  }
  const moneyspacePermissions: MoneyspacePermissions = {
    view: () => true,
    create: (user?: User) => user?.role !== 3,
    edit: (moneyspace: Moneyspace, user?: User) => {
      if (user === undefined || user.role === 3) {
        return false
      }
      if (moneyspace.isContractee) {
        if (moneyspace.contractee?.id !== user.companyId) {
          return false
        }
      } else if (moneyspace.contractor?.id !== user.companyId) {
        return false
      }
      return true
    },
    delete: (moneyspace: Moneyspace, user?: User) => {
      if (user === undefined) {
        return false
      }
      if (!moneyspace.isDeletable) {
        return false
      }
      if (moneyspace.isContractee) {
        if (moneyspace.contractee?.id !== user.companyId) {
          return false
        }
      } else if (moneyspace.contractor?.id !== user.companyId) {
        return false
      }
      if (user.role === 3) {
        return moneyspace.createdBy.id === user.id
      }
      return true
    },
    move: (user?: User) => user?.role !== 3,
    pinned: (user?: User) => user?.role !== 3,
    members: (user?: User) => {
      if (user === undefined) {
        return false
      }
      return user.role !== 3
    },
  }
  const moneyspaceFolderPermissions: MoneyspaceFolderPermissions = {
    view: () => true,
    create: (user?: User) => user?.role !== 3,
    edit: (user?: User) => user?.role !== 3,
    delete: (user?: User) => user?.role !== 3,
  }
  const documentPermissions: DocumentPermissions = {
    view: (contractee: boolean, document: Document, moneyspace: Moneyspace) => {
      if (moneyspace.client) return true
      return isDocumentOwner(contractee, document) ? true : [3, 4, 5].includes(document.status)
    },
    edit: (contractee: boolean, document: Document, moneyspace: Moneyspace) =>
      moneyspace.client !== null || isDocumentOwner(contractee, document) ? [1].includes(document.status) : false,
    copy: (document: Document) => [4, 5].includes(document.status), // TODO 要検討
    delete: (contractee: boolean, document: Document, moneyspace: Moneyspace) =>
      moneyspace.client !== null || isDocumentOwner(contractee, document) ? [1].includes(document.status) : false,
    apply: (contractee: boolean, document: Document, moneyspace: Moneyspace) =>
      moneyspace.client !== null || isDocumentOwner(contractee, document) ? [1].includes(document.status) : false,
    viewApprovalFlowState: (contractee: boolean, document: Document, moneyspace: Moneyspace) => {
      if (moneyspace.client !== null || isDocumentOwner(contractee, document)) {
        if (document.type === 7) {
          return [2, 3].includes(document.status)
        }
        return [2].includes(document.status)
      }
      return false
    },
    approve: (
      contractee: boolean,
      document: Document,
      moneyspace: Moneyspace,
      user?: User,
      approvalFlowState?: ApprovalFlowState
    ) => {
      if (document.type !== 7 && isDocumentOwner(contractee, document) && [3].includes(document.status)) {
        return true
      }
      if (user === undefined || approvalFlowState === undefined) {
        return false
      }
      if (moneyspace.client !== null || isDocumentOwner(contractee, document)) {
        const member = approvalFlowState.members.find((m) => m.step === approvalFlowState.currentStep)
        if (member) {
          return [2, 3].includes(document.status) && member.user.id === user.id
        }
      }
      return false
    },
    reject: (
      contractee: boolean,
      document: Document,
      moneyspace: Moneyspace,
      user?: User,
      approvalFlowState?: ApprovalFlowState
    ) => {
      if (document.type !== 7 && isDocumentOwner(contractee, document) && [3].includes(document.status)) {
        return true
      }
      if (user === undefined || approvalFlowState === undefined) {
        return false
      }
      if (moneyspace.client !== null || isDocumentOwner(contractee, document)) {
        const member = approvalFlowState.members.find((m) => m.step === approvalFlowState.currentStep)
        if (member) {
          return [2, 3].includes(document.status) && member.user.id === user.id
        }
      }
      return false
    },
  }
  const transactionPermissions: TransactionPermissions = {
    view: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace) => {
      if (moneyspace.client) return true
      return isTransactionOwner(contractee, transaction)
        ? true
        : [TransactionStatusReviewed, TransactionStatusClosed, TransactionStatusCanceled].includes(transaction.status)
    },
    create: (user?: User) => user?.role !== 3,
    edit: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => {
      if (user === undefined || user.role === 3) {
        return false
      }
      return moneyspace.client !== null || isTransactionOwner(contractee, transaction)
        ? [TransactionStatusDraft].includes(transaction.status) && transaction.installment == null
        : false
    },
    editClosingPaymentDate: (contractee: boolean, transaction: Transaction, user?: User) => {
      if (user === undefined || user.role === 3) {
        return false
      }
      return (
        contractee &&
        [TransactionStatusReviewed].includes(transaction.status) &&
        transaction.currentPhase === TransactionPhaseBilling
      )
    },
    copy: (transaction: Transaction, user?: User) => {
      if (user === undefined || user.role === 3) {
        return false
      }
      return ![TransactionStatusClosed, TransactionStatusCanceled].includes(transaction.status)
    },
    cancel: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => {
      if (!contractee) {
        return false
      }
      if (user === undefined || user.role === 3) {
        return false
      }
      if (transaction.status === TransactionStatusCanceled) {
        return false
      }
      return !(
        transaction.phases[transaction.phases.length - 1] === transaction.currentPhase &&
        transaction.status === TransactionStatusClosed
      )
    },
    apply: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => {
      if (user === undefined || user.role === 3) {
        return false
      }
      return moneyspace.client !== null || isTransactionOwner(contractee, transaction)
        ? [TransactionStatusDraft].includes(transaction.status) && transaction.installment == null
        : false
    },
    installment: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace, user?: User) => {
      if (user === undefined || user.role === 3) {
        return false
      }
      return moneyspace.client !== null || isTransactionOwner(contractee, transaction)
        ? [TransactionStatusDraft].includes(transaction.status)
        : false
    },
    viewApprovalFlowState: (contractee: boolean, transaction: Transaction, moneyspace: Moneyspace) => {
      if (moneyspace.client !== null || isTransactionOwner(contractee, transaction)) {
        if (transaction.currentPhase === TransactionPhaseBilling) {
          return [TransactionStatusReview, TransactionStatusReviewed].includes(transaction.status)
        }
        return [TransactionStatusReview].includes(transaction.status)
      }
      return false
    },
    approve: (
      contractee: boolean,
      transaction: Transaction,
      moneyspace: Moneyspace,
      user?: User,
      approvalFlowState?: ApprovalFlowState
    ) => {
      if (
        transaction.currentPhase !== TransactionPhaseBilling &&
        isTransactionOwner(contractee, transaction) &&
        [TransactionStatusReviewed].includes(transaction.status)
      ) {
        return true
      }
      if (user === undefined || approvalFlowState === undefined) {
        return false
      }
      if (moneyspace.client !== null || isTransactionOwner(contractee, transaction)) {
        const member = approvalFlowState.members.find((m) => m.step === approvalFlowState.currentStep)
        if (member) {
          return (
            [TransactionStatusReview, TransactionStatusReviewed].includes(transaction.status) &&
            member.user.id === user.id
          )
        }
      }
      return false
    },
    reject: (
      contractee: boolean,
      transaction: Transaction,
      moneyspace: Moneyspace,
      user?: User,
      approvalFlowState?: ApprovalFlowState
    ) => {
      if (
        transaction.currentPhase !== TransactionPhaseBilling &&
        isTransactionOwner(contractee, transaction) &&
        [TransactionStatusReviewed].includes(transaction.status)
      ) {
        return true
      }
      if (user === undefined || approvalFlowState === undefined) {
        return false
      }
      if (moneyspace.client !== null || isTransactionOwner(contractee, transaction)) {
        const member = approvalFlowState.members.find((m) => m.step === approvalFlowState.currentStep)
        if (member) {
          return (
            [TransactionStatusReview, TransactionStatusReviewed].includes(transaction.status) &&
            member.user.id === user.id
          )
        }
      }
      return false
    },
    delete: (user?: User) => user?.role !== 3,
  }
  const userPermissions: UserPermissions = {
    view: () => true,
    create: (user?: User) => user?.role !== 3,
    edit: (target: User, user?: User) => {
      if (user === undefined) {
        return false
      }
      if (user.id === target.id) {
        return true
      }
      return user.role < target.role
    },
    delete: (target: User, user?: User) => {
      if (user === undefined) {
        return false
      }
      if (target.role === 1 || user.role === 3) {
        return false
      }
      if (user.id === target.id) {
        return true
      }
      return user.role < target.role
    },
  }
  const approvalFlowPermissions: ApprovalFlowPermissions = {
    create: (user?: User) => user?.role !== 3,
    edit: (user?: User) => user?.role !== 3,
    delete: (user?: User) => user?.role !== 3,
  }

  const clientPermissions: ClientPermissions = {
    create: (user?: User) => user?.role !== 3,
  }

  return {
    companyPermissions,
    moneyspacePermissions,
    moneyspaceFolderPermissions,
    documentPermissions,
    transactionPermissions,
    userPermissions,
    approvalFlowPermissions,
    clientPermissions,
  }
}

import Decimal from 'decimal.js-light'
import { DocumentAttributes } from '../types/Document'
import { Moneyspace } from '../types/Moneyspace'
import useConsumptionTax from './useConsumptionTax'
import useDocument from './useDocument'

function taxRate(taxBucket: number) {
  if (taxBucket === 1) {
    return 0.1
  }
  if (taxBucket === 2) {
    return 0.08
  }
  return 0
}

function calcPrice(price: number, taxBucket: number, isTaxIn: boolean) {
  if (isTaxIn) {
    const rate = taxRate(taxBucket)
    const ti = rate === 0 ? 0 : 1 + rate
    return price - Math.trunc(new Decimal(price).times(rate).div(ti).toNumber())
  }
  return price
}

function sum(prev: number, current: number) {
  return prev + current
}

function findMembershipFeeValue(chart: number[][], amount: number): number {
  // eslint-disable-next-line no-restricted-syntax
  for (const item of chart) {
    if (item[1] === null) {
      return item[2]
    }
    if (item[0] <= amount && amount <= item[1]) {
      return item[2]
    }
  }
  return 0
}

function calcMembershipFee(subTotal: number, tax: number, moneyspace: Moneyspace) {
  const { membershipFee } = moneyspace
  if (!membershipFee || membershipFee.chart.length === 0) {
    return undefined
  }
  const amount = membershipFee.isTaxIn ? subTotal + tax : subTotal
  if (amount < 0) {
    return 0
  }
  const value = findMembershipFeeValue(membershipFee.chart, amount)
  if (membershipFee.useRate) {
    return Math.floor((amount * value) / 100)
  }
  return value
}

export default function useDocumentAmounts(moneyspace: Moneyspace, document: DocumentAttributes) {
  const { calc8percent, calc10percent } = useConsumptionTax()
  const { calcDetailAmount } = useDocument()
  const isTaxIn = typeof document.isTaxIn === 'string' ? (document.isTaxIn as string) === 'true' : document.isTaxIn
  const total10Percent = document.details
    .filter((detail) => detail.taxBucket === 1)
    .map((detail) => calcDetailAmount(detail))
    .reduce(sum, 0)
  const subTotal10Percent = calcPrice(total10Percent, 1, isTaxIn)
  const total8Percent = document.details
    .filter((detail) => detail.taxBucket === 2)
    .map((detail) => calcDetailAmount(detail))
    .reduce(sum, 0)
  const subTotal8Percent = calcPrice(total8Percent, 2, isTaxIn)
  const total0Percent = document.details
    .filter((detail) => detail.taxBucket === 3)
    .map((detail) => calcDetailAmount(detail))
    .reduce(sum, 0)
  const subTotal = subTotal10Percent + subTotal8Percent + total0Percent
  const tax10Percent = isTaxIn ? total10Percent - subTotal10Percent : calc10percent(subTotal10Percent)
  const tax8Percent = isTaxIn ? total8Percent - subTotal8Percent : calc8percent(subTotal8Percent)
  const membershipFee = calcMembershipFee(subTotal, tax10Percent + tax8Percent, moneyspace)
  const total = subTotal + tax10Percent + tax8Percent - (membershipFee ?? 0)

  return {
    membershipFee,
    subTotal,
    tax10Percent,
    subTotal10Percent,
    tax8Percent,
    subTotal8Percent,
    total,
  }
}

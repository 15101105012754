import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const documentsSelector = (state: StoreState) => state.documents
export const DocumentsSelectors = {
  document: createSelector([documentsSelector], (state) => state.document),
  documentDiffs: createSelector([documentsSelector], (state) => state.documentDiffs),
  activities: createSelector([documentsSelector], (state) => state.activities),
  approvalFlowState: createSelector([documentsSelector], (state) => {
    if (state.approvalFlowState) {
      const members = Array.from(state.approvalFlowState.members).sort(
        (member1, member2) => member1.step - member2.step
      )
      return {
        ...state.approvalFlowState,
        members,
      }
    }
    return undefined
  }),
  searchParams: createSelector([documentsSelector], (state) => state.searchParams),
  searchDocumentResult: createSelector([documentsSelector], (state) => state.searchResult),
  searchConditions: createSelector([documentsSelector], (state) => state.searchConditions),
  factoringDocuments: createSelector([documentsSelector], (state) => state.factoringDocuments),
  unapprovedDocuments: createSelector([documentsSelector], (state) => state.unapprovedDocuments),
  submittedDocuments: createSelector([documentsSelector], (state) => state.submittedDocuments),
  paymentDocuments: createSelector([documentsSelector], (state) => state.paymentDocuments),
  tags: createSelector([documentsSelector], (state) => state.tags),
}

import { useDispatch, useSelector } from 'react-redux'
import { Component } from '../../types/react'
import Snackbar from '../atoms/feedback/Snackbar'
import systemSlice from '../../store/system'
import { SystemSelectors } from '../../store/system/selectors'
import LoadingLayer from '../molecules/feedback/LoadingLayer'

type SystemProps = {
  children: Component
}

export default function System({ children }: SystemProps) {
  const loading = useSelector(SystemSelectors.loading)
  const loadingMessage = useSelector(SystemSelectors.loadingMessage)
  const message = useSelector(SystemSelectors.message)
  const dispatch = useDispatch()
  const handleCloseMessage = () => dispatch(systemSlice.actions.clearMessage())
  return (
    <>
      <LoadingLayer open={loading} message={loadingMessage} />
      <Snackbar
        open={message?.text !== undefined}
        message={message?.text ?? ''}
        severity={message?.severity ?? 'success'}
        onClose={handleCloseMessage}
      />
      {children}
    </>
  )
}

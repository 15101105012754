import { useSelector } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexRow } from '../../atoms/layout/Flex'
import PdfViewer from '../../atoms/display/PdfViewer'
import usePdf from '../../../hooks/usePdf'
import { SessionSelectors } from '../../../store/session/selectors'
import { useDeviceType } from '../../../hooks/useDeviceType'
import { useBillingPdfPreviewDialog } from '../../../hooks/useDialog'

type BillingPdfPreviewDialogProps = {
  billingId: string
}

export default function BillingPdfPreviewDialog({ billingId }: BillingPdfPreviewDialogProps) {
  const { state, close } = useBillingPdfPreviewDialog()
  const pdfParams = usePdf({})
  const token = useSelector(SessionSelectors.token)
  const { pc } = useDeviceType()
  const baseUrl = process.env.REACT_APP_API_BASE_URL

  if (token && baseUrl) {
    return (
      <Dialog open={state !== undefined} width="lg" title="請求書PDF" onClose={close}>
        <FlexRow align="center" justify="space-between">
          <PdfViewer
            url={`${baseUrl}/billings/${billingId}/pdf`}
            httpHeaders={{
              authorization: `Token ${token}`,
            }}
            height={pc ? 960 : 480}
            scale={pc ? 1.15 : 0.42}
            params={pdfParams}
          />
        </FlexRow>
      </Dialog>
    )
  }
  return <></>
}

import { Request } from './Request'
import {
  Company,
  CompanyAttributes,
  CsvImportSetting,
  CsvImportSettingGetResponse,
  DetailPropConfig,
  TransactionPropConfig,
} from '../types/Company'
import { ApprovalFlow } from '../types/ApprovalFlow'
import { MembershipFee2 } from '../types/Moneyspace'

type LoadCompanyResponse = Company

type LoadClientsResponse = {
  clients: Company[]
}

export default class CompanyRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadCompany(companyId: string, silent?: boolean) {
    const response = await this.request.get<LoadCompanyResponse>(
      `/companies/${companyId}`,
      undefined,
      undefined,
      silent
    )
    return response.data
  }

  async saveCompany(id: string, company: CompanyAttributes) {
    const response = await this.request.put<Company>(`/companies/${id}`, {
      name: company.name,
      representativeName: company.representativeName,
      representativeRole: company.representativeRole,
      zipCode: company.zipCode,
      address: company.address,
      phoneNumber: company.phoneNumber,
      phoneNumberMobile: company.phoneNumberMobile,
      bankName: company.bankName,
      bankCode: company.bankCode,
      branchName: company.branchName,
      branchCode: company.branchCode,
      accountType: company.accountType,
      accountNumber: company.accountNumber,
      accountOwner: company.accountOwner,
      payAfterHolidays: company.payAfterHolidays,
      invoiceLicenseNumber: company.invoiceLicenseNumber,
      fiscalPeriod: company.fiscalPeriod,
    })
    return response.data
  }

  async saveTransactionConfig(
    id: string,
    detailPropConfig: DetailPropConfig,
    taxRoundingType: number,
    transactionPropConfig: TransactionPropConfig
  ) {
    const response = await this.request.put<Company>(`/companies/${id}`, {
      detailPropConfig,
      taxRoundingType,
      transactionPropConfig,
    })
    return response.data
  }

  async saveInvoice(id: string, invoiceLessActionType: number) {
    const response = await this.request.put<Company>(`/companies/${id}`, { invoiceLessActionType })
    return response.data
  }

  async saveCovenant(id: string, covenant: string | null) {
    await this.request.put<Company>(`/companies/${id}`, { covenant })
  }

  async loadClients(companyId: string, silent?: boolean) {
    const response = await this.request.get<LoadClientsResponse>(
      `/companies/${companyId}/clients`,
      undefined,
      undefined,
      silent
    )
    return response.data.clients
  }

  async createClient(companyId: string, inviteCompanyUid: string) {
    await this.request.post(`/companies/${companyId}/clients`, {
      companyUid: inviteCompanyUid,
    })
  }

  async saveClient(companyId: string, clientId: string, client: CompanyAttributes) {
    const response = await this.request.put<Company>(`/companies/${companyId}/clients/${clientId}`, {
      name: client.name,
      representativeName: client.representativeName,
      representativeRole: client.representativeRole,
      zipCode: client.zipCode,
      address: client.address,
      bankName: client.bankName,
      bankCode: client.bankCode,
      branchName: client.branchName,
      branchCode: client.branchCode,
      accountType: client.accountType,
      accountNumber: client.accountNumber,
      accountOwner: client.accountOwner,
    })
    return response.data
  }

  async deleteClient(companyId: string, client: Company) {
    await this.request.delete(`/companies/${companyId}/clients/${client.id}`)
  }

  async acceptClient(companyId: string, clientId: string) {
    await this.request.post(`/companies/${companyId}/clients/${clientId}/accept`)
  }

  async loadApprovalFlows(companyId: string) {
    const response = await this.request.get<{ approvalFlows: ApprovalFlow[] }>(`/companies/${companyId}/approval-flows`)
    return response.data.approvalFlows
  }

  async createApprovalFlow(companyId: string, name: string, users: (string | null)[]) {
    const response = await this.request.post<ApprovalFlow>(`/companies/${companyId}/approval-flows`, {
      name,
      members: users.map((id, index) => ({
        userId: id,
        step: index + 1,
      })),
    })
    return response.data
  }

  async saveApprovalFlow(companyId: string, flowId: string, name: string, users: (string | null)[]) {
    const response = await this.request.put<ApprovalFlow>(`/companies/${companyId}/approval-flows/${flowId}`, {
      name,
      members: users.map((id, index) => ({
        userId: id,
        step: index + 1,
      })),
    })
    return response.data
  }

  async saveAutoReviewBillingPhase(companyId: string, flowId: string, autoReviewBillingPhase: boolean) {
    const response = await this.request.put<ApprovalFlow>(`/companies/${companyId}/approval-flows/${flowId}`, {
      autoReviewBillingPhase,
    })
    return response.data
  }

  async deleteApprovalFlow(companyId: string, flowId: string) {
    await this.request.delete(`/companies/${companyId}/approval-flows/${flowId}`)
  }

  async inviteToken(companyId: string, email?: string) {
    const response = await this.request.post<{ token: string }>(`/companies/${companyId}/invite-client`, { email })
    return response.data.token
  }

  async searchInvoiceLicense(invoiceLicenseNumber: string) {
    const response = await this.request.post<{ name: string | null }>(
      `/validate-invoice-license`,
      { invoiceLicenseNumber },
      true
    )
    return response.data.name
  }

  async saveMembershipFee(companyId: string, membershipFee: MembershipFee2) {
    const chart: (number | null)[][] = []
    let fromAmount = 0
    membershipFee?.conditions.forEach((condition, index) => {
      const toAmount = index === membershipFee.conditions.length - 1 ? null : condition.amount
      chart.push([fromAmount, toAmount, condition.value])
      fromAmount = condition.amount + 1
    })
    await this.request.post(`/companies/${companyId}/membership-fee`, {
      isTaxIn: membershipFee?.taxIn === 2,
      useRate: membershipFee?.type === 2,
      chart,
      maximumFee: membershipFee?.maximumFee,
      roundingType: membershipFee?.roundingType,
    })
  }

  async saveAggregateTransaction(
    companyId: string,
    aggregateTransactions: boolean,
    fixMonth: number | null,
    fixDay: number | null
  ) {
    await this.request.put(`/companies/${companyId}`, {
      aggregateTransactions,
      fixMonth,
      fixDay,
    })
  }

  async validateCompanyUid(uid: string) {
    const response = await this.request.post<{ isUsed: boolean }>('/validate-company-uid', { uid }, true)
    return !response.data.isUsed
  }

  async loadCsvImportSetting(companyId: string): Promise<CsvImportSetting | null> {
    const response = await this.request.get<CsvImportSettingGetResponse | null>(
      `/companies/${companyId}/import-config`,
      undefined,
      {
        forbidden: () => null,
      }
    )
    if (response.data === null) {
      return null
    }
    const { data } = response
    return {
      ...data,
      skippingTargetValues: (data.skippingTargetValues ?? []).map((v) => ({ value: v })),
    }
  }

  async saveCsvImportSetting(companyId: string, setting: CsvImportSetting) {
    await this.request.put(`/companies/${companyId}/import-config`, {
      ...setting,
      skippingTargetValues: setting.skippingTargetValues.map((v) => v.value.trim()),
    })
  }

  async downloadClientCsv(filename: string, companyId: string, searchParamName?: string, searchParamInvoice?: number) {
    let name = searchParamName?.trim()
    if (name?.length === 0) {
      name = undefined
    }
    await this.request.download(filename, `/companies/${companyId}/clients/csv`, {
      name,
      invoice: searchParamInvoice,
    })
  }
}

import MuiTableContainer from '@mui/material/TableContainer'
import MuiTable from '@mui/material/Table'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableBody from '@mui/material/TableBody'
import { Component } from '../../../types/react'

type TableProps = {
  header?: Component
  children: Component
}

export default function Table({ header, children }: TableProps) {
  return (
    <MuiTableContainer>
      <MuiTable>
        {header && <MuiTableHead>{header}</MuiTableHead>}
        <MuiTableBody>{children}</MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  )
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import useRouter from '../../../hooks/useRouter'
import useValidationRule from '../../../hooks/useValidationRule'
import useYup from '../../../hooks/useYup'
import Form from '../../../components/atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import FormItem from '../../../components/molecules/inputs/FormItem'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import TextField from '../../../components/atoms/inputs/TextField'
import { CompanySelectors } from '../../../store/company/selectors'
import { CompanyActions } from '../../../store/company'

type PhoneParams = {
  phoneNumber?: string
  phoneNumberMobile?: string
}

export default function RegisterContracteePhonePage() {
  const company = useSelector(CompanySelectors.company)
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    phoneNumber: rules.phoneNumber.required(),
    phoneNumberMobile: rules.phoneNumberMobile.required(),
  })
  const form = useForm<PhoneParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      phoneNumber: company?.phoneNumber,
      phoneNumberMobile: company?.phoneNumberMobile,
    },
  })
  const router = useRouter()
  const dispatch = useDispatch()

  const handleNext = useCallback(
    (params: PhoneParams) => {
      if (company) {
        dispatch(
          CompanyActions.saveCompany({
            id: company.id,
            company: {
              ...company,
              phoneNumber: params.phoneNumber,
              phoneNumberMobile: params.phoneNumberMobile,
            },
          })
        )
      }
      router.registerContracteeInvoiceLicense()
    },
    [dispatch, router, company]
  )

  return (
    <>
      <Form onSubmit={form.handleSubmit(handleNext)}>
        <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
          <FlexColumn align="center">
            <Label text="企業情報" variant="title" />
            <FlexColumn width="360px">
              <FormItem label="電話番号（会社）">
                <TextField register={form.register('phoneNumber')} error={form.formState.errors.phoneNumber?.message} />
              </FormItem>
              <FormItem label="電話番号（携帯）">
                <TextField
                  register={form.register('phoneNumberMobile')}
                  error={form.formState.errors.phoneNumberMobile?.message}
                />
              </FormItem>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn align="center">
            <Button type="submit" size="lg">
              次へ
            </Button>
            <FlexRow justify="center">
              <DotPagination count={11} current={7} />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </Form>
    </>
  )
}

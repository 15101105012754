import MuiBox from '@mui/material/Box'
import { Component } from '../../../types/react'

type FixedBoxProps = {
  top?: string | number
  left?: string | number
  right?: string | number
  bottom?: string | number
  zIndex?: number
  children: Component
}

export default function FixedBox({ top, left, right, bottom, zIndex, children }: FixedBoxProps) {
  return (
    <MuiBox top={top} left={left} right={right} bottom={bottom} position="fixed" boxSizing="border-box" zIndex={zIndex}>
      {children}
    </MuiBox>
  )
}

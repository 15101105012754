import dayjs from 'dayjs'
import { Request } from './Request'
import {
  Tag,
  Document,
  DocumentItem,
  DocumentAttributes,
  DocumentSearchResultItem,
  DocumentActivity,
  DocumentItemSearchResultItem,
  FactoringDocument,
  DocumentDiffsResponse,
  DocumentSearchParams,
  DocumentTypes,
  DocumentSearchConditionCreateResponse,
  DocumentSearchConditionGetResponse,
} from '../types/Document'
import { ApprovalFlowState } from '../types/ApprovalFlow'
import { ImportDocumentsParams } from '../store/tools'
import { Page, SortDirection } from '../types/System'
import { TransactionDetail } from '../types/transaction'

type DocumentDetailResource = {
  tags: Tag[]
} & DocumentItem

type DocumentResource = {
  details: DocumentDetailResource[]
} & Document

type LoadDocumentsResponse = {
  documents: DocumentResource[]
}

type LoadTagsResponse = {
  tags: Tag[]
}

function valuesToString(values?: number[]) {
  if (values && values.length > 0) {
    return values
  }
  return undefined
}
function textToString(text?: string) {
  if (text && text.length > 0) {
    return text
  }
  return undefined
}
function documentTypesToList(types: DocumentTypes) {
  const list = []
  if (types.type1) {
    list.push(1)
  }
  if (types.type2) {
    list.push(2)
  }
  if (types.type3) {
    list.push(3)
  }
  if (types.type4) {
    list.push(4)
  }
  if (types.type5) {
    list.push(5)
  }
  if (types.type6) {
    list.push(6)
  }
  if (types.type7) {
    list.push(7)
  }
  if (types.type8) {
    list.push(8)
  }
  return list
}

export default class DocumentRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async loadDocuments(moneyspaceId: string) {
    const response = await this.request.get<LoadDocumentsResponse>(`/documents`)
    return response.data.documents.filter((document) => document.moneyspace.id === moneyspaceId)
  }

  async loadDocument(id: string) {
    const response = await this.request.get<Document>(`/documents/${id}`)
    return response.data
  }

  async loadDocumentDiffs(id: string) {
    const response = await this.request.get<DocumentDiffsResponse | undefined>(`/documents/${id}/diffs`, undefined, {
      notFound: () => undefined,
    })
    return response.data
  }

  async loadDocumentActivities(id: string) {
    const response = await this.request.get<{ activities: DocumentActivity[] }>(`/documents/${id}/activities`)
    return response.data.activities
  }

  async loadApprovalFlowState(id: string) {
    const response = await this.request.get<ApprovalFlowState | undefined>(
      `/documents/${id}/approval-flow`,
      {},
      {
        notFound: () => undefined,
      }
    )
    return response.data
  }

  async searchDocuments(
    params: {
      silent?: boolean
    } & DocumentSearchParams
  ) {
    const response = await this.request.get<{ documents: DocumentSearchResultItem[] }>(
      `/documents`,
      {
        freeWord: textToString(params.keyword),
        type: documentTypesToList(params.documentTypes),
        status: valuesToString(params.documentStatus ? [params.documentStatus] : []),
        paymentDateFrom: params.paymentDateFrom,
        paymentDateTo: params.paymentDateTo,
        closingDateFrom: params.closingDateFrom,
        closingDateTo: params.closingDateTo,
      },
      undefined,
      params.silent
    )
    return {
      documents: response.data.documents,
    }
  }

  async searchPagingDocuments(
    params: {
      page: number
      silent?: boolean
    } & DocumentSearchParams
  ) {
    const response = await this.request.get<Page<DocumentSearchResultItem>>(
      `/documents`,
      {
        page: params.page,
        freeWord: textToString(params.keyword),
        role: params.moneyspacePositions,
        type: documentTypesToList(params.documentTypes),
        status: valuesToString(params.documentStatus ? [params.documentStatus] : []),
        paymentDateFrom: params.paymentDateFrom,
        paymentDateTo: params.paymentDateTo,
        closingDateFrom: params.closingDateFrom,
        closingDateTo: params.closingDateTo,
        amountFrom: params.amountMin ?? undefined,
        amountTo: params.amountMax ?? undefined,
      },
      undefined,
      params.silent
    )
    return response.data
  }

  async searchPagingDocumentItems(
    params: {
      page: number
    } & DocumentSearchParams
  ) {
    const response = await this.request.get<Page<DocumentItemSearchResultItem>>(`/document-details`, {
      page: params.page,
      freeWord: textToString(params.keyword),
      role: params.moneyspacePositions,
      type: documentTypesToList(params.documentTypes),
      status: valuesToString(params.documentStatus ? [params.documentStatus] : []),
      paymentDateFrom: params.paymentDateFrom,
      paymentDateTo: params.paymentDateTo,
      closingDateFrom: params.closingDateFrom,
      closingDateTo: params.closingDateTo,
      amountFrom: params.amountMin ?? undefined,
      amountTo: params.amountMax ?? undefined,
    })
    return response.data
  }

  async downloadDocuments(
    params: {
      filename: string
    } & DocumentSearchParams
  ) {
    await this.request.download(params.filename, `/documents/csv`, {
      freeWord: textToString(params.keyword),
      role: params.moneyspacePositions,
      type: documentTypesToList(params.documentTypes),
      status: valuesToString(params.documentStatus ? [params.documentStatus] : []),
      paymentDateFrom: params.paymentDateFrom,
      paymentDateTo: params.paymentDateTo,
      closingDateFrom: params.closingDateFrom,
      closingDateTo: params.closingDateTo,
      amountFrom: params.amountMin ?? undefined,
      amountTo: params.amountMax ?? undefined,
    })
  }

  async downloadDocumentItems(
    params: {
      filename: string
    } & DocumentSearchParams
  ) {
    await this.request.download(params.filename, `/document-details/csv`, {
      freeWord: textToString(params.keyword),
      type: documentTypesToList(params.documentTypes),
      status: valuesToString(params.documentStatus ? [params.documentStatus] : []),
      paymentDateFrom: params.paymentDateFrom,
      paymentDateTo: params.paymentDateTo,
      closingDateFrom: params.closingDateFrom,
      closingDateTo: params.closingDateTo,
      amountFrom: params.amountMin ?? undefined,
      amountTo: params.amountMax ?? undefined,
    })
  }

  async loadUnApprovedDocuments() {
    const response = await this.request.get<{ documents: DocumentSearchResultItem[] }>(
      `/documents`,
      {
        filterType: 'unapproved',
      },
      undefined,
      true
    )
    return response.data.documents
  }

  async searchUnApprovedDocuments(page: number, sortColumn?: string, sortDirection?: SortDirection) {
    const sort = sortColumn && sortDirection ? `${sortColumn}_${sortDirection}` : undefined
    const response = await this.request.get<Page<DocumentSearchResultItem>>(`/documents`, {
      page,
      filterType: 'unapproved',
      sort,
    })
    return response.data
  }

  async searchSubmittedDocuments(page: number, sortColumn?: string, sortDirection?: SortDirection) {
    const sort = sortColumn && sortDirection ? `${sortColumn}_${sortDirection}` : undefined
    const response = await this.request.get<Page<DocumentSearchResultItem>>(`/documents`, {
      page,
      filterType: 'submitted',
      sort,
    })
    return response.data
  }

  async searchPaymentDocuments(page: number, sortColumn?: string, sortDirection?: SortDirection) {
    const sort = sortColumn && sortDirection ? `${sortColumn}_${sortDirection}` : undefined
    const today = dayjs()
    const response = await this.request.get<Page<DocumentSearchResultItem>>(`/documents`, {
      page,
      sort,
      filterType: 'payment',
      role: 1,
      paymentDateFrom: today.startOf('month').format('YYYY/MM/DD'),
      paymentDateTo: today.endOf('month').format('YYYY/MM/DD'),
    })
    return response.data
  }

  async loadSearchConditions(userId: string) {
    const response = await this.request.get<DocumentSearchConditionGetResponse>(`/users/${userId}/search-queries`)
    return response.data
  }

  async createSearchCondition(userId: string, name: string, query: string) {
    const response = await this.request.post<DocumentSearchConditionCreateResponse>(`/users/${userId}/search-queries`, {
      name,
      query,
    })
    return response.data
  }

  async updateSearchCondition(userId: string, queryId: string, name: string) {
    await this.request.put(`/users/${userId}/search-queries/${queryId}`, {
      name,
    })
  }

  async deleteSearchCondition(userId: string, queryId: string) {
    await this.request.delete(`/users/${userId}/search-queries/${queryId}`)
  }

  async loadLatestDocuments() {
    const response = await this.request.get<{ documents: DocumentSearchResultItem[] }>(
      `/documents`,
      {
        filterType: 'lastUpdated',
      },
      undefined,
      true
    )
    return response.data.documents
  }

  async loadFactoringDocuments(silent?: boolean) {
    const response = await this.request.get<{ documents: FactoringDocument[] }>(
      `/documents`,
      {
        filterType: 'factoring',
      },
      undefined,
      silent
    )
    return response.data.documents
  }

  async createDocument(document: DocumentAttributes) {
    // paidAtをnullで指定すると400エラーになるので、ひとまずパラメータに含めないようにundefinedを指定
    const response = await this.request.post<Document>(`/documents`, {
      ...document,
      paidAt: undefined,
    })
    return response.data
  }

  async saveDocument(id: string, document: DocumentAttributes) {
    // paidAtをnullで指定すると400エラーになるので、ひとまずパラメータに含めないようにundefinedを指定
    const response = await this.request.put<Document>(`/documents/${id}`, {
      ...document,
      paidAt: undefined,
    })
    return response.data
  }

  async uploadAttachment(id: string, file: File) {
    const formData = new FormData()
    formData.append('attachment', file)
    const response = await this.request.put<{ attachment: string }>(`/documents/${id}/attachment`, formData)
    return response.data.attachment
  }

  async deleteDocument(id: string) {
    await this.request.delete(`/documents/${id}`)
  }

  async applyDocument(id: string) {
    const response = await this.request.put<Document>(`/documents/${id}/apply`)
    return response.data
  }

  async updateApprovalFlow(id: string, flowId: string) {
    await this.request.put<Document>(`/documents/${id}/approval-flow`, { approvalFlowId: flowId })
  }

  async approveDocument(id: string) {
    const response = await this.request.put<Document>(`/documents/${id}/approve`)
    return response.data
  }

  async bulkApproveDocument(documentIdList: string[]) {
    await this.request.put(`/documents/bulk-approve`, { documentIdList })
  }

  async rejectDocument(id: string, note?: string) {
    const response = await this.request.put<Document>(`/documents/${id}/reject`, { note })
    return response.data
  }

  async deleteAttachment(id: string) {
    await this.request.delete(`/documents/${id}/attachment`)
  }

  async loadTags() {
    const response = await this.request.get<LoadTagsResponse>('/tags')
    return response.data.tags
  }

  async createTag(name: string) {
    const response = await this.request.post<Tag>('/tags', { name })
    return response.data
  }

  async saveTag(tag: Tag) {
    const response = await this.request.put<Tag>(`/tags/${tag.id}`, { name: tag.name })
    return response.data
  }

  async deleteTag(tag: Tag) {
    await this.request.delete(`/tags/${tag.id}`)
  }

  async import(params: ImportDocumentsParams) {
    await this.request.post('/documents/import', [...params.contractCreateParams, ...params.contractUpdateParams])
  }

  async uploadOcrFile(file: File) {
    const formData = new FormData()
    formData.append('file', file)
    const response = await this.request.post<TransactionDetail[]>('/scan-document', formData)
    return { transactionDetails: response.data }
  }
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Document } from '../../../types/Document'
import Dialog from '../../atoms/feedback/Dialog'
import { useApprovalFlowSettingDialog, useDocumentApplyDialog } from '../../../hooks/useDialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useDocument from '../../../hooks/useDocument'
import useProcessing from '../../../hooks/useProcessing'
import { DocumentsActions } from '../../../store/documents'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { ApprovalFlowSelectors } from '../../../store/approval_flow/selectors'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import { RadioCheckedIcon, RadioUncheckedIcon } from '../../atoms/display/Icons'
import { SessionSelectors } from '../../../store/session/selectors'
import { ApprovalFlowActions } from '../../../store/approval_flow'

type DocumentApplyDialogProps = {
  document: Document
}

export default function DocumentApplyDialog({ document }: DocumentApplyDialogProps) {
  const { processing, startProcess } = useProcessing(DocumentsActions.applyDocument.typePrefix)
  const { state, close } = useDocumentApplyDialog()
  const { applyDocument } = useDocument()
  const flows = useSelector(ApprovalFlowSelectors.flows)
  const [selectedFlow, setSelectedFlow] = useState<string>()
  const ApprovalFlowSettingDialog = useApprovalFlowSettingDialog()
  const companyId = useSelector(SessionSelectors.companyId)
  const dispatch = useDispatch()
  useEffect(() => {
    if (companyId && state !== undefined) {
      dispatch(ApprovalFlowActions.fetchFlows({ companyId }))
    }
  }, [companyId, dispatch, state])
  const handleApply = useCallback(() => {
    startProcess()
    if (selectedFlow) {
      applyDocument(document.id, selectedFlow)
    }
  }, [applyDocument, document.id, selectedFlow, startProcess])
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="書類の確認を依頼する"
      actions={
        <Button onClick={handleApply} disabled={selectedFlow === undefined || processing}>
          確認を依頼する
        </Button>
      }
    >
      <FlexColumn space={4}>
        <Label text="承認フローを選択してください" />
        <FlexColumn>
          <FlexColumn maxHeight="480px" scroll>
            {flows.map((flow) => (
              <FlexColumn width="100%" space={0} scroll={false}>
                <Label text={flow.name} />
                <Button variant="outlined" fullWidth onClick={() => setSelectedFlow(flow.id)}>
                  <FlexRow width="100%" align="center">
                    {flow.id === selectedFlow ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                    <AvatarStepper members={flow.members} />
                  </FlexRow>
                </Button>
              </FlexColumn>
            ))}
          </FlexColumn>
          {flows.length < 10 && (
            <FlexRow justify="flex-end">
              <Button variant="text" size="lg" onClick={() => ApprovalFlowSettingDialog.open()}>
                新しい承認フローを作成する
              </Button>
            </FlexRow>
          )}
        </FlexColumn>
        <FlexColumn>
          <Label text="この書類の確認を依頼しますか？" />
          <Label text="確認を依頼すると、書類の編集ができなくなります" />
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { TransactionSelectors } from '../../store/transactions/selectors'
import { TransactionActions } from '../../store/transactions'
import useMoneyspace from '../../hooks/useMoneyspace'
import TransactionInstallmentForm from '../../components/organisms/transaction/TransactionInstallmentForm'
import { FlexColumn, FlexRow } from '../../components/atoms/layout/Flex'
import Label from '../../components/atoms/display/Label'

export default function TransactionInstallmentPage() {
  const { transactionId } = useParams()
  const transaction = useSelector(TransactionSelectors.transaction)
  const { find } = useMoneyspace()
  const moneyspace = find(transaction?.moneyspace.id)
  const dispatch = useDispatch()

  useEffect(() => {
    if (transactionId) {
      dispatch(TransactionActions.loadTransaction({ transactionId }))
    }
  }, [dispatch, transactionId])

  if (transaction && moneyspace) {
    return (
      <FlexColumn>
        <FlexRow>
          <Label text="分割請求" variant="subtitle" />
          <Label text={`第${transaction.times ?? 1}回`} variant="subtitle" />
        </FlexRow>
        <TransactionInstallmentForm transaction={transaction} moneyspace={moneyspace} />
      </FlexColumn>
    )
  }
  return <></>
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { useApprovalFlowSettingDialog, useTransactionApplyDialog } from '../../../hooks/useDialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useProcessing from '../../../hooks/useProcessing'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { ApprovalFlowSelectors } from '../../../store/approval_flow/selectors'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import { RadioCheckedIcon, RadioUncheckedIcon } from '../../atoms/display/Icons'
import { SessionSelectors } from '../../../store/session/selectors'
import { ApprovalFlowActions } from '../../../store/approval_flow'
import { Transaction, TransactionInstallmentParams } from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import useMoneyspace from '../../../hooks/useMoneyspace'
import useConsts from '../../../hooks/useConsts'
import useDay from '../../../hooks/useDay'
import usePath from '../../../hooks/usePath'

type TransactionApplyDialogProps = {
  transaction: Transaction
  installment?: TransactionInstallmentParams
}

export default function TransactionApplyDialog({ transaction, installment }: TransactionApplyDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.applyTransaction.typePrefix)
  const { state, close } = useTransactionApplyDialog()
  const flows = useSelector(ApprovalFlowSelectors.flows)
  const [selectedFlow, setSelectedFlow] = useState<string>()
  const ApprovalFlowSettingDialog = useApprovalFlowSettingDialog()
  const hasPic = transaction.pic != null || transaction.picSub != null
  const companyId = useSelector(SessionSelectors.companyId)
  const dispatch = useDispatch()
  const { isContractee, find } = useMoneyspace()
  const moneyspace = find(transaction.moneyspace.id)
  const user = useSelector(SessionSelectors.user)
  const isContracteeUser = moneyspace ? isContractee(moneyspace, user) : true
  const { phaseName } = useConsts()
  const { formatDate } = useDay()
  const { transaction: transactionPage } = usePath()

  useEffect(() => {
    if (companyId && state !== undefined) {
      dispatch(ApprovalFlowActions.fetchFlows({ companyId }))
    }
  }, [companyId, dispatch, state])
  const handleApply = useCallback(() => {
    startProcess()
    if (selectedFlow) {
      dispatch(
        TransactionActions.applyTransaction({
          transactionId: transaction.id,
          flowId: selectedFlow,
          installment: installment
            ? {
                publishedAt: formatDate(installment.publishedAt),
                closingDate: formatDate(installment.closingDate),
                paymentDate: formatDate(installment.paymentDate),
                deliveryDateFrom: installment.deliveryDateFrom ? formatDate(installment.deliveryDateFrom) : null,
                deliveryDateTo: installment.deliveryDateTo ? formatDate(installment.deliveryDateTo) : null,
                picId: installment.pic,
                picSubId: installment.picSub,
                details: installment.details,
              }
            : undefined,
          getRedirectPath: () => transactionPage(transaction.id),
        })
      )
    }
  }, [dispatch, formatDate, installment, selectedFlow, startProcess, transaction, transactionPage])
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title={`取引（${phaseName(transaction.currentPhase)}）の確認を依頼する`}
      actions={
        <Button onClick={handleApply} disabled={selectedFlow === undefined || processing}>
          確認を依頼する
        </Button>
      }
    >
      <FlexColumn space={4}>
        <Label text="承認フローを選択してください" />
        <FlexColumn>
          {isContracteeUser ? (
            <>
              {!hasPic && (
                <Label
                  text="取引に担当者が設定されていないため、担当者を含む承認フローは選択できません"
                  variant="caption"
                  color="caution.main"
                />
              )}
            </>
          ) : (
            <Label text="担当者を含む承認フローは、発注者のみ選択できます" variant="caption" color="caution.main" />
          )}
          <FlexColumn maxHeight="480px" scroll>
            {flows.map((flow) => (
              <FlexColumn width="100%" space={0} scroll={false} key={flow.id}>
                <Label text={flow.name} />
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setSelectedFlow(flow.id)}
                  disabled={flow.members.some((member) => member.user === null) && (!hasPic || !isContracteeUser)}
                >
                  <FlexRow width="100%" align="center">
                    {flow.id === selectedFlow ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                    <AvatarStepper members={flow.members} />
                  </FlexRow>
                </Button>
              </FlexColumn>
            ))}
          </FlexColumn>
          {flows.length < 10 && (
            <FlexRow justify="flex-end">
              <Button variant="text" size="lg" onClick={() => ApprovalFlowSettingDialog.open()}>
                新しい承認フローを作成する
              </Button>
            </FlexRow>
          )}
        </FlexColumn>
        <FlexColumn>
          <Label text="このフェーズの確認を依頼しますか？" />
          <Label text="確認を依頼すると、取引の編集ができなくなります" />
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import { useTagDeleteDialog } from '../../../hooks/useDialog'
import { Tag } from '../../../types/Document'
import { DocumentsActions } from '../../../store/documents'

type TagDeleteDialogProps = {
  tag: Tag
}

export default function TagDeleteDialog({ tag }: TagDeleteDialogProps) {
  const { state, close } = useTagDeleteDialog()
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    dispatch(DocumentsActions.deleteTag({ tag }))
  }, [dispatch, tag])

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="ユーザーの削除"
      actions={
        <Button color="caution" onClick={handleDelete}>
          削除
        </Button>
      }
    >
      <FlexColumn space={4}>
        <FlexColumn>
          <FlexRow>
            <Label text="削除するタグ" />
            <Label text={tag.name} />
          </FlexRow>
        </FlexColumn>
        <FlexColumn>
          <Label text="このタグを削除しますか？" />
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import Form from '../../atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import CompanyDomainField from '../../molecules/inputs/CompanyDomainField'
import EmailField from '../../molecules/inputs/EmailField'
import Button from '../../atoms/inputs/Button'
import { SessionActions } from '../../../store/session'
import usePath from '../../../hooks/usePath'
import useProcessing from '../../../hooks/useProcessing'

type PasswordResetParams = {
  companyDomain: string
  email: string
}

export default function PasswordResetForm() {
  const { processing, startProcess } = useProcessing(SessionActions.resetPassword.typePrefix)
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    companyDomain: rules.companyDomain.required(),
    email: rules.email.required(),
  })
  const { handleSubmit, register, errors } = useForm<PasswordResetParams>(schema)
  const dispatch = useDispatch()
  const { passwordResetCompleted } = usePath()
  const handlePasswordReset = useCallback(
    (values: PasswordResetParams) => {
      startProcess()
      dispatch(
        SessionActions.resetPassword({
          companyDomain: values.companyDomain,
          email: values.email,
          getRedirectPath: () => passwordResetCompleted(),
        })
      )
    },
    [dispatch, passwordResetCompleted, startProcess]
  )
  return (
    <Form onSubmit={handleSubmit(handlePasswordReset)}>
      <FlexColumn>
        <FormItem label="企業ID" required>
          <CompanyDomainField register={register('companyDomain')} error={errors.companyDomain} />
        </FormItem>
        <FormItem label="メールアドレス" required>
          <EmailField register={register('email')} error={errors.email} />
        </FormItem>
        <FlexRow justify="center">
          <Button type="submit" disabled={processing}>
            送信
          </Button>
        </FlexRow>
      </FlexColumn>
    </Form>
  )
}

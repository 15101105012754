import { Control } from 'react-hook-form'
import RadioGroup from '../../atoms/inputs/RadioGroup'

type BankAccountTypeFieldProps = {
  name: string
  control: Control
  disabled?: boolean
}

export default function BankAccountTypeField({ name, control, disabled }: BankAccountTypeFieldProps) {
  return (
    <RadioGroup
      items={[
        { value: 1, label: '普通' },
        { value: 2, label: '当座' },
      ]}
      row
      name={name}
      control={control}
      disabled={disabled}
    />
  )
}

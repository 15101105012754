import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useUserDeleteDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import TextField from '../../atoms/inputs/TextField'
import { CompanyActions } from '../../../store/company'
import { User } from '../../../types/User'
import { CompanySelectors } from '../../../store/company/selectors'
import useProcessing from '../../../hooks/useProcessing'

type UserDeleteDialogProps = {
  user: User
}

export default function UserDeleteDialog({ user }: UserDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(CompanyActions.deleteUser.typePrefix)
  const { state, close } = useUserDeleteDialog()
  const [enabledDeleteButton, setEnabledDeleteButton] = useState(false)
  const company = useSelector(CompanySelectors.company)
  const dispatch = useDispatch()
  const handleCompanyDomain = useCallback(
    (value?: string) => {
      setEnabledDeleteButton(company?.uid === value)
    },
    [company?.uid]
  )
  const handleUserDelete = useCallback(() => {
    startProcess()
    dispatch(CompanyActions.deleteUser({ user }))
  }, [startProcess, dispatch, user])

  if (state) {
    return (
      <Dialog
        open={state !== undefined}
        onClose={close}
        title="ユーザーの削除"
        actions={
          <FlexRow align="center">
            {user.hasUnapproved && (
              <Label
                text="このユーザーはまだ承認待ちの取引があるため、削除できません"
                color="caution.main"
                variant="caption"
              />
            )}
            <Button
              color="caution"
              disabled={!enabledDeleteButton || user.hasUnapproved || processing}
              onClick={handleUserDelete}
            >
              削除
            </Button>
          </FlexRow>
        }
      >
        <FlexColumn space={4}>
          <FlexColumn>
            <FlexRow>
              <Label text="削除するユーザ" />
              <Label text={state.user.name} />
            </FlexRow>
            <FlexRow>
              <Label text="企業ID" />
              <Label text={company?.uid ?? ''} />
            </FlexRow>
          </FlexColumn>
          <FlexColumn>
            <Label text="このユーザを削除しますか？" />
            <Label text="削除する場合は、以下のテキストボックスに企業IDを入力し、削除ボタンを押してください" />
            <TextField placeholder="企業ID" onChange={handleCompanyDomain} />
          </FlexColumn>
        </FlexColumn>
      </Dialog>
    )
  }
  return <></>
}

import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useCallback, useEffect, useState } from 'react'
import { Transaction, TransactionInstallmentDetail } from '../../../types/transaction'
import Form from '../../atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import DateControl from '../../atoms/inputs/DateControl'
import SelectControl from '../../atoms/inputs/SelectControl'
import Label from '../../atoms/display/Label'
import Alert from '../../atoms/feedback/Alert'
import TransactionInstallmentDetailForm from './TransactionInstallmentDetailForm'
import Button from '../../atoms/inputs/Button'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import { Moneyspace } from '../../../types/Moneyspace'
import useTransaction from '../../../hooks/useTransaction'
import useRouter from '../../../hooks/useRouter'
import useMoneyspace from '../../../hooks/useMoneyspace'
import { RelatedUser } from '../../../types/User'
import TransactionInstallmentDialog from './TransactionInstallmentDialog'
import RadioControl from '../../atoms/inputs/RadioControl'

type TransactionInstallmentFormProps = {
  moneyspace: Moneyspace
  transaction: Transaction
}

export type TransactionInstallmentFormParams = {
  publishedAt: string | null
  closingDate: string | null
  paymentDate: string | null
  deliveryDateType: 1 | 2
  deliveryDateFrom?: string | null
  deliveryDateTo?: string | null
  pic?: string | null
  picSub?: string | null
  details: TransactionInstallmentDetail[]
}

function findContracteeMembers(members: RelatedUser[], moneyspace?: Moneyspace) {
  if (moneyspace === undefined || moneyspace.contractee == null) {
    return []
  }
  const contracteeId = moneyspace.contractee.id
  return members
    .filter((member) => member.companyId === contracteeId)
    .map((member) => ({
      value: member.id,
      label: member.name,
    }))
}

function enableSubmit(transaction: Transaction, amounts: number[]) {
  let total = 0
  for (let index = 0; transaction.details.length > index; index += 1) {
    const detail = transaction.details[index]
    const amount = amounts[index]
    if (detail.amount < amount) {
      return false
    }
    total += amount
  }
  return total > 0
}

export default function TransactionInstallmentForm({ moneyspace, transaction }: TransactionInstallmentFormProps) {
  const { isFixed } = useTransaction()
  const { transaction: transactionPage } = useRouter()
  const { findMembers } = useMoneyspace()
  const members = findMembers(transaction?.moneyspace?.id ?? '')
  const contracteeMembers = findContracteeMembers(members, moneyspace)
  const [selectedPic, setSelectedPic] = useState<string | null | undefined>(transaction?.pic?.id)
  const [selectedPicSub, setSelectedPicSub] = useState<string | null | undefined>(transaction?.picSub?.id)
  const yup = useYup()
  const rules = useValidationRule()
  const [installmentParams, setInstallmentParams] = useState<TransactionInstallmentFormParams | undefined>()
  const [deliveryDateType, setDeliveryDateType] = useState<number>(transaction.deliveryDateTo ? 2 : 1)

  const schema = yup.object().shape({
    publishedAt: rules.publishedAt.required(),
    note: rules.note,
    paymentDate: rules.paymentDate.required().test({
      name: 'overFixedDate',
      message: '承認期限が過ぎているため、次回の支払日に変更してください',
      test() {
        const { parent } = this as { parent: TransactionInstallmentFormParams }
        const { closingDate } = parent
        if (moneyspace) {
          return !(moneyspace.aggregateTransactions && isFixed(moneyspace, closingDate))
        }
        return true
      },
    }),
    closingDate: rules.closingDate
      .required()
      .test(
        'overFixedDate',
        '承認期限が過ぎているため、次回の締め日に変更してください',
        (item: Date | null | undefined) => {
          if (moneyspace) {
            return !(moneyspace.aggregateTransactions && isFixed(moneyspace, item))
          }
          return true
        }
      ),
    deliveryDateFrom: moneyspace?.detailPropConfig?.requiredDeliveryDate
      ? rules.deliveryDateFrom.required()
      : rules.deliveryDateFrom,
    deliveryDateTo: moneyspace?.detailPropConfig?.requiredDeliveryDate
      ? rules.deliveryDateTo.required()
      : rules.deliveryDateTo,
    pic: moneyspace?.detailPropConfig?.requiredPic ? rules.pic.required() : rules.pic,
    details: rules.installmentDetails,
  })
  const form = useForm<TransactionInstallmentFormParams>({
    resolver: yupResolver(schema),
    defaultValues: {
      publishedAt: transaction?.publishedAt,
      closingDate: transaction?.closingDate,
      paymentDate: transaction?.paymentDate,
      deliveryDateType: transaction.deliveryDateTo ? 2 : 1,
      deliveryDateFrom: transaction?.deliveryDateFrom,
      deliveryDateTo: transaction?.deliveryDateTo,
      pic: transaction?.pic?.id ?? '',
      picSub: transaction?.picSub?.id ?? '',
      details: transaction?.details.map((detail) => ({
        id: detail.id,
        amount: detail.amount,
      })),
    },
    mode: 'onChange',
  })
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = form
  const [amounts, setAmounts] = useState<number[]>(transaction.details.map((detail) => detail.amount))

  useEffect(() => {
    watch((value, { name }) => {
      if (name) {
        if (name === 'pic') {
          setSelectedPic(value.pic)
        }
        if (name === 'picSub') {
          setSelectedPicSub(value.picSub)
        }
        if (name.startsWith('details') && value.details) {
          setAmounts(value.details.map((detail) => detail?.amount ?? 0))
        }
        const type = Number(value.deliveryDateType)
        if (name === 'deliveryDateType' && value.deliveryDateType) {
          setDeliveryDateType(type)
          if (type === 1) {
            setValue('deliveryDateTo', value.deliveryDateFrom)
          }
          if (type === 2) {
            setValue('deliveryDateTo', null)
          }
        }
        if (name === 'deliveryDateFrom' && value.deliveryDateFrom && type === 1) {
          setValue('deliveryDateTo', value.deliveryDateFrom)
        }
      }
    })
  }, [contracteeMembers, watch, setValue])

  const handleCancel = useCallback(() => {
    transactionPage(transaction.id)
  }, [transaction, transactionPage])

  const handleNext = useCallback((params: TransactionInstallmentFormParams) => {
    setInstallmentParams(params)
  }, [])

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(handleNext)}>
        <FlexColumn>
          <FlexRow wrap>
            <FormItem label="発行日" required>
              <DateControl name="publishedAt" control={control} error={errors.publishedAt?.message} />
            </FormItem>
            <FormItem label="締め日" required>
              <DateControl name="closingDate" control={control} error={errors.closingDate?.message} />
            </FormItem>
            <FormItem label="支払期限" required>
              <DateControl name="paymentDate" control={control} error={errors.paymentDate?.message} />
            </FormItem>
          </FlexRow>
          <FlexRow wrap>
            <FormItem label="工事期間" required={moneyspace?.detailPropConfig?.requiredDeliveryDate}>
              <FlexRow pl={1}>
                <RadioControl
                  items={[
                    { label: '日付指定', value: 1 },
                    { label: '期間指定', value: 2 },
                  ]}
                  name="deliveryDateType"
                  control={control}
                  row
                  fontSize={16}
                />
              </FlexRow>
              <FlexRow wrap>
                <DateControl name="deliveryDateFrom" control={control} error={errors.deliveryDateFrom?.message} />
                {deliveryDateType === 2 && (
                  <DateControl name="deliveryDateTo" control={control} error={errors.deliveryDateTo?.message} />
                )}
              </FlexRow>
            </FormItem>
          </FlexRow>
          <FlexRow wrap>
            <FormItem label="担当者1" required={moneyspace?.detailPropConfig?.requiredPic}>
              <SelectControl
                name="pic"
                items={contracteeMembers.filter((member) => member.value !== selectedPicSub)}
                blank
                control={control}
                size="sm"
                error={errors.pic?.message}
              />
            </FormItem>
            <FormItem label="担当者2">
              <SelectControl
                name="picSub"
                items={contracteeMembers.filter((member) => member.value !== selectedPic)}
                blank
                control={control}
                size="sm"
              />
            </FormItem>
          </FlexRow>
          <FlexRow align="flex-start">
            <FormItem label="消費税の表示">
              <Label text={transaction?.isTaxIn ? '税込' : '税抜'} />
            </FormItem>
            <FlexColumn>
              <Alert
                severity="success"
                icon={false}
                label={transaction?.bearingFee ? '振込手数料：受注者負担' : '振込手数料：発注者負担'}
              />
            </FlexColumn>
          </FlexRow>
          <FlexRow>
            {transaction && <TransactionInstallmentDetailForm transaction={transaction} amounts={amounts} />}
          </FlexRow>
          <FlexRow justify="center">
            <Button onClick={handleCancel} color="secondary">
              キャンセル
            </Button>
            <Button type="submit" disabled={!enableSubmit(transaction, amounts)}>
              次へ
            </Button>
          </FlexRow>
        </FlexColumn>
      </Form>
      {installmentParams && (
        <TransactionInstallmentDialog
          transaction={transaction}
          params={installmentParams}
          onClose={() => setInstallmentParams(undefined)}
        />
      )}
    </FormProvider>
  )
}

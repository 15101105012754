import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type PasswordFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
}

export default function PasswordField({ register, error }: PasswordFieldProps) {
  return <TextField type="password" size="md" register={register} error={error?.message} />
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Dialog from '../../atoms/feedback/Dialog'
import { useApprovalFlowSettingDialog, useTransactionsApprovalFlowSettingDialog } from '../../../hooks/useDialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useProcessing from '../../../hooks/useProcessing'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { ApprovalFlowSelectors } from '../../../store/approval_flow/selectors'
import AvatarStepper from '../../molecules/display/AvatarStepper'
import { RadioCheckedIcon, RadioUncheckedIcon } from '../../atoms/display/Icons'
import { ApprovalFlowActions } from '../../../store/approval_flow'
import { SessionSelectors } from '../../../store/session/selectors'
import { TransactionSearchResultItem } from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import FormItem from '../../molecules/inputs/FormItem'
import DateControl from '../../atoms/inputs/DateControl'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useDay from '../../../hooks/useDay'
import usePath from '../../../hooks/usePath'

type TransactionsApprovalFlowSettingDialogProps = {
  transactions: TransactionSearchResultItem[]
}

type ApprovalFlowSettingParams = {
  closingDate: string | null
  paymentDate: string | null
}

export default function TransactionsApprovalFlowSettingDialog({
  transactions,
}: TransactionsApprovalFlowSettingDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.updateApprovalFlows.typePrefix)
  const { state, close } = useTransactionsApprovalFlowSettingDialog()
  const companyId = useSelector(SessionSelectors.companyId)
  const flows = useSelector(ApprovalFlowSelectors.flows)
  const [selectedFlow, setSelectedFlow] = useState<string>()
  const ApprovalFlowSettingDialog = useApprovalFlowSettingDialog()
  const dispatch = useDispatch()
  const { formatDate } = useDay()
  const { billingTransactions } = usePath()

  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    paymentDate: rules.paymentDate.required(),
    closingDate: rules.closingDate.required(),
  })
  const form = useForm<ApprovalFlowSettingParams>({
    defaultValues: {
      closingDate: null,
      paymentDate: null,
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(ApprovalFlowActions.fetchFlows({ companyId }))
    }
  }, [companyId, dispatch])
  const handleApply = useCallback(
    (params: ApprovalFlowSettingParams) => {
      startProcess()
      const transactionIds = transactions.map((transaction) => transaction.id)
      if (selectedFlow && params.closingDate && params.paymentDate) {
        dispatch(
          TransactionActions.updateApprovalFlows({
            transactionIds,
            flowId: selectedFlow,
            closingDate: formatDate(params.closingDate),
            paymentDate: formatDate(params.paymentDate),
            getRedirectPath: () => billingTransactions(),
            pageSize: 100,
          })
        )
      }
    },
    [startProcess, transactions, selectedFlow, dispatch, formatDate, billingTransactions]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="承認フローを設定する"
      onSubmit={form.handleSubmit(handleApply)}
      actions={
        <Button type="submit" disabled={selectedFlow === undefined || processing}>
          設定
        </Button>
      }
    >
      <FlexColumn space={4}>
        <FlexRow>
          <FormItem label="締め日" required>
            <DateControl name="closingDate" control={form.control} error={form.formState.errors.closingDate?.message} />
          </FormItem>
          <FormItem label="支払期限" required>
            <DateControl name="paymentDate" control={form.control} error={form.formState.errors.paymentDate?.message} />
          </FormItem>
        </FlexRow>
        <Label text="承認フローを選択してください" />
        <FlexColumn maxHeight="480px" scroll>
          <Label text="一括設定では、担当者を含む承認フローは選択できません" variant="caption" color="caution.main" />
          {flows.map((flow) => (
            <FlexColumn width="100%" space={0} scroll={false} key={flow.id}>
              <Label text={flow.name} />
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setSelectedFlow(flow.id)}
                disabled={flow.members.some((member) => member.user === null)}
              >
                <FlexRow width="100%" align="center">
                  {flow.id === selectedFlow ? <RadioCheckedIcon /> : <RadioUncheckedIcon />}
                  <AvatarStepper members={flow.members} />
                </FlexRow>
              </Button>
            </FlexColumn>
          ))}
          {flows.length < 10 && (
            <FlexRow justify="flex-end">
              <Button variant="text" size="lg" onClick={() => ApprovalFlowSettingDialog.open()}>
                新しい承認フローを作成する
              </Button>
            </FlexRow>
          )}
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

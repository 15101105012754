import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import usePage from '../hooks/usePage'
import Label from '../components/atoms/display/Label'
import { FlexColumn } from '../components/atoms/layout/Flex'
import Pagination from '../components/atoms/navigation/Pagination'
import PaginationCountLabel from '../components/molecules/display/PaginationCountLabel'
import { SortDirection } from '../types/System'
import useRouter from '../hooks/useRouter'
import { TransactionSelectors } from '../store/transactions/selectors'
import TransactionSearchResultSortableTable from '../components/molecules/display/TransactionSearchResultSortableTable'
import { TransactionActions } from '../store/transactions'

export default function ReceivedTransactionsPage() {
  const { changeTitle } = usePage()
  const { receivedTransactions } = useRouter()
  const dispatch = useDispatch()
  const submittedTransactions = useSelector(TransactionSelectors.submittedTransactions)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const sortColumn = query.get('column') ?? undefined
  const sortDirection = (query.get('direction') as SortDirection) ?? undefined
  const page = Number(query.get('page') ?? 1)

  useEffect(() => {
    changeTitle('社内確認が必要な取引')
  }, [changeTitle])
  useEffect(() => {
    dispatch(TransactionActions.loadSubmittedTransactions({ page, sortColumn, sortDirection }))
  }, [dispatch, page, sortColumn, sortDirection])
  const handleSort = (name: string, direction: SortDirection) => {
    receivedTransactions(page, name, direction)
    window.scrollTo(0, 0)
  }
  const handlePage = (pageNumber: number) => {
    receivedTransactions(pageNumber, sortColumn, sortDirection)
    window.scrollTo(0, 0)
  }
  return (
    <FlexColumn>
      <Label text="社内で確認が必要な取引の一覧です。下記の取引を確認してください。" variant="subtitle" />
      {submittedTransactions.count === 0 ? (
        <Label text="社内確認が必要な取引はありません" />
      ) : (
        <>
          <PaginationCountLabel page={page} contents={submittedTransactions} />
          <TransactionSearchResultSortableTable
            transactions={submittedTransactions.results}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={handleSort}
          />
          <Pagination count={submittedTransactions.count} page={page} size={30} onChange={handlePage} />
        </>
      )}
    </FlexColumn>
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type DocumentItemOthersFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function DocumentItemOthersField({ register, error, disabled }: DocumentItemOthersFieldProps) {
  return (
    <TextField
      register={register}
      placeholder="資材名、管理番号"
      size="md"
      error={error?.message}
      readonly={disabled}
    />
  )
}

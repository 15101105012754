import { Component } from '../../types/react'
import { FlexColumn, FlexRow } from '../atoms/layout/Flex'
import SiteNavBar from '../organisms/SiteNavBar'
import SiteNavHeader from '../organisms/SiteNavHeader'
import { useDeviceType } from '../../hooks/useDeviceType'

type LoggedInLayoutProps = {
  children: Component
}

export default function LoggedInLayout({ children }: LoggedInLayoutProps) {
  const { pc } = useDeviceType()
  return (
    <FlexRow>
      <FlexColumn height="100vh" displayPrint={false}>
        <SiteNavBar />
      </FlexColumn>
      <FlexColumn ml={pc ? 40 : 0} displayPrint={false} />
      <FlexColumn px={2} pb={2} grow>
        <FlexRow displayPrint={false}>
          <SiteNavHeader />
        </FlexRow>
        <FlexColumn>{children}</FlexColumn>
      </FlexColumn>
    </FlexRow>
  )
}

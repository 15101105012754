import { ReactNode } from 'react'
import MuiBadge from '@mui/material/Badge'

type BadgeColor = 'primary' | 'secondary' | 'error'

type BadgeProps = {
  label?: string | number
  children: ReactNode
  color?: BadgeColor
  variant?: 'standard' | 'dot'
}

export default function Badge({ label, children, color, variant }: BadgeProps) {
  return (
    <MuiBadge badgeContent={label} color={color} overlap="circular" variant={variant}>
      {children}
    </MuiBadge>
  )
}

import { Control, FieldError } from 'react-hook-form'
import { Company } from '../../../types/Company'
import Combobox from '../../atoms/inputs/Combobox'

type ClientSelectProps = {
  clients: Company[]
  name: string
  control: Control
  error?: FieldError
}

export default function ClientSelect({ clients, name, control, error }: ClientSelectProps) {
  const items = clients
    .filter((client) => client.isAccepted)
    .map((client) => ({
      value: client.uid,
      label: client.name,
      description: client.address,
    }))
  items.unshift({ value: 'no_client', label: '取引先を選択せずにマネースペースを作成する', description: undefined })
  return <Combobox items={items} name={name} control={control} error={error?.message} />
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FieldError } from 'react-hook-form'
import { usePasswordChangeDialog } from '../../../hooks/useDialog'
import { FlexColumn } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import PasswordField from '../../molecules/inputs/PasswordField'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import useForm from '../../../hooks/useForm'
import { SessionActions } from '../../../store/session'
import useProcessing from '../../../hooks/useProcessing'

type ChangePasswordParams = {
  currentPassword: string
  password: string
  passwordConfirm: string
}

export default function PasswordChangeDialog() {
  const { processing, startProcess } = useProcessing(SessionActions.changePassword.typePrefix)
  const { state, close } = usePasswordChangeDialog()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    currentPassword: rules.password.required(),
    password: rules.password.required(),
    passwordConfirm: rules.passwordConfirm.required(),
  })
  const { handleSubmit, register, errors } = useForm<ChangePasswordParams>(schema, {})
  const dispatch = useDispatch()
  const handleChangePassword = useCallback(
    (values: ChangePasswordParams) => {
      startProcess()
      dispatch(SessionActions.changePassword({ currentPassword: values.currentPassword, password: values.password }))
    },
    [dispatch, startProcess]
  )

  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="パスワード変更"
      actions={
        <Button type="submit" disabled={processing}>
          変更
        </Button>
      }
      onSubmit={handleSubmit(handleChangePassword)}
    >
      <FlexColumn>
        <FormItem label="現在のパスワード" required>
          <PasswordField register={register('currentPassword')} error={errors.currentPassword as FieldError} />
        </FormItem>
        <FormItem label="新しいパスワード" required>
          <PasswordField register={register('password')} error={errors.password as FieldError} />
        </FormItem>
        <FormItem label="新しいパスワード（確認用）" required>
          <PasswordField register={register('passwordConfirm')} error={errors.passwordConfirm as FieldError} />
        </FormItem>
      </FlexColumn>
    </Dialog>
  )
}

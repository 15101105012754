import Image from '../../atoms/display/Image'
import Link from '../../atoms/navigation/Link'
import ImageDialog from '../../atoms/feedback/ImageDialog'
import RelativeBox from '../../atoms/layout/RelativeBox'
import AbsoluteBox from '../../atoms/layout/AbsoluteBox'
import IconButton from '../../atoms/inputs/IconButton'
import { CloseIcon } from '../../atoms/display/Icons'
import { useDeviceType } from '../../../hooks/useDeviceType'

type FactoringDialogProps = {
  url?: string
  open: boolean
  onClose: () => void
}

export default function FactoringDialog({ url, open, onClose }: FactoringDialogProps) {
  const { pc } = useDeviceType()
  if (url) {
    return (
      <ImageDialog open={open} width={pc ? 960 : 310} onClose={onClose}>
        <RelativeBox>
          <Link href={url} onClick={onClose}>
            {pc ? (
              <Image url="/factoring_pc.png" alt="もっと便利に受け取れます" width={960} height={540} />
            ) : (
              <Image url="/factoring_sp.png" alt="もっと便利に受け取れます" width={310} height={552} />
            )}
          </Link>
          <AbsoluteBox top={2} right={2}>
            <IconButton label="閉じる" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </AbsoluteBox>
        </RelativeBox>
      </ImageDialog>
    )
  }
  return <></>
}

import { useDropzone } from 'react-dropzone'
import { useEffect } from 'react'
import MuiBox from '@mui/material/Box'
import { Component } from '../../../types/react'

type FileSelectProps = {
  accepts: string[]
  maxFiles?: number
  maxSize?: number
  children: Component
  onSelect: (_files: File[]) => void
  onActive?: (_active: boolean) => void
  onError?: () => void
}

export default function FileDropField({
  accepts,
  onSelect,
  onActive,
  maxFiles,
  maxSize,
  children,
  onError,
}: FileSelectProps) {
  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop: onSelect,
    maxFiles,
    accept: accepts,
    maxSize,
  })
  useEffect(() => {
    if (onActive) {
      onActive(isDragActive)
    }
  }, [isDragActive, onActive])
  useEffect(() => {
    fileRejections.forEach(() => {
      if (onError) {
        onError()
      }
    })
  }, [fileRejections, onError])

  return (
    <MuiBox {...getRootProps()} sx={{ width: '100%' }}>
      <input {...getInputProps()} />
      {children}
    </MuiBox>
  )
}

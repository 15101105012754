import { UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type TagNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: string
}

export default function TagNameField({ register, error }: TagNameFieldProps) {
  return <TextField register={register} placeholder="タグ名" size="sm" error={error} />
}

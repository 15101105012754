import MuiMenuItem from '@mui/material/MenuItem'
import MuiListItemIcon from '@mui/material/ListItemIcon'
import MuiListItemText from '@mui/material/ListItemText'
import { ReactElement, ReactNode } from 'react'
import MuiTypography from '@mui/material/Typography'
import MuiBox from '@mui/material/Box'

type MenuItemProps = {
  icon?: ReactElement
  label: string
  subLabel?: string
  right?: ReactNode
  onClick?: () => void
}

export default function MenuItem({ icon, label, subLabel, right, onClick }: MenuItemProps) {
  return (
    <MuiMenuItem onClick={onClick}>
      {icon && <MuiListItemIcon>{icon}</MuiListItemIcon>}
      <MuiListItemText>
        <MuiBox sx={{ display: 'flex', flexDirection: 'column' }}>
          {label}
          {subLabel && <MuiTypography variant="caption">{subLabel}</MuiTypography>}
        </MuiBox>
      </MuiListItemText>
      {right}
    </MuiMenuItem>
  )
}

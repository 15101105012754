import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Card from '../../atoms/surfaces/Card'
import Label from '../../atoms/display/Label'
import useConsts from '../../../hooks/useConsts'
import { InvoiceLessPatternB, InvoiceLessPatternC, Moneyspace } from '../../../types/Moneyspace'
import { BillingSearchResultItem } from '../../../types/transaction'

type BillingAmountViewProps = {
  moneyspace: Moneyspace
  billing: BillingSearchResultItem
  isFixed: boolean
}

export default function BillingAmountView({ moneyspace, billing, isFixed }: BillingAmountViewProps) {
  const { monthName } = useConsts()
  const fixDay = moneyspace.fixDay === 99 ? '末' : moneyspace.fixDay
  const taxLabel = billing.invoiceLessActionType === InvoiceLessPatternC ? '消費税相当額' : '消費税'

  return (
    <FlexColumn space={2}>
      <FlexRow width={360}>
        <Table>
          <TableRow>
            <TableCell align="center" color="header" size="xs">
              小計
            </TableCell>
            <TableCell align="right" size="xs">
              <Label text={billing.subtotal} format="amountNoStyle" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" color="header" size="xs">
              {taxLabel}
            </TableCell>
            <TableCell align="right" size="xs">
              {billing.invoiceLessActionType === InvoiceLessPatternB ? (
                <Label text="請求不可" />
              ) : (
                <Label text={billing.totalTax} format="amountNoStyle" />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" color="header" size="xs">
              協力会費
            </TableCell>
            <TableCell align="right" size="xs">
              <Label text={billing.totalMembershipFee} format="amountNoStyle" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center" color="header" size="xs">
              <FlexColumn>
                <Label text="合計金額" />
                {isFixed ? <Label text="確定" color="primary" /> : <Label text="未確定" color="caution.main" />}
              </FlexColumn>
            </TableCell>
            <TableCell align="right" size="xs">
              <Label text={billing.totalAmount} format="amountNoStyle" />
            </TableCell>
          </TableRow>
        </Table>
      </FlexRow>
      <FlexRow wrap width={360}>
        {moneyspace?.aggregateTransactions && moneyspace?.fixMonth && fixDay && (
          <Label
            text={`請求金額は承認期限（締め日の${monthName(moneyspace.fixMonth)}${fixDay}日）の翌日に自動確定します`}
            variant="caption"
          />
        )}
      </FlexRow>
      <FlexRow width={360}>
        <Card size="full">
          <FlexColumn scroll>
            <Label text={`※${taxLabel}内訳`} />
            <FlexColumn>
              <FlexRow scroll={false}>
                <FlexRow scroll={false} align="center">
                  <FlexRow justify="flex-end" width={56}>
                    <Label text="10%対象" variant="caption" />
                  </FlexRow>
                  <FlexRow justify="flex-end" width={120}>
                    {billing.invoiceLessActionType === InvoiceLessPatternB ? (
                      <Label text="-" />
                    ) : (
                      <Label text={billing.subtotal10} format="amountNoStyle" />
                    )}
                  </FlexRow>
                </FlexRow>
                <FlexRow scroll={false} align="center">
                  <FlexRow justify="flex-end" width={40}>
                    <Label text={taxLabel} variant="caption" />
                  </FlexRow>
                  <FlexRow justify="flex-end" width={80}>
                    {billing.invoiceLessActionType === InvoiceLessPatternB ? (
                      <Label text="請求不可" />
                    ) : (
                      <Label text={billing.totalTax10} format="amountNoStyle" />
                    )}
                  </FlexRow>
                </FlexRow>
              </FlexRow>
              <FlexRow scroll={false}>
                <FlexRow scroll={false} align="center">
                  <FlexRow justify="flex-end" width={56}>
                    <Label text="8%対象" variant="caption" />
                  </FlexRow>
                  <FlexRow justify="flex-end" width={120}>
                    {billing.invoiceLessActionType === InvoiceLessPatternB ? (
                      <Label text="-" />
                    ) : (
                      <Label text={billing.subtotal8} format="amountNoStyle" />
                    )}
                  </FlexRow>
                </FlexRow>
                <FlexRow scroll={false} align="center">
                  <FlexRow justify="flex-end" width={40}>
                    <Label text={taxLabel} variant="caption" />
                  </FlexRow>
                  <FlexRow justify="flex-end" width={80}>
                    {billing.invoiceLessActionType === InvoiceLessPatternB ? (
                      <Label text="請求不可" />
                    ) : (
                      <Label text={billing.totalTax8} format="amountNoStyle" />
                    )}
                  </FlexRow>
                </FlexRow>
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        </Card>
      </FlexRow>
    </FlexColumn>
  )
}

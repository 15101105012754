import { Control, FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { CompanyAttributes } from '../../../types/Company'
import CompanyBasicForm from './CompanyBasicForm'
import BankAccountForm from './BankAccountForm'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import InvoiceLicenseNumberForm from './InvoiceLicenseNumberForm'
import FiscalPeriodSelect from '../../molecules/inputs/FiscalPeriodSelect'

type CompanyFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>
  control: Control
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValues: UseFormGetValues<any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>
  errors: FieldErrors<CompanyAttributes>
  uid?: string
  withInvoiceLicenseNumberForm?: boolean
  withFiscalPeriodSelect?: boolean
}

export default function CompanyForm({
  register,
  control,
  getValues,
  setValue,
  errors,
  uid,
  withInvoiceLicenseNumberForm,
  withFiscalPeriodSelect,
}: CompanyFormProps) {
  return (
    <FlexColumn align="center" space={2}>
      <FlexColumn>
        {uid && (
          <FlexRow align="center">
            <Label text="企業ID" variant="subtitle" />
            <Label text={uid} />
          </FlexRow>
        )}
        <FlexColumn space={0}>
          <Label text="基本情報" variant="subtitle" />
          <FlexColumn>
            <CompanyBasicForm register={register} errors={errors} getValues={getValues} setValue={setValue} />
            {withInvoiceLicenseNumberForm && (
              <InvoiceLicenseNumberForm register={register} errors={errors} getValues={getValues} />
            )}
            {withFiscalPeriodSelect && <FiscalPeriodSelect control={control} name="fiscalPeriod" />}
          </FlexColumn>
        </FlexColumn>
        <FlexColumn space={0}>
          <Label text="振込先口座情報" variant="subtitle" />
          <BankAccountForm register={register} control={control} errors={errors} />
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  )
}

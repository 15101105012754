import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { Severity } from '../../components/atoms/feedback/Snackbar'
import { SessionActions } from '../session'
import { DocumentsActions } from '../documents'
import { MoneyspacesActions } from '../moneyspaces'
import { TransactionActions } from '../transactions'
import { CompanyActions } from '../company'
import { createAsyncAction } from '../actions'
import { MaintenanceInfo } from '../../types/System'
import { ToolsActions } from '../tools'
import { RegisterActions } from '../register'
import { ChatActions } from '../chat'

type RequestResult = {
  status?: number
  message?: string
}

type Message = {
  text: string
  severity: Severity
}

export type SystemState = {
  pageTitle?: string
  pageSubTitle?: string
  requestingCount: number
  requestResult?: RequestResult
  message?: Message
  redirectTo?: string
  sidebar: boolean
  processing?: string
  maintenanceInfo?: MaintenanceInfo
  loadingMessage?: string
}

export const initialSystemState: SystemState = {
  pageTitle: undefined,
  pageSubTitle: undefined,
  requestingCount: 0,
  requestResult: undefined,
  message: undefined,
  redirectTo: undefined,
  sidebar: true,
  processing: undefined,
}

export const SystemActions = {
  loadMaintenanceInfo: createAsyncAction<
    void,
    {
      maintenanceInfo: MaintenanceInfo
    }
  >('loadMaintenanceInfo', async (params, { systemRepository }) => {
    const maintenanceInfo = await systemRepository.loadMaintenanceInfo()
    return { maintenanceInfo }
  }),
}

const systemSlice = createSlice({
  name: 'system',
  initialState: initialSystemState,
  reducers: {
    pageTitle: (
      state,
      action: PayloadAction<{
        title: string
        subTitle?: string
      }>
    ) => {
      state.pageTitle = action.payload.title
      state.pageSubTitle = action.payload.subTitle
    },
    beginRequest: (state, action: PayloadAction<{ silent?: boolean }>) => {
      if (!action.payload.silent) {
        state.requestingCount += 1
      }
    },
    endRequest: (
      state,
      action: PayloadAction<
        | {
            error?: AxiosError
            silent?: boolean
          }
        | undefined
      >
    ) => {
      const error = action.payload?.error
      if (!action.payload?.silent) {
        state.requestingCount -= 1
      }
      if (error && error.response) {
        const { status } = error.response
        state.requestResult = { status }
        if (status >= 500) {
          state.redirectTo = '/error/500'
        } else if (status >= 400) {
          const data = error.response.data as { detail: string }
          if (data && data.detail) {
            state.message = { text: data.detail, severity: 'error' }
          } else {
            state.message = { text: 'エラーが発生しました', severity: 'error' }
          }
        }
      } else {
        state.requestResult = undefined
      }
    },
    clearError: (state) => {
      state.requestResult = undefined
    },
    clearMessage: (state) => {
      state.message = undefined
    },
    clearRedirectTo: (state) => {
      state.redirectTo = undefined
    },
    openSidebar: (state) => {
      state.sidebar = true
    },
    closeSidebar: (state) => {
      state.sidebar = false
    },
    setProcessingTarget: (state, action: PayloadAction<{ actionName: string }>) => {
      state.processing = action.payload.actionName
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(SystemActions.loadMaintenanceInfo.fulfilled, (state, action) => {
        state.maintenanceInfo = action.payload.maintenanceInfo
      })
      .addCase(RegisterActions.register.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(RegisterActions.invite.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(SessionActions.register.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(SessionActions.register.rejected, (state, action) => {
        const response = action.payload as { status?: number; data?: { companyUid?: string[] } }
        if (response.status === 400) {
          const companyUid = response.data?.companyUid
          if (companyUid && companyUid.length > 0 && companyUid[0] === 'Company uid has already used.') {
            state.message = { text: 'この企業IDは既に使用されています', severity: 'error' }
          }
        }
      })
      .addCase(SessionActions.login.fulfilled, (state) => {
        state.message = { text: 'ログインしました', severity: 'success' }
      })
      .addCase(SessionActions.login.rejected, (state) => {
        state.message = { text: 'ログインできませんでした', severity: 'warning' }
      })
      .addCase(SessionActions.logout.fulfilled, (state) => {
        state.message = { text: 'ログアウトしました', severity: 'success' }
      })
      .addCase(SessionActions.updateMe.fulfilled, (state) => {
        state.message = { text: '個人設定を更新しました', severity: 'success' }
      })
      .addCase(SessionActions.changePassword.fulfilled, (state) => {
        state.message = { text: 'パスワードを変更しました', severity: 'success' }
      })
      .addCase(SessionActions.changeEmail.fulfilled, (state) => {
        state.message = { text: '入力したメールアドレス宛に確認メールを送信しました', severity: 'success' }
      })
      .addCase(SessionActions.changeEmail.rejected, (state) => {
        state.message = { text: '登録済みのメールアドレスです', severity: 'warning' }
      })
      .addCase(SessionActions.uploadUserImage.fulfilled, (state) => {
        state.message = { text: 'プロフィール画像をアップロードしました', severity: 'success' }
      })
      .addCase(SessionActions.resetPassword.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(SessionActions.updateNotificationSetting.fulfilled, (state) => {
        state.message = { text: '通知設定を更新しました', severity: 'success' }
      })
      .addCase(MoneyspacesActions.createMoneyspaceFolder.fulfilled, (state, action) => {
        state.message = {
          text: `フォルダ「${action.payload.folderName}」を作成しました`,
          severity: 'success',
        }
      })
      .addCase(MoneyspacesActions.saveMoneyspaceFolder.fulfilled, (state, action) => {
        state.message = {
          text: `フォルダ「${action.payload.folderName}」を更新しました`,
          severity: 'success',
        }
      })
      .addCase(MoneyspacesActions.deleteMoneyspaceFolder.fulfilled, (state) => {
        state.message = {
          text: `フォルダを削除しました`,
          severity: 'success',
        }
      })
      .addCase(MoneyspacesActions.createMoneyspace.fulfilled, (state, action) => {
        state.message = {
          text: `マネースペース「${action.payload.moneyspace.name}」を作成しました`,
          severity: 'success',
        }
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(MoneyspacesActions.saveMoneyspace.fulfilled, (state, action) => {
        state.message = {
          text: `マネースペース「${action.payload.moneyspace.name}」を保存しました`,
          severity: 'success',
        }
      })
      .addCase(MoneyspacesActions.deleteMoneyspace.fulfilled, (state, action) => {
        state.message = {
          text: `マネースペース「${action.payload.moneyspace.name}」を削除しました`,
          severity: 'success',
        }
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(MoneyspacesActions.inviteMoneyspace.fulfilled, (state, action) => {
        state.message = {
          text: `マネースペース「${action.payload.moneyspace.name}」に${action.payload.companyDomain}を招待しました`,
          severity: 'success',
        }
      })
      .addCase(DocumentsActions.fetchDocument.rejected, (state, action) => {
        const response = action.payload as { status?: number; data?: { companyUid?: string[] } }
        if (response.status === 403) {
          state.message = { text: 'この書類は閲覧できません', severity: 'error' }
        }
      })
      .addCase(DocumentsActions.createDocument.fulfilled, (state, action) => {
        state.message = { text: '書類を作成しました', severity: 'success' }
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(DocumentsActions.saveDocument.fulfilled, (state, action) => {
        state.message = { text: '書類を保存しました', severity: 'success' }
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(DocumentsActions.deleteDocument.fulfilled, (state) => {
        state.message = { text: '書類を削除しました', severity: 'success' }
      })
      .addCase(DocumentsActions.approveDocument.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(TransactionActions.createTransaction.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(TransactionActions.deleteTransaction.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(TransactionActions.saveTransaction.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(TransactionActions.copyTransaction.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(TransactionActions.deleteContract.fulfilled, (state, action) => {
        state.message = { text: '取引を削除しました', severity: 'success' }
        state.redirectTo = action.payload.redirectTo
      })
      .addCase(TransactionActions.applyTransaction.fulfilled, (state, action) => {
        state.redirectTo = action.payload.redirectTo
        if (action.payload.approvalFlowState) {
          const firstMember = action.payload.approvalFlowState.members[0]
          state.message = { text: `${firstMember.user.name}に確認を依頼しました`, severity: 'success' }
        }
      })
      .addCase(TransactionActions.submitTransaction.fulfilled, (state) => {
        state.message = { text: '取引先に提出しました', severity: 'success' }
      })
      .addCase(TransactionActions.paidBilling.fulfilled, (state) => {
        state.message = {
          text: `請求書類を支払済にしました`,
          severity: 'success',
        }
      })
      .addCase(TransactionActions.bulkPaidBilling.fulfilled, (state, action) => {
        state.message = {
          text: `${action.meta.arg.billingIds.length}件の請求書類を支払済にしました`,
          severity: 'success',
        }
      })
      .addCase(TransactionActions.saveInstallment.fulfilled, (state, action) => {
        state.message = {
          text: `取引の分割請求設定を保存しました`,
          severity: 'success',
        }
        if (action.payload.redirectTo) {
          state.redirectTo = action.payload.redirectTo
        }
      })

      .addCase(CompanyActions.saveCompany.fulfilled, (state) => {
        state.message = { text: '企業情報を保存しました', severity: 'success' }
      })
      .addCase(CompanyActions.createUser.fulfilled, (state) => {
        state.message = { text: 'ユーザーを作成しました', severity: 'success' }
      })
      .addCase(CompanyActions.updateUser.fulfilled, (state) => {
        state.message = { text: 'ユーザー情報を保存しました', severity: 'success' }
      })
      .addCase(CompanyActions.deleteUser.fulfilled, (state) => {
        state.message = { text: 'ユーザーを削除しました', severity: 'success' }
      })
      .addCase(CompanyActions.createClient.fulfilled, (state) => {
        state.message = { text: '取引先を招待しました', severity: 'success' }
      })
      .addCase(CompanyActions.inviteUser.fulfilled, (state) => {
        state.message = { text: '取引先を招待しました', severity: 'success' }
      })
      .addCase(CompanyActions.deleteClient.fulfilled, (state) => {
        state.message = { text: '取引先を削除しました', severity: 'success' }
      })
      .addCase(CompanyActions.saveMembershipFee.fulfilled, (state) => {
        state.message = { text: '協力会費の設定を保存しました', severity: 'success' }
      })
      .addCase(CompanyActions.saveAggregateTransaction.fulfilled, (state) => {
        state.message = { text: '請求書類の承認期限を保存しました', severity: 'success' }
      })
      .addCase(CompanyActions.saveTransactionConfig.fulfilled, (state) => {
        state.message = { text: '取引項目の設定を保存しました', severity: 'success' }
      })
      .addCase(CompanyActions.saveInvoice.fulfilled, (state) => {
        state.message = { text: 'インボイス対応の設定を保存しました', severity: 'success' }
      })
      .addCase(CompanyActions.saveCsvImportSetting.fulfilled, (state) => {
        state.message = { text: 'インポート設定を保存しました', severity: 'success' }
      })
      .addCase(ToolsActions.importDocuments.pending, (state) => {
        state.loadingMessage =
          'インポート中です。この処理には時間がかかる場合があります。画面を操作せずにそのままお待ちください。'
      })
      .addCase(ToolsActions.importDocuments.fulfilled, (state) => {
        state.message = { text: 'インポートが完了しました。作成した取引をご確認ください。', severity: 'success' }
        state.loadingMessage = undefined
      })
      .addCase(ToolsActions.importDocuments.rejected, (state) => {
        state.loadingMessage = undefined
      })
      .addCase(ToolsActions.importTransactions.pending, (state) => {
        state.loadingMessage =
          'インポート中です。この処理には時間がかかる場合があります。画面を操作せずにそのままお待ちください。'
      })
      .addCase(ToolsActions.importTransactions.fulfilled, (state) => {
        state.message = { text: 'インポートが完了しました。作成した取引をご確認ください。', severity: 'success' }
        state.loadingMessage = undefined
      })
      .addCase(ToolsActions.importTransactions.rejected, (state) => {
        state.loadingMessage = undefined
      })
      .addCase(ToolsActions.ocrDocument.pending, (state) => {
        state.loadingMessage =
          '読み込み中です。この処理には時間がかかる場合があります。画面を操作せずにそのままお待ちください。'
      })
      .addCase(ToolsActions.ocrDocument.fulfilled, (state, action) => {
        state.loadingMessage = undefined
        if (action.payload.transactionDetails.length === 0) {
          state.message = { text: '書類を読み込めません', severity: 'error' }
        } else {
          state.message = { text: '書類の読み込みが完了しました', severity: 'success' }
        }
      })
      .addCase(ToolsActions.ocrDocument.rejected, (state) => {
        state.loadingMessage = undefined
      })
      .addCase(ChatActions.postMessages.fulfilled, (state) => {
        state.message = { text: 'メッセージを送信しました', severity: 'success' }
      })
      .addCase(ChatActions.postReservedMessage.fulfilled, (state, action) => {
        if (action.payload.repeat) {
          state.message = { text: 'メッセージの繰り返し送信を登録しました', severity: 'success' }
        } else {
          state.message = { text: 'メッセージ送信を予約しました', severity: 'success' }
        }
      })
      .addCase(ChatActions.updateReservedMessage.fulfilled, (state, action) => {
        if (action.payload.repeat) {
          state.message = { text: 'メッセージの繰り返し送信を更新しました', severity: 'success' }
        } else {
          state.message = { text: 'メッセージの予約送信を更新しました', severity: 'success' }
        }
      })
      .addCase(ChatActions.deleteReservedMessage.fulfilled, (state) => {
        state.message = { text: 'メッセージの予約送信を削除しました', severity: 'success' }
      })
      .addMatcher(
        (action: { type: string }) => action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected'),
        (state, action: { type: string }) => {
          const values = action.type.split('/')
          if (state.processing === values[0]) {
            state.processing = undefined
          }
        }
      )
  },
})

export default systemSlice

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import { useTransactionReleaseDialog } from '../../../hooks/useDialog'
import useProcessing from '../../../hooks/useProcessing'
import { TransactionActions } from '../../../store/transactions'

type TransactionReleaseDialogProps = {
  moneyspaceId: string
  transactionId: string
}

export default function TransactionReleaseDialog({ moneyspaceId, transactionId }: TransactionReleaseDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.releaseTransaction.typePrefix)
  const { close } = useTransactionReleaseDialog()
  const dispatch = useDispatch()
  const handleRelease = useCallback(() => {
    startProcess()
    dispatch(
      TransactionActions.releaseTransaction({
        moneyspaceId,
        transactionId,
      })
    )
  }, [dispatch, moneyspaceId, startProcess, transactionId])
  return (
    <Dialog
      open
      onClose={close}
      title="おまとめ解除"
      actions={
        <Button color="caution" onClick={handleRelease} disabled={processing}>
          解除
        </Button>
      }
    >
      <FlexColumn space={4}>
        <FlexColumn>
          <Label text="おまとめ解除しますか？" />
        </FlexColumn>
      </FlexColumn>
    </Dialog>
  )
}

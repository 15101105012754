import ReactPanZoom from 'react-image-pan-zoom-rotate'

type ImageViewerProps = {
  src: string
  alt: string
}

export default function ImageViewer({ src, alt }: ImageViewerProps) {
  return <ReactPanZoom image={src} alt={alt} />
}

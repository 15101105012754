import { useCallback } from 'react'

type Color = {
  foreground?: string
  background?: string
}

const white: Color = {
  foreground: '#000',
  background: '#fff',
}
const black: Color = {
  foreground: '#fff',
  background: '#000',
}
const header: Color = {
  foreground: '#000',
  background: '#eee',
}
const diffPlusLight: Color = {
  foreground: '#000',
  background: '#E6FFEC',
}
const diffPlus: Color = {
  foreground: '#000',
  background: '#AAF3BC',
}
const diffMinusLight: Color = {
  foreground: '#000',
  background: '#FFEBE9',
}
const diffMinus: Color = {
  foreground: '#000',
  background: '#FFC1C0',
}

export type ColorName = 'white' | 'black' | 'header' | 'diffPlus' | 'diffPlusLight' | 'diffMinus' | 'diffMinusLight'

export default function useColor() {
  const nameToColor = useCallback((name: ColorName | undefined): Color => {
    switch (name) {
      case 'white':
        return white
      case 'black':
        return black
      case 'header':
        return header
      case 'diffPlus':
        return diffPlus
      case 'diffPlusLight':
        return diffPlusLight
      case 'diffMinus':
        return diffMinus
      case 'diffMinusLight':
        return diffMinusLight
      default:
        return {
          foreground: undefined,
          background: undefined,
        }
    }
  }, [])

  return {
    nameToColor,
  }
}

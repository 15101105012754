import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type EmailFieldProps = {
  value?: string
  readonly?: boolean
  register?: UseFormRegisterReturn
  error?: FieldError
}

export default function EmailField({ value, readonly, register, error }: EmailFieldProps) {
  return (
    <TextField
      value={value}
      readonly={readonly}
      placeholder="example@moment.jp"
      register={register}
      error={error?.message}
      size="md"
    />
  )
}

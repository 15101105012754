import { useSearchParams } from 'react-router-dom'
import { FlexColumn } from '../../components/atoms/layout/Flex'
import Label from '../../components/atoms/display/Label'
import Card from '../../components/atoms/surfaces/Card'
import Link from '../../components/atoms/navigation/Link'
import RegisterStepView from '../../components/molecules/navigation/RegisterStepView'
import usePath from '../../hooks/usePath'
import RegisterForm from '../../components/organisms/session/RegisterForm'

export default function RegisterPage() {
  const { login } = usePath()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') ?? undefined
  return (
    <FlexColumn align="center" mt={4} space={4}>
      <RegisterStepView step={0} />
      <Label text="アカウント作成" variant="title" />
      <FlexColumn>
        <Label text="アカウント作成は無料です。" />
        <Card size="lg">
          <FlexColumn pa={4}>
            <RegisterForm token={token} />
            <FlexColumn align="center">
              <Link href={login()}>
                <Label text="ログインはこちら" />
              </Link>
            </FlexColumn>
          </FlexColumn>
        </Card>
      </FlexColumn>
    </FlexColumn>
  )
}

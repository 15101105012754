import MuiDivider from '@mui/material/Divider'
import { ReactNode } from 'react'

type DividerProps = {
  vertical?: boolean
  children?: ReactNode
}

export default function Divider({ vertical, children }: DividerProps) {
  return (
    <MuiDivider orientation={vertical ? 'vertical' : 'horizontal'} textAlign="right" sx={{ alignItems: 'flex-start' }}>
      {children}
    </MuiDivider>
  )
}

import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import Dialog from '../../atoms/feedback/Dialog'
import { useTransactionSearchConditionFormDialog } from '../../../hooks/useDialog'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import FormItem from '../../molecules/inputs/FormItem'
import TextField from '../../atoms/inputs/TextField'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useConsts from '../../../hooks/useConsts'
import useDay from '../../../hooks/useDay'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { TransactionActions } from '../../../store/transactions'
import { TransactionPhase, TransactionStatus } from '../../../types/transaction'
import { TransactionSearchParams } from '../../../types/search'

type TransactionSearchConditionFormDialogProps = {
  id?: string
}

function PaymentDateView(params: { searchParams: TransactionSearchParams }) {
  const { searchParams } = params
  const { paymentDateFrom, paymentDateTo } = searchParams
  const { formatDate } = useDay()

  if (paymentDateFrom == null && paymentDateTo == null) {
    return <></>
  }
  return (
    <FlexRow align="center" wrap>
      <FlexRow width={120}>
        <Label text="支払期限" variant="subtitle" />
      </FlexRow>
      {paymentDateFrom && <Label text={formatDate(paymentDateFrom)} />}
      <Label text="〜" />
      {paymentDateTo && <Label text={formatDate(paymentDateTo)} />}
    </FlexRow>
  )
}

function ClosingDateView(params: { searchParams: TransactionSearchParams }) {
  const { searchParams } = params
  const { closingDateFrom, closingDateTo } = searchParams
  const { formatDate } = useDay()

  if (closingDateFrom == null && closingDateTo == null) {
    return <></>
  }
  return (
    <FlexRow align="center" wrap>
      <FlexRow width={120}>
        <Label text="締め日" variant="subtitle" />
      </FlexRow>
      {closingDateFrom && <Label text={formatDate(closingDateFrom)} />}
      <Label text="〜" />
      {closingDateTo && <Label text={formatDate(closingDateTo)} />}
    </FlexRow>
  )
}

function AmountView(params: { searchParams: TransactionSearchParams }) {
  const { searchParams } = params
  const { amountMin, amountMax } = searchParams

  if (amountMin == null && amountMax == null) {
    return <></>
  }
  return (
    <FlexRow align="center" wrap>
      <FlexRow width={120}>
        <Label text="金額" variant="subtitle" />
      </FlexRow>
      {amountMin && (
        <FlexRow space={0}>
          <Label text={amountMin} format="amountNoStyle" />
          <Label text="円" />
        </FlexRow>
      )}
      <Label text="〜" />
      {amountMax && (
        <FlexRow space={0}>
          <Label text={amountMax} format="amountNoStyle" />
          <Label text="円" />
        </FlexRow>
      )}
    </FlexRow>
  )
}

type TransactionSearchConditionSaveParams = {
  name: string
}

function phases(searchParams: TransactionSearchParams): TransactionPhase[] {
  const list: TransactionPhase[] = []
  if (searchParams.phases.phase1) {
    list.push(1)
  }
  if (searchParams.phases.phase2) {
    list.push(2)
  }
  if (searchParams.phases.phase3) {
    list.push(3)
  }
  if (searchParams.phases.phase4) {
    list.push(4)
  }
  if (searchParams.phases.phase5) {
    list.push(5)
  }
  if (searchParams.phases.phase6) {
    list.push(6)
  }
  if (searchParams.phases.phase7) {
    list.push(7)
  }
  return list
}

export default function TransactionSearchConditionFormDialog({ id }: TransactionSearchConditionFormDialogProps) {
  const { state, close } = useTransactionSearchConditionFormDialog()
  const searchParams = useSelector(TransactionSelectors.searchParams)
  const transactionSearchConditions = useSelector(TransactionSelectors.searchConditions)
  const searchCondition = transactionSearchConditions.find((item) => item.id === id)
  const params = searchCondition ? searchCondition.params : searchParams
  const { phaseName, transactionStatusName } = useConsts()
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    name: rules.documentSearchConditionName,
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TransactionSearchConditionSaveParams>({
    defaultValues: {
      name: searchCondition?.name ?? '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const handleSave = useCallback(
    (values: TransactionSearchConditionSaveParams) => {
      dispatch(
        TransactionActions.saveSearchCondition({
          id,
          name: values.name,
          params,
        })
      )
    },
    [dispatch, id, params]
  )
  const transactionPhases = phases(params)
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="お気に入り検索条件の保存"
      onSubmit={handleSubmit(handleSave)}
      actions={<>{id === undefined ? <Button type="submit">作成</Button> : <Button type="submit">保存</Button>}</>}
    >
      <FlexColumn>
        <FormItem label="タイトル" required>
          <TextField register={register('name')} error={errors.name?.message} />
        </FormItem>
        <Label text="検索条件" variant="title" />
        {params.keyword && (
          <FlexRow align="center" wrap>
            <FlexRow width={120}>
              <Label text="検索キーワード" variant="subtitle" />
            </FlexRow>
            <Label text={params.keyword} />
          </FlexRow>
        )}
        {transactionPhases.length > 0 && (
          <FlexRow align="center" wrap>
            <FlexRow width={120}>
              <Label text="フェーズ" variant="subtitle" />
            </FlexRow>
            {transactionPhases.map((phase) => (
              <Label text={phaseName(phase)} />
            ))}
          </FlexRow>
        )}
        {params.status !== undefined && (
          <FlexRow align="center" wrap>
            <FlexRow width={120}>
              <Label text="ステータス" variant="subtitle" />
            </FlexRow>
            {Number(params.status) === 0 ? (
              <Label text="全て" />
            ) : (
              <Label text={transactionStatusName(Number(params.status) as TransactionStatus)} />
            )}
          </FlexRow>
        )}
        {params.isFavorite && (
          <FlexRow align="center" wrap>
            <FlexRow width={120}>
              <Label text="お気に入りのみ" variant="subtitle" />
            </FlexRow>
            <Label text="オン" />
          </FlexRow>
        )}
        <AmountView searchParams={params} />
        <PaymentDateView searchParams={params} />
        <ClosingDateView searchParams={params} />
        <FlexRow align="center" wrap>
          <FlexRow width={120}>
            <Label text="検索対象" variant="subtitle" />
          </FlexRow>
          {Number(params.searchType) === 1 && <Label text="フェーズごとに表示" />}
          {Number(params.searchType) === 2 && <Label text="項目ごとに表示" />}
        </FlexRow>
      </FlexColumn>
    </Dialog>
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { useContractDeleteDialog } from '../../../hooks/useDialog'
import { Contract } from '../../../types/Document'
import { TransactionActions } from '../../../store/transactions'
import Label from '../../atoms/display/Label'
import usePath from '../../../hooks/usePath'
import useProcessing from '../../../hooks/useProcessing'

type ContractDeleteDialogProps = {
  contract: Contract
  moneyspaceId: string
}

export default function ContractDeleteDialog({ contract, moneyspaceId }: ContractDeleteDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.deleteContract.typePrefix)
  const { state, close } = useContractDeleteDialog()
  const { moneyspace } = usePath()
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    startProcess()
    dispatch(
      TransactionActions.deleteContract({
        id: contract.id,
        getRedirectPath: () => moneyspace(moneyspaceId),
      })
    )
  }, [contract.id, dispatch, moneyspace, moneyspaceId, startProcess])
  return (
    <Dialog
      open={state !== undefined}
      title="取引を削除する"
      actions={
        <Button color="caution" onClick={handleDelete} disabled={processing}>
          削除
        </Button>
      }
      onClose={close}
    >
      <FlexColumn>
        <FlexRow>
          <Label text="取引名" />
          <Label text={contract.name} />
        </FlexRow>
      </FlexColumn>
    </Dialog>
  )
}

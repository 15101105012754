import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import Button from '../../../components/atoms/inputs/Button'
import useRouter from '../../../hooks/useRouter'

export default function RegisterContracteeWellcomePage() {
  const { root } = useRouter()
  return (
    <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
      <FlexColumn align="center">
        <Label text="アカウント登録" variant="title" />
        <Label text="登録完了" color="primary" />
        <Label text="ありがとうございました" color="primary" />
      </FlexColumn>
      <FlexColumn align="center">
        <Button size="lg" onClick={root}>
          ようこそmonectへ
        </Button>
        <FlexRow justify="center">
          <DotPagination count={11} current={10} />
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}

import { useForm } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Form from '../../atoms/inputs/Form'
import CheckboxControl from '../../atoms/inputs/CheckboxControl'
import SelectControl from '../../atoms/inputs/SelectControl'
import Button from '../../atoms/inputs/Button'
import FormItem from '../../molecules/inputs/FormItem'
import { CompanySelectors } from '../../../store/company/selectors'
import { CompanyActions } from '../../../store/company'
import { SessionSelectors } from '../../../store/session/selectors'

type BillingSettingParams = {
  aggregateTransactions: boolean
  fixMonth: number | null
  fixDay: number | null
}

export default function CompanyTabItemBilling() {
  const company = useSelector(CompanySelectors.company)
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState<boolean>(company?.aggregateTransactions === false ?? true)
  const user = useSelector(SessionSelectors.user)

  const { control, handleSubmit, watch, setValue } = useForm<BillingSettingParams>({
    defaultValues: {
      aggregateTransactions: company?.aggregateTransactions ?? false,
      fixMonth: company?.fixMonth ?? 2,
      fixDay: company?.fixDay ?? null,
    },
    mode: 'onChange',
  })

  const days: { value: number; label: string }[] = []
  for (let day = 1; day < 28; day += 1) {
    days.push({
      value: day,
      label: `${day}日`,
    })
  }
  days.push({
    value: 99,
    label: '末日',
  })

  useEffect(() => {
    watch((value, { name }) => {
      if (name === 'aggregateTransactions') {
        if (value.aggregateTransactions) {
          setValue('fixMonth', 2)
          setValue('fixDay', 1)
        } else {
          setValue('fixMonth', null)
          setValue('fixDay', null)
        }
        setDisabled(!value.aggregateTransactions)
      }
    })
  }, [setValue, watch])

  const handleSave = useCallback(
    (params: BillingSettingParams) => {
      dispatch(
        CompanyActions.saveAggregateTransaction({
          aggregateTransactions: params.aggregateTransactions,
          fixMonth: params.fixMonth,
          fixDay: params.fixDay,
        })
      )
    },
    [dispatch]
  )

  return (
    <FlexColumn>
      <Label text="発注者としてマネースペースに参加する場合は設定してください" variant="subtitle" />
      <FlexColumn space={0}>
        <Label
          text="すでに作成済みのマネースペースには変更内容は反映されません。"
          variant="caption"
          color="caution.main"
        />
        <Label text="設定・変更後に新しくマネースペースを作成してください。" variant="caption" color="caution.main" />
      </FlexColumn>
      <Form onSubmit={handleSubmit(handleSave)}>
        <FlexColumn>
          <CheckboxControl
            name="aggregateTransactions"
            control={control}
            label="取引のおまとめ機能を使用する"
            disabled={user?.role === 3}
          />
          <FormItem label="取引の承認期限">
            <FlexRow align="center">
              <Label text="締め日の" />
              <SelectControl
                items={[
                  { value: 1, label: '当月' },
                  { value: 2, label: '翌月' },
                  { value: 3, label: '翌々月' },
                  { value: 4, label: '翌々々月' },
                ]}
                size="xs"
                name="fixMonth"
                control={control}
                disabled={disabled || user?.role === 3}
              />
              <SelectControl
                name="fixDay"
                items={days}
                control={control}
                size="xs"
                disabled={disabled || user?.role === 3}
              />
            </FlexRow>
          </FormItem>
          <Label text="承認期限の翌日に請求金額が確定します" variant="caption" />
          <Button type="submit" disabled={user?.role === 3}>
            保存
          </Button>
        </FlexColumn>
      </Form>
    </FlexColumn>
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '../../atoms/inputs/Button'
import Dialog from '../../atoms/feedback/Dialog'
import List from '../../atoms/display/List'
import ListItem from '../../atoms/display/ListItem'
import { MoneyspacesSelectors } from '../../../store/moneyspaces/selectors'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Checkbox from '../../atoms/inputs/Checkbox'

type Props = {
  onChange: (moneyspaceIds: string[]) => void
}

export default function MoneyspaceSelectDialog(props: Props) {
  const { onChange } = props
  const [open, setOpen] = useState(false)
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const [selectedMoneyspaces, setSelectedMoneyspaces] = useState<string[]>([])

  useEffect(() => {
    const moneyspaceIds = moneyspaces.map((moneyspace) => moneyspace.id)
    setSelectedMoneyspaces(moneyspaceIds)
    onChange(moneyspaceIds)
  }, [moneyspaces, onChange])

  const handleClickMoneyspace = useCallback(
    (moneyspaceId: string) => {
      if (selectedMoneyspaces.includes(moneyspaceId)) {
        setSelectedMoneyspaces(selectedMoneyspaces.filter((id) => id !== moneyspaceId))
      } else {
        setSelectedMoneyspaces([...selectedMoneyspaces, moneyspaceId])
      }
    },
    [selectedMoneyspaces]
  )

  const handleApply = useCallback(() => {
    onChange(selectedMoneyspaces)
    setOpen(false)
  }, [onChange, selectedMoneyspaces])

  return (
    <>
      <Button onClick={() => setOpen(true)}>マネースペース</Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title="対象マネースペースの選択"
        actions={<Button onClick={handleApply}>適用</Button>}
      >
        <FlexColumn>
          <FlexRow>
            <Button
              height="small"
              onClick={() => setSelectedMoneyspaces(moneyspaces.map((moneyspace) => moneyspace.id))}
            >
              全選択
            </Button>
            <Button height="small" onClick={() => setSelectedMoneyspaces([])}>
              全解除
            </Button>
          </FlexRow>
          <FlexColumn maxHeight="50vh">
            <List>
              {moneyspaces.map((moneyspace) => (
                <ListItem key={moneyspace.id} onClick={() => handleClickMoneyspace(moneyspace.id)}>
                  <FlexRow align="center">
                    <Checkbox
                      checked={selectedMoneyspaces.includes(moneyspace.id)}
                      onChange={() => handleClickMoneyspace(moneyspace.id)}
                    />
                    {moneyspace.name}
                  </FlexRow>
                </ListItem>
              ))}
            </List>
          </FlexColumn>
        </FlexColumn>
      </Dialog>
    </>
  )
}

import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import { TransactionSearchParams } from '../../../types/search'
import TransactionSearchForm from './TransactionSearchForm'
import Form from '../../atoms/inputs/Form'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import PaginationCountLabel from '../../molecules/display/PaginationCountLabel'
import TransactionPhaseSearchResultTable from '../../molecules/display/TransactionPhaseSearchResultTable'
import Pagination from '../../atoms/navigation/Pagination'
import { TransactionSelectors } from '../../../store/transactions/selectors'
import { TransactionActions } from '../../../store/transactions'
import { useTransactionSearchConditionFormDialog } from '../../../hooks/useDialog'
import useDay from '../../../hooks/useDay'
import TransactionDetailSearchResultTable from '../../molecules/display/TransactionDetailSearchResultTable'
import useRouter from '../../../hooks/useRouter'

export default function SearchTabTransaction() {
  const dispatch = useDispatch()
  const { search: searchPage } = useRouter()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = Number(query.get('page') ?? 1)
  const searchParams = useSelector(TransactionSelectors.searchParams)
  const searchTransactionResult = useSelector(TransactionSelectors.searchTransactionResult)
  const searchTransactionDetailResult = useSelector(TransactionSelectors.searchTransactionDetailResult)
  const transactionSearchConditionFormDialog = useTransactionSearchConditionFormDialog()
  const { timestamp } = useDay()
  const form = useForm<TransactionSearchParams>({
    defaultValues: searchParams,
    mode: 'onChange',
  })

  useEffect(() => {
    dispatch(TransactionActions.searchTransaction({ page, searchParams }))
  }, [dispatch, searchParams, page])

  const handleSearch = useCallback(
    (params: TransactionSearchParams) => {
      dispatch(TransactionActions.saveSearchParams({ searchParams: params }))
      searchPage('transaction', 1)
    },
    [dispatch, searchPage]
  )

  const handleChangePage = useCallback(
    (newPage: number) => {
      searchPage('transaction', newPage)
      dispatch(TransactionActions.searchTransaction({ page: newPage, searchParams }))
      window.scrollTo(0, 0)
    },
    [dispatch, searchParams, searchPage]
  )

  const handleDownload = useCallback(
    (params: TransactionSearchParams) => {
      dispatch(
        TransactionActions.downloadTransactions({
          filename: `transaction_details_${timestamp()}.csv`,
          searchParams: params,
        })
      )
    },
    [dispatch, timestamp]
  )

  return (
    <FlexColumn>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(handleSearch)}>
          <TransactionSearchForm />
        </Form>
        <FlexRow align="center" id="search_results" justify="space-between" wrap>
          <FlexRow align="center" wrap>
            <Label text="検索結果" variant="subtitle" />
            {searchTransactionResult && <PaginationCountLabel page={page} contents={searchTransactionResult} />}
            {searchTransactionDetailResult && (
              <PaginationCountLabel page={page} contents={searchTransactionDetailResult} />
            )}
          </FlexRow>
          <FlexRow align="center" wrap>
            <Button variant="outlined" size="lg" onClick={() => transactionSearchConditionFormDialog.open()}>
              現在の検索条件を保存
            </Button>
            <Button variant="outlined" onClick={() => form.handleSubmit(handleDownload)()}>
              CSVダウンロード
            </Button>
            <Button variant="outlined" size="sm" onClick={() => window.print()}>
              印刷
            </Button>
          </FlexRow>
        </FlexRow>
      </FormProvider>
      {searchTransactionResult && (
        <>
          {searchTransactionResult.count === 0 ? (
            <FlexRow justify="center">
              <Label text="検索条件に一致する取引は見つかりませんでした" variant="subtitle" />
            </FlexRow>
          ) : (
            <>
              <TransactionPhaseSearchResultTable items={searchTransactionResult.results} />
              <Pagination count={searchTransactionResult.count} page={page} size={30} onChange={handleChangePage} />
            </>
          )}
        </>
      )}
      {searchTransactionDetailResult && (
        <>
          {searchTransactionDetailResult.count === 0 ? (
            <FlexRow justify="center">
              <Label text="検索条件に一致する取引は見つかりませんでした" variant="subtitle" />
            </FlexRow>
          ) : (
            <>
              <TransactionDetailSearchResultTable items={searchTransactionDetailResult.results} />
              <Pagination
                count={searchTransactionDetailResult.count}
                page={page}
                size={30}
                onChange={handleChangePage}
              />
            </>
          )}
        </>
      )}
    </FlexColumn>
  )
}

import Image from '../../atoms/display/Image'
import { AccountIcon } from '../../atoms/display/Icons'

type UserImageProps = {
  url?: string
}

export default function UserImage({ url }: UserImageProps) {
  return (
    <>
      {url ? (
        <Image url={url} alt="プロフィール画像" width={256} height={256} borderRadius={3} />
      ) : (
        <AccountIcon size={256} />
      )}
    </>
  )
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import useRouter from '../../../hooks/useRouter'
import useValidationRule from '../../../hooks/useValidationRule'
import useYup from '../../../hooks/useYup'
import Form from '../../../components/atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import InvoiceLicenseNumberForm from '../../../components/organisms/company/InvoiceLicenseNumberForm'
import { CompanySelectors } from '../../../store/company/selectors'
import FiscalPeriodSelect from '../../../components/molecules/inputs/FiscalPeriodSelect'
import { CompanyActions } from '../../../store/company'

type InvoiceLicenseParams = {
  name?: string
  representativeName?: string
  invoiceLicenseNumber?: string | null
  fiscalPeriod?: number
}

export default function RegisterContracteeInvoiceLicensePage() {
  const company = useSelector(CompanySelectors.company)
  const invoiceLicenseCompanyName = useSelector(CompanySelectors.invoiceLicenseCompanyName)
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    invoiceLicenseNumber: rules.invoiceLicenseNumber,
  })
  const form = useForm<InvoiceLicenseParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: company?.name,
      representativeName: company?.representativeName,
      invoiceLicenseNumber: company?.invoiceLicenseNumber?.substring(1),
      fiscalPeriod: company?.fiscalPeriod,
    },
  })
  const router = useRouter()
  const dispatch = useDispatch()

  const handleNext = useCallback(
    (params: InvoiceLicenseParams) => {
      if (company) {
        const invoiceLicenseNumber = params.invoiceLicenseNumber ? `T${params.invoiceLicenseNumber}` : null
        dispatch(
          CompanyActions.saveCompany({
            id: company.id,
            company: {
              ...company,
              invoiceLicenseNumber,
              fiscalPeriod: params.fiscalPeriod,
            },
          })
        )
      }
      router.registerContracteeBank()
    },
    [dispatch, router, company]
  )

  return (
    <>
      <Form onSubmit={form.handleSubmit(handleNext)}>
        <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
          <FlexColumn align="center">
            <Label text="企業情報" variant="title" />
            <FlexColumn width="360px">
              <InvoiceLicenseNumberForm
                register={form.register}
                errors={form.formState.errors}
                getValues={form.getValues}
              />
              <FiscalPeriodSelect control={form.control} name="fiscalPeriod" />
            </FlexColumn>
          </FlexColumn>
          <FlexColumn align="center">
            <Button variant="text" size="lg" onClick={router.registerContracteeBank}>
              この項目をスキップ
            </Button>
            <Button
              type="submit"
              size="lg"
              disabled={
                invoiceLicenseCompanyName !== company?.name && invoiceLicenseCompanyName !== company?.representativeName
              }
            >
              次へ
            </Button>
            <FlexRow justify="center">
              <DotPagination count={11} current={8} />
            </FlexRow>
          </FlexColumn>
        </FlexColumn>
      </Form>
    </>
  )
}

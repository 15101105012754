import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type ApprovalFlowNameFieldProps = {
  register: UseFormRegisterReturn
  error?: FieldError
}

export default function ApprovalFlowNameField({ register, error }: ApprovalFlowNameFieldProps) {
  return <TextField register={register} size="sm" error={error?.message} />
}

import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import DecorationBox from '../../atoms/display/DecorationBox'
import { FlexColumn } from '../../atoms/layout/Flex'
import SearchField from '../../molecules/inputs/SearchField'
import Checkbox from '../../atoms/inputs/Checkbox'
import { useDeviceType } from '../../../hooks/useDeviceType'
import { MoneyspacesSelectors } from '../../../store/moneyspaces/selectors'

type Props = {
  moneyspaceIds: string[]
  onChangeMoneyspaceIds: (moneyspaceIds: string[]) => void
}

export default function MoneyspaceSelect({ moneyspaceIds, onChangeMoneyspaceIds }: Props) {
  const { pc } = useDeviceType()
  const moneyspaces = useSelector(MoneyspacesSelectors.moneyspaces)
  const [searchKeyword, setSearchKeyword] = useState<string>()

  const handleCheck = useCallback(
    (msId: string) => {
      if (moneyspaceIds.includes(msId)) {
        onChangeMoneyspaceIds(moneyspaceIds.filter((id) => id !== msId))
      } else {
        onChangeMoneyspaceIds([...moneyspaceIds, msId])
      }
    },
    [moneyspaceIds, onChangeMoneyspaceIds]
  )

  const handleAllCheck = useCallback(
    (checked: boolean) => {
      if (checked) {
        onChangeMoneyspaceIds(moneyspaces.map((moneyspace) => moneyspace.id))
      } else {
        onChangeMoneyspaceIds([])
      }
    },
    [moneyspaces, onChangeMoneyspaceIds]
  )

  return (
    <DecorationBox type="Border" color="lightgray">
      <FlexColumn width={pc ? 360 : '100%'} pa={1}>
        <SearchField placeholder="マネースペースを検索" onChange={setSearchKeyword} />
        <Checkbox
          label="全選択"
          checked={moneyspaceIds.length === moneyspaces.length}
          indeterminate={moneyspaceIds.length > 0 && moneyspaceIds.length < moneyspaces.length}
          onChange={(checked) => handleAllCheck(checked)}
        />
        <FlexColumn height={pc ? 'calc(100vh - 64px - 224px)' : '240px'}>
          {moneyspaces
            .filter((moneyspace) => (searchKeyword ? moneyspace.name.includes(searchKeyword) : true))
            .map((moneyspace) => (
              <Checkbox
                label={moneyspace.name}
                onChange={() => handleCheck(moneyspace.id)}
                checked={moneyspaceIds.includes(moneyspace.id)}
              />
            ))}
        </FlexColumn>
      </FlexColumn>
    </DecorationBox>
  )
}

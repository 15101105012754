import { useEffect, useState } from 'react'
import TabView from '../components/atoms/navigation/TabView'
import usePage from '../hooks/usePage'
import OrderAggregationView from '../components/organisms/aggregation/OrderAggregationView'
import BillingAggregationView from '../components/organisms/aggregation/BillingAggregationView'

export default function AggregationPage() {
  const { changeTitle } = usePage()
  const [tab, setTab] = useState('order')

  useEffect(() => {
    changeTitle('集計')
  }, [changeTitle])

  return (
    <TabView
      value={tab}
      onChange={setTab}
      tabs={[
        {
          value: 'order',
          label: '発注',
          panel: <OrderAggregationView />,
        },
        {
          value: 'billing',
          label: '請求書',
          panel: <BillingAggregationView />,
        },
      ]}
    />
  )
}

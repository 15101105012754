import MuiChip from '@mui/material/Chip'

type ChipColor = 'primary' | 'secondary' | 'warning' | 'error'

type ChipProps = {
  label: string | number
  size?: 'small' | 'medium'
  color?: ChipColor
}

export default function Chip({ label, size, color }: ChipProps) {
  return <MuiChip label={label} color={color} size={size} />
}

import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import { Transaction } from '../../../types/transaction'
import useTransactionAmounts from '../../../hooks/useTransactionAmounts'

type TransactionInstallmentViewProps = {
  transaction: Transaction
}

export default function TransactionInstallmentView({ transaction }: TransactionInstallmentViewProps) {
  const { calcSimpleAmount, calcInstallmentAccumulated } = useTransactionAmounts()
  const billingAmount = calcSimpleAmount(transaction.details)
  const billedAmount = calcInstallmentAccumulated(transaction)

  return (
    <FlexColumn>
      <FlexRow>
        <Label text="分割請求" variant="subtitle" />
        <Label text={`第${transaction.times ?? 1}回`} variant="subtitle" />
      </FlexRow>
      <FlexColumn width={940}>
        <Table>
          <TableRow>
            <TableCell color="header" size="sm3">
              今回の請求額
            </TableCell>
            <TableCell size="sm" align="right">
              <Label text={billingAmount} format="amountWithUnit" />
            </TableCell>
            <TableCell color="header" size="sm3">
              合計金額（分割前）
            </TableCell>
            <TableCell size="sm" align="right">
              <Label text={transaction.installment?.amount ?? 0} format="amountWithUnit" />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell color="header" size="sm3">
              過去請求額（累計）
            </TableCell>
            <TableCell size="sm" align="right">
              <Label text={billedAmount} format="amountWithUnit" />
            </TableCell>
            <TableCell color="header" size="sm3">
              未請求分の金額
            </TableCell>
            <TableCell size="sm" align="right">
              {transaction.installment && (
                <Label text={transaction.installment.amount - billedAmount - billingAmount} format="amountWithUnit" />
              )}
            </TableCell>
          </TableRow>
        </Table>
      </FlexColumn>
    </FlexColumn>
  )
}

import MuiTypography from '@mui/material/Typography'

type LabelVariant = 'body' | 'caption' | 'title' | 'subtitle' | 'headline'
type LabelColor = 'primary' | 'secondary' | 'warning' | 'error' | 'caution' | 'white.main'

const typographyVariant = (variant: LabelVariant) => {
  switch (variant) {
    case 'body':
      return 'body1'
    case 'caption':
      return 'caption'
    case 'title':
      return 'h5'
    case 'subtitle':
      return 'subtitle1'
    case 'headline':
      return 'h4'
    default:
      return 'body1'
  }
}

type LabelEllipsisProps = {
  variant?: LabelVariant
  text: string | number
  width: string
  color?: LabelColor
}

export default function LabelEllipsis({ variant = 'body', text, width, color }: LabelEllipsisProps) {
  return (
    <MuiTypography
      variant={typographyVariant(variant)}
      color={color}
      sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', width, overflow: 'hidden', flexGrow: 1 }}
    >
      {text}
    </MuiTypography>
  )
}

import { ReactElement } from 'react'
import MuiTab from '@mui/material/Tab'
import MuiTabs from '@mui/material/Tabs'
import MuiBox from '@mui/material/Box'
import MuiTypography from '@mui/material/Typography'
import { Component } from '../../../types/react'

type Tab = {
  value: string
  icon?: ReactElement
  label?: string
  panel?: Component
}

type TabViewProps = {
  value: string
  tabs: Tab[]
  leftItem?: Component
  rightItem?: Component
  variant?: 'scrollable' | 'fullWidth'
  orientation?: 'horizontal' | 'vertical'
  onChange?: (_value: string) => void
}

export default function TabView({
  value,
  tabs,
  leftItem,
  rightItem,
  variant = 'scrollable',
  orientation = 'horizontal',
  onChange,
}: TabViewProps) {
  const selectedTab = tabs.find((tab) => tab.value === value)
  return (
    <MuiBox sx={{ display: 'flex', flexDirection: orientation === 'horizontal' ? 'column' : 'row' }}>
      <MuiBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
        <MuiBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {leftItem}
          <MuiTabs
            value={value}
            onChange={(event, newValue) => onChange && onChange(newValue as string)}
            variant={variant}
            orientation={orientation}
            scrollButtons="auto"
          >
            {tabs.map((tab) => (
              <MuiTab
                label={
                  <MuiBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                    {tab.icon && <MuiBox sx={{ mr: 1 }}>{tab.icon}</MuiBox>}
                    {tab.label && <MuiTypography variant="button">{tab.label}</MuiTypography>}
                  </MuiBox>
                }
                value={tab.value}
                iconPosition="start"
                key={tab.value}
              />
            ))}
          </MuiTabs>
        </MuiBox>
        {rightItem}
      </MuiBox>
      <MuiBox sx={{ m: 2 }}>{selectedTab && <MuiBox>{selectedTab.panel}</MuiBox>}</MuiBox>
    </MuiBox>
  )
}

import { Control, FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'
import { RelatedUser } from '../../../types/User'
import Select from '../../atoms/inputs/Select'

type DocumentItemPicNameFieldProps = {
  members: RelatedUser[]
  register?: UseFormRegisterReturn
  control?: Control
  name?: string
  error?: FieldError
  disabled?: boolean
}

export default function DocumentItemPicNameField({
  members,
  register,
  control,
  name,
  error,
  disabled,
}: DocumentItemPicNameFieldProps) {
  const items = members.map((member) => ({
    value: member.name,
    label: member.name,
  }))
  items.unshift({
    value: '',
    label: '選択なし',
  })
  return (
    <>
      {members.length > 0 ? (
        <Select items={items} control={control} name={name} size="sm" disabled={disabled} />
      ) : (
        <TextField register={register} placeholder="" size="xs" error={error?.message} readonly={disabled} />
      )}
    </>
  )
}

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import useValidationRule from '../../../hooks/useValidationRule'
import useYup from '../../../hooks/useYup'
import Form from '../../../components/atoms/inputs/Form'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import FormItem from '../../../components/molecules/inputs/FormItem'
import TextField from '../../../components/atoms/inputs/TextField'
import Alert from '../../../components/atoms/feedback/Alert'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import useRouter from '../../../hooks/useRouter'
import List from '../../../components/atoms/display/List'
import ListItem from '../../../components/atoms/display/ListItem'
import registerSlice, { RegisterActions } from '../../../store/register'
import { RegisterSelectors } from '../../../store/register/selectors'

type UidParams = {
  uid?: string
}

function companyUidVerifiedMessage(companyUiVerified?: boolean) {
  if (companyUiVerified === false) {
    return 'すでに登録されているIDは使用できません'
  }
  return undefined
}

export default function RegisterContracteeUidPage() {
  const { registerContracteeName } = useRouter()
  const companyUiVerified = useSelector(RegisterSelectors.companyUiVerified)
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    uid: rules.companyDomain.required(),
  })
  const form = useForm<UidParams>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = form
  const dispatch = useDispatch()

  useEffect(() => {
    watch((params) => {
      if (params.uid) {
        dispatch(RegisterActions.validateCompanyUid({ uid: params.uid }))
      }
    })
  }, [dispatch, watch])

  const handleNext = useCallback(
    (params: UidParams) => {
      dispatch(
        registerSlice.actions.setContractee({
          uid: params.uid,
        })
      )
      registerContracteeName()
    },
    [dispatch, registerContracteeName]
  )
  return (
    <Form onSubmit={handleSubmit(handleNext)}>
      <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
        <FlexColumn align="center">
          <Label text="アカウント登録" variant="title" />
          <FormItem label="あなたの企業IDを入力">
            <TextField
              register={register('uid')}
              error={errors.uid?.message || companyUidVerifiedMessage(companyUiVerified)}
              size="md"
            />
          </FormItem>
          <FlexColumn space={0}>
            <Label text="企業IDに利用できる文字" />
            <FlexColumn ml={2}>
              <List>
                <ListItem>
                  <Label text="半角英大文字、小文字、半角数字" variant="caption" />
                </ListItem>
                <ListItem>
                  <Label text="半角記号 ,.-_!@:*()+~&" variant="caption" />
                </ListItem>
              </List>
            </FlexColumn>
          </FlexColumn>
          <FlexColumn width="360px">
            <Alert severity="warning" label="企業IDはログイン時に必要な貴社のIDです" />
            <Alert severity="warning">
              <FlexRow>企業名をローマ字にしたもの等、企業に関連したIDでの設定をおすすめします</FlexRow>
              <FlexRow>例 マネクト左官 → monectsakan</FlexRow>
            </Alert>
            <Alert severity="warning" label="企業IDは登録後に変更できません" />
          </FlexColumn>
        </FlexColumn>
        <FlexColumn align="center">
          <Button
            type="submit"
            size="lg"
            disabled={errors.uid !== undefined || companyUidVerifiedMessage(companyUiVerified) !== undefined}
          >
            次へ
          </Button>
          <FlexRow justify="center">
            <DotPagination count={11} current={1} />
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Form>
  )
}

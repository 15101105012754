import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import Label from '../../atoms/display/Label'
import UserRoleLabel from '../../molecules/display/UserRoleLabel'
import IconButton from '../../atoms/inputs/IconButton'
import { DeleteIcon, EditIcon } from '../../atoms/display/Icons'
import { User } from '../../../types/User'
import { useUserDeleteDialog, useUserEditDialog } from '../../../hooks/useDialog'
import usePermission from '../../../hooks/usePermission'
import { SessionSelectors } from '../../../store/session/selectors'
import Avatar from '../../atoms/display/Avatar'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'

type UserTableProps = {
  users: User[]
}

export default function UserTable({ users }: UserTableProps) {
  const { userPermissions } = usePermission()
  const sessionUser = useSelector(SessionSelectors.user)
  const userEditDialog = useUserEditDialog()
  const userDeleteDialog = useUserDeleteDialog()
  const handleEdit = useCallback(
    (user: User) => {
      userEditDialog.open(user)
    },
    [userEditDialog]
  )
  const handleDelete = useCallback(
    (user: User) => {
      userDeleteDialog.open(user)
    },
    [userDeleteDialog]
  )
  return (
    <FlexColumn space={0}>
      <FlexRow justify="flex-end">
        <Label
          text="オーナー権限を持つユーザーを削除する場合は、権限をリーダー以下に変更してから削除してください"
          variant="caption"
        />
      </FlexRow>
      <Table>
        {users.map((user) => (
          <TableRow key={user.id}>
            <TableCell size="xs">
              <Avatar alt={user.name} url={user.icon} />
            </TableCell>
            <TableCell size="sm">
              <Label text={user.name} />
            </TableCell>
            <TableCell size="sm">
              <Label text={user.email} />
            </TableCell>
            <TableCell size="xs" align="center">
              <UserRoleLabel role={user.role} />
            </TableCell>
            <TableCell size="xs" align="center">
              <IconButton
                label="編集"
                disabled={!userPermissions.edit(user, sessionUser)}
                onClick={() => handleEdit(user)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                label="削除"
                disabled={!userPermissions.delete(user, sessionUser)}
                onClick={() => handleDelete(user)}
                color="caution"
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </FlexColumn>
  )
}

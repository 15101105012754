import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { FlexColumn, FlexRow } from '../../../components/atoms/layout/Flex'
import Label from '../../../components/atoms/display/Label'
import Form from '../../../components/atoms/inputs/Form'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import FormItem from '../../../components/molecules/inputs/FormItem'
import TextField from '../../../components/atoms/inputs/TextField'
import Alert from '../../../components/atoms/feedback/Alert'
import Button from '../../../components/atoms/inputs/Button'
import DotPagination from '../../../components/atoms/navigation/DotPagination'
import useRouter from '../../../hooks/useRouter'
import registerSlice from '../../../store/register'

type EmailPasswordParams = {
  email: string
  password: string
  passwordConfirm: string
}

export default function RegisterContracteeEmailPage() {
  const { registerContracteeUid } = useRouter()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    email: rules.email.required(),
    password: rules.password.required(),
    passwordConfirm: rules.passwordConfirm.required(),
  })
  const form = useForm<EmailPasswordParams>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = form
  const dispatch = useDispatch()

  const handleNext = useCallback(
    (params: EmailPasswordParams) => {
      dispatch(
        registerSlice.actions.setContractee({
          email: params.email,
          password: params.password,
        })
      )
      registerContracteeUid()
    },
    [dispatch, registerContracteeUid]
  )
  return (
    <Form onSubmit={handleSubmit(handleNext)}>
      <FlexColumn align="center" height="calc(100vh - 128px)" justify="space-between" mt={4}>
        <FlexColumn align="center" width={360}>
          <Label text="アカウント登録" variant="title" />
          <FormItem label="メールアドレスを入力">
            <TextField register={register('email')} error={errors.email?.message} size="md" />
          </FormItem>
          {errors.email?.message && getValues().email?.length > 0 && (
            <Alert severity="warning">
              <FlexColumn>
                <Label text="入力されたメールアドレスは登録できない形式のメールアドレスです。" variant="caption" />
                <Label text="以下のようなメールアドレスは登録できません" />
                <Label
                  text="アットマーク（@）の直前やメールアドレスの先頭にピリオド（.）がある（例：.xxx@xxx.xxx）"
                  variant="caption"
                />
                <Label
                  text="アットマーク（@）より前で、ピリオド（.）が連続している（例：xxx..xxx@xxx.xxx）"
                  variant="caption"
                />
                <Label text="半角英数字と記号以外の文字を含んでいる" variant="caption" />
                <Label text="アットマーク（@）より後ろに存在しないドメインを指定している" variant="caption" />
              </FlexColumn>
            </Alert>
          )}
          <FormItem label="パスワードを入力">
            <TextField register={register('password')} error={errors.password?.message} size="md" type="password" />
          </FormItem>
          <FormItem label="パスワードを再入力">
            <TextField
              register={register('passwordConfirm')}
              error={errors.passwordConfirm?.message}
              size="md"
              type="password"
            />
          </FormItem>
          <Alert
            severity="warning"
            label="メールアドレスが @docomo @ezweb @i.softbank @ymobile @au ですと、
          あなたのセキュリティ設定の関係でメールが届かない可能性があります。パソコンのメール（ @gmail @ocn 等）でのご登録を推奨します。"
          />
          <Alert severity="warning" label="ログイン時に必要ですので、パスワードは忘れにくいものにしてください" />
        </FlexColumn>
        <FlexColumn align="center">
          <Button type="submit" size="lg">
            次へ
          </Button>
          <FlexRow justify="center">
            <DotPagination count={11} current={0} />
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </Form>
  )
}

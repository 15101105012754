import { FieldErrors, useFieldArray, useFormContext } from 'react-hook-form'
import Table from '../../atoms/display/Table'
import TableRow from '../../atoms/display/TableRow'
import TableCell from '../../atoms/display/TableCell'
import { Transaction, TransactionDetail } from '../../../types/transaction'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import NumberControl from '../../atoms/inputs/NumberControl'
import { TransactionInstallmentFormParams } from './TransactionInstallmentForm'
import useTransactionAmounts from '../../../hooks/useTransactionAmounts'

type TransactionInstallmentDetailFormProps = {
  transaction: Transaction
  amounts: number[]
}

function detailTitleLabel(transaction: Transaction, index: number) {
  const currentDetail = transaction.details[index]
  if (currentDetail) {
    return (
      <FlexRow wrap>
        {currentDetail.defaultProp && <Label text={currentDetail.defaultProp} />}
        {currentDetail.customProp1 && <Label text={currentDetail.customProp1} />}
        {currentDetail.customProp2 && <Label text={currentDetail.customProp2} />}
      </FlexRow>
    )
  }
  return <FlexRow />
}

function detailAmountError(
  index: number,
  details: TransactionDetail[],
  amounts: number[],
  errors: FieldErrors<TransactionInstallmentFormParams>
) {
  if (details[index].amount < amounts[index]) {
    return '請求可能額を超えています'
  }
  return errors.details && errors.details[index] && errors.details[index].amount?.message
}

export default function TransactionInstallmentDetailForm({
  transaction,
  amounts,
}: TransactionInstallmentDetailFormProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext<TransactionInstallmentFormParams>()
  const { fields } = useFieldArray({ control, name: 'details' })
  const { calcInstallmentAccumulated } = useTransactionAmounts()

  const currentTransactionAmount = transaction.details.reduce((total, detail) => total + detail.amount, 0)
  const transactionAmount = transaction.installment ? calcInstallmentAccumulated(transaction) : currentTransactionAmount
  const totalAmount = amounts.reduce((total, amount) => total + amount, 0)

  return (
    <Table
      header={
        <TableRow>
          <TableCell header cols={2} color="header" rightBorder>
            明細
          </TableCell>
          <TableCell header cols={3} color="header">
            今回の処理
          </TableCell>
        </TableRow>
      }
    >
      <TableRow>
        <TableCell header>項目</TableCell>
        <TableCell size="xs" header rightBorder>
          <FlexRow wrap justify="center" align="flex-end">
            <Label text="請求可能金額" />
            <Label text="/" />
            <Label text="①合計金額（分割前）" variant="caption" />
          </FlexRow>
        </TableCell>
        <TableCell size="sm2" header>
          ②今回の請求額
        </TableCell>
        <TableCell size="xs" header>
          ②/①x100%
        </TableCell>
        <TableCell size="sm" header>
          残高
        </TableCell>
      </TableRow>
      {fields.map((detail, index) => (
        <TableRow key={detail.id}>
          <TableCell>{detailTitleLabel(transaction, index)}</TableCell>
          <TableCell size="sm" align="right" rightBorder>
            <FlexColumn space={0}>
              <FlexRow justify="flex-end">
                <Label text={transaction.details[index].amount} format="amountWithUnit" />
                <Label text="/" />
              </FlexRow>
              {transaction.installment ? (
                <Label
                  text={transaction.installment.details.original[index].amount}
                  format="amountWithUnit"
                  variant="caption"
                />
              ) : (
                <Label text={transaction.details[index].amount} format="amountWithUnit" variant="caption" />
              )}
            </FlexColumn>
          </TableCell>
          <TableCell size="sm2">
            <FlexColumn space={0}>
              {transaction.details[index].amount === 0 ? (
                <FlexRow align="center" justify="flex-end">
                  <Label text="0円" />
                </FlexRow>
              ) : (
                <FlexRow align="center" justify="flex-end">
                  <NumberControl
                    control={control}
                    name={`details.${index}.amount`}
                    type="amount"
                    error={detailAmountError(index, transaction.details, amounts, errors)}
                  />
                  <Label text="円" />
                </FlexRow>
              )}
            </FlexColumn>
          </TableCell>
          <TableCell size="xs" align="right">
            {transaction.details[index].amount === 0 ? (
              <Label text="0%" />
            ) : (
              <>
                {transaction.installment ? (
                  <Label
                    text={`${Math.floor(
                      (amounts[index] * 100.0) / transaction.installment.details.original[index].amount
                    )}%`}
                  />
                ) : (
                  <Label text={`${Math.floor((amounts[index] * 100.0) / transaction.details[index].amount)}%`} />
                )}
              </>
            )}
          </TableCell>
          <TableCell size="sm" align="right">
            <Label text={transaction.details[index].amount - amounts[index]} format="amountWithUnit" />
          </TableCell>
        </TableRow>
      ))}
      <TableRow>
        <TableCell align="right">
          <Label text="合計" />
        </TableCell>
        <TableCell size="sm" align="right" rightBorder>
          <FlexColumn space={0}>
            <FlexRow justify="flex-end">
              <Label text={currentTransactionAmount} format="amountWithUnit" />
              <Label text="/" />
            </FlexRow>
            <Label text={transactionAmount} format="amountWithUnit" variant="caption" />
          </FlexColumn>
        </TableCell>
        <TableCell size="sm2" align="right">
          <Label text={totalAmount} format="amountWithUnit" />
        </TableCell>
        <TableCell size="xs" align="right">
          {transactionAmount === 0 ? (
            <Label text="0%" />
          ) : (
            <Label text={`${Math.floor((totalAmount * 100.0) / transactionAmount)}%`} />
          )}
        </TableCell>
        <TableCell size="sm" align="right">
          <Label text={currentTransactionAmount - totalAmount} format="amountWithUnit" />
        </TableCell>
      </TableRow>
    </Table>
  )
}

import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type BankBranchOfficeNameFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  disabled?: boolean
}

export default function BankBranchOfficeNameField({ register, error, disabled }: BankBranchOfficeNameFieldProps) {
  return (
    <TextField register={register} placeholder="マネクト支店" size="sm" error={error?.message} readonly={disabled} />
  )
}

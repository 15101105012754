import Timeline from '../../atoms/display/Timeline'
import Label from '../../atoms/display/Label'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import useConsts from '../../../hooks/useConsts'
import Avatar from '../../atoms/display/Avatar'
import { TransactionActivity } from '../../../types/transaction'

type TransactionTimelineProps = {
  activities: TransactionActivity[]
}

export default function TransactionTimeline({ activities }: TransactionTimelineProps) {
  const { transactionActivityActionNames, phaseName } = useConsts()
  return (
    <FlexColumn>
      <Label text="アクティビティ" variant="subtitle" />
      <Timeline
        items={activities.map((activity) => ({
          key: activity.id,
          left: <Label text={activity.actionedAt} />,
          right: (
            <FlexColumn space={0}>
              <Label text={phaseName(activity.phase) ?? ''} variant="caption" />
              <Label text={transactionActivityActionNames[activity.actionType] ?? ''} />
              <FlexRow align="center">
                {activity.actionedBy && (
                  <>
                    {activity.actionedBy.isDeleted ? (
                      <>
                        <Avatar alt="icon" size="sm" />
                        <FlexRow>
                          <Label text={activity.actionedBy.name} variant="caption" />
                          <Label text="(削除済み)" variant="caption" />
                        </FlexRow>
                      </>
                    ) : (
                      <>
                        <Avatar url={activity.actionedBy.icon} alt="icon" size="sm" />
                        <Label text={activity.actionedBy.name} variant="caption" />
                      </>
                    )}
                  </>
                )}
              </FlexRow>
              {activity.note && <Label text={activity.note} variant="caption" whiteSpace="pre-wrap" />}
            </FlexColumn>
          ),
        }))}
        leftWidth={120}
        rightWidth={480}
      />
    </FlexColumn>
  )
}

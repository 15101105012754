import { FlexColumn } from '../../components/atoms/layout/Flex'
import Label from '../../components/atoms/display/Label'
import RegisterStepView from '../../components/molecules/navigation/RegisterStepView'

export default function RegisterSendEmailPage() {
  return (
    <FlexColumn align="center" mt={4} space={4} width={640} mbWidth={360}>
      <RegisterStepView step={1} />
      <Label text="アカウントを作成しました" variant="title" />
      <FlexColumn align="center">
        <Label text="ご登録ありがとうございます。" />
        <Label text="入力頂いたメールアドレス宛てに本登録用のメールをお送りしました。" />
        <Label text="メールをご確認いただき、本登録を完了してください。" />
      </FlexColumn>
    </FlexColumn>
  )
}

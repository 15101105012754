// selector
import { createSelector } from '@reduxjs/toolkit'
import { StoreState } from '../index'

const sessionSelector = (state: StoreState) => state.session
export const SessionSelectors = {
  token: createSelector([sessionSelector], (state) => state.token),
  loggedIn: createSelector([sessionSelector], (state) => state.token !== undefined),
  user: createSelector([sessionSelector], (state) => state.user),
  contractIncludeClosed: createSelector([sessionSelector], (state) => state.contractIncludeClosed),
  companyId: createSelector([sessionSelector], (state) => state.companyId),
  notifications: createSelector([sessionSelector], (state) => state.notifications),
  lineConnectUrl: createSelector([sessionSelector], (state) => state.lineConnectUrl),
}

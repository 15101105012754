import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlexColumn, FlexRow } from '../../atoms/layout/Flex'
import TextField from '../../atoms/inputs/TextField'
import { SendIcon } from '../../atoms/display/Icons'
import IconButton from '../../atoms/inputs/IconButton'
import { ChatActions } from '../../../store/chat'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import Form from '../../atoms/inputs/Form'
import useValidationRule from '../../../hooks/useValidationRule'
import MessageBoard from './MessageBoard'
import useMoneyspace from '../../../hooks/useMoneyspace'
import MultipleCombobox, { ComboboxItem } from '../../atoms/inputs/MultipleCombobox'
import { ChatSelectors } from '../../../store/chat/selectors'
import { SessionSelectors } from '../../../store/session/selectors'
import Button from '../../atoms/inputs/Button'
import useRouter from '../../../hooks/useRouter'
import { RelatedUser } from '../../../types/User'
import { MoneyspacesActions } from '../../../store/moneyspaces'

type ChatPanelProps = {
  moneyspaceId: string
  showMoneyspaceButton?: boolean
  height?: string
  minRows?: number
}

type PostMessageParams = {
  description: string
  destinations: ComboboxItem[]
}

export default function ChatPanel({ moneyspaceId, showMoneyspaceButton, height, minRows }: ChatPanelProps) {
  const currentUser = useSelector(SessionSelectors.user)
  const { findMembers } = useMoneyspace()
  const { moneyspace: moneyspacePage } = useRouter()
  const [members, setMembers] = useState<RelatedUser[]>([])
  const room = useSelector(ChatSelectors.rooms).find((item) => item.moneyspaceId === moneyspaceId)
  const messages = room?.messages ?? []
  const dispatch = useDispatch()
  const yup = useYup()
  const rules = useValidationRule()
  const schema = yup.object().shape({
    description: rules.chatMessageText,
  })
  const { handleSubmit, register, control, errors, getValues, resetField } = useForm<PostMessageParams>(schema, {
    description: '',
    destinations: [],
  })

  useEffect(() => {
    dispatch(MoneyspacesActions.fetchMembers({ moneyspaceId }))
  }, [dispatch, moneyspaceId])

  useEffect(() => {
    setMembers(findMembers(moneyspaceId).filter((member) => member.id !== currentUser?.id))
  }, [currentUser, findMembers, moneyspaceId])

  useEffect(() => {
    if (moneyspaceId) {
      dispatch(ChatActions.fetchMessages({ moneyspaceId }))
    }
    const interval = setInterval(() => {
      dispatch(ChatActions.fetchMessages({ moneyspaceId }))
    }, 10000)
    resetField('destinations')
    resetField('description')
    return () => clearInterval(interval)
  }, [dispatch, moneyspaceId, resetField])
  const handlePost = useCallback(
    (params: PostMessageParams) => {
      dispatch(
        ChatActions.postMessage({
          moneyspaceId,
          description: params.description,
          destinations: params.destinations.map((item) => item.value),
        })
      )
      resetField('destinations')
      resetField('description')
    },
    [dispatch, moneyspaceId, resetField]
  )
  return (
    <FlexColumn>
      {currentUser && <MessageBoard currentUserId={currentUser.id} messages={messages} height={height} />}
      <Form onSubmit={handleSubmit(handlePost)}>
        <FlexColumn>
          <MultipleCombobox
            items={members.map((member) => ({ label: member.name, value: member.id }))}
            control={control}
            name="destinations"
            placeholder="宛先"
          />
          <FlexRow align="flex-end">
            <TextField
              register={register('description')}
              error={errors.description?.message}
              multiline
              minRows={minRows ?? 1}
              maxRows={5}
            />
            <IconButton label="" color="primary" type="submit" disabled={getValues('description').length === 0}>
              <SendIcon />
            </IconButton>
          </FlexRow>
          {showMoneyspaceButton && (
            <Button size="lg" variant="outlined" onClick={() => moneyspacePage(moneyspaceId)}>
              マネースペースを確認する
            </Button>
          )}
        </FlexColumn>
      </Form>
    </FlexColumn>
  )
}

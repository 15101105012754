import { PhaseChangeLog, Transaction, TransactionDetail } from '../types/transaction'

function same(detail1: TransactionDetail, detail2: TransactionDetail): boolean {
  if (detail1.defaultProp !== detail2.defaultProp) {
    return false
  }
  if (detail1.customProp1 !== detail2.customProp1) {
    return false
  }
  if (detail1.customProp2 !== detail2.customProp2) {
    return false
  }
  if (detail1.unitPrice !== detail2.unitPrice) {
    return false
  }
  if (detail1.quantity !== detail2.quantity) {
    return false
  }
  if (detail1.unit !== detail2.unit) {
    return false
  }
  if (detail1.taxBucket !== detail2.taxBucket) {
    return false
  }
  return detail1.amount === detail2.amount
}

export default function useTransactionDiff(currentPhase?: Transaction, previousPhase?: Transaction) {
  if (!currentPhase || !previousPhase) {
    return {
      changeLogs: [],
      updated: false,
    }
  }

  let previousIndex = 0
  let currentIndex = 0
  const changeLogs: PhaseChangeLog[] = previousPhase.details.map((detail) => {
    const currentDetail = currentPhase.details.find((item) => item.id === detail.id)
    if (!currentDetail) {
      const prevIndex = previousIndex
      previousIndex += 1
      return {
        status: 'delete',
        previousNo: prevIndex + 1,
        previousDetail: detail,
        currentDetail: undefined,
      }
    }
    const prevIndex = previousIndex
    const curIndex = currentIndex
    previousIndex += 1
    currentIndex += 1
    if (same(detail, currentDetail)) {
      return {
        status: 'same',
        previousNo: prevIndex + 1,
        currentNo: curIndex + 1,
        previousDetail: detail,
        currentDetail,
      }
    }
    return {
      status: 'change',
      previousNo: prevIndex + 1,
      currentNo: curIndex + 1,
      previousDetail: detail,
      currentDetail,
    }
  })
  currentPhase.details.forEach((detail) => {
    const previousDetail = previousPhase.details.find((item) => item.id === detail.id)
    if (!previousDetail) {
      const curIndex = currentIndex
      currentIndex += 1
      changeLogs.push({
        status: 'add',
        currentNo: curIndex + 1,
        previousDetail: undefined,
        currentDetail: detail,
      })
    }
  })
  const updated = changeLogs.some((log) => log.status !== 'same')

  return {
    changeLogs,
    updated,
  }
}

import dayjs from 'dayjs'
import { useCallback } from 'react'
import { Moneyspace } from '../types/Moneyspace'
import {
  Transaction,
  TransactionPhaseBilling,
  TransactionPhaseBillingDetail,
  TransactionPhaseDelivered,
  TransactionStatusCanceled,
  TransactionStatusClosed,
} from '../types/transaction'

export default function useTransaction() {
  const isFixed = (moneyspace: Moneyspace, date: Date | string | null | undefined) => {
    if (!moneyspace.aggregateTransactions || moneyspace.fixMonth == null || moneyspace.fixDay == null) {
      return false
    }
    let fixedDate = dayjs(date).startOf('month')
    fixedDate = fixedDate.add(moneyspace.fixMonth - 1, 'M')
    if (moneyspace.fixDay === 99) {
      fixedDate = fixedDate.endOf('month')
    } else {
      fixedDate = fixedDate.add(moneyspace.fixDay, 'd')
    }
    return dayjs().isAfter(fixedDate)
  }
  const pdfUrl = useCallback((id: string, phase: number) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL
    if (baseUrl) {
      return `${baseUrl}/transactions/${id}/pdf?phase=${phase}`
    }
    return ''
  }, [])

  const validatePic = (moneyspace: Moneyspace, transaction: Transaction) =>
    !(moneyspace.detailPropConfig.requiredPic && transaction.pic == null)

  const validatePublishDate = (transaction: Transaction) => transaction.publishedAt !== null

  const validateClosingDate = (moneyspace: Moneyspace, transaction: Transaction) => {
    if ([TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase)) {
      if ([TransactionStatusClosed, TransactionStatusCanceled].includes(transaction.status)) {
        return true
      }
      return transaction.closingDate != null && !isFixed(moneyspace, transaction.closingDate)
    }
    return true
  }

  const validatePaymentDate = (moneyspace: Moneyspace, transaction: Transaction) => {
    if ([TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(transaction.currentPhase)) {
      if ([TransactionStatusClosed, TransactionStatusCanceled].includes(transaction.status)) {
        return true
      }
      return transaction.paymentDate != null && !isFixed(moneyspace, transaction.closingDate)
    }
    return true
  }

  const validateDeliveryDate = (moneyspace: Moneyspace, transaction: Transaction) => {
    if (
      [TransactionPhaseDelivered, TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(
        transaction.currentPhase
      )
    ) {
      if (moneyspace.detailPropConfig.requiredDeliveryDate) {
        return transaction.deliveryDateFrom != null
      }
    }
    return true
  }

  const validateCustomProp1 = (moneyspace: Moneyspace, transaction: Transaction) => {
    if (
      [TransactionPhaseDelivered, TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(
        transaction.currentPhase
      )
    ) {
      if (moneyspace.transactionPropConfig?.customProp1 == null) {
        return true
      }
      if (moneyspace.transactionPropConfig.customProp1.required) {
        return transaction.customProp1 != null && transaction.customProp1.length > 0
      }
    }
    return true
  }

  const validateCustomProp2 = (moneyspace: Moneyspace, transaction: Transaction) => {
    if (
      [TransactionPhaseDelivered, TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(
        transaction.currentPhase
      )
    ) {
      if (moneyspace.transactionPropConfig?.customProp2 == null) {
        return true
      }
      if (moneyspace.transactionPropConfig.customProp2.required) {
        return transaction.customProp2 != null && transaction.customProp2.length > 0
      }
    }
    return true
  }

  const validateCustomProp3 = (moneyspace: Moneyspace, transaction: Transaction) => {
    if (
      [TransactionPhaseDelivered, TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(
        transaction.currentPhase
      )
    ) {
      if (moneyspace.transactionPropConfig?.customProp3 == null) {
        return true
      }
      if (moneyspace.transactionPropConfig.customProp3.required) {
        return transaction.customProp3 != null && transaction.customProp3.length > 0
      }
    }
    return true
  }

  const validateCustomProp4 = (moneyspace: Moneyspace, transaction: Transaction) => {
    if (
      [TransactionPhaseDelivered, TransactionPhaseBillingDetail, TransactionPhaseBilling].includes(
        transaction.currentPhase
      )
    ) {
      if (moneyspace.transactionPropConfig?.customProp4 == null) {
        return true
      }
      if (moneyspace.transactionPropConfig.customProp4.required) {
        return transaction.customProp4 != null && transaction.customProp4.length > 0
      }
    }
    return true
  }

  const validateCustomProps = (moneyspace: Moneyspace, transaction: Transaction) =>
    validateCustomProp1(moneyspace, transaction) &&
    validateCustomProp2(moneyspace, transaction) &&
    validateCustomProp3(moneyspace, transaction) &&
    validateCustomProp4(moneyspace, transaction)

  const validateTransaction = (moneyspace: Moneyspace, transaction: Transaction) =>
    validatePic(moneyspace, transaction) &&
    validatePublishDate(transaction) &&
    validateClosingDate(moneyspace, transaction) &&
    validatePaymentDate(moneyspace, transaction) &&
    validateDeliveryDate(moneyspace, transaction) &&
    validateCustomProps(moneyspace, transaction) &&
    transaction.details.length > 0

  return {
    isFixed,
    pdfUrl,
    validatePic,
    validatePublishDate,
    validateClosingDate,
    validatePaymentDate,
    validateDeliveryDate,
    validateTransaction,
    validateCustomProp1,
    validateCustomProp2,
    validateCustomProp3,
    validateCustomProp4,
  }
}

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

type ZipcodeSearchResult = {
  address1: string
  address2: string
  address3: string
}

export default class ZipcodeRepository {
  instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_ZIPCODE_API_BASE_URL,
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json',
    })
  }

  async searchAddress({ zipcode }: { zipcode: string }) {
    const config: AxiosRequestConfig = {
      params: { zipcode },
    }
    const response = await this.instance.get<{ results: ZipcodeSearchResult[] }>(`/search`, config)
    const { results } = response.data
    if (results === null || results.length === 0) {
      return null
    }
    const result = response.data.results[0]
    return result.address1 + result.address2 + result.address3
  }
}

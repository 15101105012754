import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import { DocumentsActions } from '../../../store/documents'
import { SortDirection } from '../../../types/System'
import { FlexColumn } from '../../atoms/layout/Flex'
import Label from '../../atoms/display/Label'
import Button from '../../atoms/inputs/Button'
import { useDocumentBulkApproveDialog } from '../../../hooks/useDialog'

type DocumentBulkApproveDialogProps = {
  documentIds: string[]
  page: number
  sortColumn?: string
  sortDirection?: SortDirection
}

export default function DocumentBulkApproveDialog({
  documentIds,
  page,
  sortColumn,
  sortDirection,
}: DocumentBulkApproveDialogProps) {
  const { state, close } = useDocumentBulkApproveDialog()
  const dispatch = useDispatch()
  const handleBulkApprove = useCallback(() => {
    dispatch(DocumentsActions.bulkApproveDocument({ documentIds, page, sortColumn, sortDirection }))
  }, [documentIds, dispatch, page, sortColumn, sortDirection])
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title="書類の一括承認"
      actions={<Button onClick={handleBulkApprove}>承認する</Button>}
    >
      <FlexColumn align="center">
        <Label text={`${documentIds.length}件の書類を選択中`} />
        <Label text="選択中の書類を一括で承認しますか？" />
      </FlexColumn>
    </Dialog>
  )
}

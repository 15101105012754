import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import NotLoggedInLayout from '../components/templates/NotLoggedInLayout'
import { FlexColumn, FlexRow } from '../components/atoms/layout/Flex'
import Label from '../components/atoms/display/Label'
import { SystemActions } from '../store/system'
import { SystemSelectors } from '../store/system/selectors'
import useDay from '../hooks/useDay'

export default function MaintenancePage() {
  const { formatDateTime } = useDay()
  const dispatch = useDispatch()
  const info = useSelector(SystemSelectors.maintenanceInfo)
  useEffect(() => {
    dispatch(SystemActions.loadMaintenanceInfo())
  }, [dispatch])
  return (
    <NotLoggedInLayout>
      <FlexColumn align="center" space={4}>
        <Label text="只今メンテナンス中です" variant="title" />
        {info && (
          <FlexColumn align="center">
            <Label text="メンテナンス期間" variant="subtitle" />
            <FlexRow>
              <Label text={formatDateTime(info.from)} />
              <Label text="〜" />
              <Label text={formatDateTime(info.to)} />
            </FlexRow>
          </FlexColumn>
        )}
        <FlexColumn align="center">
          <Label text="ご不便をおかけし申し訳ございません" />
          <Label text="メンテナンス作業が完了するまで今しばらくお待ち下さい" />
        </FlexColumn>
      </FlexColumn>
    </NotLoggedInLayout>
  )
}

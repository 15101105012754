import TableCell, { CellSize } from '../../atoms/display/TableCell'
import Tooltip from '../../atoms/display/Tooltip'
import { FlexRow } from '../../atoms/layout/Flex'
import { SortAscIcon, SortDescIcon } from '../../atoms/display/Icons'
import { SortDirection } from '../../../types/System'

function sortTooltipLabel(name: string, sortColumn?: string, sortDirection?: SortDirection) {
  if (sortColumn === name) {
    if (sortDirection === 'asc') {
      return '降順に並び替える'
    }
    return '昇順に並び替える'
  }
  return '降順に並び替える'
}

export default function SortableTableCell(params: {
  onClick: (name: string) => void
  name: string
  label: string
  sortColumn?: string
  sortDirection?: SortDirection
  size: CellSize
  header?: boolean
}) {
  const { onClick, name, label, sortColumn, sortDirection, size, header } = params
  return (
    <TableCell header={header} size={size} onClick={() => onClick(name)}>
      <Tooltip text={sortTooltipLabel(name, sortColumn, sortDirection)}>
        <FlexRow justify={header ? 'center' : undefined}>
          {label}
          {sortColumn === name && <>{sortDirection === 'asc' ? <SortAscIcon /> : <SortDescIcon />}</>}
        </FlexRow>
      </Tooltip>
    </TableCell>
  )
}

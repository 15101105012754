import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import TextField from '../../atoms/inputs/TextField'

type CompanyDomainFieldProps = {
  register?: UseFormRegisterReturn
  error?: FieldError
  readonly?: boolean
}

export default function CompanyDomainField({ register, error, readonly }: CompanyDomainFieldProps) {
  return (
    <TextField
      size="md"
      placeholder="example"
      prefix="monect.jp/"
      readonly={readonly}
      register={register}
      error={error?.message}
    />
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMoneyspaceCreateDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import MoneyspaceForm from './MoneyspaceForm'
import useForm from '../../../hooks/useForm'
import useYup from '../../../hooks/useYup'
import useValidationRule from '../../../hooks/useValidationRule'
import Button from '../../atoms/inputs/Button'
import { MoneyspacesActions } from '../../../store/moneyspaces'
import usePath from '../../../hooks/usePath'
import { MoneyspaceCreateParams, MoneyspaceFolder } from '../../../types/Moneyspace'
import useProcessing from '../../../hooks/useProcessing'
import FormItem from '../../molecules/inputs/FormItem'
import Label from '../../atoms/display/Label'
import { FlexColumn } from '../../atoms/layout/Flex'
import { CompanySelectors } from '../../../store/company/selectors'

type MoneyspaceCreateDialogProps = {
  folder: MoneyspaceFolder | null
}

export default function MoneyspaceCreateDialog({ folder }: MoneyspaceCreateDialogProps) {
  const { processing, startProcess } = useProcessing(MoneyspacesActions.createMoneyspace.typePrefix)
  const { state, close } = useMoneyspaceCreateDialog()
  const yup = useYup()
  const rules = useValidationRule()
  const [membershipFee, setMembershipFee] = useState<boolean>(false)
  const [displayRole, setDisplayRole] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { moneyspace: moneyspacePage } = usePath()
  const clients = useSelector(CompanySelectors.clients)
  const company = useSelector(CompanySelectors.company)
  const schema = yup.object().shape({
    name: rules.moneyspaceName.required(),
    closingDay: rules.closingDay.required(),
    paymentDay: rules.paymentDay.required(),
    invitee: rules.invitee.required(),
  })
  const { handleSubmit, register, control, errors, watch, setValue, getValues } = useForm<MoneyspaceCreateParams>(
    schema,
    {
      isContractee: 'true',
      paymentMonth: 2,
      isTaxIn: false,
      bearingFee: true,
      membershipFee: 1,
      fixGracePeriod: 10,
    }
  )
  useEffect(() => {
    watch((values, { name }) => {
      if (name === 'isContractee') {
        if (values.isContractee !== 'true') {
          setValue('membershipFee', 1)
        }
      }
      if (name === 'membershipFee') {
        setMembershipFee(Number(values.membershipFee) === 2)
      }
      if (name === 'invitee') {
        setDisplayRole(values.invitee === 'no_client')
        if (values.invitee !== 'no_client') {
          setValue('isContractee', 'true')
        }
      }
    })
  }, [watch, setValue])
  const handleCreate = useCallback(
    (values: MoneyspaceCreateParams) => {
      startProcess()
      const invitee = values.invitee === 'no_client' ? undefined : values.invitee
      let alertBillingDeadline = false
      if (values.isContractee) {
        alertBillingDeadline = company?.aggregateTransactions ?? false
      } else {
        const inviteeClient = clients.find((client) => client.id === invitee)
        if (inviteeClient) {
          alertBillingDeadline = inviteeClient.aggregateTransactions
        }
      }
      dispatch(
        MoneyspacesActions.createMoneyspace({
          folderId: folder ? folder.id : null,
          name: values.name,
          isContractee: values.isContractee === 'true',
          payment: {
            closingDay: values.closingDay,
            paymentMonth: values.paymentMonth,
            paymentDay: values.paymentDay,
            bearingFee: values.bearingFee,
            isTaxIn: values.isTaxIn,
          },
          inviteCompanyDomain: invitee,
          membershipFee: Number(values.membershipFee) === 2,
          getRedirectPath: (moneyspace) => moneyspacePage(moneyspace.id),
          alertBillingDeadline,
          fixGracePeriod: values.fixGracePeriod ?? 10,
        })
      )
    },
    [clients, company, dispatch, folder, moneyspacePage, startProcess]
  )
  return (
    <Dialog
      open={state !== undefined}
      title="マネースペースを作成する"
      actions={
        <Button type="submit" disabled={processing}>
          作成
        </Button>
      }
      onSubmit={handleSubmit(handleCreate)}
      onClose={close}
    >
      <FlexColumn>
        <Label text="受注者としてMS作成することはできません。" />
        <FormItem label="フォルダ">
          <Label text={folder ? folder.name : 'フォルダ未選択'} />
        </FormItem>
        <MoneyspaceForm
          register={register}
          control={control}
          errors={errors}
          disabledMembershipFee={getValues().isContractee === 'false'}
          hideMembershipFee={!membershipFee}
          displayRole={displayRole}
        />
      </FlexColumn>
    </Dialog>
  )
}

import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTransactionApproveDialog } from '../../../hooks/useDialog'
import Dialog from '../../atoms/feedback/Dialog'
import Button from '../../atoms/inputs/Button'
import Label from '../../atoms/display/Label'
import useProcessing from '../../../hooks/useProcessing'
import {
  Transaction,
  TransactionPhase,
  TransactionPhaseBilling,
  TransactionStatusReview,
} from '../../../types/transaction'
import { TransactionActions } from '../../../store/transactions'
import useConsts from '../../../hooks/useConsts'

type TransactionApproveDialogProps = {
  transaction: Transaction
}

function getLabels(transaction: Transaction, phaseName: (phase: TransactionPhase) => string) {
  if (transaction.currentPhase === TransactionPhaseBilling || transaction.status === TransactionStatusReview) {
    return {
      title: `取引（${phaseName(transaction.currentPhase)}）を承認する`,
      message: `取引（${phaseName(transaction.currentPhase)}）を承認しますか？`,
      button: '承認する',
    }
  }
  if (transaction.phases[transaction.phases.length - 1] === transaction.currentPhase) {
    return {
      title: '取引を終了する',
      message: 'この取引を終了しますか？',
      button: '終了する',
    }
  }
  const currentIndex = transaction.phases.indexOf(transaction.currentPhase)
  const nextPhaseName = phaseName(transaction.phases[currentIndex + 1])
  return {
    title: `${nextPhaseName}に進む`,
    message: `${nextPhaseName}に進みますか？`,
    button: `${nextPhaseName}へ進む`,
  }
}

export default function TransactionApproveDialog({ transaction }: TransactionApproveDialogProps) {
  const { processing, startProcess } = useProcessing(TransactionActions.approveTransaction.typePrefix)
  const { state, close } = useTransactionApproveDialog()
  const dispatch = useDispatch()
  const { phaseName } = useConsts()

  const handleApprove = useCallback(() => {
    startProcess()
    dispatch(
      TransactionActions.approveTransaction({
        transaction,
      })
    )
  }, [dispatch, startProcess, transaction])
  const labels = getLabels(transaction, phaseName)
  return (
    <Dialog
      open={state !== undefined}
      onClose={close}
      title={labels.title}
      actions={
        <Button onClick={handleApprove} disabled={processing}>
          {labels.button}
        </Button>
      }
    >
      <Label text={labels.message} />
    </Dialog>
  )
}

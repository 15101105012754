import { Control, FieldError } from 'react-hook-form'
import NumberField from '../../atoms/inputs/NumberField'

type DocumentItemQuantityFieldProps = {
  name?: string
  control?: Control
  error?: FieldError
  disabled?: boolean
}

export default function DocumentItemQuantityField({ name, control, error, disabled }: DocumentItemQuantityFieldProps) {
  return (
    <NumberField size="xs" name={name} placeholder="1" control={control} error={error?.message} disabled={disabled} />
  )
}

import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../atoms/feedback/Dialog'
import FileSelectField from '../../molecules/inputs/FileSelectField'
import { useDeviceType } from '../../../hooks/useDeviceType'
import FileSelectButton from '../../atoms/inputs/FileSelectButton'
import { FlexColumn } from '../../atoms/layout/Flex'
import { ToolsActions } from '../../../store/tools'
import { ToolsSelectors } from '../../../store/tools/selectors'
import { DocumentItem } from '../../../types/Document'
import Label from '../../atoms/display/Label'

type DocumentOcrDialogProps = {
  open: boolean
  onResult: (details: DocumentItem[], file: File) => void
  onClose: () => void
}

export default function DocumentOcrDialog({ open, onResult, onClose }: DocumentOcrDialogProps) {
  const { pc } = useDeviceType()
  const accepts = ['application/pdf']
  const [file, setFile] = useState<File>()
  const ocrResult = useSelector(ToolsSelectors.ocrResult)
  const dispatch = useDispatch()
  const handleSelect = useCallback(
    (files: File[]) => {
      setFile(files[0])
      dispatch(ToolsActions.ocrDocument({ file: files[0] }))
    },
    [dispatch]
  )
  useEffect(() => {
    if (ocrResult && file) {
      onResult([], file)
    }
  }, [file, ocrResult, onResult])
  return (
    <Dialog open={open} onClose={onClose} title="書類を読み込みます">
      <FlexColumn align="center">
        <Label text="すべての入力内容が削除され、読み込んだ明細が入力されます" />
        {pc && <FileSelectField accepts={accepts} onSelect={handleSelect} maxFiles={1} />}
        <FileSelectButton accepts={accepts} onSelect={handleSelect}>
          ファイルを選択する
        </FileSelectButton>
      </FlexColumn>
    </Dialog>
  )
}

import { Request } from './Request'
import {
  BillingSearchResultItem,
  FactoringTransaction,
  Transaction,
  TransactionActivity,
  TransactionDetailSearchResultItem,
  TransactionInstallmentDetail,
  TransactionPhase,
  TransactionPhaseBilling,
  TransactionPhaseDiffsResponse,
  TransactionSearchConditionCreateResponse,
  TransactionSearchConditionGetResponse,
  TransactionSearchResultItem,
  TransactionStatus,
  TransactionStatusReview,
} from '../types/transaction'
import { Page, SortDirection } from '../types/System'
import { ApprovalFlowState } from '../types/ApprovalFlow'
import { BillingSearchParams, TransactionSearchParams } from '../types/search'
import { ImportTransactionParams } from '../store/tools'

function textToString(text?: string) {
  if (text && text.length > 0) {
    return text
  }
  return undefined
}

function transactionPhaseToList(searchParams: TransactionSearchParams) {
  const list = []
  if (searchParams.phases.phase1) {
    list.push(1)
  }
  if (searchParams.phases.phase2) {
    list.push(2)
  }
  if (searchParams.phases.phase3) {
    list.push(3)
  }
  if (searchParams.phases.phase4) {
    list.push(4)
  }
  if (searchParams.phases.phase5) {
    list.push(5)
  }
  if (searchParams.phases.phase6) {
    list.push(6)
  }
  if (searchParams.phases.phase7) {
    list.push(7)
  }
  return list
}

export default class TransactionRepository {
  private request: Request

  constructor(request: Request) {
    this.request = request
  }

  async createTransaction(msId: string, name: string, phases: TransactionPhase[], fixFollowingPhase: boolean) {
    const response = await this.request.post<Transaction>('/transactions', {
      msId,
      name,
      phases,
      fixFollowingPhase,
    })
    return response.data
  }

  async saveTransaction(transaction: Transaction) {
    const response = await this.request.put<Transaction>(`/transactions/${transaction.id}`, {
      name: transaction.name,
      siteAddress: transaction.siteAddress,
      isTaxIn: transaction.isTaxIn,
      publishedAt: transaction.publishedAt,
      paymentDate: transaction.paymentDate,
      closingDate: transaction.closingDate,
      deliveryDateFrom: transaction.deliveryDateFrom,
      deliveryDateTo: transaction.deliveryDateTo,
      picId: transaction.pic?.id ?? null,
      picSubId: transaction.picSub?.id ?? null,
      details: transaction.details.map((detail) => ({
        ...detail,
        id: detail.id === '' ? undefined : detail.id,
      })),
      note: transaction.note,
      customProp1: transaction.customProp1,
      customProp2: transaction.customProp2,
      customProp3: transaction.customProp3,
      customProp4: transaction.customProp4,
    })
    return response.data
  }

  async saveTransactionClosingDate(transactionId: string, closingDate: string, paymentDate: string) {
    const response = await this.request.put<Transaction>(`/transactions/${transactionId}`, {
      paymentDate,
      closingDate,
    })
    return response.data
  }

  async saveTransactionForInstallment(
    transactionId: string,
    publishedAt: string,
    paymentDate: string,
    closingDate: string,
    deliveryDateFrom: string | null,
    deliveryDateTo: string | null,
    picId: string | null,
    picSubId: string | null
  ) {
    const response = await this.request.put<Transaction>(`/transactions/${transactionId}`, {
      publishedAt,
      paymentDate,
      closingDate,
      deliveryDateFrom,
      deliveryDateTo,
      picId: picId ?? null,
      picSubId: picSubId ?? null,
    })
    return response.data
  }

  async searchActiveTransactions(
    page: number,
    sortColumn?: string,
    sortDirection?: SortDirection
  ): Promise<Page<TransactionSearchResultItem>> {
    const sort = sortColumn && sortDirection ? `${sortColumn}_${sortDirection}` : undefined
    const response = await this.request.get<Page<TransactionSearchResultItem>>(`/transactions`, {
      filterType: 'active',
      page,
      sort,
    })
    return response.data
  }

  async searchBillingTransactions(page: number, pageSize?: number): Promise<Page<TransactionSearchResultItem>> {
    const response = await this.request.get<Page<TransactionSearchResultItem>>(`/transactions`, {
      filterType: 'noApprovalFlow',
      status: TransactionStatusReview,
      currentPhase: TransactionPhaseBilling,
      page,
      pageSize,
    })
    return response.data
  }

  async loadTransactions(msId: string, page: number, onBilling?: boolean, filterType?: 'active' | 'inactive') {
    const response = await this.request.get<Page<Transaction>>('/transactions', {
      msId,
      page,
      onBilling,
      filterType,
    })
    return response.data
  }

  async loadAllTransactions(msId: string) {
    const response = await this.request.get<{ transactions: Transaction[] }>('/transactions', {
      msId,
    })
    return response.data.transactions
  }

  async loadTransaction(transactionId: string, phase?: TransactionPhase) {
    const response = await this.request.get<Transaction>(`/transactions/${transactionId}`, {
      phase,
    })
    return response.data
  }

  async loadInstallmentTransactions(installmentId: string) {
    const response = await this.request.get<{ transactions: Transaction[] }>(`/transactions`, {
      installmentId,
    })
    return response.data.transactions
  }

  async loadActivities(transactionId: string) {
    const response = await this.request.get<{ activities: TransactionActivity[] }>(
      `/transactions/${transactionId}/activities`
    )
    return response.data.activities
  }

  async loadApprovalFlowState(transactionId: string) {
    const response = await this.request.get<ApprovalFlowState | undefined>(
      `/transactions/${transactionId}/approval-flow`,
      {},
      {
        notFound: () => undefined,
      }
    )
    return response.data
  }

  async cancelTransaction(transactionId: string, note?: string) {
    const response = await this.request.put<Transaction>(`/transactions/${transactionId}/cancel`, { note })
    return response.data
  }

  async deleteTransaction(transactionId: string) {
    await this.request.delete(`/transactions/${transactionId}`)
  }

  async createInstallment(transactionId: string, details: TransactionInstallmentDetail[]) {
    await this.request.post(`/transactions/${transactionId}/installment`, {
      details: details.map((detail) => ({
        id: detail.id,
        unitPrice: detail.amount,
      })),
    })
  }

  async loadFactoringTransactions() {
    const response = await this.request.get<{ transactions: FactoringTransaction[] }>(
      `/transactions`,
      {
        filterType: 'factoring',
      },
      undefined,
      true
    )
    return response.data.transactions
  }

  async loadTransactionPhaseDiffs(id: string, phase?: TransactionPhase) {
    const response = await this.request.get<TransactionPhaseDiffsResponse | undefined>(
      `/transactions/${id}/diffs`,
      {
        phase,
      },
      {
        notFound: () => undefined,
      }
    )
    return response.data
  }

  async applyTransaction(id: string) {
    const response = await this.request.put<Transaction>(`/transactions/${id}/review`)
    return response.data
  }

  async updateApprovalFlow(id: string, flowId: string) {
    await this.request.put<Transaction>(`/transactions/${id}/approval-flow`, { approvalFlowId: flowId })
  }

  async approveTransaction(id: string, status: TransactionStatus) {
    if (status === TransactionStatusReview) {
      const response = await this.request.put<Transaction>(`/transactions/${id}/reviewed`)
      return response.data
    }
    const response = await this.request.put<Transaction>(`/transactions/${id}/approved`)
    return response.data
  }

  async submitTransaction(id: string) {
    const response = await this.request.put<Transaction>(`/transactions/${id}/reviewed`, { skipFlow: true })
    return response.data
  }

  async bulkApproveTransaction(transactionIdList: string[]) {
    await this.request.put(`/transactions/bulk-approve`, { transactionIdList })
  }

  async rejectTransactionPhase(id: string, note?: string) {
    const response = await this.request.put<Transaction>(`/transactions/${id}/reject`, { note })
    return response.data
  }

  async searchTransactions(page: number, searchParams: TransactionSearchParams) {
    const response = await this.request.get<Page<TransactionSearchResultItem>>(`/transactions`, {
      page,
      freeWord: textToString(searchParams.keyword),
      currentPhase: transactionPhaseToList(searchParams),
      status: Number(searchParams.status) === 0 ? undefined : searchParams.status,
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      subtotalFrom: searchParams.amountMin ?? undefined,
      subtotalTo: searchParams.amountMax ?? undefined,
      isFavorite: searchParams.isFavorite ? true : undefined,
    })
    return response.data
  }

  async aggregateTransactions(
    phase: TransactionPhase,
    publishedDateFrom: string | null,
    publishedDateTo: string | null
  ) {
    const response = await this.request.get<{ transactions: TransactionSearchResultItem[] }>(`/transactions`, {
      publishedDateFrom,
      publishedDateTo,
    })
    const { transactions } = response.data
    return transactions.filter((transaction) => transaction.phases.includes(phase))
  }

  async searchTransactionDetails(page: number, searchParams: TransactionSearchParams) {
    const response = await this.request.get<Page<TransactionDetailSearchResultItem>>(`/transaction-details`, {
      page,
      freeWord: textToString(searchParams.keyword),
      currentPhase: transactionPhaseToList(searchParams),
      status: Number(searchParams.status) === 0 ? undefined : searchParams.status,
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      subtotalFrom: searchParams.amountMin ?? undefined,
      subtotalTo: searchParams.amountMax ?? undefined,
      isFavorite: searchParams.isFavorite ? true : undefined,
    })
    return response.data
  }

  async downloadTransactions(filename: string, searchParams: TransactionSearchParams) {
    await this.request.download(filename, `/transactions/csv`, {
      freeWord: textToString(searchParams.keyword),
      currentPhase: transactionPhaseToList(searchParams),
      status: Number(searchParams.status) === 0 ? undefined : searchParams.status,
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      subtotalFrom: searchParams.amountMin ?? undefined,
      subtotalTo: searchParams.amountMax ?? undefined,
      isFavorite: searchParams.isFavorite ? true : undefined,
    })
  }

  async downloadTransactionDetails(filename: string, searchParams: TransactionSearchParams) {
    await this.request.download(filename, `/transaction-details/csv`, {
      freeWord: textToString(searchParams.keyword),
      currentPhase: transactionPhaseToList(searchParams),
      status: Number(searchParams.status) === 0 ? undefined : searchParams.status,
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      subtotalFrom: searchParams.amountMin ?? undefined,
      subtotalTo: searchParams.amountMax ?? undefined,
      isFavorite: searchParams.isFavorite ? true : undefined,
    })
  }

  async searchBillings(page: number, searchParams: BillingSearchParams) {
    const sort =
      searchParams.sortColumn && searchParams.sortDirection
        ? `${searchParams.sortColumn}_${searchParams.sortDirection}`
        : undefined
    const response = await this.request.get<Page<BillingSearchResultItem>>(`/billings`, {
      page,
      msId: textToString(searchParams.moneyspaceId),
      clientName: textToString(searchParams.clientName),
      totalAmountFrom: searchParams.amountMin,
      totalAmountTo: searchParams.amountMax,
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      isPaid: searchParams.isPaid,
      filterType: searchParams.filterType as string,
      hasInvoiceLicense:
        Number(searchParams.invoiceLessActionType) === 2 ? undefined : searchParams.invoiceLessActionType,
      sort,
    })
    return response.data
  }

  async loadBillings(searchParams: BillingSearchParams) {
    const response = await this.request.get<{ billings: BillingSearchResultItem[] }>(`/billings`, {
      msId: textToString(searchParams.moneyspaceId),
      clientName: textToString(searchParams.clientName),
      totalAmountFrom: searchParams.amountMin,
      totalAmountTo: searchParams.amountMax,
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      isPaid: searchParams.isPaid,
    })
    return response.data.billings
  }

  async downloadBillingsPdf(filename: string, searchParams: BillingSearchParams) {
    await this.request.download(filename, `/billings/export-pdf`, {
      clientName: textToString(searchParams.clientName),
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      totalAmountFrom: searchParams.amountMin ?? undefined,
      totalAmountTo: searchParams.amountMax ?? undefined,
      hasInvoiceLicense:
        Number(searchParams.invoiceLessActionType) === 2 ? undefined : searchParams.invoiceLessActionType,
      isPaid: searchParams.isPaid,
    })
  }

  async downloadBillings(filename: string, searchParams: BillingSearchParams) {
    await this.request.download(filename, `/billings/csv`, {
      clientName: textToString(searchParams.clientName),
      paymentDateFrom: searchParams.paymentDateFrom,
      paymentDateTo: searchParams.paymentDateTo,
      closingDateFrom: searchParams.closingDateFrom,
      closingDateTo: searchParams.closingDateTo,
      totalAmountFrom: searchParams.amountMin ?? undefined,
      totalAmountTo: searchParams.amountMax ?? undefined,
      hasInvoiceLicense:
        Number(searchParams.invoiceLessActionType) === 2 ? undefined : searchParams.invoiceLessActionType,
      isPaid: searchParams.isPaid,
    })
  }

  async loadSearchConditions(userId: string) {
    const response = await this.request.get<TransactionSearchConditionGetResponse>(`/users/${userId}/search-queries`)
    return response.data
  }

  async createSearchCondition(userId: string, name: string, query: string) {
    const response = await this.request.post<TransactionSearchConditionCreateResponse>(
      `/users/${userId}/search-queries`,
      {
        name,
        query,
      }
    )
    return response.data
  }

  async updateSearchCondition(userId: string, queryId: string, name: string) {
    await this.request.put(`/users/${userId}/search-queries/${queryId}`, {
      name,
    })
  }

  async deleteSearchCondition(userId: string, queryId: string) {
    await this.request.delete(`/users/${userId}/search-queries/${queryId}`)
  }

  async searchUnApprovedTransactions(page: number, sortColumn?: string, sortDirection?: SortDirection) {
    const sort = sortColumn && sortDirection ? `${sortColumn}_${sortDirection}` : undefined
    const response = await this.request.get<Page<TransactionSearchResultItem>>(`/transactions`, {
      page,
      filterType: 'unapproved',
      sort,
    })
    return response.data
  }

  async searchSubmittedTransactions(page: number, sortColumn?: string, sortDirection?: SortDirection) {
    const sort = sortColumn && sortDirection ? `${sortColumn}_${sortDirection}` : undefined
    const response = await this.request.get<Page<TransactionSearchResultItem>>(`/transactions`, {
      page,
      filterType: 'submitted',
      sort,
    })
    return response.data
  }

  async copyTransaction(transactionId: string, name: string) {
    const response = await this.request.post<{ id: string }>(`/transactions/${transactionId}/copy`, {
      name,
    })
    return response.data.id
  }

  async releaseTransaction(transactionId: string) {
    await this.request.put<Transaction>(`/transactions/${transactionId}/billing`)
  }

  async paidBilling(billingId: string) {
    await this.request.put(`/billings/${billingId}/paid`)
  }

  async bulkPaidBilling(billingIds: string[]) {
    await this.request.post(`/billings/bulk-paid`, { billingIds })
  }

  async import(params: ImportTransactionParams) {
    await this.request.post('/transactions/import', {
      proceedToReviewed: params.proceedToReviewed,
      fixFollowingPhase: params.fixFollowingPhase,
      phases: params.phases,
      data: params.data,
    })
  }

  async addFavorite(transactionId: string) {
    await this.request.post(`/transactions/${transactionId}/favorite`)
  }

  async removeFavorite(transactionId: string) {
    await this.request.delete(`/transactions/${transactionId}/favorite`)
  }
}
